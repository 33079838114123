// @flow
import { createElement as el, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fork } from "fluture";
import * as S from "sanctuary";

import { getMessageTypes } from "./../../Api";
import {
  useToken,
  useMessageTypes,
  refreshAction,
} from "../../../../State";
import  { useCatchExpiredToken } from "./../../../../State/Token.js";

import { View } from "./view.js";

const Controller = (props: {
  address: string,
  direction: string,
}) => {
  const {
    address,
    direction,
  } = props;
  const token = useToken();
  const dispatch = useDispatch();
  const messageTypes = useMessageTypes();
  const catchExpiredToken = useCatchExpiredToken();

  useEffect(
    () => {
      const future = getMessageTypes(token)(address);
      fork(
        error => {
          console.log(error);
          catchExpiredToken(error);
        }
      )(
        messageTypes => {
          console.log(messageTypes);
          dispatch(refreshAction(messageTypes))
        }
      )(
        future
      );
    },
    [],
  )

  const messageTypesFilteredForDirection = S.filter (
    S.compose (S.equals (direction)) (S.prop ("direction"))
  ) (messageTypes);

  return el(
    View,
    {
      address,
      direction,
      messageTypes: messageTypesFilteredForDirection,
    },
  )
}
export { Controller }

