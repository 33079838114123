// @flow
import {
  createElement as el,
  useEffect,
  useState,
}
from "react";
import log from "loglevel";
import {
  useParams,
} from "react-router-dom";
import {
  connect,
  useDispatch,
  useSelector,
} from "react-redux";
import * as S from "sanctuary";

import {
  type Platform,
} from "../../../Entity/Types.js";
import {
  withQboV1AuthnApi,
} from "../Api/";
import {
  ConnectedView,
  NotConnectedView,
} from "./View.js";
import {
  INPUT,
  WAITING,
  SUCCESS,
} from "../../../Util";

import {
  getOrganization,
} from "./../../../Api/MartletOrganization/Organization.js";
import {
  addOrg as addOrganization,
} from "./../../../State/Organizations.js";
import { useToken } from "./../../../State";

const logger = log.getLogger("Qbo");

const find = sequence => key => value => {
  const platform = S.find ( item => S.equals (S.prop (key) (item)) (value)) ( sequence);
  const platform_ = S.maybeToNullable (platform);
  logger.debug("platform_", platform_);
  return platform_;
}

const makeOrganizationThunk = token => organizationId => {
  const thunk = (dispatch, getState) => {
    console.log("Inside the thunk");
    const state = getState();
    const organizations = state.orgs;
    console.log("organizations already in state:", organizations);
    const selector = S.compose (S.equals (organizationId)) (S.prop ("id"));
    const maybeOrganization = S.find (selector) (organizations);
    const eitherOrganization = S.maybeToEither ("not in store") (maybeOrganization);
    const fetchOrganization = async () => {
      console.log("Shazam: api call for organization");
      const o = await getOrganization({token, organizationId});
      return o
    }
    const doNothing = org => {
      console.log("vvvvvvvvvvvv doing nothing");
      return org;
    };
    const org = S.either (fetchOrganization) (doNothing) (eitherOrganization);
    dispatch(addOrganization(org));
  }
  return thunk
}

const Controller_ = (props: {
  qboV1AuthnApi: Object,
}) => {
  const {
    qboV1AuthnApi,
  } = props;
  const { organizationId, pid: platformId } = useParams();
  const dispatch = useDispatch();
  const token = useToken();
  const thunk = makeOrganizationThunk(token)(organizationId);
  dispatch(thunk);
  const getPlatform = (state) => {
    logger.debug("platformId", platformId)
    console.log("Redux platforms: ", state.platforms);
    const platform = find (state.platforms) ("platformId") (platformId);
    return platform;
  }
  const platform = useSelector(getPlatform);
  console.log("Da platform", platform);

  // const platformName = platform.name;
  // const platformId= platform.platformId;
  // const organizationId = platform.organizationId;

  const [connection, setConnection] = useState();
  const [display, setDisplay] = useState(INPUT);

  const refresh = async () => {
    setDisplay(WAITING);
    let connection;
    try {
      connection = await qboV1AuthnApi.select({
        organizationId,
        platformId,
      });
    } catch(err) {
      console.log("There was a dreadful error");
    }
    if (!!connection) {
        setConnection(connection);
        setDisplay(SUCCESS);
    }
    else {
        setConnection(null);
        setDisplay(SUCCESS);
    }
  }

  useEffect(
    () => {refresh()},
    [],
  );

  const onConnect = () => {
    logger.debug("connection occurred");
    // refresh()
  }
  const onTest = () => {
    logger.debug("connection test occurred");
  }
  const onDisconnect = () => {
    logger.debug("connection disconnect occurred");
    refresh()
  }

  return (
    !!connection ? el(
      ConnectedView,
      {
        display,
        platform,
        connection,
        onDisconnect,
        onTest,
      },
    ) : el(
      NotConnectedView,
      {
        display,
        platform,
        onConnect,
      },
    )
  )
}


const connector = connect(
  (state, ownProps) => {
    const platformId = ownProps.match.params.pid;
    logger.debug("platformId", platformId)
    console.log("Redux platforms: ", state.platforms);
    return {
      platform: find (state.platforms) ("platformId") (platformId),
    }
  }
)

const Controller = withQboV1AuthnApi(Controller_);

export {
  find,
  Controller,
}
