// @flow
import { createElement as el } from "react";
import { useParams } from "react-router-dom";
import { View } from "./view.js";

const Controller  = (props: {
}) => {

  const { addressId } = useParams();

  return el(
    View,
    {
      owner: addressId,
    },
  )
}


export { Controller }
