// @flow
import * as R from "ramda";
import {
  type Acceptance,
} from "../Entity/Types.js";

const INSERT_ACCEPTANCE = "INSERT_ACCEPTANCE";
const INSERT_ACCEPTANCES = "INSERT_ACCEPTANCES";


const insertAcceptance = (acceptance: Acceptance) => {
  return Object.freeze({
    type: INSERT_ACCEPTANCE,
    acceptance: acceptance,
  });
}


const insertAcceptances = (acceptances: Array<Acceptance>) => {
  return Object.freeze({
    type: INSERT_ACCEPTANCES,
    acceptances: acceptances,
  });
}


const handleInsertAcceptance= (state, acceptance) => {
    return R.uniqBy(
      acc => acc.messageId,
      R.prepend(acceptance, state),
    )
}


const handleInsertAcceptances= (state, acceptances) => {
    return R.uniqBy(
      acc => acc.messageId,
      R.concat(acceptances, state),
    )
}


const reducer = (state: Array<Acceptance>=[], action:Object) => {

  switch(action.type){
    case INSERT_ACCEPTANCE:
      return handleInsertAcceptance(state, action.acceptance);
    case INSERT_ACCEPTANCES:
      return handleInsertAcceptances(state, action.acceptances);
    default:
      return state;
  }
}


export {
  reducer as acceptances,
  insertAcceptance,
  insertAcceptances,
}


