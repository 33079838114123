// @flow
import React from "react";
import ReactTable from "react-table-6"
import "react-table-6/react-table.css"
import Button from "react-bootstrap/Button";
import * as S from "sanctuary";
import * as $ from "sanctuary-def";
import {
  CheckIcon,
  XIcon,
  NoteIcon,
} from "@primer/octicons-react"
import { Link } from "react-router-dom";

import { OrganizationProfileWidget } from "./../../../../Widgets/OrganizationProfile";
import {
  type DecoratedRejectedMessage as RejectedMessage
} from "./../../entity.js";
import { BmbixDateTime } from  "./../../../../Widgets";
import {
  includesText,
} from "./../../../../Util/filter.js";
import { generateAlias } from "./../../../../Util/alias.js";


const caseInsensitiveFilter =
  (filter, row, column) =>
{
  const re = S.regex ("g") (S.toLower (filter.value));
  const eitherCellValue = S.tagBy (S.is ($.String)) (row[filter.id]);
  const beFalse = () => false;
  const testRegex = S.compose (S.test (re)) (S.toLower);
  return S.either (beFalse) (testRegex) (eitherCellValue);
}

const View = (props: {
  organizationId: string,
  addressId: string,
  items: Array<RejectedMessage>,
}) => {
  const {
    organizationId,
    addressId,
    items,
  } = props;

  const columns = [
    {
      Header: "System Id",
      accessor: "message.systemId",
      show: false
    },
    {
      Header: "To",
      accessor: (decoratedRejectionMessage) => {
        if (!!decoratedRejectionMessage.addressProfile.organizationId) {
          return generateAlias(decoratedRejectionMessage.addressProfile);
        } else {
          return "";
        }
      },
      id: "message.toId",
      filterable: true,
      Cell: ({row, value}) => {
        const addressProfile = row._original.addressProfile;
        const organizationProfile = addressProfile.organizationProfile;
        return !!organizationProfile ? <><OrganizationProfileWidget
        organizationProfile={organizationProfile}/> - {addressProfile.displayName}  </>: value;
      } ,
    },
    {
      Header: "Message Type",
      accessor: "message.messageType",
      filterable: true,
    },
    {
      Header: "Sender Ref",
      accessor: "message.senderReference",
      filterable: true,
    },
    {
      Header: "Rejected At",
      accessor: "message.updatedAt",
      Cell: ({value}) => <BmbixDateTime timeStamp={value} />
    },
    {
      Header: "Valid",
      Cell: ({row}) => {
        return row._original.message.reasons.valid ? <CheckIcon />: <XIcon />;
      }
    },
    {
      Header: "Not Blocked",
      Cell: ({row}) => {
        return row._original.message.reasons.not_blocked? <CheckIcon />: <XIcon />;
      }
    },
    {
      Header: "Understood",
      Cell: ({row}) => {
        return row._original.message.reasons.understood ? <CheckIcon />: <XIcon />;
      }
    },
    {
      Header: "Allow-listed",
      Cell: ({row}) => {
        return row._original.message.reasons.whitelisted? <CheckIcon />: <XIcon />;
      }
    },
    {
      accessor: "message.messageId",
      Cell: ({row, value}) => {
        const path = `/u/organizations/${organizationId}/addresses/${addressId}/messages-status/${value}`;
        return <Link to={path}><Button><NoteIcon /> Status</Button></Link>;
      }
    },
  ]

  return (
    <ReactTable
      className="-striped -highlight"
      data={items}
      columns={columns}
      showPagination={false}
      pageSize={S.size(items)}
      defaultSorted={[{id: "message.systemId", desc: true}]}
      defaultFilterMethod={caseInsensitiveFilter}
    />
  );
}

export { View } ;
