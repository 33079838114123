// @flow
import React from "react";
import ButtonToolbar from "react-bootstrap/ButtonToolbar"
import {
  Spinner,
} from "./../../../Widgets/Toast";
import { MessageContentTypesTable } from "./message-content-types-table.js";

import { Controller as AddModal } from "./add-modal/controller.js";


const HomePage = (props: {
  state: Function,
  organizationId: string,
}) => {

  const { state, organizationId } = props;

  return state.value === "ready"
  ? <>Ready to go</>
  : state.value === "working"
  ? <Spinner height="50" />
  : state.value === "success"
  ? <>
    <ButtonToolbar className="float-right" >
      <AddModal organizationId={organizationId}/>
    </ButtonToolbar>
    <h2>Message Content Types</h2>
    <MessageContentTypesTable data={state.context.contentTypes} />
    </>
  : state.value === "failure"
  ? <p>Oops</p>
  : <p>Unknown state</p>;


}

export default HomePage;
export {
  HomePage,
}

