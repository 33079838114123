// @flow
import { createElement as el, useState } from "react";
import { fork } from "fluture";
import { useDispatch } from "react-redux";

import { removeMessageType } from "../../Api";
import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "../../../../Util";

import { removeAction, useToken } from "./../../../../State";
import { useCatchExpiredToken } from "./../../../../State/Token.js";
import { View } from "./view.js";

function Controller (props: {
  address: string,
  messageTypeId: string,
  onDone: Function,
}) {

  const {
    onDone,
    address,
    messageTypeId,
  } = props;


  const dispatch = useDispatch();
  const token = useToken();
  const [display, setDisplay] = useState(INPUT);
  const [confirmed, setConfirmed] = useState(false);
  const [ messageType, setMessageType ] = useState();
  const catchExpiredToken = useCatchExpiredToken();

  const onSubmit = e => {
    if (confirmed) {
      setDisplay(WAITING);
      const action = removeMessageType(token)(address)(messageTypeId)
      fork(error => {
        console.log(error)
        catchExpiredToken(error);
        setDisplay(FAILURE);
      })(messageType => {
        console.log("messageType", messageType);
        setMessageType(messageType);
        setDisplay(SUCCESS);
      })(action);
    } else {
      alert("Cannot submit unless you confirm");
    }
  }

  function onCancel (e) {
    console.log("onCancel:", e);
    onDone();
  }

  function onDoneFailure (e) {
    console.log("onDoneFailure:", e);
    onDone();
  }

  function onDoneSuccess(e) {
    console.log("onDoneSuccess:", e);
    dispatch(removeAction(messageType));
    onDone();
  }

  function onChange (e) {
    console.log(e.currentTarget.name, e.currentTarget.value);
    const n = e.currentTarget.name;
    const v = e.currentTarget.value;
    switch (n) {
      case "confirmed":
        // no value from form is checkbox not ticked.
        const actualValue = v === "confirmed"
        setConfirmed(actualValue);
        break;
      default:
        break;
    }
  }

  return el(
    View,
    {
      display,
      onCancel,
      onSubmit,
      onDoneSuccess,
      onDoneFailure,
      onChange,
      data: messageTypeId,
    }
  );

}

export { Controller };
export default Controller;
