// @flow
import React from "react";
import ReactDOM from "react-dom";
import {
  Link,
} from "react-router-dom";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


type Props = {
}
class Pooter extends React.Component<Props> {

  constructor (props:Props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount () {
    console.log("Pooter mounting", this.ref.current);
    if(!!this.props.bmbixDoc){
      this.ref.current.appendChild(this.props.bmbixDoc);
    }
  }

  render () {
    return (
      <div ref={this.ref}/>
    )
  }
}

const INPUT = "input";
const WAITING = "waiting";
const SUCCESS = "success";
const FAILURE = "failure";

const View = (props: {
  display: string,
  transformedMessage: Function,
  fromAddressUrl: ?string,
  toAddressUrl: ?string,
}) => {
  const {
    fromAddressUrl,
    toAddressUrl,
    display,
    transformedMessage,
  } = props;

  const input = display === INPUT ? (
    null
  ): null;

  const waiting = display === WAITING ? (
    <p>Waiting ... </p>
  ): null;

  const fromLink = !!fromAddressUrl ? (
    <Link to={fromAddressUrl}><Button className="mr-2">Source Address</Button></Link>
  ): null;

  const toLink = !!toAddressUrl ? (
    <Link to={toAddressUrl}><Button className="mr-2" >Destination Address</Button></Link>
  ): null;

  const success = display === SUCCESS ? (
        <>
        <ButtonToolbar>
          {fromLink}
          {toLink}
        </ButtonToolbar>
        <Pooter bmbixDoc={transformedMessage}/>
        </>
      ): null;

  const failure = display === FAILURE ? (
    <p>Ooops! Something went wrong.</p>
  ): null;

  return (
    <Container fluid={true}>
    <Row>
    <Col>
    {input}
    {waiting}
    {success}
    {failure}
    </Col>
    <Col>
    <h2>Messages</h2>
    <p>Any messages will appear here</p>
    </Col>
    </Row>
    </Container>
  )
}

export {
  View,
}

