// @flow
import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


const View = (props: {
  displayText: string,
  rolloverText: string,
}) => {
  const {
    displayText,
    rolloverText,
  } = props;

  const toolTip = <Tooltip>{rolloverText}</Tooltip>

  return (
    <OverlayTrigger
      overlay={toolTip}
      placement="bottom"
      delay={{show: 250, hide: 400}}
    ><span>{displayText}</span></OverlayTrigger>
  );
}

export {
  View,
}
