// @flow
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import {
  Input,
  Waiting,
  Success,
  Failure,
} from "../Widgets/DisplayWrappers.js";

const PurposeButton = (props: {
  onClick: Function,
}) => {
  const {
    onClick,
  } = props;

  return (
    <Button onClick={onClick}>Update</Button>
  );
}


const PurposeModal = (props: {
  display: string,
  purpose: string,
  onChange: Function,
  onSubmit: Function,
  onCancel: Function,
  onSuccessComplete: Function,
  onFailureComplete: Function,
}) => {
  const {
    display,
    purpose,
    onChange,
    onSubmit,
    onCancel,
    onSuccessComplete,
    onFailureComplete,
  } = props;

  return (
    <Modal show={true}>
    <Modal.Header>
    <Modal.Title>Update Address Purpose</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <Input display={display}>
    <Form>
    <Form.Group>
    <Form.Label>Update Purpose</Form.Label>
    <Form.Control
      as="select"
      onChange={e => onChange(e.target.value)}
      defaultValue={purpose}
    >
      <option value="sales" >Sales</option>
      <option value="purchases" >Purchases</option>
      <option value="hr" >Human Resources</option>
      <option value="treasury" >Treasury</option>
      <option value="payroll" >Payroll</option>
      <option value="property-rental" >Property Rental</option>
      <option value="leasing" >Leasing</option>
    </Form.Control>
    <Form.Text>Make a selection</Form.Text>
    </Form.Group>
    </Form>
    </Input>
    <Waiting display={display}>Waiting ...</Waiting>
    <Success display={display}>Success!</Success>
    <Failure display={display}>Oops! something went wrong</Failure>
    </Modal.Body>
    <Modal.Footer>
      <Input display={display}>
        <Button onClick={onSubmit}>Submit</Button>
        <Button onClick={onCancel}>Cancel</Button>
      </Input>
      <Waiting display={display}>
        <Button onClick={onCancel}>Cancel</Button>
      </Waiting>
      <Success display={display}>
        <Button onClick={onSuccessComplete}>Done (Success)</Button>
      </Success>
      <Failure display={display}>
        <Button onClick={onFailureComplete}>Done (Failure)</Button>
      </Failure>
    </Modal.Footer>
    </Modal>
  );
}


export {
  PurposeButton,
  PurposeModal,
}
