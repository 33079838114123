// @flow
import React from "react";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import * as S from "sanctuary";


import {BModal, BSuccess, BFailure} from "../../../../Widgets/BModal";

import { type MessageContentType } from "./../../../../Organization/ContentTypesApp/entity.js";


function MessageTypeFormAdd (props: {
  onChange: Function,
  data: Array<MessageContentType>,
}) {
  const {
    onChange,
    data,
  } = props;

  return (
      <Tabs defaultActiveKey="ubl">
        <Tab eventKey="ubl" title="UBL">
    <Form>
    <Form.Group>
    <Form.Label>Message type</Form.Label>
    <Form.Control
      as="select"
      type="text"
      name="messageType"
      onChange={onChange}
      placeholder="Enter message type"
    >
      <option value=""></option>
      <option value="CommonAggregateComponents-2">CommonAggregateComponents-2</option>
      <option value="CommonBasicComponents-2">CommonBasicComponents-2</option>
      <option value="CommonExtensionComponents-2">CommonExtensionComponents-2</option>
      <option value="CommonSignatureComponents-2">CommonSignatureComponents-2</option>
      <option value="QualifiedDataTypes-2">QualifiedDataTypes-2</option>
      <option
          value="SignatureAggregateComponents-2">SignatureAggregateComponents-2</option>
      <option value="SignatureBasicComponents-2">SignatureBasicComponents-2</option>
      <option value="UnqualifiedDataTypes-2">UnqualifiedDataTypes-2</option>
      <option value="ApplicationResponse-2">ApplicationResponse-2</option>
      <option value="AttachedDocument-2">AttachedDocument-2</option>
      <option value="AwardedNotification-2">AwardedNotification-2</option>
      <option value="BillOfLading-2">BillOfLading-2</option>
      <option value="BusinessCard-2">BusinessCard-2</option>
      <option value="CallForTenders-2">CallForTenders-2</option>
      <option value="Catalogue-2">Catalogue-2</option>
      <option value="CatalogueDeletion-2">CatalogueDeletion-2</option>
      <option value="CatalogueItemSpecificationUpdate-2">CatalogueItemSpecificationUpdate-2</option>
      <option value="CataloguePricingUpdate-2">CataloguePricingUpdate-2</option>
      <option value="CatalogueRequest-2">CatalogueRequest-2</option>
      <option value="CertificateOfOrigin-2">CertificateOfOrigin-2</option>
      <option value="ContractAwardNotice-2">ContractAwardNotice-2</option>
      <option value="ContractNotice-2">ContractNotice-2</option>
      <option value="CreditNote-2">CreditNote-2</option>
      <option value="DebitNote-2">DebitNote-2</option>
      <option value="DespatchAdvice-2">DespatchAdvice-2</option>
      <option value="DigitalAgreement-2">DigitalAgreement-2</option>
      <option value="DigitalCapability-2">DigitalCapability-2</option>
      <option value="DocumentStatus-2">DocumentStatus-2</option>
      <option value="DocumentStatusRequest-2">DocumentStatusRequest-2</option>
      <option value="Enquiry-2">Enquiry-2</option>
      <option value="EnquiryResponse-2">EnquiryResponse-2</option>
      <option value="ExceptionCriteria-2">ExceptionCriteria-2</option>
      <option value="ExceptionNotification-2">ExceptionNotification-2</option>
      <option
          value="ExpressionOfInterestRequest-2">ExpressionOfInterestRequest-2</option>
      <option
          value="ExpressionOfInterestResponse-2">ExpressionOfInterestResponse-2</option>
      <option value="Forecast-2">Forecast-2</option>
      <option value="ForecastRevision-2">ForecastRevision-2</option>
      <option value="ForwardingInstructions-2">ForwardingInstructions-2</option>
      <option value="FreightInvoice-2">FreightInvoice-2</option>
      <option value="FulfilmentCancellation-2">FulfilmentCancellation-2</option>
      <option value="GoodsItemItinerary-2">GoodsItemItinerary-2</option>
      <option value="GuaranteeCertificate-2">GuaranteeCertificate-2</option>
      <option value="InstructionForReturns-2">InstructionForReturns-2</option>
      <option value="InventoryReport-2">InventoryReport-2</option>
      <option value="ubl-invoice-2-2">Invoice-2</option>
      <option value="ItemInformationRequest-2">ItemInformationRequest-2</option>
      <option value="Order-2">Order-2</option>
      <option value="OrderCancellation-2">OrderCancellation-2</option>
      <option value="OrderChange-2">OrderChange-2</option>
      <option value="OrderResponse-2">OrderResponse-2</option>
      <option value="OrderResponseSimple-2">OrderResponseSimple-2</option>
      <option value="PackingList-2">PackingList-2</option>
      <option value="PriorInformationNotice-2">PriorInformationNotice-2</option>
      <option value="ProductActivity-2">ProductActivity-2</option>
      <option value="QualificationApplicationRequest-2">QualificationApplicationRequest-2</option>
      <option value="QualificationApplicationResponse-2">QualificationApplicationResponse-2</option>
      <option value="Quotation-2">Quotation-2</option>
      <option value="ReceiptAdvice-2">ReceiptAdvice-2</option>
      <option value="ubl-reminder-2-2">Reminder-2</option>
      <option value="RemittanceAdvice-2">RemittanceAdvice-2</option>
      <option value="RequestForQuotation-2">RequestForQuotation-2</option>
      <option value="RetailEvent-2">RetailEvent-2</option>
      <option value="SelfBilledCreditNote-2">SelfBilledCreditNote-2</option>
      <option value="SelfBilledInvoice-2">SelfBilledInvoice-2</option>
      <option value="Statement-2">Statement-2</option>
      <option value="StockAvailabilityReport-2">StockAvailabilityReport-2</option>
      <option value="Tender-2">Tender-2</option>
      <option value="TenderContract-2">TenderContract-2</option>
      <option value="TenderReceipt-2">TenderReceipt-2</option>
      <option value="TenderStatus-2">TenderStatus-2</option>
      <option value="TenderStatusRequest-2">TenderStatusRequest-2</option>
      <option value="TenderWithdrawal-2">TenderWithdrawal-2</option>
      <option value="TendererQualification-2">TendererQualification-2</option>
      <option
          value="TendererQualificationResponse-2">TendererQualificationResponse-2</option>
      <option value="TradeItemLocationProfile-2">TradeItemLocationProfile-2</option>
      <option value="TransportExecutionPlan-2">TransportExecutionPlan-2</option>
      <option
          value="TransportExecutionPlanRequest-2">TransportExecutionPlanRequest-2</option>
      <option value="TransportProgressStatus-2">TransportProgressStatus-2</option>
      <option value="TransportProgressStatusRequest-2">TransportProgressStatusRequest-2</option>
      <option
          value="TransportServiceDescription-2">TransportServiceDescription-2</option>
      <option value="TransportServiceDescriptionRequest-2">TransportServiceDescriptionRequest-2</option>
      <option value="TransportationStatus-2">TransportationStatus-2</option>
      <option
          value="TransportationStatusRequest-2">TransportationStatusRequest-2</option>
      <option value="UnawardedNotification-2">UnawardedNotification-2</option>
      <option value="UnsubscribeFromProcedureRequest-2">UnsubscribeFromProcedureRequest-2</option>
      <option value="UnsubscribeFromProcedureResponse-2">UnsubscribeFromProcedureResponse-2</option>
      <option value="UtilityStatement-2">UtilityStatement-2</option>
      <option value="Waybill-2">Waybill-2</option>
      <option value="WeightStatement-2">WeightStatement-2</option>


    </Form.Control>
    <Form.Text>The message type to add.</Form.Text>
    </Form.Group>
    </Form>
        </Tab>
        <Tab eventKey="edifact" title="Edifact">
    <Form>
    <Form.Group>
    <Form.Label>Edifact Message type</Form.Label>
    <Form.Control
      as="select"
      type="text"
      name="messageType"
      onChange={onChange}
      placeholder="Enter message type"
    >
      <option value=""></option>



<option value="ENTREC">ENTREC Accounting entries message</option>
<option value="DOCAMA">DOCAMA Advice of an amendment of a documentary credit message</option>
<option value="CONAPW">CONAPW Advice on pending works message</option>
<option value="RETANN">RETANN Announcement for returns message</option>
<option value="APERAK">APERAK Application error and acknowledgement
    message</option>
<option value="IFTMAN">IFTMAN Arrival notice message</option>
<option value="AUTHOR">AUTHOR Authorization message</option>
<option value="BALANC">BALANC Balance message</option>
<option value="BOPCUS">BOPCUS Balance of payment customer transaction report message</option>
<option value="BOPINF">BOPINF Balance of payment information from customer message</option>
<option value="BOPBNK">BOPBNK Bank transactions and portfolio transactions report message</option>
<option value="BANSTA">BANSTA Banking status message</option>
<option value="BAPLIE">BAPLIE Bayplan/stowage plan occupied and empty</option>
<option value="BERMAN">BERMAN Berth management message</option>
<option value="IFTMBC">IFTMBC Booking confirmation message</option>
<option value="BMISRM">BMISRM Bulk marine inspection summary report
    message</option>
<option value="BUSCRD">BUSCRD Business credit report message</option>
<option value="IFTICL">IFTICL Cargo insurance claims message</option>
<option value="HANMOV">HANMOV Cargo/goods handling and movement message</option>
<option value="CHACCO">CHACCO Chart of accounts message</option>
<option value="CLASET">CLASET Classification information set message</option>
<option value="COACSU">COACSU Commercial account summary message</option>
<option value="COMDIS">COMDIS Commercial dispute message</option>
<option value="IFTMCA">IFTMCA Consignment advice message</option>
<option value="COPARN">COPARN Container announcement message</option>
<option value="COPRAR">COPRAR Container discharge/loading order message</option>
<option value="COARRI">COARRI Container discharge/loading report
    message</option>
<option value="CODECO">CODECO Container gate-in/gate-out report message</option>
<option value="COPINO">COPINO Container pre-notification message</option>
<option value="COREOR">COREOR Container release order message</option>
<option value="COHAOR">COHAOR Container special handling order message</option>
<option value="COSTCO">COSTCO Container stuffing/stripping confirmation
    message</option>
<option value="COSTOR">COSTOR Container stuffing/stripping order
    message</option>
<option value="CNTCND">CNTCND Contractual conditions message</option>
<option value="COPAYM">COPAYM Contributions for payment</option>
<option value="CREADV">CREADV Credit advice message</option>
<option value="RECECO">RECECO Credit risk cover message</option>
<option value="CUSCAR">CUSCAR Customs cargo report message</option>
<option value="CUSREP">CUSREP Customs conveyance report message</option>
<option value="CUSDEC">CUSDEC Customs declaration message</option>
<option value="CUSEXP">CUSEXP Customs express consignment declaration
    message</option>
<option value="CUSRES">CUSRES Customs response message</option>
<option value="IFTDGN">IFTDGN Dangerous goods notification message</option>
<option value="DGRECA">DGRECA Dangerous goods recapitulation message</option>
<option value="DMRDEF">DMRDEF Data maintenance request definition
    message</option>
<option value="DMSTAT">DMSTAT Data maintenance status report query
    message</option>
<option value="DAPLOS">DAPLOS Data Plot Sheet</option>
<option value="DEBADV">DEBADV Debit advice message</option>
<option value="DEBREC">DEBREC Debts recovery message</option>
<option value="DELJIT">DELJIT Delivery just in time message</option>
<option value="DELFOR">DELFOR Delivery schedule message</option>
<option value="DESADV">DESADV Despatch advice message</option>
<option value="BOPDIR">BOPDIR Direct balance of payment declaration
    message</option>
<option value="DIRDEB">DIRDEB Direct debit message</option>
<option value="CONDPV">CONDPV Direct payment valuation message</option>
<option value="DIRDEF">DIRDEF Directory definition message</option>
<option value="DOCADV">DOCADV Documentary credit advice message</option>
<option value="DOCAMI">DOCAMI Documentary credit amendment information
    message</option>
<option value="DOCAPP">DOCAPP Documentary credit application message</option>
<option value="DOCINF">DOCINF Documentary credit issuance information
    message</option>
<option value="CONDRA">CONDRA Drawing administration message</option>
<option value="CONDRO">CONDRO Drawing organisation message</option>
<option value="IMPDEF">IMPDEF EDI implementation guide definition
    message</option>
<option value="INFENT">INFENT Enterprise accounting information message</option>
<option value="DESTIM">DESTIM Equipment damage and repair estimate
    message</option>
<option value="CONEST">CONEST Establishment of contract message</option>
<option value="CREEXT">CREEXT Extended credit advice message</option>
<option value="PAYEXT"> PAYEXT Extended payment order message</option>
<option value="FINCAN">FINCAN Financial cancellation message</option>
<option value="FINSTA">FINSTA Financial statement of an account message</option>
<option value="IFTMBF">IFTMBF Firm booking message</option>
<option value="IFCSUM">IFCSUM Forwarding and consolidation summary
    message</option>
<option value="IFTRIN">IFTRIN Forwarding and transport rate information
    message</option>
<option value="IFTSAI">IFTSAI Forwarding and transport schedule and availability information message</option>
<option value="IFTCCA">IFTCCA Forwarding and transport shipment charge calculation message</option>
<option value="GENRAL">GENRAL General purpose message</option>
<option value="GESMES">GESMES Generic statistical message</option>
<option value="GOVCBR">GOVCBR Government Cross Border Regulatory
    message</option>
<option value="ITRRPT">ITRRPT In transit report detail message</option>
<option value="INFCON">INFCON Infrastructure condition message</option>
<option value="INSRPT">INSRPT Inspection report message</option>
<option value="INSREQ">INSREQ Inspection request message</option>
<option value="IFTMCS">IFTMCS Instruction contract status message</option>
<option value="RETINS">RETINS Instruction for returns message</option>
<option value="IFTMIN">IFTMIN Instruction message</option>
<option value="INSDES">INSDES Instruction to despatch message</option>
<option value="ICASRP">ICASRP Insurance claim assessment and reporting
    message</option>
<option value="ICSOLI">ICSOLI Insurance claim solicitor&apos;s instruction
    message</option>
<option value="IPPOAD">IPPOAD Insurance policy administration message</option>
<option value="INSPRE">INSPRE Insurance premium message</option>
<option value="PRPAID">PRPAID Insurance premium payment message</option>
<option value="ISENDS">ISENDS Intermediary system enablement or disablement message</option>
<option value="SANCRT">SANCRT International movement of goods governmental regulatory message</option>
<option value="IFTSTA">IFTSTA International multimodal status report
    message</option>
<option value="IFTSTQ">IFTSTQ International multimodal status request
    message</option>
<option value="IFTFCC">IFTFCC International transport freight costs and other charges message</option>
<option value="INVRPT">INVRPT Inventory report message</option>
<option value="CONITT">CONITT Invitation to tender message</option>
<option value="INVOIC">INVOIC Invoice message</option>
<option value="JOBAPP">JOBAPP Job application proposal message</option>
<option value="JAPRES">JAPRES Job application result message</option>
<option value="JINFDE">JINFDE Job information demand message</option>
<option value="JOBCON">JOBCON Job order confirmation message</option>
<option value="JOBOFF">JOBOFF Job order message</option>
<option value="JOBMOD">JOBMOD Job order modification message</option>
<option value="JUPREQ">JUPREQ Justified payment request message</option>
<option value="LEDGER">LEDGER Ledger message</option>
<option value="CASRES">CASRES Legal administration response in civil proceedings message</option>
<option value="LREACT">LREACT Life reinsurance activity message</option>
<option value="LRECLM">LRECLM Life reinsurance claims message</option>
<option value="MEQPOS">MEQPOS Means of transport and equipment position
    message</option>
<option value="MEDPRE">MEDPRE Medical prescription message</option>
<option value="MEDRUC">MEDRUC Medical resource usage and cost message</option>
<option value="MEDRPT">MEDRPT Medical service report message</option>
<option value="MEDREQ">MEDREQ Medical service request message</option>
<option value="MSCONS">MSCONS Metered services consumption report
    message</option>
<option value="SSIMOD">SSIMOD Modification of identity details message</option>
<option value="IPPOMO">IPPOMO Motor insurance policy message</option>
<option value="CREMUL">CREMUL Multiple credit advice message</option>
<option value="DEBMUL">DEBMUL Multiple debit advice message</option>
<option value="FINPAY">FINPAY Multiple interbank funds transfer message</option>
<option value="PAYMUL">PAYMUL Multiple payment order message</option>
<option value="SSREGW">SSREGW Notification of registration of a worker
    message</option>
<option value="OSTENQ">OSTENQ Order status enquiry message</option>
<option value="OSTRPT">OSTRPT Order status report message</option>
<option value="PARTIN">PARTIN Party information message</option>
<option value="PAXLST">PAXLST Passenger list message</option>
<option value="PAYORD">PAYORD Payment order message</option>
<option value="CONPVA">CONPVA Payment valuation message</option>
<option value="PAYDUC">PAYDUC Payroll deductions advice message</option>
<option value="CUSPED">CUSPED Periodic customs declaration message</option>
<option value="CODENO">CODENO Permit expiration/clearance ready notice
    message</option>
<option value="MEDPID">MEDPID Person identification message</option>
<option value="PRICAT">PRICAT Price/sales catalogue message</option>
<option value="PRIHIS">PRIHIS Pricing history message</option>
<option value="PRODAT">PRODAT Product data message</option>
<option value="PRODEX">PRODEX Product exchange reconciliation message</option>
<option value="PROINQ">PROINQ Product inquiry message</option>
<option value="PROSRV">PROSRV Product service message</option>
<option value="PROCST">PROCST Project cost reporting message</option>
<option value="PROTAP">PROTAP Project tasks planning message</option>
<option value="IFTMBP">IFTMBP Provisional booking message</option>
<option value="ORDCHG">ORDCHG Purchase order change request message</option>
<option value="ORDERS">ORDERS Purchase order message</option>
<option value="ORDRSP">ORDRSP Purchase order response message</option>
<option value="QALITY">QALITY Quality data message</option>
<option value="CONQVA">CONQVA Quantity valuation message</option>
<option value="QUOTES">QUOTES Quote message</option>
<option value="RDRMES">RDRMES Raw data reporting message</option>
<option value="RECADV">RECADV Receiving advice message</option>
<option value="REGENT">REGENT Registration of enterprise message</option>
<option value="REBORD">REBORD Reinsurance bordereau message</option>
<option value="RECALC">RECALC Reinsurance calculation message</option>
<option value="RECLAM">RECLAM Reinsurance claims message</option>
<option value="RECORD">RECORD Reinsurance core data message</option>
<option value="REPREM">REPREM Reinsurance premium message</option>
<option value="RESETT">RESETT Reinsurance settlement message</option>
<option value="RETACC">RETACC Reinsurance technical account message</option>
<option value="RELIST">RELIST Reinsured objects list message</option>
<option value="REMADV">REMADV Remittance advice message</option>
<option value="RPCALL">RPCALL Repair call message</option>
<option value="COLREQ">COLREQ Request for a documentary collection
    message</option>
<option value="DOCAMR">DOCAMR Request for an amendment of a documentary credit message</option>
<option value="REQDOC">REQDOC Request for document message</option>
<option value="CASINT">CASINT Request for legal administration action in civil proceedings message</option>
<option value="REQOTE">REQOTE Request for quote message</option>
<option value="RESMSG">RESMSG Reservation message</option>
<option value="CONRPW">CONRPW Response of pending works message</option>
<option value="DOCARE">DOCARE Response to an amendment of a documentary credit message</option>
<option value="SAFHAZ">SAFHAZ Safety and hazard data message</option>
<option value="SLSRPT">SLSRPT Sales data report message</option>
<option value="SLSFCT">SLSFCT Sales forecast message</option>
<option value="STLRPT">STLRPT Settlement transaction reporting message</option>
<option value="SOCADE">SOCADE Social administration message</option>
<option value="STATAC">STATAC Statement of account message</option>
<option value="MOVINS">MOVINS Stowage instruction message</option>
<option value="SUPCOT">SUPCOT Superannuation contributions advice
    message</option>
<option value="SUPMAN">SUPMAN Superannuation maintenance message</option>
<option value="SUPRES">SUPRES Supplier response message</option>
<option value="TANSTA">TANSTA Tank status report message</option>
<option value="TAXCON">TAXCON Tax control message</option>
<option value="CONTEN">CONTEN Tender message</option>
<option value="TPFREP">TPFREP Terminal performance message</option>
<option value="COEDOR">COEDOR Transport equipment stock and profile report message</option>
<option value="UTILMD">UTILMD Utilities master data message</option>
<option value="UTILTS">UTILTS Utilities time series message</option>
<option value="VATDEC">VATDEC Value added tax message</option>
<option value="VERMAS">VERMAS Verified gross mass message</option>
<option value="CALINF">CALINF Vessel call information message</option>
<option value="VESDEP">VESDEP Vessel departure message</option>
<option value="WASDIS">WASDIS Waste disposal information message</option>
<option value="WKGRDC">WKGRDC Work grant decision message</option>
<option value="WKGRRE">WKGRRE Work grant request message</option>
<option value="CONWQD">CONWQD Work item quantity determination message</option>
<option value="SSRECH">SSRECH Workers insurance history message</option>
    </Form.Control>
    <Form.Text>The message type to add.</Form.Text>
    </Form.Group>
    </Form>
        </Tab>
        <Tab eventKey="iso20022" title="ISO 20022">
    <Form>
    <Form.Group>
    <Form.Label>ISO 20022 Message type</Form.Label>
    <Form.Control
      as="select"
      type="text"
      name="messageType"
      onChange={onChange}
      placeholder="Enter message type"
    >
      <option value=""></option>

<option value="acmt.001.001.08">acmt.001.001.08
    AccountOpeningInstructionV08</option>
<option value="acmt.002.001.08">acmt.002.001.08
    AccountDetailsConfirmationV08</option>
<option value="acmt.003.001.08">acmt.003.001.08 AccountModificationInstructionV08</option>
<option value="acmt.005.001.06">acmt.005.001.06 RequestForAccountManagementStatusReportV06</option>
<option value="acmt.006.001.07">acmt.006.001.07
    AccountManagementStatusReportV07</option>
<option value="acmt.007.001.04">acmt.007.001.04
    AccountOpeningRequestV04</option>
<option value="acmt.008.001.04">acmt.008.001.04 AccountOpeningAmendmentRequestV04</option>
<option value="acmt.009.001.03">acmt.009.001.03 AccountOpeningAdditionalInformationRequestV03</option>
<option value="acmt.010.001.03">acmt.010.001.03
    AccountRequestAcknowledgementV03</option>
<option value="acmt.011.001.03">acmt.011.001.03
    AccountRequestRejectionV03</option>
<option value="acmt.012.001.03">acmt.012.001.03 AccountAdditionalInformationRequestV03</option>
<option value="acmt.013.001.03">acmt.013.001.03 AccountReportRequestV03</option>
<option value="acmt.014.001.04">acmt.014.001.04 AccountReportV04</option>
<option value="acmt.015.001.03">acmt.015.001.03 AccountExcludedMandateMaintenanceRequestV03</option>
<option value="acmt.016.001.03">acmt.016.001.03 AccountExcludedMandateMaintenanceAmendmentRequestV03</option>
<option value="acmt.017.001.03">acmt.017.001.03 AccountMandateMaintenanceRequestV03</option>
<option value="acmt.018.001.03">acmt.018.001.03 AccountMandateMaintenanceAmendmentRequestV03</option>
<option value="acmt.019.001.03">acmt.019.001.03
    AccountClosingRequestV03</option>
<option value="acmt.020.001.03">acmt.020.001.03 AccountClosingAmendmentRequestV03</option>
<option value="acmt.021.001.03">acmt.021.001.03 AccountClosingAdditionalInformationRequestV03</option>
<option value="acmt.022.001.03">acmt.022.001.03 IdentificationModificationAdviceV03</option>
<option value="acmt.023.001.03">acmt.023.001.03 IdentificationVerificationRequestV03</option>
<option value="acmt.024.001.03">acmt.024.001.03 IdentificationVerificationReportV03</option>
<option value="acmt.027.001.04">acmt.027.001.04 AccountSwitchInformationRequestV04</option>
<option value="acmt.028.001.04">acmt.028.001.04 AccountSwitchInformationResponseV04</option>
<option value="acmt.029.001.04">acmt.029.001.04 AccountSwitchCancelExistingPaymentV04</option>
<option value="acmt.030.001.03">acmt.030.001.03 AccountSwitchRequestRedirectionV03</option>
<option value="acmt.031.001.04">acmt.031.001.04 AccountSwitchRequestBalanceTransferV04</option>
<option value="acmt.032.001.04">acmt.032.001.04 AccountSwitchBalanceTransferAcknowledgementV04</option>
<option value="acmt.033.001.02">acmt.033.001.02 AccountSwitchNotifyAccountSwitchCompleteV02</option>
<option value="acmt.034.001.04">acmt.034.001.04
    AccountSwitchRequestPaymentV04</option>
<option value="acmt.035.001.02">acmt.035.001.02
    AccountSwitchPaymentResponseV02</option>
<option value="acmt.036.001.01">acmt.036.001.01 AccountSwitchTerminationSwitchV01</option>
<option value="acmt.037.001.02">acmt.037.001.02 AccountSwitchTechnicalRejectionV02</option>
<option value="admi.002.001.01">admi.002.001.01 MessageRejectV01</option>
<option value="admi.004.001.02">admi.004.001.02
    SystemEventNotificationV02</option>
<option value="admi.005.001.01">admi.005.001.01 ReportQueryRequestV01</option>
<option value="admi.006.001.01">admi.006.001.01 ResendRequestV01</option>
<option value="admi.007.001.01">admi.007.001.01
    ReceiptAcknowledgementV01</option>
<option value="admi.009.001.02">admi.009.001.02 StaticDataRequestV02</option>
<option value="admi.010.001.02">admi.010.001.02 StaticDataReportV02</option>
<option value="admi.011.001.01">admi.011.001.01
    SystemEventAcknowledgementV01</option>
<option value="admi.017.001.01">admi.017.001.01 ProcessingRequestV01</option>
<option value="auth.001.001.01">auth.001.001.01
    InformationRequestOpeningV01</option>
<option value="auth.002.001.01">auth.002.001.01
    InformationRequestResponseV01</option>
<option value="auth.003.001.01">auth.003.001.01 InformationRequestStatusCHangeNotificationV01</option>
<option value="auth.012.001.02">auth.012.001.02 MoneyMarketSecuredMarketStatisticalReportV02</option>
<option value="auth.013.001.02">auth.013.001.02 MoneyMarketUnsecuredMarketStatisticalReportV02</option>
<option value="auth.014.001.02">auth.014.001.02 MoneyMarketForeignExchangeSwapsStatisticalReportV02</option>
<option value="auth.015.001.02">auth.015.001.02 MoneyMarketOvernightIndexSwapsStatisticalReportV02</option>
<option value="auth.016.001.02">auth.016.001.02 FinancialInstrumentReportingTransactionReportV02</option>
<option value="auth.017.001.02">auth.017.001.02 FinancialInstrumentReportingReferenceDataReportV02</option>
<option value="auth.018.001.02">auth.018.001.02
    ContractRegistrationRequestV02</option>
<option value="auth.019.001.02">auth.019.001.02 ContractRegistrationConfirmationV02</option>
<option value="auth.020.001.02">auth.020.001.02 ContractRegistrationClosureRequestV02</option>
<option value="auth.021.001.02">auth.021.001.02 ContractRegistrationAmendmentRequestV02</option>
<option value="auth.022.001.02">auth.022.001.02
    ContractRegistrationStatementV02</option>
<option value="auth.023.001.02">auth.023.001.02 ContractRegistrationStatementRequestV02</option>
<option value="auth.024.001.02">auth.024.001.02 PaymentRegulatoryInformationNotificationV02</option>
<option value="auth.025.001.02">auth.025.001.02 CurrencyControlSupportingDocumentDeliveryV02</option>
<option value="auth.026.001.02">auth.026.001.02 CurrencyControlRequestOrLetterV02</option>
<option value="auth.027.001.02">auth.027.001.02
    CurrencyControlStatusAdviceV02</option>
<option value="auth.028.001.01">auth.028.001.01 MoneyMarketStatisticalReportStatusAdviceV01</option>
<option value="auth.029.001.02">auth.029.001.02
    DerivativesTradeReportQueryV02</option>
<option value="auth.030.001.02">auth.030.001.02
    DerivativesTradeReportV02</option>
<option value="auth.031.001.01">auth.031.001.01 FinancialInstrumentReportingStatusAdviceV01</option>
<option value="auth.032.001.01">auth.032.001.01 FinancialInstrumentReportingEquityTransparencyDataReportV01</option>
<option value="auth.033.001.02">auth.033.001.02 FinancialInstrumentReportingNonEquityTransparencyDataReportV02</option>
<option value="auth.034.001.01">auth.034.001.01 InvoiceTaxReportV01</option>
<option value="auth.035.001.01">auth.035.001.01 FinancialInstrumentReportingTradingVolumeCapDataReportV01</option>
<option value="auth.036.001.02">auth.036.001.02 FinancialInstrumentReportingReferenceDataDeltaReportV02</option>
<option value="auth.038.001.01">auth.038.001.01
    InvoiceTaxReportStatusAdviceV01</option>
<option value="auth.039.001.01">auth.039.001.01 FinancialInstrumentReportingNonWorkingDayReportV01</option>
<option value="auth.040.001.01">auth.040.001.01 FinancialInstrumentReportingEquityTradingActivityReportV01</option>
<option value="auth.041.001.01">auth.041.001.01 FinancialInstrumentReportingNonEquityTradingActivityReportV01</option>
<option value="auth.042.001.02">auth.042.001.02 FinancialInstrumentReportingInvalidReferenceDataReportV02</option>
<option value="auth.043.001.01">auth.043.001.01 FinancialInstrumentReportingReferenceDataIndexReportV01</option>
<option value="auth.044.001.02">auth.044.001.02 FinancialInstrumentReportingEquityTradingActivityResultV02</option>
<option value="auth.045.001.02">auth.045.001.02 FinancialInstrumentReportingNonEquityTradingActivityResultV02</option>
<option value="auth.047.001.01">auth.047.001.01 FinancialInstrumentReportingCountryCodeReportV01</option>
<option value="auth.048.001.01">auth.048.001.01 FinancialInstrumentReportingCurrencyCodeReportV01</option>
<option value="auth.049.001.02">auth.049.001.02 FinancialInstrumentReportingMarketIdentificationCodeReportV02</option>
<option value="auth.050.001.01">auth.050.001.01 FinancialInstrumentReportingInstrumentClassificationReportV01</option>
<option value="auth.052.001.01">auth.052.001.01 SecuritiesFinancingReportingTransactionReportV01</option>
<option value="auth.053.001.01">auth.053.001.01 FinancialInstrumentReportingTradingVolume CapResultReportV01</option>
<option value="auth.054.001.01">auth.054.001.01
    CCPClearingMemberReportV01</option>
<option value="auth.055.001.01">auth.055.001.01
    CCPMemberRequirementsReportV01</option>
<option value="auth.056.001.01">auth.056.001.01
    CCPMemberObligationsReportV01</option>
<option value="auth.057.001.01">auth.057.001.01 CCPPortfolioStressTestingDefinitionReportV01</option>
<option value="auth.058.001.01">auth.058.001.01 CCPPortfolioStressTestingResultReportV01</option>
<option value="auth.059.001.01">auth.059.001.01 CCPIncomeStatementAndCapitalAdequacyReportV01</option>
<option value="auth.060.001.01">auth.060.001.01
    CCPDailyCashFlowsReportV01</option>
<option value="auth.061.001.01">auth.061.001.01 CCPInvestmentsReportV01</option>
<option value="auth.062.001.01">auth.062.001.01 CCPLiquidityStressTestingDefinitionReportV01</option>
<option value="auth.063.001.01">auth.063.001.01 CCPLiquidityStressTestingResultReportV01</option>
<option value="auth.064.001.01">auth.064.001.01 CCPAvailableFinancialResourcesReportV01</option>
<option value="auth.065.001.01">auth.065.001.01 CCPBackTestingDefinitionReportV01</option>
<option value="auth.066.001.01">auth.066.001.01
    CCPBackTestingResultReportV01</option>
<option value="auth.067.001.01">auth.067.001.01 CCPCollateralReportV01</option>
<option value="auth.068.001.01">auth.068.001.01
    CCPAccountPositionReportV01</option>
<option value="auth.069.001.01">auth.069.001.01
    CCPClearedProductReportV01</option>
<option value="auth.070.001.01">auth.070.001.01 SecuritiesFinancingReportingTransactionMarginDataReportV01</option>
<option value="auth.071.001.01">auth.071.001.01 SecuritiesFinancingReportingTransactionReusedCollateralDataReportV01</option>
<option value="auth.072.001.01">auth.072.001.01
    SettlementInternaliserReportV01</option>
<option value="auth.076.001.01">auth.076.001.01 FinancialSupervisedPartyIdentityReportV01</option>
<option value="auth.077.001.01">auth.077.001.01
    FinancialBenchmarkReportV01</option>
<option value="auth.078.001.01">auth.078.001.01 SecuritiesFinancingReportingPairingRequestV01</option>
<option value="auth.079.001.01">auth.079.001.01 SecuritiesFinancingReportingTransactionStateReportV01</option>
<option value="auth.080.001.01">auth.080.001.01 SecuritiesFinancingReportingReconciliationStatusAdviceV01</option>
<option value="auth.083.001.01">auth.083.001.01 SecuritiesFinancingReportingMissingCollateralRequestV01</option>
<option value="auth.084.001.01">auth.084.001.01 SecuritiesFinancingReportingTransactionStatusAdviceV01</option>
<option value="auth.085.001.01">auth.085.001.01 SecuritiesFinancingReportingMarginDataTransactionStateReportV01</option>
<option value="auth.086.001.01">auth.086.001.01 SecuritiesFinancingReportingReusedCollateralDataTransactionStateReportV01</option>
<option value="auth.091.001.01">auth.091.001.01 DerivativesTradeReconciliationStatisticalReportV01</option>
<option value="auth.092.001.02">auth.092.001.02 DerivativesTradeRejectionStatisticalReportV02</option>
<option value="auth.094.001.01">auth.094.001.01 SecuritiesFinancingReportingTransactionQueryV01</option>
<option value="auth.100.001.01">auth.100.001.01
    SettlementFailsMonthlyReportV01</option>
<option value="auth.101.001.01">auth.101.001.01
    SettlementFailsAnnualReportV01</option>
<option value="auth.102.001.01">auth.102.001.01 FinancialInstrumentReportingCancellationRe portV01</option>
<option value="caaa.001.001.10">caaa.001.001.10
    AcceptorAuthorisationRequestV10</option>
<option value="caaa.002.001.10">caaa.002.001.10
    AcceptorAuthorisationResponseV10</option>
<option value="caaa.003.001.10">caaa.003.001.10
    AcceptorCompletionAdviceV10</option>
<option value="caaa.004.001.09">caaa.004.001.09 AcceptorCompletionAdviceResponseV09</option>
<option value="caaa.005.001.10">caaa.005.001.10
    AcceptorCancellationRequestV10</option>
<option value="caaa.006.001.09">caaa.006.001.09
    AcceptorCancellationResponseV09</option>
<option value="caaa.007.001.10">caaa.007.001.10
    AcceptorCancellationAdviceV10</option>
<option value="caaa.008.001.09">caaa.008.001.09 AcceptorCancellationAdviceResponseV09</option>
<option value="caaa.009.001.09">caaa.009.001.09
    AcceptorReconciliationRequestV09</option>
<option value="caaa.010.001.08">caaa.010.001.08 AcceptorReconciliationResponseV08</option>
<option value="caaa.011.001.10">caaa.011.001.10
    AcceptorBatchTransferV10</option>
<option value="caaa.012.001.09">caaa.012.001.09
    AcceptorBatchTransferResponseV09</option>
<option value="caaa.013.001.09">caaa.013.001.09
    AcceptorDiagnosticRequestV09</option>
<option value="caaa.014.001.08">caaa.014.001.08
    AcceptorDiagnosticResponseV08</option>
<option value="caaa.015.001.06">caaa.015.001.06 AcceptorRejectionV06</option>
<option value="caaa.016.001.08">caaa.016.001.08 AcceptorCurrencyConversionRequestV08</option>
<option value="caaa.017.001.08">caaa.017.001.08 AcceptorCurrencyConversionResponseV08</option>
<option value="caaa.018.001.05">caaa.018.001.05 AcceptorCurrencyConversionAdviceV05</option>
<option value="caaa.019.001.04">caaa.019.001.04 AcceptorCurrencyConversionAdviceResponseV04</option>
<option value="caaa.020.001.02">caaa.020.001.02 TransactionAdviceV02</option>
<option value="caaa.021.001.02">caaa.021.001.02
    TransactionAdviceResponseV02</option>
<option value="caaa.022.001.01">caaa.022.001.01
    AcceptorNonFinancialRequestV01</option>
<option value="caaa.023.001.01">caaa.023.001.01
    AcceptorNonFinancialResponseV01</option>
<option value="caaa.024.001.01">caaa.024.001.01 AcceptorTransactionLogReportRequestV01</option>
<option value="caaa.025.001.01">caaa.025.001.01 AcceptorTransactionLogReportResponseV01</option>
<option value="caad.001.001.01">caad.001.001.01
    BatchManagementInitiationV01</option>
<option value="caad.002.001.01">caad.002.001.01
    BatchManagementResponseV01</option>
<option value="caad.003.001.01">caad.003.001.01
    BatchTransferInitiationV01</option>
<option value="caad.004.001.01">caad.004.001.01
    BatchTransferResponseV01</option>
<option value="caad.005.001.02">caad.005.001.02
    ReconciliationInitiationV02</option>
<option value="caad.006.001.02">caad.006.001.02
    ReconciliationResponseV02</option>
<option value="caad.007.001.02">caad.007.001.02 ErrorV02</option>
<option value="caam.001.001.03">caam.001.001.03 ATMDeviceReportV03</option>
<option value="caam.002.001.03">caam.002.001.03 ATMDeviceControlV03</option>
<option value="caam.003.001.03">caam.003.001.03
    ATMKeyDownloadRequestV03</option>
<option value="caam.004.001.03">caam.004.001.03
    ATMKeyDownloadResponseV03</option>
<option value="caam.005.001.02">caam.005.001.02 ATMDiagnosticRequestV02</option>
<option value="caam.006.001.02">caam.006.001.02
    ATMDiagnosticResponseV02</option>
<option value="caam.007.001.01">caam.007.001.01 HostToATMRequestV01</option>
<option value="caam.008.001.01">caam.008.001.01
    HostToATMAcknowledgementV01</option>
<option value="caam.009.001.02">caam.009.001.02
    ATMReconciliationAdviceV02</option>
<option value="caam.010.001.02">caam.010.001.02 ATMReconciliationAcknowledgementV02</option>
<option value="caam.011.001.01">caam.011.001.01 ATMExceptionAdviceV01</option>
<option value="caam.012.001.01">caam.012.001.01
    ATMExceptionAcknowledgementV01</option>
<option value="cafc.001.001.01">cafc.001.001.01
    FeeCollectionInitiationV01</option>
<option value="cafc.002.001.01">cafc.002.001.01
    FeeCollectionResponseV01</option>
<option value="cafm.001.001.01">cafm.001.001.01 FileActionInitiationV01</option>
<option value="cafm.002.001.01">cafm.002.001.01 FileActionResponseV01</option>
<option value="cafr.001.001.01">cafr.001.001.01
    FraudReportingInitiationV01</option>
<option value="cafr.002.001.01">cafr.002.001.01
    FraudReportingResponseV01</option>
<option value="cafr.003.001.01">cafr.003.001.01
    FraudDispositionInitiationV01</option>
<option value="cafr.004.001.01">cafr.004.001.01
    FraudDispositionResponseV01</option>
<option value="cain.001.001.02">cain.001.001.02
    AuthorisationInitiationV02</option>
<option value="cain.002.001.02">cain.002.001.02
    AuthorisationResponseV02</option>
<option value="cain.003.001.02">cain.003.001.02 FinancialInitiationV02</option>
<option value="cain.004.001.02">cain.004.001.02 FinancialResponseV02</option>
<option value="cain.005.001.02">cain.005.001.02 ReversalInitiationV02</option>
<option value="cain.006.001.02">cain.006.001.02 ReversalResponseV02</option>
<option value="cain.014.001.01">cain.014.001.01
    RetrievalFulfilmentInitiationV01</option>
<option value="cain.015.001.01">cain.015.001.01
    RetrievalFulfilmentResponseV01</option>
<option value="cain.016.001.01">cain.016.001.01 InquiryInitiationV01</option>
<option value="cain.017.001.01">cain.017.001.01 InquiryResponseV01</option>
<option value="cain.018.001.01">cain.018.001.01
    VerificationInitiationV01</option>
<option value="cain.019.001.01">cain.019.001.01 VerificationResponseV01</option>
<option value="cain.020.001.01">cain.020.001.01 AmendmentV01</option>
<option value="cain.021.001.01">cain.021.001.01 RetrievalInitiationV01</option>
<option value="cain.022.001.01">cain.022.001.01 RetrievalResponseV01</option>
<option value="cain.023.001.01">cain.023.001.01
    CardManagementInitiationV01</option>
<option value="cain.024.001.01">cain.024.001.01
    CardManagementResponseV01</option>
<option value="cain.025.001.01">cain.025.001.01 AddendumInitiationV01</option>
<option value="cain.026.001.01">cain.026.001.01 AddendumResponseV01</option>
<option value="cain.027.001.01">cain.027.001.01 ChargeBackInitiationV01</option>
<option value="cain.028.001.01">cain.028.001.01 ChargeBackResponseV01</option>
<option value="camt.003.001.07">camt.003.001.07 GetAccountV07</option>
<option value="camt.004.001.09">camt.004.001.09 ReturnAccountV09</option>
<option value="camt.005.001.09">camt.005.001.09 GetTransactionV09</option>
<option value="camt.006.001.09">camt.006.001.09 ReturnTransactionV09</option>
<option value="camt.007.001.08">camt.007.001.08 ModifyTransactionV08</option>
<option value="camt.008.001.09">camt.008.001.09 CancelTransactionV09</option>
<option value="camt.009.001.07">camt.009.001.07 GetLimitV07</option>
<option value="camt.010.001.08">camt.010.001.08 ReturnLimitV08</option>
<option value="camt.011.001.07">camt.011.001.07 ModifyLimitV07</option>
<option value="camt.012.001.07">camt.012.001.07 DeleteLimitV07</option>
<option value="camt.013.001.04">camt.013.001.04 GetMemberV04</option>
<option value="camt.014.001.05">camt.014.001.05 ReturnMemberV05</option>
<option value="camt.015.001.04">camt.015.001.04 ModifyMemberV04</option>
<option value="camt.016.001.04">camt.016.001.04
    GetCurrencyExchangeRateV04</option>
<option value="camt.017.001.05">camt.017.001.05
    ReturnCurrencyExchangeRateV05</option>
<option value="camt.018.001.05">camt.018.001.05
    GetBusinessDayInformationV05</option>
<option value="camt.019.001.07">camt.019.001.07
    ReturnBusinessDayInformationV07</option>
<option value="camt.020.001.04">camt.020.001.04
    GetGeneralBusinessInformationV04</option>
<option value="camt.021.001.06">camt.021.001.06 ReturnGeneralBusinessInformationV06</option>
<option value="camt.023.001.07">camt.023.001.07 BackupPaymentV07</option>
<option value="camt.024.001.07">camt.024.001.07 ModifyStandingOrderV07</option>
<option value="camt.025.001.05">camt.025.001.05 ReceiptV05</option>
<option value="camt.026.001.09">camt.026.001.09 UnableToApplyV09</option>
<option value="camt.027.001.09">camt.027.001.09 ClaimNonReceiptV09</option>
<option value="camt.028.001.11">camt.028.001.11
    AdditionalPaymentInformationV11</option>
<option value="camt.029.001.11">camt.029.001.11
    ResolutionOfInvestigationV11</option>
<option value="camt.030.001.05">camt.030.001.05
    NotificationOfCaseAssignmentV05</option>
<option value="camt.031.001.06">camt.031.001.06 RejectInvestigationV06</option>
<option value="camt.032.001.04">camt.032.001.04 CancelCaseAssignmentV04</option>
<option value="camt.033.001.06">camt.033.001.06 RequestForDuplicateV06</option>
<option value="camt.034.001.06">camt.034.001.06 DuplicateV06</option>
<option value="camt.035.001.05">camt.035.001.05 ProprietaryFormatInvestigationV05</option>
<option value="camt.036.001.05">camt.036.001.05
    DebitAuthorisationResponseV05</option>
<option value="camt.037.001.09">camt.037.001.09
    DebitAuthorisationRequestV09</option>
<option value="camt.038.001.04">camt.038.001.04
    CaseStatusReportRequestV04</option>
<option value="camt.039.001.05">camt.039.001.05 CaseStatusReportV05</option>
<option value="camt.040.001.04">camt.040.001.04 FundEstimatedCashForecastReportV04</option>
<option value="camt.041.001.04">camt.041.001.04 FundConfirmedCashForecastReportV04</option>
<option value="camt.042.001.04">camt.042.001.04 FundDetailedEstimatedCashForecastReportV04</option>
<option value="camt.043.001.04">camt.043.001.04 FundDetailedConfirmedCashForecastReportV04</option>
<option value="camt.044.001.03">camt.044.001.03 FundConfirmedCashForecastReportCancellationV03</option>
<option value="camt.045.001.03">camt.045.001.03 FundDetailedConfirmedCashForecastReportCancellationV03</option>
<option value="camt.046.001.06">camt.046.001.06 GetReservationV06</option>
<option value="camt.047.001.07">camt.047.001.07 ReturnReservationV07</option>
<option value="camt.048.001.06">camt.048.001.06 ModifyReservationV06</option>
<option value="camt.049.001.06">camt.049.001.06 DeleteReservationV06</option>
<option value="camt.050.001.06">camt.050.001.06
    LiquidityCreditTransferV06</option>
<option value="camt.051.001.06">camt.051.001.06
    LiquidityDebitTransferV06</option>
<option value="camt.052.001.09">camt.052.001.09
    BankToCustomerAccountReportV09</option>
<option value="camt.053.001.09">camt.053.001.09
    BankToCustomerStatementV09</option>
<option value="camt.054.001.09">camt.054.001.09 BankToCustomerDebitCreditNotificationV09</option>
<option value="camt.055.001.10">camt.055.001.10 CustomerPaymentCancellationRequestV10</option>
<option value="camt.056.001.10">camt.056.001.10 FIToFIPaymentCancellationRequestV10</option>
<option value="camt.057.001.07">camt.057.001.07
    NotificationToReceiveV07</option>
<option value="camt.058.001.07">camt.058.001.07 NotificationToReceiveCancellationAdviceV07</option>
<option value="camt.059.001.07">camt.059.001.07 NotificationToReceiveStatusReportV07</option>
<option value="camt.060.001.06">camt.060.001.06
    AccountReportingRequestV06</option>
<option value="camt.061.001.02">camt.061.001.02 PayInCallV02</option>
<option value="camt.062.001.03">camt.062.001.03 PayInScheduleV03</option>
<option value="camt.063.001.02">camt.063.001.02
    PayInEventAcknowledgementV02</option>
<option value="camt.066.001.01">camt.066.001.01 IntraBalanceMovementInstructionV01</option>
<option value="camt.067.001.01">camt.067.001.01 IntraBalanceMovementStatusAdviceV01</option>
<option value="camt.068.001.01">camt.068.001.01 IntraBalanceMovementConfirmationV01</option>
<option value="camt.069.001.04">camt.069.001.04 GetStandingOrderV04</option>
<option value="camt.070.001.05">camt.070.001.05 ReturnStandingOrderV05</option>
<option value="camt.071.001.04">camt.071.001.04 DeleteStandingOrderV04</option>
<option value="camt.072.001.01">camt.072.001.01 IntraBalanceMovementModificationRequestV01</option>
<option value="camt.073.001.01">camt.073.001.01 IntraBalanceMovementModificationRequestStatusAdviceV01</option>
<option value="camt.074.001.01">camt.074.001.01 IntraBalanceMovementCancellationRequestV01</option>
<option value="camt.075.001.01">camt.075.001.01 IntraBalanceMovementCancellationRequestStatusAdviceV01</option>
<option value="camt.078.001.01">camt.078.001.01
    IntraBalanceMovementQueryV01</option>
<option value="camt.079.001.01">camt.079.001.01 IntraBalanceMovementQueryResponseV01</option>
<option value="camt.080.001.01">camt.080.001.01 IntraBalanceMovementModificationQueryV01</option>
<option value="camt.081.001.01">camt.081.001.01 IntraBalanceMovementModificationReportV01</option>
<option value="camt.082.001.01">camt.082.001.01 IntraBalanceMovementCancellationQueryV01</option>
<option value="camt.083.001.01">camt.083.001.01 IntraBalanceMovementCancellationReportV01</option>
<option value="camt.084.001.01">camt.084.001.01 IntraBalanceMovementPostingReportV01</option>
<option value="camt.085.001.01">camt.085.001.01 IntraBalanceMovementPendingReportV01</option>
<option value="camt.086.001.04">camt.086.001.04
    BankServicesBillingStatementV04</option>
<option value="camt.087.001.08">camt.087.001.08
    RequestToModifyPaymentV08</option>
<option value="camt.088.001.01">camt.088.001.01 NetReportV01</option>
<option value="camt.101.001.01">camt.101.001.01 CreateLimitV01</option>
<option value="camt.102.001.02">camt.102.001.02 CreateStandingOrderV02</option>
<option value="camt.103.001.02">camt.103.001.02 CreateReservationV02</option>
<option value="camt.104.001.01">camt.104.001.01 CreateMemberV01</option>
<option value="canm.001.001.02">canm.001.001.02
    NetworkManagementInitiationV02</option>
<option value="canm.002.001.02">canm.002.001.02
    NetworkManagementResponseV02</option>
<option value="canm.003.001.02">canm.003.001.02
    KeyExchangeInitiationV02</option>
<option value="canm.004.001.02">canm.004.001.02 KeyExchangeResponseV02</option>
<option value="casp.001.001.03">casp.001.001.03
    SaleToPOIServiceRequestV03</option>
<option value="casp.002.001.03">casp.002.001.03
    SaleToPOIServiceResponseV03</option>
<option value="casp.003.001.03">casp.003.001.03 SaleToPOIReconciliationRequestV03</option>
<option value="casp.004.001.03">casp.004.001.03 SaleToPOIReconciliationResponseV03</option>
<option value="casp.005.001.03">casp.005.001.03 SaleToPOISessionManagementRequestV03</option>
<option value="casp.006.001.03">casp.006.001.03 SaleToPOISessionManagementResponseV03</option>
<option value="casp.007.001.03">casp.007.001.03 SaleToPOIAdministrativeRequestV03</option>
<option value="casp.008.001.03">casp.008.001.03 SaleToPOIAdministrativeResponseV03</option>
<option value="casp.009.001.03">casp.009.001.03
    SaleToPOIReportRequestV03</option>
<option value="casp.010.001.03">casp.010.001.03
    SaleToPOIReportResponseV03</option>
<option value="casp.011.001.03">casp.011.001.03 SaleToPOIAbortV03</option>
<option value="casp.012.001.03">casp.012.001.03
    SaleToPOIEventNotificationV03</option>
<option value="casp.013.001.02">casp.013.001.02
    SaleToPOIMessageRejectionV02</option>
<option value="casp.014.001.03">casp.014.001.03
    SaleToPOIMessageStatusRequestV03</option>
<option value="casp.015.001.03">casp.015.001.03 SaleToPOIMessageStatusResponseV03</option>
<option value="casp.016.001.03">casp.016.001.03
    SaleToPOIDeviceRequestV03</option>
<option value="casp.017.001.03">casp.017.001.03
    SaleToPOIDeviceResponseV03</option>
<option value="casr.001.001.01">casr.001.001.01
    SettlementReportingInitiationV01</option>
<option value="casr.002.001.01">casr.002.001.01
    SettlementReportingResponseV01</option>
<option value="catm.001.001.03">catm.001.001.03 StatusReportV03</option>
<option value="catm.001.001.10">catm.001.001.10 StatusReportV10</option>
<option value="catm.002.001.09">catm.002.001.09
    ManagementPlanReplacementV09</option>
<option value="catm.003.001.10">catm.003.001.10
    AcceptorConfigurationUpdateV10</option>
<option value="catm.004.001.05">catm.004.001.05
    TerminalManagementRejectionV05</option>
<option value="catm.005.001.07">catm.005.001.07
    MaintenanceDelegationRequestV07</option>
<option value="catm.006.001.05">catm.006.001.05
    MaintenanceDelegationResponseV05</option>
<option value="catm.007.001.04">catm.007.001.04
    CertificateManagementRequestV04</option>
<option value="catm.008.001.04">catm.008.001.04
    CertificateManagementResponseV04</option>
<option value="catp.001.001.02">catp.001.001.02 ATMWithdrawalRequestV02</option>
<option value="catp.002.001.02">catp.002.001.02
    ATMWithdrawalResponseV02</option>
<option value="catp.003.001.02">catp.003.001.02
    ATMWithdrawalCompletionAdviceV02</option>
<option value="catp.004.001.02">catp.004.001.02 ATMWithdrawalCompletionAcknowledgementV02</option>
<option value="catp.005.001.02">catp.005.001.02 ATMRejectV02</option>
<option value="catp.006.001.02">catp.006.001.02 ATMInquiryRequestV02</option>
<option value="catp.007.001.02">catp.007.001.02 ATMInquiryResponseV02</option>
<option value="catp.008.001.02">catp.008.001.02 ATMCompletionAdviceV02</option>
<option value="catp.009.001.02">catp.009.001.02
    ATMCompletionAcknowledgementV02</option>
<option value="catp.010.001.02">catp.010.001.02
    ATMPINManagementRequestV02</option>
<option value="catp.011.001.02">catp.011.001.02
    ATMPINManagementResponseV02</option>
<option value="catp.012.001.01">catp.012.001.01 ATMDepositRequestV01</option>
<option value="catp.013.001.01">catp.013.001.01 ATMDepositResponseV01</option>
<option value="catp.014.001.01">catp.014.001.01
    ATMDepositCompletionAdviceV01</option>
<option value="catp.015.001.01">catp.015.001.01 ATMDepositCompletionAcknowledgementV01</option>
<option value="catp.016.001.01">catp.016.001.01 ATMTransferRequestV01</option>
<option value="catp.017.001.01">catp.017.001.01 ATMTransferResponseV01</option>
<option value="colr.001.001.01">colr.001.001.01 CollateralValueQueryV01</option>
<option value="colr.002.001.01">colr.002.001.01
    CollateralValueReportV01</option>
<option value="colr.003.001.04">colr.003.001.04 MarginCallRequestV04</option>
<option value="colr.004.001.04">colr.004.001.04 MarginCallResponseV04</option>
<option value="colr.005.001.05">colr.005.001.05 CollateralManagementCancellationRequestV05</option>
<option value="colr.006.001.04">colr.006.001.04 CollateralManagementCancellationStatusV04</option>
<option value="colr.007.001.05">colr.007.001.05 CollateralProposalV05</option>
<option value="colr.008.001.05">colr.008.001.05
    CollateralProposalResponseV05</option>
<option value="colr.009.001.04">colr.009.001.04
    MarginCallDisputeNotificationV04</option>
<option value="colr.010.001.04">colr.010.001.04
    CollateralSubstitutionRequestV04</option>
<option value="colr.011.001.04">colr.011.001.04 CollateralSubstitutionResponseV04</option>
<option value="colr.012.001.04">colr.012.001.04 CollateralSubstitutionConfirmationV04</option>
<option value="colr.013.001.04">colr.013.001.04
    InterestPaymentRequestV04</option>
<option value="colr.014.001.04">colr.014.001.04
    InterestPaymentResponseV04</option>
<option value="colr.015.001.04">colr.015.001.04
    InterestPaymentStatementV04</option>
<option value="colr.016.001.04">colr.016.001.04
    CollateralAndExposureReportV04</option>
<option value="fxtr.008.001.06">fxtr.008.001.06 ForeignExchangeTradeStatusNotificationV06</option>
<option value="fxtr.013.001.03">fxtr.013.001.03 ForeignExchangeTradeWithdrawalNotificationV03</option>
<option value="fxtr.014.001.04">fxtr.014.001.04 ForeignExchangeTradeInstructionV04</option>
<option value="fxtr.015.001.04">fxtr.015.001.04 ForeignExchangeTradeInstructionAmendmentV04</option>
<option value="fxtr.016.001.04">fxtr.016.001.04 ForeignExchangeTradeInstructionCancellationV04</option>
<option value="fxtr.017.001.04">fxtr.017.001.04 ForeignExchangeTradeStatusAndDetailsNotificationV04</option>
<option value="fxtr.030.001.04">fxtr.030.001.04 ForeignExchangeTradeBulkStatusNotificationV04</option>
<option value="fxtr.031.001.01">fxtr.031.001.01 ForeignExchangeTradeCaptureReportV01</option>
<option value="fxtr.032.001.01">fxtr.032.001.01 ForeignExchangeTradeCaptureReportRequestV01</option>
<option value="fxtr.033.001.01">fxtr.033.001.01 ForeignExchangeTradeCaptureReportAcknowledgementV01</option>
<option value="fxtr.034.001.01">fxtr.034.001.01 ForeignExchangeTradeConfirmationRequestV01</option>
<option value="fxtr.035.001.01">fxtr.035.001.01 ForeignExchangeTradeConfirmationRequestAmendmentRequestV01</option>
<option value="fxtr.036.001.01">fxtr.036.001.01 ForeignExchangeTradeConfirmationRequestCancellationRequestV01</option>
<option value="fxtr.037.001.01">fxtr.037.001.01 ForeignExchangeTradeConfirmationStatusAdviceV01</option>
<option value="fxtr.038.001.01">fxtr.038.001.01 ForeignExchangeTradeConfirmationStausAdviceAcknowledgementV01</option>
<option value="head.001.001.02">head.001.001.02
    BusinessApplicationHeaderV02</option>
<option value="pacs.002.001.12">pacs.002.001.12
    FIToFIPaymentStatusReportV12</option>
<option value="pacs.003.001.09">pacs.003.001.09
    FIToFICustomerDirectDebitV09</option>
<option value="pacs.004.001.11">pacs.004.001.11 PaymentReturnV11</option>
<option value="pacs.007.001.11">pacs.007.001.11
    FIToFIPaymentReversalV11</option>
<option value="pacs.008.001.10">pacs.008.001.10
    FIToFICustomerCreditTransferV10</option>
<option value="pacs.009.001.10">pacs.009.001.10 FinancialInstitutionCreditTransferV10</option>
<option value="pacs.010.001.05">pacs.010.001.05 FinancialInstitutionDirectDebitV05</option>
<option value="pacs.028.001.05">pacs.028.001.05
    FIToFIPaymentStatusRequestV05</option>
<option value="pain.001.001.11">pain.001.001.11 CustomerCreditTransferInitiationV11</option>
<option value="pain.002.001.02">pain.002.001.02 CustomerPaymentStatusReportV12</option>
<option value="pain.002.001.12">pain.002.001.12
    CustomerPaymentStatusReportV12</option>
<option value="pain.007.001.11">pain.007.001.11
    CustomerPaymentReversalV11</option>
<option value="pain.008.001.10">pain.008.001.10
    CustomerDirectDebitInitiationV10</option>
<option value="pain.009.001.07">pain.009.001.07
    MandateInitiationRequestV07</option>
<option value="pain.010.001.07">pain.010.001.07
    MandateAmendmentRequestV07</option>
<option value="pain.011.001.07">pain.011.001.07
    MandateCancellationRequestV07</option>
<option value="pain.012.001.07">pain.012.001.07
    MandateAcceptanceReportV07</option>
<option value="pain.013.001.09">pain.013.001.09 CreditorPaymentActivationRequestV09</option>
<option value="pain.014.001.09">pain.014.001.09 CreditorPaymentActivationRequestStatusReportV09</option>
<option value="pain.017.001.03">pain.017.001.03 MandateCopyRequestV03</option>
<option value="pain.018.001.03">pain.018.001.03
    MandateSuspensionRequestV03</option>
<option value="reda.001.001.04">reda.001.001.04 PriceReportV04</option>
<option value="reda.002.001.04">reda.002.001.04
    PriceReportCancellationV04</option>
<option value="reda.004.001.05">reda.004.001.05
    FundReferenceDataReportV05</option>
<option value="reda.005.001.03">reda.005.001.03
    InvestmentFundReportRequestV03</option>
<option value="reda.014.001.01">reda.014.001.01 PartyCreationRequestV01</option>
<option value="reda.015.001.01">reda.015.001.01 PartyQueryV01</option>
<option value="reda.016.001.01">reda.016.001.01 PartyStatusAdviceV01</option>
<option value="reda.017.001.01">reda.017.001.01 PartyReportV01</option>
<option value="reda.018.001.01">reda.018.001.01 SecuritiesAccountCreationRequestV01</option>
<option value="reda.019.001.01">reda.019.001.01
    SecuritiesAccountQueryV01</option>
<option value="reda.020.001.01">reda.020.001.01
    SecuritiesAccountStatusAdviceV01</option>
<option value="reda.021.001.01">reda.021.001.01
    SecuritiesAccountReportV01</option>
<option value="reda.022.001.01">reda.022.001.01
    PartyModificationRequestV01</option>
<option value="reda.023.001.01">reda.023.001.01 SecuritiesAccountModificationRequestV01</option>
<option value="reda.031.001.01">reda.031.001.01 PartyDeletionRequestV01</option>
<option value="reda.032.001.01">reda.032.001.01 SecuritiesAccountDeletionRequestV01</option>
<option value="reda.035.001.01">reda.035.001.01 SecuritiesAccountActivityAdviceV01</option>
<option value="reda.036.001.01">reda.036.001.01 SecuritiesAccountAuditTrailQueryV01</option>
<option value="reda.037.001.01">reda.037.001.01 SecuritiesAccountAuditTrailReportV01</option>
<option value="reda.041.001.01">reda.041.001.01 PartyActivityAdviceV01</option>
<option value="reda.042.001.01">reda.042.001.01 PartyAuditTrailQueryV01</option>
<option value="reda.043.001.01">reda.043.001.01
    PartyAuditTrailReportV01</option>
<option value="reda.056.001.01">reda.056.001.01
    StandingSettlementInstructionV01</option>
<option value="reda.057.001.01">reda.057.001.01 StandingSettlementInstructionDeletionV01</option>
<option value="reda.058.001.01">reda.058.001.01 StandingSettlementInstructionStatusAdviceV01</option>
<option value="reda.059.001.01">reda.059.001.01 StandingSettlementInstructionCancellationV01</option>
<option value="reda.060.001.01">reda.060.001.01 NettingCutOffReferenceDataUpdateRequestV01</option>
<option value="reda.061.001.01">reda.061.001.01 NettingCutOffReferenceDataReportV01</option>
<option value="reda.066.001.01">reda.066.001.01 RequestToPayCreditorEnrolmentRequestV01</option>
<option value="reda.067.001.01">reda.067.001.01 RequestToPayCreditorEnrolmentAmendmentRequestV01</option>
<option value="reda.068.001.01">reda.068.001.01 RequestToPayCreditorEnrolmentCancellationRequestV01</option>
<option value="reda.069.001.01">reda.069.001.01 RequestToPayCreditorEnrolmentStatusReportV01</option>
<option value="reda.070.001.01">reda.070.001.01 RequestToPayDebtorActivationRequestV01</option>
<option value="reda.071.001.01">reda.071.001.01 RequestToPayDebtorActivationAmendmentRequestV01</option>
<option value="reda.072.001.01">reda.072.001.01 RequestToPayDebtorActivationCancellationRequestV01</option>
<option value="reda.073.001.01">reda.073.001.01 RequestToPayDebtorActivationStatusReportV01</option>
<option value="remt.001.001.05">remt.001.001.05 RemittanceAdviceV05</option>
<option value="remt.002.001.02">remt.002.001.02
    RemittanceLocationAdviceV02</option>
<option value="secl.001.001.03">secl.001.001.03 TradeLegNotificationV03</option>
<option value="secl.002.001.03">secl.002.001.03 TradeLegNotificationCancellationV03</option>
<option value="secl.003.001.03">secl.003.001.03 TradeLegStatementV03</option>
<option value="secl.004.001.03">secl.004.001.03 NetPositionV03</option>
<option value="secl.005.001.02">secl.005.001.02 MarginReportV02</option>
<option value="secl.006.001.02">secl.006.001.02
    DefaultFundContributionReportV02</option>
<option value="secl.007.001.03">secl.007.001.03 BuyInNotificationV03</option>
<option value="secl.008.001.03">secl.008.001.03 BuyInResponseV03</option>
<option value="secl.009.001.03">secl.009.001.03 BuyInConfirmationV03</option>
<option value="secl.010.001.03">secl.010.001.03
    SettlementObligationReportV03</option>
<option value="seev.001.001.08">seev.001.001.08 MeetingNotificationV08</option>
<option value="seev.002.001.07">seev.002.001.07 MeetingCancellationV07</option>
<option value="seev.003.001.07">seev.003.001.07 MeetingEntitlementNotificationV07</option>
<option value="seev.004.001.07">seev.004.001.07 MeetingInstructionV07</option>
<option value="seev.005.001.07">seev.005.001.07 MeetingInstructionCancellationRequestV07</option>
<option value="seev.006.001.07">seev.006.001.07
    MeetingInstructionStatusV07</option>
<option value="seev.007.001.07">seev.007.001.07 MeetingVoteExecutionConfirmationV07</option>
<option value="seev.008.001.07">seev.008.001.07
    MeetingResultDisseminationV07</option>
<option value="seev.009.001.01">seev.009.001.01
    AgentCANotificationAdviceV01</option>
<option value="seev.010.001.01">seev.010.001.01 AgentCANotificationCancellationRequestV01</option>
<option value="seev.011.001.01">seev.011.001.01 AgentCANotificationStatusAdviceV01</option>
<option value="seev.012.001.01">seev.012.001.01
    AgentCAElectionAdviceV01</option>
<option value="seev.013.001.01">seev.013.001.01 AgentCAElectionAmendmentRequestV01</option>
<option value="seev.014.001.01">seev.014.001.01 AgentCAElectionCancellationRequestV01</option>
<option value="seev.015.001.01">seev.015.001.01
    AgentCAElectionStatusAdviceV01</option>
<option value="seev.016.001.01">seev.016.001.01 AgentCADistributionBreakdownAdviceV01</option>
<option value="seev.017.001.01">seev.017.001.01 AgentCAGlobalDistributionAuthorisationRequestV01</option>
<option value="seev.018.001.01">seev.018.001.01 AgentCAGlobalDistributionStatusAdviceV01</option>
<option value="seev.019.001.01">seev.019.001.01
    AgentCAMovementInstructionV01</option>
<option value="seev.020.001.01">seev.020.001.01 AgentCAMovementCancellationRequestV01</option>
<option value="seev.021.001.01">seev.021.001.01
    AgentCAMovementConfirmationV01</option>
<option value="seev.022.001.01">seev.022.001.01
    AgentCAMovementStatusAdviceV01</option>
<option value="seev.023.001.01">seev.023.001.01
    AgentCAInformationAdviceV01</option>
<option value="seev.024.001.01">seev.024.001.01 AgentCAInformationStatusAdviceV01</option>
<option value="seev.025.001.01">seev.025.001.01 AgentCAStandingInstructionRequestV01</option>
<option value="seev.026.001.01">seev.026.001.01 AgentCAStandingInstructionCancellationRequestV01</option>
<option value="seev.027.001.01">seev.027.001.01 AgentCAStandingInstructionStatusAdviceV01</option>
<option value="seev.028.001.01">seev.028.001.01 AgentCADeactivationInstructionV01</option>
<option value="seev.029.001.01">seev.029.001.01 AgentCADeactivationCancellationRequestV01</option>
<option value="seev.030.001.01">seev.030.001.01 AgentCADeactivationStatusAdviceV01</option>
<option value="seev.031.001.11">seev.031.001.11
    CorporateActionNotificationV10</option>
<option value="seev.031.002.11">seev.031.002.11 CorporateActionNotification002V11</option>
<option value="seev.032.001.08">seev.032.001.08 CorporateActionEventProcessingStatusAdviceV08</option>
<option value="seev.032.002.08">seev.032.002.08 CorporateActionEventProcessingStatusAdvice002V08</option>
<option value="seev.033.001.11">seev.033.001.11
    CorporateActionInstructionV11</option>
<option value="seev.033.002.11">seev.033.002.11
    CorporateActionInstruction002V11</option>
<option value="seev.034.001.12">seev.034.001.12 CorporateActionInstructionStatusAdviceV12</option>
<option value="seev.034.002.12">seev.034.002.12 CorporateActionInstructionStatusAdvice002V12</option>
<option value="seev.035.001.12">seev.035.001.12 CorporateActionMovementPreliminaryAdviceV12</option>
<option value="seev.035.002.12">seev.035.002.12 CorporateActionMovementPreliminaryAdvice002V12</option>
<option value="seev.036.001.12">seev.036.001.12 CorporateActionMovementConfirmationV12</option>
<option value="seev.036.002.12">seev.036.002.12 CorporateActionMovementConfirmation002V12</option>
<option value="seev.037.001.12">seev.037.001.12 CorporateActionMovementReversalAdviceV12</option>
<option value="seev.037.002.12">seev.037.002.12 CorporateActionMovementReversalAdvice002V12</option>
<option value="seev.038.001.06">seev.038.001.06
    CorporateActionNarrativeV06</option>
<option value="seev.038.002.06">seev.038.002.06
    CorporateActionNarrative002V06</option>
<option value="seev.039.001.10">seev.039.001.10 CorporateActionCancellationAdviceV10</option>
<option value="seev.039.002.10">seev.039.002.10 CorporateActionCancellationAdvice002V10</option>
<option value="seev.040.001.10">seev.040.001.10 CorporateActionInstructionCancellationRequestV10</option>
<option value="seev.040.002.10">seev.040.002.10 CorporateActionInstructionCancellationRequest002V10</option>
<option value="seev.041.001.11">seev.041.001.11 CorporateActionInstructionCancellationRequestStatusAdviceV11</option>
<option value="seev.041.002.11">seev.041.002.11 CorporateActionInstructionCancellationRequestStatusAdvice002V11</option>
<option value="seev.042.001.10">seev.042.001.10 CorporateActionInstructionStatementReportV10</option>
<option value="seev.042.002.10">seev.042.002.10 CorporateActionInstructionStatementReport002V10</option>
<option value="seev.044.001.10">seev.044.001.10 CorporateActionMovementPreliminaryAdviceCancellationAdviceV10</option>
<option value="seev.044.002.10">seev.044.002.10 CorporateActionMovementPreliminaryAdviceCancellationAdvice002V10</option>
<option value="seev.045.001.03">seev.045.001.03 ShareholdersIdentificationDisclosureRequestV03</option>
<option value="seev.046.001.01">seev.046.001.01 ShareholdersIdentificationDisclosureRequestCancellationAdviceV01</option>
<option value="seev.047.001.02">seev.047.001.02 ShareholdersIdentificationDisclosureResponseV02</option>
<option value="seev.048.001.01">seev.048.001.01 ShareholderIdentificationDisclosureResponseCancellationAdviceV01</option>
<option value="seev.049.001.01">seev.049.001.01 ShareholderIdentificationDisclosureResponseStatusAdviceV01</option>
<option value="seev.050.001.01">seev.050.001.01 MarketClaimCreationV01</option>
<option value="seev.051.001.01">seev.051.001.01 MarketClaimCancellationRequestV01</option>
<option value="seev.052.001.01">seev.052.001.01
    MarketClaimStatusAdviceV01</option>
<option value="seev.053.001.01">seev.053.001.01 MarketClaimCancellationRequestStatusAdviceV01</option>
<option value="semt.001.001.03">semt.001.001.03
    SecuritiesMessageRejectionV03</option>
<option value="semt.002.001.10">semt.002.001.10 SecuritiesBalanceCustodyReportV10</option>
<option value="semt.002.001.10">semt.002.001.10 SecuritiesBalanceCustodyReportV10</option>
<option value="semt.002.002.10">semt.002.002.10 SecuritiesBalanceCustodyReport002V10</option>
<option value="semt.002.002.10">semt.002.002.10 SecuritiesBalanceCustodyReport002V10</option>
<option value="semt.003.001.02">semt.003.001.02
    AccountingStatementOfHoldingsV02</option>
<option value="semt.003.001.10">semt.003.001.10 SecuritiesBalanceAccountingReportV10</option>
<option value="semt.003.001.10">semt.003.001.10 SecuritiesBalanceAccountingReportV10</option>
<option value="semt.004.001.02">semt.004.001.02 CustodyStatementOfHoldingsCancellationV02</option>
<option value="semt.005.001.02">semt.005.001.02 AccountingStatementOfHoldingsCancellationV02</option>
<option value="semt.006.001.03">semt.006.001.03 StatementOfInvestmentFundTransactionsV03</option>
<option value="semt.007.001.03">semt.007.001.03 StatementOfInvestmentFundTransactionsCancellationV03</option>
<option value="semt.007.001.03">semt.007.001.03 StatementOfInvestmentFundTransactionsCancellationV03</option>
<option value="semt.013.002.05">semt.013.002.05 IntraPositionMovementInstruction002V05</option>
<option value="semt.014.002.06">semt.014.002.06 IntraPositionMovementStatusAdvice002V06</option>
<option value="semt.015.001.08">semt.015.001.08 IntraPositionMovementConfirmationV08</option>
<option value="semt.015.002.08">semt.015.002.08 IntraPositionMovementConfirmation002V08</option>
<option value="semt.016.001.08">semt.016.001.08 IntraPositionMovementPostingReportV08</option>
<option value="semt.016.002.08">semt.016.002.08 IntraPositionMovementPostingReport002V08</option>
<option value="semt.017.001.11">semt.017.001.11 SecuritiesTransactionPostingReportV11</option>
<option value="semt.017.002.11">semt.017.002.11 SecuritiesTransactionPostingReport002V11</option>
<option value="semt.018.001.12">semt.018.001.12 SecuritiesTransactionPendingReportV12</option>
<option value="semt.018.002.12">semt.018.002.12 SecuritiesTransactionPendingReport002V12</option>
<option value="semt.018.002.12">semt.018.002.12 SecuritiesTransactionPendingReport002V12</option>
<option value="semt.019.001.09">semt.019.001.09 SecuritiesSettlementTransactionAllegementReportV09</option>
<option value="semt.019.001.09">semt.019.001.09 SecuritiesSettlementTransactionAllegementReportV09</option>
<option value="semt.019.002.09">semt.019.002.09 SecuritiesSettlementTransactionAllegementReport002V09</option>
<option value="semt.019.002.09">semt.019.002.09 SecuritiesSettlementTransactionAllegementReport002V09</option>
<option value="semt.020.001.06">semt.020.001.06 SecuritiesMessageCancellationAdviceV06</option>
<option value="semt.020.001.06">semt.020.001.06 SecuritiesMessageCancellationAdviceV06</option>
<option value="semt.021.002.07">semt.021.002.07
    SecuritiesStatementQuery002V07</option>
<option value="semt.022.002.04">semt.022.002.04 SecuritiesSettlementTransactionAuditTrailReport002V04</option>
<option value="semt.023.001.01">semt.023.001.01
    SecuritiesEndOfProcessReportV01</option>
<option value="semt.041.001.02">semt.041.001.02 SecuritiesBalanceTransparencyReportV02</option>
<option value="semt.042.001.01">semt.042.001.01 SecuritiesBalanceTransparencyReportStatusAdviceV01</option>
<option value="sese.001.001.09">sese.001.001.09
    TransferOutInstructionV09</option>
<option value="sese.002.001.09">sese.002.001.09 TransferOutCancellationRequestV09</option>
<option value="sese.003.001.09">sese.003.001.09
    TransferOutConfirmationV09</option>
<option value="sese.004.001.09">sese.004.001.09 ReversalOfTransferOutConfirmationV09</option>
<option value="sese.005.001.09">sese.005.001.09
    TransferInInstructionV09</option>
<option value="sese.006.001.09">sese.006.001.09
    TransferInCancellationRequestV09</option>
<option value="sese.007.001.09">sese.007.001.09
    TransferInConfirmationV09</option>
<option value="sese.008.001.09">sese.008.001.09 ReversalOfTransferInConfirmationV09</option>
<option value="sese.009.001.07">sese.009.001.07 RequestForTransferStatusReportV07</option>
<option value="sese.010.001.07">sese.010.001.07 TransferCancellationStatusReportV07</option>
<option value="sese.011.001.08">sese.011.001.08 TransferInstructionStatusReportV08</option>
<option value="sese.012.001.10">sese.012.001.10
    PortfolioTransferInstructionV10</option>
<option value="sese.013.001.10">sese.013.001.10
    PortfolioTransferConfirmationV10</option>
<option value="sese.014.001.09">sese.014.001.09 PortfolioTransferCancellationRequestV09</option>
<option value="sese.018.001.08">sese.018.001.08
    AccountHoldingInformationV08</option>
<option value="sese.019.001.07">sese.019.001.07 AccountHoldingInformationRequestV07</option>
<option value="sese.020.001.06">sese.020.001.06 SecuritiesTransactionCancellationRequestV06</option>
<option value="sese.020.002.06">sese.020.002.06 SecuritiesTransactionCancellationRequest002V06</option>
<option value="sese.021.001.05">sese.021.001.05 SecuritiesTransactionStatusQueryV05</option>
<option value="sese.021.002.05">sese.021.002.05 SecuritiesTransactionStatusQuery002V05</option>
<option value="sese.022.001.05">sese.022.001.05 SecuritiesStatusOrStatementQueryStatusAdviceV05</option>
<option value="sese.022.002.05">sese.022.002.05 SecuritiesStatusOrStatementQueryStatusAdvice002V05</option>
<option value="sese.023.001.10">sese.023.001.10 SecuritiesSettlementTransactionInstructionV10</option>
<option value="sese.023.002.10">sese.023.002.10 SecuritiesSettlementTransactionInstruction002V10</option>
<option value="sese.024.001.11">sese.024.001.11 SecuritiesSettlementTransactionStatusAdviceV11</option>
<option value="sese.024.002.11">sese.024.002.11 SecuritiesSettlementTransactionStatusAdvice002V11</option>
<option value="sese.025.001.10">sese.025.001.10 SecuritiesSettlementTransactionConfirmationV10</option>
<option value="sese.025.002.10">sese.025.002.10 SecuritiesSettlementTransactionConfirmation002V10</option>
<option value="sese.026.001.09">sese.026.001.09 SecuritiesSettlementTransactionReversalAdviceV09</option>
<option value="sese.026.002.09">sese.026.002.09 SecuritiesSettlementTransactionReversalAdvice002V09</option>
<option value="sese.027.001.06">sese.027.001.06 SecuritiesTransactionCancellationRequestStatusAdviceV06</option>
<option value="sese.027.002.06">sese.027.002.06 SecuritiesTransactionCancellationRequestStatusAdvice002V06</option>
<option value="sese.028.001.09">sese.028.001.09 SecuritiesSettlementTransactionAllegementNotificationV09</option>
<option value="sese.028.002.09">sese.028.002.09 SecuritiesSettlementTransactionAllegementNotification002V09</option>
<option value="sese.029.001.05">sese.029.001.05 SecuritiesSettlementAllegementRemovalAdviceV05</option>
<option value="sese.029.002.05">sese.029.002.05 SecuritiesSettlementAllegementRemovalAdvice002V05</option>
<option value="sese.030.001.08">sese.030.001.08 SecuritiesSettlementConditionsModificationRequestV08</option>
<option value="sese.030.002.08">sese.030.002.08 SecuritiesSettlementConditionsModificationRequest002V08</option>
<option value="sese.031.001.08">sese.031.001.08 SecuritiesSettlementConditionModificationStatusAdviceV08</option>
<option value="sese.031.002.08">sese.031.002.08 SecuritiesSettlementConditionModificationStatusAdvice002V08</option>
<option value="sese.032.001.10">sese.032.001.10 SecuritiesSettlementTransactionGenerationNotificationV10</option>
<option value="sese.032.002.09">sese.032.002.09 SecuritiesSettlementTransactionGenerationNotification002V09</option>
<option value="sese.033.001.10">sese.033.001.10 SecuritiesFinancingInstructionV10</option>
<option value="sese.033.002.10">sese.033.002.10 SecuritiesFinancingInstruction002V10</option>
<option value="sese.034.001.08">sese.034.001.08 SecuritiesFinancingStatusAdviceV08</option>
<option value="sese.034.002.08">sese.034.002.08 SecuritiesFinancingStatusAdvice002V08</option>
<option value="sese.035.001.10">sese.035.001.10 SecuritiesFinancingConfirmationV10</option>
<option value="sese.035.002.10">sese.035.002.10 SecuritiesFinancingConfirmation002V10</option>
<option value="sese.036.001.07">sese.036.001.07 SecuritiesFinancingModificationInstructionV07</option>
<option value="sese.036.002.07">sese.036.002.07 SecuritiesFinancingModificationInstruction002V07</option>
<option value="sese.037.001.06">sese.037.001.06
    PortfolioTransferNotificationV06</option>
<option value="sese.037.002.06">sese.037.002.06 PortfolioTransferNotification002V06</option>
<option value="sese.038.001.08">sese.038.001.08 SecuritiesSettlementTransactionModificationRequestV08</option>
<option value="sese.038.002.08">sese.038.002.08 SecuritiesSettlementTransactionModificationRequest002V08</option>
<option value="sese.039.001.05">sese.039.001.05 SecuritiesSettlementTransactionModificationRequestStatusAdviceV05</option>
<option value="sese.039.002.05">sese.039.002.05 SecuritiesSettlementTransactionModificationRequestStatusAdvice002V05</option>
<option value="sese.040.001.03">sese.040.001.03 SecuritiesSettlementTransactionCounterpartyResponseV03</option>
<option value="sese.040.002.03">sese.040.002.03 SecuritiesSettlementTransactionCounterpartyResponse002V03</option>
<option value="sese.041.001.01">sese.041.001.01
    BuyInRegulatoryAdviceV01</option>
<option value="sese.042.001.01">sese.042.001.01
    BuyInRegulatoryAdviceResponseV01</option>
<option value="setr.001.001.04">setr.001.001.04 RedemptionBulkOrderV04</option>
<option value="setr.002.001.04">setr.002.001.04 RedemptionBulkOrderCancellationRequestV04</option>
<option value="setr.003.001.04">setr.003.001.04 RedemptionBulkOrderConfirmationV04</option>
<option value="setr.004.001.04">setr.004.001.04 RedemptionOrderV04</option>
<option value="setr.005.001.04">setr.005.001.04 RedemptionOrderCancellationRequestV04</option>
<option value="setr.006.001.04">setr.006.001.04
    RedemptionOrderConfirmationV04</option>
<option value="setr.007.001.04">setr.007.001.04
    SubscriptionBulkOrderV04</option>
<option value="setr.008.001.04">setr.008.001.04 SubscriptionBulkOrderCancellationRequestV04</option>
<option value="setr.009.001.04">setr.009.001.04 SubscriptionBulkOrderConfirmationV04</option>
<option value="setr.010.001.04">setr.010.001.04 SubscriptionOrderV04</option>
<option value="setr.011.001.04">setr.011.001.04 SubscriptionOrderCancellationRequestV04</option>
<option value="setr.012.001.04">setr.012.001.04
    SubscriptionOrderConfirmationV04</option>
<option value="setr.013.001.04">setr.013.001.04 SwitchOrderV04</option>
<option value="setr.014.001.04">setr.014.001.04 SwitchOrderCancellationRequestV04</option>
<option value="setr.015.001.04">setr.015.001.04
    SwitchOrderConfirmationV04</option>
<option value="setr.016.001.04">setr.016.001.04
    OrderInstructionStatusReportV04</option>
<option value="setr.017.001.04">setr.017.001.04
    OrderCancellationStatusReportV04</option>
<option value="setr.018.001.04">setr.018.001.04
    RequestForOrderStatusReportV04</option>
<option value="setr.027.001.03">setr.027.001.03
    SecuritiesTradeConfirmationV03</option>
<option value="setr.029.001.01">setr.029.001.01 SecuritiesTradeConfirmationCancellationV01</option>
<option value="setr.030.001.01">setr.030.001.01 SecuritiesTradeConfirmationResponseV01</option>
<option value="setr.044.001.02">setr.044.001.02 SecuritiesTradeConfirmationStatusAdviceV02</option>
<option value="setr.047.001.02">setr.047.001.02 SubscriptionOrderConfirmationCancellationInstructionV02</option>
<option value="setr.048.001.01">setr.048.001.01 SubscriptionOrderConfirmationAmendmentV01</option>
<option value="setr.049.001.02">setr.049.001.02 SubscriptionBulkOrderConfirmationCancellationInstructionV02</option>
<option value="setr.050.001.01">setr.050.001.01 SubscriptionBulkOrderConfirmationAmendmentV01</option>
<option value="setr.051.001.02">setr.051.001.02 RedemptionOrderConfirmationCancellationInstructionV02</option>
<option value="setr.052.001.01">setr.052.001.01 RedemptionOrderConfirmationAmendmentV01</option>
<option value="setr.053.001.02">setr.053.001.02 RedemptionBulkOrderConfirmationCancellationInstructionV02</option>
<option value="setr.054.001.01">setr.054.001.01 RedemptionBulkOrderConfirmationAmendmentV01</option>
<option value="setr.055.001.02">setr.055.001.02 SwitchOrderConfirmationCancellationInstructionV02</option>
<option value="setr.056.001.01">setr.056.001.01 SwitchOrderConfirmationAmendmentV01</option>
<option value="setr.057.001.02">setr.057.001.02
    OrderConfirmationStatusReportV02</option>
<option value="setr.058.001.02">setr.058.001.02 RequestForOrderConfirmationStatusReportV02</option>
<option value="tsin.001.001.01">tsin.001.001.01
    InvoiceFinancingRequestV01</option>
<option value="tsin.002.001.01">tsin.002.001.01
    InvoiceFinancingRequestStatusV01</option>
<option value="tsin.003.001.01">tsin.003.001.01 InvoiceFinancingCancellationRequestV01</option>
<option value="tsin.005.001.01">tsin.005.001.01
    UndertakingApplicationV01</option>
<option value="tsin.006.001.01">tsin.006.001.01
    InvoiceAssignmentRequestV01</option>
<option value="tsin.007.001.01">tsin.007.001.01
    InvoiceAssignmentStatusV01</option>
<option value="tsin.008.001.01">tsin.008.001.01
    InvoiceAssignmentNotificationV01</option>
<option value="tsin.009.001.01">tsin.009.001.01 PartyRegistrationAndGuaranteeRequestV01</option>
<option value="tsin.010.001.01">tsin.010.001.01 PartyRegistrationAndGuaranteeStatusV01</option>
<option value="tsin.011.001.01">tsin.011.001.01 PartyRegistrationAndGuaranteeNotificationV01</option>
<option value="tsin.012.001.01">tsin.012.001.01 PartyRegistrationAndGuaranteeAcknowledgementV01</option>
<option value="tsin.013.001.01">tsin.013.001.01 InvoiceAssignmentAcknowledgementV01</option>
<option value="tsmt.001.001.03">tsmt.001.001.03 AcknowledgementV03</option>
<option value="tsmt.002.001.04">tsmt.002.001.04 ActivityReportV04</option>
<option value="tsmt.003.001.03">tsmt.003.001.03
    ActivityReportRequestV03</option>
<option value="tsmt.004.001.02">tsmt.004.001.02
    ActivityReportSetUpRequestV02</option>
<option value="tsmt.005.001.02">tsmt.005.001.02 AmendmentAcceptanceV02</option>
<option value="tsmt.006.001.03">tsmt.006.001.03 AmendmentAcceptanceNotificationV03</option>
<option value="tsmt.007.001.02">tsmt.007.001.02 AmendmentRejectionV02</option>
<option value="tsmt.008.001.03">tsmt.008.001.03 AmendmentRejectionNotificationV03</option>
<option value="tsmt.009.001.05">tsmt.009.001.05
    BaselineAmendmentRequestV05</option>
<option value="tsmt.010.001.03">tsmt.010.001.03 BaselineMatchReportV03</option>
<option value="tsmt.011.001.04">tsmt.011.001.04 BaselineReportV04</option>
<option value="tsmt.012.001.05">tsmt.012.001.05 BaselineReSubmissionV05</option>
<option value="tsmt.013.001.03">tsmt.013.001.03 DataSetMatchReportV03</option>
<option value="tsmt.014.001.05">tsmt.014.001.05 DataSetSubmissionV05</option>
<option value="tsmt.015.001.03">tsmt.015.001.03 DeltaReportV03</option>
<option value="tsmt.016.001.03">tsmt.016.001.03 ErrorReportV03</option>
<option value="tsmt.017.001.05">tsmt.017.001.05 ForwardDataSetSubmissionReportV05</option>
<option value="tsmt.018.001.05">tsmt.018.001.05
    FullPushThroughReportV05</option>
<option value="tsmt.019.001.05">tsmt.019.001.05
    InitialBaselineSubmissionV05</option>
<option value="tsmt.020.001.02">tsmt.020.001.02 MisMatchAcceptanceV02</option>
<option value="tsmt.021.001.03">tsmt.021.001.03 MisMatchAcceptanceNotificationV03</option>
<option value="tsmt.022.001.02">tsmt.022.001.02 MisMatchRejectionV02</option>
<option value="tsmt.023.001.03">tsmt.023.001.03
    MisMatchRejectionNotificationV03</option>
<option value="tsmt.024.001.03">tsmt.024.001.03 ActionReminderV03</option>
<option value="tsmt.025.001.03">tsmt.025.001.03
    StatusChangeNotificationV03</option>
<option value="tsmt.026.001.02">tsmt.026.001.02 StatusChangeRequestV02</option>
<option value="tsmt.027.001.02">tsmt.027.001.02
    StatusChangeRequestAcceptanceV02</option>
<option value="tsmt.028.001.03">tsmt.028.001.03 StatusChangeRequestNotificationV03</option>
<option value="tsmt.029.001.02">tsmt.029.001.02
    StatusChangeRequestRejectionV02</option>
<option value="tsmt.030.001.03">tsmt.030.001.03 StatusChangeRequestRejectionNotificationV03</option>
<option value="tsmt.031.001.03">tsmt.031.001.03 StatusExtensionRequestAcceptanceV03</option>
<option value="tsmt.032.001.03">tsmt.032.001.03
    StatusExtensionNotificationV03</option>
<option value="tsmt.033.001.03">tsmt.033.001.03 StatusExtensionRequestRejectionV03</option>
<option value="tsmt.034.001.03">tsmt.034.001.03 StatusExtensionRejectionNotificationV03</option>
<option value="tsmt.035.001.03">tsmt.035.001.03
    StatusExtensionRequestV03</option>
<option value="tsmt.036.001.03">tsmt.036.001.03 StatusExtensionRequestNotificationV03</option>
<option value="tsmt.038.001.03">tsmt.038.001.03 StatusReportRequestV03</option>
<option value="tsmt.040.001.03">tsmt.040.001.03 TimeOutNotificationV03</option>
<option value="tsmt.041.001.03">tsmt.041.001.03 TransactionReportV03</option>
<option value="tsmt.042.001.03">tsmt.042.001.03
    TransactionReportRequestV03</option>
<option value="tsmt.044.001.02">tsmt.044.001.02
    IntentToPayNotificationV02</option>
<option value="tsmt.045.001.02">tsmt.045.001.02 ForwardIntentToPayNotificationV02</option>
<option value="tsmt.046.001.01">tsmt.046.001.01 IntentToPayReportV01</option>
<option value="tsmt.047.001.01">tsmt.047.001.01 SpecialRequestV01</option>
<option value="tsmt.048.001.01">tsmt.048.001.01 SpecialNotificationV01</option>
<option value="tsmt.049.001.01">tsmt.049.001.01
    RoleAndBaselineAcceptanceV01</option>
<option value="tsmt.050.001.01">tsmt.050.001.01
    RoleAndBaselineRejectionV01</option>
<option value="tsmt.051.001.01">tsmt.051.001.01 RoleAndBaselineAcceptanceNotificationV01</option>
<option value="tsmt.052.001.01">tsmt.052.001.01 RoleAndBaselineRejectionNotificationV01</option>
<option value="tsmt.053.001.01">tsmt.053.001.01 InvoicePaymentReconciliationAdviceV01</option>
<option value="tsmt.054.001.01">tsmt.054.001.01 InvoicePaymentReconciliationStatusV01</option>
<option value="tsmt.055.001.01">tsmt.055.001.01 PartyEventAdviceV01</option>
<option value="tsrv.001.001.01">tsrv.001.001.01 UndertakingIssuanceV01</option>
<option value="tsrv.002.001.01">tsrv.002.001.01
    UndertakingIssuanceAdviceV01</option>
<option value="tsrv.003.001.01">tsrv.003.001.01 UndertakingIssuanceNotificationV01</option>
<option value="tsrv.004.001.01">tsrv.004.001.01
    UndertakingAmendmentRequestV01</option>
<option value="tsrv.005.001.01">tsrv.005.001.01 UndertakingAmendmentV01</option>
<option value="tsrv.006.001.01">tsrv.006.001.01
    UndertakingAmendmentAdviceV01</option>
<option value="tsrv.007.001.01">tsrv.007.001.01 UndertakingAmendmentNotificationV01</option>
<option value="tsrv.008.001.01">tsrv.008.001.01
    UndertakingAmendmentResponseV01</option>
<option value="tsrv.009.001.01">tsrv.009.001.01 UndertakingAmendmentResponseNotificationV01</option>
<option value="tsrv.010.001.01">tsrv.010.001.01 UndertakingNonExtensionRequestV01</option>
<option value="tsrv.011.001.01">tsrv.011.001.01 UndertakingNonExtensionNotificationV01</option>
<option value="tsrv.012.001.01">tsrv.012.001.01 UndertakingTerminationNotificationV01</option>
<option value="tsrv.013.001.01">tsrv.013.001.01 UndertakingDemandV01</option>
<option value="tsrv.014.001.01">tsrv.014.001.01 ExtendOrPayRequestV01</option>
<option value="tsrv.015.001.01">tsrv.015.001.01 ExtendOrPayResponseV01</option>
<option value="tsrv.016.001.01">tsrv.016.001.01
    DemandRefusalNotificationV01</option>
<option value="tsrv.017.001.01">tsrv.017.001.01
    DemandWithdrawalNotificationV01</option>
<option value="tsrv.018.001.01">tsrv.018.001.01 TradeStatusReportV01</option>
<option value="tsrv.019.001.01">tsrv.019.001.01
    UndertakingStatusReportV01</option>



    </Form.Control>
    <Form.Text>The message type to add.</Form.Text>
    </Form.Group>
    </Form>
        </Tab>

        <Tab eventKey="x12" title="X12">
    <Form>
    <Form.Group>
    <Form.Label>X12 Message type</Form.Label>
    <Form.Control
      as="select"
      type="text"
      name="messageType"
      onChange={onChange}
      placeholder="Enter message type"
    >
      <option value=""></option>
<option value="100">100 Insurance</option>
<option value="101">101 Name</option>
<option value="102">102 Associated</option>
<option value="103">103 Abandoned</option>
<option value="104">104 Air</option>
<option value="105">105 Business</option>
<option value="106">106 Motor</option>
<option value="107">107 Request</option>
<option value="108">108 Response</option>
<option value="109">109 Vessel</option>
<option value="110">110 Air</option>
<option value="111">111 Individual</option>
<option value="112">112 Property</option>
<option value="113">113 Election</option>
<option value="120">120 Vehicle</option>
<option value="121">121 Vehicle</option>
<option value="124">124 Vehicle</option>
<option value="125">125 Multilevel</option>
<option value="126">126 Vehicle</option>
<option value="127">127 Vehicle</option>
<option value="128">128 Dealer</option>
<option value="129">129 Vehicle</option>
<option value="130">130 Student</option>
<option value="131">131 Student</option>
<option value="132">132 Human</option>
<option value="133">133 Educational</option>
<option value="135">135 Student</option>
<option value="138">138 Educational</option>
<option value="139">139 Student</option>
<option value="140">140 Product</option>
<option value="141">141 Product</option>
<option value="142">142 Product</option>
<option value="143">143 Product</option>
<option value="144">144 Student</option>
<option value="146">146 Request</option>
<option value="147">147 Response</option>
<option value="148">148 Report</option>
<option value="149">149 Notice</option>
<option value="150">150 Tax</option>
<option value="151">151 Electronic</option>
<option value="152">152 Statistical</option>
<option value="153">153 Unemployment</option>
<option value="154">154 Secured</option>
<option value="155">155 Business</option>
<option value="157">157 Notice</option>
<option value="158">158 Tax</option>
<option value="159">159 Motion</option>
<option value="160">160 Transportation</option>
<option value="161">161 Train</option>
<option value="163">163 Transportation</option>
<option value="170">170 Revenue</option>
<option value="175">175 Court</option>
<option value="176">176 Court</option>
<option value="179">179 Environmental</option>
<option value="180">180 Return</option>
<option value="185">185 Royalty</option>
<option value="186">186 Insurance</option>
<option value="187">187 Premium</option>
<option value="188">188 Educational</option>
<option value="189">189 Application</option>
<option value="190">190 Student</option>
<option value="191">191 Student</option>
<option value="194">194 Grant</option>
<option value="195">195 Federal</option>
<option value="196">196 Contractor</option>
<option value="197">197 Real</option>
<option value="198">198 Loan</option>
<option value="199">199 Real</option>
<option value="200">200 Mortgage</option>
<option value="201">201 Residential</option>
<option value="202">202 Secondary</option>
<option value="203">203 Secondary</option>
<option value="204">204 Motor</option>
<option value="205">205 Mortgage</option>
<option value="206">206 Real</option>
<option value="210">210 Motor</option>
<option value="211">211 Motor</option>
<option value="212">212 Motor</option>
<option value="213">213 Motor</option>
<option value="214">214 Transportation</option>
<option value="215">215 Motor</option>
<option value="216">216 Motor</option>
<option value="217">217 Motor</option>
<option value="219">219 Logistics</option>
<option value="220">220 Logistics</option>
<option value="222">222 Cartage</option>
<option value="223">223 Consolidators</option>
<option value="224">224 Motor</option>
<option value="225">225 Response</option>
<option value="227">227 Trailer</option>
<option value="228">228 Equipment</option>
<option value="240">240 Motor</option>
<option value="242">242 Data</option>
<option value="244">244 Product</option>
<option value="245">245 Real</option>
<option value="248">248 Account</option>
<option value="249">249 Animal</option>
<option value="250">250 Purchase</option>
<option value="251">251 Pricing</option>
<option value="252">252 Insurance</option>
<option value="255">255 Underwriting</option>
<option value="256">256 Periodic</option>
<option value="259">259 Residential</option>
<option value="260">260 Application</option>
<option value="261">261 Real</option>
<option value="262">262 Real</option>
<option value="263">263 Residential</option>
<option value="264">264 Mortgage</option>
<option value="265">265 Real</option>
<option value="266">266 Mortgage</option>
<option value="267">267 Individual</option>
<option value="268">268 Annuity</option>
<option value="269">269 Health</option>
<option value="270">270 Eligibility,</option>
<option value="271">271 Eligibility,</option>
<option value="272">272 Property</option>
<option value="273">273 Insurance/Annuity</option>
<option value="274">274 Healthcare</option>
<option value="275">275 Patient</option>
<option value="276">276 Health</option>
<option value="277">277 Health</option>
<option value="278">278 Health</option>
<option value="280">280 Voter</option>
<option value="283">283 Tax</option>
<option value="284">284 Commercial</option>
<option value="285">285 Commercial</option>
<option value="286">286 Commercial</option>
<option value="288">288 Wage</option>
<option value="290">290 Cooperative</option>
<option value="300">300 Reservation</option>
<option value="301">301 Confirmation</option>
<option value="303">303 Booking</option>
<option value="304">304 Shipping</option>
<option value="309">309 Customs</option>
<option value="310">310 Freight</option>
<option value="311">311 Canada</option>
<option value="312">312 Arrival</option>
<option value="313">313 Shipment</option>
<option value="315">315 Status</option>
<option value="317">317 Delivery/Pickup</option>
<option value="319">319 Terminal</option>
<option value="322">322 Terminal</option>
<option value="323">323 Vessel</option>
<option value="324">324 Vessel</option>
<option value="325">325 Consolidation</option>
<option value="326">326 Consignment</option>
<option value="350">350 Customs</option>
<option value="352">352 Customs</option>
<option value="353">353 Customs</option>
<option value="354">354 Customs</option>
<option value="355">355 Customs</option>
<option value="356">356 Customs</option>
<option value="357">357 Customs</option>
<option value="358">358 Customs</option>
<option value="359">359 Customs</option>
<option value="361">361 Carrier</option>
<option value="362">362 Cargo</option>
<option value="404">404 Rail</option>
<option value="410">410 Rail</option>
<option value="412">412 Trailer</option>
<option value="414">414 Rail</option>
<option value="417">417 Rail</option>
<option value="418">418 Rail</option>
<option value="419">419 Advance</option>
<option value="420">420 Car</option>
<option value="421">421 Estimated</option>
<option value="422">422 Equipment</option>
<option value="423">423 Rail</option>
<option value="424">424 Rail</option>
<option value="425">425 Rail</option>
<option value="426">426 Rail</option>
<option value="429">429 Railroad</option>
<option value="431">431 Railroad</option>
<option value="432">432 Rail</option>
<option value="433">433 Railroad</option>
<option value="434">434 Railroad</option>
<option value="435">435 Standard</option>
<option value="436">436 Locomotive</option>
<option value="437">437 Railroad</option>
<option value="440">440 Shipment</option>
<option value="451">451 Railroad</option>
<option value="452">452 Railroad</option>
<option value="453">453 Railroad</option>
<option value="455">455 Railroad</option>
<option value="456">456 Railroad</option>
<option value="460">460 Railroad</option>
<option value="463">463 Rail</option>
<option value="466">466 Rate</option>
<option value="468">468 Rate</option>
<option value="470">470 Railroad</option>
<option value="475">475 Rail</option>
<option value="485">485 Ratemaking</option>
<option value="486">486 Rate</option>
<option value="490">490 Rate</option>
<option value="492">492 Miscellaneous</option>
<option value="494">494 Rail</option>
<option value="500">500 Medical</option>
<option value="501">501 Vendor</option>
<option value="503">503 Pricing</option>
<option value="504">504 Clauses</option>
<option value="511">511 Requisition</option>
<option value="517">517 Material</option>
<option value="521">521 Income</option>
<option value="527">527 Material</option>
<option value="536">536 Logistics</option>
<option value="540">540 Notice</option>
<option value="561">561 Contract</option>
<option value="567">567 Contract</option>
<option value="568">568 Contract</option>
<option value="601">601 Customs</option>
<option value="603">603 Transportation</option>
<option value="620">620 Excavation</option>
<option value="625">625 Well</option>
<option value="650">650 Maintenance</option>
<option value="715">715 Intermodal</option>
<option value="753">753 Request</option>
<option value="754">754 Routing</option>
<option value="805">805 Contract</option>
<option value="806">806 Project</option>
<option value="810">810 Invoice</option>
<option value="811">811 Consolidated</option>
<option value="812">812 Credit/Debit</option>
<option value="813">813 Electronic</option>
<option value="814">814 General</option>
<option value="815">815 Cryptographic</option>
<option value="816">816 Organizational</option>
<option value="818">818 Commission</option>
<option value="819">819 Joint</option>
<option value="820">820 Payment</option>
<option value="821">821 Financial</option>
<option value="822">822 Account</option>
<option value="823">823 Lockbox</option>
<option value="824">824 Application</option>
<option value="826">826 Tax</option>
<option value="827">827 Financial</option>
<option value="828">828 Debit</option>
<option value="829">829 Payment</option>
<option value="830">830 Planning</option>
<option value="831">831 Application</option>
<option value="832">832 Price/Sales</option>
<option value="833">833 Mortgage</option>
<option value="834">834 Benefit</option>
<option value="835">835 Health</option>
<option value="836">836 Procurement</option>
<option value="837">837 Health</option>
<option value="838">838 Trading</option>
<option value="839">839 Project</option>
<option value="840">840 Request</option>
<option value="841">841 Specifications/Technical</option>
<option value="842">842 Nonconformance</option>
<option value="843">843 Response</option>
<option value="844">844 Product</option>
<option value="845">845 Price</option>
<option value="846">846 Inventory</option>
<option value="847">847 Material</option>
<option value="848">848 Material</option>
<option value="849">849 Response</option>
<option value="850">850 Purchase</option>
<option value="851">851 Asset</option>
<option value="852">852 Product</option>
<option value="853">853 Routing</option>
<option value="854">854 Shipment</option>
<option value="855">855 Purchase</option>
<option value="856">856 Ship</option>
<option value="857">857 Shipment</option>
<option value="858">858 Shipment</option>
<option value="859">859 Freight</option>
<option value="860">860 Purchase</option>
<option value="861">861 Receiving</option>
<option value="862">862 Shipping</option>
<option value="863">863 Report</option>
<option value="864">864 Text</option>
<option value="865">865 Purchase</option>
<option value="866">866 Production</option>
<option value="867">867 Product</option>
<option value="868">868 Electronic</option>
<option value="869">869 Order</option>
<option value="870">870 Order</option>
<option value="872">872 Residential</option>
<option value="873">873 Commodity</option>
<option value="874">874 Commodity</option>
<option value="875">875 Grocery</option>
<option value="876">876 Grocery</option>
<option value="877">877 Manufacturer</option>
<option value="878">878 Product</option>
<option value="879">879 Price</option>
<option value="880">880 Grocery</option>
<option value="881">881 Manufacturer</option>
<option value="882">882 Direct</option>
<option value="883">883 Market</option>
<option value="884">884 Market</option>
<option value="885">885 Retail</option>
<option value="886">886 Customer</option>
<option value="887">887 Coupon</option>
<option value="888">888 Item</option>
<option value="889">889 Promotion</option>
<option value="890">890 Contract</option>
<option value="891">891 Deduction</option>
<option value="892">892 Trading</option>
<option value="893">893 Item</option>
<option value="894">894 Delivery/Return</option>
<option value="895">895 Delivery/Return</option>
<option value="896">896 Product</option>
<option value="897">897 Data</option>
<option value="920">920 Loss</option>
<option value="924">924 Loss</option>
<option value="925">925 Claim</option>
<option value="926">926 Claim</option>
<option value="928">928 Automotive</option>
<option value="940">940 Warehouse</option>
<option value="943">943 Warehouse</option>
<option value="944">944 Warehouse</option>
<option value="945">945 Warehouse</option>
<option value="947">947 Warehouse</option>
<option value="980">980 Functional</option>
<option value="990">990 Response</option>
<option value="993">993 Secured</option>
<option value="996">996 File</option>
<option value="997">997 Functional</option>
<option value="998">998 Set</option>
<option value="999">999 Implementation</option>
    </Form.Control>
    <Form.Text>The message type to add.</Form.Text>
    </Form.Group>
    </Form>
        </Tab>

        <Tab eventKey="peppol" title="PEPPOL">
    <Form>
    <Form.Group>
    <Form.Label>Peppol Message type</Form.Label>
    <Form.Control
      as="select"
      type="text"
      name="messageType"
      onChange={onChange}
      placeholder="Enter message type"
    >
      <option value=""></option>
      <option value="peppol-bis-invoice-3">PEPPOL 3.0 UBL Invoice</option>
      <option value="peppol-bis-credit-note-3">PEPPOL 3.0 UBL Credit Note</option>
    </Form.Control>
    <Form.Text>The message type to add. See https://docs.peppol.eu/poacc/billing/3.0/
    </Form.Text>
    </Form.Group>
    </Form>
        </Tab>

        <Tab eventKey="bmbix" title="Bmbix">
    <Form>
    <Form.Group>
    <Form.Label>Bmbix Message type</Form.Label>
    <Form.Control
      as="select"
      type="text"
      name="messageType"
      onChange={onChange}
      placeholder="Enter message type"
    >
      <option value=""></option>
    {
      // <option value="bmbix-simplified-invoice-5">Bmbix Simplified Invoice 5</option>
      }
      {S.map (mct => <option value={mct.name} key={mct.id}>{mct.name}</option>) (data)}
    </Form.Control>
    <Form.Text>The message type to add.</Form.Text>
    </Form.Group>
    </Form>
        </Tab>
      </Tabs>
  );
}

function View (props: {
  display: string,
  onSubmit: Function,
  onDoneSuccess: Function,
  onDoneFailure: Function,
  onCancel: Function,
  onChange: Function,
  data: Function,
}) {

  const {
    display,
    onCancel,
    onSubmit,
    onDoneSuccess,
    onDoneFailure,
    onChange,
    data,
  } = props;

  return (
    <BModal title={"Add message type"} display={display} onSubmit={onSubmit} onCancel={onCancel}
  onDoneSuccess={onDoneSuccess} onDoneFailure={onDoneFailure}>
      <MessageTypeFormAdd onChange={onChange} data={data} />
      <BSuccess>Successfully added message type.</BSuccess>
      <BFailure>Oops, failed to add message type.</BFailure>
    </BModal>
  );

}

export { View };
