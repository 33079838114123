// @flow
import { createElement as el } from "react";
import { createMachine } from "xstate";
import { useMachine } from "@xstate/react";
import type { PaymentMethod } from "./../../../Entity/Types.js";
import { View } from "./view.js";


const Controller = ({
  onDone,
  paymentMethod,
}: {
  onDone: Function,
  paymentMethod: PaymentMethod,
}) => {
  const [ modeFSMState, modeFSMSend ] = useMachine(() => {
    return createMachine({
      id: "mode finite state machine",
      context: {
        paymentMethod,
      },
      initial: "button",
      states: {
        button: {
          on: {
            SHOW_MODAL: "modal",
          },
        },
        modal: {
          on: {
            SHOW_BUTTON: "button",
          },
        },
      },
    });
  });

  const handleCancel = () => {
    modeFSMSend({type: "SHOW_BUTTON"})
  }

  const handleDone = (e) => {
    modeFSMSend({type: "SHOW_BUTTON"})
    onDone(e)
  }

  const handleStart = () => {
    modeFSMSend({type: "SHOW_MODAL"})
  }

  return el(
    View,
    {
      modeFSMState,
      onDone: handleDone,
      onCancel: handleCancel,
      onStart: handleStart,
    },
  )
}
export {
  Controller,
}
