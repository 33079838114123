// @flow
import {
  useState,
  createElement as el,
} from "react";
import log from "loglevel";

import {
  withKashflowV1AuthnApi,
} from "../Api/";
import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "../../../Util";

import {
  View,
} from "./View.js";

const logger = log.getLogger("Kashflow");

const Controller_ = (props: {
  organizationId: string,
  platformId: string,
  kashflowV1AuthnApi: Object,
  onComplete: Function,
}) => {
  const {
    organizationId,
    platformId,
    kashflowV1AuthnApi,
    onComplete,
  } = props;

  const [display, setDisplay] = useState(INPUT);

  const onSubmit = (args: {
    username: string,
    password: string,
  }) => {
    const {
      username,
      password,
    } = args;
    setDisplay(WAITING);
    logger.error("username, password", username, password);
    kashflowV1AuthnApi.insert({
      organizationId,
      username,
      password,
      platformId,
    }).then(
      connection => {
        setDisplay(SUCCESS);
      }
    ).catch(
      error => {
        setDisplay(FAILURE);
      }
    );
  }

  const onSuccess = (e) => {
    logger.error("it all went swimmingly");
    onComplete();
  }

  const onFailure = (e) => {
    logger.error("it all went rather less well than hoped");
    onComplete();
  }

  const onCancel = (e) => {
    logger.error("it all went rather less well than hoped");
    onComplete();
  }

  return el(
    View,
    {
      display,
      onSuccess,
      onCancel,
      onSubmit,
      onFailure,
    },
  )
}

const Controller = withKashflowV1AuthnApi(Controller_);

export {
  Controller,
  Controller_,
}
