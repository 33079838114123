// @flow
import {
  instanceService,
} from "../../Entity/InstanceService";
import {
  type Token,
} from "../../Entity/Types.js";


import {
  ApiClient,
  TokensApi,
  Token as TokenGW,
} from "@bmbix/bmb_xero_v1_authz_client";


const createTokenFromGW = (
  tokenGW: TokenGW,
) => {
  const expected = instanceService.createToken({
    bmbixUserId: tokenGW.bmbix_user_id,
    xeroAuthEventId: tokenGW.xero_auth_event_id,
    xeroUserId: tokenGW.xero_user_id,
    recordCreatedAt: tokenGW.record_created_at,
    expires: tokenGW.expires,
    recordRefreshedAt: tokenGW.record_refreshed_at,
  });
  return expected;
}


const selectToken = (args: {
  token: string,
  defaultClient_: Object,
}): Promise<Token> => {
  const {
    token,
    defaultClient_,
  } = args;
  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new TokensApi(defaultClient);
  return new Promise((resolve, reject) => {
    apiInstance.selectToken(
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          const tokenGW = data.token;
          const token = createTokenFromGW(tokenGW);
          resolve(token);
        }
      }
    );
  });

}

export {
  createTokenFromGW,
  selectToken,
}
