// @flow
import React from "react";
import Button from "react-bootstrap/Button";


const View = (props: {
  onCancel: Function,
}) => {
  const {
    onCancel,
  } = props;

  return (
    <>
    <p>waiting</p>
    <Button onClick={onCancel}>Cancel</Button>
    </>
  );
}


export {
  View,
};
