// @flow
import { createElement as el } from "react";

import { View } from "./View.js";

const Controller = (props: {
  name: string,
  size?: string,
}) => {
  const {
    name,
    size,
  } = props;

  const size_ = !!size ? size : "16";
  const iconPath = `/platform_logos.svg#${name}`;

  return el(
    View,
    {
      iconPath,
      size: size_,
    },
  )
}

export { Controller }
