// @flow
import * as R from "ramda";
import * as S from "sanctuary";
import log from "loglevel";
import {
  chunkPromise,
  PromiseFlavor
} from "chunk-promise";

import {
  ApiClient,
  PlatformsApi,
  Platform as PlatformGW
} from "@bmbix/bmb_isimud_client";

import {
  type Platform
} from "../../Entity/Types.js";
import {
  instanceService
} from "../../Entity/InstanceService.js";

const logger = log.getLogger("Isimud:Platforms");

const createPlatformFromGW = (platformGW: PlatformGW): Platform => {
  return instanceService.createPlatform({
    platformId: platformGW.platform_id,
    organizationId: platformGW.organization_id,
    name: platformGW.name,
    type: platformGW.type,
  });
}


const getPlatform = (args: {
  token: string,
  organizationId: string,
  platformId: string,
}):Promise<Array<Platform>> => {
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = args.token;
  let apiInstance = new PlatformsApi();
  return new Promise((resolve, reject) => {
    apiInstance.indexOne(args.organizationId, args.platformId, (error, data, response) => {
      if (error) {
        resolve(error);
      } else {
        const platformResponseGW = data;
        const platformGW = platformResponseGW.platform;
        const platform = createPlatformFromGW(platformGW);
        resolve(platform);
      }
    });
  });

}


const getPlatforms = (args: {
  token: string,
  organizationId: string,
}):Promise<Array<Platform>> => {
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = args.token;
  let apiInstance = new PlatformsApi();
  return new Promise((resolve, reject) => {
    apiInstance.index(args.organizationId, (error, data, response) => {
      if (error) {
        resolve(error);
      } else {
        const platformsResponseGW = data;
        const platformsGW = platformsResponseGW.platforms;
        const platforms = R.map(createPlatformFromGW, platformsGW);
        resolve(platforms);
      }
    });
  });

}


const getAllPlatforms = async (args: {
  token: string,
  organizationIds: Array<string>,
}): Array<Platform>=> {

  const promiseArr = [];

  for (const oId of args.organizationIds) {
    const p = () => getPlatforms({
      token: args.token,
      organizationId: oId,
    });
    promiseArr.push(p);
  }
  return chunkPromise(
    promiseArr,
    {
      concurrency: 1,
      promiseFlavor: PromiseFlavor.PromiseAll,
      sleepMs: 50,
    },
  );
}


const original_getAllPlatforms = async (args: {
  token: string,
  organizationIds: Array<string>,
}): Array<Platform>=> {

  const allPlatforms = [];

  for (const oId of args.organizationIds) {
    console.log(`fetching platform for ${oId}`);
    const platforms = await getPlatforms({
      token: args.token,
      organizationId: oId,
    });
    console.log(`got platforms for ${oId}`);
    allPlatforms.push(platforms);
  }
  return S.join (allPlatforms);
}


const _getAllPlatforms = (args: {
  token: string,
  organizationIds: Array<string>,
}):Promise<Array<Platform>> => {
  const allPromises = Promise.allSettled(R.map(
    oId => getPlatforms({
      token: args.token,
      organizationId: oId,
    }),
    args.organizationIds,
  )).then(
    promises => R.filter(
      p => p.status === "fulfilled",
      promises,
    )
  ).then(
    promises => R.map(
      p => p.value,
      promises,
    )
  ).then(
    values => R.flatten(values)
  ).then(
    values => {
      logger.debug("platforms", values);
      return values;
    }
  );
  return allPromises;
}

const insertPlatform = (args: {
  token: string,
  organizationId: string,
  name: string,
  type: string,
  defaultClient_?: Function,
}):Promise<Platform> => {
  const {
    token,
    organizationId,
    name,
    type,
    defaultClient_,
  } = args;
  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  const body = PlatformGW.constructFromObject({
    organization_id: organizationId,
    name: name,
    type: type,
  });
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new PlatformsApi(defaultClient);
  return new Promise((resolve, reject) => {
    apiInstance.insert(
      body,
      organizationId,
      (error, data, response) => {
        if (error) {
          resolve(error);
        } else {
          const platformResponseGW = data;
          const platformGW = platformResponseGW.platform;
          const platform = createPlatformFromGW(platformGW);
          resolve(platform);
        }
      }
    );
  });
}

const deletePlatform = (args: {
  token: string,
  organizationId: string,
  platformId: string,
  defaultClient_?: Function,
}):Promise<null> => {
  const {
    token,
    organizationId,
    platformId,
    defaultClient_,
  } = args;
  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new PlatformsApi(defaultClient);
  return new Promise((resolve, reject) => {
    apiInstance.callDelete(
      organizationId,
      platformId,
      (error, data, response) => {
        if (error) {
          resolve(error);
        } else {
          const platformResponseGW = data;
          const null_ = platformResponseGW.platform;
          resolve(null_);
        }
      }
    );
  });

}

export {
  createPlatformFromGW,
  getAllPlatforms,
  getPlatforms,
  getPlatform,
  deletePlatform,
  insertPlatform,
}

