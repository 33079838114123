// @flow
import * as R from "ramda";

import type {
  Acceptance,
  AcceptancesResponse,
  AccessKey,
  Account,
  AccountingSystem,
  AccountingSystemPlatformAssignment,
  Address,
  AddressProfile,
  AddressBookEntry,
  BillingAddress,
  BmbixAddress,
  BmbixRole,
  Certificate,
  CertificateDetail,
  Claim,
  Contact,
  ContextType,
  ECRCompletedNotice,
  EmailServer,
  Extraction,
  ExtractionCandidateReport,
  ExtractionCandidateReportEntry,
  ExtractionCandidateReportHeader,
  ExtractionItem,
  ExtractionHeader,
  ExtractionItemWithHeader,
  InsertionInstruction,
  InsertionReceipt,
  InsertionResult,
  Invitation,
  InvoiceMetadataV5,
  KashflowCredentials,
  Message,
  MessageNote,
  MessageReadReceipt,
  MessagesCount,
  Metadata,
  NoncedOrgRoleAssignment,
  NoncedAsRoleAssignment,
  Oauth2Client,
  Organization,
  OrganizationProfile,
  OrganizationProxy,
  PaymentMethod,
  PaymentMethodLink,
  Permission,
  Platform,
  PlatformAssignment,
  ProcessedReceipt,
  PublicKey,
  BRI,
  ReceivedMessage,
  ReceivedMessagesResponse,
  RejectedMessage,
  RejectedMessagesResponse,
  Route,
  Route2,
  SelectionMetadata,
  Settings,
  StripeIntent,
  StripeSetupIntent,
  TenantMapping,
  UnaddressedMessage,
  UnaddressedMessagesResponse,
  UserAccountingSystemRoleAssignment,
  UserProfile,
  UploadedInvoice,
  XeroCredentials,
} from './Types'

const createPaymentMethodLink = (args: {
  id: string,
  organizationId: string,
  paymentMethodId: string,
  paymentMethodUserId: string,
  paymentMethodDescription: string,
}): PaymentMethodLink => Object.freeze(args);

const createPaymentMethod = (args: {
  id: string,
  userId: string,
  description: string,
  links: Array<PaymentMethodLink>,
}): PaymentMethod => Object.freeze(args);

const createStripeSetupIntent = (args: {
  setupIntent: string,
}): StripeSetupIntent => Object.freeze(args);

const createStripeIntent = (args: {
  customerSecret: string,
}): StripeIntent => Object.freeze(args);

const createBillingAddress = (args: {
  id: string,
  organizationId: string,
  createdAt: string,
  addressId: string,
}): BillingAddress => {
  return Object.freeze(args);
}

const createOauth2Client= (args: {
  updatedAt: string,
  oauth2ClientId: string,
  resource: string,
  nameFromUser: string,
  clientId: string,
  clientName: string,
  clientSecret: string,
}): Oauth2Client=> {
  return Object.freeze(args);
}

const createOrganizationProxy = (args: {
  systemId: bigint,
  updatedAt: string,
  id: string,
  organizationId: string,
  name: string,
}): OrganizationProxy => {
  return Object.freeze(args);
}


const createPublicKey = (args: {
  data: string,
  uri: string,
}): PublicKey => {
  return Object.freeze(args);
}


const createCertificate = (args: {
  certificateId: string,
  name: string,
  data: string,
  mediaType: string,
}): Certificate => {
  return Object.freeze(args)
}

export const createCertificateDetail = (args: {
  +certId: string,
  +subjectURI: string,
  +data?: string,
  +dateIssued: string,
  +issuerURI: string,
  +validatorURI: string,
  +dateRescinded?: string,
  +rescinderURI?: string,
}): CertificateDetail => {
  const {
    certId,
    subjectURI,
    data,
    dateIssued,
    issuerURI,
    validatorURI,
    dateRescinded,
    rescinderURI,
  } = args;
  return Object.freeze({
    certId,
    subjectURI,
    data,
    dateIssued,
    issuerURI,
    validatorURI,
    dateRescinded,
    rescinderURI,
  });
}

const createMessagesCount = (args: {
  toId: string,
  messagesUnread: string,
  messagesUnprocessed: string,
}): MessagesCount => {
  const {toId, messagesUnread, messagesUnprocessed} = args;
  return Object.freeze({
    toId,
    messagesUnread,
    messagesUnprocessed,
  });
}


const createMessageNote = (args: {
    noteId?: string,
    messageId: string,
    referencesNoteId?: string,
    submittedAtUtc?: string,
    userId?: string,
    content: string,
}): MessageNote  => {
  const {
    noteId,
    messageId,
    referencesNoteId,
    submittedAtUtc,
    userId,
    content,
  } = args;
  const messageNote = Object.freeze({
    noteId,
    messageId,
    referencesNoteId,
    submittedAtUtc,
    userId,
    content,
  });
  return messageNote;
}

const createMessageReadReceipt = (args: {
  messageReadReceiptId: string,
  receivedTimestamp: string,
  messageId: string,
}): MessageReadReceipt => {
  return Object.freeze(args)
}


const createECRCompletedNotice = (args: {
  extractionCandidateReportId: string,
}): ECRCompletedNotice => {
  const {
    extractionCandidateReportId,
  } = args;
  return Object.freeze({
    extractionCandidateReportId,
  });
}


const createOrganizationProfile = (args: {
  +organizationId?: ?string,
  +name?: ?string,
  +isClosed?: ?boolean,
  +verified?: ?boolean,
  +iconUrl?: ?string,
  +addresses?: ?Array<AddressProfile>,
  +sicCode?: ?string,
  +headcount?: ?number,
  +ppcSignatory?: ?boolean,
  +paymentContactEmail?: ?string,
}): OrganizationProfile => {
  const {
    organizationId,
    name,
    isClosed,
    verified,
    iconUrl,
    addresses,
    sicCode,
    headcount,
    ppcSignatory,
    paymentContactEmail,
  } = args;
  return Object.freeze({
    organizationId,
    name,
    isClosed,
    verified,
    iconUrl,
    addresses,
    sicCode,
    headcount,
    ppcSignatory,
    paymentContactEmail,
  });
}


const createAddressProfile = (args: {
  +addressId?: ?string,
  +organizationId?: ?string,
  +displayName?: ?string,
  +purpose?: ?string,
  +isClosed?: ?boolean,
  +contentTypesEmitted?: ?string,
  +contentTypesAccepted?: ?string,
  +organizationProfile?: ?OrganizationProfile,
}): AddressProfile => {
  const {
    addressId,
    organizationId,
    displayName,
    purpose,
    isClosed,
    contentTypesEmitted,
    contentTypesAccepted,
    organizationProfile,
  } = args;
  return Object.freeze({
    addressId,
    organizationId,
    displayName,
    purpose,
    isClosed,
    contentTypesEmitted,
    contentTypesAccepted,
    organizationProfile,
  });
}


const createUserProfile = (args: {
  userId?: ?string,
  name?: ?string,
  verified?: ?boolean,
  iconUrl?: ?string,
}): UserProfile => {
  const {
    userId,
    name,
    verified,
    iconUrl,
  } = args;
  return Object.freeze({
    userId,
    name,
    verified,
    iconUrl,
  });
}

const createSettings = (args: {
  userId?: ?string,
  name?: ?string,
  verified?: ?string,
  verifiedName?: ?string,
  alias?: ?string,
  email?: ?string,
  language?: ?string,
  timezone?: ?string,
  iconUrl?: ?string,
}): Settings => {
  const {
    userId,
    name,
    verified,
    verifiedName,
    alias,
    email,
    language,
    timezone,
    iconUrl,
  } = args;
  return Object.freeze({
    userId,
    name,
    verified,
    verifiedName,
    alias,
    email,
    language,
    timezone,
    iconUrl,
  });
};

const createBmbixRole = (args: {
  resourceType: string,
  roleName: string,
}): BmbixRole => {
  const {
    resourceType,
    roleName,
  } = args;

  return Object.freeze({
    resourceType,
    roleName,
  })
}

const createClaim = (args: {
    claimId: string,
    toAddressId: string,
    messageToken: string,
    claimedAt: string,
    messageId: string,
}): Claim => {
  const {
    claimId,
    toAddressId,
    messageToken,
    claimedAt,
    messageId,
  } = args;
  const expected = Object.freeze({
    claimId,
    toAddressId,
    messageToken,
    claimedAt,
    messageId,
  });
  return expected;
}

const createSelectionMetadata = (args: {
  pointer: number,
  count: number,
}): SelectionMetadata => Object.freeze(args);

const createUnaddressedMessage = (args: {
  systemId: number,
  unaddressedMessageId: string,
  receivedAt: string,
  fromAddressId: string,
  localAccount: string,
  messageType: string,
  messageContent: string,
  summary: string,
  senderReference: string,
  checksum: string,
  checksumAlgorithm: string,
  messageToken: string,
  toAddressId: string,
  claimedAt: string,
  martletSubmissionStatus: string,
  martletMessageId: string,
  martletComments: string,
  martletSubmittedAt: string,
}): UnaddressedMessage => {
  const {
  systemId,
  unaddressedMessageId,
  receivedAt,
  fromAddressId,
  localAccount,
  messageType,
  messageContent,
  summary,
  senderReference,
  checksum,
  checksumAlgorithm,
  messageToken,
  toAddressId,
  claimedAt,
  martletSubmissionStatus,
  martletMessageId,
  martletComments,
  martletSubmittedAt,
  } = args;
  const expected = Object.freeze({
    systemId,
    unaddressedMessageId,
    receivedAt,
    fromAddressId,
    localAccount,
    messageType,
    messageContent,
    summary,
    senderReference,
    checksum,
    checksumAlgorithm,
    messageToken,
    toAddressId,
    claimedAt,
    martletSubmissionStatus,
    martletMessageId,
    martletComments,
    martletSubmittedAt,
  });
  return expected;
}

const createReceivedMessagesResponse = (args: {
  receivedMessages: Array<ReceivedMessage>,
  selectionMetadata: SelectionMetadata,
}): ReceivedMessagesResponse => Object.freeze(args);

const createRejectedMessagesResponse = (args: {
  rejectedMessages: Array<RejectedMessage>,
  selectionMetadata: SelectionMetadata,
}): RejectedMessagesResponse => Object.freeze(args);

const createUnaddressedMessagesResponse = (args: {
  unaddressedMessages: Array<UnaddressedMessage>,
  selectionMetadata: SelectionMetadata,
}): UnaddressedMessagesResponse => Object.freeze(args);

const createAcceptancesResponse = (args: {
  acceptances: Array<Acceptance>,
  selectionMetadata: SelectionMetadata,
}): AcceptancesResponse=> Object.freeze(args);

const createRejectedMessage = (args: {
  systemId: string,
  messageId: string,
  messageType: string,
  reasons: Object,
  fromId: string,
  toId: string,
  senderReference: string,
  updatedAt: string,
}): RejectedMessage => {
  const {
    systemId,
    messageId,
    messageType,
    reasons,
    fromId,
    toId,
    senderReference,
    updatedAt,
  } = args;
  return Object.freeze({
    systemId,
    messageId,
    messageType,
    reasons,
    fromId,
    toId,
    senderReference,
    updatedAt,
  });
}


const createReceivedMessage = (args: {
  systemId: string,
  messageId: string,
  status: string,
  submitterOrganizationId: string,
  fromAddressId: string,
  toAddressId: string,
  senderReference: string,
  messageType: string,
  submissionTimestamp: string,
}) => {
  const {
    systemId,
    messageId,
    status,
    submitterOrganizationId,
    fromAddressId,
    toAddressId,
    senderReference,
    submissionTimestamp,
    messageType,
  } = args;
  return Object.freeze({
    systemId,
    messageId,
    status,
    submitterOrganizationId,
    fromAddressId,
    toAddressId,
    senderReference,
    submissionTimestamp,
    messageType,
  });
}


const createMessage = (args: {
  messageId: string,
  submissionTimestamp: string,
  sourceAddressId: string,
  destinationAddressId: string,
  senderReference: string,
  contentMediaType: string,
  content: string,
  checksumAlgorithm: string,
  checksum: string,
}): Message =>  {

  const {
    messageId,
    submissionTimestamp,
    sourceAddressId,
    destinationAddressId,
    senderReference,
    contentMediaType,
    content,
    checksumAlgorithm,
    checksum,
  } = args;

  const expected = Object.freeze({
    messageId,
    submissionTimestamp,
    sourceAddressId,
    destinationAddressId,
    senderReference,
    contentMediaType,
    content,
    checksumAlgorithm,
    checksum,
  });
  return expected;
}


const createToken = (args: {
  bmbixUserId: string,
  xeroAuthEventId: string,
  xeroUserId: string,
  recordCreatedAt: string,
  expires: string,
  recordRefreshedAt: string,
}) => {
  const {
      bmbixUserId,
      xeroAuthEventId,
      xeroUserId,
      recordCreatedAt,
      expires,
      recordRefreshedAt,
  } = args;
  const expected = Object.freeze({
    bmbixUserId,
    xeroAuthEventId,
    xeroUserId,
    recordCreatedAt,
    expires,
    recordRefreshedAt,
  });
  return expected;
}


const createTenantMapping = (args: {
  xeroTenantId: string,
  platformId: string,
  tenantMappingId: string,
}): TenantMapping => {
  const {
    xeroTenantId,
    platformId,
    tenantMappingId,
  } = args;
  const expected = Object.freeze({
    xeroTenantId,
    platformId,
    tenantMappingId,
  });
  return expected;
}

const createAuthenticatedTenant = (args:{
  bmbixUserId: string,
  xeroTenantId: string,
  tenantName: string,
  tenantType: string,
  recordCreatedAt: string,
}) => {
  const {
    bmbixUserId,
    xeroTenantId,
    tenantName,
    tenantType,
    recordCreatedAt,
  } = args;

  return Object.freeze({
    bmbixUserId,
    xeroTenantId,
    tenantName,
    tenantType,
    recordCreatedAt,
  })
}

const createPlatformTenant = (args:{
  exists: boolean,
  tenant: AuthenticatedTenant,
}) => {
  const {
    exists,
    tenant,
  } = args;

  return Object.freeze({
    exists,
    tenant,
  })
}

const createProcessedReceipt = (args: {
  addressId: string,
  messageProcessedReceiptId: string,
  receivedTimestamp: string,
  messageId: string,
  result: string,
  reference: string,
  comments: string,
}): ProcessedReceipt => {
  return Object.freeze({
    addressId: args.addressId,
    messageProcessedReceiptId: args.messageProcessedReceiptId,
    receivedTimestamp: args.receivedTimestamp,
    messageId: args.messageId,
    result: args.result,
    reference: args.reference,
    comments: args.comments,
  });
}


const createInsertionReceipt = (args: {
  insertionReceiptId: string,
  insertionInstructionId: string,
  receivedTimestamp: string,
  addressId: string,
  platformId: string,
  messageId: string,
  result: string,
  reference: string,
  comments: string,
  messageType: string,
}): InsertionReceipt => {
    return Object.freeze({
        insertionReceiptId: args.insertionReceiptId,
        insertionInstructionId: args.insertionInstructionId,
        receivedTimestamp: args.receivedTimestamp,
        addressId: args.addressId,
        platformId: args.platformId,
        messageId: args.messageId,
        result: args.result,
        reference: args.reference,
        comments: args.comments,
        messageType: args.messageType,
    });
}


const createInsertionInstruction = (args: {
  insertionInstructionId: string,
  receivedTimestamp: string,
  messageId: string,
  processingInstructions: string,
  sentToApInsertionQueueTimestamp: string,
  sentToNotifyMartletQueueTimestamp: string,
  sentMessageProcessedReceiptTimestamp: string,
}):InsertionInstruction => {
  const expected = Object.freeze({
    insertionInstructionId: args.insertionInstructionId,
    receivedTimestamp: args.receivedTimestamp,
    messageId: args.messageId,
    processingInstructions: args.processingInstructions,
    sentToApInsertionQueueTimestamp: args.sentToApInsertionQueueTimestamp,
    sentToNotifyMartletQueueTimestamp: args.sentToNotifyMartletQueueTimestamp,
    sentMessageProcessedReceiptTimestamp: args.sentMessageProcessedReceiptTimestamp,
  });
  return expected;
}


const createAcceptance = (args: {
  systemId: string,
  messageId: string,
  fromAddressId: string,
  toAddressId: string,
  messageType: string,
  submissionTimestamp: string,
  senderReference: string,
  checksum: string,
  checksumAlgorithm: string,
  messageProcessedReceiptId: string,
  receivedTimestamp: string,
  result: string,
  reference: string,
  comments: string,
  messageReadReceiptId: string,
  readReceivedTimestamp: string,
}): Acceptance => {
  return Object.freeze({
    systemId: args.systemId,
    messageId: args.messageId,
    fromAddressId: args.fromAddressId,
    toAddressId: args.toAddressId,
    messageType: args.messageType,
    submissionTimestamp: args.submissionTimestamp,
    senderReference: args.senderReference,
    checksum: args.checksum,
    checksumAlgorithm: args.checksumAlgorithm,
    messageProcessedReceiptId: args.messageProcessedReceiptId,
    receivedTimestamp: args.receivedTimestamp,
    result: args.result,
    reference: args.reference,
    comments: args.comments,
    messageReadReceiptId: args.messageReadReceiptId,
    readReceivedTimestamp: args.readReceivedTimestamp,
  });
}

const createExtractionCandidateReport = (args:{
  header: ExtractionCandidateReportHeader,
  entries: Array<ExtractionCandidateReportEntry>,
}):ExtractionCandidateReport => {
  const expected = Object.freeze({
    header: args.header,
    entries: args.entries,
  });
  return expected;
}


const createExtractionCandidateReportEntry = (args:{
  extractionCandidateEntryId: string,
  extractionCandidateReportId: string,
  reference: string,
  summary: string,
}):ExtractionCandidateReportEntry => {
  const expected = Object.freeze({
    extractionCandidateEntryId: args.extractionCandidateEntryId,
    extractionCandidateReportId: args.extractionCandidateReportId,
    reference: args.reference,
    summary: args.summary,
  });
  return expected;
}



const createExtractionCandidateReportHeader = (args:{
  extractionCandidateReportId: string,
  requestIssuedAt: string,
  reportCreatedAt: string,
  contentMediaType: string,
  addressId: string,
}):ExtractionCandidateReportHeader => {
   return Object.freeze({
    extractionCandidateReportId: args.extractionCandidateReportId,
    requestIssuedAt: args.requestIssuedAt,
    reportCreatedAt: args.reportCreatedAt,
    contentMediaType: args.contentMediaType,
    addressId: args.addressId,
   });
}


const createExtractionItemWithHeader = (args: {
  header: ExtractionHeader,
  item: ExtractionItem,
}): ExtractionItemWithHeader => {
  return Object.freeze({
    header: args.header,
    item: args.item,
  });
}


const createExtraction = (args: {
  header: ExtractionHeader,
  items: Array<ExtractionItem>,
}): Extraction => {
    const header = createExtractionHeader(args.header)
    const items = R.map(createExtractionItem, args.items)
    const expected = Object.freeze({
      header: header,
      items: items,
      })
  return expected;
}


const createExtractionHeader = (args: {
  +messageType: string,
  +sourceAddress: string,
  +extractionId: string,
  +userId: string,
  +date: string,
}): ExtractionHeader => {
  return Object.freeze({
    messageType: args.messageType,
    sourceAddress: args.sourceAddress,
    extractionId: args.extractionId,
    userId: args.userId,
    date: args.date,
  });
}


const createExtractionItem = (args: {
    reference: string,
    extractionItem: string,
    extractionId: string,
    extractedFromAp: boolean,
    extractedFromApTimestamp: string,
    messageIdFromMartlet: string,
    messageIdFromMartletTimestamp: string,
    acceptedByMartlet: boolean,
    acceptedByMartletTimestamp: string,
    readByRecipient: boolean,
    readByRecipientTimestamp: string,
    acceptedByRecipient: boolean,
    acceptedByRecipientTimestamp: string,
    klondikeUnaddressedMessageId: string,
    klondikeSubmissionTimestamp: string,
    klondikeMessageToken: string,
    extractionNote: string,

}): ExtractionItem => {
  return Object.freeze({
    reference: args.reference,
    extractionItem: args.extractionItem,
    extractionId: args.extractionId,
    extractedFromAp: args.extractedFromAp,
    extractedFromApTimestamp: args.extractedFromApTimestamp,
    messageIdFromMartlet: args.messageIdFromMartlet,
    messageIdFromMartletTimestamp: args.messageIdFromMartletTimestamp,
    acceptedByMartlet: args.acceptedByMartlet,
    acceptedByMartletTimestamp: args.acceptedByMartletTimestamp,
    readByRecipient: args.readByRecipient,
    readByRecipientTimestamp: args.readByRecipientTimestamp,
    acceptedByRecipient: args.acceptedByRecipient,
    acceptedByRecipientTimestamp: args.acceptedByRecipientTimestamp,
    klondikeUnaddressedMessageId: args.klondikeUnaddressedMessageId,
    klondikeSubmissionTimestamp: args.klondikeSubmissionTimestamp,
    klondikeMessageToken: args.klondikeMessageToken,
    extractionNote: args.extractionNote,
  });
}


const createAddressBookEntry = (args: {
  addressBookEntryId: string,
  ownerAddressId: string,
  localAccount: string,
  localName: string,
  localEmail: string,
  foreignAddressId: string,
}): AddressBookEntry => {
  return Object.freeze({
    addressBookEntryId: args.addressBookEntryId,
    ownerAddressId: args.ownerAddressId,
    localAccount: args.localAccount,
    localName: args.localName,
    localEmail: args.localEmail,
    foreignAddressId: args.foreignAddressId,
  });
}

const createAccessKey = (input: {
  accessKeyId: string,
  organizationId: string,
  accessKeyType: string,
  accessKey: string,
  accessKeySecret: string,
}): AccessKey => {
  return Object.freeze({
    accessKeyId: input.accessKeyId,
    organizationId: input.organizationId,
    accessKeyType: input.accessKeyType,
    accessKey: input.accessKey,
    accessKeySecret: input.accessKeySecret,
  });
}

const createBmbixAddress = (input:{
    id: string,
    organizationId: string,
    alias: string,
    displayName: string,
    isClosed: boolean,
    purpose?: ?string,
    webHook?: ?string,
    webHookToken?: ?string,
}):BmbixAddress => {
  return Object.freeze({
    id: input.id,
    organizationId: input.organizationId,
    alias: input.alias,
    displayName: input.displayName,
    isClosed: input.isClosed,
    purpose: input.purpose,
    webHook: input.webHook,
    webHookToken: input.webHookToken,
  });
}


const createPermission = ({
  permissionId,
  actor,
  power,
  resource,
}: {
  permissionId: string,
  actor: string,
  power: string,
  resource: string,
}):Permission => {
  return Object.freeze({
    permissionId,
    actor,
    power,
    resource,
  });
}


const createBRI = (args: {
  protocol: string,
  resourceType: string,
  resourceId: string,
}): ?BRI => {
  const {
    protocol,
    resourceType,
    resourceId,
  } = args;
  return Object.freeze({
    protocol,
    resourceType,
    resourceId,
  });
}

const createBRIFromString = (aString:string):BRI|null => {
  let protocol, resourceType, step, resourceId;
  const pattern = /([a-zA-Z]+):\/\/([\w-]+)($|\/([\w-]+))/
  const allparts:Array<any>|null  = aString.match(pattern);
  if (allparts === null){
    return null;
  }
  const parts = allparts.slice(1, 5);
  [ protocol, resourceType, step, resourceId, ] = parts;
  if(typeof step === "undefined"){
    return Object.freeze({
      protocol,
      resourceType,
      resourceId: null,
    });
  } else {
    return Object.freeze({
      protocol,
      resourceType,
      resourceId,
    });
  }
}


const createMetadata = ({
  contextId,
  contextType,
  token,
}: {
  contextId: string,
  contextType: ContextType,
  token: string,
}): Metadata => {
  return Object.freeze({
    Authorization: `Bearer ${token}`,
    "x-bmbix-context-type": contextType,
    "x-bmbix-context-id": contextId,
  })
}

const createInvitation = ({
  fromAddress,
  key,
}: {
  fromAddress: Address,
  key: string,
}): Invitation => {
  return Object.freeze({
    fromAddress,
    key,
  })
}


const createAddress = ({
  subaccount,
  accountingSystemId,
}:{
  subaccount: string,
  accountingSystemId: string,
}): Address => {
  return Object.freeze({
    subaccount,
    accountingSystemId,
  })
}


type Args = {
  id: string,
  hash: string;
  accountId: string,
  accountName: string,
  roleId: string,
  roleName: string,
  roleShortDescription: string,
  organizationId: string,
  organizationName: string,
}
const createNoncedOrgRoleAssignment = ({
  id,
  hash,
  accountId,
  accountName,
  roleId,
  roleName,
  roleShortDescription,
  organizationId,
  organizationName,
}:Args):NoncedOrgRoleAssignment => {
  return Object.freeze({
    id,
    hash,
    accountId,
    accountName,
    roleId,
    roleName,
    roleShortDescription,
    organizationId,
    organizationName,
  })
}

type Args2 = {
  id: string,
  hash: string;
  accountId: string,
  accountName: string,
  roleId: string,
  roleName: string,
  roleShortDescription: string,
  accountingSystemId: string,
  accountingSystemName: string,
}
const createNoncedAsRoleAssignment = ({
  id,
  hash,
  accountId,
  accountName,
  roleId,
  roleName,
  roleShortDescription,
  accountingSystemId,
  accountingSystemName,
}:Args2):NoncedAsRoleAssignment => {
  return Object.freeze({
    id,
    hash,
    accountId,
    accountName,
    roleId,
    roleName,
    roleShortDescription,
    accountingSystemId,
    accountingSystemName,
  })
}

type InsertionResultParams = {
  document_id: string,
  success: boolean,
  message: string,
}
const createInsertionResult = ({
  document_id,
  success,
  message,
}: InsertionResultParams): InsertionResult => {
  return Object.freeze({
    document_id: document_id,
    success: success,
    message: message,
  })
}

type ContactParams = {
  name: string;
  email: string;
  telephone: string;
  address1: string;
  address2: string;
  town: string;
  district: string;
  postcode: string;
  country: string;
  ledgerCode: string;
  ledgerDatabaseKey: string;
  businessName: string;
}
const createContact = ({
  name,
  email,
  telephone,
  address1,
  address2,
  town,
  district,
  postcode,
  country,
  ledgerCode,
  ledgerDatabaseKey,
  businessName,
}: ContactParams): Contact => {
  return Object.freeze({
    name,
    email,
    telephone,
    address1,
    address2,
    town,
    district,
    postcode,
    country,
    ledgerCode,
    ledgerDatabaseKey,
    businessName,
  })
}

type RouteParams = {
  ledgerCode: string,
  accountingSystemId: string,
  accountingSystemName: string,
  originAddress: Address,
  destinationAddress: Address,
}
const createRoute = (
  params: RouteParams,
): Route => {
  return Object.freeze({
    ledgerCode: params.ledgerCode,
    accountingSystemId: params.accountingSystemId,
    accountingSystemName: params.accountingSystemName,
    originAddress: params.originAddress,
    destinationAddress: params.destinationAddress,
  })
}

type Route2Params = {
  originAddress: Address,
  destinationAddress: Address,
}
const createRoute2 = (
  params: Route2Params,
): Route2 => {
  return Object.freeze({
    originAddress: params.originAddress,
    destinationAddress: params.destinationAddress,
  })
}


const createInvoiceMetadataV5 = (args: {
  createdAt: string,
  id: string,
  type: string,
  reference: string,
  date: string,
  total: string,
  currencyCode: string,
  racoss: string,
  sbasic: string,
  submitterAccountId: string,
  sacors: string,
  rbasic: string,
  receiverDeliverabilityStatus: string,
  receiverInsertabilityStatus: string,
  postingStatus: string,
  receiverReference: string,
  notes: string,
}): InvoiceMetadataV5 => {
  return Object.freeze({
    createdAt: args.createdAt,
    id: args.id,
    type: args.type,
    reference: args.reference,
    date: args.date,
    total: args.total,
    currencyCode: args.currencyCode,
    racoss: args.racoss,
    sbasic: args.sbasic,
    submitterAccountId: args.submitterAccountId,
    sacors: args.sacors,
    rbasic: args.rbasic,
    receiverDeliverabilityStatus: args.receiverDeliverabilityStatus,
    receiverInsertabilityStatus: args.receiverInsertabilityStatus,
    postingStatus: args.postingStatus,
    receiverReference: args.receiverReference,
    notes: args.notes,
  })
}

const createSupplier = (args: {
  name: string,
  id: string,
  sacors: string,
}) => {
  return Object.freeze({
    name: args.name,
    id: args.id,
    sacors: args.sacors,
  })
}

const createInvoiceV5Header = (args: {
  number: string,
  date: string,
  seller: string,
  buyer: string,
  currencyCode: string,
  net: string,
  vat: string,
  gross: string,
  sellerName: string,
  sellerAddress1: string,
  sellerAddress2: string,
  sellerAddress3: string,
  sellerBusinessRegistrationNumber: string,
  sellerTaxRegistrationNumber: string,
  customerName: string,
  customerAddress1: string,
  customerAddress2: string,
  customerAddress3: string,
  customerBusinessRegistrationNumber: string,
  customerTaxRegistrationNumber: string,
}) => {
  return Object.freeze({
    number: args.number,
    date: args.date,
    seller: args.seller,
    buyer: args.buyer,
    currencyCode: args.currencyCode,
    net: args.net,
    vat: args.vat,
    gross: args.gross,
    sellerName: args.sellerName,
    sellerAddress1: args.sellerAddress1,
    sellerAddress2: args.sellerAddress2,
    sellerAddress3: args.sellerAddress3,
    sellerBusinessRegistrationNumber: args.sellerBusinessRegistrationNumber,
    sellerTaxRegistrationNumber: args.sellerTaxRegistrationNumber,
    customerName: args.customerName,
    customerAddress1: args.customerAddress1,
    customerAddress2: args.customerAddress2,
    customerAddress3: args.customerAddress3,
    customerBusinessRegistrationNumber: args.customerBusinessRegistrationNumber,
    customerTaxRegistrationNumber: args.customerTaxRegistrationNumber,
  })
}

const createInvoiceV5Line = (args: {
  quantity: string,
  unitPrice: string,
  unitOfMeasure: string,
  itemCode: string,
  description: string,
  net: string,
  vatCode: string,
  vat: string,
  gross: string,
}) => {
  return Object.freeze({
    quantity: args.quantity,
    unitPrice: args.unitPrice,
    unitOfMeasure: args.unitOfMeasure,
    itemCode: args.itemCode,
    description: args.description,
    net: args.net,
    vatCode: args.vatCode,
    vat: args.vat,
    gross: args.gross,
  })
}

const createInvoiceV5 = ({
  header,
  lines,
}) => {
  return Object.freeze({
    header: header,
    lines: lines,
  })
}

const createUserASRole = ({
  accountingSystemId,
  roleId,
  roleShortDescription,
  accountId,
  accountName,
}) => {
  return Object.freeze({
    accountingSystemId: accountingSystemId,
    roleId: roleId,
    roleShortDescription: roleShortDescription,
    accountId: accountId,
    accountName: accountName,
  })
}

const createUserOrgRole = ({
  organizationId,
  roleId,
  roleShortDescription,
  accountId,
  accountName,
}) => {
  return Object.freeze({
    organizationId: organizationId,
    roleId: roleId,
    roleShortDescription: roleShortDescription,
    accountId: accountId,
    accountName: accountName,
  })
}

const createRole = ({
  id,
  shortDescription,
}) => {
  return Object.freeze({
    id: id,
    shortDescription: shortDescription,
  })
}

const createAccount = (args: {
  id: string,
  name: string,
  email: string,
  cognitoUsername: string,
  sub: string,
}): Account => {
  return Object.freeze({
    id: args.id,
    name: args.name,
    email: args.email,
    cognitoUsername: args.cognitoUsername,
    sub: args.sub,
  })
}

const createUserAccountingSystemRoleAssignment = ({
  accountingSystemId,
  roleId,
  accountId,
}:{
  accountingSystemId: string,
  roleId: string,
  accountId: string,
}): UserAccountingSystemRoleAssignment => {
  return Object.freeze({
    accountingSystemId: accountingSystemId,
    roleId: roleId,
    accountId: accountId,
  })
}

const createUserOrganizationRoleAssignment = ({
  organizationId,
  roleId,
  accountId,
}) => {
  return Object.freeze({
    organizationId: organizationId,
    roleId: roleId,
    accountId: accountId,
  })
}

const createNotification = ({
  id,
  content,
}) => {
  return Object.freeze({
    id: id,
    content: content,
  })
}

const createAccountingSystem = (
  id: string,
  name: string,
  organizationId: string,
  ledgerType: string,
  platformId: ?string,
  autoinsertEnabled: boolean,
  isClosed: boolean,
): AccountingSystem => {
  const o = Object.freeze({
    id: id,
    ledgerType: ledgerType,
    name: name,
    organizationId: organizationId,
    platformId: platformId,
    autoinsertEnabled: autoinsertEnabled,
    isClosed: isClosed,
  });
  return o;
}

const createPlatform = (args: {
  platformId: string,
  organizationId: string,
  type: string,
  name: string,
}): Platform => {
  return Object.freeze({
    platformId: args.platformId,
    organizationId: args.organizationId,
    type: args.type,
    name: args.name,
  })
}

const createKashflowCredentials = (
  username: string,
  password: string,
): KashflowCredentials => {
  return Object.freeze({
    username: username,
    password: password,
  })
}

const createXeroCredentials = (
  consumerKey: string,
  privateKey: string,
): XeroCredentials => {
  return Object.freeze({
    consumerKey: consumerKey,
    privateKey: privateKey,
  })
}

const createAccountingSystemPlatformAssignment = (
  accountingSystemId: string,
  platformId: string,
): AccountingSystemPlatformAssignment => {
  return Object.freeze({
    accountingSystemId: accountingSystemId,
    platformId: platformId,
  })
}

const createPlatformAssignment = (args: {
  platformAssignmentId: string,
  addressId: string,
  platformId: string,
}): PlatformAssignment => {
  return Object.freeze({
    platformAssignmentId: args.platformAssignmentId,
    addressId: args.addressId,
    platformId: args.platformId,
  })
}

const createEmailServer = (
  id: string,
  organizationId: string,
  address: string,
  port: string,
  encryption: string,
  username: string,
  password: string|null,
  senderName: string,
  senderEmail: string,
): EmailServer => {
  return Object.freeze({
    id: id,
    organizationId: organizationId,
    address: address,
    port: port,
    encryption: encryption,
    username: username,
    password: password,
    senderName: senderName,
    senderEmail: senderEmail,
  })
}

type LogoParams = {
  base64Data: Uint8Array,
  mediaType: string,
  size: string,
  logoId: string,
}
const createLogo = ({
    base64Data,
    mediaType,
    logoId,
    size,
}: LogoParams) => {
    return Object.freeze({
        base64Data: base64Data,
        mediaType: mediaType,
        logoId: logoId,
        size: size,
    })
}

type createOrganizationParams = {
  id: string,
  name: string,
  isClosed: boolean,
  sicCode: string,
  headcount: number,
  ppcSignatory: boolean,
  paymentContactEmail: string,
}
const createOrganization = (
  {
    id,
    name,
    isClosed,
    sicCode,
    headcount,
    ppcSignatory,
    paymentContactEmail,
  }: createOrganizationParams,
): Organization => {
  const o = Object.freeze({
    id: id,
    name: name,
    isClosed: isClosed,
    sicCode: sicCode,
    headcount: headcount,
    ppcSignatory: ppcSignatory,
    paymentContactEmail: paymentContactEmail,
  });
  return o;
}

const createUploadedInvoice = (
  createdAt: string,
  id: string,
  type: string,
  reference: string,
  date: string,
  total: string,
  currencyCode: string,
  racoss: string,
  submitterAccountId: string,
): UploadedInvoice => {
  return Object.freeze({
    createdAt: createdAt,
    id: id,
    type: type,
    reference: reference,
    date: date,
    total: total,
    currencyCode: currencyCode,
    racoss: racoss,
    submitterAccountId: submitterAccountId,
  })
}


const InstanceServiceFactory = () => ({
  createAcceptance,
  createAcceptancesResponse,
  createAccessKey,
  createAccount,
  createAccountingSystem,
  createAccountingSystemPlatformAssignment,
  createAddress,
  createAddressProfile,
  createAddressBookEntry,
  createAuthenticatedTenant,
  createBillingAddress,
  createBmbixAddress,
  createBmbixRole,
  createBRI,
  createBRIFromString,
  createCertificate,
  createCertificateDetail,
  createClaim,
  createContact,
  createECRCompletedNotice,
  createExtraction,
  createExtractionCandidateReport,
  createExtractionCandidateReportEntry,
  createExtractionCandidateReportHeader,
  createExtractionItem,
  createExtractionHeader,
  createExtractionItemWithHeader,
  createInsertionInstruction,
  createInsertionReceipt,
  createInsertionResult,
  createInvitation,
  createInvoiceMetadataV5,
  createInvoiceV5,
  createInvoiceV5Header,
  createInvoiceV5Line,
  createKashflowCredentials,
  createLogo,
  createMessage,
  createMessageNote,
  createMessageReadReceipt,
  createMessagesCount,
  createMetadata,
  createNoncedOrgRoleAssignment,
  createNoncedAsRoleAssignment,
  createNotification,
  createOauth2Client,
  createOrganization,
  createOrganizationProfile,
  createOrganizationProxy,
  createPaymentMethod,
  createPaymentMethodLink,
  createPermission,
  createPlatform,
  createPlatformAssignment,
  createPlatformTenant,
  createProcessedReceipt,
  createPublicKey,
  createReceivedMessage,
  createReceivedMessagesResponse,
  createRejectedMessage,
  createRejectedMessagesResponse,
  createRole,
  createRoute,
  createRoute2,
  createSelectionMetadata,
  createSettings,
  createStripeIntent,
  createStripeSetupIntent,
  createSupplier,
  createTenantMapping,
  createToken,
  createUnaddressedMessage,
  createUnaddressedMessagesResponse,
  createUploadedInvoice,
  createUserAccountingSystemRoleAssignment,
  createUserASRole,
  createUserOrganizationRoleAssignment,
  createUserOrgRole,
  createUserProfile,
  createXeroCredentials,
  createEmailServer,
})

export const instanceService = InstanceServiceFactory();
