// @flow
import React, {
} from "react"
import {
  withRouter,
} from "react-router";
import { useSelector } from "react-redux";
import {
  connect,
} from "react-redux";
import * as R from "ramda";
import * as S from "sanctuary";

import type {
  Organization,
  BmbixAddress,
} from "../Entity/Types"
import
AddressesUserTable
from "../Addresses/AddressesUserTable"
import { type DecoratedAddress } from ".";
import { decorateAddress as decorate } from "./../Util";



const OrganizationUserPage = (props: {
  addresses: Array<BmbixAddress>,
  organization: Organization,
}) => {
  const {organization, addresses}  = props;
  const platformAssignments = useSelector(state => state.platformAssignments)
  const platforms = useSelector(state => state.platforms)

  const decoratedAddresses: Array<DecoratedAddress> = S.map (
    decorate (platformAssignments) (platforms)
  ) (addresses)

  return (
    <div>
    <AddressesUserTable
      decoratedAddresses={decoratedAddresses}
      organizationId={organization.id}
    />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  if (ownProps.match === undefined){
    throw new Error("8344");
  }
  console.log("props", ownProps);
  const organizationId = ownProps.match.params.organizationId;
  return {
    addresses: R.filter(
      ad => ad.organizationId === organizationId,
      state.addresses
    ),
    organization: R.find(R.propEq("id", organizationId))(state.orgs),
  }
}

const Routed = connect(mapStateToProps)(withRouter(OrganizationUserPage));

export default Routed;
