// @flow
import * as R from "ramda";

import { instanceService } from "../../Entity/InstanceService.js";
import {
  type ExtractionItemWithHeader ,
  type Extraction,
} from "../../Entity/Types.js";

import {
  ApiClient,
  ExtractionsApi,
  ExtractionItemWithHeader as ExtractionItemWithHeaderGW,
  Extraction as ExtractionGW,
  ExtractionHeader as ExtractionHeaderGW,
  ExtractionItem as ExtractionItemGW,
} from "@bmbix/bmb_isimud_client";


const createExtractionItemWithHeaderFromGW = (
  extractionItemWithHeaderGW: ExtractionItemWithHeaderGW,
): ExtractionItemWithHeader => {

  const extractionItemGW = extractionItemWithHeaderGW.item;
  const extractionHeaderGW = extractionItemWithHeaderGW.header;

  return instanceService.createExtractionItemWithHeader({
    header: instanceService.createExtractionHeader({
      messageType:extractionHeaderGW.message_type,
      sourceAddress: extractionHeaderGW.source_address,
      extractionId: extractionHeaderGW.extraction_id,
      userId: extractionHeaderGW.user_id,
      date: extractionHeaderGW.date,
    }),
    item: instanceService.createExtractionItem({
      reference: extractionItemGW.reference,
      extractionItem: extractionItemGW.extraction_item,
      extractionId: extractionItemGW.extraction_id,
      extractedFromAp: extractionItemGW.extracted_from_ap,
      extractedFromApTimestamp: extractionItemGW.extracted_from_ap_timestamp,
      messageIdFromMartlet: extractionItemGW.message_id_from_martlet,
      messageIdFromMartletTimestamp: extractionItemGW.message_id_from_martlet_timestamp,
      acceptedByMartlet: extractionItemGW.accepted_by_martlet,
      acceptedByMartletTimestamp: extractionItemGW.accepted_by_martlet_timestamp,
      readByRecipient: extractionItemGW.read_by_recipient,
      readByRecipientTimestamp: extractionItemGW.read_by_recipient_timestamp,
      acceptedByRecipient: extractionItemGW.accepted_by_recipient,
      acceptedByRecipientTimestamp: extractionItemGW.accepted_by_recipient_timestamp,
      klondikeUnaddressedMessageId: extractionItemGW.klondike_unaddressed_message_id,
      klondikeSubmissionTimestamp: extractionItemGW.klondike_submission_timestamp,
      klondikeMessageToken: extractionItemGW.klondike_message_token,
      extractionNote: extractionItemGW.extraction_note,
    }),
  })
};


const getExtractionItemWithHeaders = (args: {
  token: string,
  organizationId: string,
  addressId: string,
  fromDate: string,
  toDate: ?string,
}): Promise<Array<ExtractionItemWithHeader>> => {
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = args.token;
  let apiInstance = new ExtractionsApi();
  return new Promise((resolve, reject) => {
    const opts:{
      fromDate: string,
      toDate?: string,
    } = {
      fromDate: args.fromDate,
    };
    if (args.toDate){
      opts["toDate"] = args.toDate
    }
    apiInstance.selectExtractionItemsForReference(args.organizationId, args.addressId, opts, (error, data, response) => {
      if (error) {
        resolve(error);
      } else {
        const itemsGW= data.extraction_item_with_headers;
        const items = R.map(createExtractionItemWithHeaderFromGW, itemsGW);
        resolve(items);
      }
    });
  });
}


const createExtractionFromGW = (extractionGW: ExtractionGW): Extraction => {
  const expected = instanceService.createExtraction({
    header: instanceService.createExtractionHeader({
      messageType: extractionGW.header.message_type,
      sourceAddress: extractionGW.header.source_address,
      extractionId: extractionGW.header.extraction_id,
      userId: extractionGW.header.user_id,
      date: extractionGW.header.date,
    }),
    items: R.map(
      itemGW => instanceService.createExtractionItem({
        reference: itemGW.reference,
        extractionItem: itemGW.extraction_item,
        extractionId: itemGW.extraction_id,
        extractedFromAp: itemGW.extracted_from_ap,
        extractedFromApTimestamp: itemGW.extracted_from_ap_timestamp,
        messageIdFromMartlet: itemGW.message_id_from_martlet,
        messageIdFromMartletTimestamp: itemGW.message_id_from_martlet_timestamp,
        acceptedByMartlet: itemGW.accepted_by_martlet,
        acceptedByMartletTimestamp: itemGW.accepted_by_martlet_timestamp,
        readByRecipient: itemGW.read_by_recipient,
        readByRecipientTimestamp: itemGW.read_by_recipient_timestamp,
        acceptedByRecipient: itemGW.accepted_by_recipient,
        acceptedByRecipientTimestamp: itemGW.accepted_by_recipient_timestamp,
        klondikeUnaddressedMessageId: itemGW.klondike_unaddressed_message_id,
        klondikeSubmissionTimestamp: itemGW.klondike_submission_timestamp,
        klondikeMessageToken: itemGW.klondike_message_token,
      }),
      extractionGW.items,
    ),
  });
  return expected;
}


const submitExtractionRequest = (args:{
  token: string,
    organizationId: string,
    addressId: string,
    messageType: string,
    references: Array<string>,
}): Promise<Extraction> => {
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = args.token;
  let apiInstance = new ExtractionsApi();
  return new Promise((resolve, reject) => {
    const header = new ExtractionHeaderGW()
    header.message_type = args.messageType;
    const items = R.map(
      r => ExtractionItemGW.constructFromObject({
        reference: r,
      }),
      args.references,
    );
    const body = new ExtractionGW();
    body.header = header;
    body.items = items;
    apiInstance.insert(body, args.organizationId, args.addressId, (error, data, response) => {
      if (error) {
        resolve(error);
      } else {
        const extractionGW= data.extraction;
        const extraction = createExtractionFromGW(extractionGW);
        resolve(extraction);
      }
    });
  });
}


export {
  createExtractionFromGW,
  createExtractionItemWithHeaderFromGW,
  getExtractionItemWithHeaders,
  submitExtractionRequest,
}
