// @flow
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {
  useForm,
} from "react-hook-form";


const View = (
  {
    apiState,
    onModalSubmit,
    onModalCancel,
  }: {
    apiState: Function,
    onModalSubmit: Function,
    onModalCancel: Function,
  }) => {

  console.log("apiState: ", apiState);


  const {
    handleSubmit,
  } = useForm({
  });


  return (apiState.value === "ready" || apiState.value === "unconfigured")
  ? <Modal show={true}>
    <Modal.Body>
      <Form onSubmit={handleSubmit(onModalSubmit)}>
      <Button type="submit">Submit</Button>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={onModalCancel}>Cancel</Button>
    </Modal.Footer>
    </Modal>
  : apiState.value === "working"
  ? <p>Working ...</p>
  : apiState.value === "success"
  ? <p>Success!</p>
  : <p>Failure</p>

}


export {
  View,
}
