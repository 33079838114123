// @flow
import React, {
  useState,
} from "react";
import {
  Link,
} from "react-router-dom";
import Form from "react-bootstrap/Form";
import * as R from "ramda";
import * as S from "sanctuary";
import Button from "react-bootstrap/Button";

import {
  type BmbixAddress,
} from "../../../Entity/Types.js";

type Error = "no-addresses" | "other";

const ErrorView = (props: {
  error: Error,
}) => {
  const {
    error,
  } = props;
  switch (error) {
    case "no-addresses":
      return <>
        <h2>Hi there!</h2>
        <p>It looks like you have no addresses which can claim this message.</p>
        <h3>What next?</h3>
        <p>If you have manager rights to an organization, you can create an address for that organization.</p>
        <p>If not, you can create your own organization and then create an
      address.</p>
        <p>Most likely you want to use the setup wizard. This will create an organization and sales and purchase ledger addresses for you. Just click here: <Link to="/new-user-wizard-2"><Button>New User Wizard</Button></Link></p>
        <p>Alternatively, to set up organizations and addresses manually, you can start here: <Link to="/m/organizations"><Button>Manage Organizations</Button></Link></p>
        </>
    default:
      return <>Error {`${error}`} occurred for which we have no further information</>
  }
}

const SelectionClaimView = (props: {
  selectedAddressId?: ?string,
  messageToken: string,
  addresses: Array<BmbixAddress>,
  onClaim: (messageToken: string, addressId: string) => void,
}) => {
  const maybeSelectedAddressId = S.get (x => x !== null) ("selectedAddressId") (props);
  const defaultAddressId = S.maybe
    (props.addresses[0].id)
    (S.I)
    (maybeSelectedAddressId)

  const [messageToken, setMessageToken] = useState(props.messageToken);
  const [addressId, setAddressId] = useState(defaultAddressId);

  const options = R.map(
    addr => <option key={addr.id} value={addr.id}>{addr.alias}</option>,
    props.addresses,
  );

  return (
    <>
    <h1>Claim Unaddressed Message</h1>
    <p>You can use this page to claim a message from a sender who does not know
    your Bmbix address. Enter the claim code they have given you and choose the
    address you want to deliver the message to.</p>
    <Form>
    <Form.Group>
    <Form.Label>Enter claim code</Form.Label>
    <Form.Control type="text" onChange={e => setMessageToken(e.target.value)} defaultValue={messageToken} />
    <Form.Text>Enter the message token</Form.Text>
    </Form.Group>
    <Form.Group>
    <Form.Label>Select destination address</Form.Label>
    <Form.Control
      as="select"
      defaultValue={defaultAddressId}
      onChange={e => setAddressId(e.target.value)}
    >
    {options}
    </Form.Control>
    <Form.Text>Choose the destination address for this message. You can come
    back and change this before you commit.</Form.Text>
    </Form.Group>
    </Form>
    <Button className="mr-2" onClick={e => props.onClaim(messageToken, addressId)}>Claim</Button>
    </>
  );
}

const ConfirmClaimView = (props: {
  unaddressedMessage: Object,
  onConfirm: Function,
  onCancel: Function,
}) => {
  const {
    unaddressedMessage,
    onConfirm,
    onCancel,
  } = props;
  const alreadyClaimed = !!unaddressedMessage.claimedAt;

  const unclaimed = !alreadyClaimed ? (
    <>
    <hr/>
    <h2>Claim code:</h2>
    <p>{unaddressedMessage.messageToken}</p>
    <hr/>
    <h2>From:</h2>
    <p>{unaddressedMessage.alias}</p>
    <hr/>
    <h2>Message type:</h2>
    <p>{unaddressedMessage.messageType}</p>
    <hr/>
    <h2>Sender reference:</h2>
    <p>{unaddressedMessage.senderReference}</p>
    <Button className="mr-2" onClick={onConfirm}>Confirm</Button>
    </>
  ): null;

  const claimed = alreadyClaimed ? (
    <p>This code was claimed already!</p>
  ): null;

  return (
    <>
    <h1>Confirm Message Claim</h1>
    {unclaimed}
    {claimed}
    <Button className="mr-2" onClick={onCancel}>Go back</Button>
    </>
  );
}

const ResultClaimView = (props: {
  messageToken: ?string,
  messageId: string,
  address: BmbixAddress,
  callBack: () => void,
}) => {
  const {
    messageToken,
    messageId,
    address,
    callBack,
  } = props;

  const organizationId = address.organizationId;
  const addressId = address.id;
  const pathname = `/u/organizations/${organizationId}/addresses/${addressId}/messages-status/${messageId}`;
  return (
    <>
    <h2>Claim completed</h2>
    <p>Claimed {messageToken}</p>
    <p>Delivered to Bmbix with message id: {messageId}</p>
    <Link to={pathname}><Button className="mr-2">Track message</Button></Link>
    <Button className="mr-2"  onClick={callBack}>Claim another</Button>
    </>
  );
}

export {
  ErrorView, ResultClaimView, SelectionClaimView, ConfirmClaimView,
}
