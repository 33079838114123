// @flow
import { createElement as el, useState } from "react";
import { fork } from "fluture";

import { withRouter } from "react-router-dom";

import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "../../../Util";

import { withValidateUser } from "../../Api";
import { InputView, WaitingView, SuccessView, FailureView } from "./view.js";
import { type ValidateUserRequest } from "../../entity.js";

const Controller = withRouter(withValidateUser((props: {
  match: Function,
  validateUser: Function,
}) => {
  const {
    match,
    validateUser,
  } = props;
  const organizationId = match.params.organizationId;

  const [cn, setCn] = useState();
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [locality, setLocality] = useState();
  const [bmbixId, setBmbixId] = useState();
  const [display, setDisplay] = useState(INPUT);
  const [certificate, setCertificate] = useState();

  const handleChange = e => {
    console.log(e.currentTarget.name, e.currentTarget.value);
    const n = e.currentTarget.name;
    const v = e.currentTarget.value;
    switch (n) {
      case "bmbixId":
        setBmbixId(v);
        break;
      case "cn":
        setCn(v);
        break;
      case "country":
        setCountry(v);
        break;
      case "locality":
        setLocality(v);
        break;
      case "state":
        setState(v);
        break;
      default:
        break;
    }
  }

  const handleSubmit = e => {
    console.log(e);
    setDisplay(WAITING);
    const body: ValidateUserRequest = Object.freeze({
      bmbixId,
      cn,
      country,
      locality,
      state,
    });

    const action = validateUser(organizationId)(body);

    fork(error => {
      console.log(error);
      setDisplay(FAILURE);
    })(certificate => {
      console.log(certificate);
      setCertificate(certificate);
      setDisplay(SUCCESS);
    })(action)
  }

  return display === INPUT ? el(
    InputView,
    {
      display,
      onChange: handleChange,
      onSubmit: handleSubmit,
    }
  ): display === WAITING ? el (
    WaitingView,
    {
    },
  ): (display === SUCCESS) && (!!certificate)? el (
    SuccessView,
    {
      certificate,
      organizationId,
    },
  ): display === FAILURE ? el (
    FailureView,
    {
    }
  ): null;

}));

export { Controller }
