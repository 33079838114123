// @flow

import React from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import * as S from "sanctuary";
import {
  useForm,
} from "react-hook-form";
import {
  Spinner,
} from "../../../Widgets/Toast";


const View = (props: {
  display: string,
  timezone: ?string,
  timezones: Array<Function>,
  onSubmit: Function,
  onClose: Function,
}) => {
  const {
    state,
    timezone,
    timezones,
    onSubmit,
    onClose,
  } = props;

  const { register, handleSubmit, formState: { errors } }
    = useForm({defaultValues: {timezone}});
  const options = S.map (S.compose (n => <option>{n}</option>) (S.prop ("name"))) (timezones);

  const form = <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Group>
    <Form.Label>Time zone</Form.Label>
          <Form.Control as="select" {...register("timezone", {required: true})}>
            {options}
          </Form.Control>
    <Form.Text>Choose your time zone</Form.Text>
      {errors.timezone && <span>This field is required</span>}
    </Form.Group>
    <Button type="submit" variant="primary" >Save changes</Button>
      </Form>;
  const spinner = <Spinner height="24"/>;
  const successMessage = <p>All good, timezone was updated</p>;
  const failureMessage = <p>Oops, something has gone wrong.</p>;

  const body = state === "idle"
    ? form
    : state === "working"
    ? spinner
    : state === "succeeded"
    ? successMessage
    : state === "failed"
    ? failureMessage
    : <p>Unknown state</p>;

  return (
    <Modal show={true}>
    <Modal.Header closeButton onHide={onClose}>Set Timezone</Modal.Header>
    <Modal.Body>
    {body}
    </Modal.Body>
    <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>Close</Button>
    </Modal.Footer>
    </Modal>
  );
}

export {
  View,
};
