// @flow

import * as S from "sanctuary";
import {
  useSelector,
} from "react-redux";
import {
  type AddressProfile,
} from "../Entity/Types";

const MERGE_ADDRESS_PROFILES = "MERGE_ADDRESS_PROFILES";

const mergeAddressProfiles= (addressProfiles: {[string]: AddressProfile}) => {
  return {
    type: MERGE_ADDRESS_PROFILES,
    addressProfiles: addressProfiles,
  }
}

const reducer = (state:{[string]: AddressProfile}={}, action:Function) => {
  switch (action.type) {
    case MERGE_ADDRESS_PROFILES:
      const update =
        initialState =>
        addressProfile =>
        S.insert (S.prop ("addressId")(addressProfile)) (addressProfile) (initialState);
      const newState = S.reduce (update) (state) (action.addressProfiles);
      return newState;
    default:
      return state;
  }
}


const exists =
  (addressProfiles: {[string]: AddressProfile}) =>
  (addressId: string): boolean =>
  S.elem (addressId) (S.keys (addressProfiles));


const useAddressProfiles = () => {
  return useSelector(state => state.addressProfiles);
}


export {
  reducer,
  reducer as addressProfiles,
  mergeAddressProfiles,
  exists,
  useAddressProfiles,
}
