// @flow

import type {
  Connection,
} from './Types'


const createConnection = (args: {
  platformId: string,
  state: string,
  accessToken: string,
  refreshToken: string,
  expiresAtUtc: string,
}): Connection => {
  const {
    platformId,
    state,
    accessToken,
    refreshToken,
    expiresAtUtc,
  } = args;
  return Object.freeze({
    platformId,
    state,
    accessToken,
    refreshToken,
    expiresAtUtc,
  });
}

export const InstanceServiceFactory = () => ({
  createConnection,
})

export const instanceService = InstanceServiceFactory();
