// @flow
import * as S from "sanctuary";
import {
  fork,
  both,
  promise,
} from "fluture";
import {
  assign,
  createMachine,
} from "xstate";

import { type Oauth2Client } from "./../Entity/Types.js";
import { instanceService } from "./../Entity/InstanceService.js";
import { getProxies as fetchProxiesFromApi } from "./Api/get-proxies.js";
import { fetch as fetchClientsFromApi } from "./Api/fetch.js";
import {
  type OrganizationProxyOauth2Client,
  createOrganizationProxyOauth2Client,
} from "./entity.js";

const OAUTH2CLIENT_FETCH = "OAUTH2CLIENT_FETCH";
const OAUTH2CLIENT_ADD = "OAUTH2CLIENT_ADD";
const OAUTH2CLIENT_DELETE = "OAUTH2CLIENT_DELETE";


const fetchOauth2Clients = (oauth2Clients: Array<Oauth2Client>) => {
  return {
    type: OAUTH2CLIENT_FETCH,
    oauth2Clients
  }
}

const addOauth2Client = (oauth2Client: Oauth2Client) => {
  return {
    type: OAUTH2CLIENT_ADD,
    oauth2Client
  }
}

const deleteOauth2Client = (oauth2ClientId:string) => {
  return {
    type: OAUTH2CLIENT_DELETE,
    oauth2ClientId,
  }
}


const oauth2Clients = (state: Array<Oauth2Client>=[], action: Function) => {
  switch(action.type){
    case OAUTH2CLIENT_FETCH:
      return action.oauth2Clients;
    case OAUTH2CLIENT_ADD:
      return S.append (action.oauth2Client) (state);
    case OAUTH2CLIENT_DELETE:
      return S.reject (k => k.oauth2ClientId === action.oauth2ClientId) (state);
    default:
      return state;
  }
}


const getOrganizationProxyOauth2Clients =
  (token: string) =>
  (organizationId: string) =>
{
  const blank = instanceService.createOauth2Client({});
  const thunk = (dispatch, getState) => {
    return new Promise((res, rej) => {

      const fetchProxies = () => fetchProxiesFromApi(token)(organizationId);
      const fetchClients = () => fetchClientsFromApi(token)(organizationId);

      const reject = something => rej(something);

      const resolve =
        (pair: [Array<OrganizationProxy>, Array<Oauth2Client>]) =>
      {
        const [ organizationProxies, oauth2Clients ] = pair;

        const i = (c: oauth2Client) => S.Pair (c.resource) (c);

        const oauth2ClientsStrMap = S.fromPairs (S.map (i) (oauth2Clients));

        const h = (o: organizationProxy) => `bmbix://organization-proxy/${o.id}`;

        const g = (o: organizationProxy) => S.value (h(o)) (oauth2ClientsStrMap);

        const k = (o: organizationProxy) => S.fromMaybe (blank) (g(o));

        const f = (o: OrganizationProxy) => createOrganizationProxyOauth2Client({
          organizationProxy: o,
          oauth2Client: k(o)
        });
        res(S.map (f) (organizationProxies));
      }
      fork(reject)(resolve)(both(fetchProxies())(fetchClients()));

    });
  }
  return thunk;
}


export {
  addOauth2Client,
  deleteOauth2Client,
  fetchOauth2Clients,
  oauth2Clients,
  getOrganizationProxyOauth2Clients,
}


