// @flow

export type WebHook = {
  URL: string,
  token: string,
}


const createWebHook = (args: {
  token: string,
  URL: string,
}): WebHook => {
  return Object.freeze({
    token: args.token,
    URL: args.URL,
  });
}

export {
  createWebHook,
}
