// @flow

const selectStylesheetLocation = (contentMediaType: string) =>
          contentMediaType === "bmbix-simplified-invoice-5"
          ? "/invoice.sef.json"
          : contentMediaType === "ubl-reminder-2-2"
          ?  "/ubl_reminder_2_2.sef.json"
          : contentMediaType === "ubl-invoice-2-2"
          ?  "/ubl_invoice_2_2.sef.json"
          : null;

export {
  selectStylesheetLocation,
}
