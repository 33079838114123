// @flow
import React from "react";
import * as R from "ramda";

import {
  AddController as AddMessageNoteWidget,
} from "./AddController.js";
import {
  type EnrichedMessageNote,
} from "./Entity.js";
import {
  UserProfileWidget,
} from "../UserProfile";
import {instanceService} from "../../Entity/InstanceService.js";
import {RelativeTimeWidget} from "../RelativeTimeWidget";


const NoteView = (props: {
  note: EnrichedMessageNote,
}) => {
  const {
    note,
  } = props;

  const userProfile = instanceService.createUserProfile({
    userId: note.userId,
    name: note.name,
    verified: note.verified,
    iconUrl: note.iconUrl,
  });

  const timestamp = new Date(Date.parse(note.submittedAtUtc+"Z"))

  return (
    <>
    <hr/>
    <UserProfileWidget userProfile={userProfile}/> <RelativeTimeWidget timestamp={timestamp} />
    <p style={{paddingLeft: 5, paddingTop: 5}}>{note.content}</p>
    </>
  )
}

const View = (props: {
  messageNotes: Array<EnrichedMessageNote>,
  addressId: string,
  messageId: string,
}) => {
  const {
    messageNotes,
    messageId,
    addressId,
  } = props;

  const scrollStyle = {
    overflow: "scroll",
    height: "50%",

  }

  const notes = (
    <div style={scrollStyle}>
    {R.map(n => <NoteView key={n.noteId} note={n}/>, messageNotes)}
    </div>
  )

  return (
    <>
      <h2>Notes</h2>
      {notes}
      <AddMessageNoteWidget addressId={addressId} messageId={messageId} />
    </>
  );
}

export {
  View,
}
