// @flow
import Future from "fluture";

import {
  ApiClient,
  AddressesApi,
  MessageType as MessageTypeGW,
} from "@bmbix/bmb_martlet_organization_client";

import { gwToMessageType } from "./adapt.js";

const addMessageType =
  (token: string) =>
  (address: string) =>
  (messageType: string) =>
  (direction: string) =>
  (retentionPolicy: string): Future =>
{
  console.log("token", token);
  console.log("address", address);

  const apiInstance = new AddressesApi();
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;

  const body = MessageTypeGW.constructFromObject({
    message_type: messageType,
    direction: direction,
    retention_policy: retentionPolicy,
  })

  return Future((reject, resolve) => {
    apiInstance.createMessageType(body, address, (error, data, response) => {
      if (error) {
        console.log("error", error);
        reject(error);
      } else {
        const gw = data.message_type;
        const messageType = gwToMessageType(gw);
        resolve(messageType);
      }
    });

    return console.log
  });
}

export { addMessageType }
