// @flow
import React, {
  useState,
  useEffect,
} from "react";
import * as R from "ramda";
import {
  connect,
} from "react-redux";
import ButtonToolbar from "react-bootstrap/ButtonToolbar"
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  withRouter,
} from "react-router";
import log from "loglevel";
import {
  PlusIcon,
  XCircleIcon,
  CheckCircleIcon,
} from "@primer/octicons-react";

import type {
  BmbixAddress,
  Organization,
  Permission,
} from "../Entity/Types"
import {AddModal} from "../Addresses/AddModal";
import {OpenButton} from "../Addresses/OpenButton.js";
import {CloseButton} from "../Addresses/CloseButton.js";
import {
  AccountingSystemsListOpen,
  AccountingSystemsListClosed,
} from "./Table";
import {
  addAddress,
  deleteAddress,
  addPermission,
  deletePermission,
} from "../State";
import { useAccount } from "./../State/Account.js";
import { useToken } from "./../State/Token.js";
import { getPermissions } from "./../Api/MartletOrganization/Users.js";
import { getAddresses } from "./../Api/MartletOrganization/Organization.js";

const logger = log.getLogger("Addresses:View");

function ClosedActionsBar(props:{
  accountingSystemIds: Array<string>,
  organizationId: string,
}) {

  return (
    <>
      <OpenButton
        label="Open"
        organizationId={props.organizationId}
        addressIds={props.accountingSystemIds}
      />
    </>
  )
}

function ActionsBar(props:{
  accountingSystemIds: Array<string>,
  organizationId: string,
}) {

  return (
    <>
      <CloseButton
        label="Close"
        organizationId={props.organizationId}
        addressIds={props.accountingSystemIds}
      />
    </>
  )
}

const NakedView = (props:{
  permissions: Array<Permission>,
  organization: Organization,
  addresses: Array<BmbixAddress>,
  addAddress: Function,
  deleteAddress: Function,
  addPermission: Function,
  deletePermission: Function,
}) => {
  const {
    addAddress,
    deleteAddress,
    addPermission,
    deletePermission,
    permissions,
    addresses,
    organization,
  } = props;
  logger.debug("[Addresses:View]: addresses", addresses);
  const token = useToken();
  const account = useAccount();
  const userId = account.id;

  const organizationId = organization.id;
  const [selectedAsRefs, setSelectedAsRefs] = useState([]);
  const [selectedClosedAsRefs, setSelectedClosedAsRefs] = useState([]);
  // const [addresses, setAddresses] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);

  const refresh = async () => {
    logger.debug("calling refresh");
    R.pipe(ad => ad.id, deleteAddress)(addresses);
    const freshAddresses = await getAddresses({token, organizationId});
    R.map(addAddress, freshAddresses);
    R.pipe(p => p.permissionId, deletePermission)(permissions);
    const newPermissions = await getPermissions({token, userId,});
    R.map(addPermission, newPermissions);
  }

  useEffect(
    () => {refresh()},
    [],
  );
  logger.debug("[Addresses:View]: addresses", addresses);

  const toggleSelectHandler = (event:any) => {
    const id = event.target.id;
    const checked = event.target.checked;
    const s = new Set(selectedAsRefs);
    if (checked) {
      s.add(id);
    }
    else {
      s.delete(id);
    }
    setSelectedAsRefs(Array.from(s));
  }

  const toggleSelectClosedHandler = (event:any) => {
    const id = event.target.id;
    const checked = event.target.checked;
    const s = new Set(selectedClosedAsRefs);
    if (checked) {
      s.add(id);
    }
    else {
      s.delete(id);
    }
    setSelectedClosedAsRefs(Array.from(s));
  }

  const addModal = !!showAddModal ? (
    <AddModal
      organizationId={organizationId}
      onCancel={() => setShowAddModal(false)}
      onComplete={() => {
        setShowAddModal(false);
        refresh();
      }}
    />
  ): null;

  return (
    <>
      <ButtonToolbar className="float-right" >
        <Button onClick={(e) => setShowAddModal(true)}><PlusIcon /> Add</Button>
        {addModal}
      </ButtonToolbar>
      <h2>Addresses</h2>
      <p>Displaying those addresses where you have a Manager role.</p>
      <Tabs defaultActiveKey="open">
        <Tab eventKey="open" title={<><CheckCircleIcon/> Open</>}>
          <ActionsBar
            accountingSystemIds={selectedAsRefs}
            organizationId={organizationId}
          />
          <AccountingSystemsListOpen
            accountingSystems={R.filter(R.propEq("isClosed", false), addresses)}
            organizationId={organizationId}
            toggleSelectHandler={toggleSelectHandler}
            checkedItems={selectedAsRefs}
          />
        </Tab>
        <Tab eventKey="closed" title={<><XCircleIcon/> Closed</>}>
          <ClosedActionsBar
            accountingSystemIds={selectedClosedAsRefs}
            organizationId={organizationId}
          />
          <AccountingSystemsListClosed
            organizationId={organizationId}
            accountingSystems={R.filter(R.propEq("isClosed", true), addresses)}
            toggleSelectHandler={toggleSelectClosedHandler}
            checkedItems={selectedClosedAsRefs}
          />
        </Tab>
      </Tabs>
    </>
  )
}

const connector = connect(
  (state, ownProps) => {
    const organizationId = ownProps.match.params.organizationId;
    const organization = R.find(R.propEq("id", organizationId))(state.orgs);
    const addresses = R.filter(R.propEq("organizationId", organizationId))(state.addresses);
    const me = `bmbix://user/${state.account.sub}`;
    const permissions = R.filter(R.propEq("actor", me))(state.permissions);
    logger.debug("stored addresses:", addresses);
    return {
      organization,
      addresses,
      permissions,
    }
  },
  {
    addAddress,
    deleteAddress,
    addPermission,
    deletePermission,
  }
)

const View = withRouter(connector(NakedView));


export {
  NakedView,
  View,
}
