// @flow
//
import * as S from "sanctuary";
import {
  send,
  assign,
} from "xstate";


const controlPanelStateChart = {
  id: "ControlPanel",
  predictableActionArguments: true,
  context: {
    completions: [],
    results: {},
  },
  initial: "off",
  states: {
    off: {
      on: {
        START: undefined,
      }
    },
    warmingUp: {
      on: {
        UPDATE: {
          actions: [
            // (context, event) => console.log("***EVENT****", event),
            assign((context, event) => (
              {
                completions : S.append (event.name) (context.completions),
                results: Object.assign(context.results, {[event.name]: event.result}),
              }
            )),
            send("CHECK"),
          ],
        },
        CHECK: {
          target: "ready",
          cond: "fetchingIsComplete",
        },
        FAILURE: {
          target: "failed",
        }
      }
    },
    ready: {
      type: "final",
    },
    failed: {
      type: "final",
    }
  }
}

export {
  controlPanelStateChart,
};
