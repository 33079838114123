// @flow
import {
  AccessList as AccessListGW,
  AccessListEntry as AccessListEntryGW,
  AccessListActivation as AccessListActivationGW,
} from "@bmbix/bmb_martlet_organization_client";

import {
  type AccessList,
  type AccessListEntry,
  type AccessListActivation,
  createAccessList,
  createAccessListEntry,
  createAccessListActivation,
} from "./../entity.js";


const gwToAccessList = (gw: AccessListGW): AccessList => {
  return createAccessList({
    isActive: gw.is_active,
    list: gw.list,
    name: gw.name,
    type: gw.type,
  });
}

const gwToAccessListEntry = (gw: AccessListEntryGW): AccessListEntry => {
  return createAccessListEntry({
    sender: gw.sender,
    list: gw.list,
    entry: gw.entry,
  });
}

const gwToAccessListActivation = (gw: AccessListActivationGW): AccessListActivation => {
  return createAccessListActivation({
    active: gw.active,
  });
};


export {
  gwToAccessList,
  gwToAccessListEntry,
  gwToAccessListActivation,
}
