// @flow
import React, {
  useState,
  useEffect,
} from "react";
import {
  withRouter,
} from "react-router-dom";
import {
  connect,
} from "react-redux";
import * as R from "ramda";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import { fork } from "fluture";

import {
  MessageNotesWidget,
} from "../Widgets/MessageNotes";
import {
  withMartletOrganizationApi,
} from "../Api/MartletOrganization";
import {
  type BmbixAddress,
} from "../Entity/Types.js";
import { markRead } from "./../Api/MartletOrganization/Address.js";
import { useToken } from "./../State/Token.js";


type Props = {
}
class Pooter extends React.Component<Props> {

  constructor (props:Props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount () {
    console.log("Pooter mounting", this.ref.current);
    if(!!this.props.bmbixDoc){
      this.ref.current.appendChild(this.props.bmbixDoc);
    }
  }

  render () {
    return (
      <div ref={this.ref}/>
    )
  }
}



const INPUT = "input";
const WAITING = "waiting";
const SUCCESS = "success";
const FAILURE = "failure";


const MessageView_ = (props: {
  address: BmbixAddress,
  martletOrganizationApi: Object,
  match: Object,
}) => {
  const {
    address,
    martletOrganizationApi,
    match,
  } = props;
  const messageId = match.params.messageId;
  const addressId = address.id;
  const organizationId = address.organizationId;
  const [display, setDisplay] = useState(WAITING);
  const [message, setMessage] = useState();
  const [theTransform, setTheTransform] = useState();
  const token = useToken();

  const handleMarkRead = e => {
    const action = markRead(token)(addressId)(messageId);
    fork(error => console.log(error))(messageReadReceipt =>
      console.log(messageReadReceipt))(action);
  }

  const refresh = () => {
    setDisplay(WAITING);
    setMessage(null);
    martletOrganizationApi.getMessage({
      organizationId,
      addressId,
      messageId,
    }).then(
      message => {
        setMessage(message);
        const source = atob(message.content);
        const promise = window.SaxonJS.transform({
          stylesheetLocation: "/invoice.sef.json",
          sourceText: source,
          destination: "raw",
        }, "async");

        promise.then(
          transform => {
            console.log("transform", transform);
            setTheTransform(transform.principalResult);
            setDisplay(SUCCESS);
          }
        ).catch(
          error => {
            console.log("error", error);
          }
        );
      }
    ).catch(
      error => {
        setDisplay(FAILURE);
        setMessage(null);
      }
    );
  }

  useEffect(
    refresh,
    [],
  )

  const input = display === INPUT ? (
    null
  ): null;

  const waiting = display === WAITING ? (
    <p>Waiting ... </p>
  ): null;

  const success = ( display === SUCCESS && !! message ) ? (
    <>
        <Pooter bmbixDoc={theTransform}/>
    </>
      ): null;

  const failure = display === FAILURE ? (
    <p>Ooops! Something went wrong.</p>
  ): null;

  return (
    <Container fluid={true}>
    <Row>
    <Col>
    {!!message && <h2>Document type: {message.contentMediaType}</h2>}
    </Col>
    </Row>
    <Row>
    <Col>
    <Alert variant="info">
    This is a human-readable render of a purely digital accounting document. This document
    can be automatically inserted into your accounting systems without re-keying
    or scanning. <a
    href="https://bmbix.com">Find out how</a>.
    </Alert>
    </Col>
    </Row>
    <Row>
    <Col>
    {input}
    {waiting}
    {success}
    {failure}
    </Col>
    <Col>
    <Button onClick={handleMarkRead}>Mark Read</Button>
    <MessageNotesWidget messageId={messageId} addressId={addressId} />
    </Col>
    </Row>
    </Container>
  )

}

const connector = connect(
  (state, ownProps) => {
    const {
      match,
    } = ownProps;
    const addressId = match.params.addressId
    return {
      address: R.find(R.propEq("id", addressId))(state.addresses),
    }
  }
)

const MessageView = withRouter(connector(withMartletOrganizationApi(MessageView_)));

export {
  MessageView,
}
