// @flow
import React, { useState } from "react";
import Form from "react-bootstrap/Form";


const Controller = (props: {
  onChange: Function,
  onKeyPress: Function,
  data: Function,
}) => {
  const {
    data,
    onChange,
    onKeyPress,
  } = props;

  const [value, setValue] = useState(data);

  return (
    <Form>

    <Form.Group onChange={onChange}>
    <Form.Label>Profile type</Form.Label>
    <Form.Check
      type="radio"
      name="type"
      value="user"
      label="User"
    />
    <Form.Check
      type="radio"
      name="type"
      value="organization"
      label="Organization"
    />
    </Form.Group>

    <Form.Group>
    <Form.Label>Search terms</Form.Label>
    <Form.Control
      type="text"
      name="terms"
      onChange={onChange}
      placeholder="Enter what you are looking for"
      value={value}
      onKeyPress={onKeyPress}
    />
    <Form.Text>The part of the name you are searching for</Form.Text>
    </Form.Group>

    </Form>
  )
}

export { Controller }
