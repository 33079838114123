// @flow
import {
  createElement as el,
} from "react";
import {
  connect,
} from 'react-redux'

import {
  type Account,
} from "../../../Entity/Types.js";

import { View } from "./view.js";


const Controller_ = (props: {
  account: Account,
}) => {
  const {
    account,
  } = props;

  return el (
    View,
    {
      account,
    }
  );
}

const connector = connect(
  state => {
    return {
      account: state.account,
    }
  },
);

const Controller = connector(Controller_);

export {
  Controller,
}
