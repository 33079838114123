// @flow
import React from "react";

import {
  type ExtractionCandidateReportHeader,
} from "../Entity/Types";


const Heading = (props: {
  header: ExtractionCandidateReportHeader,
}) => {
  return (
    <>
      <h2>Extraction Candidate Report</h2>
      <p>Created at: {props.header.reportCreatedAt}</p>
    </>
  )
}


export {
  Heading,
}
