// @flow
import * as R from "ramda";

import Future from "fluture";

import {
  instanceService,
} from "../../Entity/InstanceService";


import {
  type Claim,
  type UnaddressedMessage,
  type UnaddressedMessagesResponse,
  type SelectionMetadata,
} from "../../Entity/Types.js";

import {
  ApiClient,
  ClaimsApi,
  UnaddressedMessagesApi,
  Claim as ClaimGW,
  UnaddressedMessage as UnaddressedMessageGW,
  SelectionMetadata as SelectionMetadataGW,
} from "@bmbix/bmb_klondike_client";

const createUnaddressedMessageFromGW = (
  unaddressedMessageGW: UnaddressedMessageGW,
): UnaddressedMessage => {
  const expected = instanceService.createUnaddressedMessage({
    systemId: unaddressedMessageGW.system_id,
    unaddressedMessageId: unaddressedMessageGW.unaddressed_message_id,
    receivedAt: unaddressedMessageGW.received_at,
    fromAddressId: unaddressedMessageGW.from_address_id,
    localAccount: unaddressedMessageGW.local_account,
    messageType: unaddressedMessageGW.message_type,
    messageContent: unaddressedMessageGW.message_content,
    summary: unaddressedMessageGW.summary,
    senderReference: unaddressedMessageGW.sender_reference,
    checksum: unaddressedMessageGW.checksum,
    checksumAlgorithm: unaddressedMessageGW.checksum_algorithm,
    messageToken: unaddressedMessageGW.message_token,
    toAddressId: unaddressedMessageGW.to_address_id,
    claimedAt: unaddressedMessageGW.claimed_at,
    martletSubmissionStatus: unaddressedMessageGW.martlet_submission_status,
    martletMessageId: unaddressedMessageGW.martlet_message_id,
    martletComments: unaddressedMessageGW.martlet_comments,
    martletSubmittedAt: unaddressedMessageGW.martlet_submitted_at,
  });
  return expected;
}

const createSelectionMetadataFromGW =
  (selectionMetadataGW: SelectionMetadataGW): SelectionMetadata =>
{
  return instanceService.createSelectionMetadata({
    pointer: selectionMetadataGW.pointer,
    count: selectionMetadataGW.count,
  });
}

const getUnaddressedMessageByMessageToken = (args: {
  token: string,
  messageToken: string,
  defaultClient_: Object,
}): Promise<UnaddressedMessage> => {
  const {
    token,
    messageToken,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new ClaimsApi(defaultClient);
  return new Promise((resolve, reject) => {
    apiInstance.selectWithMessageToken(
      messageToken,
      (error, data, response) => {
        if (error) {
          resolve(error);
        } else {
          console.log("data", data);
          const unaddressedMessageGW = data.unaddressed_message;
          const unaddressedMessage = createUnaddressedMessageFromGW(unaddressedMessageGW);
          resolve(unaddressedMessage);
        }
      }
    );
  });
}

const createClaimFromClaimGW = (claimGW: ClaimGW): Claim => {
  const expected = instanceService.createClaim({
    claimId: claimGW.claim_id,
    toAddressId: claimGW.to_address_id,
    messageToken: claimGW.message_token,
    claimedAt: claimGW.claimed_at,
    messageId: claimGW.message_id,
  });
  return expected;
}

const updateWithClaim = (args: {
  token: string,
  addressId: string,
  messageToken: string,
  defaultClient_: Object,
}): Promise<Claim> => {
  const {
    token,
    addressId,
    messageToken,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new ClaimsApi(defaultClient);

  const body = ClaimGW.constructFromObject({
    to_address_id: addressId,
    message_token: messageToken,
  });
  return new Promise((resolve, reject) => {
    apiInstance.updateWithClaim(
      body,
      addressId,
      (error, data, response) => {
        if (error) {
          resolve(error);
        } else {
          const claimGW = data.claim;
          const claim = createClaimFromClaimGW(claimGW);
          resolve(claim);
        }
      }
    );
  });
}

const updateWithClaimFuture =
  (token: string) =>
  (addressId: string) =>
  (messageToken: string) => {
  const defaultClient = ApiClient.instance;
  const body = ClaimGW.constructFromObject({
    to_address_id: addressId,
    message_token: messageToken,
  });
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new ClaimsApi(defaultClient);
  const future = Future((reject, resolve) => {
    apiInstance.updateWithClaim(
      body,
      addressId,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          const claimGW = data.claim;
          const claim = createClaimFromClaimGW(claimGW);
          resolve(claim);
        }
      }
    );
    return () => {
      console.log("cancelled updateWithClaimFuture")
    }
  });
  return future;
}



const getUnaddressedMessage = (args: {
  token: string,
  addressId: string,
  unaddressedMessageId: string,
  defaultClient_: Object,
}): Promise<UnaddressedMessage> => {
  const {
    token,
    addressId,
    unaddressedMessageId,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new UnaddressedMessagesApi(defaultClient);
  return new Promise((resolve, reject) => {
    apiInstance.select(
      addressId,
      unaddressedMessageId,
      (error, data, response) => {
        if (error) {
          resolve(error);
        } else {
          console.log("data", data);
          const unaddressedMessageGW = data.unaddressed_message;
          const unaddressedMessage = createUnaddressedMessageFromGW(unaddressedMessageGW);
          resolve(unaddressedMessage);
        }
      }
    );
  });
}

const getUnaddressedMessages = (args: {
  token: string,
  addressId: string,
  query: string,
  pointer: number,
  limit: number,
  defaultClient_: Object,
}): Promise<UnaddressedMessagesResponse> => {
  const {
    token,
    addressId,
    query,
    pointer,
    limit,
    defaultClient_,
  } = args;

  const opts = { query, pointer, limit};

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new UnaddressedMessagesApi(defaultClient);
  return new Promise((resolve, reject) => {
    apiInstance.selectFilteredFromAddressId(
      addressId, opts,
      (error, data, response) => {
        if (error) {
          resolve(error);
        } else {
          console.log("data", data);
          const unaddressedMessagesGW = data.unaddressed_messages;
          console.log("unaddressedMessagesGW", unaddressedMessagesGW);
          const unaddressedMessages = R.map(umgw => createUnaddressedMessageFromGW(umgw), unaddressedMessagesGW,
          );
          console.log("unaddressedMessages", unaddressedMessages);
          const selectionMetadataGW = data.selection_metadata;
          const selectionMetadata =
            createSelectionMetadataFromGW(selectionMetadataGW);
          const unaddressedMessagesResponse =
            instanceService.createUnaddressedMessagesResponse({
              selectionMetadata,
              unaddressedMessages,
            });
          resolve(unaddressedMessagesResponse);
        }
      }
    );
  });
}

export {
  createUnaddressedMessageFromGW,
  createClaimFromClaimGW,
  getUnaddressedMessageByMessageToken,
  updateWithClaim,
  updateWithClaimFuture,
  getUnaddressedMessage,
  getUnaddressedMessages,
}
