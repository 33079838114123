// @flow
import React from "react";
import {
  Switch,
  Route,
} from "react-router-dom";

import {
  AddressProfilePage,
  AddressProfilePublicKeyPage,
  IndexPage,
  OrganizationProfilePage,
  OrganizationProfileCertificatePage,
  OrganizationProfilePublicKeyPage,
  UserProfilePage,
  UserProfilePublicKeyPage,
  UserProfileCertificatePage,
} from "./Widgets";


const View = (props: {
  here: string,
}) => {
  return (
    <Switch>
    <Route
      path={`${props.here}/organization/:organizationId/certificate`}
      component={OrganizationProfileCertificatePage}
    />
    <Route
      path={`${props.here}/organization/:organizationId/public_key`}
      component={OrganizationProfilePublicKeyPage}
    />
    <Route
      path={`${props.here}/organization/:organizationId`}
      component={OrganizationProfilePage}
    />
    <Route
      path={`${props.here}/address/:addressId/public_key`}
      component={AddressProfilePublicKeyPage}
    />
    <Route
      path={`${props.here}/address/:addressId`}
      component={AddressProfilePage}
    />
    <Route
      path={`${props.here}/user/:userId/certificate`}
      component={UserProfileCertificatePage}
    />
    <Route
      path={`${props.here}/user/:userId/public_key`}
      component={UserProfilePublicKeyPage}
    />
    <Route
      path={`${props.here}/user/:userId`}
      component={UserProfilePage}
    />
    <Route
      exact
      path={`${props.here}`}
      component={IndexPage}
    />
    </Switch>
  )
}


export {
  View,
}
