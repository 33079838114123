// @flow
import {
  CheckCircleFillIcon,
  XCircleFillIcon,
} from "@primer/octicons-react";

const EnabledIcon = (props: {
}) => {
  const { enabled } = props;

  return enabled
  ? <CheckCircleFillIcon fill="#4c4"/>
  : <XCircleFillIcon fill="#c44"/>
}


export {
  EnabledIcon,
  EnabledIcon as default,
}
