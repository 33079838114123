// @flow
import { useSelector } from "react-redux";
import { type MessageType } from "./../Entity/Types.js";
import * as S from "sanctuary";

const MESSAGE_TYPE_REFRESH = "MESSAGE_TYPE_REFRESH";
const MESSAGE_TYPE_ADD = "MESSAGE_TYPE_ADD";
const MESSAGE_TYPE_REMOVE = "MESSAGE_TYPE_REMOVE";


type RefreshAction = {
  type: string,
  messageTypes: Array<MessageType>,
}

type AddAction = {
  type: string,
  messageType: MessageType,
}

type RemoveAction = {
  type: string,
  messageType: MessageType,
}

type MessageTypeAction = | RefreshAction | AddAction | RemoveAction;

const refreshAction  = (messageTypes: Array<MessageType>): RefreshAction => {
  return {
    type: MESSAGE_TYPE_REFRESH,
    messageTypes: messageTypes,
  }
}

const addAction = (messageType: MessageType): AddAction => {
  return {
    type: MESSAGE_TYPE_ADD,
    messageType: messageType,
  }
}

const removeAction = (messageType: MessageType): RemoveAction => {
  return {
    type: MESSAGE_TYPE_REMOVE,
    messageType: messageType,
  }
}


const isCandidate = rc => mt => S.equals (S.prop ("id") (rc)) (S.prop ("id") (mt))

const remove = rc => mts => mt => S.ifElse (isCandidate(rc)) (_ => mts) (x => S.prepend (x) (mts)) (mt)

const messageTypes = (
  state: Array<MessageType>=[],
  action: MessageTypeAction,
): Array<MessageType> => {
  switch (action.type) {
    case MESSAGE_TYPE_REFRESH:
      console.log("RefreshAction", action);
      return S.prop ("messageTypes") (action);
    case MESSAGE_TYPE_REMOVE:
      console.log("RemoveAction", action);
      return S.reduce (remove (S.prop ("messageType") (action))) ([]) (state);
    case MESSAGE_TYPE_ADD:
      console.log("AddAction", action);
      return S.prepend (S.prop ("messageType") (action)) (state)
    default:
      return state;
  }
}

const useMessageTypes = (): Array<MessageType> => {
  return useSelector(state => state.messageTypes)
}

export {
  refreshAction,
  removeAction,
  addAction,
  useMessageTypes,
  messageTypes,
}
