// @flow
import * as R from "ramda";
import log from "loglevel";

import {
  extractResourceIds,
  } from "./bri.js";

import {
  type BmbixAddress,
} from "../Entity/Types.js";

import {
  getPermissions,
  getSettings,
} from "./../Api/MartletOrganization/Users.js";
import {
  getAllAddresses,
} from "./../Api/MartletOrganization/Organization.js";

const logger = log.getLogger("Util");


const getAddresses = async (
  martletOrganizationApi: Object,
  addressIds:Array<string>,
): Promise<Array<BmbixAddress>> => {

  const getAddress = addressId => martletOrganizationApi.getAddress({addressId});

  const results = await Promise.allSettled(R.map(getAddress, addressIds));
  const successes = R.filter(R.propEq("status", "fulfilled"), results);
  const addresses = R.map(x => x.value, successes);
  return addresses;
}

const syncToServer = async (args:{
    token: string,
    userId: string,
    catchExpiredToken: Function,
    addOrg:Function,
    addAddress:Function,
    addAccessKey:Function,
    addPermission:Function,
    addPlatform: Function,
    addPlatformAssignment: Function,
    setSettings: Function,
    martletOrganizationApi: Object,
    isimudApi: Object,
}):Promise<null> => {
  const {
    token,
    userId,
  } = args;
  try {
  const permissions = await getPermissions({token, userId});
  logger.debug("[sync] permissions:", permissions);
  R.map(args.addPermission, permissions);

  const organizationIds = extractResourceIds("organization")(permissions);
  const organizations = await args.martletOrganizationApi.getAllOrganizations({
    organizationIds: organizationIds,
  });
  logger.debug("[sync] organizations:", organizations);
  R.map(args.addOrg, organizations);

  const addresses = await getAllAddresses({
    token,
    organizationIds,
  }
  );
  logger.debug("[sync] addresses:", addresses);
  R.map(args.addAddress, addresses);

  const addressIds = extractResourceIds("address")(permissions);
  const permissionAddresses = await getAddresses(args.martletOrganizationApi,
    addressIds);
  logger.debug("[sync] permissionAddresses:", permissionAddresses);
  R.map(args.addAddress, permissionAddresses);

  const settings = await getSettings({
    token: args.token,
    userId: args.userId,
  });
  logger.debug("[sync] settings:", settings);
  /*
  try {
  const locale = new Intl.Locale(settings.language);
  changeLocale(locale);
  } catch (e) {
    logger.debug("Unable to set language");
  }
  */
  args.setSettings(settings);
  } catch (error) {
    console.log("permissions failed", error.status, Object.getOwnPropertyNames(error),);
    args.catchExpiredToken(error);
  }

  return null;
}

export {
  syncToServer,
}
