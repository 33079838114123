// @flow

export type PublicKey = {|
  +bri: string,
  +expiresAt: Date,
  +fingerprint: string,
  +publicKey: string,
|}


export type PrivateKey = {|
  +privateKeyId: string,
  +bri: string,
  +fingerprint: string,
  +updatedAt: Date,
  +createdAt: Date,
  +expiresAt: Date,
  +rescinded: boolean,
  +userSupplied: boolean,
|}


const createPublicKey = (args: PublicKey) => {
  return Object.freeze(args)
}

const createPrivateKey = (args: PrivateKey) => {
  return Object.freeze(args)
}


export {
  createPublicKey,
  createPrivateKey,
}
