// @flow

import {
  applyMiddleware,
  createStore,
} from 'redux'
import thunk from "redux-thunk";
import { rootReducer } from '../bmbixredux'

const store = createStore(
  rootReducer,
  applyMiddleware(thunk),
)

export {
  store,
}
