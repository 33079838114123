// @flow
import {
  createElement as el,
  useState,
} from "react";
import log from "loglevel";
import {
  connect,
} from "react-redux";

import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "../../Util";
import {
  withIsimudApi,
} from "../../Api/Isimud";
import {
  updateForeignAddressId,
} from "../../State/AddressBookEntries.js";

import {
  View,
} from "./View.js";


const logger = log.getLogger("AddressBookEntry");


const Controller_ = (props: {
  organizationId: string,
  addressId: string,
  addressBookEntryId: string,
  onDismiss: Function,
  localAccount: string,
  isimudApi: Object,
  updateForeignAddressId: Function,
}) => {
  const {
    organizationId,
    addressId,
    addressBookEntryId,
    onDismiss,
    localAccount,
    isimudApi,
    updateForeignAddressId,
  } = props;

  const [display, setDisplay] = useState(INPUT);
  const [foreignAddressId, setForeignAddressId] = useState("");

  const onChange = (e) => {
    setForeignAddressId(e.target.value);
  }

  const onCancel = (e) => {
    onDismiss();
  }

  const onDone = (e) => {
    onDismiss()
  }

  const onSubmit = (e) => {
    setDisplay(WAITING);
    isimudApi.updateForeignAddressId({
      organizationId,
      addressId,
      addressBookEntryId,
      localAccount,
      foreignAddressId,
      updateForeignAddressId,
    }).then(
      result => {
        setDisplay(SUCCESS);
      }
    ).catch(
      error => {
        setDisplay(FAILURE);
      }
    );
  }

  return el(
    View,
    {
      display,
      foreignAddressId,
      onChange,
      onDone,
      onCancel,
      onSubmit,
    },
  );
}

const connector = connect(
  null,
  {
    updateForeignAddressId,
  }
);


const Controller = connector(withIsimudApi(Controller_));

export {
  Controller,
}
