// @flow
import React, {
  Component,
} from "react"
import {
  withRouter,
} from "react-router";
import {
  connect
} from "react-redux";
import type {
  Organization,
  Platform,
  AccountingSystem,
} from "../Entity/Types"
import
  OrganizationsTable
from "../Organization/OrganizationsTableUser"
import * as R from "ramda"


type Props = {
  match: Object,
  history: Object,
  api: Object,
  bmbApi: Object,
  ass: Array<AccountingSystem>,
  orgs: Array<Organization>,
  platforms: Array<Platform>,
  token: string,
}
type State = {
}
class OrganizationsUserView extends Component<Props, State> {

  getOrganization (organizationId: string) {
    const organization = R.find(o => o.id === organizationId)(this.props.orgs)
    //console.log(organization)
    return organization
  }

  getAccountingSystems (organizationId: string) {
    const accountingSystems = R.filter(accountingSystem => accountingSystem.organizationId === organizationId, this.props.ass)
    //console.log(accountingSystems)
    return accountingSystems
  }

  constructor (props: Props) {
    console.log("OrganizationsUserView:render")
    super(props)
    this.getOrganization = this.getOrganization.bind(this)
    this.getAccountingSystems = this.getAccountingSystems.bind(this)
  }

  render () {
    console.log("OrganizationsUserView:render")
    return (
       <>
       <h2>Organizations</h2>
       <p>You have permission to process invoice documents for the organizations listed below.</p>
       <OrganizationsTable
         urlRoot={this.props.match.url}
         organizations={this.props.orgs}
       />
       </>
    )
  }

  componentDidMount () {
    console.log("OrganizationsUserView:componentDidMount")
  }

  componentDidUpdate (prevProps: Props, prevState: State, snapshot: any) {
    console.log("OrganizationsUserView:componentDidUpdate")
  }

  componentWillUnmount() {
    console.log("OrganizationsUserView:componentWillUnmount")
  }

}


const mapStateToProps = (state) => {
  return {
    orgs: state.orgs,
  }
}

const Routed = connect(mapStateToProps)(withRouter(OrganizationsUserView));

export default Routed
