// @flow

import React, {
  useState,
} from "react"
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal"

import {
  Spinner,
} from "../Widgets/Toast";
import { CloseForm } from "./CloseForm";


const CloseModal = (props: {
  callBack?: Function,
  cancel?: Function,
  display?: string,
}) => {

  const handleClose = (e) => {
    e.preventDefault();
    if (props.cancel){
      props.cancel();
    }
  }

  const handleSave= (e) => {
    e.preventDefault();
    if(props.callBack){
      props.callBack();
    }
  }

  const submitButton = props.display === "input" ? (
          <Button variant="primary" onClick={handleSave}>Close organization</Button>
  ): null;

  const cancelButton = props.display === "input" || props.display === "waiting" ? (
          <Button variant="secondary" onClick={handleClose}>Cancel</Button>
  ): null;

  const doneButton = props.display === "success" || props.display === "failure" ? (
          <Button variant="secondary" onClick={handleClose}>Done</Button>
  ): null;

  return (
    <Modal show={true} >
      <Modal.Header>
        <Modal.Title>Close Organizations</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          {
            (props.display === "input" || props.display === undefined)
            &&
            <CloseForm />
          }
          {
            props.display === "waiting"
            &&
            <Spinner />
          }
          {
            props.display === "success"
            &&
            <p>Success, Closed organizations</p>
          }
          {
            props.display === "failure"
            &&
            <p>Ooops, Looks like something went wrong</p>
          }
        </>
        <Modal.Footer>
          {cancelButton}
          {submitButton}
          {doneButton}
        </Modal.Footer>

      </Modal.Body>
    </Modal>
  );

}

export {
  CloseModal,
}
