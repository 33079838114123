// @flow
import * as R from "ramda";
import log from "loglevel";
import * as S from "sanctuary";
import Future, { promise } from "fluture";

import {
  ApiClient,
  UsersApi,
  Organization as OrganizationGW,
  Permission as PermissionGW,
  Settings as SettingsGW,
  MessagesCount as MessagesCountGW,
  StripeSetupIntent as StripeSetupIntentGW,
  StripeIntent as StripeIntentGW,
  PaymentMethod as PaymentMethodGW,
  PaymentMethodLink as PaymentMethodLinkGW,
} from "@bmbix/bmb_martlet_organization_client";
import {
  type Permission,
} from "../../Entity/Types";
import { callBack } from "./../CallBack";
import type { StripeIntent } from "./../../Entity/Types.js";
import {
  type Settings,
  type MessagesCount,
} from "../../Entity/Types.js";
import {
  instanceService,
} from "../../Entity/InstanceService.js";
import {
  createOrganizationFromGW,
  createPaymentMethodLinkFromGW,
} from "./adapters.js";

const logger = log.getLogger(":Api:Freud:Settings");

const createPermissionFromGW = (gw: PermissionGW): Permission => {
  return Object.freeze({
    permissionId: gw.permission_id,
    actor: gw.actor,
    power: gw.power,
    resource: gw.resource,
  });
}

const createStripeIntentFromGW = (gw: StripeIntentGW): StripeIntent => {
  return instanceService.createStripeIntent({
    customerSecret: gw.customer_secret,
  })
}


const createPaymentMethodFromGW = (gw: PaymentMethodGW): PaymentMethod => {
  return instanceService.createPaymentMethod({
    id: gw.id,
    userId: gw.user_id,
    description: gw.description,
    links: S.map (createPaymentMethodLinkFromGW) (gw.links),
  });
}


const getPermissions = (args:{
  token: string,
  userId: string,
}):Promise<Array<Permission>> => {
  const {
    token,
    userId,
  } = args;

  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new UsersApi();

  const dataHandler = data => {
    const permissionsResponseGW = data;
    const permissions = R.map(
      createPermissionFromGW,
      permissionsResponseGW.permissions,
    );
    return permissions;
  }

  return new Promise((resolve, reject) => {
    apiInstance.listPermissions(
      userId,
      callBack(reject, resolve)(dataHandler),
    );
  });
}

const createSettingsFromGW = (
  settingsGW: SettingsGW,
): Settings => {
  const settings = instanceService.createSettings({
    userId: settingsGW.user_id,
    name: settingsGW.name,
    verified: settingsGW.verified,
    verifiedName: settingsGW.verified_name,
    alias: settingsGW.alias,
    email: settingsGW.email,
    language: settingsGW.language,
    timezone: settingsGW.timezone,
    iconUrl: settingsGW.icon_url,
  });
  return settings;
}

const getSettings = (args: {
  token: string,
  userId: string,
  defaultClient_?: Function,
}):Promise<?Settings> => {
  const {
    token,
    userId,
    defaultClient_,
  } = args;
  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new UsersApi(defaultClient);
  return new Promise((resolve, reject) => {
    apiInstance.getSettings(
      userId,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          const settingsResponseGW  = data;
          const settingsGW = settingsResponseGW.settings;
          logger.debug("settingsGW:", settingsGW);
          if (!settingsGW) {
            logger.debug("returning null");
            resolve(null);
          } else {
            logger.debug("converting to settings");
            const settings = createSettingsFromGW(settingsGW);
            resolve(settings);
          }
        }
      }
    );
  });
}

const setName = (args: {
  token: string,
  userId: string,
  name: string,
  defaultClient_?: Function,
}):Promise<Settings> => {
  const {
    token,
    userId,
    name,
    defaultClient_,
  } = args;
  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  const body = SettingsGW.constructFromObject({
    name: name,
  });

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new UsersApi(defaultClient);
  return new Promise((resolve, reject) => {
    apiInstance.updateName(
      body,
      userId,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          const settingsResponseGW  = data;
          const settingsGW = settingsResponseGW.settings;
          const settings = createSettingsFromGW(settingsGW);
          resolve(settings);
        }
      }
    );
  });
}

const setLanguage = (args: {
  token: string,
  userId: string,
  language: string,
  defaultClient_?: Function,
}):Promise<Settings> => {
  const {
    token,
    userId,
    language,
    defaultClient_,
  } = args;
  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  const body = SettingsGW.constructFromObject({
    language: language,
  });

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new UsersApi(defaultClient);
  return new Promise((resolve, reject) => {
    apiInstance.updateLanguage(
      body,
      userId,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          const settingsResponseGW  = data;
          const settingsGW = settingsResponseGW.settings;
          const settings = createSettingsFromGW(settingsGW);
          resolve(settings);
        }
      }
    );
  });
}

const setTimezone = (args: {
  token: string,
  userId: string,
  timezone: string,
  defaultClient_?: Function,
}):Promise<Settings> => {
  const {
    token,
    userId,
    timezone,
    defaultClient_,
  } = args;
  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  const body = SettingsGW.constructFromObject({
    timezone: timezone,
  });

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new UsersApi(defaultClient);
  return new Promise((resolve, reject) => {
    apiInstance.updateTimezone(
      body,
      userId,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          const settingsResponseGW  = data;
          const settingsGW = settingsResponseGW.settings;
          const settings = createSettingsFromGW(settingsGW);
          resolve(settings);
        }
      }
    );
  });
}

const addOrganization = (args: {
  token: string,
  userId: string,
  name: string,
  isClosed: boolean,
}): Promise<Organization> => {
  const { token, userId, name, isClosed } = args;
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  const apiInstance = new UsersApi();
  let body = new OrganizationGW();
  body.is_closed = isClosed;
  body.name = name;

  const dataHandler = data => {
    const organizationGW = data.organization;
    const organization = createOrganizationFromGW(organizationGW);
    return organization;
  }

  return new Promise((resolve, reject) => {
    apiInstance.createOrganization(
      body,
      userId,
      callBack(reject, resolve) (dataHandler),
    );
  });
}

const createMessagesCountFromGW = (m: MessagesCountGW): MessagesCount => {
  return instanceService.createMessagesCount({
    toId: m.to_id,
    messagesUnread: m.messages_unread,
    messagesUnprocessed: m.messages_unprocessed,
  });
}

const countMessages = (args: {
  token: string,
  userId: string,
}): Future => {

  const {
    token,
    userId,
  } = args;
  const defaultClient = ApiClient.instance;

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new UsersApi(defaultClient);
  return Future((reject, resolve) => {
    apiInstance.countMessages(
      userId,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          const messagesCountsGW= data.messages_counts;
          const messagesCounts = S.map (createMessagesCountFromGW) (messagesCountsGW);
          logger.debug("messagesCounts:", messagesCounts);
          resolve(messagesCounts);
        }
      }
    );
    return console.log;
  });
}

const getStripeIntent_ = (args: {
  token: string,
  userId: string,
}): Future => {

  const {
    token,
    userId,
  } = args;
  const defaultClient = ApiClient.instance;

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new UsersApi(defaultClient);

  return Future((reject, resolve) => {
    apiInstance.getStripeIntent(
      userId,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          const stripeIntentGW = data.stripe_intent;
          const stripeIntent = createStripeIntentFromGW(stripeIntentGW);
          logger.debug("stripeIntent:", stripeIntent);
          resolve(stripeIntent);
        }
      }
    );
    return console.log;
  });
}

const getStripeIntent = (args) => promise(getStripeIntent_(args))

const createPaymentMethodFromStripeIntentFuture = (args: {
  token: string,
  userId: string,
  setupIntent: string,
}): Future => {

  const {
    token,
    userId,
    setupIntent,
  } = args;

  const body = StripeSetupIntentGW.constructFromObject({
    setup_intent: setupIntent,
  });

  const defaultClient = ApiClient.instance;

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new UsersApi(defaultClient);

  return Future((reject, resolve) => {
    apiInstance.createPaymentMethodFromStripeIntent(
      body,
      userId,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          const paymentMethodGW = data.payment_method;
          const paymentMethod = createPaymentMethodFromGW(paymentMethodGW);
          logger.debug("paymentMethod:", paymentMethod);
          resolve(paymentMethod);
        }
      }
    );
    return console.log;
  });
}

const createPaymentMethodFromStripeIntent = (args) => promise(createPaymentMethodFromStripeIntentFuture(args))


const listPaymentMethodsFuture = (args: {
  token: string,
  userId: string,
}): Future => {

  const {
    token,
    userId,
  } = args;

  const defaultClient = ApiClient.instance;

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new UsersApi(defaultClient);

  return Future((reject, resolve) => {
    apiInstance.listPaymentMethods(
      userId,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          const paymentMethodsGW = data.payment_methods;
          const paymentMethods = S.map (createPaymentMethodFromGW) (paymentMethodsGW);
          logger.debug("paymentMethods:", paymentMethods);
          resolve(paymentMethods);
        }
      }
    );
    return console.log;
  });
}

const listPaymentMethods = (args) => promise(listPaymentMethodsFuture(args))

const createPaymentMethodLinkFuture = (args: {
  token: string,
  userId: string,
  paymentMethodId: string,
  organizationId: string,
}): Future => {

  const {
    token,
    userId,
    organizationId,
    paymentMethodId,
  } = args;

  const body = PaymentMethodLinkGW.constructFromObject({
    payment_method_id: paymentMethodId,
    organization_id: organizationId,
  });

  const defaultClient = ApiClient.instance;

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new UsersApi(defaultClient);

  return Future((reject, resolve) => {
    apiInstance.createPaymentMethodLink(
      body,
      userId,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          const paymentMethodLinkGW = data.payment_method_link;
          const paymentMethodLink = createPaymentMethodLinkFromGW(paymentMethodLinkGW);
          logger.debug("paymentMethodLink:", paymentMethodLink);
          resolve(paymentMethodLink);
        }
      }
    );
    return console.log;
  });
}

const createPaymentMethodLink = (args) => promise(createPaymentMethodLinkFuture(args))

const deletePaymentMethodFuture = (args: {
  token: string,
  userId: string,
  paymentMethodId: string,
}): Future => {

  const {
    token,
    userId,
    paymentMethodId,
  } = args;


  const defaultClient = ApiClient.instance;

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new UsersApi(defaultClient);

  return Future((reject, resolve) => {
    apiInstance.deletePaymentMethod(
      userId,
      paymentMethodId,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          logger.debug("deletePaymentMethod data:", data);
          resolve(null);
        }
      }
    );
    return console.log;
  });
}

const deletePaymentMethod = (args) => promise(deletePaymentMethodFuture(args))

const deletePaymentMethodLinkFuture = (args: {
  token: string,
  userId: string,
  paymentMethodLinkId: string,
}): Future => {

  const {
    token,
    userId,
    paymentMethodLinkId,
  } = args;


  const defaultClient = ApiClient.instance;

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new UsersApi(defaultClient);

  return Future((reject, resolve) => {
    apiInstance.deletePaymentMethodLink(
      userId,
      paymentMethodLinkId,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          logger.debug("deletePaymentMethodLink data:", data);
          resolve(null);
        }
      }
    );
    return console.log;
  });
}

const deletePaymentMethodLink = (args) => promise(deletePaymentMethodLinkFuture(args))

const getPaymentMethodFuture = (args: {
  token: string,
  userId: string,
  paymentMethodId: string,
}): Future => {

  const {
    token,
    userId,
    paymentMethodId,
  } = args;


  const defaultClient = ApiClient.instance;

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new UsersApi(defaultClient);

  return Future((reject, resolve) => {
    apiInstance.getPaymentMethod(
      userId,
      paymentMethodId,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          const paymentMethodGW = data.payment_method;
          const paymentMethod = createPaymentMethodFromGW(paymentMethodGW);
          logger.debug("paymentMethod:", paymentMethod);
          resolve(paymentMethod);
        }
      }
    );
    return console.log;
  });
}

const getPaymentMethod = (args) => promise(getPaymentMethodFuture(args))

export {
  addOrganization,
  createPermissionFromGW,
  getPermissions,
  createPaymentMethodFromStripeIntent,
  createPaymentMethodFromStripeIntentFuture,
  createPaymentMethodLink,
  createSettingsFromGW,
  deletePaymentMethod,
  deletePaymentMethodLink,
  getPaymentMethod,
  getStripeIntent,
  getSettings,
  listPaymentMethods,
  setLanguage,
  setName,
  setTimezone,
  countMessages,
}
