// @flow
import Future from "fluture";
import * as S from "sanctuary";

import {
  ApiClient,
  AddressesApi,
  UsersApi,
  OrganizationsApi,
} from "@bmbix/bmb_martlet_organization_client";

import { callBack } from "./../../Api/CallBack";

import {
  type PrivateKey ,
} from "./../entity";

import { createPrivateKeyFromGW } from "./adapt";

const index =
  (token: string) =>
  (resourceType: string) =>
  (resourceId: string): Future<Array<PrivateKey>> =>
{
  const allowedResourceTypes = [
    "organizations",
    "addresses",
    "users",
  ];
  if (!S.elem (resourceType) (allowedResourceTypes)) {
    throw new Error("resourceType was not one of allowed values")
  }

  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;

  const dataHandler = data => {
    const gws = data.private_keys;
    const privateKeys = S.map (createPrivateKeyFromGW) (gws);
    console.log("dataHandler got privateKeys:", privateKeys);
    return privateKeys;
  }

  switch (resourceType) {

    case "organizations": {
      const organizationsApiInstance = new OrganizationsApi();
      return Future((reject, resolve) => {
        organizationsApiInstance.listPrivateKeys(
          resourceId,
          callBack(reject, resolve)(dataHandler),
        )
        return console.log;
      });
    }
    case "users": {
      const usersApiInstance = new UsersApi();
      return Future((reject, resolve) => {
        usersApiInstance.listPrivateKeys(
          resourceId,
          callBack(reject, resolve)(dataHandler),
        )
        return console.log;
      });
    }
    default: {
      const addressesApiInstance = new AddressesApi();
      return Future((reject, resolve) => {
        addressesApiInstance.listPrivateKeys(
          resourceId,
          callBack(reject, resolve)(dataHandler),
        )
        return console.log;
      });
    }
  }

}

export {
  index,
}
