// @flow

import React, {
  useState,
} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  Spinner,
} from "../Widgets/Toast";
import {CloseForm} from "./CloseForm";


function CloseModal (props: {
  callBack?: Function,
  cancel?: Function,
  display?: string,
}) {

  const handleClose = (e) => {
    e.preventDefault();
    if (props.cancel){
      props.cancel();
    }
  }

  const handleSave= (e) => {
    e.preventDefault();
    if(props.callBack){
      props.callBack();
    }
  }

  return (
    <Modal show={true} >
      <Modal.Header>
        <Modal.Title>Close Organizations</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          {
            ( props.display === "input" || props.display === undefined )
            &&
            <CloseForm />
          }
          {
            props.display === "waiting"
            &&
            <Spinner />
          }
          {
            props.display === "success"
            &&
            <p>Success Closeed organizations</p>
          }
          {
            props.display === "failure"
            &&
            <p>Ooops, Looks like something went wrong</p>
          }
        </>

      </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
          <Button variant="primary" onClick={handleSave}>Save changes</Button>
        </Modal.Footer>
    </Modal>
  );

}


export {
  CloseModal,
}

