// @flow

/*
// The SuperAgent error is a struct described here:
// https://github.com/visionmedia/superagent/blob/master/src/client.js#L419
// err.parse
// err.original
// err.rawResponse
// err.status
// err.method
// err.url
// maybe also err.response
*/

const callBack =
  (reject: Function, resolve:Function) =>
  (dataHandler: Function) =>
  (error: Function, data: Function, response: Function) =>
{
  if (error) {
    reject(error);
  } else {
    resolve(dataHandler(data));
  }
}


export {
  callBack,
}
