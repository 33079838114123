// @flow
import React from "react";

import {
  type Acceptance,
} from "./../../Entity/Types.js";


const View = (props: {
  deliveryAdvice: Acceptance,
}) => {
  const {
    deliveryAdvice,
  } = props;

  return (
    <>
    <h2>Delivery Advice</h2>
    <table className="table table-striped table-hover">
      <tbody>
      <tr>
        <th>Message Id:</th>
        <td>{deliveryAdvice.messageId}</td>
      </tr>
      <tr>
        <th>From Address Id:</th>
        <td>{deliveryAdvice.fromAddressId}</td>
      </tr>
      <tr>
        <th>To Address Id:</th>
        <td>{deliveryAdvice.toAddressId}</td>
      </tr>
      <tr>
        <th>Submission timestamp:</th>
        <td>{deliveryAdvice.submissionTimestamp}</td>
      </tr>
      <tr>
        <th>Sender reference:</th>
        <td>{deliveryAdvice.senderReference}</td>
      </tr>
      <tr>
        <th>Checksum:</th>
        <td>{deliveryAdvice.checksum}</td>
      </tr>
      <tr>
        <th>Checksum Algorithm:</th>
        <td>{deliveryAdvice.messageId}</td>
      </tr>
      <tr>
        <th>Message read receipt id:</th>
        <td>{deliveryAdvice.messageReadReceiptId}</td>
      </tr>
      <tr>
        <th>Message read receipt timestamp:</th>
        <td>{deliveryAdvice.readReceivedTimestamp}</td>
      </tr>
      <tr>
        <th>Message processed receipt id:</th>
        <td>{deliveryAdvice.messageProcessedReceiptId}</td>
      </tr>
      <tr>
        <th>Message processed receipt timestamp:</th>
        <td>{deliveryAdvice.receivedTimestamp}</td>
      </tr>
      <tr>
        <th>Message processed receipt result:</th>
        <td>{deliveryAdvice.result}</td>
      </tr>
      <tr>
        <th>Message processed receipt reference:</th>
        <td>{deliveryAdvice.reference}</td>
      </tr>
      <tr>
        <th>Message processed receipt comments:</th>
        <td>{deliveryAdvice.comments}</td>
      </tr>
      </tbody>
    </table>
    </>
  );
}

export {
  View,
}

