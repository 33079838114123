import {createElement as el } from "react";
import log from "loglevel";

import {
  type UserProfile,
} from "../../Entity/Types.js";

import {
  View,
} from "./View.js";

const logger = log.getLogger("MessageNotes");

const Controller = (props: {
  userProfile: UserProfile,
}) => {
  const {
    userProfile,
  } = props;
  logger.debug("userProfile_", userProfile);

  return el(
    View,
    {
      name: userProfile.name,
      userId: userProfile.userId,
      iconUrl: userProfile.iconUrl,
      verified: userProfile.verified,
    },
  )
}
export {
  Controller,
}
