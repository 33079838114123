// @flow
import { createElement as el, useState } from "react";
import { fork } from "fluture";
import { useDispatch } from "react-redux";

import {
  updateAccessListMutation,
  useToken,
} from "../../../../State";
import { removeEntry } from "../../Api";
import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "../../../../Util";
import View from "./view.js";
import { useCatchExpiredToken } from "./../../../../State/Token.js";

function Controller (props: {
  owner: string,
  list: string,
  entry : string,
  onDone: Function,
}) {

  const {
    onDone,
    entry,
    owner,
    list,
  } = props;

  const dispatch = useDispatch();
  const mutationAction = updateAccessListMutation();

  const token = useToken();
  const [display, setDisplay] = useState(INPUT);
  const [confirmed, setConfirmed] = useState(false);
  const catchExpiredToken = useCatchExpiredToken();

  const onSubmit = e => {
    if (confirmed) {
      setDisplay(WAITING);
      const action = removeEntry(token)(owner)(list)(entry)
      fork(error => {
        console.log(error);
        catchExpiredToken(error);
        setDisplay(FAILURE);
      })(entry_ => {
        console.log("entry_", entry_);
        setDisplay(SUCCESS);
      })(action);
    } else {
      alert("Cannot submit unless you confirm");
    }
  }


  function onCancel (e) {
    console.log("onCancel:", e);
    onDone();
  }

  function onDoneFailure (e) {
    console.log("onDoneFailure:", e);
    onDone();
  }

  function onDoneSuccess(e) {
    console.log("onDoneSuccess:", e);
    dispatch(mutationAction)
    onDone();
  }

  function onChange (e) {
    console.log(e.currentTarget.name, e.currentTarget.value);
    const n = e.currentTarget.name;
    const v = e.currentTarget.value;
    switch (n) {
      case "confirmed":
        // no value from form is checkbox not ticked.
        const actualValue = v === "confirmed"
        setConfirmed(actualValue);
        break;
      default:
        break;
    }
  }

  return el(
    View,
    {
      display,
      onCancel,
      onSubmit,
      onDoneSuccess,
      onDoneFailure,
      onChange,
      data: entry,
    }
  );

}

export { Controller as default };
