// @flow
import React, {
  useState,
} from "react";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import Form from "react-bootstrap/Form";
import * as R from "ramda";

import {
  type Permission,
} from "./../../../../Entity/Types.js";
import {
  UserProfileWidget,
} from "./../../../../Widgets/UserProfile";

const PermissionsTable = (props: {
  permissions: Array<Permission>,
  checked: Array<string>,
  onChange: Function,
}) => {
  const {
    permissions,
    checked,
    onChange,
  } = props;

  const [chkd, setChkd] = useState(checked);

  const isChecked = (permissionId) => {
     return R.contains(permissionId, chkd);
  }

  const handleChange = (e) => {
    const isChecked = e.target.checked;
    const permissionId = e.target.value;
    let newChecked;
    if(isChecked){
      newChecked = R.uniq(R.append(permissionId, chkd));
    }
    else {
      newChecked = R.without(permissionId, chkd);
    }
    setChkd(newChecked);
    onChange(newChecked);
  }

  const handleSelectAll = (e) => {
    const allRefs = R.map(
      i => i.permissionId,
      permissions,
    );
    setChkd(allRefs);
    onChange(allRefs);
  }

  const handleSelectNone = (e) => {
    const noneRefs = [];
    setChkd(noneRefs);
    onChange(noneRefs);
  }

  const columns = [
    {
      Header: "Select",
      Cell:  ({row}) => {
        // console.log(row);
        // console.log(typeof row);
        const permissionId = row._original.permissionId;
        return (
          <Form.Check
            id={permissionId}
            name={permissionId}
            key={permissionId}
            value={permissionId}
            onChange={handleChange}
            checked={isChecked(permissionId)}
          />
        )
      }
    },
    {
      Header: "User",
      Cell:  ({row}) => {
        // console.log(row);
        // console.log(typeof row);
        const permissionId = row._original.permissionId;
        const userProfile = row._original.userProfile;
        const actorName = row._original.actorName;
        return !!userProfile ? (
          <UserProfileWidget userProfile={userProfile} />
        ) : (<>{actorName}</>);
      }
    },
    {
      Header: "Role",
      accessor: "power",
    },
  ]

  return (
    <ReactTable
      className="-striped -highlight"
      defaultPageSize={10}
      data={permissions}
      columns={columns}
    />
  );
}

export {
  PermissionsTable,
}
