// @flow
import React, { createElement as el } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "../../Util";

function BModal (props: {
  display: string,
  title: string,
  onSubmit: Function,
  onDoneSuccess: Function,
  onDoneFailure: Function,
  onCancel: Function,
  children: Array<Function>,
}) {
  const {
    onCancel,
    onSubmit,
    onDoneSuccess,
    onDoneFailure,
    display,
    title,
    children,
  } = props;

  const [ formEl, successEl, failureEl ] = children;

  const waitingEl = <p>Waiting</p>

  const body = display === INPUT ? formEl:
    display === WAITING ? waitingEl:
    display === SUCCESS ? successEl:
    failureEl;

  const formFooterEl = <>
    <Button onClick={onCancel}>Cancel</Button>
    <Button onClick={onSubmit}>Submit</Button>
    </>

  const waitingFooterEl = <></>

  const successFooterEl = <>
    <Button onClick={onDoneSuccess}>Done</Button>
    </>

  const failureFooterEl = <>
    <Button onClick={onDoneFailure}>Done</Button>
    </>

  const footer = display === INPUT ? formFooterEl:
    display === WAITING ? waitingFooterEl:
    display === SUCCESS ? successFooterEl:
    display === FAILURE ? failureFooterEl:
    <>display set to unknown value</>;

  return (
    <Modal show={true}>
    <Modal.Header>{title}</Modal.Header>
    <Modal.Body>
    {body}
    </Modal.Body>
    <Modal.Footer>
    {footer}
    </Modal.Footer>
    </Modal>
  );
}

function BSuccess (props: {
  children: Array<Function>|string,
}) {
  const {
    children,
  } = props;

  return el(
    React.Fragment,
    {
    },
    children
  );
}

function BFailure (props: {
  children: Array<Function>|string,
}) {
  const {
    children,
  } = props;

  return el(
    React.Fragment,
    {
    },
    children
  );

}

export {
  BModal,
  BFailure,
  BSuccess,
};
