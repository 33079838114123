// @flow
import { createElement as el } from "react";

import { connect } from "react-redux";

import * as S from "sanctuary";

import {
  type BRI,
  type Organization,
  type Permission,
} from "../../../Entity/Types.js";

import { deserializeBRI } from "../../../Util";

import { View } from "./view.js";





const mapStateToProps = (state) => {

  const LVO = "bmbix://role/organization/certificate-validator";
  const isLVO = (p: Permission) => p.power === LVO;
  const getPermissionResource = (p: Permission): BRI => deserializeBRI(p.resource);
  const getOrganizationId = (bri: BRI): string => bri.resourceId;

  const lvoPermissions: Array<Permission> = S.filter (isLVO)  (state.permissions);
  console.log("lvoPermissions:", lvoPermissions);
  const lvoResources: Array<BRI> = S.map (getPermissionResource) (lvoPermissions);
  console.log("lvoResources:", lvoResources);
  const organizationIds: Array<string> = S.map (getOrganizationId) (lvoResources);
  console.log("organizationIds:", organizationIds);
  const allOrganizations: Array<Organization> = state.orgs;
  console.log("allOrganizations:", allOrganizations);

  const f = (oIds: Array<string>): Function => {
    console.log("oIds:", oIds);
    const ff = (o: Organization): Boolean => {
      console.log("o:", o);
      const result = S.any (oId => o.id === oId) (oIds);
      console.log("result:", result);
      return result;
    }
    return ff;
  }

  const organizations: Array<Organization> = S.filter (f(organizationIds)) (allOrganizations);
  console.log("organizations:", organizations);


  return { organizations }
}


const Controller = connect(mapStateToProps)((props: {
  organizations: Array<Organization>,
}) => {
  const {
    organizations,
  } = props;

  return el (
    View,
    {
      organizations,
    }
  )
});



export {
  Controller,
}
