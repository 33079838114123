// @flow
import React from 'react';
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import {
  OrganizationIcon,
  PeopleIcon,
  ImageIcon,
  KeyIcon,
  MentionIcon,
  PlugIcon,
} from "@primer/octicons-react";

import { type Organization } from "../Entity/Types";
import { addCheckedProperty } from "../Util";
import { BmbixImage } from "../Widgets/BmbixImage";
import {
  includesText,
} from "../Util/filter.js";

type Props = {
  orgs: Array<Organization>,
  toggleSelectHandler: Function,
  checkedItems: Array<string>,
}

const OrganizationsTable = (props:Props) => {

    const decoratedItems = addCheckedProperty(props.orgs, props.checkedItems);

    const columns = [
        {
          maxWidth: 20,
          Cell:  ({row}) => {
            // console.log(row);
            // console.log(typeof row);
            return (
              <Form.Check
                id={row.id}
                name={row.id}
                key={row.id}
                value={row.id}
                onChange={props.toggleSelectHandler}
                checked={row._original.checked}
              />
            )
          }
        },
        {
          maxWidth: 40,
          Cell:  ({row}) => {
            const organizationId = row._original.id;
            return (
              <BmbixImage src={`https://bmbix-images.s3.eu-west-2.amazonaws.com/organization/${organizationId}`} />
            )
          },
        },
        {
          Header: "Name",
          accessor: "name",
          filterable: true,
          Cell:  ({row}) => {
            const path = `/m/organizations/${row.id}`
            return (
              <Link
                to={{pathname: path}} ><OrganizationIcon /> {row._original.name}</Link>
            )
          }
        },
        {
          Header: "",
          accessor: "id",
          Cell:  ({row}) => {
            const path = `/m/organizations/${row.id}/addresses`
            return (
              <Link
                to={{pathname: path}} ><MentionIcon /> Addresses</Link>
            )
          }
        },
        {
          Header: "",
          accessor: "id",
          Cell:  ({row}) => {
            const path = `/m/organizations/${row.id}/team`
            return (
              <Link
                to={{pathname: path}} ><PeopleIcon /> Team</Link>
            )
          }
        },
        {
          Header: "",
          accessor: "id",
          Cell:  ({row}) => {
            const path = `/m/organizations/${row.id}/organization-proxies`
            return (
              <Link
                to={{pathname: path}} ><KeyIcon /> Proxies</Link>
            )
          }
        },
        {
          Header: "",
          accessor: "id",
          Cell:  ({row}) => {
            const path = `/m/organizations/${row.id}/icon`
            return (
              <Link
                to={{pathname: path}} ><ImageIcon /> Icon</Link>
            )
          }
        },
        {
          Header: "",
          accessor: "id",
          Cell:  ({row}) => {
            const path = `/m/organizations/${row.id}/platforms`
            return (
              <Link
                to={{pathname: path}} ><PlugIcon /> Platforms</Link>
            )
          }
        },
    ]

    return (
      <div>
      <ReactTable
        data={decoratedItems}
        columns={columns}
        defaultPageSize={10}
        className="-striped -highlight"
        defaultFilterMethod={includesText}
      />
      </div>
    )
}
export default OrganizationsTable
