// @flow
import React from "react";

import {
  connect as reduxConnect,
} from "react-redux";

import {
  callDelete,
  connect,
  select,
  test,
} from "./Authn.js";


class FreeagentV1AuthnApi {
  constructor (args) {
    this.token = args.token;
  }

  connect(args) {
    const args_ = {token: this.token, ...args}
    return connect(args_);
  }

  callDelete(args) {
    const args_ = {token: this.token, ...args}
    return callDelete(args_);
  }

  select(args) {
    const args_ = {token: this.token, ...args}
    return select(args_);
  }

  test(args) {
    const args_ = {token: this.token, ...args}
    return test(args_);
  }
}


const withFreeagentV1AuthnApi = (ComponentFunc) => {

  const BmbixFunc = (connectedProps) => {
    const freeagentV1AuthnApi = new FreeagentV1AuthnApi({token: connectedProps.token});
    const {token, ...userProps} = connectedProps;
    const processedProps = {...userProps, freeagentV1AuthnApi};

    return (
      <ComponentFunc {...processedProps} />
    )
  }
  const mapStateToProps = (state) => {
    return {
      token: state.token
    }
  }
  return reduxConnect(mapStateToProps)(BmbixFunc);

}


export {
  withFreeagentV1AuthnApi,
}

