// @flow
import * as R from "ramda";
import * as S from "sanctuary";
import { useSelector } from "react-redux";
import { type Platform } from "../Entity/Types";

import {
  getPlatform as getPlatform_,
} from "./../Api/Isimud/Platforms.js";

const SET_PLATFORMS = "SET_PLATFORMS";
const ADD_PLATFORM = "ADD_PLATFORM";
const DEL_PLATFORM = "DEL_PLATFORM";

const makePlatformThunk = token => organizationId => platformId => {
  const thunk = (dispatch, getState) => {
    const state = getState();
    const platformsFromState = state.platforms;
    const selector = S.compose (S.equals (platformId)) (S.prop ("platformId"));
    const maybePlatform = S.find (selector) (platformsFromState);
    const eitherPlatform = S.maybeToEither ("platform not in store") (maybePlatform);

    const fetchPlatform = async () => {
      const platformFromServer = await getPlatform_({
        token, organizationId, platformId});
      dispatch(addPlatform(platformFromServer));
      return platformFromServer;
    }

    const doNothing = pa => Promise.resolve(pa);

    const promise = S.either (fetchPlatform) (doNothing) (eitherPlatform);
    return promise
  }
  return thunk
}



const getPlatform = (
  id:string,
  platforms:Array<Platform>,
) => {
  throw new Error("getPlatform uses id which seems incorrect");
  // return R.find(R.propEq("id", id))(platforms);
}


const setPlatforms = (platforms: Array<Platform>) => {
  return {
    type: SET_PLATFORMS,
    platforms: platforms
  }
}

const addPlatform = (platform: Platform) => {
  return {
    type: ADD_PLATFORM,
    platform: platform
  }
}

const deletePlatform = (platformId: string) => {
  return {
    type: DEL_PLATFORM,
    platformId: platformId
  }
}

const reducer = (state: Array<Platform> = [], action: Object) => {
  switch (action.type) {
    case ADD_PLATFORM:
      return R.uniq(R.append(action.platform, state))
    case DEL_PLATFORM:
      return R.filter((p) => (p.platformId !== action.platformId) , state)
    case SET_PLATFORMS:
      return action.platforms;
    default:
      return state
  }
}

const usePlatform = (platformId: string) => {
  return useSelector(store => {
    const platformIdSelector = S.compose (S.equals (platformId)) (S.prop ("platformId"))
    const maybePlatform = S.find (platformIdSelector) (store.platforms);
    const platform = S.maybeToNullable (maybePlatform);
    return platform;
  });
}


export {
  reducer as platforms,
  setPlatforms,
  addPlatform,
  deletePlatform,
  getPlatform,
  makePlatformThunk,
  usePlatform,
}
