import React, {
  Component,
} from "react"
import {
  Link,
} from 'react-router-dom'
import ReactTable from "react-table-6"
import "react-table-6/react-table.css"
import type {
  Organization,
} from "../Entity/Types"


const constructPath = (root, addition) => {
  if(root.endsWith("/")){
    return `${root}${addition}`
  }
  return `${root}/${addition}`
}


type Props = {
  urlRoot: string,
  organizations: Array<Organization>,
}
class OrganizationsTable extends Component<Props> {

  render () {
    const columns = [
        {
          Header: "Name",
          accessor: "name"
        },
        {
          Header: "",
          accessor: "id",
          Cell:  ({row}) => {
            return (
              <Link to={constructPath(this.props.urlRoot, row.id)}>Addresses</Link>
            )
          }
        }
    ]
    return (
        <ReactTable
          data={this.props.organizations}
          columns={columns}
          defaultPageSize={10}
          className="-striped -highlight"
        />
    )
  }
}

export default OrganizationsTable
