// @flow
import React from "react";

import { IndexPage } from "./Widgets";

const View = (props: {

}) => {

  return (
    <IndexPage />
  )
}
export { View }
