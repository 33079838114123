// @flow
import React, {
  createElement as el,
  useState,
  useEffect,
} from "react";
import ReactDOM from "react-dom";
import {
  withRouter,
} from "react-router-dom";
import log from "loglevel";
import {
  connect,
} from "react-redux";


import {
  type BmbixAddress,
} from "../../Entity/Types.js";
import {
  withMartletOrganizationApi,
} from "../../Api/MartletOrganization";
import {
  View,
} from "./View.js";
import {
  findAddress,
} from "../../Util";

const logger = log.getLogger("Messages.Full");

const INPUT = "input";
const WAITING = "waiting";
const SUCCESS = "success";
const FAILURE = "failure";


const Controller_ = (props: {
  addresses: Array<BmbixAddress>,
  martletOrganizationApi: Object,
  match: Object,
}) => {
  const {
    addresses,
    martletOrganizationApi,
    match,
  } = props;
  const messageId = match.params.messageId;
  const [display, setDisplay] = useState(INPUT);
  const [transformedMessage, setTransformedMessage] = useState();
  const [fromAddress, setFromAddress] = useState();
  const [toAddress, setToAddress] = useState();

  const refresh = () => {
    setDisplay(WAITING);
    martletOrganizationApi.getMessageNoContext({
      messageId,
    }).then(
      message => {
        setFromAddress(
          findAddress(addresses)(message.sourceAddressId));
        setToAddress(
          findAddress(addresses)(message.destinationAddressId));
        window.SaxonJS.transform({
          stylesheetLocation: "/invoice.sef.json",
          sourceText: atob(message.content),
          destination: "raw",
        }, "async").then(
          transform => {
            logger.error("transform", transform);
            setTransformedMessage(transform.principalResult);
            setDisplay(SUCCESS);
          }
        ).catch(
          error => {
            logger.error("error", error);
          }
        );
      }
    ).catch(
      error => {
        setDisplay(FAILURE);
      }
    );
  }

  const fromAddressUrl = !!fromAddress ?
    `/u/organizations/${fromAddress.organizationdId}/addresses/${fromAddress.id}/messages/${messageId}`:
    null;

  const toAddressUrl = !!toAddress ?
    `/u/organizations/${toAddress.organizationdId}/addresses/${toAddress.id}/messages/${messageId}`:
    null;

  useEffect(
    refresh,
    [],
  )

  return el (
    View,
    {
      display,
      transformedMessage,
      fromAddressUrl,
      toAddressUrl,
    },
  )

}

const connector = connect(
  state => {
    return {
      addresses: state.addresses,
    }
  }
)

const Controller = withRouter(
  withMartletOrganizationApi(connector(Controller_)));

export {
  Controller,
}

