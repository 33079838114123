// @flow
import React, {
  useState,
} from "react";
import Button from "react-bootstrap/Button";

import {
  WAITING,
  SUCCESS,
} from "../../../Util";

import {
  Controller as TestModal,
} from "../TestModal/Controller.js";
import {
  Controller as DisconnectModal,
} from "../DisconnectModal/Controller.js";
import {
  Controller as ConnectModal,
} from "../ConnectModal/Controller.js";
import {
  type Connection,
} from "../Entity/Types.js";
import {
  type Platform,
} from "../../../Entity/Types.js";

const ConnectedView = (props: {
  display: string,
  platform: Platform,
  connection: Connection,
  onDisconnect: Function,
  onTest: Function,
}) => {
  const {
    connection,
    display,
    platform,
    onDisconnect,
    onTest,
  } = props;

  const platformId = platform.platformId;
  const platformName = platform.name;
  const organizationId = platform.organizationId;

  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [showTestModal, setShowTestModal] = useState(false);

  const testModal = !!showTestModal ?
    <TestModal
      platformId={platformId}
      organizationId={organizationId}
      onComplete={e => {
        setShowTestModal(false);
        onTest();
      }}
    /> :
    null;

  const disconnectModal = !!showDisconnectModal ?
    <DisconnectModal
      platformId={platformId}
      organizationId={organizationId}
      onComplete={e => {
        setShowDisconnectModal(false);
        onDisconnect();
      }}
    /> :
    null;

  return (
    display === WAITING ?
    (
      <p>Waiting ...</p>
    ) :
    display === SUCCESS ?
    (
      <>
      {testModal}
      {disconnectModal}
      <h3>Freeagent Platform</h3>
      <h2>{platformName}</h2>
      <hr/>
      <h2>Status</h2>
      <p>connected</p>
      <Button onClick={e => setShowDisconnectModal(true)} className="mr-2">Disconnect</Button>
      <Button onClick={e => setShowTestModal(true)}>Test</Button>
      </>
    ) :
    (
      <p>Ooops Something went wrong</p>
    )
  )
}

const NotConnectedView = (props: {
  display: string,
  platform: Platform,
  onConnect: Function,
}) => {
  const {
    onConnect,
    display,
    platform,
  } = props;

  const [showConnectModal, setShowConnectModal] = useState(false);
  const platformId = platform.platformId;
  const platformName = platform.name;
  const organizationId = platform.organizationId;

  const connectModal = !!showConnectModal ?
    <ConnectModal
      organizationId={organizationId}
      platformId={platformId}
      onComplete={e => {
        setShowConnectModal(false);
        onConnect();
      }}

    /> :
    null;

  return (
    display === WAITING ?
    (
      <p>Waiting ...</p>
    ) :
    display === SUCCESS ?
    (
      <>
      {connectModal}
      <h3>Freeagent Platform</h3>
      <h2>{platformName}</h2>
      <hr/>
      <h2>Status</h2>
      <p>not connected</p>
      <Button onClick={e => setShowConnectModal(true)}>Connect</Button>
      </>
    ) :
    (
      <p>Ooops something went wrong</p>
    )
  )
}

export {
  ConnectedView,
  NotConnectedView,
}
