// @flow
import React, {
  useState,
} from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import {
  withMartletOrganizationApi,
} from "../Api/MartletOrganization";

const INPUT = "input";
const WAITING = "waiting";
const SUCCESS = "success";
const FAILURE = "failure";

const Modal_ = (props: {
  martletOrganizationApi: Object,
  organizationId: string,
  permissionIds: Array<string>,
  onSuccess: Function,
  onCancel: Function,
  onFailure: Function,
}) => {
  const {
    martletOrganizationApi,
    organizationId,
    permissionIds,
    onCancel,
    onSuccess,
    onFailure,
  } = props;
  const [display, setDisplay] = useState(INPUT);
  console.log("permissionIds", permissionIds);

  const handleSubmit = () => {
    setDisplay(WAITING);
    martletOrganizationApi.deletePermission({
      organizationId,
      permissionId: permissionIds[0],
    }).then(
      permission => {
        setDisplay(SUCCESS);
      }
    ).catch(
      error => {
        setDisplay(FAILURE);
        onFailure();
      }
    );
  }

  const input = display === INPUT ? (
    <p>Do you want to delete the selected permissions&#63;</p>
  ): null;

  const waiting = display === WAITING ? (
    <p>Waiting ...</p>
  ): null;

  const success = display === SUCCESS ? (
    <p>Success!</p>
  ): null;

  const failure = display === FAILURE ? (
    <p>Ooops! something went wrong ...</p>
  ): null;

  const submitButton = display === INPUT ? (
    <Button onClick={handleSubmit}>Submit</Button>
  ): null;

  const cancelButton = display !== SUCCESS ? (
    <Button onClick={onCancel}>Cancel</Button>
  ): null;

  const doneButton = display === SUCCESS ? (
    <Button onClick={onSuccess}>Done</Button>
  ): null;

  return (
    <Modal show={true}>
    <Modal.Header>Delete Permission</Modal.Header>
    <Modal.Body>
    {input}
    {waiting}
    {success}
    {failure}
    </Modal.Body>
    <Modal.Footer>
    {submitButton}
    {cancelButton}
    {doneButton}
    </Modal.Footer>
    </Modal>
  );
}

const PermissionsDeleteModal = withMartletOrganizationApi(Modal_);

export {
  PermissionsDeleteModal,
}
