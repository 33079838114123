// @flow
import React from "react";
import ReactTable from "react-table-6";
import * as S from "sanctuary";
import * as $ from "sanctuary-def";

import {
  type UserProfile,
  type OrganizationProfile,
} from "./../../../Entity/Types.js";
import { UserProfileWidget } from "./../../../Widgets/UserProfile";
import { OrganizationProfileWidget } from "./../../../Widgets/OrganizationProfile";

const View = (props: {
  userProfiles: Array<UserProfile>,
  organizationProfiles: Array<OrganizationProfile>,
}) => {
  const {
    userProfiles,
    organizationProfiles,
  } = props;
  console.log("nikolai", userProfiles);
  console.log("tesla", organizationProfiles);

  const userProfileColumns = [
    {
      Header: "Profile",
      Cell: ({row}) => <UserProfileWidget
      userProfile={row._original}/>,
    },
  ];

  const decidePPCSignatoryStatus = maybeBoolean =>
    S.either (S.show) (bool => bool ? "Signatory" : "Not Signatory") (S.maybeToEither ("n/a") (maybeBoolean))

  const organizationProfileColumns = [
    {
      Header: "Profile",
      Cell: ({row}) => <OrganizationProfileWidget
      organizationProfile={row._original}/>,
    },
    {
      Header: "PPC Signatory",
      Cell: ({row}) => decidePPCSignatoryStatus (S.get (S.is ($.Boolean)) ("ppcSignatory") (row._original)),
    },
  ];

  const userTable = userProfiles.length > 0 ? <ReactTable
      data={userProfiles}
      columns={userProfileColumns}
      className="-striped -highlight"
      defaultPageSize={10}
    />: null;


  const organizationTable = organizationProfiles.length > 0 ? <ReactTable
      data={organizationProfiles}
      columns={organizationProfileColumns}
      className="-striped -highlight"
      defaultPageSize={10}
    />: null;

  const noResults = (userProfiles.length === 0) && (organizationProfiles.length ===
    0) ? <p>There were no results for your query</p>: null;

  return (
    <>
      {userTable}
      {organizationTable}
      {noResults}
    </>
  )

}

export { View };
