// @flow
import { createElement as el, useEffect } from "react";
import { useMachine } from "@xstate/react";
import { createMachine, assign } from "xstate";
import {
  listPaymentMethods,
} from "./../../../Api/MartletOrganization/Users";
import { useAccount } from "./../../../State/Account.js";
import { useToken } from "./../../../State/Token.js";
import { View } from "./view.js";


const paymentMethodsMachine = createMachine({
  id: "paymentMethodsMachine",
  context: {
    token: undefined,
    userId: undefined,
    errors: undefined,
    paymentMethods: undefined
  },
  initial: "unconfigured",
  states: {
    unconfigured: {
      on: {
        CONFIGURE: {
          target: "ready",
          actions: assign({
            userId: (c, e, m) => e.userId,
            token: (c, e, m) => e.token
          }),
        }
      },
    },
    ready: {
      on: {
        SUBMIT: "working",
      }
    },
    working: {
      invoke: {
        src: (context, event) => listPaymentMethods({token: context.token, userId: context.userId}),
        onDone: {
          target: "success",
          actions: assign({
            paymentMethods: (c, e) => e.data,
          })
        },
        onError: {
          target: "failure",
          actions: assign({
            errors: (c, e) => e.data,
          })
        }
      },
    },
    success: {
      on: {
        RESET: "ready",
        REFRESH: "working",
      }
    },
    failure: {
      on: {
        RESET: "ready"
      }
    },
  },
});





const Controller = (props: {
}) => {
  const account = useAccount();
  const userId = account.sub;
  const token = useToken();
  const [ paymentMethodsState, paymentMethodsSend ] = useMachine(paymentMethodsMachine);

  useEffect(
    () => {
      paymentMethodsSend({type: "CONFIGURE", userId, token});
      paymentMethodsSend({type: "SUBMIT"});
    },
    [userId, token, paymentMethodsSend]
  );


  const handleItemsChanged = (e) => {
    console.log("Paramaribo: ", e, paymentMethodsState.value);
    paymentMethodsSend({type: "REFRESH"})
  }


  return el(
    View,
    {
      paymentMethodsState,
      onItemsChanged: handleItemsChanged,
    },
  );


}


export {
  Controller,
}
