// @flow
import React from "react";
import * as S from "sanctuary";

import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import {
  Link,
} from "react-router-dom";
import log from "loglevel";
import {
  NoteIcon,
} from "@primer/octicons-react";
import Button from "react-bootstrap/Button";
import { BmbixDateTime, } from "../Widgets/";

const logger = log.getLogger("UnaddressedMessages");

const UnaddressedMessagesTable = (props:{
  organizationId: string,
  addressId: string,
  aliasedMessages: Array<Object>,
}) => {
  const {
    organizationId,
    addressId,
    aliasedMessages,
  } = props;

  logger.error("aliasedMessages", aliasedMessages);

  const columns = [
    {
      Header: "Token",
      accessor: "obj.messageToken",
    },
    {
      Header: "Issued at",
      accessor: "obj.receivedAt",
      Cell: ({value}) => <BmbixDateTime timeStamp={value} />
    },
    {
      Header: "Local Account",
      accessor: "obj.localAccount",
    },
    {
      Header: "Ref",
      accessor: "obj.senderReference",
    },
    {
      Header: "Type",
      accessor: "obj.messageType",
    },
    {
      Header: "Claimed by",
      accessor: "alias",
    },
    {
      Header: "Claimed at",
      accessor: "obj.claimedAt",
      Cell: ({value}) => <BmbixDateTime timeStamp={value} />
    },
    {
      Header: "Message",
      accessor: "obj.martletMessageId",
      Cell:  ({row}) => {
        const messageId = row["obj.martletMessageId"];
        if (!!messageId) {
          const path = `/u/organizations/${organizationId}/addresses/${addressId}/messages-status/${messageId}`;
          return (
            <Link to={{pathname: path}} ><Button><NoteIcon /> Status</Button></Link>
          )
        } else {
          return null;
        }
      }
    },
  ]

  const caseInsensitiveFilter =
    (filter, row, column) =>
  {
    const re = S.regex ("g") (S.toLower (filter.value));
    return S.test (re) (S.toLower (row[filter.id]));
  }

  return (
    <div>
    <ReactTable
      data={ aliasedMessages }
      columns={ columns }
      showPagination={false}
      pageSize={ S.size (aliasedMessages)}
      filterable={true}
      defaultFilterMethod={caseInsensitiveFilter}
      className="-striped -highlight"
      defaultSorted = {[{id:"obj.receivedAt", desc: true}]}
    />
    </div>
  )
}

export {
  UnaddressedMessagesTable,
}
