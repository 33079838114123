// @flow
import React, {
} from "react";
import Form from "react-bootstrap/Form";


const AddForm = (props:{
  onChange: any,
}) => {
  const {
    onChange,
  } = props;

  const handleInputChange = (e) => {
    if (e.target.name === "display_name") {
      onChange(e.target.value);
    }
  }

  return (
    <>
      <Form>
      <Form.Group>
      <Form.Label>Display Name</Form.Label>
      <Form.Control
        type="text"
        name="display_name"
        onChange={handleInputChange}
        placeholder="Enter address display name"
      />
      </Form.Group>
      </Form>
    </>
  );
}


export default AddForm;
export {
  AddForm,
}
