// @flow

import React, {
  createElement as el,
  useState,
} from "react";
import Button from "react-bootstrap/Button";

import {
  AuthenticatedTenant,
  Platform,
} from "./../../../Entity/Types.js";
import {
  TenantMappingCreateModal,
} from "./TenantMappingCreateModal.js";

const Controller = (props: {
  tenants: Array<AuthenticatedTenant>,
  platform: Platform,
  onUpdate: Function,
}) => {

  const {
    onUpdate,
    tenants,
    platform,
  } = props;

  const [ showModal, setShowModal ] = useState(false);

  const handleShowWidget = e => setShowModal(true);

  const handleHideWidget = e => setShowModal(false);

  const button = el(
    ButtonView,
    {
      onShowWidget: handleShowWidget,
    }
  );

  const modal = el(
    ModalView,
    {
      platform: platform,
      tenants: tenants,
      onCancel: handleHideWidget,
      onUpdate,
    },
  )

  return showModal
  ? modal
  : button;
}


const ModalView = (props: {
  tenants: Array<AuthenticatedTenant>,
  platform: Platform,
  onCancel: Function,
  onUpdate: Function,
}) => {
  const {
    tenants,
    platform,
    onCancel,
    onUpdate,
  } = props;

  return <TenantMappingCreateModal
    onUpdate={onUpdate}
    onCancel={onCancel}
    platform={platform}
    tenants={tenants}
  />
}


const ButtonView = (props: {
  onShowWidget: Function,
}) => {

  const {
    onShowWidget,
  } = props;


  return (<>
    <Button onClick={onShowWidget}>Create a new Tenant Mapping</Button>
  </>
  )
}

export {
  Controller,
  Controller as TenantMappingCreate,
}
