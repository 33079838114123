// @flow

import {
  send,
  assign,
  sendParent,
} from "xstate";
const fetcherStateChart = {
  id: "fetcher",
  context: {},
  initial: "idle",
  states: {
    idle: {
      entry: "setConfiguration",
      on: {
        START: "fetching",
      },
    },
    fetching: {
      invoke: {
        id: "invoking fetch",
        src: "fetch",
        onDone: {
          actions: [
            assign((context, event) => ({result: event.data})),
            send("SUCCESS"),
          ],
        },
        onError: {
          actions: send("FAILURE"),
        },
      },
      on: {
        SUCCESS: "success",
        FAILURE: "failure",
      },
    },
    success: {
      entry:  sendParent((context, event) => ({type: "UPDATE", ...context})),
      type: "final",
    },
    failure: {
      entry:  sendParent((context, event) => ({type: "FAILURE"})),
      type: "final",
    },
  }
}

export {
  fetcherStateChart,
}
