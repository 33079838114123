// @flow
import React, { useEffect } from "react"

import * as R from "ramda"
import {
  Row,
  Col,
} from 'react-bootstrap'
import { connect } from "react-redux"
import {
  withRouter,
  Link,
} from "react-router-dom"

import { type BmbixAddress } from "../Entity/Types";
import { PurposeEditor } from "./PurposeEditor.js";
import { WebHookEditor } from "../Widgets/WebHookEditor";

const AddressWidget = (props: {
  address: BmbixAddress,
}) => {


  const {
    address,
  } = props;

  useEffect (
    () => {
      document.title = `Address ${address.id.slice(0,8)} settings`;
      return () => {
        document.title = "Bmbix";
    }},
    [address]
  );
  return (
    <>
    <div className="clearfix">
    <h2 className="float-left">{address.displayName}</h2>
    <Link className="align-middle d-inline-flex ml-2 mt-2" to={`/profiles/address/${address.id}`} >public profile</Link>
    </div>
    <hr/>
    <Row>
    <Col>
    <h3>Bmbix Id</h3>
    <p>{address.id}</p>
    <hr/>
    <h3>Alias</h3>
    <p>{address.alias}</p>
    <hr/>
    <h3>Status</h3>
    <p>Address is closed: {address.isClosed.toString()}</p>
    <hr/>
    <h3>Purpose</h3>
    <p>{address.purpose}</p>
    <PurposeEditor
      address={address}
    />
    <hr/>
    </Col>
    <Col>
    <h3>Integrations Web Hook</h3>
    <p>URL: {address.webHook}</p>
    <p>Token: {address.webHookToken}</p>
    <WebHookEditor
      address={address}
    />
    <hr/>
    </Col>
    </Row>
    </>
  )
}



const ASPagePresenter = (props: {
  address: BmbixAddress,
  match: Object,
}) => {


    return (
      <AddressWidget address={props.address} />
    )
}



const mapStateToProps = (state, ownProps) => {
  const addressId = ownProps.match.params.addressId;
  const address = R.find(R.propEq("id", addressId))(state.addresses);
  return {
    address,
  }
}

const ASPage = withRouter(connect(
  mapStateToProps,
)(ASPagePresenter))


export default ASPage
