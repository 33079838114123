// @flow
import * as R from "ramda";


import {
  type ExtractionCandidateReport,
} from "../Entity/Types";

const INSERT_ECR = "INSERT_ECR";
const DELETE_ECR = "DELETE_ECR";
const UPDATE_ECR = "UPDATE_ECR";


const insertExtractionCandidateReport = (ecr: ExtractionCandidateReport) => {
  return Object.freeze({
    type: INSERT_ECR,
    ecr: ecr,
  });
}


const updateExtractionCandidateReport = (ecr: ExtractionCandidateReport) => {
  return Object.freeze({
    type: UPDATE_ECR,
    ecr: ecr,
  });
}

const deleteExtractionCandidateReport = (addressId: string) => {
  return Object.freeze({
    type: DELETE_ECR,
    addressId: addressId,
  });
}


const handleInsert = (state, ecr) => {
  return R.uniqBy(
    (ecr) => ecr.header.addressId,
    R.prepend(ecr, state),
  );
}

const handleDelete = (state, addressId) => {
  return R.filter(
    ecr => ecr.header.addressId !== addressId,
    state,
  );
}

const handleUpdate = (state, ecr) => {
  const addressId = ecr.header.addressId;
  const filtered = R.filter(
    ecr => ecr.header.addressId !== addressId,
    state,
  );
  const prepended = R.prepend(
    ecr,
    filtered,
  )
  return prepended;
}


const reducer = (state: Array<ExtractionCandidateReport>=[], action:Object) => {
  switch(action.type){
    case INSERT_ECR:
      return handleInsert(state, action.ecr);
    case DELETE_ECR:
      return handleDelete(state, action.addressId);
    case UPDATE_ECR:
      return handleUpdate(state, action.ecr);
    default:
      return state;
  }
}


export {
  reducer as extractionCandidateReports,
  insertExtractionCandidateReport,
  deleteExtractionCandidateReport,
  updateExtractionCandidateReport,
}
