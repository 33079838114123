// @flow
import Future from "fluture";

import {
  ApiClient,
  AddressesApi,
} from "@bmbix/bmb_martlet_organization_client";

import { createMessageFromGW } from "./../../Api/MartletOrganization";

const getMessage = (token: String) => (messageId: String): Future => {

  const defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AddressesApi(defaultClient);

  const future = Future((reject, resolve) => {
    apiInstance.selectNoContext(
      messageId,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          const messageGW = data.message;
          const message = createMessageFromGW(messageGW);
          resolve(message);
        }
      }
    );
    return console.log
  });
  return future;
}


export { getMessage };
