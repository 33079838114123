// @flow
import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { IndexPage, ListPage } from "./Widgets";

const View = (props: {
}) => {

  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:list/entries`}>
        <ListPage />
      </Route>
      <Route path={`${match.path}/:list`}>
        <ListPage />
      </Route>
      <Route path={`${match.path}`}>
        <IndexPage />
      </Route>
    </Switch>
  )
}

export { View }
