// @flow

import Future from "fluture";
import * as S from "sanctuary";

import {
  ApiClient,
  OrganizationsApi
} from "@bmbix/bmb_martlet_organization_client";

import { createOrganizationProxyFromGW } from "./adapt.js";
import { type OrganizationProxy } from "./../../Entity/Types.js";

const getProxies =
  (token: string) =>
  (organizationId: string): Future<OrganizationProxy> =>
{
  const apiInstance = new OrganizationsApi();
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;

  return Future((reject, resolve) => {

    apiInstance.listProxies(organizationId, (error, data, response) => {
      if (error) {
        console.log("error", error);
        reject(error);
      } else {
        const gws = data.organization_proxies;
        const organizationProxies = S.map (createOrganizationProxyFromGW) (gws);
        resolve(organizationProxies);
      }
    });

    return console.log;
  });
}


export {
  getProxies,
}

