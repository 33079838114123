// @flow
import { type MessageType } from "./../../Entity/Types.js";

const createMessageType = (args: {
  id: string,
  updatedAt: string,
  address: string,
  messageType: string,
  direction: string,
  retentionPolicy: string,
}): MessageType => {
  return Object.freeze(args);
}

export {
  createMessageType,
}
