// @flow
import React from "react";

import {
  ApiClient,
  CertificateApi,
} from "@bmbix/bmb_polly_client";

import Future from "fluture";

import { connect } from "react-redux";

import {
  type ValidateOrganizationRequest,
  type ValidateUserRequest,
} from "../entity.js";

import { createCertificateFromGW } from "./util.js";

const mapStateToProps = (state) => {
  return {
    token: state.token
  }
}

const validate= (token: string) => (organizationId: string) => (body: Function): Future => {

  const apiInstance = new CertificateApi();
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;

  return Future((reject, resolve) => {
    apiInstance.validate(
      body,
      organizationId,
      (error, data, response) => {
        if(error){
          console.log("error 323423232342");
          reject(error);
        } else {
          console.log("success 52341789891");
          const certificateGW = data.certificate;
          const certificate = createCertificateFromGW(certificateGW);
          resolve(certificate);
        }
      }
    );
    const cancelFunc = console.log;
    return cancelFunc;
  });

}


const validateOrganization = (token: string) => (organizationId: string) => (args: ValidateOrganizationRequest): Future => {
  const body = {
    organization: {
      bmbix_id: args.bmbixId,
      registrar: args.registrar,
      register_entry: args.registerEntry,
      organization_name: args.organizationName,
    }
  }
  return validate(token)(organizationId)(body);
}


const validateUser = (token: string) => (organizationId: string) => (args: ValidateUserRequest): Future => {
  const body = {
    user: {
      bmbix_id: args.bmbixId,
      cn: args.cn,
      country: args.country,
      state: args.state,
      locality: args.locality,
    }
  }
  return validate(token)(organizationId)(body);
}




const withValidateOrganization = (Component: Function) => {
  const newComponent = (propsWithToken) => {
    const {token, ...props} = propsWithToken;
    const apiFunc = validateOrganization(token);
    const newProps = Object.assign(props, {validateOrganization: apiFunc});
    return <Component {...newProps} />
  }
  return connect(mapStateToProps)(newComponent)
}


const withValidateUser= (Component: Function) => {
  const newComponent = (propsWithToken) => {
    const {token, ...props} = propsWithToken;
    const apiFunc = validateUser(token);
    const newProps = Object.assign(props, {validateUser: apiFunc});
    return <Component {...newProps} />
  }
  return connect(mapStateToProps)(newComponent)
}

export {
  withValidateUser,
  withValidateOrganization,
}

