// @flow
import React, {useEffect, useState} from "react";
import * as S from "sanctuary";
import * as $ from "sanctuary-def";
import { useMachine } from "@xstate/react";
import { createMachine } from "xstate";
import { fork } from "fluture";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {
  setBillingAddress as setBillingAddressApi,
} from "./../../Api";
import { useToken } from "./../../../State";
import {
  Spinner,
} from "./../../../Widgets/Toast";


import { type BillingAddress } from "./../../../Entity/Types";

const fetchingMachine = createMachine({
  id: "fetchingMachine",
  initial: "input",
  states: {
    waiting: {
      on: {
        SUCCESS_HAPPENED: "success",
        FAILURE_HAPPENED: "failure",
      }
    },
    success: {
      on: {
        SUBMIT_HAPPENED: "waiting",
      }
    },
    failure: {
      on: {
        RESET_HAPPENED: "input"
      }
    },
    input: {
      on: {
        SUBMIT_HAPPENED: "waiting"
      }
    },
  },
});

const getTimestamp = () => (new Date()).getTime()

const Controller = (props: {
  organizationId: string,
}) => {
  const {
    organizationId,
  } = props;
  const [ UIVersion, setUIVersion ] = useState(getTimestamp())
  const [ state, send ] = useMachine(fetchingMachine);
  const [ billingAddress, setBillingAddress ] = useState();
  const token = useToken();
  const [ addressId, setAddressId ] = useState();

  const handleChange = e => {
    const addressId = e.currentTarget.value;
    setAddressId(addressId);
  }

  const onSubmit = e => {
    send({type: "SUBMIT_HAPPENED"});
    const action = setBillingAddressApi({
      organizationId,
      addressId,
      token,
    })
    fork(
      error => {
        send({type: "FAILURE_HAPPENED"});
        console.log(error);
      }
    )(
      billingAddress => {
        send({type: "SUCCESS_HAPPENED"});
      }
    )(action)
  }

  return state.value === "input"
  ? <>
    <h2>Set Billing Address</h2>
    <Form>
    <Form.Group>
    <Form.Label>Set Billing Address</Form.Label>
    <Form.Control
      type="text"
      name="addressId_"
      placeholder="bmbix address id"
      onChange={handleChange}
      value={addressId}
      />
    <Form.Text>Destination address id</Form.Text>
    </Form.Group>
    </Form>
    <Button onClick={onSubmit} >Submit</Button>
    <ul>
    {S.fromMaybe ("") (S.get (S.complement (S.is ($.Undefined))) ("addressId") (billingAddress))}
    </ul>
    </>
  : state.value === "waiting"
  ? <Spinner height="50" />
  : state.value === "failure"
  ? <p>Oops</p>
  : state.value === "success"
  ? <p>Address has been set.</p>
  : <p>Unknown state</p>;
}


export {
  Controller,
}
