// @flow
import * as R from "ramda";
import log from "loglevel";

import {
  ApiClient,
  OrganizationsApi,
  Permission as PermissionGW,
} from "@bmbix/bmb_martlet_organization_client";
import {
  type Permission,
} from "../../Entity/Types";
import { callBack } from "./../CallBack/";
import { createPermissionFromGW } from "./adapters.js";

const logger = log.getLogger("MartletOrganization:Permission");



const getPermissions = (args: {
  token:string,
  organizationId:string,
  apiInstance?: OrganizationsApi,
}):Promise<Array<Permission>> => {
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = args.token;

  let apiInstance;
  if (!args.apiInstance) {
    apiInstance = new OrganizationsApi();
  }
  else {
    apiInstance = args.apiInstance;
  }

  const dataHandler = data => {
    const permissionsResponseGW = data;
    const permissionsGWS = permissionsResponseGW.permissions;
    const permissions = R.map(createPermissionFromGW, permissionsGWS);
    return permissions;
  }

  return new Promise((resolve, reject) => {
    apiInstance.listPermissions(
      args.organizationId,
      callBack (reject, resolve) (dataHandler),
    );
  });
}


const deletePermission = (args: {
  token: string,
  organizationId: string,
  permissionId: string,
  defaultClient_: Object,
}): Promise<?Permission> => {
  const {
    token,
    organizationId,
    permissionId,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new OrganizationsApi(defaultClient);
  const dataHandler = data => {
    return null;
  }
  return new Promise((resolve, reject) => {
    apiInstance.deletePermission(
      organizationId,
      permissionId,
      callBack(reject, resolve) (dataHandler),
    );
  });
}

const createPermission = (args: {
  token: string,
  organizationId: string,
  actor: string,
  power: string,
  defaultClient_: Object,
}): Promise<Permission> => {
  const {
    token,
    organizationId,
    actor,
    power,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  const body = PermissionGW.constructFromObject({
    actor,
    power,
  });

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new OrganizationsApi(defaultClient);

  const dataHandler = data => {
    const permissionGW = data.permission;
    const permission = createPermissionFromGW(permissionGW);
    return permission;
  }

  return new Promise((resolve, reject) => {
    apiInstance.createPermission(
      body,
      organizationId,
      callBack(reject, resolve) (dataHandler),
    );
  });
}

const getAllPermissions = async (args:{
  token: string,
  organizationIds: Array<string>
}):
Promise<Array<Permission>> => {
  const allPromises = await Promise.allSettled(
    R.map(
      oId => getPermissions({token: args.token, organizationId: oId}),
      args.organizationIds,
    )
  );
  logger.debug("allPromises", allPromises);
  const fulfilledPromises = R.filter(
    ap => ap.status === "fulfilled",
    allPromises,
  );
  logger.debug("fulfilledPromises", fulfilledPromises);
  const permissionsLists = R.map(
    fp => fp.value,
    fulfilledPromises,
  );
  logger.debug("permissionsLists", permissionsLists);
  const permissions = R.flatten(permissionsLists);
  logger.debug("permissions", permissions);
  return permissions;
}

const decoy = () => {console.log("decoy was called")}

export {
  getPermissions,
  getAllPermissions,
  deletePermission,
  createPermission,
  decoy,
}
