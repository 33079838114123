// @flow
import React from "react";

import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "./../../../Util";
import { type Certificate } from "./../../../Entity/Types.js";
import CertificateViewer from
"./../../../Widgets/Certificate/Viewer";
import { CenteredSpinner } from "./../../../Widgets/Toast.js";

const View = (props: {
  certificate: Certificate,
  display: String,
}) => {
  const {
    certificate,
    display,
  } = props;

  const input = display === INPUT ? (
    <p>Ready to fetch profile</p>
  ): null;

  const waiting = display === WAITING ? (
    <CenteredSpinner />
  ): null;

  const success = display === SUCCESS && !!certificate ? (
    <CertificateViewer pem={certificate.data}
    />
  ): null;

  const failure = display === FAILURE ? (
    <p>Ooops!, something went wrong</p>
  ): null;

  return (
    <>
    <h1>Organization Identity Validation Certificate</h1>
    <hr/>
    {input}
    {waiting}
    {success}
    {failure}
    </>
  );
}

export {
  View,
}
