// @flow
import {
  ApiClient,
  AuthzApi,
  GenerateURL as GenerateURLGW,
  GenerateURLResponse as GenerateURLResponseGW,
} from "@bmbix/bmb_xero_v1_authz_client"


const createURLFromGW = (generateURLResponseGW: GenerateURLResponseGW): string => {
  return generateURLResponseGW.url;
  }


const generateURL = (args: {
  token: string,
  return_to_url: string,
  defaultClient_?: Function,
}): Promise<string> => {
  const {
    token,
    return_to_url,
    defaultClient_,
  } = args;
  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  const body = GenerateURLGW.constructFromObject({
    return_to_url,
  });

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AuthzApi(defaultClient);
  return new Promise((resolve, reject) => {
    apiInstance.generateUrl(
      body,
      (error, data, response) => {
        if (error) {
          resolve(error);
        } else {
          const generateURLResponseGW = data;
          const url = createURLFromGW(generateURLResponseGW);
          resolve(url);
        }
      }
    );
  });


}


export {
  createURLFromGW,
  generateURL,
}
