// @flow
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useForm, Controller } from "react-hook-form";
import {
  Spinner,
} from "./../../../Widgets/Toast";


const ButtonView =
  (props: {
    onShowModalView: Function,
  }) =>
{
    return <Button onClick={props.onShowModalView} >New</Button>
}


const ConfirmationForm =
  (props: {
    onSubmit: Function,
    state: Function,
  }) =>
{
  const {
    onSubmit,
    state,
  } = props;

  const { control, handleSubmit } = useForm();


  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Group>
    <Form.Label>Do you want to create new client credentials for
    {state.context.organizationProxy.name}</Form.Label>
    <Controller
      name="resource"
      control={control}
      defaultValue="" // not used.
      render={({field}) => <Form.Control hidden {...field} />}
    />

    </Form.Group>
    <Button as="input" type="submit" value="Submit" />
    </Form>
  )
}


const Body =
  (props: {
    state: Function,
    onSubmit: Function,
  }) =>
{
  const {
    onSubmit,
    state
  } = props;
  return state.value === "idle"
  ? <ConfirmationForm onSubmit={onSubmit} state={state} />
  : state.value === "working"
  ? <Spinner height="24" />
  : state.value === "failure"
  ? <p>Sorry, that request failed with this error: {state.context.errors}</p>
  : state.value === "success"
  ? <>
    <p>Client id: {state.context.oauth2Client.clientId}</p>
    <p>Client secret: {state.context.oauth2Client.clientSecret}</p>
    </>
  : <p>Sorry, the application has run into a problem. </p>
}



const ModalView =
  (props: {
    state: Function,
    onCancel: Function,
    onSubmit: Function,
  }) =>
{
  const {
    state,
    onCancel,
    onSubmit,
  } = props;

  return (
    <Modal show={true}>
    <Modal.Header closeButton onHide={onCancel}>
      <Modal.Title>New client credentials</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Body state={state} onSubmit={onSubmit} />
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={onCancel}>Cancel</Button>
    </Modal.Footer>
    </Modal>
  );
}


const View =
  (props: {
    modeState: Function,
    fetchState: Function,
    onShowModalView: Function,
    onSubmit: Function,
    onCancel: Function,
  }) =>
{
  const {
    modeState,
    fetchState,
    onShowModalView,
    onSubmit,
    onCancel,
  } = props;

  return modeState.value === "button"
  ? <ButtonView onShowModalView={onShowModalView}/>
  : modeState.value === "modal"
  ? <ModalView state={fetchState}  onSubmit={onSubmit} onCancel={onCancel} />
  : <p>Sorry, the application has run into a problem. </p>
}


export {
  View,
}
