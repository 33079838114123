// @flow

import React, {
  useState,
} from "react"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button";

import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "../Util";
import {
  Spinner,
} from "../Widgets/Toast";
import AddForm from "./AddForm";
import { addAddress } from "./../Api/MartletOrganization/Organization.js";
import { useToken } from "./../State/Token.js";


const AddModal = (props: {
  organizationId: string,
  onCancel: Function,
  onComplete: Function,
}) => {

  const {
    organizationId,
    onCancel,
    onComplete,
  } = props;

  const [display, setDisplay] = useState(INPUT);
  const [addressDisplayName, setAddressDisplayName] = useState();
  const token = useToken();

  const handleAdd = (e) => {
    setDisplay(WAITING);
    addAddress({
      token,
      organizationId,
      displayName: addressDisplayName,
    }).then(
      address => {
        setDisplay(SUCCESS);
      }
    ).catch(
      error => {
        setDisplay(FAILURE);
      }
    );
  }

  const handleCancel = (e) => {
    onCancel();
  }

  const handleComplete = (e) => {
    onComplete();
  }

  const changeListener = (addressDisplayName: string) => {
    setAddressDisplayName(addressDisplayName);
  }

  const input = display === INPUT ? (
    <AddForm
      onChange={changeListener}
    />
  ): null;

  const waiting = display === WAITING ? (
    <Spinner />
  ): null;

  const success = display === SUCCESS ? (
    <p>Success, Created address!</p>
  ): null;

  const failure = display === FAILURE ? (
    <p>Ooops! Looks like something went wrong</p>
  ): null;

  const submitButton = display === INPUT ? (
    <Button variant="primary" onClick={handleAdd}>Save changes</Button>
  ): null;

  const cancelButton = display === INPUT || display === WAITING ? (
    <Button variant="secondary" onClick={handleCancel}>Close</Button>
  ): null;

  const completeButton = display === SUCCESS || display === FAILURE ? (
    <Button variant="secondary" onClick={handleComplete}>Done</Button>
  ): null;


  return (
      <Modal show={true} >
        <Modal.Header>
          <Modal.Title>Add Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {input}
            {waiting}
            {success}
            {failure}
          </>

        </Modal.Body>
        <Modal.Footer>
            {submitButton}
            {cancelButton}
            {completeButton}
        </Modal.Footer>
      </Modal>
  );
}


export {
  AddModal,
}

