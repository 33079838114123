// @flow
import {createElement as el} from "react";
import {View} from "./View.js";


const iDiv = (a: number, b: number) => Math.floor(a/b);


const relativeTime = (args: {
  timestamp: Date,
  clock?: Function,
}): string => {
  const {
    timestamp,
    clock,
  } = args;
  const now = !!clock ? clock() : new Date();
  const elapsed = now - timestamp;
  const ONE_SECOND = 1000;
  const ONE_MINUTE = ONE_SECOND * 60;
  const ONE_HOUR = ONE_MINUTE * 60;
  const ONE_DAY = ONE_HOUR * 24;
  const ONE_WEEK = ONE_DAY * 7;
  const ONE_MONTH = ONE_DAY * 30;
  const ONE_YEAR = ONE_DAY * 365;
  return iDiv(elapsed, ONE_YEAR) > 0 ? iDiv(elapsed, ONE_YEAR) + " year(s) ago":
    iDiv(elapsed, ONE_MONTH) > 0 ? iDiv(elapsed, ONE_MONTH) + " month(s) ago":
    iDiv(elapsed, ONE_WEEK) > 0 ? iDiv(elapsed, ONE_WEEK) + " week(s) ago":
    iDiv(elapsed, ONE_DAY) > 0 ? iDiv(elapsed, ONE_DAY) + " day(s) ago":
    iDiv(elapsed, ONE_HOUR) > 0 ? iDiv(elapsed, ONE_HOUR) + " hour(s) ago":
    iDiv(elapsed, ONE_MINUTE) > 0 ? iDiv(elapsed, ONE_MINUTE) + " minute(s) ago":
    "second(s) ago";
}


const Controller = (props: {
  timestamp: Date,
  relativeTimeFunc?: (args: {timestamp: Date, clock?: Function}) => string
}) => {
  const {
    timestamp,
    relativeTimeFunc,
  } = props;


  const relTime = !!relativeTimeFunc ? relativeTimeFunc: relativeTime;

  const displayText = relTime({timestamp});
  const rolloverText = timestamp.toString();

  return el(
    View,
    {
      displayText,
      rolloverText,
    },
  )
}


export {
  Controller,
}
