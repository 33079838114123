// @flow
import { createElement as el } from "react";
import { useMachine } from "@xstate/react";
import { createMachine } from "xstate";
import { View } from "./view.js";


const modeMachineSpec = {
  id: "ModeMachine",
  context: {},
  initial: "showButton",
  states: {
    showButton: {
      on: {
        SHOW_MODAL: {
          target: "showModal",
        },
      },
    },
    showModal: {
      on: {
        SHOW_BUTTON: {
          target: "showButton",
        },
      },
    },
  },
}

const modeMachine = createMachine(modeMachineSpec);

const Controller = ({
  onCreated,
}: {
  onCreated: Function,
}) => {
  const [ state, send ] = useMachine(modeMachine);

  const onShowButtonClick = e => {
    send({type: "SHOW_MODAL"})
  }

  const onCancel = e => {
    send({type: "SHOW_BUTTON"});
  }

  const onDone = paymentMethodLink => {
    console.log("Submitted data event: ", paymentMethodLink);
    onCreated(paymentMethodLink);
    send({type: "SHOW_BUTTON"})
  }

  return el(
    View,
    {
      state,
      onShowButtonClick,
      onCancel,
      onDone,
    },
  )
}



export {
  Controller,
}
