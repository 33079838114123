// @flow
import React from "react";
import { createElement as el } from "react";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { createMachine, assign } from "xstate";
import { useMachine } from "@xstate/react";
import { useToken } from "./../../../State/Token.js";
import { deletePaymentMethodLinkForOrganization } from "./../../../Api/MartletOrganization/Organization.js";


const Controller = ({
  onDone,
}: {
  onDone: Function,
}) => {
  const { organizationId } = useParams();
  const [ modalState, modalSend ] = useMachine(() => createMachine({
    id: "modalFSM",
    initial: "button",
    context: {
      organizationId,
    },
    states: {
      button: {
        on: {
          SHOW_MODAL: "modal",
        },
      },
      modal: {
        on: {
          SHOW_BUTTON: "button",
        },
      },
    }
  }));

  const handleDone = (e) => {
    modalSend({type: "SHOW_BUTTON"});
    onDone(e);
  }

  const handleStart = (e) => {
    modalSend({type: "SHOW_MODAL"});
  }

  const handleCancel = (e) => {
    modalSend({type: "SHOW_BUTTON"});
  }

  return el(
    View,
    {
      onDone: handleDone,
      onStart: handleStart,
      onCancel: handleCancel,
      modalState,
    },
  );
}

const View = ({
  onDone,
  onStart,
  onCancel,
  modalState,
}: {
  onDone: Function,
  onStart: Function,
  onCancel: Function,
  modalState: Function,
}) => {

  return modalState.value === "button"
  ? <Button onClick={onStart}>Unlink Payment Method</Button>
  : <ModalWidget
      onDone={onDone}
      onCancel={onCancel}
      organizationId={modalState.context.organizationId}
     />

}


const ModalWidget = ({
  onDone,
  onCancel,
  organizationId,
}: {
  onDone: Function,
  onCancel: Function,
  organizationId: string,
}) => {
  const token = useToken();
  const [ apiState, apiSend ] = useMachine(() => createMachine({
    id: "apiFSM",
    initial: "ready",
    context: {
      token,
      organizationId,
      errors: undefined,
    },
    states: {
      ready: {
        on: {
          SUBMIT: "working",
        },
      },
      working: {
        invoke: {
          src: (c, e) => deletePaymentMethodLinkForOrganization({
            token: c.token,
            organizationId: c.organizationId,
          }),
          onDone:{
            target: "success",
          },
          onError: {
            target: "failure",
            actions: assign({
              errors: (c, e) => e.data
            }),
          },
        }
      },
      success: {},
      failure: {},
    }
  }));

  const handleSubmit = (e) => {
    apiSend({type: "SUBMIT"});
  }

  const handleCancel = (e) => {
    onDone(e);
  }

  const handleDone = (e) => {
    onDone(e);
  }

  return el (
    ModalWidgetView,
    {
      apiState,
      onSubmit: handleSubmit,
      onCancel: handleCancel,
      onDone: handleDone,
    },
  )
}

const ModalWidgetView = ({
  onSubmit,
  onCancel,
  onDone,
  apiState,
}: {
  onSubmit: Function,
  onDone: Function,
  onCancel: Function,
  apiState: Function,
}) => {
  return <Modal show={true}>
    <Modal.Header>
    <Modal.Title>Delete Payment Method</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    { apiState.value === "ready"
      ? <Button type="submit" onClick={onSubmit}>Submit</Button>
      : apiState.value === "working"
      ? <p>Spinning ...</p>
      : apiState.value === "success"
      ? <Button onClick={onDone}>Dismiss</Button>
      : <p>There were errors</p>
    }

    </Modal.Body>
    <Modal.Footer>
    <Button onClick={onCancel}>Cancel</Button>
    </Modal.Footer>
    </Modal>
}



export {
  Controller,
}
