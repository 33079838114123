// @flow
import React from "react";
import * as S from "sanctuary";

import { type MessageContentType } from "./../entity.js";

const MessageContentTypeView = (props: {
  messageContentType: MessageContentType,
}) => {
  const { messageContentType } = props;

  return <>
    <h2>Message Content Type</h2>
    <hr/>
    <h3>Name</h3>
    <p>{messageContentType.name}</p>
    <hr/>
    <h3>Schema</h3>
    <p>{atob(messageContentType.b64Schema)}</p>
    <hr/>
    <h3>Visibility</h3>
    <ul>
    {S.map (v => <li key="messageContentType.id">{v}</li>) (messageContentType.visibility)}
    </ul>
    </>
}

export {
  MessageContentTypeView,
}
