// @flow

import Future, { chain } from "fluture";

import {
  ApiClient,
  OrganizationsApi,

} from "@bmbix/bmb_martlet_organization_client";

import { store as reduxStore, } from "../../State/Store.js";
import { deleteOauth2Client } from "./../store.js";

const delete_ =
  (token: string) =>
  (organizationId: string) =>
  (oauth2ClientId: string): Future<null> => {

  const apiInstance = new OrganizationsApi();
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;

  return Future((reject, resolve) => {

    apiInstance.deleteOauth2Client(organizationId, oauth2ClientId, (error, data, response) => {
      if (error) {
        console.log("error", error);
        reject(error);
      } else {
        const gw = data;
        resolve(oauth2ClientId);
      }
    });

    return console.log;
  });
}


const removeFromStore = (o: string) => {
  return Future((reject, resolve) => {
    try {
      reduxStore.dispatch(deleteOauth2Client(o))
      resolve(o)
    } catch (error) {
      reject(error)
    }
    return console.log;
  });
}


const deleteAndRemoveFromStore =
  (token:string) => (orgId: string) => (oauth2ClientId: string) =>
    chain (removeFromStore) (delete_ (token) (orgId) (oauth2ClientId))


export {
  delete_,
  deleteAndRemoveFromStore,
}

