// @flow
import { createElement as el, useEffect } from "react";
import { connect } from "react-redux";
import * as S from "sanctuary";
import log from "loglevel";

import {
  type MessageNote,
  type UserProfile,
} from "../../Entity/Types.js";
import { withMartletOrganizationApi } from "../../Api/MartletOrganization";
import {
  addMessageNotes,
  mergeUserProfiles,
} from "../../State";
import { type EnrichedMessageNote } from "./Entity.js";
import { View } from "./View.js";

const logger = log.getLogger("MessageNotes");

const createEnrichedMessageNote = messageNote => userProfile => {
  logger.debug("userProfile", userProfile);
  const enrichedMessageNote = Object.assign({}, messageNote, userProfile);
  logger.debug("enrichedMessageNote", enrichedMessageNote);
  return enrichedMessageNote;
}

const enrichMessageNotes = (messageNotes: Array<MessageNote>) => (userProfiles: Array<UserProfile>): Array<EnrichedMessageNote> => {
  const userProfileStrMap = S.fromPairs (
    S.map (
      userProfile => S.Pair (userProfile.userId) (userProfile)
    ) (userProfiles)
  );
  const enrichedMessageNotes = S.map (
    messageNote => createEnrichedMessageNote (messageNote) (
      S.fromMaybe ({}) (S.value (messageNote.userId) (userProfileStrMap))
    )
  ) (messageNotes);
  return enrichedMessageNotes;
}

const uniqUserId = (userIds: Array<string>, acc: Array<string>) => {
  if (userIds.length === 0) {
    return acc;
  } else {
    const [head, ...tail] = userIds;
    if (acc.includes(head)) {
      return uniqUserId(tail, acc)
    } else {
      return uniqUserId(tail, S.append (head) (acc))
    }
  }
}

function difference(setA, setB) {
    let _difference = new Set(setA)
    for (let elem of setB) {
        _difference.delete(elem)
    }
    return _difference
}

const missingUserProfiles = (notes: Array<MessageNote>) =>
  (profiles: Array<UserProfile>): Array<string> => {
    const nids = new Set(S.map (n => n.userId) (notes));
    const pids = new Set(S.map (p => p.userId) (profiles));
    return [...difference(nids, pids)];
  }

const Controller_ = (props: {
  addressId: string,
  messageId: string,
  martletOrganizationApi: Object,
  messageNotes: Array<MessageNote>,
  userProfiles: Array<UserProfile>,
  addMessageNotes: Function,
  mergeUserProfiles: Function,
}) => {

  const {
    martletOrganizationApi,
    addressId,
    messageId,
    messageNotes,
    userProfiles,
    mergeUserProfiles,
  } = props;

  const enrichedMessageNotes = enrichMessageNotes (messageNotes) (userProfiles);
  logger.debug("enrichedMessageNotes:", enrichedMessageNotes)

  useEffect(
    () => {
      console.log("REFRESH");
      const refresh = async () => {
        // ********************************* Dedupe messages in the store!!!!
        await martletOrganizationApi.getMessageNotes({
          addressId,
          messageId,
        });
        // const userIds = uniqUserId (S.map (S.prop ("userId")) (messageNotes), [])
      }
      refresh();
    },
    [addressId, messageId,],
  );

  useEffect(
    () => {
      console.log("PROFILES");
      const freshenUserProfiles = async () => {
        const userIds = missingUserProfiles(messageNotes)(userProfiles);
        if (userIds.length > 0){
          const newUserProfiles = await martletOrganizationApi.getUserProfiles({userIds})
          console.log("newUserProfiles:", newUserProfiles);
          if (newUserProfiles.length > 0) {
            mergeUserProfiles(newUserProfiles)
          }
        } else {
          console.log("nothing to do");
        }
      }
      freshenUserProfiles();
    },
    [messageNotes, userProfiles,]
  );

  return el(
    View,
    {
      messageNotes: enrichedMessageNotes,
      addressId,
      messageId,
    }
  )
}

const connector = connect(
  (state, ownProps) => {
    return {
      messageNotes: S.filter (n => n.messageId === ownProps.messageId) (state.messageNotes),
      userProfiles: state.userProfiles,
    }
  },
  {addMessageNotes, mergeUserProfiles}
)

const Controller = connector(withMartletOrganizationApi(Controller_));


export {
  Controller,
  enrichMessageNotes,
  uniqUserId,
}
