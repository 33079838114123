// @flow
import Future from "fluture";
import * as S from "sanctuary";

import {
  ApiClient,
  AddressesApi,
  OrganizationsApi,
  UsersApi,
} from "@bmbix/bmb_martlet_organization_client";

import { callBack } from "./../../Api/CallBack";

import {
  type PrivateKey ,
} from "./../entity";

import { createPrivateKeyFromGW } from "./adapt";


const create =
  (token: string) =>
  (resourceType: string) =>
  (resourceId: string): Future<Array<PrivateKey>> =>
{
  const allowedResourceTypes = [
    "organizations",
    "addresses",
    "users",
  ];
  if (!S.elem (resourceType) (allowedResourceTypes)) {
    throw new Error("resourceType was not one of allowed values")
  }

  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;

  const dataHandler = data => {
    const gw = data.private_key;
    const privateKey = createPrivateKeyFromGW(gw);
    return privateKey;
  }

  switch (resourceType) {
    case "organizations":
      return Future((reject, resolve) => {
        const organizationsApiInstance = new OrganizationsApi();
        organizationsApiInstance.createPrivateKey(
          resourceId,
          callBack(reject, resolve)(dataHandler),
        )
        return console.log;
      });
    case "users":
      return Future((reject, resolve) => {
        const usersApiInstance = new UsersApi();
        usersApiInstance.createPrivateKey(
          resourceId,
          callBack(reject, resolve)(dataHandler),
        )
        return console.log;
      });
    default:
      return Future((reject, resolve) => {
        const addressesApiInstance = new AddressesApi();
        addressesApiInstance.createPrivateKey(
          resourceId,
          callBack(reject, resolve)(dataHandler),
        )
        return console.log;
      });

  }

}

export {
  create,
}
