// @flow

import React from "react";
import * as S from "sanctuary";
import * as $ from "sanctuary-def";
import {
  type Acceptance,
} from "../Entity/Types";
import ReactTable from "react-table-6"
import "react-table-6/react-table.css"
import {
  withRouter,
  Link,
} from "react-router-dom";
import {
  NoteIcon,
  StopIcon,
} from "@primer/octicons-react";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BmbixDateTime } from "./../Widgets/";

const ExtractionItemWithHeaderTable_ = (props: {
  match: Object,
  items: Array<Acceptance>,
}) => {
    const {
      match,
      items,
    } = props;

    const copyToClipboard = str => {
      const el = document.createElement('textarea');
      el.value = str;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    };

    const columns = [
      {
        Header: "Type",
        accessor: "header.messageType",
      },
      {
        Header: "Ref",
        accessor: "item.reference",
      },
      {
        Header: "Requested",
        accessor: "header.date",
        Cell: ({value}) => !!value ? <BmbixDateTime timeStamp={value} />: null,
      },
      {
        Header: "Extracted from Platform",
        accessor: "item.extractedFromApTimestamp",
        Cell: ({value}) => !!value ? <BmbixDateTime timeStamp={value} />: null,
      },
      {
        Header: "Extraction Result",
        Cell: ({row}) => {

          const extractionSucceeded = (r) => {
            const result = S.fromMaybe (false) (S.get (S.is ($.Boolean)) ("extractedFromAp") (r._original.item));
            console.log("did extract: ", r, "result:", result);
            return result;
          }

          const showSuccessFullExtraction = (r) => {

            const isMartlet = (r) => S.fromMaybe (false) (S.get (S.is ($.Boolean)) ("messageIdFromMartletTimestamp") (r._original.item));

            const showMartlet = (r) => {
              const {
                organizationId,
                addressId,
              } = match.params;
              const messageId = r._original.item.messageIdFromMartlet;
              const pathname = `/u/organizations/${organizationId}/addresses/${addressId}/messages-status/${messageId}`;
              const tooltip = <Tooltip>{r._original.item.messageIdFromMartletTimestamp}</Tooltip>
              return (
                <OverlayTrigger overlay={tooltip} placement="bottom" delay={{show: 250, hide: 400}} ><Link to={pathname}><Button><NoteIcon /> Status</Button></Link></OverlayTrigger>
              )
            }

            const showKlondike = (r) => {
              const {
                organizationId,
                addressId,
              } = match.params;
              const claimToken = r._original.item.klondikeMessageToken;
              const unaddressedMessageId = r._original.item.klondikeUnaddressedMessageId;
              const pathname = `/u/organizations/${organizationId}/addresses/${addressId}/unaddressed-messages/${unaddressedMessageId}`;
              return (
                <>
                <Link to={pathname} style={{"font-family": "monospace"}}>{r._original.item.klondikeMessageToken}</Link>
                <Button onClick={e => copyToClipboard(claimToken)}>Copy</Button>
                </>
              )
            }

            return S.ifElse
            (isMartlet)
            (showMartlet)
            (showKlondike)
            (r)
          }

          const showFailedExtraction = (r) => {
            console.log("failed item", r);
            return <><StopIcon fill="#ff0000"/> {r._original.item.extractionNote}</>
          }

          return S.ifElse
          (extractionSucceeded)
          (showSuccessFullExtraction)
          (showFailedExtraction)
          (row)
        }
      },
  ]

  return (
    <ReactTable
      className="-striped -highlight"
      data={items}
      columns={columns}
      defaultPageSize={10}
      defaultSorted={[{id:"header.date", desc: true}]}
    />
  )
}

const ExtractionItemWithHeaderTable = withRouter(ExtractionItemWithHeaderTable_);

export {
  ExtractionItemWithHeaderTable,
}
