// @flow

import {
  type OrganizationProxy,
  type Oauth2Client,
} from "./../Entity/Types.js"

export type OrganizationProxyOauth2Client = {
  +organizationProxy: OrganizationProxy,
  +oauth2Client: Oauth2Client,
}

export const createOrganizationProxyOauth2Client =
  (args: {
    organizationProxy: OrganizationProxy,
    oauth2Client: Oauth2Client,
  }) => Object.freeze(args);
