// @flow
import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import { Controller as MessageTypesManager } from "./../../Widgets/MessageTypesManager/controller.js";


const View = (props: {
  address: string,
}) => {
  const {
    address,
  } = props;

  const [key, setKey] = useState("inbound");

  return (
    <>
    <Tabs activeKey={key} onSelect={k => setKey(k)} id="address-inbound-outbound">
    <Tab eventKey="inbound" title="Inbound">
    <MessageTypesManager direction="inbound" address={address} />
    </Tab>
    <Tab eventKey="outbound" title="Outbound">
    <MessageTypesManager direction="outbound" address={address} />
    </Tab>
    </Tabs>
    </>
  )
}

export { View }
