// Orphans
export const ADD_USER = "ADD_USER"
export const DEL_USER = "DEL_USER"
export const ADD_USER_AS_ROLE = "ADD_USER_AS_ROLE"
export const DEL_USER_AS_ROLE = "DEL_USER_AS_ROLE"
export const ADD_USER_ORG_ROLE = "ADD_USER_ORG_ROLE"
export const DEL_USER_ORG_ROLE = "DEL_USER_ORG_ROLE"
export const ADD_ACCOUNTING_SYSTEM_PLATFORM_ASSIGNMENT = "ADD_ACCOUNTING_SYSTEM_PLATFORM_ASSIGNMENT"
export const DEL_ACCOUNTING_SYSTEM_PLATFORM_ASSIGNMENT = "DEL_ACCOUNTING_SYSTEM_PLATFORM_ASSIGNMENT"

// OrganizationRoleAssignments
export const ACTION_BEGIN = "ACTION_BEGIN";
export const ACTION_SUCCESS = "ACTION_SUCCESS";
export const ACTION_ERROR = "ACTION_ERROR";
export const ORGANIZATION_ROLE_ASSIGNMENT_ADD= "ORGANIZATION_ROLE_ASSIGNMENT_ADD";
export const ORGANIZATION_ROLE_ASSIGNMENT_REMOVE= "ORGANIZATION_ROLE_ASSIGNMENT_REMOVE";

// AccountingSystemRoleAssignments
export const ACCOUNTING_SYSTEM_ROLE_ASSIGNMENTS_SELECT_BEGIN = "ACCOUNTING_SYSTEM_ROLE_ASSIGNMENTS_SELECT_BEGIN";
export const ACCOUNTING_SYSTEM_ROLE_ASSIGNMENTS_SELECT_SUCCESS = "ACCOUNTING_SYSTEM_ROLE_ASSIGNMENTS_SELECT_SUCCESS";
export const ACCOUNTING_SYSTEM_ROLE_ASSIGNMENTS_SELECT_ERROR = "ACCOUNTING_SYSTEM_ROLE_ASSIGNMENTS_SELECT_ERROR";
export const ACCOUNTING_SYSTEM_ROLE_ASSIGNMENTS_ADD = "ACCOUNTING_SYSTEM_ROLE_ASSIGNMENTS_ADD ";
export const ACCOUNTING_SYSTEM_ROLE_ASSIGNMENTS_DELETE = "ACCOUNTING_SYSTEM_ROLE_ASSIGNMENTS_DELETE ";



// Oauth
export const SET_OAUTH2_TOKEN = "SET_OAUTH2_TOKEN";
export const UNSET_OAUTH2_TOKEN = "UNSET_OAUTH2_TOKEN";

// Accounting Systems
export const SET_ACTIVE_AS = "SET_ACTIVE_AS";
export const ADD_AS = "ADD_AS";
export const DEL_AS = "DEL_AS";
export const UPDATE_AS = "UPDATE_AS";

// Notifications
export const ADD_NOTIF = "ADD_NOTIF"
export const DEL_NOTIF = "DEL_NOTIF"
