// @flow
import {createElement as el} from "react";
import {
  type Account,
  type BmbixAddress,
  type Organization,
  type Permission,
} from "../../../Entity/Types.js";

import {
  AddressView,
  View,
} from "./View.js";
import {
  buildDataItems,
  buildAddressDataItems
} from "./Entity.js";

const Controller = (props: {
  account: Account,
  organizations: Array<Organization>,
  permissions: Array<Permission>,
}) => {
  const {
    account,
    organizations,
    permissions,
  } = props;

  const data = buildDataItems(account)(permissions)(organizations);

  return el(
    View,
    {
      data,
    }
  );
}

const AddressController = (props: {
  account: Account,
  addresses: Array<BmbixAddress>,
  permissions: Array<Permission>,
  addressProfiles: Array<Function>,
}) => {
  const {
    account,
    addresses,
    permissions,
    addressProfiles,
  } = props;

  const data = buildAddressDataItems(account)(permissions)(addresses);

  return el(
    AddressView,
    {
      data,
      addressProfiles,
    }
  );
}


export {
  Controller,
  AddressController,
}
