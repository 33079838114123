// @flow
import {
  createElement as el,
  useEffect,
  useState,
}
from "react";
import log from "loglevel";
import {
  withRouter,
} from "react-router-dom";
import {
  connect,
} from "react-redux";
import * as S from "sanctuary";

import {
  type Platform,
} from "../../../Entity/Types.js";
import {
  withFreeagentV1AuthnApi,
} from "../Api/";
import {
  getPlatforms,
} from "./../../../Api/Isimud/Platforms.js";
import {
  useToken,
} from "./../../../State";
import {
  ConnectedView,
  NotConnectedView,
} from "./View.js";
import {
  INPUT,
  WAITING,
  SUCCESS,
} from "../../../Util";

const logger = log.getLogger("Freeagent");

const getPlatform = platformId => platforms => {
  const selector = S.compose (S.equals (platformId)) (S.prop ("platformId"))
  const maybePlatform = S.find (selector) (platforms)
  return S.maybeToNullable(maybePlatform);
}
/*


const stateChart = {
  id: "FreeAgent Platform Fetcher",
  context: {},
  initial: "idle",
  states: {
    idle: {
      on: {
        START: "working",
      }
    },
    working: {
      invoke: {
        id: "fetch platform",
        src: "fetchPlatforms",
        onDone: {
          actions: ["updateContext", send("INITIALIZED")]
        },
        onError: "failed",
      }
    },
    ready: {
      on: {
        REFRESH: "working",
      }
    },
    failed: {
      on: {
        REFRESH: "working",
      }
    },
  }
}

const config = {
  actions: {
    "updateContext": assign(
      (context, event) => {
        const platforms = event.data ;
        const platformId = context.platformId;
        const maybePlatform = getPlatform (platformId) (platforms);
        return { maybePlatform };
      }
    ),
  },

  services: {
    fetchPlatforms: (context, event) => getPlatforms({
      token: context.token,
      organizationId: context.organizationId,
    }),
  }
}

  // using the Control Panel
  const platformsConfig = {
    actions: {
      setConfiguration: assign({
        name: "platforms"
      }),
      sendUpdateToParent: sendParent(
        (context, event) => ({
          type: "UPDATE", fetcher: "platforms", ...context})
      ),
    },

    services: {
      fetch: (context, event) => getPlatforms({token, organizationId})
    },
  }
  const platformsMachine = createMachine(fetcherStateChart, platformsConfig);

  const connectionConfig = {
    actions: {
      setConfiguration: assign({
        name: "connection"
      }),
      sendUpdateToParent: sendParent(
        (context, event) => ({
          type: "UPDATE", fetcher: "connection", ...context})
      ),
    },

    services: {
      fetch: (context, event) => getConnection({
        token,
        organizationId,
        platformId,
      })
    },
  }
  const connectionMachine = createMachine(fetcherStateChart, connectionConfig);

  const startObj = {
    actions: [
      assign({platformsFetcher: () => spawn(platformsMachine)}),
      send({type: "START"}, {to: (context) => context.platformsFetcher}),
      assign({connectionFetcher: () => spawn(connectionMachine)}),
      send({type: "START"}, {to: (context) => context.connectionFetcher}),
    ],
    target: "warmingUp",
  }
  const chart = makeStateChart(controlPanelStateChart)(startObj);

  const controlPanelConfig = {
    guards: {
      fetchingIsComplete: (context, event, condMeta) => {
        const expected = S.sort (["platforms", "connection"]);
        const actual = S.sort (context.completions);
        const result = S.equals (actual) (expected);
        // console.log("expected:", expected, "actual:", actual, "Result:", result);
        return result;
      },
    },
  }
  const controlPanelMachine = createMachine(chart, controlPanelConfig);
  const [ state, sendEvent ] = useMachine(controlPanelMachine);

  useEffect(
    () => {
      sendEvent({type: "START"});
    },
    [sendEvent]
  );

*/


const Controller_ = (props: {
  match: Function,
  platform: Platform,
  freeagentV1AuthnApi: Object,
}) => {
  const {
    match,
    freeagentV1AuthnApi,
  } = props;
  const platformId = match.params.pid;
  const organizationId = match.params.organizationId;

  const [platform, setPlatform ] = useState();
  const [connection, setConnection] = useState();
  const [display, setDisplay] = useState(INPUT);
  const token = useToken();

  const refresh = async () => {
    setDisplay(WAITING);
    const platforms = await getPlatforms({
      token,
      organizationId,
    })
    const platform = getPlatform(platformId)(platforms)
    setPlatform(platform);
    const connection = await freeagentV1AuthnApi.select({
      organizationId,
      platformId,
    });
    if (!!connection) {
        setConnection(connection);
        setDisplay(SUCCESS);
    }
    else {
        setConnection(null);
        setDisplay(SUCCESS);
    }

  }

  useEffect(
    () => {refresh()},
    [],
  );

  const onConnect = () => {
    logger.debug("connection occurred");
    // refresh()
  }
  const onTest = () => {
    logger.debug("connection test occurred");
  }
  const onDisconnect = () => {
    logger.debug("connection disconnect occurred");
    refresh()
  }

  return (
    display !== "SUCCESS"
    ? <p>Working</p>
    : !!connection
    ? el(
      ConnectedView,
      {
        display,
        platform,
        connection,
        onDisconnect,
        onTest,
      },
    )
    : el(
      NotConnectedView,
      {
        display,
        platform,
        onConnect,
      },
    )
  )
}


const find = sequence => key => value => {
  const platform = S.find ( item => S.equals (S.prop (key) (item)) (value)) ( sequence);
  const platform_ = S.maybeToNullable (platform);
  logger.debug("platform_", platform_);
  return platform_;
}

const connector = connect(
  (state, ownProps) => {
    const platformId = ownProps.match.params.pid;
    logger.debug("platformId", platformId)
    return {
      platform: find (state.platforms) ("platformId") (platformId),
    }
  }
)

const Controller = withRouter(connector(withFreeagentV1AuthnApi(Controller_)));

export {
  find,
  Controller,
}
