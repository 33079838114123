// @flow
import React, { useState } from "react";
import Button from "react-bootstrap/Button";

import {
  UploadCertificateModal,
} from "../../Widgets/UploadCertificateModal";
import {
  type Organization,
} from "../../Entity/Types.js";

import RawViewer from "./../../Widgets/Certificate/RawViewer";
import Viewer from "./../../Widgets/Certificate/Viewer";

import {
  UploadOrganizationCertificateModal,
} from "./UploadOrganizationCertificateModal";




const SuccessView = (props: {
  organization: Organization,
  subjectName: string,
  certificateId: string,
  data: string,
}) => {
  const {
    organization,
    subjectName,
    data,
    certificateId,
  } = props;
  const[showModal, setShowModal] = useState(false);

  const modal = showModal ? (
    <UploadCertificateModal
      onHide={e => setShowModal(false)}
      organization={organization}
    />
  ): null;

  return (
    <>
    <h2>X509 Certificate</h2>
    <p>This certificate asserts the identity of this organization.</p>
    <Button onClick={e => setShowModal(true)}>Upload</Button>
    {modal}
    <Viewer pem={data} />
    <RawViewer
      data={data}
      subjectName={subjectName}
      certificateId={certificateId} />
    </>
  )
}

const FailureView = (props: {
  onResetAfterFailure: Function,
}) => {
  const {
    onResetAfterFailure
  } = props;

  return (
    <>
    <p>Ooops, looks like something went wrong</p>
    <p>Perhaps you have not uploaded your certificate?</p>
    <Button onClick={onResetAfterFailure}>Go to certficate upload</Button>
    </>
  )
}

const InputView = (props: {
  organization: Organization,
}) => {
  const {
    organization,
  } = props;
  const [showX509Modal, setShowX509Modal] = useState(false);

  const x509Modal = showX509Modal ? (
    <UploadOrganizationCertificateModal
      onHide={e => setShowX509Modal(false)}
      organization={organization}
    />
  ): null;

  return (
    <>
      <p>Upload your X509 user certificate</p>
      <Button onClick={e => setShowX509Modal(true)}>Upload</Button>
      {x509Modal}
    </>
  )
}
export {
  SuccessView,
  FailureView,
  InputView,
}
