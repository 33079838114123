// @flow
import React, {
  useEffect,
  useState,
} from "react";
import {
  withRouter,
} from "react-router-dom";
import * as S from "sanctuary";
import * as $ from "sanctuary-def";
import { SyncIcon } from "@primer/octicons-react";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";

import {
  withKlondikeApi,
} from "../Api/Klondike";
import {
  withMartletOrganizationApi,
} from "../Api/MartletOrganization";
import {
  UnaddressedMessagesTable,
} from "./UnaddressedMessagesTable.js";
import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "../Util";
import {
  unique,
  generateAliasMap,
} from "../Util/alias";
import {
  useAddressProfiles,
} from "./../State/AddressProfiles.js";
import {
  refreshAddressProfilesThunk,
} from "./../Thunks/address-profiles.js";
import { useToken } from "./../State";
import {
  Spinner,
} from "./../Widgets/Toast.js";

const UnadddressedMessagesView_ = (props: {
  match: Object,
  klondikeApi: Object,
  martletOrganizationApi: Object,
}) => {
  const {
    match,
    klondikeApi,
    martletOrganizationApi,
  } = props;
  const organizationId = match.params.organizationId
  const addressId = match.params.addressId
  const addressProfiles = S.values (useAddressProfiles());
  const dispatch = useDispatch();
  const token = useToken();
  const [ unaddressedMessages, setUnaddressedMessages ] = useState([]);
  const [ pointer, setPointer ] = useState();
  const [ totalRecordCount, setTotalRecordCount ] = useState(-1);
  const [ fetchedRecordCount, setFetchedRecordCount ] = useState(0);
  const [ display, setDisplay ] = useState(WAITING);
  const [ displayFetching, setFetchingDisplay ] = useState(INPUT);

  const aliasMap = generateAliasMap(addressProfiles);
  console.log("aliasMap:", aliasMap);
  const limit = 25;

  const aliasedMessages = S.map (
    um => {
      const alias = S.either (x => x) (toAddressId => S.fromMaybe ("No alias found") (S.value (toAddressId) (aliasMap))) (S.maybeToEither ("") (S.get (x => typeof x !== "undefined") ("toAddressId") (um)));
      const obj = um;
      return Object.freeze({obj, alias});
  })(unaddressedMessages);

  const fetchRecords = async (mode) => {
    // mode = initial, next, reset
    setFetchingDisplay("FETCHING");

    const unaddressedMessagesResponse = mode !== "next"
    ? await klondikeApi.getUnaddressedMessages({
      addressId,
      limit,
    })
    : await klondikeApi.getUnaddressedMessages({
      addressId,
      limit,
      pointer,
    });
    const unaddressedMessages_ =
      unaddressedMessagesResponse.unaddressedMessages;
    setPointer(
      S.maybeToNullable (
        S.map (S.prop ("systemId")) (S.last (unaddressedMessages_))));
    setTotalRecordCount(unaddressedMessagesResponse.selectionMetadata.count);
    switch (mode) {
      case "initial":
      case "next":
        setUnaddressedMessages(unaddressedMessages.concat(unaddressedMessages_));
        setFetchedRecordCount(prev => S.add (prev) (S.size(unaddressedMessages_)));
        break;
      case "reset":
        setUnaddressedMessages(unaddressedMessages_);
        setFetchedRecordCount(S.size(unaddressedMessages_));
        break;
      default:
        throw new Error("found a case we didn't like");
    }

    const thunk = refreshAddressProfilesThunk(token)(S.justs (S.map (S.get (S.is ($.String)) ("toAddressId"))(unaddressedMessages_)));
    dispatch(thunk);

    setFetchingDisplay("DONE");
    setDisplay(SUCCESS)
  }

  const isLastRecordSet = () => S.equals (fetchedRecordCount) (totalRecordCount);

  useEffect(
    () => {
      fetchRecords("initial")
    },
    [],
  )

  const waiting = display === WAITING ? (
    <p>Fetching unaddressed message records ...</p>
  ): null;

  const success = display === SUCCESS ? (
    <>
    <div>Showing {fetchedRecordCount} of {totalRecordCount} records found.</div>
    <UnaddressedMessagesTable
      organizationId={organizationId}
      addressId={addressId}
      aliasedMessages={aliasedMessages}
    />
    </>
  ): null;

  const failure = display === FAILURE ? <p>Something wrong</p>: null;

  return (
    <>
    <h2>Unaddressed Messages</h2>
     <Button className="mr-2" onClick={e => fetchRecords("reset")}><SyncIcon /> Refresh</Button>
    <>
     { displayFetching === "FETCHING" && <Spinner height="25"/> }
     { displayFetching !== "FETCHING" &&
     <Button disabled={isLastRecordSet()} onClick={e => fetchRecords("next")}>Load more</Button>
     }

    </>
    {waiting}
    {success}
    {failure}
    <div><br/></div>
    </>
  );
}

const UnaddressedMessagesView = withMartletOrganizationApi(withKlondikeApi(withRouter(UnadddressedMessagesView_)));

export {
  UnaddressedMessagesView,
}
