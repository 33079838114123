// @flow
import {
  createElement as el,
  useState,
  useEffect,
} from "react";
import log from "loglevel";

import {
  Controller as ECRInput,
} from "../ECRInput/Controller.js";
import {
  Controller as ECRWaiting,
} from "../ECRWaiting/Controller.js";
import {
  Controller as Failure,
} from "../Failure/Controller.js";
import {
  Controller as EIInput,
} from "../EIInput/Controller.js";
import {
  Controller as EIWaiting,
} from "../EIWaiting/Controller.js";
import {
  Controller as EISuccess,
} from "../EISuccess/Controller.js";
import {
  withIsimudApi,
} from "../../Api/Isimud";

import { useIdToken,} from "./../../State";
import {
  createWireMessageFromWS,
  getCredentialsPromise,
  buildWebSocket,
  sendKeepAliveMessage,
  handleCloseEvent,
  createExtractionCandidateReportRequestCompletedNotice,
} from "./../../Util/notifications"
const REGION = "eu-west-2"; //REGION
const IDENTITY_POOL_ID = "eu-west-2:22df95ae-bc6a-430e-b669-1fb6218d2965";

const logger = log.getLogger("Pull");

const INPUT = "INPUT";
const FAILURE = "FAILURE";
const WAITING_ECR = "WAITING_ECR";
const INPUT_EI = "INPUT_EI";
const WAITING_EI = "WAITING_EI";
const SUCCESS_EI = "SUCCESS_EI";


const Controller_ = (props: {
  addressId: string,
  organizationId: string,
  isimudApi: Object,
}) => {
  const {
    addressId,
    organizationId,
    isimudApi,
  } = props;

  const [display, setDisplay] = useState(INPUT);
  const [request, setRequest] = useState({});
  const [report, setReport] = useState();
  const [checkedRefs, setCheckedRefs] = useState([]);
  const [extraction, setExtraction] = useState();
  const idToken = useIdToken();

  useEffect(
    () => {

      const handleReportIdAvailable = (wsMessage) => {
        const wireMessage  = createWireMessageFromWS(wsMessage);
        const notice = createExtractionCandidateReportRequestCompletedNotice(wireMessage.noticeData);
        const reportId = notice.extractionCandidateReportId;
        isimudApi.getExtractionCandidateReport({
          addressId,
          organizationId,
          extractionCandidateReportId: reportId,
        }).then(
          report => {
            setReport(report);
            setDisplay(INPUT_EI);
          }
        ).catch(
          error => {
            setDisplay(FAILURE);
          }
        );
      }

      let webSocket = null;
      console.log("idToken:", idToken);
      const credentialsPromise = getCredentialsPromise(REGION)(IDENTITY_POOL_ID)(idToken)();
      credentialsPromise.then(
        credentials => {
          console.log("Here are credentials:", credentials);
          webSocket = buildWebSocket(credentials);
          webSocket.onopen = sendKeepAliveMessage;
          webSocket.onmessage = handleReportIdAvailable;
          webSocket.onclose = handleCloseEvent;
        }
      ).catch(
        e => {
          console.log("e:", e)
        }
      )

      return () => {
        webSocket.close();
      }
    },
    [idToken, addressId, organizationId, isimudApi],
  );

  const onRetry = () => {
  }

  const submitECR = (e) => {
    setDisplay(WAITING_ECR);
    setReport(null);
    isimudApi.requestExtractionCandidateReport({
      addressId,
      organizationId,
      fromDate: request.fromDate,
      toDate: request.toDate,
      contentMediaType: request.contentMediaType,
    }).then(
      reportId => {
        logger.debug("reportId:", reportId);
      }
    ).catch(
      error => {
        logger.debug(error);
        setDisplay(FAILURE);
      }
    );
  }


  const submitExtractionInstruction = () => {
    setDisplay(WAITING_EI);
    isimudApi.submitExtractionRequest({
      organizationId,
      addressId,
      messageType: report.header.contentMediaType,
      references: checkedRefs,
    }).then(
      extraction => {
        setExtraction(extraction);
        setDisplay(SUCCESS_EI);
        console.log(extraction);
      }
    ).catch(
      error => {
        setDisplay(FAILURE);
        console.log(error);
      }
    );
  }
  const onFromDateChange = (text) => {
    setRequest(prev => Object.assign({}, prev, {fromDate:text}));
  }
  const onToDateChange = (text) => {
    setRequest(prev => Object.assign({}, prev, {toDate:text}));
  }
  const onContentMediaTypeChange = (text) => {
    logger.debug("contentMediaType:", text);
    setRequest(prev => Object.assign({}, prev, {contentMediaType:text}));
  }

  const cancelECR = (e) => {
    setDisplay(INPUT)
  }

  const cancelEI = (e) => {
    setDisplay(INPUT_EI)
  }

  const onDone = () => {
    setDisplay(INPUT);
    // and reset a lot of state
  }

  return display === INPUT ? el(
    ECRInput,
    {
      onSubmit: submitECR,
      onFromDateChange: onFromDateChange,
      onToDateChange: onToDateChange,
      onContentMediaTypeChange: onContentMediaTypeChange,
    },
  ): display === WAITING_ECR ? el (
    ECRWaiting,
    {
      onCancel: cancelECR
    },
  ): display === FAILURE ? el(
    Failure,
    {
      onRetry,
    },
  ): display === INPUT_EI ? el(
    EIInput,
    {
      report,
      onSubmit: submitExtractionInstruction,
      onCancel: cancelECR,
      setCheckedRefs,
    },
  ): display === WAITING_EI ? el(
    EIWaiting,
    {
      onCancel: cancelEI,
    },
  ): display === SUCCESS_EI ? el(
    EISuccess,
    {
      onDone,
    },
  ): null;
}


const Controller = withIsimudApi(Controller_);

export {
  Controller,
  Controller_,
}
