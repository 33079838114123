// @flow
import { createElement as el } from "react";
import { createMachine } from "xstate";
import { useMachine } from "@xstate/react";
import { useAccount } from "./../../../State/Account.js";
import { useToken } from "./../../../State/Token.js";
import { deletePaymentMethod } from "./../../../Api/MartletOrganization/Users.js"
import type { PaymentMethod } from "./../../../Entity/Types.js";
import { View } from "./modal-view.js";

const Controller = ({
  onCancel,
  onDone,
  paymentMethod,
}: {
  onCancel: Function,
  onDone: Function,
  paymentMethod: PaymentMethod,
}) => {
  const account = useAccount();
  const token = useToken();

  const [ apiState, apiSend ] = useMachine(() => createMachine({
    id: "apiFSM",
    initial: "ready",
    context: {
      userId: account.id,
      token,
      paymentMethod,
    },
    states: {
      ready: {
        on: {
          SUBMIT: "working",
          CANCEL: {
            actions: (c, e) => onDone(),
          },
        }
      },
      working: {
        invoke: {
          src: (context, event) => {
            console.log("Invoking: ", context, event);
            return deletePaymentMethod({
              token: context.token,
              userId: context.userId,
              paymentMethodId: context.paymentMethod.id,
            });
          },
          onDone: "success",
          onError: "failure",
        },
      },
      success: {
        entry: (c, e) => {
          console.log("Success Helsinki: ", e);
          onDone();
        },
      },
      failure: {
        entry: (c, e) => {
          console.log("Failure Gothenburg: ", e);
          onDone();
        },
      },
    },
  }));

  const handleCancel = (e) => {
    apiSend({type: "CANCEL"})
  }
  const handleSubmit = (e) => {
    console.log("handleSubmit: ", e);
    apiSend({type: "SUBMIT"});
  }

  return el(
    View,
    {
      apiState,
      onCancel: handleCancel,
      onSubmit: handleSubmit,
    },
  )
}

export {
  Controller,
}
