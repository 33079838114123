// @flow
import React, {
  useState,
  useEffect,
} from "react"
import * as S from "sanctuary";
import * as $ from "sanctuary-def";
import {
  connect,
} from "react-redux";
import { useDispatch } from "react-redux";
import {
  withRouter,
} from "react-router";
import * as R from "ramda"
import Button from "react-bootstrap/Button";
import {
  SyncIcon,
} from "@primer/octicons-react";
import { useMachine } from "@xstate/react";
import { createMachine } from "xstate";

import type {
  BmbixAddress,
} from "../Entity/Types"
import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "../Util";

import {
  withMartletOrganizationApi,
} from "../Api/MartletOrganization";
import {
  ReceiptedAcceptanceTable,
} from "./ReceiptedAcceptanceTable.js";
import {
  Spinner,
} from "../Widgets/Toast";
import {
  useAddressProfiles,
} from "./../State/AddressProfiles.js";
import {
  refreshAddressProfilesThunk,
} from "./../Thunks/address-profiles.js";
import { useToken } from "./../State";
import {
  generateAlias,
} from "../Util/alias";


const waitingMachine = createMachine({
  id: "waitingMachine",
  initial: "waiting",
  states: {
    quiet: {
      on: {
        WAITING_HAPPENED: "waiting",
      }
    },
    waiting: {
      on: {
        SUCCESS_HAPPENED: "quiet",
        FAILURE_HAPPENED: "failure",
      }
    },
    failure: {
      on: {
        RESET_HAPPENED: "quiet"
      }
    },
  },
});

const lookup = (aliases, id) => {
  const address = R.find(R.propEq("addressId", id))(aliases)
  return !!address ? address : null;
}
const curriedLookup = R.curry(lookup)



const AcceptancesView_ = (props:{
  address: BmbixAddress,
  martletOrganizationApi: Object,
}) => {

  const {
    address,
    martletOrganizationApi,
  } = props;

  const [ sents, setSents ] = useState([]);
  const [ state, send ] = useMachine(waitingMachine);
  const addressProfiles = useAddressProfiles();
  const dispatch = useDispatch();
  const token = useToken();
  const [ acceptances, setAcceptances, ] = useState([]);
  const [ pointer, setPointer ] = useState();
  const [ totalRecordCount, setTotalRecordCount ] = useState(-1);
  const [ fetchedRecordCount, setFetchedRecordCount ] = useState(0);
  const [ display, setDisplay ] = useState(INPUT);
  const [ displayFetching, setFetchingDisplay ] = useState(INPUT);
  const limit = 25;
  const addressId = address.id;

  const isLastRecordSet = () => S.equals (fetchedRecordCount) (totalRecordCount);

  const updateAcceptance =
    addressProfiles =>
    acceptance =>
  {
    const addressId = S.prop ("toAddressId") (acceptance);
    const maybeAddressProfile = S.value (addressId) (addressProfiles);
    const alias = S.fromMaybe ({}) (maybeAddressProfile);
    const updatedAcceptance = Object.assign({}, acceptance, {alias});
    return updatedAcceptance;
  }

  const decoratedAcceptances = S.map (updateAcceptance(addressProfiles))
  (acceptances);
  console.log("decoratedAcceptances:", decoratedAcceptances);

  const fetchRecords = async (mode) => {
    // mode = initial, next, reset
    setFetchingDisplay("FETCHING");

    const acceptancesResponse = mode !== "next"
    ? await martletOrganizationApi.getAcceptances({
      addressId,
      limit,
    })
    : await martletOrganizationApi.getAcceptances({
      addressId,
      limit,
      pointer,
    });

    const acceptances_ = acceptancesResponse.acceptances;

    setPointer(
      S.maybeToNullable (
        S.map (S.prop ("systemId")) (S.last (acceptances_))));
    setTotalRecordCount(acceptancesResponse.selectionMetadata.count);

    switch (mode) {
      case "initial":
      case "next":
        setAcceptances(prev => prev.concat(acceptances_));
        setFetchedRecordCount(prev => S.add (prev) (S.size(acceptances_)));
        break;
      case "reset":
        setAcceptances(acceptances_);
        setFetchedRecordCount(S.size(acceptances_));
        break;
      default:
        throw new Error("found a case we didn't like");
    }

    const thunk = refreshAddressProfilesThunk(token)(S.justs (S.map (S.get (S.is ($.String)) ("toAddressId"))(acceptances_)));
    dispatch(thunk);

    setDisplay(SUCCESS);
    send({type: "SUCCESS_HAPPENED"});
    setFetchingDisplay("DONE");
  }

  useEffect(() => { fetchRecords("initial"); }, [])  // only run on page load


  return state.value === "waiting"
  ?  <Spinner />
  : state.value === "failure"
  ? <p>Error fetching accepted messages.</p>
  : state.value === "quiet"
  ? <>
      <h2>Accepted for delivery</h2>
      <Button className="mr-2" onClick={e => fetchRecords("reset")}><SyncIcon /> Refresh</Button>
    <>
     { displayFetching === "FETCHING" && <Spinner height="25"/> }
     { displayFetching !== "FETCHING" &&
     <Button disabled={isLastRecordSet()} onClick={e => fetchRecords("next")}>Load more</Button>
     }

    </>
    <div>Showing {fetchedRecordCount} of {totalRecordCount} records found.</div>
      <ReceiptedAcceptanceTable items={decoratedAcceptances} address={address}/>
    </>
  : null;
}


const connector = connect(
  (state, ownProps) => {
    const {
      match,
    } = ownProps;
    const addressId = match.params.addressId
    return {
      address: R.find(R.propEq("id", addressId))(state.addresses),
    }
  }
);

const AcceptancesView = withRouter(connector(withMartletOrganizationApi(AcceptancesView_)));

export {
  AcceptancesView,
  curriedLookup,
}


