import React from "react";


const ExtractionView = () => {
  return (
    <p>here is the extract view.</p>
  )
}


export {
  ExtractionView,
}

