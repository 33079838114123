// @flow
import Future from "fluture";
import * as S from "sanctuary";

import {
  ApiClient,
  AddressesApi,
} from "@bmbix/bmb_martlet_organization_client";
import { gwToAccessListEntry } from "./adapt.js";

const getEntries = (token: string) => (owner: string) => (list: string): Future => {

  const apiInstance = new AddressesApi();
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;

  return Future((reject, resolve) => {
    console.log("token", token);
    apiInstance.listAccessListEntries(owner, list, (error, data, response) => {
      if ( error ) {
        console.log("error", error);
        reject(error);
      } else {
        const gws = data.access_list_entries;
        const accessListEntries = S.map (gwToAccessListEntry) (gws);
        resolve(accessListEntries);
      }
    });
    return console.log
  });
}

export { getEntries }
