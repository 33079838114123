// @flow
import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import {
  Input,
  Waiting,
  Success,
  Failure,
} from "../DisplayWrappers.js";

import { type WebHook } from "./Entity.js";


const View = (props: {
  webHook: WebHook;
  display: string,
  onChange: Function,
  onSubmit: Function,
  onCancel: Function,
  onSuccessComplete: Function,
  onFailureComplete: Function,
}) => {
  const {
    webHook,
    display,
    onChange,
    onSubmit,
    onCancel,
    onSuccessComplete,
    onFailureComplete,
  } = props;

  return (
    <Modal show={true}>
    <Modal.Header>
    <Modal.Title>Update Web Hook</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <Input display={display}>
    <Form>

    <Form.Group>
    <Form.Label>Update URL</Form.Label>
    <Form.Control
      onChange={onChange}
      name="URL"
      value={webHook.URL}
    />
    <Form.Text>Supply an accessible URL</Form.Text>
    </Form.Group>

    <Form.Group>
    <Form.Label>Update token</Form.Label>
    <Form.Control
      onChange={onChange}
      name="token"
      value={webHook.token}
    />
    <Form.Text>Supply a secret token</Form.Text>
    </Form.Group>

    </Form>
    </Input>
    <Waiting display={display}>Waiting ...</Waiting>
    <Success display={display}>Success!</Success>
    <Failure display={display}>Oops! something went wrong</Failure>
    </Modal.Body>
    <Modal.Footer>
      <Input display={display}>
        <Button onClick={onSubmit}>Submit</Button>
        <Button onClick={onCancel}>Cancel</Button>
      </Input>
      <Waiting display={display}>
        <Button onClick={onCancel}>Cancel</Button>
      </Waiting>
      <Success display={display}>
        <Button onClick={onSuccessComplete}>Done (Success)</Button>
      </Success>
      <Failure display={display}>
        <Button onClick={onFailureComplete}>Done (Failure)</Button>
      </Failure>
    </Modal.Footer>
    </Modal>
  );
}

const InputView = (props: {
  onSubmit: Function,
  onCancel: Function,
  onChangeToken: Function,
  onChangeURL: Function,
  token: string,
  url: string,
}) => {
  const {
    onSubmit,
    onCancel,
  } = props;
  return (
    <>
    <Form>

    </Form>
    <Button onClick={onSubmit}>Save</Button>
    <Button onClick={onCancel}>Cancel</Button>
    </>
  );
}

const SuccessView = (props: {
  message: string,
  onDismiss: Function
}) => {
  const {
    message,
    onDismiss,
  } = props;

  return (
    <>
    <p>{message}</p>
    <Button onClick={onDismiss}>Dismiss</Button>
    </>
  );
}

const WaitingView = (props: {
}) => {
  return (
    <p>waiting</p>
  );
}

const FailureView = (props: {
  message: string,
  onDismiss: Function
}) => {
  const {
    message,
    onDismiss,
  } = props;
  return (
    <>
    <p>{message}</p>
    <Button onClick={onDismiss}>Dismiss</Button>
    </>
  );
}



export {
  View,
  InputView,
  SuccessView,
  WaitingView,
  FailureView,
}
