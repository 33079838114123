// @flow

import React, {
  createElement as el,
  useEffect,
  useState
} from "react";
import log from "loglevel";
import { fork } from "fluture";

import { useParams } from "react-router-dom";

import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "./../../../Util";
import { useToken } from "./../../../State";
import { getResourcePublicKey } from "./../../Api/get_resource_public_key";
import { View } from "./view.js";
import { useCatchExpiredToken } from "./../../../State/Token.js";

const logger = log.getLogger("Profile");

const Controller = (props: {
}) => {

  const catchExpiredToken = useCatchExpiredToken();
  const token = useToken();
  const { organizationId } = useParams();
  logger.debug("organizationId:", organizationId);

  const [ publicKeyData, setPublicKeyData] = useState();
  const [ display, setDisplay ] = useState(INPUT);

  useEffect(
    () => {
      setDisplay(WAITING);
      const action = getResourcePublicKey(token)("organizations")(organizationId);

      fork(error => {
        console.log("error:", error);
        catchExpiredToken(error);
        setDisplay(FAILURE);
      })(publicKey_ => {
        setPublicKeyData(publicKey_.data);
        setDisplay(SUCCESS);
      })(action);

    },
    [],
  );

  return el(
    View,
    {
      display,
      publicKeyData,
    }
  );


};

export {
  Controller,
}
