// @flow
import React, { Component } from 'react';
import Button from "react-bootstrap/Button";
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import * as R from "ramda";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import log from "loglevel";
import {
  PlusIcon,
  XCircleIcon,
  CheckCircleIcon,
} from "@primer/octicons-react";

import type {
  Organization,
} from "../Entity/Types"
import OrganizationsTable from "./Table2.js";
import { AddModal } from "./AddModal";
import { CloseButton } from "./CloseButton";
import { OpenButton } from "./OpenButton";

const logger = log.getLogger(":Organization:OrganizationsView");

function ClosedActionsBar(props:{
  organizationIds: Array<string>,
}) {
  return (
    <>
      <OpenButton
        label="Open"
        organizationIds={props.organizationIds}
      />
    </>
  )
}

function ActionsBar(props:{
  organizationIds: Array<string>,
}) {
  return (
    <>
    <CloseButton
      label = "Close"
      organizationIds={props.organizationIds}
    />
    </>
  )
}

export const makeTableOrgs = (orgs:Array<Organization>, checkedOrgs:Array<string>) => {
  const tableOrgs = [];
  for (let i=0 ; i<orgs.length; i++){
    const org = orgs[i];
    const checked = R.contains(org.id, checkedOrgs);
    tableOrgs.push(Object.freeze({
      id: org.id,
      name: org.name,
      checked: checked,
    }));
  }
  return tableOrgs;
}

type Props = {
  orgs: Array<Organization>,
}
type State = {
  orgs: Array<Organization>,
  name: string|null,
  orgId: string|null,
  showAddModal: boolean,
  selectedOrgRefs: Array<string>,
  selectedClosedOrgRefs: Array<string>,
}
class View_ extends Component<Props, State> {
  constructor (props:Props) {
    logger.debug("[OrganizationsView] props:", props);
    super(props)
    this.state = {
      orgId: null,
      name: null,
      orgs: [],
      showAddModal: false,
      selectedOrgRefs: [],
      selectedClosedOrgRefs: [],
    }
    this.handleShowAddModal = this.handleShowAddModal.bind(this)
    this.handleHideAddModal = this.handleHideAddModal.bind(this)
  }


  handleShowAddModal = () => {
    this.setState({showAddModal: true})
  }

  handleHideAddModal = () => {
    this.setState({showAddModal: false})
  }

  toggleSelectHandler = (event:any) => {
    const id = event.target.id;
    const checked = event.target.checked;
    const s = new Set(this.state.selectedOrgRefs);
    if (checked) {
      s.add(id);
    }
    else {
      s.delete(id);
    }
    this.setState({
      selectedOrgRefs: Array.from(s),
    });
  }

  toggleSelectClosedHandler = (event:any) => {
    const id = event.target.id;
    const checked = event.target.checked;
    const s = new Set(this.state.selectedClosedOrgRefs);
    if (checked) {
      s.add(id);
    }
    else {
      s.delete(id);
    }
    this.setState({
      selectedClosedOrgRefs: Array.from(s),
    });
  }

  render () {
    return (
      <div>
      <ButtonToolbar
        className="float-right"
      >
      <Button onClick={this.handleShowAddModal}><PlusIcon/> Add</Button>
      {this.state.showAddModal &&
        <AddModal
          onComplete={this.handleHideAddModal}
          onCancel={this.handleHideAddModal}
        />
      }
      </ButtonToolbar>
      <h2>Organizations</h2>
      <p>Displaying those organizations where you have Organization Manager role.</p>
      <Tabs defaultActiveKey="open">
      <Tab eventKey="open" title={<><CheckCircleIcon/> Open</>}>
      <ActionsBar
        organizationIds={this.state.selectedOrgRefs}
      />
      <OrganizationsTable
        orgs={R.filter(R.propEq("isClosed", false), this.props.orgs)}
        toggleSelectHandler={this.toggleSelectHandler}
        checkedItems={this.state.selectedOrgRefs}
      />
      </Tab>
      <Tab eventKey="closed" title={<><XCircleIcon /> Closed</>}>
      <ClosedActionsBar
        organizationIds={this.state.selectedClosedOrgRefs}
      />
      <OrganizationsTable
        orgs={R.filter(R.propEq("isClosed", true), this.props.orgs)}
        toggleSelectHandler={this.toggleSelectClosedHandler}
        checkedItems={this.state.selectedClosedOrgRefs}
      />
      </Tab>
      </Tabs>
      </div>
    )}
}

const connector = connect(
  state => {
    return {
      orgs: state.orgs,
    }
  }
);

const OrganizationsView = connector(withRouter(View_));

export {
  OrganizationsView,
};
