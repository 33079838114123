// @flow
import {createElement as el,  useEffect, useState} from 'react';
import { connect } from 'react-redux'
import log from "loglevel";
import "@psyycker/react-translation";
import { registerTranslations } from "@psyycker/react-translation";
import frenchFR from "./../Translations/french-fr.json";
import chineseCN from "./../Translations/chinese-CN.json";
import russianRU from "./../Translations/russian-RU.json";

import {
  addOrg,
  addAddress,
  addAccessKey,
  addPermission,
  addPlatform,
  addPlatformAssignment,
  setSettings,
} from "./../State";
import { withMartletOrganizationApi, } from "./../Api/MartletOrganization/Controller.js";
import { withIsimudApi, } from "./../Api/Isimud";
import {
  syncToServer,
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "./../Util";
//  import './App.css';
import { MainPresenter } from "./../AppView.js";
import {
  useCatchExpiredToken,
} from "./../State/Token.js";
import { useAccount } from "./../State/Account.js";

registerTranslations({
  "fr-FR": frenchFR,
  "zh-CN": chineseCN,
  "ru-RU": russianRU,
});

log.setDefaultLevel(log.levels.DEBUG);
log.getLogger("Util").setLevel(log.levels.DEBUG, false);
log.getLogger("Util.notifications").setLevel(log.levels.DEBUG, false);
const logger = log.getLogger("App");

const Controller_ = (props: {
  token: string,
  addAccessKey: Function,
  addAddress: Function,
  addOrg: Function,
  addPermission: Function,
  addPlatform: Function,
  addPlatformAssignment: Function,
  setSettings: Function,
  martletOrganizationApi: Object,
  isimudApi: Object,
}) => {
  const account = useAccount();
  const {
    token,
    addAccessKey,
    addAddress,
    addOrg,
    addPermission,
    addPlatform,
    addPlatformAssignment,
    setSettings,
    martletOrganizationApi,
    isimudApi,
  } = props;
  logger.debug("Site token: ", token);
  const [display, setDisplay] = useState(INPUT);
  const catchExpiredToken = useCatchExpiredToken();

  useEffect(
    () => {
      setDisplay(WAITING);
      syncToServer({
        token,
        userId: account.id,
        catchExpiredToken,
        addOrg,
        addAddress,
        addAccessKey,
        addPermission,
        addPlatform,
        addPlatformAssignment,
        setSettings,
        isimudApi,
        martletOrganizationApi,
      }).then(
        (values) => {
          setDisplay(SUCCESS);
        }
      ).catch(
        error => {
          // logger.error(Object.keys(error));
          logger.error(error.status);
          logger.error(error.crossDomain);
          logger.error(error.method);
          logger.error(error.url);
          setDisplay(FAILURE);
        }
      )
    },
    [addOrg, addAddress, addAccessKey, addPermission, addPlatform,
      addPlatformAssignment, isimudApi,martletOrganizationApi,
      setSettings ],
  );

  return el(
    MainPresenter,
    {
      display,
    }
  )
}

const ctr = connect(
  state => {
    return {
      token: state.token,
    }
  },
  {
    addOrg,
    addAddress,
    addAccessKey,
    addPermission,
    addPlatform,
    addPlatformAssignment,
    setSettings,
  },
);
const Controller = withIsimudApi(
  withMartletOrganizationApi(
      ctr(
        Controller_)));

export {
  Controller,
}

