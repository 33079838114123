// @flow
import React from "react";

import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";



const View = (props: {
  buttonText: string,
  infoTitle: string,
  infoContent: Function,
}) => {
  const {
    buttonText,
    infoTitle,
    infoContent,
  } = props;



  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">{infoTitle}</Popover.Title>
      <Popover.Content>
        {infoContent}
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" placement="right" overlay={popover}>
      <Button variant="link">{buttonText}</Button>
    </OverlayTrigger>
  );

}

export {
  View,
}
