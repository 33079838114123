// @flow

import {
  createElement as el,
  useState,
} from "react";
import {
  connect,
} from "react-redux";
import log from "loglevel";

import {
  type Settings,
} from "../../../Entity/Types.js";
import { setSettings } from "../../../State";
import { View } from "./View.js";
import { setLanguage } from "./../../../Api/MartletOrganization/Users.js";
import { useAccount } from "./../../../State/Account.js";
import { useToken } from "./../../../State/Token.js";

import {
  createMachine,
} from "xstate";
import {
  useMachine,
} from "@xstate/react";

const logger = log.getLogger("Account");

const machineSpecification = {
  id: "setLanguage",
  initial: "idle",
  context: {},
  states: {
    idle: {
      on: {
        START: "working",
      }
    },
    working: {
      on: {
        SUCCESS: "succeeded",
        FAILURE: "failed",
      }
    },
    succeeded: {
      on: {
        RESET: "idle",
      },
    },
    failed: {
      on: {
        RESET: "idle",
      },
    },
  },
};

const stateMachine = createMachine(machineSpecification);

const Controller_ = (props: {
  onHide: Function,
  settings: Settings,
  setSettings: Function,
}) => {
  const {
    onHide,
    settings,
    setSettings,
  } = props;
  const [ state, send ] = useMachine(stateMachine);
  const [active, setActive] = useState(true);
  const token = useToken();
  const account = useAccount();
  const userId = account.id;

  const onSubmit = data => {
    send("START");
    setLanguage({
      token,
      userId,
      language: data.language,
    }).then(
      updatedSettings => {
        send("SUCCESS");
        logger.debug("settings:", settings);
        logger.debug("updatedSettings:", updatedSettings);
        const mergedSettings = Object.assign({}, settings, {language:
          data.language});
        logger.debug("mergedSettings:", mergedSettings);
        setSettings(mergedSettings);
      }
    ).catch(
      error => {
        send("FAILURE");
        logger.error("SetLanguageModal:", error);
      }
    );
  }

  const onClose = e => {
    send("RESET");
    setActive(false);
    onHide();
  }

  // return <p>Hello World</p>

  return active ? el(
      View,
      {
        state: state.value,
        language: settings.language,
        onSubmit,
        onClose,
      }
  ): null;
}

const connector = connect(
  null,
  {
    setSettings,
  },
)

const Controller = connector(Controller_);

export {
  Controller,
  Controller_,
}

