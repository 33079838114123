// @flow

import React from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import {
  Input,
  Waiting,
  Success,
  Failure,
} from "../../Widgets/DisplayWrappers.js";


const View = (props: {
  display: string,
  userId: ?string,
  onUserIdChange: Function,
  onSubmit: Function,
  onCancel: Function,
  onSuccessComplete: Function,
  onFailureComplete: Function,
}) => {
  const {
    display,
    userId,
    onUserIdChange,
    onSubmit,
    onCancel,
    onSuccessComplete,
    onFailureComplete,
  } = props;

  return (
    <Modal show={true}>
    <Modal.Header>Add User</Modal.Header>
    <Modal.Body>
    <Input display={display}>
      <Form>
      <Form.Group>
      <Form.Label>Account Name</Form.Label>
      <Form.Control
        type="text"
        name="userId"
        onChange={e => onUserIdChange(e.target.value)}
        placeholder="Enter new user id"
        value={userId}
      />
      </Form.Group>
      </Form>
    </Input>
    <Waiting display={display}>
      <p>Waiting ...</p>
    </Waiting>
    <Success display={display}>
      <p>Success!</p>
    </Success>
    <Failure display={display}>
      <p>Oops! something went wrong ...</p>
    </Failure>
    </Modal.Body>
    <Modal.Footer>
    <Input display={display}>
      <Button onClick={onCancel}>Cancel</Button>
      <Button key="def" onClick={onSubmit}>Submit</Button>
    </Input>
    <Waiting display={display}>
      <Button onClick={onCancel}>Cancel</Button>
    </Waiting>
    <Failure display={display}>
      <Button onClick={onFailureComplete}>Done (Failure)</Button>
    </Failure>
    <Success display={display}>
      <Button onClick={onSuccessComplete}>Done (Success)</Button>
    </Success>
    </Modal.Footer>
    </Modal>
  );
}

export {
  View,
};
