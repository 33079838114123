// @flow
import { createElement as el } from "react";

import {
  type UserProfile,
  type OrganizationProfile,
} from "./../../../Entity/Types.js";
import { View } from "./view.js";

const Controller = (props: {
  userProfiles: Array<UserProfile>,
  organizationProfiles: Array<OrganizationProfile>,
}) => {
  const {
    userProfiles,
    organizationProfiles,
  } = props;
  console.log("akron", userProfiles);
  console.log("ohio", organizationProfiles);

  return el(
    View,
    {
      userProfiles,
      organizationProfiles,
    }
  )
}

export { Controller };
