// @flow
import React from "react";

import {
  type Account,
} from "../../../Entity/Types.js";

import { Controller as EncryptionApp } from "./../../../EncryptionApp/controller";

const View = (props: {
  account: Account,
}) => {
  const {
    account,
  } = props;

  return (
    <>
      <h2>Privacy</h2>
      <hr />
      <EncryptionApp resourceType="users" resourceId={account.id}/>
    </>
  );
}

export {
  View,
}
