// @flow
import React from "react";
import Form from "react-bootstrap/Form";

import {BModal, BSuccess, BFailure} from "../../../../Widgets/BModal";

function EntryFormRemove (props: {
  onChange: Function,
  data: Function,
}) {
  const {
    onChange,
    data,
  } = props;

  return (
    <Form>
      <Form.Group>
      <Form.Check
        type="checkbox"
        label={`Confirm entry ${data} deletion`}
        onChange={onChange}
        name="confirmed"
        value="confirmed"
      />
      <Form.Text>Confirm list deletion</Form.Text>
      </Form.Group>
    </Form>
  );
}

function View (props: {
  display: string,
  onSubmit: Function,
  onDoneSuccess: Function,
  onDoneFailure: Function,
  onCancel: Function,
  onChange: Function,
  data: Function,
}) {

  const {
    display,
    onCancel,
    onSubmit,
    onDoneSuccess,
    onDoneFailure,
    onChange,
    data,
  } = props;
  console.log("propaprapsafsd", props);

  return (
    <BModal title={"My Title"} display={display} onSubmit={onSubmit} onCancel={onCancel}
  onDoneSuccess={onDoneSuccess} onDoneFailure={onDoneFailure}>
      <EntryFormRemove onChange={onChange} data={data} />
      <BSuccess>Some success data</BSuccess>
      <BFailure>Some failure message</BFailure>
    </BModal>
  );

}

export { View as default };
