// @flow
import React from "react";
import Button from "react-bootstrap/Button";
import {
  Row,
  Col,
} from 'react-bootstrap'
import { Link } from "react-router-dom";

import {
  type UserProfile,
} from "./../../../Entity/Types.js";
import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "./../../../Util";
import {
  copyToClipboard,
} from "./../../../Util/copy.js";
import {
  BmbixQR,
} from "./../../../Widgets/BmbixQR";
import {
  UserProfileWidget,
} from "./../../../Widgets/UserProfile";

const View = (props: {
  profile?: ?UserProfile,
  display: String,
}) => {
  const {
    profile,
    display,
  } = props;

  const certificatePath = !!profile ? `./${profile.userId}/certificate`:"";
  const publicKeyPath = !!profile ? `./${profile.userId}/public_key`:"";

  const input = display === INPUT ? (
    <p>Ready to fetch profile</p>
  ): null;

  const waiting = display === WAITING ? (
    <p>Working ...</p>
  ): null;


  const success = display === SUCCESS && !!profile ? (
    <Row>
    <Col>
    <h2>QR Code</h2>
    <BmbixQR
      uri={`https://client.bmbix.com/profiles/user/${profile.userId}`}
      title="user"
    />
    </Col>
    <Col>
    <h2>Name</h2>
    <UserProfileWidget userProfile={profile}/>
    <hr/>
    <h2>Bmbix User Id</h2>
    <p>{profile.userId}</p>
    <Button onClick={e => copyToClipboard(profile.userId)}>Copy</Button>
    <hr/>
    </Col>
    <Col>
    <h2>Public Key</h2>
    <Link to={publicKeyPath}>public key</Link>
    <hr/>
    <h2>Identity Validation Certificate</h2>
    <Link to={certificatePath}>certificate</Link>
    <hr/>
    </Col>
    </Row>
  ): null;

  const failure = display === FAILURE ? (
    <p>Ooops!, something went wrong</p>
  ): null;

  return (
    <>
    <h1>User Profile</h1>
    <hr/>
    {input}
    {waiting}
    {success}
    {failure}
    </>
  );
}

export {
  View,
}
