// @flow
import {
  createElement as el,
  useState,
} from "react";
import log from "loglevel";

import {
  type Organization,
} from "../../Entity/Types.js";
import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "../../Util";
import {
  View,
} from "./View.js";
import { useToken } from "./../../State";
import { setOrganizationCertificate } from "./../../Api/MartletOrganization/Certificates";


const logger = log.getLogger("UploadCertificateModal");

const Controller = (props: {
  onHide: Function,
  organization: Organization,
}) => {
  const {
    onHide,
    organization,
    martletOrganizationApi,
  } = props;
  const organizationId = organization.id;
  const [display, setDisplay] = useState(INPUT);
  const [file, setFile] = useState();
  const token = useToken();

  const onFileChange = file => setFile(file);

  const onSubmit = e => {
    if (!!file) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const data_ = fileReader.result;
        logger.debug("data_: ", data_);
        if (!!data_) {
          setDisplay(WAITING);
          setOrganizationCertificate({
            token,
            organizationId,
            data: Buffer.from(data_).toString("base64"),
            mediaType: file.type,
          }).then(
            null_ => {
              setDisplay(SUCCESS);
              logger.debug("Set certificate: ", null_);
            }
          ).catch(
            error => {
              setDisplay(FAILURE);
              logger.debug("Failure when storing certificate");
            }
          );
        } else {
          logger.debug("No data from file to send");
        }
      }
      fileReader.readAsArrayBuffer(file);
    }
    logger.debug("No file to send");
  }

  const onCancel = e => {
    setDisplay(INPUT);
    onHide();
  }
  const onSuccessComplete = onCancel;
  const onFailureComplete = onCancel;

  return el(
    View,
    {
      display,
      onFileChange,
      onSubmit,
      onCancel,
      onSuccessComplete,
      onFailureComplete,
    }
  );
}


export {
  Controller,
}
