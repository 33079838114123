// @flow
import * as S from "sanctuary";

import {
  type Account,
  type BmbixAddress,
  type Permission,
  type Organization,
} from "../../../Entity/Types.js";

export type OrganizationUser = {
  +organization: Organization,
  +permission: Permission,
}

export type AddressUser = {
  +address: BmbixAddress,
  +permission: Permission,
}

const createOrganizationUser = (props: {
  organization: Organization,
  permission: Permission,
}): OrganizationUser => {
  const {
    organization,
    permission,
  } = props;
  return Object.freeze({
    organization,
    permission,
  })
}

const createAddressUser = (props: {
  address: BmbixAddress,
  permission: Permission,
}): AddressUser => {
  const {
    address,
    permission,
  } = props;
  return Object.freeze({
    address,
    permission,
  })
}





const permissionBelongs = ac => pn => `bmbix://user/${ac.sub}` === pn.actor;
const orgIdsMatch = pn => og => `bmbix://organization/${og.id}` === pn.resource;
const mergePermWithOrg = f => p => o => f(p)(o) ?
  S.Just(createOrganizationUser({permission: p, organization: o})):
  S.Nothing

/*
const result = S.justs (
  S.ap (
    S.map (
      mergePermWithOrg(orgIdsMatch)
    ) (permissions)
  ) (organizations)
)
*/

const buildDataItems = (account:Account) => (permissions: Array<Permission>) => (organizations: Array<Organization>) => S.pipe ([
  S.filter (permissionBelongs (account)),
  S.map (mergePermWithOrg(orgIdsMatch)),
  S.flip (S.ap) (organizations),
  S.justs,
]) (permissions)


const addressIdsMatch = pn => ad => `bmbix://address/${ad.id}` === pn.resource;
const mergePermWithAddress = f => p => a => f(p)(a) ?
  S.Just(createAddressUser({permission: p, address: a})):
  S.Nothing

const buildAddressDataItems = (account:Account) => (permissions: Array<Permission>) => (addresses: Array<BmbixAddress>) => S.pipe ([
  S.filter (permissionBelongs (account)),
  S.map (mergePermWithAddress(addressIdsMatch)),
  S.flip (S.ap) (addresses),
  S.justs,
]) (permissions)

export {
  createAddressUser,
  createOrganizationUser,
  buildAddressDataItems,
  buildDataItems,
}
