// @flow
import * as R from "ramda";
import Future from "fluture";
import * as S from "sanctuary";
import { callBack } from "./../CallBack";

import {
  type AuthenticatedTenant,
} from "../../Entity/Types.js";
import {
  instanceService,
} from "../../Entity/InstanceService";
import {
  ApiClient,
  TenantsApi,
  AuthenticatedTenant as AuthenticatedTenantGW,
  TestConnectionResponse as TestConnectionResponseGW,
} from "@bmbix/bmb_xero_v1_authz_client";

const createPlatformTenantFromGW = (
  platformTenantGW: PlatformTenantGW,
): PlatformTenant => {

  const tenantExists = (platformTenantGW) => platformTenantGW.exists;

  const createWithTenant = (platformTenantGW) =>
    instanceService.createPlatformTenant({
      exists: platformTenantGW.exists,
      tenant: createAuthenticatedTenantFromGW(platformTenantGW.tenant),
    });

  const createWithoutTenant = (platformTenantGW) =>
    instanceService.createPlatformTenant({
      exists: platformTenantGW.exists,
    });

  return S.ifElse
    (tenantExists)
    (createWithTenant)
    (createWithoutTenant)
    (platformTenantGW)
}


const createAuthenticatedTenantFromGW = (
  authenticatedTenantGW: AuthenticatedTenantGW,
): AuthenticatedTenant => {

  return instanceService.createAuthenticatedTenant({
    bmbixUserId: authenticatedTenantGW.bmbix_user_id,
    xeroTenantId: authenticatedTenantGW.xero_tenant_id,
    tenantName: authenticatedTenantGW.tenant_name,
    tenantType: authenticatedTenantGW.tenant_type,
    recordCreatedAt: authenticatedTenantGW.record_created_at,
  });
}


const getPlatformTenant = (token: string) => (platformId: string) => {
  const defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new TenantsApi(defaultClient);

  const dataHandler = data => {
    const platformTenantGW = data.platform_tenant;
    const platformTenant = createPlatformTenantFromGW(platformTenantGW);
    return platformTenant;
  }

  return Future((reject, resolve) => {
    apiInstance.getPlatformTenant(
      platformId,
      callBack(reject, resolve) (dataHandler),
    );
    return console.log;
  });

}


const getTenants = (args: {
  token: string,
  defaultClient_: Object,
}): Promise<Array<AuthenticatedTenant>> => {
  const {
    token,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new TenantsApi(defaultClient);
  return new Promise((resolve, reject) => {
    apiInstance.getTenants(
      (error, data, response) => {
        if (error) {
          resolve(error);
        } else {
          const authenticatedTenantsResponseGW = data;
          const authenticatedTenants = R.map(
            createAuthenticatedTenantFromGW,
            authenticatedTenantsResponseGW.authenticated_tenants,
          );
          resolve(authenticatedTenants);
        }
      }
    );
  });

}


const testTenant = (args: {
  token: string,
  xeroTenantId: string,
  defaultClient_: Object,
}): Promise<Array<AuthenticatedTenant>> => {
  const {
    token,
    xeroTenantId,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new TenantsApi(defaultClient);
  return new Promise((resolve, reject) => {
    apiInstance.testTenant(
      xeroTenantId,
      (error, data, response) => {
        if (error) {
          resolve(error);
        } else {
          const testConnectionResponseGW = data;
          const status = createTestConnectionResponseFromGW(testConnectionResponseGW);
          resolve(status);
        }
      }
    );
  });

}

const createTestConnectionResponseFromGW = (
  testConnnectionResponseGW: TestConnectionResponseGW,
) => {
  const expected = testConnnectionResponseGW.status;
  return expected;
}

export {
  createAuthenticatedTenantFromGW,
  createTestConnectionResponseFromGW,
  createPlatformTenantFromGW,
  getTenants,
  testTenant,
  getPlatformTenant,
}
