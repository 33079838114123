// @flow
import React, { Component } from 'react';
import {
  BellIcon,
  BriefcaseIcon,
  DesktopDownloadIcon,
  VerifiedIcon,
  GearIcon,
  HomeIcon,
  PersonIcon,
  SignOutIcon,
  ThreeBarsIcon,
} from "@primer/octicons-react"
import {
  Dropdown,
  NavDropdown,
} from 'react-bootstrap'
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import
  Nav
from "react-bootstrap/Nav"
import
  Navbar
from "react-bootstrap/Navbar"
import { connect } from 'react-redux'
import { Translation } from "@psyycker/react-translation";
import {
  withRouter,
} from "react-router-dom"
import logo from "./bmbix_inverted.svg"
import {
  type Organization,
  type Account,
} from "./Entity/Types"


type Props = {
  account: Account,
  history: Function,
  handleLogout: Function,
  organizations: Array<Organization>,
}
type State = {
}
export class BMBNavbarPresenter extends Component<Props, State> {

  constructor (props: Props) {
    super(props)
    this.handleUserSelect = this.handleUserSelect.bind(this)
    this.handleHome = this.handleHome.bind(this)
  }

  handleUserSelect(ek:any, ev:any) {
    switch (ek) {
      case "home":
        this.props.history.push("/");
        break;
      default:
        this.props.history.push("/" + ek);
    }
  }

  handleHome (ev:any) {
    this.props.history.push("/")
  }

  render () {
    const accountTooltip = <Tooltip>{this.props.account.sub}</Tooltip>
    return (
      <Navbar variant="dark" bg="primary">
        <Nav>
          <Nav.Link className="house" onClick={this.handleHome}><HomeIcon /></Nav.Link>
        </Nav>
          <Navbar.Brand>
            <a href="https://bmbix.com"><img src={logo} className="App-logo" alt="logo" /></a>
          </Navbar.Brand>
          <Nav className="ml-auto">
          <Nav.Item><OverlayTrigger overlay={accountTooltip} placement="bottom" delay={{show: 250, hide: 400}} ><Nav.Link>{this.props.account ? this.props.account.email: ""}</Nav.Link></OverlayTrigger></Nav.Item>
          <NavDropdown
            className="hamburger"
            id="dropdown-menu-user"
            alignRight
            title={<ThreeBarsIcon/>}
            onSelect={this.handleUserSelect}
          >
            <Dropdown.Item key="3" eventKey="home" ><HomeIcon/> <Translation translationKey="BMBNavbar.home" defaultValue="Home" /></Dropdown.Item>
            <Dropdown.Item key="2" eventKey="m/organizations" ><GearIcon/> <Translation translationKey="BMBNavbar.manage" defaultValue="Manage" /></Dropdown.Item>
            <Dropdown.Item key="1" eventKey="a" ><PersonIcon/> <Translation translationKey="BMBNavbar.account" defaultValue="Account" /></Dropdown.Item>
            <Dropdown.Item key="55" eventKey="profiles" ><BriefcaseIcon/> <Translation translationKey="BMBNavbar.profiles" defaultValue="Profiles" /></Dropdown.Item>
            <Dropdown.Item key="7" eventKey="c" ><DesktopDownloadIcon/> <Translation translationKey="BMBNavbar.claim" defaultValue="Claim" /></Dropdown.Item>
            <Dropdown.Item key="8" eventKey="v/organizations" ><VerifiedIcon/> <Translation translationKey="BMBNavbar.verify" defaultValue="Verify" /></Dropdown.Item>
            <Dropdown.Item key="6" eventKey="request-logout" ><SignOutIcon/> <Translation translationKey="BMBNavbar.logout" defaultValue="Logout" /></Dropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar >
    )
  }
}

const connector = connect(
  state => {
    return {
      account: state.account,
      organizations: state.orgs,
    }
  },
);


const BMBNavbar = connector(BMBNavbarPresenter);


export default withRouter(BMBNavbar)
