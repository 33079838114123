// @flow
import React, {
} from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

import {
  type Account,
} from "../../../Entity/Types.js";
import {
  copyToClipboard,
} from "../../../Util/copy.js";

const View = (props: {
  account: Account,
}) => {
  const {
    account,
  } = props;

  return (
    <>
      <div className="clearfix">
      <h2 className="float-left">My Account</h2>
      <Link className="align-middle d-inline-flex ml-2 mt-2" to={`/profiles/user/${account.sub}`} >public profile</Link>
      </div>
      <hr/>
      <h2>Bmbix User Id</h2>
      <p>{account.sub}</p>
      <Button onClick={e => copyToClipboard(account.sub)}>Copy</Button>
    </>
  )
}

export {
  View,
}
