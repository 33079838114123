// @flow
import { createElement as el } from "react";
import { View } from "./view.js";
import {
  type DecoratedRejectedMessage as RejectedMessage
} from "./../../entity.js";

const Controller = (props: {
  organizationId: string,
  addressId: string,
  rejectedMessages: Array<RejectedMessage>,
}) => {
  const {
    organizationId,
    addressId,
    rejectedMessages,
  } = props;

  return el(
    View,
    {
      organizationId,
      addressId,
      items: rejectedMessages,
    }
  )
}


export { Controller }
