// @flow
import React from "react";
import Button from "react-bootstrap/Button";
import * as S from "sanctuary";
import { SyncIcon } from "@primer/octicons-react";

import { Link } from "react-router-dom";
import {
  CheckIcon,
  XIcon,
} from "@primer/octicons-react"

import {
  type Acceptance,
  type ReceivedMessage,
  type RejectedMessage,
} from "./../../../Entity/Types.js";


const BoolIcon = (props: {aBoolean: boolean}) => {
  const { aBoolean } = props;
  return aBoolean
  ? <CheckIcon />
  : <XIcon />
}


const StatusTable = (props: {
  receivedMessage?: ReceivedMessage,
  acceptedMessage?: Acceptance,
  rejectedMessage?: RejectedMessage,
  processedMessage?: Function,
  aliasMap: Function,
}) => {
  const {
    receivedMessage,
    acceptedMessage,
    rejectedMessage,
    processedMessage,
    aliasMap,
  } = props;

  const link = addressId => {
    console.log("----- addressId:", addressId);
    if (!!addressId) {
      const to = `/profiles/address/${addressId}`;
      const slug = S.fromMaybe ("No alias found") (S.value (addressId) (aliasMap));
      return (
        <Link to={to}>{slug}</Link>
      )
    } else {
      return null;
    }
  }

  const receivedRows = !!receivedMessage ? (
    <>
    <tr>
      <th>Message id:</th>
      <td><Link to={`/messages/${receivedMessage.messageId}`}>{receivedMessage.messageId}</Link></td>
    </tr>
    <tr>
      <th>Status:</th>
      <td>{receivedMessage.status}</td>
    </tr>
    <tr>
      <th>From:</th>
      <td>{link(receivedMessage.fromAddressId)}</td>
    </tr>
    <tr>
      <th>To:</th>
      <td>{link(receivedMessage.toAddressId)}</td>
    </tr>
    <tr>
      <th>Message type:</th>
      <td>{receivedMessage.messageType}</td>
    </tr>
    </>
  ): null;


  const rejectedRows = !!rejectedMessage ? (
    <>
      <tr>
        <th>Valid:</th>
        <td><BoolIcon aBoolean={rejectedMessage.reasons.valid} /></td>
      </tr>
      <tr>
        <th>Access listed:</th>
        <td><BoolIcon aBoolean={rejectedMessage.reasons.whitelisted} /></td>
      </tr>
      <tr>
        <th>Not blocked:</th>
        <td><BoolIcon aBoolean={rejectedMessage.reasons.not_blocked} /></td>
      </tr>
      <tr>
        <th>Understood:</th>
        <td><BoolIcon aBoolean={rejectedMessage.reasons.understood} /></td>
      </tr>
    </>
  ): null;

  const acceptedRows = !!acceptedMessage ? (
    <>
      <tr>
        <th>Sender reference:</th>
        <td>{acceptedMessage.senderReference}</td>
      </tr>
      <tr>
        <th>Submission timestamp:</th>
        <td>{acceptedMessage.submissionTimestamp}</td>
      </tr>
      <tr>
        <th>Message processed receipt id:</th>
        <td>{acceptedMessage.messageProcessedReceiptId}</td>
      </tr>
      <tr>
        <th>Recipient processed timestamp:</th>
        <td>{acceptedMessage.receivedTimestamp}</td>
      </tr>
      <tr>
        <th>Recipient processing result:</th>
        <td>{acceptedMessage.result}</td>
      </tr>
      <tr>
        <th>Recipient processing reference:</th>
        <td>{acceptedMessage.reference}</td>
      </tr>
      <tr>
        <th>Recipient processing comments:</th>
        <td>{acceptedMessage.comments}</td>
      </tr>

    </>
  ): null;

  return (
    <table className="table table-striped table-hover">
      <tbody>
        { receivedRows }
        { rejectedRows }
        { acceptedRows }
      </tbody>
    </table>

  )
}


const View = (props: {
  aliasMap: Function,
  receivedMessage: ReceivedMessage,
  acceptedMessage: Acceptance,
  rejectedMessage: RejectedMessage,
  onRefresh: Function,
}) => {
  const {
    aliasMap,
    receivedMessage,
    acceptedMessage,
    rejectedMessage,
    onRefresh,
  } = props;

  return (
    <>
    <h2>Message Status Report</h2>
    <Button onClick={onRefresh} ><SyncIcon /> Refresh</Button>
    <StatusTable
       receivedMessage={receivedMessage}
       rejectedMessage={rejectedMessage}
       acceptedMessage={acceptedMessage}
       aliasMap={aliasMap}
    />
    </>
  );
}

export {
  View,
}

