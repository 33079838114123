// @flow

import React from "react";
import Tree, { TreeNode } from "rc-tree";
import "rc-tree/assets/index.css";
import * as S from "sanctuary";

const addChildToNode = parent => child => {
  const key = S.prop ("key") (parent);
  const title = S.prop ("title") (parent);
  const children = S.prop ("children") (parent);
  const updatedChildren = S.append (child) (children)
  const updatedParent = {
    key,
    title,
    children: updatedChildren,
  }
  return updatedParent
}

const createNode = title => {
  return {title, key: Math.random, children: []};
}

const createRoot = () => {
  return [];
}

const addNodeToRoot = root => node => {
  return S.append (node) (root);
}

const processLeaf = (leafNode) => {
  return {title: leafNode.tagName, key: Math.random()}
}

const hasChildren = (walker) => {
  const firstChild = walker.firstChild();
  walker.parentNode();
  if (firstChild) {
    return true
  } else {
    return false
  }
}

const isLastNode = walker => {
  console.log("isLastNode, walker.currentNode", walker.currentNode);
  const nextNode = walker.nextNode();
  if (nextNode === null) {
    return true;
  } else {
    walker.previousNode();
    return false;
  }
}

const isFirstLevelNode = walker => {
  console.log("isFirstLevelNode, walker.currentNode", walker.currentNode);
  const parentNode = walker.parentNode();
  if (parentNode === null) {
    return true;
  }
  else {
    walker.previousNode();
    return false;
  }
}

const convert =
  (content: string) =>
{
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, "application/xml");

  const result = process (doc.documentElement);
  console.log("result:", result);

  return [result];
}

const isElement =
  node =>
{
  return node.nodeType === Node.ELEMENT_NODE;
}

const attrToStr =
  attribute =>
{
  return `${attribute.name}="${attribute.value}"`;
}

const immediateNonEmptyTextNodes = el =>
  [...el.childNodes].filter(e =>
    e.nodeType === Node.TEXT_NODE && e.textContent.trim()
  );

const generateTitle =
  element =>
{
  const attributes = [];
  for (const name of element.getAttributeNames()) {
    const value = element.getAttribute(name);
    attributes.push({name, value});
  }
  const text = tn => tn.textContent;
  const textContent = immediateNonEmptyTextNodes(element).map(text).join("");
  const attributeStrings = S.unwords (S.map (attrToStr) (attributes));
  return `<${element.tagName} ${attributeStrings}>${textContent}`;
}


const process =
  node =>
{
  if (node.hasChildNodes()){
    if (isElement(node)){
      const key = Math.random();
      const title = generateTitle(node);
      const children = []
      for (const n of node.childNodes) {
        const result = process(n);
        if (result !== null) {
          children.push(result);
        }
      }
      return {key, title, children}
    } else {
      return null;
    }

  } else {

    if (isElement(node)){
      const key = Math.random();
      const title = generateTitle(node);
      const children = []
      return {key, title, children}
    } else {
      return null;
    }


  }
}


const XmlTree = (props: {
  content: string;
}) => {

  const {
    content,
  } = props;

  console.log("content:", content);

  const treeData = convert(content);

  return  <Tree
    showLine
    showIcon={false}
    defaultExpandAll
    treeData={treeData}
  />
}


export {
  XmlTree,
  XmlTree as default,
}
