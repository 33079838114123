// @flow
import { createElement as el } from "react";

const Controller = (props: {
  type: string,
  data: string,
  filename: string,
}) => {
  const {
    data,
    type,
    filename,
  } = props;

  const blob = new Blob(
    [data],
    {
      type,
    }
  );

  const url = window.URL.createObjectURL(blob);

  return el(
    "a",
    {
      download: filename,
      href: url,
    },
    "Download",
  );
}

export {
  Controller,
}
