// @flow


import {
  createElement as el,
  useState,
}from "react";

import { View } from "./view";

const Controller = (props: {}) => {


  return el(
    View,
    {
    },
  )
}



export { Controller }
