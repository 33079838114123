//@flow

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { fork } from "fluture";

import { useToken } from "./../../../State";
import { deleteAndRemoveFromStore } from "./../../Api/delete.js";

const DeleteModal = (props: {
  organizationId: string,
  oauth2ClientId: string,
  onCancel: Function,
  onComplete: Function,
}) => {
  const {
    organizationId,
    oauth2ClientId,
    onCancel,
    onComplete,
  } = props;

  const INPUT = "input";
  const WAITING = "waiting";
  const SUCCESS = "success";
  const FAILURE = "failure";

  const [display, setDisplay] = useState(INPUT);
  const [errorMessage, setErrorMessage] = useState();
  const token = useToken();

  const handleSubmit = () => {
    setDisplay(WAITING);
    fork(error => {
      setDisplay(FAILURE);
      setErrorMessage(error.toString());
    })(_ => {
        setDisplay(SUCCESS);
    })(deleteAndRemoveFromStore (token) (organizationId) (oauth2ClientId))
  }

  const input =
    <>
    <h3>Delete keys?</h3>
    <p>{oauth2ClientId}</p>
    </>

  const waiting = display === WAITING ? (
    <p>Waiting ...</p>
  ): null;

  const success = display === SUCCESS ? (
    <p>Success!</p>
  ): null;

  const failure = display === FAILURE ? (
    <>
    <p>Ooops! something went wrong.</p>
    <p>{errorMessage}</p>
    </>
  ): null;

  const completeButton = (display === SUCCESS || display === FAILURE ) ? (
    <Button onClick={ e => onComplete() } >Dismiss</Button>
  ): null;

  const cancelButton = (display === INPUT || display === WAITING) ? (
    <Button onClick={ e => onCancel() } >Cancel</Button>
  ): null;

  const submitButton = display === INPUT ? (
    <Button onClick={handleSubmit}>Submit</Button>
  ): null;

  return (
    <Modal show={true}>
    <Modal.Header closeButton onHide={onCancel}>
      <Modal.Title>Delete</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {input}
      {waiting}
      {success}
      {failure}
    </Modal.Body>
    <Modal.Footer>
      {completeButton}
      {cancelButton}
      {submitButton}
    </Modal.Footer>
    </Modal>
  )
}

export { DeleteModal }
