// @flow
import React from "react";
import {
  useParams,
  Route,
} from "react-router-dom";
import {
  useDispatch,
} from "react-redux";
import {
  fork,
} from "fluture";

import AddressPage from "../../Addresses/ViewOne";
import { View as AddressesPage, } from "../../Addresses/View.js";
import { OrganizationsView, } from '../../Organization/OrganizationsView';
import { OrganizationNavbar } from "../../Organization/OrganizationNavbar";
import { Navbar as AddressNavbar } from "../../Addresses/Navbar";

import OrganizationPanel from "../../Organization/OrganizationPanel";
import PlatformsView from "../../Platforms/PlatformsView"
import { View as OrganizationProxiesView, } from "../../OrganizationProxies";
import { PermissionsView, } from "../../Organization/PermissionsView.js";
import { IconView } from "../../Organization/IconView.js";
import { PkiView } from "../../Organization/PkiView.js";
import { CertificateView } from "../../Organization/CertificateView";
import { View as APlatformAssignmentView } from "../../PlatformAssignment/View";
import { Page as AAddressBookEntriesPage } from "../../AddressBookEntries/Page.js";
import { TeamApp } from "../../Addresses/TeamApp";
import { AccessListsApp } from "../../Addresses/AccessListsApp/";
import { BillingApp } from "../../BillingApp/";
import MessageTypesApp from "../../Addresses/MessageTypesApp";
import { PkiView as PkiAddressView } from "../../Addresses/PkiView";
import { useToken } from "./../../State";
import { refreshUserProfilesThunk } from "./../../Thunks/user-profiles.js";
import { App as contentTypesApp } from "./../../Organization/ContentTypesApp";


const Prefetch = (props: {
}) => {
  const dispatch = useDispatch();
  const token = useToken();
  const {
    addressId,
    organizationId,
  } = useParams();

  const future = dispatch(refreshUserProfilesThunk(token)(addressId));
  fork(console.log)(console.log)(future)

  return null;
}

const View = (props: {
  here: string,
}) => {
  const {
    here,
  } = props;

  return (
    <>
    <Route
      exact
      path={[
        `${here}/:organizationId`,
        `${here}/:organizationId/platforms`,
        `${here}/:organizationId/platforms/:pid`,
        `${here}/:organizationId/platforms/:pid/:platformType`,
        `${here}/:organizationId/addresses`,
        `${here}/:organizationId/team`,
        `${here}/:organizationId/content-types`,
        `${here}/:organizationId/organization-proxies`,
        `${here}/:organizationId/icon`,
        `${here}/:organizationId/identify`,
        `${here}/:organizationId/billing`,
        `${here}/:organizationId/pki`,
      ]}>
      <OrganizationNavbar />
    </Route>
    <Route
      exact
      path={[
        `${here}/:organizationId/addresses/:addressId`,
        `${here}/:organizationId/addresses/:addressId/team`,
        `${here}/:organizationId/addresses/:addressId/platform`,
        `${here}/:organizationId/addresses/:addressId/address-book`,
        `${here}/:organizationId/addresses/:addressId/access-lists`,
        `${here}/:organizationId/addresses/:addressId/message-types`,
        `${here}/:organizationId/addresses/:addressId/pki`,
      ]}
    >
      <AddressNavbar />
    </Route>
    <Route
      exact
      path={`${here}`}
      component={OrganizationsView}
    />
    <Route
      exact
      path={`${here}/:organizationId`}
      component={OrganizationPanel}
    />
    <Route
      path={`${here}/:organizationId/platforms`}
      component={PlatformsView}
    />
    <Route
      path={`${here}/:organizationId/team`}
      component={PermissionsView}
    />
    <Route
      path={`${here}/:organizationId/icon`}
      component={IconView}
    />
    <Route
      path={`${here}/:organizationId/identify`}
      component={CertificateView}
    />
    <Route
      path={`${here}/:organizationId/billing`}
      component={BillingApp}
    />
    <Route
      path={`${here}/:organizationId/content-types`}
      component={contentTypesApp}
    />
    <Route
      exact
      path={`${here}/:organizationId/organization-proxies`}
      component={OrganizationProxiesView}
    />
    <Route
      exact
      path={`${here}/:organizationId/pki`}
      component={PkiView}
    />
    <Route
      exact
      path={`${here}/:organizationId/addresses`}
      component={AddressesPage}
    />
    <Route
      exact
      path={`${here}/:organizationId/addresses/:addressId`}
      component={AddressPage}
    />
    <Route
      path={`${here}/:organizationId/addresses/:addressId/team`}
    ><Prefetch /></Route>
    {/*
    <Route
      path={`${here}/:organizationId/addresses/:addressId/team`}
      component={PermissionsView}
    />
    */}
    <Route
      path={`${here}/:organizationId/addresses/:addressId/team`}
      component={TeamApp}
    />
    <Route
      path={`${here}/:organizationId/addresses/:addressId/platform`}
      component={APlatformAssignmentView}
    />
    <Route
      path={`${here}/:organizationId/addresses/:addressId/address-book`}
      component={AAddressBookEntriesPage}
    />
    <Route
      path={`${here}/:organizationId/addresses/:addressId/access-lists`}
      component={AccessListsApp}
    />
    <Route
      path={`${here}/:organizationId/addresses/:addressId/message-types`}
      component={MessageTypesApp}
    />
    <Route
      path={`${here}/:organizationId/addresses/:addressId/pki`}
      component={PkiAddressView}
    />
    </>
  )
}


export {
  View,
}

