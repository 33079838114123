// @flow
import {createElement as el} from "react";
import {
  View,
} from "./View.js";

const Controller = (props: {
  infoTitle: string,
  buttonText: string,
  infoContent: Function,
}) => {
  return el(
    View,
    props,
  )
}


export {
  Controller,
}
