// @flow
import * as R from "ramda";

import {
  ApiClient,
  AddressBookEntriesApi,
  AddressBookEntry as AddressBookEntryGW,
} from "@bmbix/bmb_isimud_client";
import { instanceService } from "../../Entity/InstanceService";
import { type AddressBookEntry } from "../../Entity/Types";
import log from "loglevel";

const logger = log.getLogger("Api.Isimud")

const createAddressBookEntryFromGW = (
  addressBookEntryGW:AddressBookEntryGW,
): AddressBookEntry => {
  return instanceService.createAddressBookEntry({
    addressBookEntryId: addressBookEntryGW.address_book_entry_id,
    ownerAddressId: addressBookEntryGW.owner_address_id,
    localAccount: addressBookEntryGW.local_account,
    localEmail: addressBookEntryGW.local_email,
    localName: addressBookEntryGW.local_name,
    foreignAddressId: addressBookEntryGW.foreign_address_id,
  });
}


const getAddressBookEntries = (args: {
  token: string,
  organizationId: string,
  addressId: string,
  storeAddressBookEntries: Function,
}):Promise<Array<AddressBookEntry>> => {
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = args.token;
  let apiInstance = new AddressBookEntriesApi();
  return new Promise((resolve, reject) => {
    apiInstance.index(args.organizationId, args.addressId, (error, data, response) => {
      if (error) {
        reject(error);
      } else {
        const addressBookEntriesResponseGW = data;
        const addressBookEntriesGW = addressBookEntriesResponseGW.address_book_entries;
        const addressBookEntries = R.map(createAddressBookEntryFromGW, addressBookEntriesGW);
        args.storeAddressBookEntries(addressBookEntries);
        resolve(addressBookEntries);
      }
    });
  });
}

const original_getAddressBookEntries = (args: {
  token: string,
  organizationId: string,
  addressId: string,
}):Promise<Array<AddressBookEntry>> => {
  console.warn("this method not tested!");
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = args.token;
  let apiInstance = new AddressBookEntriesApi();
  return new Promise((resolve, reject) => {
    apiInstance.index(args.organizationId, args.addressId, (error, data, response) => {
      if (error) {
        resolve(error);
      } else {
        const addressBookEntriesResponseGW = data;
        const addressBookEntriesGW = addressBookEntriesResponseGW.address_book_entries;
        const addressBookEntries = R.map(createAddressBookEntryFromGW, addressBookEntriesGW);
        resolve(addressBookEntries);
      }
    });
  });
}

const insertAddressBookEntry = (args: {
  token: string,
  organizationId: string,
  addressId: string,
  localAccount: string,
  foreignAddressId: string,
}):Promise<AddressBookEntry> => {
  console.log("args:", args);
  const body = AddressBookEntryGW.constructFromObject({
    owner_address_id: args.addressId,
    local_account: args.localAccount,
    foreign_address_id: args.foreignAddressId,
  });
  console.warn("this method not tested!");
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = args.token;
  let apiInstance = new AddressBookEntriesApi();
  // console.log("args.organizationId", args.organizationId);
  return new Promise((resolve, reject) => {
    apiInstance.insert(body, args.organizationId, args.addressId, (error, data, response) => {
      if (error) {
        reject(error);
      } else {
        const addressBookEntryGW = data.address_book_entry;
        const addressBookEntry = createAddressBookEntryFromGW(addressBookEntryGW);
        resolve(addressBookEntry);
      }
    });
  });
}

const deleteAddressBookEntry = (args: {
  token: string,
  organizationId: string,
  addressId: string,
  addressBookEntryId: string,
}):Promise<void> => {
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = args.token;
  let apiInstance = new AddressBookEntriesApi();
  // console.log("args.organizationId", args.organizationId);
  return new Promise((resolve, reject) => {
    apiInstance.callDelete(
      args.organizationId,
      args.addressId,
      args.addressBookEntryId,
      (error, data, response) => {
      if (error) {
        resolve(error);
      } else {
        const addressBookEntryGW = data.address_book_entry;
        if (addressBookEntryGW === undefined) { // was null
          resolve(addressBookEntryGW);
        } else {
          reject(new Error(`Delete response was not null`));
        }

      }
    });
  });
}

const syncAddressBook = (args: {
  token: string,
  organizationId: string,
  addressId: string,
  apiClient?: Object,
}):Promise<string> => {
  logger.debug("args:", args);

  const apiClient = !!args.apiClient ? args.apiClient: ApiClient.instance;

  let MartletOauth2 = apiClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = args.token;

  let apiInstance = new AddressBookEntriesApi(apiClient);
  return new Promise((resolve, reject) => {
    apiInstance.sync(args.organizationId, args.addressId, (error, data, response) => {
      if (error) {
        resolve(error);
      } else {
        const syncId = data.sync_id;
        resolve(syncId);
      }
    });
  });
}

const updateForeignAddressId = (args: {
  organizationId: string,
  addressId: string,
  addressBookEntryId: string,
  localAccount: string,
  foreignAddressId: string,
  addressBookEntriesApi: Object,
  updateForeignAddressId: Function,
}):Promise<null> => {

  const body = AddressBookEntryGW.constructFromObject({
    local_account: args.localAccount,
    foreign_address_id: args.foreignAddressId,
  });

  return new Promise((resolve, reject) => {
    args.addressBookEntriesApi.updateForeignAddressId(
      body,
      args.organizationId,
      args.addressId,
      args.addressBookEntryId,
      (error, data, response) => {
      if (error) {
        reject(error);
      } else {
        args.updateForeignAddressId(
          args.addressBookEntryId,
          args.foreignAddressId,
        );
        resolve(data);
      }
    });
  });
}





export {
  createAddressBookEntryFromGW,
  getAddressBookEntries,
  insertAddressBookEntry,
  deleteAddressBookEntry,
  syncAddressBook,
  updateForeignAddressId,
}
