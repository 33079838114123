// @flow

import React from "react";
import * as R from "ramda";
import { Route, withRouter, Link, Switch } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { connect } from "react-redux";

import {
  VerifiedIcon,
  GearIcon,
  TasklistIcon,
  OrganizationIcon,
  MentionIcon,
} from "@primer/octicons-react"

import { getOrganization } from "../State/Organizations";

const mw = <><GearIcon/> Manage</>
const uw = <><TasklistIcon/> Process</>
const vw = <><VerifiedIcon/> Validate</>

// const domains = { m: "Manage", u: "Processing", v: "Validate" }
const domains = { m: mw, u: uw, v: vw }


const OneParts = (props) => {
  // console.log("1pmatch", props.match)
  // console.log("1plocation", props.location)
  const domain = props.match.params.domain;
  const className = domain === "m" ? "manage" :
    domain === "u" ? "user" :
    "validate" ;

  return (
    <Breadcrumb className={className}>
      <Breadcrumb.Item active >{domains[domain]}</Breadcrumb.Item>
    </Breadcrumb>
  );

}

const TwoParts = (props) => {
  // console.log("2pmatch", props.match)
  // console.log("2plocation", props.location)
  const domain = props.match.params.domain;
  const oid = props.match.params.oid
  const org_ = getOrganization(oid, props.organizations);
  const org = !!org_ ? org_ : {name: "External"};
  const linkProps = {
    to:`/${domain}/organizations`
  };
  const className = domain === "m" ? "manage" :
    domain === "u" ? "user" :
    "validate" ;

  return (
    <Breadcrumb className={className}>
      <Breadcrumb.Item linkAs={Link} linkProps={linkProps}>
        {domains[domain]}
      </Breadcrumb.Item>
      <Breadcrumb.Item active ><OrganizationIcon /> {org.name}</Breadcrumb.Item>
    </Breadcrumb>
  );

}

const ThreeParts = (props) => {
  // console.log("3pmatch", props.match)
  // console.log("3plocation", props.location)

  const domain = props.match.params.domain;
  const oid = props.match.params.oid
  const organizationId = oid;
  const org_ = getOrganization(oid, props.organizations);
  const org = !!org_ ? org_ : {name: "External"};
  const aid = props.match.params.aid
  const addressId = aid;
  const as = R.find(R.propEq("id", aid))(props.addresses);

  const className = domain === "m" ? "manage" :
    domain === "u" ? "user" :
    "validate" ;
  const first = `/${domain}/organizations` ;
  const second = `${first}/${organizationId}`;
  const third = `${second}/addresses/${addressId}`;

  return (
    <Breadcrumb className={className}>
      <Breadcrumb.Item linkAs={Link} linkProps={{to: first}}>
        {domains[domain]}
      </Breadcrumb.Item>
      <Breadcrumb.Item linkAs={Link} linkProps={{to: second}}>
        <OrganizationIcon /> {org.name || "not known"}
      </Breadcrumb.Item>
      <Breadcrumb.Item linkAs={Link} linkProps={{to: third}} active>
        <MentionIcon /> {as.displayName}
      </Breadcrumb.Item>
    </Breadcrumb>
  );

}


const withData = (component) => {
  const mapStateToProps = (state, ownProps) => {
    return {
      addresses: state.addresses,
      organizations: state.orgs,
    }
  }
  return withRouter(connect(mapStateToProps)(component))
}


const OnePartsR = withData(OneParts);
const TwoPartsR = withData(TwoParts);
const ThreePartsR = withData(ThreeParts);

const Trail = (props) => {
  // console.log("match", props.match)
  // console.log("location", props.location)

  return (
    <Switch>
      <Route path="/:domain/organizations/:oid/addresses/:aid" >
        <ThreePartsR />
      </Route>
      <Route path="/:domain/organizations/:oid/accounting-systems/:asid" >
        <ThreePartsR />
      </Route>
      <Route path="/:domain/organizations/:oid/" >
        <TwoPartsR />
      </Route>
      <Route path="/:domain/" >
        <OnePartsR />
      </Route>
    </Switch>
  );
}


export default Trail;
