// @flow
import React, {
  useState,
  useEffect,
} from "react";
import {
  withRouter,
} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import { fork } from "fluture";
import * as S from "sanctuary";
import Accordion from 'react-bootstrap/Accordion'
import Card from "react-bootstrap/Card";

import { Controller as MessageStatusWidget } from "./../MessageStatusWidget/controller.js";

import {
  MessageNotesWidget,
} from "./../../../Widgets/MessageNotes";
import {
  type BmbixAddress,
  type Message,
} from "./../../../Entity/Types.js";
import { selectStylesheetLocation } from "./util.js";
import { XmlTree } from "./../../../Widgets/xml-tree.js";

import DownloadLink from "./../../../Widgets/Certificate/DownloadLink";

const Bonzer = (props) => {
  console.log("bonzer props", props);
  return (
    <>
    {props.ref}
    </>
  )
}


type Props = {
}
class Pooter extends React.Component<Props> {

  constructor (props:Props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount () {
    console.log("Pooter mounting", this.ref.current);
    if(!!this.props.bmbixDoc){
      this.ref.current.appendChild(this.props.bmbixDoc);
    }
  }

  render () {
    return (
      <div ref={this.ref}/>
    )
  }
}



const INPUT = "input";
const WAITING = "waiting";
const SUCCESS = "success";
const FAILURE = "failure";


const MessageView_ = (props: {
  address: BmbixAddress,
  message: ?Message,
}) => { const {
    address,
    message,
  } = props;
  const addressId = address.id;
  const organizationId = address.organizationId;
  const [display, setDisplay] = useState(WAITING);
  const [theTransform, setTheTransform] = useState();

  const messageId = S.prop ("messageId") (message);


  const refresh = () => {
    setDisplay(WAITING);

    const firstPromise = new Promise((resolve, reject) => {
      if (!!message) {
        console.log("%%%%%%%%%%%%%%%%%%%%%%%%%", message);
        resolve(message)
      } else {
        const error = new Error("No useful message yet");
        reject(error);
      }
    });
    firstPromise.then(
      message => {
        const stylesheetLocation =
          selectStylesheetLocation(message.contentMediaType);
        const source = message.content;
        const promise = window.SaxonJS.transform({
          stylesheetLocation: stylesheetLocation,
          sourceText: source,
          destination: "raw",
        }, "async");

        promise.then(
          transform => {
            console.log("transform", transform);
            setTheTransform(transform.principalResult);
            setDisplay(SUCCESS);
          }
        ).catch(
          error => {
            console.log("error", error);
          }
        );
      }
    ).catch(
      error => {
        setDisplay(FAILURE);
      }
    );
  }


  useEffect(
    refresh,
    [],
  )

  const input = display === INPUT ? (
    null
  ): null;

  const waiting = display === WAITING ? (
    <p>Waiting ... </p>
  ): null;

  const success = ( display === SUCCESS && !! message ) ? (
    <>
        <Pooter bmbixDoc={theTransform}/>
    </>
      ): null;

  const failure = display === FAILURE ? (
    <>
    <h3>Oops!</h3>
    <p>Looks like we don&apos;t have a renderer for this message type yet.</p>
    <p>You can always view the raw message by using the &quot;Toggle Raw
    Message&quot; button above</p>
    </>
  ): null;

  console.log("message: ", message);

  return (
    <>
    <Row>
    <Col>
    <Alert variant="info">
    This is a human-readable render of a purely digital accounting document. This document
    can be automatically inserted into your accounting systems without re-keying
    or scanning. <a
    href="https://bmbix.com">Find out how</a>.
    </Alert>
    </Col>
    </Row>
    <Row>
    <Col>
    {!!message && <h2>Document type: {message.contentMediaType}</h2>}
    <DownloadLink filename={`bmbix_message_${message.messageId}.xml`} type={message.contentMediaType}  data={message.content} />
    </Col>
    </Row>
    <Row>
    <Col>
    <Accordion>
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey="0">
        Toggle Status Report
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        <Card.Body>
          <MessageStatusWidget address={address} messageId={messageId} />
        </Card.Body>
      </Accordion.Collapse>
    </Card>
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey="1">
        Toggle Raw Message
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="1">
        <Card.Body>
          {message.content}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey="2">
        Toggle Message As Tree
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="2">
        <Card.Body>
          <XmlTree content={message.content} />
        </Card.Body>
      </Accordion.Collapse>
    </Card>
    </Accordion>
    </Col>
    </Row>
    <Row className="mt-3">
    <Col lg={9} sm={9} md={9} xs={9} xl={9}>
    {input}
    {waiting}
    {success}
    {failure}
    </Col>
    <Col>
    <MessageNotesWidget messageId={messageId} addressId={addressId} />
    </Col>
    </Row>
    </>
  )

}


const MessageView = withRouter(MessageView_);

export {
  MessageView,
}
