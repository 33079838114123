// @flow

import * as R from "ramda";

type Decorated = {
  item: any,
  selected: boolean,
}

const decorate = (args: {
  items: Array<any>,
  selection: Array<any>,
  key:string,
}): Array<Decorated> => {
  const {selection, items, key} = args;
  if(selection.length > items.length){
    throw new Error("selection > items");
  }
  if (R.equals(selection, [])) {
    return R.map(item => {return {item:item, selected: false}}, items);
  }
  else {
    const [head, ...remainingSelection] = selection;
    const item = R.find(R.propEq(key, head))(items);
    if(item === undefined) {
      throw new Error("selection contains unknown value");
    }
    const remainingItems = R.without([item], items);
    return R.append(
      {item: item, selected: true},
      decorate({
        items: remainingItems,
        selection: remainingSelection,
        key: key,
      })
    );
  }
}


const decorateInPlace = (args: {
  items: Array<any>,
  selection: Array<any>,
  key:string,
}): Array<Decorated> => {
  const {selection, items, key} = args;
  if(selection.length > items.length){
    throw new Error("selection > items");
  }
  if (R.equals(items, [])) {
    return [];
  }
  else {
    const [head, ...remainingItems] = items;
    const id = R.find(id => head[key] === id)(selection);
    if(id === undefined) {
      return R.prepend(
        {item: head, selected: false},
        decorateInPlace({
          items: remainingItems,
          selection: selection,
          key: key,
        })
      );
    }
    const remainingSelection = R.without([id], selection);
    return R.prepend(
      {item: head, selected: true},
      decorateInPlace({
        items: remainingItems,
        selection: remainingSelection,
        key: key,
      })
    );
  }
}


const filterSelection = (items:any, selection:any, key:string) => {
  if(R.equals(selection, [])){
    return [];
  }
  else {
    const [value, ...remainingSelection] = selection;
    const item = R.find(R.propEq(key, value))(items);
    if (item === undefined){
      throw new Error("item not found for key");
    }
    const remainingItems = R.without([item], items);
    return R.append(
      item,
      filterSelection(remainingItems, remainingSelection, key),
    );
  }
}


export {
  decorate,
  decorateInPlace,
  filterSelection,
}
