// @flow
import React, {
  useState,
  useEffect,
  createElement as el,
} from "react";
import * as R from "ramda";
import * as S from "sanctuary";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { promise } from "fluture";
import {
  withRouter,
} from "react-router";
import {
  Link,
} from "react-router-dom";

import { getPlatforms } from "./../Api/Isimud/Platforms.js";
import {
  withXeroV1AuthzApi,
  getPlatformTenant,
} from "../Api/XeroV1Authz";
import {
  type Platform,
  type AuthenticatedTenant,
} from "../Entity/Types.js";
import {
  Spinner,
} from "./../Widgets/Toast";
import {
  TenantMapping,
} from "./XeroV1/Widgets/TenantMapping.js";

import { useToken } from "./../State";


import { useMachine } from "@xstate/react";
import {
  createMachine,
  send,
  assign,
  spawn,
  sendParent,
} from "xstate";
import { fetcherStateChart } from "./../StateMachines/FetchingMachine.js";
import {
  controlPanelStateChart,
} from "./../StateMachines/ControlPanelMachine.js";
import { makeStateChart } from "./../StateMachines";


const getTimestamp = () => (new Date()).getTime()

const SelectTenantWidget = (props: {
  tenant: AuthenticatedTenant,
  platform?: Platform,
  onSelect: Function,
}) => {
  const {
    tenant,
    platform,
    onSelect,
  } = props;

  const handleClickSelect = (e) => {
    onSelect(tenant)
  }

  if ( platform === undefined || platform === null) {
    return (
      <>
        <p>{tenant.tenantName}</p>
        <Button onClick={handleClickSelect}>Select</Button>
      </>
    )
  } else {
    const path = `/m/organizations/${platform.organizationId}/platforms/${platform.platformId}/${platform.type}`;
    return (
      <>
        <p>{tenant.tenantName}</p>
        <Link to={path} >{platform.name}</Link>
      </>
    )
  }
}

const SelectTenantModal = (props: {
  tenantPlatformPairs: Array<any>,
  onSelect: Function,
  onClose: Function,
}) => {
  const {
    tenantPlatformPairs,
    onSelect,
    onClose,
  } = props;
  console.log("tenantPlatformPairs", tenantPlatformPairs);
  return R.map(
    pair => {
      const [tenant, platform] = pair;
      return (
        <Modal show={true} onHide={onClose}>
        <Modal.Header closeButton>
        <Modal.Title>Assign tenant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SelectTenantWidget
            tenant={tenant}
            platform={platform}
            onSelect={onSelect}
          />
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
        </Modal>
      )
    },
    tenantPlatformPairs,
  );
}

const SelectTenants = (props: {}) => {
  const {
    xeroV1AuthzApi,
    platform,
    tenants,
    mappings,
    platforms,
    show,
    onClose,
    onRefresh,
  } = props;

  const getMap = (tenant, mappings) => {
    return R.find(R.propEq("xeroTenantId", tenant.xeroTenantId))(mappings);
  }

  const getPlatform = (mapping, platforms) => {
    return R.find(R.propEq("platformId", mapping.platformId))(platforms);
  }

  const join = (pair, pairs) => {
    return R.append(pair, pairs);
  }

  const marry = (tenants, platforms, mappings) => {
    console.log("tenants", tenants);
    console.log("mappings", mappings);
    if (tenants.length === 0) {
      return [];
    } else {
      const [tenant, ...restTenants] = tenants;
      const mapping = getMap(tenant, mappings);
      if (mapping !== undefined) {
        const platform = getPlatform(mapping, platforms);
        const pair = [tenant, platform];
        const nextPairs = marry(restTenants, platforms, mappings);
        const pairs = join(pair, nextPairs);
        return pairs;
      } else {
        const pair = [tenant, null];
        const nextPairs = marry(restTenants, platforms, mappings);
        const pairs = join(pair, nextPairs);
        return pairs;
      }
    }
  }

  const tenantPlatformPairs = marry(tenants, platforms, mappings);

  const handleSelectTenant = (tenant) => {
    xeroV1AuthzApi.insertMapping({
      platformId: platform.platformId,
      xeroTenantId: tenant.xeroTenantId,
    }).then(
      tenantMapping => {
        console.log("created tenant mapping", tenantMapping);
        onClose();
        onRefresh();
      }
    ).catch(
      error => {
        alert(`insertMapping blew up, ${error}`)
        onClose();
      }
    );
  }

  return show === true
  ? <SelectTenantModal
      tenantPlatformPairs={tenantPlatformPairs}
      onSelect={handleSelectTenant}
      onClose={onClose}
    />
  : null;
}

const TenantWidget = (props: {}) => {
  const {
    xeroV1AuthzApi,
    connection,
    tenant,
    mapping,
    platform,
    tenants,
    mappings,
    platforms,
    onRefresh,
  } = props;

  const [ showSelectTenantModal, setShowSelectTenantModal ] = useState();
  const [ testStatus, setTestStatus ] = useState();

  const handleClickAssignTenant = (e) => {
    setShowSelectTenantModal(true);
  };

  const handleClickUnassignTenant = (e) => {
    if (mapping === undefined || mapping === null) {
      console.log("No mapping to unassign");
      alert("No mapping to unassign");
    } else {
      xeroV1AuthzApi.deleteMapping({
        tenantMappingId: mapping.tenantMappingId,
      }).then(
        null_ => {
          console.log("deleted mapping");
          onRefresh();
        }
      ).catch(
        error => console.log("deleteMapping blew up", error)
      );
    }
  };

  const handleClickTest= (e) => {
    if ( tenant === undefined ) {
      console.log("No tenant to test");
      alert("No tenant to test");
    } else {
      xeroV1AuthzApi.testTenant({
        xeroTenantId: tenant.xeroTenantId,
      }).then(
        status => {
          setTestStatus(status);
          alert(`Test status: ${status}`);
        }
      ).catch(
        error => {
          console.log("deleteMapping blew up", error);
          alert(`testing tenant blew up ${error}`);
        }
      );
    }
  };


  const handleClose = e => setShowSelectTenantModal(false);

  return (connection === undefined || connection === null )
  ? <p>Awaiting connection</p>
  : (tenant === undefined || tenant === null )
  ? <>
      <Button onClick={handleClickAssignTenant}>Assign Tenant</Button>
      <SelectTenants
        xeroV1AuthzApi={xeroV1AuthzApi}
        platform={platform}
        tenants={tenants}
        mappings={mappings}
        platforms={platforms}
        show={showSelectTenantModal}
        onClose={handleClose}
        onRefresh={onRefresh}
      />
    </>
  : <>
      <p>{tenant.tenantName}</p>
      <Button onClick={handleClickUnassignTenant}>Unassign</Button>
      <Button onClick={handleClickTest}>Test</Button>
      </>
}

const ConnectionWidget = (props: {}) => {
  const {
    xeroV1AuthzApi,
    connection
  } = props;

  const handleClickConnection = (e) => {
    const return_to_url = window.location.href;
    console.log("return_to_url:", return_to_url);
    xeroV1AuthzApi.generateURL({
      return_to_url,
    }).then(
      url => {
        window.location = url
      }
    ).catch(
      error => console.log("Error connecting to Xero:", error)
    );
  }

  return connection === undefined
  ? <Button onClick={handleClickConnection}>Connect</Button>
  : <>
    <p>Existing connection found {connection.xeroAuthEventId}</p>
    <Button onClick={handleClickConnection}>Refresh Connection</Button>
    </>
}

const XeroView = (props: {
  xeroV1AuthzApi: Object,
  match: Object,
}) => {
  const {
    xeroV1AuthzApi,
    match,
  } = props;
  const platformId = match.params.pid;
  const organizationId = match.params.organizationId;
  const token = useToken();
  const [ showSelectTenantModal, setShowSelectTenantModal ] = useState();
  const [ xeroPlatforms, setXeroPlatforms ] = useState([]);
  const [ platform, setPlatform ] = useState();
  const [ tenants, setTenants ] = useState([]);
  const [ mappings, setMappings ] = useState([]);
  const [ connection, setConnection ] = useState();
  const [ tenant, setTenant ] = useState();
  const [ mapping, setMapping ] = useState();
  const [ tenantPlatformPairs, setTenantPlatformPairs ] = useState([]);
  const [ testStatus, setTestStatus ] = useState();
  const [ UIVersion, setUIVersion ] = useState(getTimestamp())

  /////////////////////////////////////////////////////////////////////
  //////////// BUILD STATE MACHINE ////////////////////////////////////
  /////////////////////////////////////////////////////////////////////

  // platforms, mappings, tenants, connectionToken, platformTenant
  const platformsConfig = {
    actions: {
      setConfiguration: assign({
        name: "platforms"
      }),
      sendUpdateToParent: sendParent(
        (context, event) => ({
          type: "UPDATE", fetcher: "platforms", ...context})
      ),
    },

    services: {
      fetch: (context, event) => getPlatforms({token, organizationId})
    },
  }
  const platformsMachine = createMachine(fetcherStateChart, platformsConfig);

  const mappingsConfig = {
    actions: {
      setConfiguration: assign({
        name: "mappings"
      }),
      sendUpdateToParent: sendParent(
        (context, event) => (
          {type: "UPDATE", fetcher: "mappings", ...context})
      ),
    },

    services: {
      fetch: (context, event) => xeroV1AuthzApi.getMappings()
    },
  }
  const mappingsMachine = createMachine(fetcherStateChart, mappingsConfig);

  const tenantsConfig = {
    actions: {
      setConfiguration: assign({
        name: "tenants"
      }),
      sendUpdateToParent: sendParent(
        (context, event) => (
          {type: "UPDATE", fetcher: "mappings", ...context})
      ),
    },

    services: {
      fetch: (context, event) => xeroV1AuthzApi.getTenants()
    },
  }
  const tenantsMachine = createMachine(fetcherStateChart, tenantsConfig);

  const connectionTokenConfig = {
    actions: {
      setConfiguration: assign({
        name: "connectionToken"
      }),
      sendUpdateToParent: sendParent(
        (context, event) => (
          {type: "UPDATE", fetcher: "mappings", ...context})
      ),
    },

    services: {
      fetch: (context, event) => xeroV1AuthzApi.selectToken()
    },
  }
  const connectionTokenMachine = createMachine(
    fetcherStateChart, connectionTokenConfig);

  const platformTenantConfig = {
    actions: {
      setConfiguration: assign({
        name: "platformTenant"
      }),
      sendUpdateToParent: sendParent(
        (context, event) => (
          {type: "UPDATE", fetcher: "mappings", ...context})
      ),
    },

    services: {
      fetch: (context, event) => promise(
        getPlatformTenant(token)(platformId)
      ),
    },
  }
  const platformTenantMachine = createMachine(
    fetcherStateChart, platformTenantConfig);

  const startObj = {
    actions: [
      assign({platformsFetcher: () => spawn(platformsMachine)}),
      send({type: "START"}, {to: (context) => context.platformsFetcher}),
      assign({mappingsFetcher: () => spawn(mappingsMachine)}),
      send({type: "START"}, {to: (context) => context.mappingsFetcher}),
      assign({tenantsFetcher: () => spawn(tenantsMachine)}),
      send({type: "START"}, {to: (context) => context.tenantsFetcher}),
      assign({connectionTokenFetcher: () => spawn(connectionTokenMachine)}),
      send({type: "START"}, {to: (context) => context.connectionTokenFetcher}),
      assign({platformTenantFetcher: () => spawn(platformTenantMachine)}),
      send({type: "START"}, {to: (context) => context.platformTenantFetcher}),
    ],
    target: "warmingUp",
  }
  const chart = makeStateChart(controlPanelStateChart)(startObj);

  const controlPanelConfig = {
    guards: {
      fetchingIsComplete: (context, event, condMeta) => {
        const expected = S.sort (["platforms", "mappings", "tenants", "connectionToken", "platformTenant"]);
        const actual = S.sort (context.completions);
        const result = S.equals (actual) (expected);
        // console.log("expected:", expected, "actual:", actual, "Result:", result);
        return result;
      },
    },
  }
  const controlPanelMachine = createMachine(chart, controlPanelConfig);
  const [ state, sendEvent ] = useMachine(controlPanelMachine);

  /////////////////////////////////////////////////////////////////////
  //////////// END STATE MACHINE //////////////////////////////////////
  /////////////////////////////////////////////////////////////////////

  const getMap = (tenant, mappings) => {
    return R.find(R.propEq("xeroTenantId", tenant.xeroTenantId))(mappings);
  }

  const getPlatform = (mapping, platforms) => {
    return R.find(R.propEq("platformId", mapping.platformId))(platforms);
  }

  const join = (pair, pairs) => {
    return R.append(pair, pairs);
  }

  const marry = (tenants, platforms, mappings) => {
    console.log("tenants", tenants);
    console.log("mappings", mappings);
    if (tenants.length === 0) {
      return [];
    } else {
      const [tenant, ...restTenants] = tenants;
      const mapping = getMap(tenant, mappings);
      if (mapping !== undefined) {
        const platform = getPlatform(mapping, platforms);
        const pair = [tenant, platform];
        const nextPairs = marry(restTenants, platforms, mappings);
        const pairs = join(pair, nextPairs);
        return pairs;
      } else {
        const pair = [tenant, null];
        const nextPairs = marry(restTenants, platforms, mappings);
        const pairs = join(pair, nextPairs);
        return pairs;
      }
    }
  }

  const onRefresh = e => {
    const newTimestamp = getTimestamp();
    setUIVersion(newTimestamp)
  }

  useEffect(
    () => {
      sendEvent({type: "START"});
    },
    [sendEvent, UIVersion],
  );

  /*

  useEffect(
    () => {
      getPlatforms({token, organizationId}).then(
        platforms_ => {
          const xeroPlatforms_ = R.filter(R.propEq("type", "xero"), platforms_);
          const platformIdentifier = S.compose (S.equals (platformId)) (S.prop ("platformId"));
          const maybePlatform = S.find (platformIdentifier) (platforms_);
          const platform_ = S.maybeToNullable (maybePlatform);
          setPlatform(platform_);
          setXeroPlatforms(xeroPlatforms_);
        }
      ).catch(
        error => {
          console.log("Error fetching platforms:", error);
        }
      );
    },
    [token, platformId, organizationId]
  );


  useEffect(
    () => {
      xeroV1AuthzApi.getTenants().then(
        tenants_ => {
          setTenants(tenants_);
        }
      ).catch(
        error => {
          console.log("Error fetching tenants:", error);
        }
      );
    },
    [xeroV1AuthzApi],
  );


  useEffect(
    () => {
      xeroV1AuthzApi.getMappings().then(
        mappings_ => {
          setMappings(mappings_);
        }
      ).catch(
        error => {
          console.log("Error fetching mappings:", error);
        }
      );
    },
    [xeroV1AuthzApi],
  );


  useEffect(
    () => {
      fork
      (error => {console.log("platformTenant error:", error)})
      (platformTenant => {
        console.log("platformTenant", platformTenant)
        const platformTenantExists = (platformTenant) => platformTenant.exists;

        const setTheTenant = (platformTenant) => setTenant(platformTenant.tenant)

        const unsetTheTenant = (platformTenant) => setTenant(null);

        S.ifElse
        (platformTenantExists)
        (setTheTenant)
        (unsetTheTenant)
        (platformTenant)
      })
      (getPlatformTenant(token)(platformId));
    },
    [token, platformId, platform],
  );

  useEffect(
    () => {
      xeroV1AuthzApi.selectToken().then(
        connectionToken => {
          setConnection(connectionToken)
        }
      ).catch(
        error => {
          console.log("Error fetching connection token", error);
        }
      )
    },
    [xeroV1AuthzApi]
  );

  */



  const refresh = () => {
    send({type: "WAITING_HAPPENED"});
    setXeroPlatforms([]);
    setTenants([]);
    setMappings([]);
    setConnection();
    setMapping();
    setShowSelectTenantModal();
    setTenantPlatformPairs([]);
    setTestStatus([]);
    let tenants, mappings, xeroPlatforms;
    Promise.allSettled([
      xeroV1AuthzApi.getMappings(),
      getPlatforms({token, organizationId})
    ]).then(
      results => {
        const [tenantsPromise, mappingsPromise, platformsPromise] = results;
        if (tenantsPromise.status === "fulfilled"){
          tenants = tenantsPromise.value;
        } else {
          throw new Error("tenants blew up")
        }
        if (mappingsPromise.status === "fulfilled") {
          mappings = mappingsPromise.value || [];
          console.log("mappings923", mappings);
        } else {
          throw new Error("mappings blew up")
        }
        if (platformsPromise.status === "fulfilled") {
          const platforms = platformsPromise.value || [];
        } else {
          throw new Error("platforms blew up")
        }
        const pairs = marry(tenants, xeroPlatforms, mappings);
        setTenantPlatformPairs(pairs);
        const mapping = R.find(R.propEq("platformId", platform.platformId))(mappings);
        console.log("mapping 32", mapping);
        if ( mapping !== undefined ) {
          setMapping(mapping);
        }
        send({type: "SUCCESS_HAPPENED"});
      }
    ).catch(
      error => {
        console.log("failed 38439266", error);
        send({type: "FAILURE_HAPPENED"});
      }
    );
  }



  const handleSelectTenant = (tenant) => {
    xeroV1AuthzApi.insertMapping({
      platformId: platform.platformId,
      xeroTenantId: tenant.xeroTenantId,
    }).then(
      tenantMapping => {
        console.log("created tenant mapping", tenantMapping);
        refresh();
      }
    ).catch(
      error => {
        alert(`insertMapping blew up, ${error}`)
        setShowSelectTenantModal(false);
      }
    );
  }

  /*
  const selectTenants = () => {
    try {
    if ( showSelectTenantModal === true ) {
      return (
        <SelectTenantModal
          tenantPlatformPairs={tenantPlatformPairs}
          onSelect={handleSelectTenant}
          onClose={() => setShowSelectTenantModal(false) }
        />
      );
    } else {
      return null;
    }
    } catch (error) {
      console.log("tenantPlatformPairs", tenantPlatformPairs);
      console.log("error 92342", error);
    }
  }
  */

  /*
  if (state.value === "ready"){
    const { results } = state.context;
    const {tenants, mappings, platforms, connectionToken, platformTenant} = results;

    const xeroPlatforms = R.filter(R.propEq("type", "xero"), platforms);
    setXeroPlatforms(xeroPlatforms);

    console.log("Platform: ", platform);
    setPlatform(platform);
    setTenants(tenants);
    setMappings(mappings);
    setConnection(connectionToken);

    console.log("platformTenant", platformTenant)

  }
  */

  const makePlatform = state => platformId => {
    const platforms = state.context.results.platforms;
    const platformIdentifier = S.compose (S.equals (platformId)) (S.prop ("platformId"));
    const maybePlatform = S.find (platformIdentifier) (platforms);
    const platform = S.maybeToNullable (maybePlatform);
    return platform;
  }

  const makeTenant = state => {
    const platformTenant = state.context.results.platformTenant;
    const platformTenantExists = (platformTenant) => platformTenant.exists;
    const returnTheTenant = (platformTenant) => platformTenant.tenant
    const returnNull = (platformTenant) => null;
    return S.ifElse
    (platformTenantExists)
    (returnTheTenant)
    (returnNull)
    (platformTenant)
  }

  const makeMapping = state => platformId => {
    const mappings = state.context.results.mappings;
    const mapping = R.find(R.propEq("platformId", platformId))(mappings);
    return mapping;
  }


  /*
  return state.value === "ready"
  ? <ReadyWidget data={state.context} />
  : state.value === "warmingUp"
  ? <p>Warming up</p>
  : state.value === "failed"
  ? <p>Failed</p>
  : state.value === "off"
  ? <p>Off</p>
  : <p>Unknown state</p>;
  */

  console.log("xero state machine context: ", state.context);
  return state.value === "ready"
  ? el(
    View,
    {
      connectionToken: state.context.results.connectionToken,
      platform: makePlatform(state)(platformId),
      xeroV1AuthzApi,
      tenant: makeTenant(state),
      mapping: makeMapping(state)(platformId),
      tenants: state.context.results.tenants,
      mappings: state.context.results.mappings,
      platforms: state.context.results.platforms,
      onRefresh,
    }
  )
  : state.value === "warmingUp"
  ? <Spinner height="50" />
  : state.value === "failed"
  ? <p>Oops</p>
  : <p>Unknown state</p>
}

const View = (props: {}) => {
  const {
    xeroV1AuthzApi,
    connectionToken,
    platform,
    tenant,
    mapping,
    tenants,
    mappings,
    platforms,
    onRefresh,
  } = props;


  return <>
    <h2>Xero Platform</h2>
    <hr/>
    <h3>Name</h3>
    <p>{platform.name}</p>
    <hr/>
    <h3>Connection</h3>
    <ConnectionWidget
      connection={connectionToken}
      xeroV1AuthzApi={xeroV1AuthzApi}
    />
    <hr/>
    <h3>Tenant</h3>
    <TenantWidget
      xeroV1AuthzApi={xeroV1AuthzApi}
      tenant={tenant}
      connection={connectionToken}
      mapping={mapping}
      platform={platform}
      tenants={tenants}
      mappings={mappings}
      platforms={platforms}
      onRefresh={onRefresh}
    />
    <hr/>
    <h3>Tenant Mapping</h3>
    <TenantMapping
      tenantMapping={mapping}
      platform={platform}
      tenants={tenants}
    />
  </>
}

export {
  XeroView,
}

export default withRouter(withXeroV1AuthzApi(XeroView));
