// @flow
import { createElement as el } from "react";
import type {PaymentMethodLink } from "./../../../Entity/Types.js";
import { View } from "./view.js";

const Controller = (props: {paymentMethodLinks: Array<PaymentMethodLink>}) => {
  const {
    paymentMethodLinks,
  } = props;
  return el(
    View,
    {
      paymentMethodLinks,
    },
  )

}
export {
  Controller,
}
