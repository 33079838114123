// @flow
import React, { useState } from "react";
import ReactTable from "react-table-6";
import Button from "react-bootstrap/Button";
import { TrashIcon } from "@primer/octicons-react";
import EntriesModalDelete from "../EntriesModalDelete";

import { OrganizationProfileWidget } from "./../../../../Widgets/OrganizationProfile";
import { type AccessListEntry } from "../../entity.js";

const DeleteEntry = (props: {
  entry: string,
  owner: string,
  list: string,
}) => {
  const {
    owner,
    list,
    entry,
  } = props;
  const [showModal, setShowModal] = useState(false);

  return showModal ?
    <EntriesModalDelete owner={owner} list={list} entry={entry} onDone={() => {setShowModal(false)}}/> :
    <Button value={entry} onClick={e => setShowModal(true)}><TrashIcon /> Delete</Button>;
}

const View = (props: {
  owner: string,
  list: string,
  entries: Array<AccessListEntry>,
}) => {
  const {
    owner,
    list,
    entries,
  } = props;
  console.log("entries:", entries);

  const columns = [
    {
      Header: "Sender",
      accessor: "sender",
      Cell: ({row, value}) => {
        const addressProfile = row._original.addressProfile;
        const organizationProfile = addressProfile.organizationProfile;
        const organizationWidget= !!organizationProfile ?
          <OrganizationProfileWidget organizationProfile={organizationProfile} /> : null;
        const addressName = !!addressProfile.displayName ?  addressProfile.displayName: "NOT-KNOWN";
        return <>{organizationWidget} - {addressName}</>
      },
    },
    {
      Header: "Actions",
      accessor: "entry",
      Cell: ({value}) => {
        console.log("value of entry: ", value);
        return (<DeleteEntry owner={owner} list={list} entry={value} />);
      },
    },
  ]

  return (
    <ReactTable
      className="-striped -highlight"
      data={entries}
      columns={columns}
      defaultPageSize={10}
    />
  )
}

export { View as default };
