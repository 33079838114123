// @flow

import React from "react"
import Nav from "react-bootstrap/Nav"
import {
  Link,
  withRouter,
} from "react-router-dom"
import log from "loglevel";
import {
  ImageIcon,
  KeyIcon,
  LockIcon,
  MentionIcon,
  NumberIcon,
  OrganizationIcon,
  PeopleIcon,
  PlugIcon,
  TagIcon,
  VerifiedIcon,
} from "@primer/octicons-react";

const logger = log.getLogger(":Organization:OrganizationNavbar");

const OrganizationNavbarPresenter = (props: {
  match: Object,
  location: Object,
}) => {
  const {
    match,
    location,
  } = props;

  const oid = match.params.organizationId;

  const about_url = `/m/organizations/${oid}`;
  const addresses_url = `/m/organizations/${oid}/addresses`;
  const team_url = `/m/organizations/${oid}/team`;
  const platforms_url = `/m/organizations/${oid}/platforms`;
  const content_types_url = `/m/organizations/${oid}/content-types`;
  const organization_proxies_url = `/m/organizations/${oid}/organization-proxies`;
  const icon_url = `/m/organizations/${oid}/icon`;
  const pki_url = `/m/organizations/${oid}/pki`;
  const identify_url = `/m/organizations/${oid}/identify`;
  const billing_url = `/m/organizations/${oid}/billing`;

  const selectActiveKey = () => {
    logger.debug("[OrganizationNavbar] pathname:", location.pathname);
    switch (location.pathname) {
      case about_url:
        return about_url;
      case addresses_url:
        return addresses_url;
      case content_types_url:
        return content_types_url;
      case team_url:
        return team_url;
      case organization_proxies_url:
        return organization_proxies_url;
      case icon_url:
        return icon_url;
      case pki_url:
        return pki_url;
      case identify_url:
        return identify_url;
      case billing_url:
        return billing_url;
      default:
        return about_url;
    }
  }

  return (
    <Nav
      variant="tabs"
      activeKey={selectActiveKey()}
    >
      <Nav.Item>
        <Nav.Link
          as={Link}
          href={about_url}
          to={about_url}
        ><OrganizationIcon/> Organization</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          href={addresses_url}
          to={addresses_url}
        ><MentionIcon/> Addresses</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          href={team_url}
          to={team_url}
        ><PeopleIcon/> Team</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          href={content_types_url}
          to={content_types_url}
        ><TagIcon/> Content types</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          href={organization_proxies_url}
          to={organization_proxies_url}
        ><KeyIcon/> Proxies</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          href={icon_url}
          to={icon_url}
        ><ImageIcon/> Icon</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          href={identify_url}
          to={identify_url}
        ><VerifiedIcon/> Verification</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          href={pki_url}
          to={pki_url}
        ><LockIcon/> Encryption</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          href={billing_url}
          to={billing_url}
        ><NumberIcon/> Billing</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          href={platforms_url}
          to={platforms_url}
        ><PlugIcon/> Platforms</Nav.Link>
      </Nav.Item>
    </Nav>
  )
}

export const OrganizationNavbar = withRouter(OrganizationNavbarPresenter);
