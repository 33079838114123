// @flow
import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import {
  copyToClipboard,
} from "./../../../Util/copy.js";


import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "./../../../Util";
import { CenteredSpinner } from "./../../../Widgets/Toast.js";

const View = (props: {
  publicKeyData: string,
  display: string,
}) => {
  const {
    publicKeyData,
    display,
  } = props;

  const input = display === INPUT ? (
    <p>Ready to fetch profile</p>
  ): null;

  const waiting = display === WAITING ? (
    <CenteredSpinner />
  ): null;

  const success = display === SUCCESS && !! publicKeyData? (
    <>
    <Button onClick={e => copyToClipboard(publicKeyData)}>Copy</Button>
    <Form.Control rows={10} as="textarea" readOnly value={publicKeyData}></Form.Control>
    </>
  ): null;

  const failure = display === FAILURE ? (
    <p>No public key available.</p>
  ): null;

  return (
    <>
    <h2>Organization Public Key</h2>
    <hr/>
    {input}
    {waiting}
    {success}
    {failure}
    </>
  );
}

export {
  View,
}
