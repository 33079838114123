// @flow
import React, {
  useState,
  useEffect,
} from "react";
import {
  withRouter,
  Link,
} from "react-router-dom";
import * as S from "sanctuary";
import * as $ from "sanctuary-def";

import {
  withKlondikeApi,
} from "../Api/Klondike";

const WAITING = "waiting";
const SUCCESS = "success";
const FAILURE = "failure";


const Address = (props) => {
  const {
    id,
  } = props;

  const hasAddress = id => S.not (S.is ($.Undefined) (id))

  return hasAddress(id)
  ? <Link to={`/profiles/address/${id}`}>{id}</Link>
  : null;
}

const Message = (props) => {
  const {
    id,
  } = props;

  const hasAddress = id => S.not (S.is ($.Undefined) (id))

  return hasAddress(id)
  ? <Link to={`/messages/${id}`}>{id}</Link>
  : null;
}

const UnaddressedMessageView_ = (props: {
  klondikeApi: Object,
  match: Object,
}) => {
  const {
    klondikeApi,
    match,
  } = props;
  const unaddressedMessageId = match.params.unaddressedMessageId;
  const addressId = match.params.addressId;
  const [unaddressedMessage, setUnaddressedMessage] = useState();
  const [display, setDisplay] = useState(WAITING);

  const refresh = async () => {
    setDisplay(WAITING);
    const unaddressedMessage = await klondikeApi.getUnaddressedMessage({
      addressId,
      unaddressedMessageId,
    });
    setUnaddressedMessage(unaddressedMessage);
    setDisplay(SUCCESS);
  }

  useEffect(
    () => { refresh() },
    [],
  )

  const waiting = display === WAITING ? (
    <p>Waiting ...</p>
  ): null ;

  const success = display === SUCCESS && !! unaddressedMessage ? (
    <>
    <h2>Unaddressed Message</h2>
    <table className="table table-striped table-hover">
      <tbody>
      <tr>
        <th>Unaddressed Message Id:</th>
        <td>{unaddressedMessage.unaddressedMessageId}</td>
      </tr>
      <tr>
        <th>Received At:</th>
        <td>{unaddressedMessage.receivedAt}</td>
      </tr>
      <tr>
        <th>From Address Id:</th>
        <td><Link to={`/profiles/address/${unaddressedMessage.fromAddressId}`}>{unaddressedMessage.fromAddressId}</Link></td>
      </tr>
      <tr>
        <th>Local Account:</th>
        <td>{unaddressedMessage.localAccount}</td>
      </tr>
      <tr>
        <th>Message Type</th>
        <td>{unaddressedMessage.messageType}</td>
      </tr>
      <tr>
        <th>Message Content:</th>
        <td>{unaddressedMessage.messageContent}</td>
      </tr>
      <tr>
        <th>Summary:</th>
        <td>{unaddressedMessage.summary}</td>
      </tr>
      <tr>
        <th>Sender Reference:</th>
        <td>{unaddressedMessage.senderReference}</td>
      </tr>
      <tr>
        <th>Checksum:</th>
        <td>{unaddressedMessage.checksum}</td>
      </tr>
      <tr>
        <th>checksumAlgorithm:</th>
        <td>{unaddressedMessage.checksumAlgorithm}</td>
      </tr>
      <tr>
        <th>messageToken:</th>
        <td>{unaddressedMessage.messageToken}</td>
      </tr>
      <tr>
        <th>To Address Id:</th>
        <td><Address id={unaddressedMessage.toAddressId} /></td>
      </tr>
      <tr>
        <th>Claimed At:</th>
        <td>{unaddressedMessage.claimedAt}</td>
      </tr>
      <tr>
        <th>Martlet Submission Status:</th>
        <td>{unaddressedMessage.martletSubmissionStatus}</td>
      </tr>
      <tr>
        <th>Martlet Message Id:</th>
        <td><Message id={unaddressedMessage.martletMessageId} /></td>
      </tr>
      <tr>
        <th>Martlet Comments:</th>
        <td>{unaddressedMessage.martletComments}</td>
      </tr>
      <tr>
        <th>Martlet Submitted At:</th>
        <td>{unaddressedMessage.martletSubmittedAt}</td>
      </tr>
      </tbody>
    </table>
    </>
  ): null ;

  return (
    <>
    {waiting}
    {success}
    </>
  );
}

const UnaddressedMessageView = withRouter(withKlondikeApi(UnaddressedMessageView_));

export {
  UnaddressedMessageView,
}
