// @flow

import React, {
  createElement as el,
  useEffect,
} from "react";

import * as S from "sanctuary";
import * as $ from "sanctuary-def";
import * as R from "ramda";

import {
  assign,
  createMachine,
  send,
} from "xstate";
import {
  useMachine,
} from "@xstate/react";

import {
  AuthenticatedTenant,
  Platform,
  TenantMapping,
} from "./../../../Entity/Types.js";
import {
  TenantMappingShow,
} from "./TenantMappingShow.js";
import {
  TenantMappingDelete,
} from "./TenantMappingDelete.js";
import {
  TenantMappingCreate,
} from "./TenantMappingCreate.js";
import {
  getMappings,
} from "./../../../Api/XeroV1Authz/Mappings.js";
import {
  useToken,
} from "./../../../State";

const makeMapping = mappings => platformId => {
  const mapping = R.find(R.propEq("platformId", platformId))(mappings);
  return mapping;
}

const tenantMappingOK = tenantMapping => {
  const isNull = S.is ($.Null) (tenantMapping);
  const isUndefined = S.is ($.Undefined) (tenantMapping);
  const isNullOrUndefined = S.or (isNull) (isUndefined);
  return S.not (isNullOrUndefined);
}

const stateChart = {
  id: "tenant mapping widget",
  context: {},
  initial: "idle",
  states: {
    idle: {
      on: {
        START: "working",
      }
    },
    working: {
      invoke: {
        src: "fetchData",
        onDone: {
          actions: ["updateContext", send("INITIALIZED") ],
        },
        onError: "failed",
      },
      on: {
        INITIALIZED: "ready",
      },
    },
    ready: {
      on: {
        REFRESH: {
          target: "working",
        }
      },
    },
    failed: {
      on: {
        REFRESH: {
          target: "working",
        }
      },
    }
  },

}

const options = {
  actions: {
    "updateContext": assign(
      (context, event) => {
        const tenantMappings = event.data ;
        const platformId = context.platformId;
        const tenantMapping = makeMapping(tenantMappings)(platformId);
        return { tenantMapping };
      }
    ),
  },

  services: {
    fetchData: (context, event) => getMappings({
      token: context.token,
    }),
  }
}

const Controller = (props: {
  platform: Platform,
  tenants: Array<AuthenticatedTenant>,
}) => {

  const {
    platform,
    tenants,
  } = props;

  const context = {
    token: useToken(),
    platformId: platform.platformId,
  }

  const machine = createMachine(stateChart, options).withContext(context);
  const [ state, sendEvent ] = useMachine(machine);

  useEffect(
    () => {
      sendEvent("START");
    },
    [sendEvent]
  );

  const handleUpdate = () => {
    sendEvent("REFRESH");
  }


  const tenantMappingView = el(
    ViewWithTenantMapping,
    {
      callback: handleUpdate,
      tenantMapping: state.context.tenantMapping,
    },
  );

  const noTenantMappingView = el(
    ViewNoTenantMapping,
    {
      callback: handleUpdate,
      platform: platform,
      tenants: tenants,
    }
  );

  return tenantMappingOK(state.context.tenantMapping)
  ? tenantMappingView
  : noTenantMappingView;
}


const ViewWithTenantMapping = (props: {
  callback: Function,
  tenantMapping: TenantMapping,
}) => {
  return <>
    <TenantMappingShow tenantMapping={props.tenantMapping} />
    <TenantMappingDelete
      tenantMapping={props.tenantMapping}
      onUpdate={props.callback}
    />
  </>;
}

const ViewNoTenantMapping = (props: {
  callback: Function,
  tenants: Array<AuthenticatedTenant>,
  platform: Platform,
}) => {
  return <TenantMappingCreate
    onUpdate={props.callback}
    tenants={props.tenants}
    platform={props.platform}
    />
}


export {
  Controller,
  Controller as TenantMapping,
  ViewWithTenantMapping,
  ViewNoTenantMapping,
}
