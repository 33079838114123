// @flow
import Future from "fluture";

import {
  ApiClient,
  OrganizationsApi,
} from "@bmbix/bmb_martlet_organization_client";


import { callBack } from "./../../Api/CallBack";
import { createBillingAddressFromGW } from "./adapt";


const getCurrentBillingAddress = (args: {
  token: string,
  organizationId: string,
}) => {
  const {
    token,
    organizationId,
  } = args;

  const defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new OrganizationsApi(defaultClient);

  const dataHandler = data => {
    const billingAddressGW = data.billing_address;
    const billingAddress = createBillingAddressFromGW(billingAddressGW);
    return billingAddress;
  }

  return Future((reject, resolve) => {
    apiInstance.getCurrentBillingAddress(
      organizationId,
      callBack(reject, resolve)(dataHandler),
    )
    return console.log;
  })

}

export {
  getCurrentBillingAddress,
}
