// @flow
import React from "react";
import {
  Route, Switch, useRouteMatch,
} from "react-router-dom";

import {
  IndexPage,
  MessagePage,
} from "./Widgets";

const View = (props: {
}) => {
  const match = useRouteMatch();

  return (
    <Switch>
    <Route
      path={`${match.path}/:messageId`}
    >
      <MessagePage/>
    </Route>
    <Route
      path={`${match.path}`}
    >
      <IndexPage />
    </Route>
    </Switch>
  )
}
export { View } ;
