// @flow
import {
  createElement as el,
  useEffect,
} from "react";

import { createMachine, assign } from "xstate";
import { useMachine } from "@xstate/react";

import { useAccount } from "./../../../State/Account.js";
import { useToken } from "./../../../State/Token.js";
import { createPaymentMethodFromStripeIntent } from "./../../../Api/MartletOrganization/Users.js";

import { View } from "./view.js";
/*
 * This is what we get as our props.
 *
 *
 *
 * If success then use the secret to give to the backend to fetch the
 * setupIntent, and get the successful payment_method from it.
 * Then record it in the:
 * stripe table and then
 * the payment_method tebal.
{
  "history": {
    "length": 25,
    "action": "POP",
    "location": {
      "pathname": "/a/payment-gateways/stripe/setup-status",
      "search": "?setup_intent=seti_1NFErzBDzG9tAfYoDgthihb0&setup_intent_client_secret=seti_1NFErzBDzG9tAfYoDgthihb0_secret_O1HQvHMpcjseJBqwp049eAzbEJU42ah&redirect_status=succeeded",
      "hash": ""
    }
  },
  "location": {
    "pathname": "/a/payment-gateways/stripe/setup-status",
    "search": "?setup_intent=seti_1NFErzBDzG9tAfYoDgthihb0&setup_intent_client_secret=seti_1NFErzBDzG9tAfYoDgthihb0_secret_O1HQvHMpcjseJBqwp049eAzbEJU42ah&redirect_status=succeeded",
    "hash": ""
  },
  "match": {
    "path": "/a/payment-gateways/stripe/setup-status",
    "url": "/a/payment-gateways/stripe/setup-status",
    "isExact": true,
    "params": {}
  }
}
*/



const apiMachineSpecification = {
  id: "MartletOrganizationApi",
  initial: "unconfigured",
  context: {
    token: undefined,
    userId: undefined,
    setupIntent: undefined,
    errors: undefined,
    paymentMethod: undefined,
  },
  states: {
    unconfigured: {
      on: {
        CONFIGURE: {
          target: "idle",
          actions: assign({
            setupIntent: (c, e, m) => e.setupIntent,
            userId: (c, e, m) => e.userId,
            token: (c, e, m) => e.token
          }),
        }
      },
    },
    idle: {
      on: {
        START: "working",
      }
    },
    working: {
      invoke: {
        id: "createPaymentMethod",
        src: (c, e) => createPaymentMethodFromStripeIntent({ token: c.token, userId: c.userId, setupIntent: c.setupIntent, }),
        onDone: {
          target: "succeeded",
          actions: assign({
            paymentMethod: (c, e) => e.data,
          })
        },
        onError: {
          target: "failed",
          actions: assign({
            errors: (c, e) => e.data,
          })
        },
      },
    },
    succeeded: {
      on: {
        RESET: "idle",
      },
    },
    failed: {
      on: {
        RESET: "idle",
      },
    },
  },
};
const apiMachine = createMachine(apiMachineSpecification);
const Controller = (props: {}) => {
  console.log("stripe-setup-status: ", props);
  const token = useToken();
  const account = useAccount()
  const userId = account.id;
  const [ state, send ] = useMachine(apiMachine);

  console.log("stripe-post-redirect: ", state);

  useEffect(
    () => {
      const search = new URLSearchParams(window.location.search);
      // const clientSecret = search.get( 'setup_intent_client_secret');
      const setupIntent = search.get( 'setup_intent');
      // const redirectStatus = search.get( 'redirect_status');

      send({type: "CONFIGURE", token, userId, setupIntent});
      send({type: "START"});

    },
    [send, token, userId],
  );

  return state.value === "succeeded"
  ? el(
    View,
    {
      paymentMethod: state.context.paymentMethod,
    },
  )
  : el ("p", {}, ["not in succeeded state",]);

}


export {
  Controller,
}
