// @flow
import { createElement as el }from "react";

import {
  type BmbixAddress,
} from "../../Entity/Types.js";

import { View } from "./View.js";

const Controller = (props: {
  addresses?: Array<BmbixAddress>,
  selectedAddressId?: string,
  messageToken?: string,
  onMessageTokenChange: Function,
  onAddressChange?: Function,
}) => {

  const {
    addresses,
    messageToken,
    onMessageTokenChange,
    onAddressChange,
  } = props;

  return el(
    View,
    {
      addresses,
      messageToken,
      onMessageTokenChange,
      onAddressChange,
    }
  )
}

export {
  Controller,
}
