import * as R from "ramda";

const hashCode = (aString: string): string => {
    var hash = 0;
    if (aString.length == 0) {
        return hash.toString();
    }
    for (var i = 0; i < aString.length; i++) {
        var charCode = aString.charCodeAt(i);
        hash = ((hash<<5)-hash)+charCode;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash.toString();
}

/**
 *
  * Given a list of objects, create a dictionary (ie a JS object) based on one
  * attribute (ie a JS property) of the objects in the list.
  *
  * @param property func eg R.prop("id")
  * @param objects
  * objFromListWith("id", [obj1, obj2,... ])
  * returns {id1: obj1, id2: obj2 .... }
 */
const objFromListWith = R.curry((fn, list) => R.chain(R.zipObj, R.map(fn))(list))
const objFromListOnProp = R.curry((prop, list) => R.chain(R.zipObj, R.map(R.prop(prop)))(list))

/**
 * Given a list of checked items and an event (item, isChecked), return
 * a new list accordingly
 *
 */
const updateCheckedItems = (checkedItems, item, isChecked) => {
  const itemSet = new Set(checkedItems);
  if(isChecked){
    itemSet.add(item);
  } else {
    itemSet.delete(item);
  }
  const result = Array.from(itemSet);
  return result;
}


/**
 * Given a list of objects and a list of ids indicating whether the objects
 * are checked, return a new list of objects with a checked property set
 * appropriately.
 */
const addCheckedProperty= (entities, checkedItems, keyName) => {
  let keyName_ = keyName;
  if(keyName_ === undefined){
    keyName_ = "id";
  }
  const f = (entity) => {
    const checked = R.contains(entity[keyName_], checkedItems)
    const result = Object.assign({checked: checked}, entity);
    return result;
  }
  const result = R.map(f, entities);
  return result;
}

const formatter = new Intl.NumberFormat(
  "en-UK",
  {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
);

const fmt = (amount: string): string=> {
  return formatter.format(Number(amount));
}


const INPUT = "INPUT"
const WAITING = "WAITING"
const SUCCESS = "SUCCESS"
const FAILURE = "FAILURE"

export {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
  hashCode,
  objFromListWith,
  objFromListOnProp,
  updateCheckedItems,
  addCheckedProperty,
  fmt,
}
export * from "./address";
export * from "./selection";
export * from "./bri.js";
export * from "./sync.js";
export {
  decorateAddress,
  getPlatformAssignment,
  getPlatform,
  getPlatformFromAddress,
  makeObject
} from "./platform.js";
