// @flow
import Future from "fluture";
import * as S from "sanctuary";

import {
  ApiClient,
  AddressesApi
} from "@bmbix/bmb_martlet_organization_client";

import { gwToAccessList } from "./adapt.js";

const getLists = (token: string) => (owner: string): Future => {

  const apiInstance = new AddressesApi();
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;

  return Future((reject, resolve) => {
    console.log("token", token);
    apiInstance.listAccessLists(owner, (error, data, response) => {
      if(error){
        reject(error);
      } else {
        const accessListsResponseGW = data.access_lists;
        const accessLists = S.map (gwToAccessList) (accessListsResponseGW);
        resolve(accessLists)
      }
    });
    return console.log
  });
}

export { getLists }
