// @flow
import {
  createElement as el,
  useState,
} from "react";
import log from "loglevel";

import {
  type Account,
} from "../../../Entity/Types.js";
import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "../../../Util";
import {
  View,
} from "./View.js";

import { setUserCertificate } from "./../../../Api/MartletOrganization/Certificates";

import { useToken } from "./../../../State";


const logger = log.getLogger("UploadUserCertificate");

const Controller_ = (props: {
  onHide: Function,
  account: Account,
}) => {
  const {
    onHide,
    account,
  } = props;
  const userId = account.id;
  const [display, setDisplay] = useState(INPUT);
  const [file, setFile] = useState();
  const token = useToken();

  const onFileChange = file => {
    setFile(file);
    logger.debug("file:", file);
  }

  const onSubmit = e => {
    if (!!file) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const data_ = fileReader.result;
        logger.debug("data_: ", data_);
        if (!!data_) {
          setDisplay(WAITING);
          try {
          setUserCertificate({
            token,
            userId: userId,
            data: Buffer.from(data_).toString("base64"),
            mediaType: file.type,
          }).then(
            null_ => {
              setDisplay(SUCCESS);
              logger.debug("Set certificate: ", null_);
            }
          ).catch(
            error => {
              setDisplay(FAILURE);
              logger.debug("Failure when storing certificate");
            }
          );
        } catch (e) {
          logger.debug("Error setting user certificate");
          setDisplay(FAILURE)
        }
        } else {
          logger.debug("No data from file to send");
        }
      }
      fileReader.readAsArrayBuffer(file);
    } else {
      logger.debug("No file to send");
    }
  }

  const onCancel = e => {
    setDisplay(INPUT);
    onHide();
  }
  const onSuccessComplete = onCancel;
  const onFailureComplete = onCancel;

  return el(
    View,
    {
      display,
      onFileChange,
      onSubmit,
      onCancel,
      onSuccessComplete,
      onFailureComplete,
    }
  );
}

const Controller = Controller_;

export {
  Controller,
}
