// @flow
import React from "react";
import * as S from "sanctuary";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import type { Organization } from "./../../../Entity/Types.js";
import { useOrganizations } from "./../../../State/Organizations.js";
import {
  useForm,
} from "react-hook-form";


const Options = ({
  organizations,
}: {
  organizations: Array<Organization>,
}) => {
  return <>
    {S.map (o => <option key={o.id} value={o.id}>{o.name}</option>) (organizations)}
    </>
}

const View = (
  {
    apiState,
    onModalSubmit,
    onModalCancel,
  }: {
    apiState: Function,
    onModalSubmit: Function,
    onModalCancel: Function,
  }) => {

  console.log("apiState: ", apiState);

  const organizations = useOrganizations();

  const {
    register,
    handleSubmit,
    formState: {
      errors,
    },
  } = useForm({
  });


  return (apiState.value === "ready" || apiState.value === "unconfigured")
  ? <Modal show={true}>
    <Modal.Body>
      <Form onSubmit={handleSubmit(onModalSubmit)}>
      <Form.Control as="select" {...register("organizationId", {required: true})}>
      <Options organizations={organizations} />
      </Form.Control>
      {errors.organizationId && <span>This field is required</span>}
      <Button type="submit">Submit</Button>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={onModalCancel}>Cancel</Button>
    </Modal.Footer>
    </Modal>
  : apiState.value === "working"
  ? <p>Working ...</p>
  : apiState.value === "success"
  ? <p>Success!</p>
  : <p>Failure</p>

}


export {
  View,
}

