// @flow
import React, {
  useState,
} from "react";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {
  useParams,
} from "react-router-dom";
import * as S from "sanctuary";
import {
  TrashIcon,
  PlusIcon,
  SyncIcon,
} from "@primer/octicons-react"

import {
  PermissionsTable,
} from "./../table/view.js";
import {
  AddModal,
} from "./../add-modal/controller.js";
import {
  PermissionsDeleteModal as DeleteModal,
} from "./../delete-modal/view.js";
import {
  getUserIds,
  permissionsMap,
  unwrap,
  userProfilesMap,
  zipByKey,
} from "./../../../../Organization/EnrichPermissionsLogic.js";
import { usePermissionsByAddress } from "./../../../../State/Permissions.js";
import {
  useUserProfiles,
} from "./../../../../State/UserProfiles.js";
import {
  useAddress,
} from "./../../../../State/Addresses.js";

const READY = "ready";
const WAITING = "waiting";
const SUCCESS = "success";
const FAILURE = "failure";

const View_ = (props: {}) => {
  const {
    addressId,
    organizationId,
  }  = useParams();
  const address = useAddress(addressId);
  const [display, setDisplay] = useState(READY);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const [enrichedPermissions, setEnrichedPermissions] = useState([]);
  const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);

  const permissions = usePermissionsByAddress(addressId);
  const userIds = getUserIds(permissions);
  const userProfiles = useUserProfiles(userIds);
  const upm = userProfilesMap(userProfiles)
  const pm = permissionsMap(permissions)
  const enrichedPermissions = S.justs (S.map (unwrap) (zipByKey(pm, upm)));
  console.log("enriched permissions", enrichedPermissions);
  // setEnrichedPermissions(enrichedPermissions_);


  const refresh = async () => {
    setDisplay(WAITING);
    /*
    const permissions = await martletOrganizationApi.getPermissionsForAddress({
      organizationId,
      addressId,
    });
    const userIds = getUserIds(permissions);
    console.log("userIds:", userIds);
    const future = fetchUserProfiles(token)(userIds);
    fork(error => {
      console.log("Boom:", error);
    })(userProfiles => {
      console.log("Huzzah:", userProfiles)
      const upm = userProfilesMap(userProfiles)
      const pm = permissionsMap(permissions)
      const enrichedPermissions = S.justs (S.map (unwrap) (zipByKey(pm, upm)));
      console.log("enriched permissions", enrichedPermissions);
      setEnrichedPermissions(enrichedPermissions);
    })(future)
    setDisplay(READY);
    */
  }

  /*
  useEffect(
    () => {refresh()},
    [],
  )
  */

  /*
  useEffect(
    () => {
      dispatch(fetchUserProfilesThunk(token)(userIds));
    },
    [dispatch, userIds, userProfiles, token]
  );
  */

  const deleteModal = showDeleteModal ? (
    <DeleteModal
      organizationId={organizationId}
      addressId={addressId}
      permissionIds={selectedPermissionIds}
      onSuccess={() => {
        setShowDeleteModal(false);
        refresh();
      }}
      onCancel={() => {setShowDeleteModal(false)}}
      onFailure={() => {setShowDeleteModal(false)}}
    />
  ): null;

  const addModal = showAddModal ? (
    <AddModal
      address={address}
      onHide={
        e => {
          setShowAddModal(false);
          refresh();
        }
      }
    />
  ): null;

  const ready = display === READY ? (
    <>
    <ButtonToolbar className="float-right" >
      <AddModal addressId={addressId} organizationId={organizationId}/>
    </ButtonToolbar>
    <h2>Address Team</h2>
    <Button className="mr-2" onClick={() => setShowDeleteModal(true) }><TrashIcon /> Delete</Button>
    <Button className="mr-2" onClick={refresh}><SyncIcon /> Refresh</Button>
    <PermissionsTable
      permissions={enrichedPermissions}
      onChange={permissionIds => setSelectedPermissionIds(permissionIds)}
      checked={selectedPermissionIds}
    />
    </>
  ): null;

  const waiting = display === WAITING ? (
    <>
    <p>Waiting ...</p>
    </>
  ): null;

  return (
    <>
    {ready}
    {waiting}
    {deleteModal}
    </>
  );
}

const findAddress = (addressId, addresses) => {
  return S.maybeToNullable (S.head ( S.filter (x => x.id === addressId) (addresses)));
}

/*
const connector = connect(
  (state, ownProps) => {
    const addressId = ownProps.match.params.addressId;
    return {
      token: state.token,
      address: findAddress(addressId, state.addresses),
    }
  }
)

const PermissionsView = withRouter(withMartletOrganizationApi(connector(View_)));
*/

export {
  View_ as PermissionsView,
}
