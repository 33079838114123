// @flow
import * as S from "sanctuary";

import { type DecoratedAddress } from "./../Organization/types.js";

export const getPlatformAssignment =
  platformAssignments =>
  address =>
  S.find (pa => pa.addressId === address.id) (platformAssignments)


export const getPlatform =
  platforms_ =>
  platformAssignment_ =>
  S.find (p => p.platformId === platformAssignment_.platformId) (platforms_)


export const getPlatformFromAddress =
  paGetter =>
  plGetter =>
  address =>
  S.chain (plGetter) (paGetter(address))


export const makeObject =
  address_ =>
  platform_ => {
    // console.log("makeObject", address_, platform_);
    return Object.assign({...address_}, {platform: platform_})
  }

export const makeDecoratedAddress = makeObject;


const c = f => {
  const x = args => {
    const result = f(args)
    return S.Just(result)
  }
  return x
}


export const decorateAddress =
  (platformAssignments) =>
  (platforms) =>
  (address_): DecoratedAddress => {
  const maybePlatformAssignment =
      getPlatformAssignment(platformAssignments)(address_);
  const maybePlatform = S.chain (getPlatform(platforms)) (maybePlatformAssignment);

  const platform = S.fromMaybe ({}) (maybePlatform)
  const result = makeObject(address_) (platform)

  return result

}
