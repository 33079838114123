// @flow
import Future from "fluture";
import {
  ApiClient,
  AddressesApi,
} from "@bmbix/bmb_martlet_organization_client";
import { gwToAccessListEntry } from "./adapt.js";

const removeEntry = (token: string) => (owner: string) => (list: string) => (entry: string): Future => {

  const apiInstance = new AddressesApi();
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;

  return Future((reject, resolve) => {
    console.log("token", token);
    apiInstance.deleteAccessListEntry(owner, list, entry,(error, data, response) => {
      if (error) {
        console.log("error", error);
        reject(error);
      } else {
        const gw = data.access_list_entry;
        const accessListEntry = gwToAccessListEntry(gw);
        resolve(accessListEntry);
      }
    });
    return console.log
  });
}

export { removeEntry }
