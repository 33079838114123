// @flow
import log from "loglevel";

import { store } from "./State/Store.js";
import { addBadTokenFlag, removeToken } from "./State";

import * as S from "sanctuary";
import * as $ from "sanctuary-def";

const logger = log.getLogger("Tokens");


const IDENTITY_TOKEN = "idToken";
const ACCESS_TOKEN = "accessToken";

function getParameterByName(name) {
  var match = RegExp('[#&]' + name + '=([^&]*)').exec(window.location.hash);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

function getMaybeURLAccessToken () {
  const accessToken = getParameterByName('access_token');
  return S.ifElse (S.is ($.Null)) (S.K (S.Nothing)) (S.Just) (accessToken)
}

function getMaybeCachedAccessToken () {
  const accessToken = window.localStorage.getItem(ACCESS_TOKEN);
  return S.ifElse (S.is ($.Null)) (S.K (S.Nothing)) (S.Just) (accessToken)
}

function cacheAccessToken (accessToken) {
  // Not allowed in private mode in Mobile Safari ios5 and above
  // trap error
  window.localStorage.setItem(ACCESS_TOKEN, accessToken);
  return accessToken;
}

function getMaybeURLIdentityToken () {
  const identityToken = getParameterByName('id_token');
  return S.ifElse (S.is ($.Null)) (S.K (S.Nothing)) (S.Just) (identityToken)
}

function getMaybeCachedIdentityToken () {
  const identityToken = window.localStorage.getItem(IDENTITY_TOKEN);
  return S.ifElse (S.is ($.Null)) (S.K (S.Nothing)) (S.Just) (identityToken)
}

function cacheIdentityToken (identityToken) {
  // Not allowed in private mode in Mobile Safari ios5 and above
  // trap error
  console.log("IDDDDDTOKEN:", identityToken)
  window.localStorage.setItem(IDENTITY_TOKEN, identityToken);
  return identityToken;
}

function getAccessToken() {
  let accessToken;
  logger.debug("accessToken:", accessToken);
  accessToken = getParameterByName('access_token');
  if ( !!accessToken ) {
    window.localStorage.setItem(ACCESS_TOKEN, accessToken);
    return accessToken;
  } else {
    return window.localStorage.getItem(ACCESS_TOKEN);
  }
}

function getIdToken() {
  let idToken;
  idToken = getParameterByName('id_token');
  logger.debug("idToken:", idToken);
  if ( !!idToken ) {
    window.localStorage.setItem(IDENTITY_TOKEN, idToken);
    return idToken;
  } else {
    return window.localStorage.getItem(IDENTITY_TOKEN);
  }
}

const removeIdToken = () => window.localStorage.removeItem(IDENTITY_TOKEN);
const removeAccessToken = () => window.localStorage.removeItem(ACCESS_TOKEN);

const removeTokens = () => {
  removeIdToken();
  removeAccessToken();
  store.dispatch(removeToken())
  logger.error("removed tokens");
}



export {
  cacheAccessToken,
  cacheIdentityToken,
  getIdToken,
  getAccessToken,
  getMaybeCachedAccessToken,
  getMaybeURLAccessToken,
  getMaybeCachedIdentityToken,
  getMaybeURLIdentityToken,
  removeTokens,
};
