// @flow
import * as R from "ramda";

import {
  instanceService,
} from "../../Entity/InstanceService";
import {
  ApiClient,
  TenantMapping as TenantMappingGW,
  MappingsApi,
} from "@bmbix/bmb_xero_v1_authz_client";
import {
  type TenantMapping,
} from "../../Entity/Types.js";


const createTenantMappingFromGW = (
  tenantMappingGW: TenantMappingGW,
): TenantMapping  => {
  const expected = instanceService.createTenantMapping({
    xeroTenantId: tenantMappingGW.xero_tenant_id,
    platformId: tenantMappingGW.platform_id,
    tenantMappingId: tenantMappingGW.tenant_mapping_id,
  });
  return expected;
}


const insertMapping = (args: {
  token: string,
  platformId: string,
  xeroTenantId: string,
  defaultClient_: Object,
}): Promise<TenantMapping> => {
  const {
    token,
    platformId,
    xeroTenantId,
    defaultClient_,
  } = args;
  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }


  const body = TenantMappingGW.constructFromObject({
    platform_id: platformId,
    xero_tenant_id: xeroTenantId,
  });

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new MappingsApi(defaultClient);
  return new Promise((resolve, reject) => {
    apiInstance.insertMapping(
      body,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          const tenantMappingGW = data.tenant_mapping;
          const tenantMapping = createTenantMappingFromGW(tenantMappingGW);
          resolve(tenantMapping);
        }
      }
    );
  });

}


const getMappings = (args: {
  token: string,
  defaultClient_: Object,
}): Promise<TenantMapping> => {
  const {
    token,
    defaultClient_,
  } = args;
  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new MappingsApi(defaultClient);
  return new Promise((resolve, reject) => {
    apiInstance.getMappings(
      (error, data, response) => {
        if (error) {
          resolve(error);
        } else {
          const tenantMappingsGW = data.tenant_mappings || [];
          const tenantMappings = R.map(
            createTenantMappingFromGW,
            tenantMappingsGW,
          );
          resolve(tenantMappings);
        }
      }
    );
  });

}


const deleteMapping = (args: {
  token: string,
  tenantMappingId: string,
  defaultClient_: Object,
}): Promise<null> => {
  const {
    token,
    tenantMappingId,
    defaultClient_,
  } = args;
  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new MappingsApi(defaultClient);
  return new Promise((resolve, reject) => {
    apiInstance.deleteMapping(
      tenantMappingId,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          const tenant_mapping = data.tenant_mapping;
          if (tenant_mapping === null || tenant_mapping === undefined){
            resolve(null);
          } else {
            reject(new Error("Was expecting null"));
          }
        }
      }
    );
  });

}

export {
  createTenantMappingFromGW,
  insertMapping,
  getMappings,
  deleteMapping,
}
