// @flow
import {
  createElement as el,
  useEffect,
  useState,
} from "react";
import {
  withRouter,
} from "react-router-dom";
import { fork } from "fluture";
import * as S from "sanctuary";
import * as $ from "sanctuary-def";
import { useDispatch } from "react-redux";


import { useToken } from "./../../../../State";

import {
  withMartletOrganizationApi,
  getAddressProfilesWithFuture,
} from "./../../../../Api/MartletOrganization";
import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "./../../../../Util";

import { View } from "./view.js";
import {
  createDecoratedRejectedMessage
} from "./../../entity.js";
import {
  refreshAddressProfilesThunk,
} from "./../../../../Thunks/address-profiles.js";
import {
  useAddressProfiles,
} from "./../../../../State/AddressProfiles.js";


const uniq = (items) => S.reduce (
    xs => x => S.elem (x) (xs) ? xs : S.append (x) (xs)
  ) ([]) (items)



const decorate = (messages) => (profiles) => {
  const d = S.fromPairs (S.map (p => S.Pair (p.addressId) (p) ) (profiles) )
  const get = (dict) => (key) => S.fromMaybe ({}) (S.value (key) (dict) )
  return S.map (m => createDecoratedRejectedMessage({
    message: m,
    addressProfile: get (d) (m.toId),
  })) (messages);
}



const Controller = withMartletOrganizationApi(withRouter((props: {
  martletOrganizationApi: Object,
  match: Object,
}) => {
  const {
    match,
    martletOrganizationApi,
  } = props;
  const addressId = match.params.addressId;
  const token = useToken();
  const dispatch = useDispatch();
  const addressProfiles = useAddressProfiles();
  const limit = 25;
  const organizationId = match.params.organizationId;
  const [display, setDisplay] = useState(INPUT);
  const [rejectedMessages, setRejectedMessages] = useState([]);
  const [ displayFetching, setFetchingDisplay ] = useState(INPUT);
  const [ pointer, setPointer ] = useState();
  const [ totalRecordCount, setTotalRecordCount ] = useState(-1);
  const [ fetchedRecordCount, setFetchedRecordCount ] = useState(0);

  const decoratedRejectedMessages = decorate(rejectedMessages)(addressProfiles);

  const isLastRecordSet = () => S.equals (fetchedRecordCount) (totalRecordCount);

  const fetchRecords = async (mode) => {
    // mode = initial, next, reset
    setFetchingDisplay("FETCHING");

    const rejectedMessagesResponse = mode !== "next"
    ? await martletOrganizationApi.getRejectedMessages({
      organizationId,
      addressId,
      limit,
    })
    : await martletOrganizationApi.getRejectedMessages({
      organizationId,
      addressId,
      limit,
      pointer,
    });

    const rejectedMessages_ = rejectedMessagesResponse.rejectedMessages;

    setPointer(
      S.maybeToNullable (
        S.map (S.prop ("systemId")) (S.last (rejectedMessages_))));
    setTotalRecordCount(rejectedMessagesResponse.selectionMetadata.count);

    switch (mode) {
      case "initial":
      case "next":
        setRejectedMessages(prev => prev.concat(rejectedMessages_));
        setFetchedRecordCount(prev => S.add (prev) (S.size(rejectedMessages_)));
        break;
      case "reset":
        setRejectedMessages(rejectedMessages_);
        setFetchedRecordCount(S.size(rejectedMessages_));
        break;
      default:
        throw new Error("found a case we didn't like");
    }

    const thunk = refreshAddressProfilesThunk(token)(S.justs (S.map (S.get (S.is ($.String)) ("toId"))(rejectedMessages_)));
    dispatch(thunk);

    setDisplay(SUCCESS);
    setFetchingDisplay("DONE");

  }

  useEffect(
    () => {fetchRecords("initial")},
    [],
  );


  const handleReset = e => fetchRecords("reset");
  const handleNext = e => fetchRecords("next");


  console.log("fetchedRecordCount:", fetchedRecordCount);
  console.log("totalRecordCount:", totalRecordCount);
  console.log("isLastRecordSet:", isLastRecordSet());
  console.log("pointer:", pointer);

  return el(
    View,
    {
      display,
      organizationId,
      addressId,
      rejectedMessages: decoratedRejectedMessages,
      onReset: handleReset,
      onNext: handleNext,
      displayFetching,
      lastRecordSet: isLastRecordSet(),
      fetchedRecordCount,
      totalRecordCount,
    },
  );
}));


export { Controller }
