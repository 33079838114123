// @flow
import React from "react";

import Form from "react-bootstrap/Form";
import {BModal, BSuccess, BFailure} from "./../../../Widgets/BModal";
import * as S from "sanctuary";

export type SicCode = {
  +code: string,
  +description: string,
}

const codex = [
  {code: "23742", description: "some SIC code"},
  {code: "59802", description: "other SIC code"},
];

let codes_data = require("./sic.json");
const codes = codes_data.data;

function SicCodeFormChange (props: {
  onChange: Function,
  data: string,
}) {
  const {
    onChange,
    data,
  } = props;

  return (
    <Form>
    <Form.Group>
    <Form.Label>SIC Code</Form.Label>
    <Form.Control
      as="select"
      type="text"
      name="sicCode"
      onChange={onChange}
      placeholder="Enter new SIC code"
      value={data}
    >
    {
      S.map (({code, description}) => <option key={code} value={code}>{`${code} ${description}`}</option>) (codes)
    }
    </Form.Control>
    <Form.Text>Choose an appropriate SIC Code</Form.Text>
    </Form.Group>
    </Form>
  );
}

function View (props: {
  display: string,
  onSubmit: Function,
  onDoneSuccess: Function,
  onDoneFailure: Function,
  onCancel: Function,
  onChange: Function,
  data: Function,
}) {

  const {
    display,
    onCancel,
    onSubmit,
    onDoneSuccess,
    onDoneFailure,
    onChange,
    data,
  } = props;

  return (
    <BModal title={"Update SIC Code"} display={display} onSubmit={onSubmit} onCancel={onCancel}
  onDoneSuccess={onDoneSuccess} onDoneFailure={onDoneFailure}>
      <SicCodeFormChange onChange={onChange} data={data} />
      <BSuccess>Successfully</BSuccess>
      <BFailure>Oops, failed to change SIC code.</BFailure>
    </BModal>
  );

}

export { View as default };

