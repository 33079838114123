// @flow

import {
  instanceService,
} from "../Entity/InstanceService.js";
import {
  type Connection,
} from "../Entity/Types.js";
import {
  AuthnApi,
  ApiClient,
  Connection as ConnectionGw,
} from "@bmbix/bmb_sage_v1_authn_client";
import log from "loglevel";

const logger = log.getLogger("Sage");

const createConnectionFromGW = (connectionGW:ConnectionGw) => {
  return instanceService.createConnection({
    id: connectionGW.id,
    platformId: connectionGW.platform_id,
    state: connectionGW.state,
    accessToken: connectionGW.access_token,
    refreshToken: connectionGW.refresh_token,
    expiresAtUtc: connectionGW.expires_at_utc,
  });
}


const connect = (args: {
  token: string,
  organizationId: string,
  platformId: string,
  defaultClient_: Object,
}): Promise<string> => {
  const {
    token,
    organizationId,
    platformId,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  const body = ConnectionGw.constructFromObject({
    platform_id: platformId,
  });

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AuthnApi(defaultClient);
  return new Promise((resolve, reject) => {
    apiInstance.connect(
      body,
      organizationId,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          logger.debug("auth_code response data:", data);
          const authCodeUrl = data.auth_code_url;
          resolve(authCodeUrl);
        }
      }
    );
  });
}

const select = (args: {
  token: string,
  organizationId: string,
  platformId: string,
  defaultClient_: Object,
}): Promise<?Connection> => {
  logger.debug("args:", args);
  const {
    token,
    organizationId,
    platformId,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AuthnApi(defaultClient);
  return new Promise((resolve, reject) => {
    apiInstance.select(
      organizationId,
      platformId,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          const connectionGw = data.connection;
          if (!!connectionGw) {
            resolve(createConnectionFromGW(connectionGw));
          } else {
            resolve(null);
          }
        }
      }
    );
  });
}

const callDelete = (args: {
  token: string,
  organizationId: string,
  platformId: string,
  defaultClient_: Object,
}): Promise<Connection> => {
  const {
    token,
    organizationId,
    platformId,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AuthnApi(defaultClient);
  return new Promise((resolve, reject) => {
    apiInstance.callDelete(
      organizationId,
      platformId,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          resolve(data); // should be null
        }
      }
    );
  });
}

const test = (args: {
  token: string,
  organizationId: string,
  platformId: string,
  defaultClient_: Object,
}): Promise<string> => {
  const {
    token,
    organizationId,
    platformId,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AuthnApi(defaultClient);
  return new Promise((resolve, reject) => {
    apiInstance.test(
      organizationId,
      platformId,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          const status = data.status;
          resolve(status);
        }
      }
    );
  });
}


export {
  createConnectionFromGW,
  callDelete,
  connect,
  select,
  test,
}
