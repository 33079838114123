// @flow
import React, {useState} from "react";
import styled from "styled-components";
import {useTable} from "react-table";
import Form from "react-bootstrap/Form";
import * as R from "ramda";
import * as S from "sanctuary";
import log from "loglevel";

import {UserProfileWidget} from "../../Widgets/UserProfile";

import {type UserProfile } from "./../../Entity/Types";

const logger = log.getLogger("PermissionsTable");

const Styles = styled.div`
  /* padding: 1rem; */
  padding-top: 1rem;
  table {
    background-color: var(--white);
    border-spacing: 0;
    border: 1px solid black;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      :last-child {
        border-right: 0;
      }
    }
  }
`

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  // Render the UI for your table
  return (
    <table {...getTableProps()} className="table table-striped table-hover">
      <thead className="thead-dark">
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const View = (props: {
  permissions: Function;
  checked: Array<string>,
  onChange: Function,
}) => {
  const {
    permissions,
    checked,
    onChange,
  } = props;

  logger.error("checked:", checked);
  logger.error("permissions:", permissions);


  const [chkd, setChkd] = useState(checked);

  const isChecked = (permissionId) => {
    logger.error("permissionId: ", permissionId, " chkd: ", chkd);
     return R.contains(permissionId, chkd);
  }

  const handleChange = (e) => {
    const isChecked = e.target.checked;
    const permissionId = e.target.value;
    let newChecked;
    if(isChecked){
      newChecked = R.uniq(R.append(permissionId, chkd));
    }
    else {
      newChecked = R.without(permissionId, chkd);
    }
    logger.error("outbound permission ids:", newChecked);
    setChkd(newChecked);
    onChange(newChecked);
  }

  const handleSelectAll = (e) => {
    const allRefs = R.map(
      i => i.permissionId,
      permissions,
    );
    setChkd(allRefs);
    onChange(allRefs);
  }

  const handleSelectNone = (e) => {
    const noneRefs = [];
    setChkd(noneRefs);
    onChange(noneRefs);
  }


  const columns = React.useMemo(
    () => [
      {
        id: "select",
        Cell:  ({row}) => {
          // console.log(row);
          // console.log(typeof row);
          const permissionId = row.original.permissionId;
          return (
            <Form.Check
              id={permissionId}
              name={permissionId}
              key={permissionId}
              value={permissionId}
              onChange={handleChange}
              checked={isChecked(permissionId)}
            />
          )
        }
      },
      {
        Header: "Profile",
        Cell: ({row})=> {
          const userProfile: UserProfile = S.props (["original", "userProfile"]) (row)
          return (
            S.ifElse (up => typeof up === "undefined")
              (_ => S.props (["original", "actorName"]) (row))
              (up => <UserProfileWidget userProfile={up}/>)
              (userProfile)
          );
        }
      },
      {
        Header: 'Role',
        accessor: 'power',
      },
    ],
    [chkd]
  )

  return (
    <Styles>
      <Table columns={columns} data={permissions} />
    </Styles>
  )
}

export {
  View,
}
