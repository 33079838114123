// @flow
import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "./Modal";
import * as R from "ramda";
import * as S from "sanctuary";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useMachine } from "@xstate/react";
import { createMachine } from "xstate";
import {
  Spinner,
} from "./../Widgets/Toast";

import {
  type BmbixAddress,
  type Platform,
  type PlatformAssignment,
} from "../Entity/Types";
import { instanceService } from "../Entity/InstanceService";
import {
  UnassignModal,
} from "./UnassignModal.js";
import {
  AssignModal,
} from "./AssignModal.js";
import {
  addPlatformAssignment,
  deletePlatformAssignment,
} from "../State/PlatformAssignments.js";
import { useToken } from "./../State";
import { getPlatformAssignments } from "./../Api/Isimud/PlatformAssignments.js";
import { getPlatforms } from "./../Api/Isimud/Platforms.js";


const PlatformWidget = (props: {
  platform: Platform,
}) => {

  const {
    platform,
  } = props;
  console.log("Banana platform: ", platform);

  return (
    <>
    <hr/>
    <h3>Name:</h3>
    <p>{platform.name}</p>
    <hr/>
    <h3>Type:</h3>
    <p>{platform.type}</p>
    </>
  )
}


const fetchingMachine = createMachine({
  id: "fetchingMachine",
  initial: "waiting",
  states: {
    waiting: {
      on: {
        SUCCESS_HAPPENED: "success",
        FAILURE_HAPPENED: "failure",
      }
    },
    success: {
      on: {
        WAITING_HAPPENED: "waiting",
      }
    },
    failure: {
      on: {
        RESET_HAPPENED: "input"
      }
    },
    input: {
      on: {
        SUBMIT_HAPPENED: "waiting"
      }
    },
  },
});


const View_ = (props: {
  addPlatformAssignment: Function,
  deletePlatformAssignment: Function,
  address: BmbixAddress,
}) => {

  const {
    addPlatformAssignment,
    deletePlatformAssignment,
    address,
  } = props;

  const [ state, send ] = useMachine(fetchingMachine);
  const [ updateTime, setUpdateTime ] = useState(new Date());
  const token = useToken();
  const [showUnassignModal, setShowUnassignModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [ platforms, setPlatforms ] = useState([]);
  const [ platformAssignment, setPlatformAssignment ] = useState();
  const [ platform, setPlatform ] = useState();
  console.log("showUnassignModal", showUnassignModal);
  console.log("showAssignModal", showAssignModal);

  useEffect(
    ()=>{
      send({type: "WAITING_HAPPENED"});
      getPlatformAssignments({
        token,
        organizationId: address.organizationId,
        addressId: address.id,
      }).then(
        platformAssignments => {
          console.log("platform assignments:", platformAssignments);
          getPlatforms({
            token,
            organizationId: address.organizationId,
          }).then(
            platforms => {
              console.log("platforms: ", platforms);

              const equalsFunc = S.equals (address.id);
              const getterFunc = S.prop ("addressId");
              const selector = S.compose (equalsFunc) (getterFunc);

              const maybeThingy = S.find (selector) (platformAssignments);
              console.log("maybeThingy: ", maybeThingy);
              const platformAssignment = S.maybeToNullable (maybeThingy);
              console.log("Here is the platform assignment: ", platformAssignment);
              if(!!platformAssignment) {
                const platformEqualsFunc = S.equals (platformAssignment.platformId);
                const platformGetterFunc = S.prop ("platformId");
                const platformSelector = S.compose (platformEqualsFunc) (platformGetterFunc);

                const maybePlatform = S.find (platformSelector) (platforms);
                const platform = S.maybeToNullable (maybePlatform);
                console.log("Banana latform: ", platform);
                setPlatform(platform);
                setPlatformAssignment(platformAssignment);
              }

              setPlatforms(platforms);

              send({type: "SUCCESS_HAPPENED"});
            }
          );
        }
      )
    },
    [updateTime]
  )

  const hasAssignment = ( platformAssignment === undefined || platformAssignment === null ) ? false : true;

  const assignModalWidget = ( showAssignModal && !platformAssignment ) ? (
    <AssignModal
      onCancel={ () => setShowAssignModal(false) }
      onComplete={(platformAssignment) => {
        setShowAssignModal(false)
        addPlatformAssignment(platformAssignment);
        setUpdateTime(new Date());
      }}
      platforms={platforms}
      address={address}
    />
  ): null;


  const unassignModalWidget = ( showUnassignModal && !!platformAssignment ) ? (
    <UnassignModal
      onCancel={ () => setShowUnassignModal(false) }
      onComplete={(platformId) => {
        setShowUnassignModal(false);
        deletePlatformAssignment(platformAssignment.platformAssignmentId);
        setUpdateTime(new Date());
      }}
      organizationId={address.organizationId}
      platformAssignmentId={platformAssignment.platformAssignmentId}
    />
  ) : null;


  const existingAssignmentWidget = hasAssignment && platformAssignment ? (
    <>
      <PlatformWidget
        platform={platform}
      />
      <Button onClick={ e => setShowUnassignModal(true) }>Unassign</Button>
    </>
  ) : null ;

  const noAssigmentWidget= !hasAssignment ? (
    <>
    <p>No platform is yet assigned to this address.</p>
      <Button onClick={ e => setShowAssignModal(true) }>Assign</Button>
    </>
  ) : null ;

  return state.value === "success"
  ? (<>
    <h2>Platform Assignment</h2>
    {existingAssignmentWidget}
    {noAssigmentWidget}
    {unassignModalWidget}
    {assignModalWidget}
    </>)
  : state.value === "waiting"
  ? <Spinner height="50" />
  : state.value === "failure"
  ? <p>Oops</p>
  : <p>Unknown state</p>;
}

const mapStateToProps = (state, ownProps) => {
  const {
    match,
  } = ownProps;
  const addressId = match.params.addressId;
  console.log("state", state.platformAssignments);
  return {
    address: R.find(R.propEq("id", addressId))(state.addresses),
  }
}

const connector = connect(
  mapStateToProps,
  {addPlatformAssignment, deletePlatformAssignment,},
)

const View = withRouter(connector(View_));

export {
  View,
}
