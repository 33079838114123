// @flow

export type AccessListType = "allow" | "deny";

export type AccessList = {
  +isActive: boolean,
  +list: string,
  +name: string,
  +type: AccessListType,
}

export type AccessListEntry = {
  entry: string,
  sender: string,
}

export type AccessListActivation = {
  +active: string,
}


const createAccessList = (args: {
  isActive: boolean,
  list: string,
  name: string,
  type: AccessListType,
}): AccessList => {

  return Object.freeze(args);
}

const createAccessListEntry = (args: {
  entry: string,
  sender: string,
}): AccessListEntry => {

  return Object.freeze(args);
}

const createAccessListActivation = (args: {
  active: string,
}): AccessListActivation  => {
  return Object.freeze({
    active: args.active,
  });
}


export {
  createAccessList,
  createAccessListEntry,
  createAccessListActivation,
}

