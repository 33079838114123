// @flow

import sanctuary from "sanctuary";
import { env as flutureEnv } from "fluture-sanctuary-types";
import {
  encaseP,
  map,
  resolve,
} from "fluture";
import {
  getPlatform,
} from "./../Api/Isimud/Platforms.js";
import {
  addPlatform,
} from "./../State/Platforms.js";

const S = sanctuary.create ({
  checkTypes: true, env:
  sanctuary.env.concat(flutureEnv)
});


const cacheFetchResult =
  dispatch =>
  platform =>
{
  dispatch(addPlatform(platform));
  return platform;
}


const refreshPlatformThunk =
  token =>
  organizationId =>
  platformId =>
{
  const thunk =
    (dispatch, getState) =>
  {

    // Either we have the platform in the cache or we dont.
    // Which is it ...
    const state = getState();
    const platformsFromState = state.platforms;
    const selector = S.compose (S.equals (platformId)) (S.prop ("platformId"));
    const maybePlatform = S.find (selector) (platformsFromState);
    const eitherPlatform = S.maybeToEither ("platform not in store") (maybePlatform);

    // If yes, return a future with the cached assignment in it.
    const cacheHit = p => resolve(p);


    // If not in cache fetch the platform
    // cache it, and return it in a future
    const cacheMiss =
      _ =>
    {
      const fetchFuture = encaseP (getPlatform) ({
        token, organizationId, platformId});
      const futureAfterCaching = map (cacheFetchResult(dispatch)) (fetchFuture);
      return futureAfterCaching;
    }

    return S.either (cacheMiss) (cacheHit) (eitherPlatform);

  }
  return thunk;
}

export {
  refreshPlatformThunk,
}

