// @flow
import {createElement as el} from "react";

import {
  View,
} from "./View.js";

const Controller = (props: {
  onSubmit: Function,
  onFromDateChange: Function,
  onToDateChange: Function,
  onContentMediaTypeChange: Function,
}) => {

  const {
    onSubmit,
    onFromDateChange,
    onToDateChange,
    onContentMediaTypeChange,
  } = props;

  return el(
    View,
    {
      onSubmit,
      onFromDateChange,
      onToDateChange,
      onContentMediaTypeChange,
    },
  )
}

export {
  Controller,
}
