// @flow

import React, {
  useState,
} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  connect,
} from "react-redux";

import {
  addPlatform,
} from "../State";
import {
  withIsimudApi,
} from "../Api/Isimud";
import {
  Spinner,
} from "../Widgets/Toast";
import {
  AddForm,
} from "./AddForm";


const INPUT = "input";
const WAITING = "waiting";
const SUCCESS = "success";
const FAILURE = "failure";


const AddModal = (props:{
  organizationId: string,
  onComplete: Function,
  onCancel: Function,
  isimudApi: Object,
  addPlatform: Function,
}) => {
  const {
    organizationId,
    onComplete,
    onCancel,
    isimudApi,
    addPlatform,
  } = props;
  const [display, setDisplay] = useState(INPUT);
  const [form, setForm] = useState({});

  const handleCancel = (e) => {
    onCancel();
  }

  const handleSubmit = () => {
    setDisplay(WAITING);
    isimudApi.insertPlatform({
      organizationId: organizationId,
      name: form.name,
      type: form.type,
    }).then(
      platform => {
        console.log("added platform", platform);
        addPlatform(platform);
        setDisplay(SUCCESS);
      }
    ).catch(
      error => {
        console.log("failed to add platform", error)
        setDisplay(FAILURE)
      }
    );
  }

  const input = display === INPUT ? (
    <AddForm
      callBack={form => setForm(form)}
    />
  ) : null;

  const waiting = display === WAITING ? (
    <Spinner />
  ) : null;

  const success = display === SUCCESS ? (
    <p>Success!</p>
  ) : null;

  const failure = display === FAILURE ? (
    <p>Failure!</p>
  ) : null;

  const cancelButton = display === INPUT || display === WAITING ? (
      <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
      ): null;

  const completeButton = display === SUCCESS || display === FAILURE ? (
      <Button variant="secondary" onClick={onComplete}>Done</Button>
      ): null;

  const submitButton = display === INPUT ? (
      <Button variant="primary" onClick={handleSubmit}>Save changes</Button>
      ): null;


  return (
    <Modal show={true}>
      <Modal.Header>
        <Modal.Title>Add Platform</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {input}
        {waiting}
        {success}
        {failure}
      </Modal.Body>
      <Modal.Footer>
        {submitButton}
        {cancelButton}
        {completeButton}
      </Modal.Footer>
    </Modal>
  );

}


export default withIsimudApi(connect(undefined, {addPlatform})(AddModal));

export {
  AddModal,
}
