// @flow
import { createElement as el } from "react";

import { IndexPage } from "./Widgets";


const Controller = (props: {
  match: Function,
}) => {
  const {
    match,
  } = props;

  return el (
    IndexPage,
    {
      match,
    }
  )
}

export { Controller }
