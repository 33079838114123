// @flow

import React, {
  useState,
  useEffect,
} from "react";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import * as R from "ramda";
import {
  withRouter,
} from "react-router-dom";
import {
  connect,
} from "react-redux";
import {
  TrashIcon,
  PlusIcon,
  SyncIcon,
} from "@primer/octicons-react"
import log from "loglevel";
import * as S from "sanctuary";
import * as $ from "sanctuary-def";
import { useMachine } from "@xstate/react";
import { createMachine, assign } from "xstate";
import {
  Spinner,
} from "./../Widgets/Toast";

import {
  List,
} from "./List";
import {
  AddModal,
} from "./AddModal";
import {
  withIsimudApi,
} from "../Api/Isimud";
import {
  withMartletOrganizationApi,
} from "../Api/MartletOrganization";
import {
  type AddressBookEntry,
} from "../Entity/Types";
import {
  DeleteModal,
} from "./DeleteModal";
import {
  filterSelection,
} from "../Util";
import {
  addAddressBookEntries,
  addAddressBookEntry,
} from "../State";
import {
  EditForeignAddressWidget,
} from "./EditForeignAddressWidget";
import {
  generateAliasMap,
} from "../Util/alias";
import { useIdToken,} from "./../State";
import {
  createWireMessageFromWS,
  getCredentialsPromise,
  buildWebSocket,
  sendKeepAliveMessage,
  handleCloseEvent,
} from "./../Util/notifications"


const TimedMessage = (props: {
  delay: number;
}) => {
  const [ theChildren, setTheChildren ] = useState(props.children);
  const {
    delay,
  } = props;

  useEffect(
    () => {
      setTimeout(() => {
        setTheChildren(null);
      }, delay);
    },
    [delay],
  );
  return theChildren;
}


const AwaitAddressUpdates = (props: {
  state: string,
}) => {

  return props.state === "waiting"
  ? <Spinner height="24" />
  : props.state === "idle"
  ? null
  : <TimedMessage delay={2000}><p>Addresses updated. You can refresh now</p></TimedMessage>
  ;
}


const REGION = "eu-west-2"; //REGION
const IDENTITY_POOL_ID = "eu-west-2:22df95ae-bc6a-430e-b669-1fb6218d2965";

const fetchingMachine = createMachine({
  id: "fetchingMachine",
  initial: "waiting",
  context: {
    error: undefined,
  },
  states: {
    waiting: {
      on: {
        SUCCESS_HAPPENED: "success",
        FAILURE_HAPPENED: "failure",
        FAILURE403_HAPPENED: {
          target: "failure403",
          actions: assign({
            error: (c, e) => e.error
          }),
        },
      }
    },
    success: {
      on: {
        WAITING_HAPPENED: "waiting",
      }
    },
    failure: {
      on: {
        RESET_HAPPENED: "input"
      }
    },
    failure403: {
      on: {
        RESET_HAPPENED: "input",
      }
    },
    input: {
      on: {
        SUBMIT_HAPPENED: "waiting"
      }
    },
  },
});

const logger = log.getLogger("AddressBookEntry");

const uniq_ = (result) => (list) => {
  if (list.length === 0) {return result}
  else {
    const [ head, ...tail ] = list
    if (result.includes(head)) {
      return uniq_(result)(tail)
    } else {
      result.push(head)
      return uniq_(result)(tail)
    }

  }
}
const uniq = (list) => uniq_([])(list)

const filterOutNullField = (objects) => (field) => {
  const fieldIsString = S.is ($.String) (field);
  if (!fieldIsString) throw new Error("field is not string");
  const objectsIsList = S.is ($.Array ($.Object)) (objects);
  if (!objectsIsList) throw new Error("objects is not a list");
  const selectorMaybeFunc = S.get  (obj => {
    return S.not (S.or (S.is ($.Undefined)(obj))(S.is ($.Null)(obj)))
  }) (field);
  const maybeObjects = S.map (selectorMaybeFunc) (objects)
  const usefulObjects = S.justs (maybeObjects)
  return uniq(usefulObjects)
}

const lookup = (aliases, id) => {
  const address = R.find(R.propEq("id", id))(aliases)
  return !!address ? address.alias : null;
}
const curriedLookup = R.curry(lookup)


const Page_ = (props: {
  addressBookEntries: Array<AddressBookEntry>,
  addAddressBookEntry: Function,
  addAddressBookEntries: Function,
  deleteAddressBookEntry: Function,
  match: Object,
  isimudApi: Object,
  martletOrganizationApi: Object,
}) => {
  const organizationId = props.match.params.organizationId;
  const addressId = props.match.params.addressId;
  const addressBookEntries = props.addressBookEntries;

  const [selection, setSelection] = useState([]);
  const [addModal, setAddModal] = useState(null);
  const [deleteModal, setDeleteModal] = useState(null);
  const [editForeignAddress, setEditForeignAddress] = useState(null);
  const [aliasedAddressBookEntries, setAliasedAddressBookEntries] = useState([]);
  const [ state, send ] = useMachine(fetchingMachine);
  const idToken = useIdToken();
  const [ addressUpdateState, setAddressUpdateState ] = useState("idle");

  const selectedEntries = filterSelection(
    addressBookEntries,
    selection,
    "addressBookEntryId",
  );

  const selectionHandler = (args: {
    addressBookEntryId: string,
    selected: boolean,
  }) => {
    const {addressBookEntryId, selected} = args;
    switch (selected) {
      case true:
        setSelection(prevSelection => R.uniq(R.append(
          addressBookEntryId,
          prevSelection,
        )));
        return;
      case false:
        setSelection(prevSelection => R.uniq(R.without(
          addressBookEntryId,
          prevSelection,
        )));
        return;
      default:
        throw new Error("selected was not true or false");
    }
  }

  const handleDeleteClose = () => {
    refresh();
    setDeleteModal(null);
  }

  const handleDelete = (e) => {
    setDeleteModal(
      <DeleteModal
        organizationId={organizationId}
        addressId={addressId}
        addressBookEntries={selectedEntries}
        callbackCancel={handleDeleteClose}
        callbackSubmit={()=>{
          setSelection([])
        }}
      />
    );
  }

  const handleAddClose = () => {
    refresh();
    setAddModal(null);
  }

  const handleAdd = (e) => {
    setAddModal(
      <AddModal
        organizationId={organizationId}
        addressId={addressId}
        callbackCancel={handleAddClose}
      />
    );
  }

  const handleDismissEditForeignAddress = () => {
      refresh();
      setEditForeignAddress(null);
  }

  const handleEditForeignAddress = (e) => {
    logger.debug("target value", e.target.value);
    const addressBookEntryId = e.target.value;
    const addressBookEntry = S.maybeToNullable (S.find (abe => abe.addressBookEntryId === addressBookEntryId) (addressBookEntries));

    console.log("BOOOOOOOOOOM", addressBookEntry);
      setEditForeignAddress(
        <EditForeignAddressWidget
          organizationId={organizationId}
          addressId={addressId}
          localAccount={addressBookEntry.localAccount}
          addressBookEntryId={addressBookEntryId}
          onDismiss={handleDismissEditForeignAddress}
        />
    );
    console.log("zorgo");
  }

  const sync = async () => {
    logger.debug("sync")
    const syncId = await props.isimudApi.syncAddressBook({
      organizationId,
      addressId,
    });
    logger.debug("syncId: ", syncId);
    setAddressUpdateState("waiting");
  }

  const addAlias = aliasMap => abe => {
    const maybeForeignAddressId = S.get (S.is ($.String)) ("foreignAddressId") (abe);
    const alias = S.maybe ("no alias found") (fai => S.prop (fai) (aliasMap)) (maybeForeignAddressId);
    return Object.assign({alias: alias}, abe);
  }

  const refresh = async () => {
    try {
      send({type: "WAITING_HAPPENED"});

      const addressBookEntries = await props.isimudApi.getAddressBookEntries({
        storeAddressBookEntries: props.addAddressBookEntries,
        organizationId,
        addressId,
      })
      console.log("addressBookEntries:", addressBookEntries);
      // props.addAddressBookEntries(addressBookEntries);
      const addressIds = filterOutNullField(addressBookEntries)("foreignAddressId")

      const addressProfiles: Array<AddressProfile> = await props.martletOrganizationApi.getAddressProfiles({addressIds})
      console.log("addressProfiles:", addressProfiles);
      const aliasMap = generateAliasMap(addressProfiles);
      console.log("aliasMap:", aliasMap);
      const aliasedAddressBookEntries =  S.map (addAlias(aliasMap)) (addressBookEntries)
      setAliasedAddressBookEntries(aliasedAddressBookEntries);


      send({type: "SUCCESS_HAPPENED"});
    } catch(error) {
      console.log("AddressBookeEntriesPage error:", error);
      console.log(error.status);
      console.log(error.response);
      console.log("errorObject", JSON.parse(error.response.text));
      const errorObject = JSON.parse(error.response.text);
      if (error.status === 403) {
        send({type: "FAILURE403_HAPPENED", error: errorObject});
      } else {
        send({type: "FAILURE_HAPPENED"});
      }
    }
  }

  useEffect(
    () => {refresh()},
    [],
  );


  useEffect(
    () => {
      const handleSyncIdAvailable = (wsMessage) => {
        const wireMessage  = createWireMessageFromWS(wsMessage);
        console.log("Fritters and spam: ", wireMessage);
        setAddressUpdateState("done");
      }

      let webSocket = null;
      console.log("idToken:", idToken);
      const credentialsPromise = getCredentialsPromise(REGION)(IDENTITY_POOL_ID)(idToken)();
      credentialsPromise.then(
        credentials => {
          console.log("Here are credentials:", credentials);
          webSocket = buildWebSocket(credentials);
          webSocket.onopen = sendKeepAliveMessage;
          webSocket.onmessage = handleSyncIdAvailable;
          webSocket.onclose = handleCloseEvent;
        }
      ).catch(
        e => {
          console.log("e:", e)
        }
      )

      return () => {
        webSocket.close();
      }
    },
    [idToken],
  );


  return state.value === "success"
  ? <>
      <ButtonToolbar className="float-right" >
        <Button onClick={handleAdd} ><PlusIcon /> Add</Button>
      </ButtonToolbar>
      <h2>Address Book Entries</h2>
      <Button className="mr-2" onClick={handleDelete} ><TrashIcon /> Delete</Button>
      <Button className="mr-2" onClick={refresh} ><SyncIcon /> Refresh</Button>
      <Button className="mr-2" onClick={sync} ><SyncIcon /> Platform Sync</Button>
      {addModal}
      {deleteModal}
      {editForeignAddress}
      <AwaitAddressUpdates state={addressUpdateState}/>
      <List
        addressBookEntries={aliasedAddressBookEntries}
        selection={selection}
        callback={selectionHandler}
        onEdit={handleEditForeignAddress}
      />
    </>
  : state.value === "waiting"
  ? <Spinner height="50" />
  : state.value === "failure403"
  ? <>
    <p>It looks like you need more permissions to do this.</p>
    <p>Log trace: {state.context.error.code}</p>
    <p>Information: {state.context.error.description}</p>
    </>
  : state.value === "failure"
  ? <p>Oops</p>
  : <p>Unknown state</p>;

}


const mapStateToProps = (state, ownProps) => {
  return {
    addressBookEntries: R.filter(
      ad => ad.ownerAddressId === ownProps.match.params.addressId,
      state.addressBookEntries,
    ),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addAddressBookEntries: (addressBookEntries) => dispatch(
      addAddressBookEntries(addressBookEntries)),
    addAddressBookEntry: (addressBookEntry) => dispatch(
      addAddressBookEntry(addressBookEntry)),
  }
}

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const Page = connector(
  withRouter(withIsimudApi(withMartletOrganizationApi(Page_)))
);


export type DecoratedAddressBookEntry = {
  item: AddressBookEntry,
  checked: boolean,
}


export {
  Page,
  filterOutNullField,
}
