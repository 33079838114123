// @flow
import {
  createElement as el,
  useEffect,
  useState,
} from "react";

import { fork } from "fluture";

import { useToken } from "./../State"
import { index } from "./Api/index";
import { create } from "./Api/create";
import { update } from "./Api/update";

import { useMachine } from "@xstate/react";
import { createMachine } from "xstate";
import {
  useCatchExpiredToken,
} from "./../State/Token.js";

import { InputView, SuccessView, FailureView, WaitingView } from "./view";

const fetchingMachine = createMachine({
  id: "fetchingMachine",
  initial: "waiting",
  states: {
    waiting: {
      on: {
        SUCCESS_HAPPENED: "success",
        FAILURE_HAPPENED: "failure",
      }
    },
    success: {
      on: {
        WAITING_HAPPENED: "waiting",
      }
    },
    failure: {
      on: {
        RESET_HAPPENED: "input"
      }
    },
    input: {
      on: {
        SUBMIT_HAPPENED: "waiting"
      }
    },
  },
});

const Controller = (props: {
  resourceType: string,
  resourceId: string,
}) => {
  const {
    resourceType,
    resourceId,
  } = props;
  const catchExpiredToken = useCatchExpiredToken();

  const token = useToken();
  const [ privateKeys, setPrivateKeys ] = useState([]);
  const [ updateTime, setUpdateTime ] = useState(new Date());
  const [ state, send ] = useMachine(fetchingMachine);

  useEffect(
    () => {
      send({type: "WAITING_HAPPENED"});
      const action = index(token)(resourceType)(resourceId);
      fork(error => {
        console.log(error);
        catchExpiredToken(error);
        send({type: "FAILURE_HAPPENED"});
      })(privateKeys => {
        console.log("Received private keys:", privateKeys);
        setPrivateKeys(privateKeys);
        send({type: "SUCCESS_HAPPENED"});
      })(action)
    },
    [token, resourceType, resourceId, updateTime, send],
  );

  const onGeneratePrivateKey = e => {
      send({type: "WAITING_HAPPENED"});
      const action = create(token)(resourceType)(resourceId);
      fork(error => {
        console.log(error);
        catchExpiredToken(error);
        send({type: "FAILURE_HAPPENED"});
      })(privateKey => {
        setUpdateTime(new Date())
        send({type: "SUCCESS_HAPPENED"});
      })(action);
  }

  const rescindPrivateKey = e => {
      send({type: "WAITING_HAPPENED"});
      const privateKeyId = e.target.value;
      console.log("privateKeyId:", privateKeyId);
      const action = update(token)(resourceType)(resourceId)(privateKeyId);
      fork(error => {
        console.log(error);
        catchExpiredToken(error);
        send({type: "FAILURE_HAPPENED"});
      })(privateKey => {
        setUpdateTime(new Date())
        send({type: "SUCCESS_HAPPENED"});
      })(action)
  }

  return  state.value === "waiting"
  ? el (WaitingView)
  : state.value === "success"
  ? el (
      SuccessView,
      {
        onGeneratePrivateKey,
        privateKeys,
        rescindPrivateKey,
      }
    )
  : state.value === "failure"
  ? el (
      FailureView,
  )
  : state.value === "input"
  ? el (
      InputView,
    )
  : null;

}

export {
  Controller,
}
