// @flow
import React, {
  createElement as el,
  useEffect,
  useState,
}  from "react";
import { useParams } from "react-router-dom";
import {
  promise,
} from "fluture";
import * as S from "sanctuary";
import { useMachine } from "@xstate/react";
import {
  assign,
  createMachine,
} from "xstate";
import {
  Spinner,
} from "./../../../Widgets/Toast";

import { useToken, useIdToken, usePermissions, useAddresses } from "./../../../State";

import { type Permission, type Message } from "./../../../Entity/Types.js";

import { getMessage } from "./../../Api";

import {
  ChooseView,
} from "./chooseView.js";
import {
  MessageView as MessageRecipientView,
} from "./messageRecipientView.js";
import {
  MessageView as MessageSenderView,
} from "./messageSenderView.js";

import {
  getCredentialsPromise,
  buildWebSocket,
  sendKeepAliveMessage,
  handleCloseEvent,
  handleMessageEvent,
} from "./../../../Util/notifications"

const REGION = "eu-west-2"; //REGION
const IDENTITY_POOL_ID = "eu-west-2:22df95ae-bc6a-430e-b669-1fb6218d2965";

const fetchingMachine = createMachine({
  id: "fetchingMachine",
  initial: "input",
  context: {
    token: null,
    messageId: null,
    message: null,
    error: null,
  },
  states: {
    input: {
      on: {
        FETCH_MESSAGE: {
          target: "waiting",
          actions: assign({
            token: (c, e) => e.token,
            messageId: (c, e) => e.messageId,
          }),
        }
      },
    },
    waiting: {
      invoke: {
        id: "fetching message",
        src: (context, event) => promise(getMessage (context.token) (context.messageId)),
        onDone: {
          target: "success",
          actions: assign({
            message: (context, event) => event.data
          }),
        },
        onError: {
          target: "failure",
          actions: assign({
            error: (context, event) => event.data
          }),
        }
      },
    },
    success: {
      on: {
        WAITING_HAPPENED: "waiting",
      }
    },
    failure: {
      on: {
        RESET_HAPPENED: "input"
      }
    },
  },
});

const viewerIsSender = (permissions: Array<Permission>) => (message: Message): Boolean  => S.elem (`bmbix://address/${message.sourceAddressId}`) (
S.map (p => p.resource) (permissions));

const viewerIsRecipient = (permissions: Array<Permission>) => (message: Message): Boolean  => S.elem ( `bmbix://address/${message.destinationAddressId}`) ( S.map (p => p.resource) (permissions));

const getRecipientAddress = addresses => message => {
  console.log("addreeses:", addresses);
  console.log("message:", message);
  const DEFAULTADDR = {};
  const recipientAddress = S.fromMaybe (DEFAULTADDR) (S.find (ad => S.equals (ad.id) (S.prop ("destinationAddressId") (message))) (addresses));
  console.log("recipientAddress:", recipientAddress);
  return recipientAddress;
}

const getSenderAddress = addresses => message => {
  return S.maybeToNullable (S.find (ad => S.equals (ad.id) (S.prop ("sourceAddressId") (message))) (addresses));
}

const Controller = (props: {
}) => {

  const { messageId } = useParams();
  const token = useToken();
  const idToken = useIdToken();
  const [ viewer, setViewer ] = useState();
  const permissions = usePermissions();
  const addresses = useAddresses();
  const [ state, send ] = useMachine(fetchingMachine);

  useEffect(
    () => {
      let webSocket = null;
      console.log("idToken:", idToken);
      send({type: "WAITING_HAPPENED"})
      const credentialsPromise = getCredentialsPromise(REGION)(IDENTITY_POOL_ID)(idToken)();
      credentialsPromise.then(
        credentials => {
          console.log("Here are credentials:", credentials);
          webSocket = buildWebSocket(credentials);
          webSocket.onopen = sendKeepAliveMessage;
          webSocket.onmessage = handleMessageEvent;
          webSocket.onclose = handleCloseEvent;
        }
      ).catch(
        e => {
          console.log("e:", e)
        }
      )

      send({type: "FETCH_MESSAGE", token, messageId });

      return () => {
        webSocket.close();
      }
    },
    [ token, idToken, messageId, permissions, send],
  );

  const statusOfViewer = message => {
    const isRecipient = viewerIsRecipient(permissions)(message);
    const isSender = viewerIsSender(permissions)(message);
    const isBoth = S.and (isRecipient) (isSender);

    return viewer
    ? viewer
    : isBoth
    ? "both"
    : isRecipient
    ? "recipientOnly"
    : "senderOnly";
  }

  const setViewType = (viewType) => {
    setViewer(viewType);
  }


  switch (state.value) {
    case "input": {
      return <p>Awaiting input</p>
    }
    case "waiting": {
      return <Spinner height="50" />
    }
    case "success": {
      switch (statusOfViewer(state.context.message)) {
        case "senderOnly": {
          return el(
            MessageSenderView,
            {
              message: state.context.message,
              address: getSenderAddress(addresses)(state.context.message),
            }
          );
        }
        case "recipientOnly": {
          return el(
            MessageRecipientView,
            {
              message: state.context.message,
              address: getRecipientAddress(addresses)(state.context.message),
            }
          );
        }
        default: { // ie both sender and receiver
          return el (ChooseView, {setViewType})
        }
      }
    }
    case "failure": {
      return  <>
        <p>Oops, unable to fetch message.</p>
        <p>Most likely the message was rejected for non-conformance.</p>
        <p>For more information, check the logs of the submitting application and the Bmbix message status report.</p>
      </>
    }
    default: {
      return <p>Unknown state</p>;
    }
  }

}
export { Controller }
