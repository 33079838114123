// @flow
import React from "react";

import { type Organization } from "../../../Entity/Types.js";

import { Controller as OrganizationsTable } from "./../OrganizationsTable/controller.js";

const View = (props: {
  organizations: Array<Organization>,
}) => {

  const {
    organizations,
  } = props;

  return (
    <>
    <h2>Certificate-issuing Organizations</h2>
    <p>Here is a list of the organizations on whose behalf you may issue
    certificates.
    </p>
    <p>If an organization appears to be missing from this list, contact a
    manager
    at that organization and ask them to add your bmbix id with
    bmbix://role/certificate-issuer
    </p>
    <OrganizationsTable organizations={organizations} />
    </>
  )
}
export {
  View,
}
