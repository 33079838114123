import React from "react";

import {
  connect,
} from "react-redux";

import {
  ApiClient as IsimudApiClient,
  AddressBookEntriesApi,
} from "@bmbix/bmb_isimud_client";


import {
  getAddressBookEntries,
  insertAddressBookEntry,
  deleteAddressBookEntry,
  syncAddressBook,
  updateForeignAddressId,
} from "./AddressBookEntries.js";
import {
  getExtractionCandidateReport,
  requestExtractionCandidateReport,
} from "./ExtractionCandidates.js";
import {
  getExtractionItemWithHeaders,
  submitExtractionRequest,
} from "./Extractions.js";
import {
  submitInsertionInstruction,
  queryInsertionReceipts,
} from "./Insertions.js";
import {
  getAllPlatformAssignments,
  insertPlatformAssignment,
  deletePlatformAssignment,
} from "./PlatformAssignments.js";
import {
  insertPlatform,
  deletePlatform,
  getAllPlatforms,
} from "./Platforms.js";


const createAddressBookEntriesApi = (token) => {
  const apiClient = IsimudApiClient.instance;
  let MartletOauth2 = apiClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  return new AddressBookEntriesApi(apiClient);
}


class IsimudApi {

  constructor (args) {
    this.token = args.token;
    this.addressBookEntriesApi = createAddressBookEntriesApi(args.token);
  }

  getExtractionCandidateReport(args) {
    const args_ = {token: this.token, ...args}
    return getExtractionCandidateReport(args_);
  }

  requestExtractionCandidateReport(args) {
    const args_ = {token: this.token, ...args}
    return requestExtractionCandidateReport(args_);
  }

  getAddressBookEntries(args) {
    const args_ = {token: this.token, ...args}
    return getAddressBookEntries(args_);
  }

  updateForeignAddressId(args) {
    const args_ = {addressBookEntriesApi: this.addressBookEntriesApi, ...args}
    return updateForeignAddressId(args_);
  }

  deleteAddressBookEntry(args) {
    const args_ = {token: this.token, ...args}
    return deleteAddressBookEntry(args_);
  }

  syncAddressBook(args) {
    const args_ = {token: this.token, ...args}
    return syncAddressBook(args_);
  }

  insertAddressBookEntry(args) {
    const args_ = {token: this.token, ...args}
    return insertAddressBookEntry(args_);
  }

  submitExtractionRequest(args) {
    const args_ = {token: this.token, ...args}
    return submitExtractionRequest(args_);
  }

  getExtractionItemWithHeaders(args) {
    const args_ = {token: this.token, ...args}
    return getExtractionItemWithHeaders(args_);
  }

  submitInsertionInstruction(args) {
    const args_ = {token: this.token, ...args}
    return submitInsertionInstruction(args_);
  }

  queryInsertionReceipts(args) {
    const args_ = {token: this.token, ...args}
    return queryInsertionReceipts(args_);
  }

  getAllPlatformAssignments(args) {
    const args_ = {token: this.token, ...args}
    return getAllPlatformAssignments(args_);
  }

  insertPlatformAssignment(args) {
    const args_ = {token: this.token, ...args}
    return insertPlatformAssignment(args_);
  }

  deletePlatformAssignment(args) {
    const args_ = {token: this.token, ...args}
    return deletePlatformAssignment(args_);
  }

  getAllPlatforms(args) {
    const args_ = {token: this.token, ...args}
    return getAllPlatforms(args_);
  }

  insertPlatform(args) {
    const args_ = {token: this.token, ...args}
    return insertPlatform(args_);
  }

  deletePlatform(args) {
    const args_ = {token: this.token, ...args}
    return deletePlatform(args_);
  }
}


const withIsimudApi = (ComponentFunc) => {

  const BmbixFunc = (connectedProps) => {
    const isimudApi = new IsimudApi({token: connectedProps.token});
    const {token, ...userProps} = connectedProps;
    const processedProps = {...userProps, isimudApi};

    return (
      <ComponentFunc {...processedProps} />
    )
  }

  const mapStateToProps = (state) => {
    return {
      token: state.token
    }
  }
  return connect(mapStateToProps)(BmbixFunc);

}


export {
  withIsimudApi,
}
