// @flow
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import EntriesManager from "../EntriesManager";
import Table from "react-bootstrap/Table";
import {
  IssueReopenedIcon,
  TrashIcon,
} from "@primer/octicons-react";
import ListModalDelete from "../ListModalDelete";
import ListModalActivate from "../ListModalActivate";
import ListModalDeactivate from "../ListModalDeactivate";

import { type AccessListType } from "../../entity.js";

const DeactivateList = (props: {
  owner: string,
  list: string,
}) => {
  const {
    owner,
    list,
  } = props;

  const [showModal, setShowModal] = useState(false);
  return showModal ?
    <ListModalDeactivate list={list} onDone={() => setShowModal(false)} owner={owner} /> :
    <Button onClick={e => setShowModal(true)}><IssueReopenedIcon /> Deactivate</Button>
}

const ActivateList = (props: {
  owner: string,
  list: string,
}) => {
  const {
    owner,
    list
  } = props;

  const [showModal, setShowModal] = useState(false);
  return showModal ?
    <ListModalActivate owner={owner} list={list} onDone={() => setShowModal(false) } /> :
    <Button onClick={e => setShowModal(true)}><IssueReopenedIcon /> Activate</Button>
}

const ToggleActive = (props: {
  owner: string,
  list: string,
  isActive: boolean,
}) => {
  const {
    owner,
    list,
    isActive,
  } = props;

  const toggleToInactive = <DeactivateList list={list} owner={owner}/>

  const toggleToActive = <ActivateList list={list} owner={owner}/>

  return isActive ? toggleToInactive : toggleToActive;
}


const DeleteList = (props: {
  list: string,
  owner: string,
}) => {
  const {
    owner,
    list,
  } = props;

  const [showModal, setShowModal] = useState(false);
  return showModal ?
    <ListModalDelete owner={owner} list={list} onDone={() => setShowModal(false) } /> :
    <Button className="mr-2" onClick={e => setShowModal(true)}><TrashIcon /> Delete</Button>
}

const SuccessView = (props: {
  owner: string,
  name: string,
  type: AccessListType,
  isActive: boolean,
  list: string,
}) => {
  const {
    isActive,
    list,
    name,
    owner,
    type,
  } = props;

  return (
    <>
    <h3>{name}</h3>
    <DeleteList list={list} owner={owner} />
    <ToggleActive list={list} isActive={isActive} owner={owner} />
    <Table striped bordered hover>
    <tbody>
    <tr>
     <th>List</th>
     <td>{list}</td>
    </tr>
    <tr>
     <th>Name</th>
     <td>{name}</td>
    </tr>
    <tr>
     <th>Active</th>
     <td>{isActive.toString()}</td>
    </tr>
    <tr>
     <th>Type</th>
     <td>{type}</td>
    </tr>
    </tbody>
    </Table>
    <EntriesManager owner={owner} list={list} />
    </>
  )
}

const FailureView = (props: {

}) => {

  return (
    <p>This list is not available.</p>
  )
}


const WaitingView = (props: {

}) => {

  return (
    <p>Retrieving list</p>
  )
}

export {
  WaitingView,
  SuccessView,
  FailureView,
}
