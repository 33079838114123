// @flow

import {
  PrivateKey as PrivateKeyGW
} from "@bmbix/bmb_martlet_organization_client";

import {
  type PrivateKey ,
  createPrivateKey,
} from "./../entity";


const createPrivateKeyFromGW = (pk: PrivateKeyGW): PrivateKey => {

  const bri = pk.bri;
  const privateKeyId = pk.private_key_id;
  const userSupplied = pk.user_supplied;
  const rescinded = pk.rescinded;
  const updatedAt = new Date(pk.updated_at);
  const createdAt = new Date(pk.created_at);
  const expiresAt = new Date(pk.expires_at);
  const fingerprint = pk.fingerprint;


  const privateKey = createPrivateKey({
    bri,
    privateKeyId,
    userSupplied,
    rescinded,
    updatedAt,
    createdAt,
    expiresAt,
    fingerprint,
  });
  return privateKey;
}


export {
  createPrivateKeyFromGW,
}
