// @flow
import React from "react";

import Form from "react-bootstrap/Form";
import {BModal, BSuccess, BFailure} from "./../../../Widgets/BModal";
import * as S from "sanctuary";

export type PaymentContactEmail = {
  +code: string,
  +description: string,
}

const codes = [
  {code: "23742", description: "some SIC code"},
  {code: "59802", description: "other SIC code"},
];

function PaymentContactEmailFormChange (props: {
  onChange: Function,
  data: string,
}) {
  const {
    onChange,
    data,
  } = props;

  return (
    <Form>
    <Form.Group>
    <Form.Label>SIC Code</Form.Label>
    <Form.Control
      type="text"
      name="paymentContactEmail"
      onChange={onChange}
      placeholder="Enter new payment contact email"
      value={data}
    />
    <Form.Text>Update payment contact email</Form.Text>
    </Form.Group>
    </Form>
  );
}

function View (props: {
  display: string,
  onSubmit: Function,
  onDoneSuccess: Function,
  onDoneFailure: Function,
  onCancel: Function,
  onChange: Function,
  data: Function,
}) {

  const {
    display,
    onCancel,
    onSubmit,
    onDoneSuccess,
    onDoneFailure,
    onChange,
    data,
  } = props;

  return (
    <BModal title={"Update Payment Contact"} display={display} onSubmit={onSubmit} onCancel={onCancel}
  onDoneSuccess={onDoneSuccess} onDoneFailure={onDoneFailure}>
      <PaymentContactEmailFormChange onChange={onChange} data={data} />
      <BSuccess>Successfully updated Payment Contact.</BSuccess>
      <BFailure>Oops, failed to change Payment Contact.</BFailure>
    </BModal>
  );

}

export { View as default };

