// @flow

import React, {
  useState,
} from "react";
import * as R from "ramda";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  connect,
} from "react-redux";

import {
  Spinner,
} from "../Widgets/Toast";
import {
  deletePlatform,
} from "../State";
import {
  withIsimudApi,
} from "../Api/Isimud";


const INPUT = "input";
const WAITING = "waiting";
const SUCCESS = "success";
const FAILURE = "failure";


const RemoveModal = (props:{
  organizationId: string,
  platformIds: Array<string>,
  onComplete: Function,
  onCancel: Function,
  isimudApi: Object,
  deletePlatform: Function,
}) => {
  const {
    organizationId,
    platformIds,
    onComplete,
    isimudApi,
    deletePlatform,
    onCancel,
  } = props;
  const [display, setDisplay] = useState(INPUT);


  const xe = (pid, promise) => {
    /*
     * the bare promise returns null. We need it to return
     * the platformid. That's what this does.
     */
    console.log("running xe for:", pid);
    return new Promise((resolve, reject) => {
      promise.then(
        platform => resolve({platformId: pid}),
      ).catch(
        error => reject(error)
      )
    });
  }

  const handleCancel = (e) => {
    onCancel();
  }

  const handleSubmit = () => {
    console.log("platformIds:", platformIds);
    setDisplay(WAITING);
    const promises = R.map(
      pid => xe(
        pid,
        isimudApi.deletePlatform({organizationId: organizationId, platformId: pid})
      ),
      platformIds,
    )
    Promise.allSettled(promises).then(
      answers => {
        console.log("answers: ", answers);
        const successes = R.filter(R.propEq("status", "fulfilled"), answers);
        const failures = R.filter(R.propEq("status", "rejected"), answers);
        if (failures.length > 0){
          console.log("failures:", failures);
          setDisplay(FAILURE);
        } else {
          console.log("successes:", successes);
          setDisplay(SUCCESS);
        }
        const values = R.map(x => x.value, successes);
        R.map(
          v => deletePlatform(v.platformId),
          values,
        );
      }
    ).catch(
      error => console.log("there was an error")
    );
  }

  const input = display === INPUT ? (
    <p>Delete platform&#63;</p>
  ) : null;

  const waiting = display === WAITING ? (
    <Spinner />
  ) : null;

  const success = display === SUCCESS ? (
    <p>Success!</p>
  ) : null;

  const failure = display === FAILURE ? (
    <p>Failure!</p>
  ) : null;

  const cancelButton = display === INPUT || display === WAITING ? (
      <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
      ): null;

  const completeButton = display === SUCCESS || display === FAILURE ? (
      <Button variant="secondary" onClick={onComplete}>Done</Button>
      ): null;

  const submitButton = display === INPUT ? (
      <Button variant="primary" onClick={handleSubmit}>Delete</Button>
      ): null;

  return (
    <>
    <Modal show={true}>
      <Modal.Header>
        <Modal.Title>Remove Platforms</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {input}
        {waiting}
        {success}
        {failure}
      </Modal.Body>
      <Modal.Footer>
        {submitButton}
        {cancelButton}
        {completeButton}
      </Modal.Footer>
    </Modal>
    </>
  );

}

const redux_connector = connect(
  undefined,
  {deletePlatform},
)

export default withIsimudApi(redux_connector(RemoveModal));

export {
  RemoveModal,
}

