// @flow
import {
  createElement as el,
  useState,
} from "react";
import { type Organization } from "./../../../Entity/Types.js";


import { TheButton, TheForm} from "./view.js"

const Controller = (props: {
  organization: Organization
}) => {
  const {
    organization,
  } = props;
  const [ active, setActive ] = useState(false);

  const handleActivate= e => setActive(true);

  const handleDeactivate = e => setActive(false);

  const theButton = el (
    TheButton,
    {
      handleActivate,
    }
  );


  const theForm = el (
    TheForm,
    {
      organization,
      handleDeactivate,
    }
  );


  return active ? theForm : theButton;
}


export { Controller } ;
