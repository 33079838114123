// @flow
import React, {createElement as el, useState,} from "react";
import log from "loglevel";
import {
  connect,
} from "react-redux";

import Form from "react-bootstrap/Form";

import {
  Link,
} from "react-router-dom";

import {
  type Account,
} from "../../Entity/Types.js";
import {
  withIsimudApi,
} from "../../Api/Isimud";
import {
  withMartletOrganizationApi,
} from "../../Api/MartletOrganization";
import {
  createPermissionForAddress,
} from "../../Api/MartletOrganization/Address.js";
import {
  addAddress,
  addOrg,
  addPlatform,
  addPlatformAssignment,
  addPermission,
  addAccessKey,
  setSettings,
} from "../../State";
import {
  syncToServer,
} from "../../Util";
import {
  setName,
  addOrganization,
} from "./../../Api/MartletOrganization/Users.js";
import {
  useToken,
} from "./../../State/Token.js";

import {
  FAILURE,
  SUCCESS,
  WAITING,
} from "../../Util";
import {
  Screen,
} from "../../Widgets/Wizard";

import {
  View,
} from "./View.js";


const logger = log.getLogger("NewUserWizard2");


const Controller_ = (props: {
  history: Function,
  martletOrganizationApi: Object,
  isimudApi: Object,
  addAddress: Function,
  addOrg: Function,
  addPlatform: Function,
  addPlatformAssignment: Function,
  addAccessKey: Function,
  addPermission: Function,
  setSettings: Function,
  account: Account,
}) => {

  const {
    history,
    isimudApi,
    martletOrganizationApi,
    addAddress,
    addOrg,
    addPlatform,
    addPlatformAssignment,
    addAccessKey,
    addPermission,
    setSettings,
    account,
  } = props;

  const [display, setDisplay] = useState();
  const [showScreen, setShowScreen] = useState(0);
  const [timezone, setTimezone] = useState();
  const [language, setLanguage] = useState();
  const [userName, setUserName] = useState();
  const [organizationName, setOrganizationName] = useState();
  const userId = account.id;
  const token = useToken();

  const onAbort = e => {
    logger.debug("going home")
    history.push("/");
  }

  const handleCompletion = e => {
    logger.debug(
      "Completing",
      "timezone", timezone,
      "language", language,
      "userName", userName,
      "organizationName", organizationName,
    );
    handleStart(e);
  }

  const screen0 = <Screen
    key="zero"
    onNext={e => setShowScreen(1)}
    nextValue="next"
    onSkip={onAbort}
    skipValue="Cancel"
    >
    <h2>Welcome New User</h2>
    <ol>
    <li key="profile">Create your profile (optional)</li>
    <li key="organization">Create an organization (optional)</li>
    </ol>
    </Screen>

  const screen1 = <Screen
    key="one"
    onBack={e => setShowScreen(0)}
    backValue="back"
    onSkip={e => setShowScreen(2)}
    skipValue="skip"
    onNext={e => setShowScreen(2)}
    nextValue="next"
    >
    <h2>Create your profile</h2>
    <Form>
      <Form.Group>
      <Form.Label>Name</Form.Label>
      <Form.Control
        type="text"
        name="userName"
        onChange={e => setUserName(e.target.value)}
        placeholder="Enter new user name"
        value={userName}
      />
      </Form.Group>
      <Form.Group>
      <Form.Label>Timezone</Form.Label>
      <Form.Control
        type="text"
        name="timezone"
        onChange={e => setTimezone(e.target.value)}
        placeholder="Enter new timezone"
        value={timezone}
      />
      </Form.Group>
      <Form.Group>
      <Form.Label>Language</Form.Label>
      <Form.Control
        type="text"
        name="language"
        onChange={e => setLanguage(e.target.value)}
        placeholder="Enter new language"
        value={language}
      />
      </Form.Group>
    </Form>
    </Screen>

  const screen2 = <Screen
    key="two"
    onBack={e => setShowScreen(1)}
    backValue="back"
    onSkip={e => setShowScreen(3)}
    skipValue="skip"
    onNext={handleCompletion}
    nextValue="next"
    >
    <h2>Create an organization</h2>
    <Form>
      <Form.Group>
      <Form.Label>Name</Form.Label>
      <Form.Control
        type="text"
        name="organizationName"
        onChange={e => setOrganizationName(e.target.value)}
        placeholder="Enter new user name"
        value={organizationName}
      />
      </Form.Group>
    </Form>
    </Screen>

  const screen3 = <Screen
    key="three"
    >
    <p>New user setup completed!</p>
    <Link to="/" >go to home page</Link>
    </Screen>

  const screen = showScreen === 0 ? screen0 :
    showScreen === 1 ? screen1 :
    showScreen === 2 ? screen2 :
    showScreen === 3 ? screen3 : null;

  const update = message => log.debug(message);

  const handleStart = async (e) => {
    try {
      setDisplay(WAITING);
      update("Starting wizard");
      if (!!organizationName) {
        const actor = `bmbix://user/${account.sub}`;
        update(`Generating resources for ${actor}`);
        update("Creating organization");
        const organization = await addOrganization({
          token,
          userId,
          name: organizationName,
          isClosed: false,
        })
        addOrg(organization);
        update("Organization created");
        update("Creating sales address");
        const address1 = await martletOrganizationApi.addAddress({
          organizationId: organization.id,
          displayName: "Sales Ledger",
          purpose: "sales",
        });
        addAddress(address1);
        update("Sales address created");
        update("Giving manager role for sales address");
        await createPermissionForAddress({
          token,
          addressId: address1.id,
          actor,
          power: "bmbix://role/address/manager",
        });
        update("Gave manager role for sales address");
        update("Creating purchases address");
        const address2 = await martletOrganizationApi.addAddress({
          organizationId: organization.id,
          displayName: "Purchase Ledger",
          purpose: "purchases",
        });
        addAddress(address2);
        update("Purchases address created");
        update("Giving manager role for purchases address");
        await createPermissionForAddress({
          token,
          addressId: address2.id,
          actor,
          power: "bmbix://role/address/manager",
        });
        update("Gave manager role for purchases address");
      }
      if (!!userName) {
        await setName({token, userId, name: userName})
        update("Set user name")
      }
      if (!!timezone) {
        await setTimezone({token, userId, timezone: timezone})
        update("Set user timezone")
      }
      if (!!language) {
        await setLanguage({token: userId, language: language})
        update("Set user language")
      }
      update("Re-syncing with server");
      await syncToServer({
        addOrg,
        addAddress,
        addAccessKey,
        addPermission,
        addPlatform,
        addPlatformAssignment,
        setSettings,
        martletOrganizationApi,
        isimudApi,
      });
      update("Wizard successfully completed");
      setDisplay(SUCCESS);
      setShowScreen(3)
    }
    catch (e) {
      logger.error("NewUserWizard2:", e);
      setDisplay(FAILURE);
    }
  }


  return el(
    View,
    {
    },
    [screen],
  )
}

const connector = connect(
  state => {
    return {
      account: state.account,
   }
  },
  {
    addAddress,
    addOrg,
    addPlatform,
    addPlatformAssignment,
    addPermission,
    addAccessKey,
    setSettings,
  }
);

const Controller = connector(
  withIsimudApi(withMartletOrganizationApi(Controller_))
);

export {
  Controller,
}
