// @flow
import log from "loglevel";
import React, {
  useState,
} from "react";
import * as R from "ramda";
import {
  connect,
} from "react-redux";
import Button from "react-bootstrap/Button";
import {
  Link,
} from "react-router-dom";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import {
  type Account,
} from "../Entity/Types.js";
import {
  withIsimudApi,
} from "../Api/Isimud";
import {
  withMartletOrganizationApi,
} from "../Api/MartletOrganization";
import {
  addOrganization,
} from "../Api/MartletOrganization/Users.js";
import {
  createPermissionForAddress,
} from "../Api/MartletOrganization/Address.js";
import {
  addAddress,
  addOrg,
  addPlatform,
  addPlatformAssignment,
  addPermission,
  addAccessKey,
  setSettings,
} from "../State";
import {
  syncToServer,
} from "../Util";
import { useToken } from "./../State/Token.js";

const logger = log.getLogger("NewUserWizardView");

const {
  append,
  curry,
  map,
} = R;

const INPUT = "INPUT";
const WAITING = "WAITING";
const SUCCESS = "SUCCESS";
const FAILURE = "FAILURE";


const ProgressLog = (props: {
  log: Array<string>,
}) => {
  const {
    log,
  } = props;

  return (
    <ol>
    {map(item => <li key={Math.random()} >{item}</li>, log)}
    </ol>
  );
}

const WizardForm = (props: {
  onChange: (o:?string, p:?string) => void,
  organizationName: ?string,
  platformType: ?string,
}) => {
  const {
    onChange,
    organizationName,
    platformType,
  } = props;

  const handleChange = (e) => {
    const value = e.target.value;
    let o = organizationName
    let p = platformType;

    switch (e.target.name) {
      case "organizationName":
        o = value;
        break;
      case "platformType":
        p = value;
        break;
      default:
        throw new Error("Unexpected event target name");
    }
    onChange(o, p);
  }

  return (
    <Form>
      <Form.Group>
      <Form.Label>1. Organization name</Form.Label>
      <Form.Control type="text" name="organizationName"  onChange={handleChange} defaultValue={organizationName}/>
      <Form.Text>Give a name for your organization</Form.Text>
      </Form.Group>
      <Form.Group>
      <Form.Label>2. Choose a platform type</Form.Label>
      <Form.Control name="platformType" as="select" onChange={handleChange} defaultValue={platformType}>
        <option value="freeagent">FreeAgent</option>
        <option value="freshbooks">Freshbooks</option>
        <option value="kashflow">Kashflow</option>
        <option value="qbo">Quickbooks Online</option>
        <option value="sage">Sage</option>
        <option value="xero">Xero</option>
        <option value="none">No Accounting Platform</option>
      </Form.Control>
      <Form.Text>Choose your accounting software platform or select none</Form.Text>
      </Form.Group>
    </Form>
  )
}

const View_ = (props: {
  martletOrganizationApi: Object,
  isimudApi: Object,
  addAddress: Function,
  addOrg: Function,
  addPlatform: Function,
  addPlatformAssignment: Function,
  addAccessKey: Function,
  addPermission: Function,
  setSettings: Function,
  account: Account,
}) => {
  const {
    isimudApi,
    martletOrganizationApi,
    addAddress,
    addOrg,
    addPlatform,
    addPlatformAssignment,
    addAccessKey,
    addPermission,
    setSettings,
    account,
  } = props;

  const [organizationName, setOrganizationName] = useState("Jones Bros");
  const [platformType, setPlatformType] = useState("xero");
  const [platformURL, setPlatformURL] = useState();
  const [display, setDisplay] = useState(INPUT);
  const [progressLog, setProgressLog] = useState([]);
  const userId = account.id;
  const token = useToken();

  const updateProgress = curry((progressLog, setProgressLog, message) => {
    setProgressLog( existingLog => append(message, existingLog));
  });
  const update = updateProgress(progressLog, setProgressLog);

  const handleStart = async (e) => {
    try {
      setDisplay(WAITING);
      update("Starting wizard");
      const actor = `bmbix://user/${account.sub}`;
      update(`Generating resources for ${actor}`);
      update("Creating organization");
      const organization = await addOrganization({
        token,
        userId,
        name: organizationName,
        isClosed: false,
      })
      addOrg(organization);
      update("Organization created");
      update("Creating sales address");
      const address1 = await martletOrganizationApi.addAddress({
        organizationId: organization.id,
        displayName: "Sales Ledger",
        purpose: "sales",
      });
      addAddress(address1);
      update("Sales address created");
      update("Giving manager role for sales address");
      await createPermissionForAddress({
        token,
        addressId: address1.id,
        actor,
        power: "bmbix://role/address/manager",
      });
      update("Gave manager role for sales address");
      update("Creating purchases address");
      const address2 = await martletOrganizationApi.addAddress({
        organizationId: organization.id,
        displayName: "Purchase Ledger",
        purpose: "purchases",
      });
      addAddress(address2);
      update("Purchases address created");
      update("Giving manager role for purchases address");
      await createPermissionForAddress({
        token,
        addressId: address2.id,
        actor,
        power: "bmbix://role/address/manager",
      });
      update("Gave manager role for purchases address");
      if (platformType === "none") {
        update("Skipping platform creation as none selected");
      } else {
        update("Creating platform");
        const platform = await isimudApi.insertPlatform({
          organizationId: organization.id,
          name: `${platformType}-1`,
          type: platformType,
        });
        addPlatform(platform);
        update("Platform created");
        update("Assigning sales address to platform");
        const platformAssignment1 = await isimudApi.insertPlatformAssignment({
          organizationId: organization.id,
          platformId: platform.platformId,
          addressId: address1.id,
        });
        addPlatformAssignment(platformAssignment1);
        update("Sales address assigned to platform");
        update("Assigning purchases address to platform");
        const platformAssignment2 = await isimudApi.insertPlatformAssignment({
          organizationId: organization.id,
          platformId: platform.platformId,
          addressId: address2.id,
        });
        addPlatformAssignment(platformAssignment2);
        update("Purchases address assigned to platform");
        setPlatformURL(`/m/organizations/${organization.id}/platforms/${platform.platformId}/${platformType}`);
      }
      update("Re-syncing with server");
      await syncToServer({
        addOrg,
        addAddress,
        addAccessKey,
        addPermission,
        addPlatform,
        addPlatformAssignment,
        setSettings,
        martletOrganizationApi,
        isimudApi,
      });
      update("Wizard successfully completed");
      setDisplay(SUCCESS);
    }
    catch (e) {
      logger.error("NewUserWizardView:", e);
      setDisplay(FAILURE);
    }
  }

  const input = display === INPUT ? (
    <>
    <WizardForm
      onChange={(o, p) => {
        logger.debug("o:", o);
        logger.debug("p:", p);
        setOrganizationName(o);
        setPlatformType(p);
      }}
      organizationName={organizationName}
      platformType={platformType}
    />
    <Button onClick={handleStart} >Go</Button>
    </>
  ): null;

  const success = display === SUCCESS  ? (
    <>
    <h2>All done!</h2>
    <p>All you have to do now is make the connection to your {platformType} platform</p>
    <Link target="_blank" rel="noopener noreferrer"  to={platformURL}>Connect to Platform</Link>
    <p>(Opens in a new tab)</p>
    <p>When you have done that, you can <Link to={"/"}>get started</Link>.</p>
    </>
  ): null;

  const failure = display === FAILURE ? (
    <p>Ooops, something has gone wrong</p>
  ): null;

  const waiting = display === WAITING ? (
    <p>Working ...</p>
  ): null;

  return (
    <Container fluid={true}>
    <Row>
    <h1>Let&apos;s get started</h1>
    </Row>
    <Row>
    <Col>
      {input}
      {waiting}
      <ProgressLog log={progressLog}/>
      {success}
      {failure}
    </Col>
    <Col>
      <p>The wizard will: </p>
      <ul>
      <li>Create an organization with the name you supply.</li>
      <li>Give you the manager role for your new organization.</li>
      <li>Create a Bmbix address for a sales ledger or accounts receivable process.</li>
      <li>Create a Bmbix address for your purchase ledger or accounts payable process.</li>
      <li>Give you the manager role for each of the above addresses</li>
      <li>Create a link from your new organization to your accounting platform.</li>
      <li>Connect your two new Bmbix addresses to your accounting platform.</li>
      </ul>
      <p>Do not worry - everything can be adjusted from the <Link to="/m/organizations">management console</Link>.</p>
    </Col>
    </Row>
    </Container>
  );
}

const connector = connect(
  state => {
    return {
      account: state.account,
   }
  },
  {
    addAddress,
    addOrg,
    addPlatform,
    addPlatformAssignment,
    addPermission,
    addAccessKey,
    setSettings,
  }
);

const NewUserWizardView = connector(
  withIsimudApi(withMartletOrganizationApi(View_))
);

export {
  NewUserWizardView,
  WizardForm,
}
