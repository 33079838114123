import React from "react";

import {
  connect,
} from "react-redux";

import {
  getUnaddressedMessage,
  getUnaddressedMessages,
  getUnaddressedMessageByMessageToken,
  updateWithClaim,
  updateWithClaimFuture,
} from "./UnaddressedMessages.js";


class KlondikeApi {

  constructor (args) {
    this.token = args.token;
  }

  getUnaddressedMessage(args) {
    const args_ = {token: this.token, ...args}
    return getUnaddressedMessage(args_);
  }

  getUnaddressedMessages(args) {
    const args_ = {token: this.token, ...args}
    return getUnaddressedMessages(args_);
  }

  getUnaddressedMessageByMessageToken(args) {
    const args_ = {token: this.token, ...args}
    return getUnaddressedMessageByMessageToken(args_);
  }

  updateWithClaim(args) {
    const args_ = {token: this.token, ...args}
    return updateWithClaim(args_);
  }

  updateWithClaimFuture() {
    return updateWithClaimFuture(this.token);
  }

}


const withKlondikeApi = (ComponentFunc) => {

  const BmbixFunc = (connectedProps) => {
    const klondikeApi = new KlondikeApi({token: connectedProps.token});
    const {token, ...userProps} = connectedProps;
    const processedProps = {...userProps, klondikeApi};

    return (
      <ComponentFunc {...processedProps} />
    )
  }
  const mapStateToProps = (state) => {
    return {
      token: state.token
    }
  }
  return connect(mapStateToProps)(BmbixFunc);

}


export {
  withKlondikeApi,
}
