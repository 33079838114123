// @flow
import Future from "fluture";

import {
  ApiClient,
  AddressesApi,
  AccessListEntry as AccessListEntryGW,
} from "@bmbix/bmb_martlet_organization_client";
import { gwToAccessListEntry } from "./adapt.js";

const addEntry = (token: string) => (owner: string) => (list: string) => (sender: string): Future => {

  const apiInstance = new AddressesApi();
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;

  const body = AccessListEntryGW.constructFromObject({
    sender,
  });
  return Future((reject, resolve) => {
    console.log("sender", sender);
    apiInstance.createAccessListEntry(body, owner, list, (error, data, response) => {
      if (error) {
        console.log("error", error);
        reject(error);
      } else {
        const gw = data.access_list_entry;
        const accessListEntry = gwToAccessListEntry(gw);
        resolve(accessListEntry);
      }
    });
    return console.log
  });
}

export { addEntry }
