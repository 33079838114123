import React, {useState} from "react";

import Image from "react-bootstrap/Image";


const View = (props: {
  src: string,
}) => {
  const {
    src,
  } = props;
  const [error, setError] = useState(false);
  const fallback = "https://via.placeholder.com/240x180.png?text=Your%20Logo";

  const onError = e => {
    setError(true);
  }

  return !error ? (
        <Image key={Math.random()} className="h-10" src={src} alt="organization logo" height="28px" width="28px" onError={onError} />
  ): (
        <Image key={Math.random()} className="h-10" src={fallback} alt="organization logo" height="28px" width="28px"/>
  );

}

export {
  View,
}
