// @flow
import React, {
  useState,
} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import * as R from "ramda";


import {
  withIsimudApi,
} from "../Api/Isimud";
import {
  type BmbixAddress,
  type Platform,
} from "../Entity/Types.js";

const INPUT = "input";
const WAITING = "waiting";
const SUCCESS = "success";
const FAILURE = "failure";


const AssignModal_ = (props: {
  onComplete: Function,
  onCancel: Function,
  platforms: Array<Platform>,
  address: BmbixAddress,
  isimudApi: Object,
}) => {

  const {
    onComplete,
    onCancel,
    platforms,
    address,
    isimudApi,
  } = props;

  const organizationId = address.organizationId;
  const addressId = address.id;

  const [display, setDisplay] = useState(INPUT);
  const [platformId, setPlatformId] = useState(null);
  const [platformAssignment, setPlatformAssignment] = useState();


  const handleClickSubmit = (e) => {
    if ( !platformId ) {
      alert(`You must select a platform`);
    }
    setDisplay(WAITING);
    isimudApi.insertPlatformAssignment({
      organizationId,
      platformId,
      addressId,
    }).then(
      platformAssignment => {
        setPlatformAssignment(platformAssignment);
        setDisplay(SUCCESS);
      }
    ).catch(
      error => setDisplay(FAILURE)
    );
  }

  const cancelButton = display === INPUT ? (
    <Button onClick={onCancel} variant="secondary">Cancel</Button>
  ): null;

  const submitButton = display === INPUT ? (
    <Button onClick={handleClickSubmit} variant="primary">Assign</Button>
  ): null;

  const doneButton = display === SUCCESS || display === FAILURE ? (
    <Button onClick={e => onComplete(platformAssignment) } variant="primary">Back</Button>
  ): null ;

  const input = display === INPUT ? (
    <Form>
      <Form.Group>
      <Form.Label>Select a platform</Form.Label>
      <Form.Control
        as="select"
        onClick={ e => setPlatformId(e.target.value) }
      >
      {R.map(
        pf => <option key={pf.platformId} value={pf.platformId}>{pf.name}</option>,
        platforms,
      )}
      </Form.Control>
      </Form.Group>
    </Form>
  ): null;

  const waiting = display === WAITING ? (
    <p>Waiting ...</p>
  ): null ;

  const success = display === SUCCESS ? (
    <p>Success!</p>
  ): null;

  const failure = display === FAILURE ? (
    <p>Ooops!, Something went wrong.</p>
  ): null;

  return (
    <Modal show={true} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Assign Platform</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {input}
        {waiting}
        {success}
        {failure}
      </Modal.Body>

      <Modal.Footer>
        {cancelButton}
        {submitButton}
        {doneButton}
      </Modal.Footer>
    </Modal>
  )

}


const AssignModal = withIsimudApi(AssignModal_);


export {
  AssignModal,
}
