// @flow
import { createElement as el } from "react";
import { useMachine } from "@xstate/react";
import { useParams } from "react-router-dom";
import { createMachine, assign } from "xstate";
import { createPaymentMethodLink } from "./../../../Api/MartletOrganization/Users.js";
import { useAccount } from "./../../../State/Account.js";
import { useToken } from "./../../../State/Token.js";
import { View } from "./modal-view.js";




const Controller = ({
  onDone,
  onCancel,
}: {
  onDone: Function,
  onCancel: Function,
}) => {
  const [ apiState, apiSend ] = useMachine(() => createMachine({


  id: "apiMachine",
  context: {
    token: undefined,
    userId: undefined,
    paymentMethodId: undefined,
    organizationId: undefined,
    errors: undefined,
    paymentMethodLink: undefined,
  },
  initial: "unconfigured",
  states: {
    unconfigured: {
      on: {
        CONFIGURE: {
          target: "ready",
          actions: assign({
            userId: (c, e, m) => e.userId,
            token: (c, e, m) => e.token,
            paymentMethodId: (c, e, m) => e.paymentMethodId,
            organizationId: (c, e, m) => e.organizationId,
          }),
        }
      },
    },
    ready: {
      on: {
        SUBMIT: "working",
      }
    },
    working: {
      invoke: {
        src: (context, event) => createPaymentMethodLink({
          token: context.token,
          userId: context.userId,
          paymentMethodId: context.paymentMethodId,
          organizationId: context.organizationId,
        }),
        onDone: {
          target: "success",
          actions: assign({
            paymentMethodLink: (c, e) => e.data,
          })
        },
        onError: {
          target: "failure",
          actions: assign({
            errors: (c, e) => e.data,
          })
        }
      },
    },
    success: {
      entry: (c, e) => {
        onDone(c.paymentMethodLink);
        console.log("donededonedonedone");
      },
      on: {
        RESET: "ready",
      }
    },
    failure: {
      on: {
        RESET: "ready"
      }
    },
  },





  }));
  const account = useAccount();
  const userId = account.id;
  const token = useToken();
  const {paymentMethodId} = useParams();

  const onModalCancel = onCancel

  const onModalSubmit = e => {
    console.log("Submitted data event: ", e);
    const organizationId = e.organizationId;
    apiSend({type: "CONFIGURE", organizationId, userId, paymentMethodId,
      token});
    apiSend({type: "SUBMIT"});
  }

  return el(
    View,
    {
      apiState,
      onModalCancel,
      onModalSubmit,
    },
  )
}



export {
  Controller,
}

