import {
  acceptances,
  accessKeys,
  accessListsMutations,
  account,
  addressBookEntries,
  addresses,
  badTokenFlag,
  extractionCandidateReports,
  messageNotes,
  messageTypes,
  orgs,
  permissions,
  platforms,
  platformAssignments,
  processedReceipts,
  settings,
  token,
  idToken,
  ECRCompletedNotices,
  userProfiles,
} from "./State";
import {
  addressProfiles,
} from "./State/AddressProfiles.js";
import {
  signupOrganization,
  signupPlatformType,
} from "./Pages/HammockSignupWizard/state";
import {
  oauth2Clients,
} from "./OrganizationProxies/store.js";

import {
  ADD_NOTIF,
  DEL_NOTIF,
} from "./State/ActionTypes";
const R = require("redux");
const rda = require("ramda");

export const addNotif = (notif) => {
  return {
    type: ADD_NOTIF,
    notif: notif
  }
}

export const delNotif = (notif_id) => {
  return {
    type: DEL_NOTIF,
    notif_id: notif_id
  }
}

const notifs = (state=[], action) => {
  switch (action.type) {
    case ADD_NOTIF:
      return rda.uniq(rda.append(action.notif, state))
    case DEL_NOTIF:
      return rda.filter((n) => (n.id !== action.notif_id) , state)
    default:
      return state
  }
}

export const rootReducer = R.combineReducers({
  ECRCompletedNotices,
  acceptances,
  accessKeys,
  accessListsMutations,
  account,
  addressBookEntries,
  addressProfiles,
  addresses,
  badTokenFlag,
  extractionCandidateReports,
  idToken,
  messageNotes,
  messageTypes,
  notifs,
  oauth2Clients,
  orgs,
  permissions,
  platformAssignments,
  platforms,
  processedReceipts,
  settings,
  signupOrganization,
  signupPlatformType,
  token,
  userProfiles,
})
