// @flow

import * as S from "sanctuary";

import {
  instanceService,
} from "../Entity/InstanceService.js";
import {
  type KashflowConnection,
} from "../Entity/Types.js";
import {
  AuthnApi,
  ApiClient,
  Connection as ConnectionGw,
} from "@bmbix/bmb_kashflow_v1_authn_client";


const createKashflowConnectionFromGW = (connectionGw:ConnectionGw) => {
  return instanceService.createKashflowConnection({
    id: connectionGw.id,
    platformId: connectionGw.platform_id,
    username: connectionGw.username,
    password: connectionGw.password,
  });
}

const insert = (args: {
  token: string,
  organizationId: string,
  platformId: string,
  username: string,
  password: string,
  defaultClient_: Object,
}): Promise<KashflowConnection> => {
  const {
    token,
    organizationId,
    platformId,
    username,
    password,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  const body = ConnectionGw.constructFromObject({
    platform_id: platformId,
    username,
    password,
  });

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AuthnApi(defaultClient);
  return new Promise((resolve, reject) => {
    apiInstance.insert(
      body,
      organizationId,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          const connectionGw = data.connection;
          const connection = createKashflowConnectionFromGW(connectionGw);
          resolve(connection);
        }
      }
    );
  });
}

const query = (args: {
  token: string,
  organizationId: string,
  platformId: string,
  defaultClient_: Object,
}): Promise<Array<KashflowConnection>> => {
  const {
    token,
    organizationId,
    platformId,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AuthnApi(defaultClient);
  return new Promise((resolve, reject) => {
    apiInstance.query(
      organizationId,
      platformId,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          const connectionsGw = data.connections;
          const connections = S.map (createKashflowConnectionFromGW) (connectionsGw);
          resolve(connections);
        }
      }
    );
  });
}

const select = (args: {
  token: string,
  organizationId: string,
  connectionId: string,
  defaultClient_: Object,
}): Promise<KashflowConnection> => {
  const {
    token,
    organizationId,
    connectionId,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AuthnApi(defaultClient);
  return new Promise((resolve, reject) => {
    apiInstance.select(
      organizationId,
      connectionId,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          const connectionGw = data.connection;
          const connection = createKashflowConnectionFromGW(connectionGw);
          resolve(connection);
        }
      }
    );
  });
}

const callDelete = (args: {
  token: string,
  organizationId: string,
  connectionId: string,
  defaultClient_: Object,
}): Promise<KashflowConnection> => {
  const {
    token,
    organizationId,
    connectionId,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AuthnApi(defaultClient);
  return new Promise((resolve, reject) => {
    apiInstance.callDelete(
      organizationId,
      connectionId,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          resolve(data); // should be null
        }
      }
    );
  });
}

const test = (args: {
  token: string,
  organizationId: string,
  connectionId: string,
  defaultClient_: Object,
}): Promise<string> => {
  const {
    token,
    organizationId,
    connectionId,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AuthnApi(defaultClient);
  return new Promise((resolve, reject) => {
    apiInstance.test(
      organizationId,
      connectionId,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          const status = data.status;
          resolve(status);
        }
      }
    );
  });
}


export {
  createKashflowConnectionFromGW,
  callDelete,
  insert,
  query,
  select,
  test,
}
