// @flow

import React from "react";
import Form from "react-bootstrap/Form";


const CloseForm = () => {
  return (
    <Form>
    <Form.Group>
    <Form.Label>Close Addresses</Form.Label>
    <Form.Text>Are you sure?</Form.Text>
    </Form.Group>
    </Form>
  )
}

export {
  CloseForm,
}

