// @flow
import React, {useState} from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  UserRolesTable,
  UserAddressRolesTable
} from "../UserRolesTable";
import {
  type BmbixAddress,
  type Account,
  type Organization,
  type Permission,
} from "../../../Entity/Types.js";

const View = (props: {
  account: Account,
  addresses: Array<BmbixAddress>,
  organizations: Array<Organization>,
  permissions: Array<Permission>,
  addressProfiles: Array<Function>,
}) => {
  const {
    account,
    organizations,
    permissions,
    addresses,
    addressProfiles,
  } = props;

  const [key, setKey] = useState("organizations");

  return (
    <>
    <h2>My Teams</h2>
    <Tabs activeKey={key} onSelect={k => setKey(k)}>

    <Tab eventKey="organizations" title="Organizations">
    <h3>Organizations</h3>
      <UserRolesTable
        account={account}
        organizations={organizations}
        permissions={permissions}
      />
    </Tab>

    <Tab eventKey="addresses" title="Addresses">
    <h3>Addresses</h3>
      <UserAddressRolesTable
        account={account}
        addresses={addresses}
        permissions={permissions}
        addressProfiles={addressProfiles}
      />
    </Tab>

    </Tabs>

    </>
  );
}

export { View }
