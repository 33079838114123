// @flow
import * as R from "ramda";
import log from "loglevel";
import Future from "fluture";

import {
  instanceService
} from "../../Entity/InstanceService.js";
import type {
  Organization,
  Certificate,
  PublicKey,
  PaymentMethodLink,
} from "../../Entity/Types.js";
import {
  ApiClient,
  Address as AddressGW,
  OrganizationsApi,
  OrganizationResponse as OrganizationResponseGW,
  Image as ImageGW,
  Certificate as CertificateGW,
  PublicKey as PublicKeyGW,
  GenericPatch as GenericPatchGW,
  GenericPatchItem as GenericPatchItemGW,
  PaymentMethodLink as PaymentMethodLinkGW,
} from "@bmbix/bmb_martlet_organization_client";
import {
  store as reduxStore,
} from "../../State/Store.js";
import { updateOrg } from "./../../State/Organizations.js";
import { callBack } from "./../CallBack/";
import {
  createOrganizationFromGW,
  createPaymentMethodLinkFromGW,
  createAddressFromGW,
} from "./adapters.js";

const logger = log.getLogger("Api:MartletOrganiztion");

const createPublicKeyFromGW = (gw: PublicKeyGW): PublicKey => {
  return instanceService.createPublicKey({
    data: gw.data,
    uri: gw.uri,
  });
}

const createCertificateFromGW = (gw: CertificateGW): Certificate => {
  return instanceService.createCertificate({
    certificateId: gw.certificate_id,
    name: gw.name,
    data: gw.data,
    mediaType: gw.media_type,
  });
}

const getOrganization = (args: {
  token: string,
  organizationId: string,
}): Promise < OrganizationResponseGW > => {
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = args.token;
  let apiInstance = new OrganizationsApi();

  const dataHandler = data => {
    return data;
  }

  return new Promise((resolve, reject) => {
    apiInstance.getOrganization(
      args.organizationId,
      callBack(reject, resolve) (dataHandler),
    );
  });
}

const getAllOrganizations = async (args: {
  token: string,
  organizationIds: Array < string > ,
}): Promise < Array < Organization >> => {
  const promises = await Promise.allSettled(
    R.map(
      orgId => getOrganization({token: args.token, organizationId: orgId}),
      args.organizationIds,
    ));
  const fulfilled_promises = R.filter(
    p => p.status === "fulfilled",
    promises,
  );
  const organization_responses_gw = R.map(
    fp => fp.value,
    fulfilled_promises,
  );
  const organizations_gw = R.map(
    or_gw => or_gw.organization,
    organization_responses_gw,
  );
  const organizations = R.map(
    createOrganizationFromGW,
    organizations_gw,
  );
  return organizations;
}

const updateOrganization = (args: {
  token: string,
  organizationId: string,
  isClosed: boolean,
  ppcSignatory: boolean,
  sicCode: string,
  headcount: number,
  apiInstance?: OrganizationsApi,
}): Promise<Organization> => {
  console.log("args", args);
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = args.token;
  let apiInstance;
  if (!args.apiInstance) {
    apiInstance = new OrganizationsApi();
  }
  else {
    apiInstance = args.apiInstance;
  }

  let body = new GenericPatchGW();
  const patches = []
  if (!!args && args.hasOwnProperty("paymentContactEmail")) {
    const patch = new GenericPatchItemGW()
    patch.op = "replace";
    patch.path = "/payment_contact_email";
    patch.value = args.paymentContactEmail;
    patches.push(patch);
  }
  if (!!args && args.hasOwnProperty("ppcSignatory")) {
    const patch = new GenericPatchItemGW()
    patch.op = "replace";
    patch.path = "/ppc_signatory";
    patch.value = args.ppcSignatory;
    patches.push(patch);
  }
  if (!!args.headcount) {
    const patch = new GenericPatchItemGW()
    patch.op = "replace";
    patch.path = "/headcount";
    patch.value = args.headcount;
    patches.push(patch);
  }
  if (!!args && args.hasOwnProperty("isClosed")) {
    const patch = new GenericPatchItemGW()
    patch.op = "replace";
    patch.path = "/is_closed";
    patch.value = args.isClosed;
    patches.push(patch);
  }
  if (!!args.sicCode) {
    const patch = new GenericPatchItemGW()
    patch.op = "replace";
    patch.path = "/sic_code";
    patch.value = args.sicCode;
    patches.push(patch);
  }
  console.log("patches", patches);
  body.patches = patches;
  console.log("body", body);

  const dataHandler = data => {
    const organizationGW = data.organization;
    const organization = createOrganizationFromGW(organizationGW);
    reduxStore.dispatch(updateOrg(organization));
    return organization;
  }

  return new Promise((resolve, reject) => {
    apiInstance.updateOrganization(
      body,
      args.organizationId,
      callBack(reject, resolve) (dataHandler),
    );
  });
}

const setIcon = (args: {
  token: string,
  organizationId: string,
  mediaType: string,
  data: string,
  defaultClient_: Object,
}): Promise<null> => {
  const {
    token,
    organizationId,
    mediaType,
    data,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  const body = ImageGW.constructFromObject({
    data: data,
    media_type: mediaType,
  });

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new OrganizationsApi(defaultClient);

  const dataHandler = data => {
    const imageId= data.image_id;
    logger.debug("imageId:", imageId);
    return null;
  }

  return new Promise((resolve, reject) => {
    apiInstance.setIcon(
      body,
      organizationId,
      callBack(reject, resolve)(dataHandler),
    );
  });
}

const setCertificate= (args: {
  token: string,
  organizationId: string,
  mediaType: string,
  data: string,
  defaultClient_: Object,
}): Promise<null> => {
  const {
    token,
    organizationId,
    mediaType,
    data,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  const body = CertificateGW.constructFromObject({
    data: data,
    media_type: mediaType,
  });

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new OrganizationsApi(defaultClient);

  const dataHandler = data => {
    const certificateId= data.certificate_id;
    logger.debug("certificateId:", certificateId);
    return null;
  }

  return new Promise((resolve, reject) => {
    apiInstance.setCertificate(
      body,
      organizationId,
      callBack(reject, resolve) (dataHandler),
    );
  });
}

const getCertificate= (token: string) => (organizationId: string): Future => {
  const defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new OrganizationsApi(defaultClient);

  const dataHandler = data => {
    const publicKeyGW = data.certificate;
    const certificate = createCertificateFromGW(publicKeyGW);
    logger.debug("certificate:", certificate);
    return certificate;
  }

  return Future((reject, resolve) => {
    apiInstance.getCertificate(
      organizationId,
      callBack(reject, resolve) (dataHandler),
    );
    return console.log;
  });
}

const getPublicKey = (token: string) => (organizationId: string): Future => {
  const defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new OrganizationsApi(defaultClient);

  const dataHandler = data => {
    const publicKeyGW = data.public_key;
    console.log("publicKeyGW", publicKeyGW);
    const publicKey = createPublicKeyFromGW(publicKeyGW);
    logger.debug("publicKey:", publicKey);
    return publicKey;
  }

  return Future((reject, resolve) => {
    apiInstance.getPublicKey(
      organizationId,
      callBack(reject, resolve) (dataHandler),
    );
    return console.log;
  });
}

const addAddress = (args: {
  token: string,
  organizationId: string,
  displayName: string,
  purpose?: string,
}): Promise<BmbixAddress>=> {
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = args.token;
  let apiInstance = new OrganizationsApi();

  const dataHandler = data => {
    const addressGW = data.address;
    const address = createAddressFromGW(addressGW);
    return address;
  }

  let body = new AddressGW();
  body.display_name = args.displayName;
  if (Object.keys(args).includes("purpose")){
    body.purpose = args.purpose
  }
  return new Promise((resolve, reject) => {
    apiInstance.createAddress(
      body,
      args.organizationId,
      callBack(reject, resolve)(dataHandler),
    );
  });
}


const getAddresses = (args: {
  token: string,
  organizationId:string,
}):Promise<Array<BmbixAddress>> => {
  const {
    token,
    organizationId,
  } = args;
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new OrganizationsApi();

  const dataHandler = data => {
    const addressesResponseGW = data.addresses;
    const addresses = R.map(createAddressFromGW, addressesResponseGW);
    return addresses;
  }

  return new Promise((resolve, reject) => {
    apiInstance.listAddresses(
      organizationId,
      callBack(reject, resolve) (dataHandler),
    );
  });
}

const getAllAddresses = async (args: {
  token: string,
  organizationIds:Array<string>,
}): Promise<Array<BmbixAddress>> => {
  const all_promises = await Promise.allSettled(
    R.map(
      oId => getAddresses({token: args.token, organizationId: oId}),
      args.organizationIds,
    )
  );
  const fulfilled_promises = R.filter(
    p => p.status === "fulfilled",
    all_promises,
  );
  const address_lists = R.map(
    fp => fp.value,
    fulfilled_promises,
  );
  const addresses = R.flatten(address_lists);
  return addresses;
};


const getPaymentMethodLinkForOrganization = (args: {
  token: string,
  organizationId:string,
}):Promise<PaymentMethodLink> => {
  const {
    token,
    organizationId,
  } = args;
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new OrganizationsApi();

  const dataHandler = data => {
    const paymentMethodLinkGW = data.payment_method_link;
    const paymentMethodLink = createPaymentMethodLinkFromGW(paymentMethodLinkGW);
    return paymentMethodLink;
  }

  return new Promise((resolve, reject) => {
    apiInstance.getPaymentMethodLinkForOrganization(
      organizationId,
      callBack(reject, resolve) (dataHandler),
    );
  });
}


const deletePaymentMethodLinkForOrganization = (args: {
  token: string,
  organizationId:string,
}):Promise<null> => {
  const {
    token,
    organizationId,
  } = args;
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new OrganizationsApi();

  const dataHandler = data => {
    return null;
  }

  return new Promise((resolve, reject) => {
    apiInstance.deletePaymentMethodLinkForOrganization(
      organizationId,
      callBack(reject, resolve) (dataHandler),
    );
  });
}

export {
  createOrganizationFromGW,
  getAllOrganizations,
  getOrganization,
  updateOrganization,
  setIcon,
  setCertificate,
  getCertificate,
  deletePaymentMethodLinkForOrganization,
  getPaymentMethodLinkForOrganization,
  getPublicKey,
  addAddress,
  getAddresses,
  getAllAddresses,
}

