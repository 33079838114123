// @flow

import * as S from "sanctuary";


import {
  type BmbixAddress,
} from "../Entity/Types.js";

const createAddress = (id: string): string => {
  const ownerBmbixAddress = `bmbix://led/${id}`;
  return ownerBmbixAddress;
}

const parseAddress = (address: String): Object => {
  const pattern =
    /^(bmbix):\/\/(led|org)\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/;
  const [_, protocol, type, id]= address.match(pattern);
  return {protocol, type, id};
}

const findAddress = (addresses: Array<BmbixAddress>) => (addressId: string): ?BmbixAddress  => S.maybeToNullable (
  S.find (a => a.id === addressId) (addresses)
)

export {
  createAddress,
  parseAddress,
  findAddress,
}
