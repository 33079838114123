// @flow
import React, { createElement as el, useState } from "react";
import { fork, promise } from "fluture";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  assign,
  createMachine,
} from "xstate";
import {
  useMachine,
} from "@xstate/react";

import { addMessageType } from "../../Api";
import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "../../../../Util";
import { addAction, useToken } from "./../../../../State";
import { View } from "./view.js";

import { useCatchExpiredToken } from "./../../../../State/Token.js";
import {
  fetchMessageContentTypes
} from "./../../../../Organization/ContentTypesApp/api.js";


const api =
  (token: string) =>
  (organizationId: string): Promise<Array<MesssageContentType>> =>
  promise(fetchMessageContentTypes(token)(organizationId))


const apiSpecification = {
  id: "MartletOrganizationApi",
  initial: "unconfigured",
  context: {
    token: undefined,
    errors: undefined,
    messageContentTypes: undefined,
    organizationId: undefined,
  },
  states: {
    unconfigured: {
      on: {
        CONFIGURE: {
          target: "idle",
          actions: assign({
            organizationId: (c, e, m) => e.organizationId,
            token: (c, e, m) => e.token
          }),
        }
      },
    },
    idle: {
      on: {
        START: "working",
      }
    },
    working: {
      invoke: {
        id: "fetchMessageContentTypes",
        src: (c, e) => api(c.token)(c.organizationId),
        onDone: {
          target: "succeeded",
          actions: assign({
            messageContentTypes: (c, e) => e.data,
          })
        },
        onError: {
          target: "failed",
          actions: assign({
            errors: (c, e) => e.data,
          })
        },
      },
    },
    succeeded: {
      on: {
        RESET: "idle",
      },
    },
    failed: {
      on: {
        RESET: "idle",
      },
    },
  },
};

const apiStateMachine = createMachine(apiSpecification);

function Controller (props: {
  address: string,
  direction: string,
  onDone: Function,
}) {

  const {
    address,
    direction,
    onDone,
  } = props;

  const { organizationId } = useParams();
  const dispatch = useDispatch();
  const token = useToken();
  const [ apiState, apiSend ] = useMachine(apiStateMachine);
  const [display, setDisplay] = useState(INPUT);
  const [messageType, setMessageType] = useState();
  const [ messageTypeObj, setMessageTypeObj ] = useState();
  const catchExpiredToken = useCatchExpiredToken();
  const retentionPolicy = "bmbix://retention-policy/30-days-after-receipt";

  apiSend({type: "CONFIGURE", token, organizationId});
  apiSend({type: "START"});

  const onSubmit = e => {
    setDisplay(WAITING);
    const action =
      addMessageType(token)(address)(messageType)(direction)(retentionPolicy)
    fork(error => {
      console.log(error)
      catchExpiredToken(error);
      setDisplay(FAILURE);
    })(messageTypeObj => {
      console.log("messageType", messageType);
      setMessageTypeObj(messageTypeObj);
      setDisplay(SUCCESS);
    })(action);
  }


  function onCancel (e) {
    console.log("onCancel:", e);
    onDone();
  }

  function onDoneFailure (e) {
    console.log("onDoneFailure:", e);
    onDone();
  }

  function onDoneSuccess(e) {
    console.log("onDoneSuccess:", e);
    dispatch(addAction(messageTypeObj))
    onDone();
  }

  function onChange (e) {
    console.log("onChange:", e);
    setMessageType(e.target.value);
  }


  return apiState.value === "succeeded"
  ? el(
      View,
      {
        display,
        onCancel,
        onSubmit,
        onDoneSuccess,
        onDoneFailure,
        onChange,
        data: apiState.context.messageContentTypes,
      }
    )
  : <p>Waiting</p>
  ;

}

export { Controller };
export default Controller;
