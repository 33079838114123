// @flow
import React from "react";
import { Controller as PaymentMethodLinksTable } from "./../PaymentMethodLinksTable/controller.js";
import { Controller as PaymentMethodLinkAddWidget } from "./../PaymentMethodLinkAddWidget/controller.js";
const View = (props: {
  state: Function,
  onCreated: Function,
}) => {
  const { state, onCreated } = props;
  return state.value === "success"
  ? <>
    <h2>Payment Method</h2>
      <PaymentMethodLinkAddWidget onCreated={onCreated}/>
      <PaymentMethodLinksTable
        paymentMethodLinks={state.context.paymentMethod.links}
      />
    </>
    : <p>Waiting ...</p>
}
export {
  View,
}
