// @flow

import React from "react";
import ReactTable from "react-table-6"
import "react-table-6/react-table.css"
import * as S from "sanctuary";
import {
  Link,
} from "react-router-dom";
import Button from "react-bootstrap/Button";
import {
  CheckIcon,
  DashIcon,
  FileIcon,
  NoteIcon,
  XIcon,
} from "@primer/octicons-react"
import log from "loglevel";

import {
  type Acceptance,
  type BmbixAddress,
} from "../Entity/Types";
import {
  BmbixImage,
} from "../Widgets/BmbixImage";
import {
  BmbixDateTime,
} from "../Widgets/";
import {
  includesText,
} from "../Util/filter.js";
import { generateAlias } from "./../Util/alias.js";

const ReceiptedAcceptanceTable = (props: {
  address: BmbixAddress,
  items: Array<Acceptance>,
}) => {
    const {items, address} = props;
    // const organizationId = address.organizationId;
    const addressId = address.id;

    const columns = [
      {
        accessor: "organization.id",
        minWidth: 28,
        maxWidth: 28,
        Cell: ({row}) => {
          if (!!row._original.alias.organizationId) {
            const organizationId = row._original.alias.organizationId;
            const src=`https://bmbix-images.s3.eu-west-2.amazonaws.com/organization/${organizationId}`; // need the FROM organization.
            return <BmbixImage src={src} />
          }
        }
      },
      {
      // accessor: item => `${item.alias.organizationProfile.name}-${item.alias.displayName}`,
      accessor: (item)=> {
        if(!!item.alias.organizationId) {
          return generateAlias(item.alias);
        } else {
          return "";
        }
      },
      id: "alias",
      filterable: true,
      filterMethod: (filter, row, column) => {
        const re = S.regex ("g") (S.toLower (filter.value));
        console.log("filter", filter);
        console.log("row", row);
        console.log("column", column);
        console.log("re", re);
        return S.test (re) (S.toLower (row[filter.id]));
      },
      Header: "To",
      Cell: ({row}) => {
        if (!!row._original.alias.organizationId) {
        const path = `/profiles/address/${row._original.toAddressId}`;
        const alias = `${row._original.alias.organizationProfile.name}-${row._original.alias.displayName}`;
        return (
          <Link to={path}>{alias}</Link>
        );
      }},
    },
      {
        Header: "Message Type",
        accessor: "messageType",
        filterable: true,
      },
      {
        Header: "Sender Ref",
        accessor: "senderReference",
        filterable: true,
      },
      {
        Header: "Submitted At",
        accessor: "submissionTimestamp",
        Cell: ({value}) => <BmbixDateTime timeStamp={value} />
      },
      {
        Header: "Status",
        Cell: ({row}) => {
          const receivedIcon = !!row._original.submissionTimestamp ? <CheckIcon /> : <><DashIcon />;</>

          const receiptedIcon = !!row._original.readReceivedTimestamp ? <CheckIcon /> : <><DashIcon /></>

          const resultIcon = row._original.result === "success" ? <CheckIcon /> : <><DashIcon /></>
        return <>{receivedIcon}{receiptedIcon}{resultIcon}</>
        },
        filterable: false,
      },
      {
        accessor: "messageId",
        Cell:  ({row}) => {
          const organizationId = address.organizationId
          const messageStatusPath = `/u/organizations/${organizationId}/addresses/${addressId}/messages-status/${row.messageId}`;
          const messageAppPath = `/messages/${row.messageId}`;
          return (
            <>
            <Link to={messageStatusPath}><Button className="mr-2"><NoteIcon /></Button></Link>
            <Link to={messageAppPath}><Button><FileIcon /></Button></Link>
            </>
          )
        },
        filterable: false,
      },
  ]

  return (
    <ReactTable
      className="-highlight -striped"
      data={items}
      columns={columns}
      pageSize={S.size (items)}
      showPagination={false}
      defaultSorted={[{id:"submissionTimestamp", desc: true}]}
      defaultFilterMethod={includesText}
    />
  )
}

export {
  ReceiptedAcceptanceTable,
}
