// @flow
import Future from "fluture";
import * as S from "sanctuary";

import {
  ApiClient,
  AddressesApi,
} from "@bmbix/bmb_martlet_organization_client";
import { gwToMessageType } from "./adapt.js";

const getMessageTypes = (token: string) => (address: string): Future => {
  console.log("token", token);
  console.log("address", address);

  const apiInstance = new AddressesApi();
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;

  return Future((reject, resolve) => {
    apiInstance.listMessageTypes(address, (error, data, response) => {
      if (error) {
        console.log("error", error);
        reject(error);
      } else {
        const gws = data.message_types;
        const messageTypes = S.map (gwToMessageType) (gws);
        resolve(messageTypes);
      }
    });

    return console.log
  });
}

export { getMessageTypes }
