// @flow

import React from "react"
import * as S from "sanctuary";

import { SelectPlatformTypeFormGroup } from "./../../../../Platforms/AddForm";
import { AddForm as AddOrganizationForm } from "./../../../../Organization/AddForm.js";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import {
  Spinner,
} from "./../../../../Widgets/Toast.js";

const InputView = (props: {
  proposedName: string,
  onChange: Function,
  onPlatformTypeChange: Function,
  onSubmit: Function,
}) => {
  const {
    proposedName,
    onChange,
    onPlatformTypeChange,
    onSubmit,
  } = props;

  return <>
    <p>Supply organization name.</p>
    <AddOrganizationForm proposedName={proposedName} onChange={onChange}/>
    <Form>
    <SelectPlatformTypeFormGroup onChange={onPlatformTypeChange} />
    </Form>
    <Button onClick={onSubmit} >Create my account</Button>
    </>
}


const WaitingView = (props: {
  messages: Array<string>;
}) => {
  const {
    messages,
  } = props;

  const renderedMessages = S.map (m => <p>{m}</p>) (messages);

  return <>
    <Spinner height="50" />
    {renderedMessages}
    </>
}

const SuccessView = (props: {
  platformId: string,
    organization: string,
    platformType: string,
    children: Function,
}) => {
  const {
    platformId,
    organization,
    platformType,
    children,
  } = props;

  return (
    <>
     <p>Success ...</p>
     <p>platform: {platformId}</p>
     <p>platformType: {platformType}</p>
     <p>organization: {organization}</p>
     {children}
    </>
  )
}

const FailureView = (props: {
}) => {
  return <p>Failure ...</p>
}

const OAuthButton = (props: {
  onSubmit: Function,
}) => {
  const {
    onSubmit,
  } = props;

  return (
    <Button onClick={onSubmit}>Connect</Button>
  )

}

export {
  InputView,
  WaitingView,
  SuccessView,
  FailureView,
  OAuthButton,
}
