// @flow
// @flow

import React, {
  useState,
} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  connect,
} from "react-redux";

import {
  Spinner,
} from "../Widgets/Toast";
import {
  Form as AddForm,
} from "./Form";
import {
  withIsimudApi,
} from "../Api/Isimud";
import {
  addAddressBookEntry,
} from "../State";
import {
  useForm,
  Controller,
} from "react-hook-form";
import Form from "react-bootstrap/Form";


function RHFAdd({onSubmit}) {
  const { control, handleSubmit, formState: { errors } } = useForm();

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Group>
    <Form.Label>Local Account</Form.Label>
    <Controller
      name="localAccount"
      rules={{required: true}}
      control={control}
      render={({field}) =>
        <Form.Control {...field} />
      }
    />
    <Form.Text>Enter local account identifier</Form.Text>
      {errors.localAccount && <span>This field is required</span>}
    </Form.Group>

    <Form.Group>
    <Form.Label>Bmbix Address</Form.Label>
    <Controller
      name="foreignAddressId"
      rules={{required: true}}
      control={control}
      render={({field}) =>
        <Form.Control {...field} />
      }

    />
    <Form.Text>The Bmbix address of the account holder</Form.Text>
      {errors.foreignAddressId && <span>This field is required</span>}
    </Form.Group>


          <Button type="submit" variant="primary" >Save changes</Button>
    </Form>
  );
}


function AddModal_ (props: {
  organizationId: string,
  addressId: string,
  callbackSubmit?: Function,
  callbackCancel?: Function,
  isimudApi: Object,
  addAddressBookEntry: Function,
}) {

  const INPUT = "input";
  const WAITING = "waiting";
  const SUCCESS = "success";
  const FAILURE = "failure";

  const [display, setDisplay] = useState(INPUT);
  const [addressBookEntry, setAddressBookEntry] = useState();

  const handleClose = (e) => {
    if(props.callbackCancel !== undefined){
      props.callbackCancel();
    }
  }

  const handleSave2 = (data) => {
    setDisplay(WAITING);
    props.isimudApi.insertAddressBookEntry({
      organizationId: props.organizationId,
      addressId: props.addressId,
      localAccount: data.localAccount,
      foreignAddressId: data.foreignAddressId,
    }).then(
      addressBookEntry => {
        setAddressBookEntry(addressBookEntry);
        setDisplay(SUCCESS);
        props.addAddressBookEntry(addressBookEntry);
        if(props.callbackSubmit !== undefined){
          props.callbackSubmit(addressBookEntry);
        }
      }
    ).catch(
      error => {
        console.log("error:", error);
        setDisplay(FAILURE);
      }
    );

  }

  const body = () => {
    switch (display) {
      case INPUT:
        return <RHFAdd onSubmit={handleSave2}/>
      case WAITING:
        return <Spinner />
      case SUCCESS:
        return <p>Added address book entry</p>
      case FAILURE:
        return <p>Ooops, Looks like something went wrong</p>
      default:
        console.log("Couldn't find a matching case");
    }
  }

  return (
    <Modal show={true} >
      <Modal.Header closeButton onHide={handleClose}>
        <Modal.Title>Add Address Book Entry</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body()}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
        </Modal.Footer>
    </Modal>
  );

}


const mapStateToProps = (state, ownProps) => {
  return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addAddressBookEntry: (addressBookEntry) => dispatch(
      addAddressBookEntry(addressBookEntry)),
  }
}

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
)

const AddModal = connector(withIsimudApi(AddModal_));

export {
  AddModal,
}
