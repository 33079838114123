// @flow

import * as R from "ramda";
import {
  type Organization,
} from "../Entity/Types";
import {
  useDispatch,
  useSelector
} from "react-redux";

const ADD_ORG = "ADD_ORG"
const DEL_ORG = "DEL_ORG"
const UPDATE_ORG = "UPDATE_ORG";

const addOrg = (org:Organization) => {
  return {
    type: ADD_ORG,
    org: org
  }
}

const delOrg = (org_id:string) => {
  return {
    type: DEL_ORG,
    org_id: org_id
  }
}

const updateOrg = (org:Organization) => {
  return {
    type: UPDATE_ORG,
    org: org
  }
}

const getOrganization = (
  id:string,
  organizations:Array<Organization>,
) => {
  return R.find(R.propEq("id", id))(organizations);
}

const updateOrganization = (
  state:Array<Organization>,
  organization:Organization,
) => {
  // remove existing record, if any
  const cleanedRecords = R.filter((o) => (o.id !== organization.id) , state)
  // add new record
  return R.uniq(R.append(organization, cleanedRecords))
}

const orgs = (state:Function=[], action:Function) => {
  switch (action.type) {
    case ADD_ORG:
      return R.uniq(R.append(action.org, state))
    case DEL_ORG:
      return R.filter((o) => (o.id !== action.org_id) , state)
    case UPDATE_ORG:
      return updateOrganization(state, action.org);
    default:
      return state
  }
}

const useSetOrganization:Function = (o: Organization) => useDispatch(updateOrg(o))

const useOrganizations = () => useSelector(store => store.orgs)

export {
  orgs,
  addOrg,
  delOrg,
  updateOrg,
  useOrganizations,
  useSetOrganization,
  getOrganization,
}
