// @flow

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";
import * as R from "ramda";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import {
  PlusIcon,
  TrashIcon,
} from "@primer/octicons-react";
import { useMachine } from "@xstate/react";
import { createMachine } from "xstate";

import {
  type Organization,
  type Platform,
} from "../Entity/Types";
import AddModal from "./AddModal";
import RemoveModal from "./RemoveModal";
import PlatformView2 from "./PlatformView2"
import {
  CheckablePlatformsTable,
} from "../Widgets/CheckablePlatformsTable";
import XeroView from "./XeroView.js";
import {StatusPage as KashflowView} from "./KashflowV1";
import {StatusPage as FreeagentView} from "./FreeagentV1";
import {StatusPage as SageView} from "./SageV1";
import {StatusPage as QboView} from "./QboV1";
import {
  Spinner,
} from "./../Widgets/Toast";
import { useToken } from "./../State";
import { getPlatforms } from "./../Api/Isimud/Platforms.js";
import { setPlatforms as setPlatformsRedux } from "./../State/Platforms.js";


const fetchingMachine = createMachine({
  id: "fetchingMachine",
  initial: "waiting",
  states: {
    waiting: {
      on: {
        SUCCESS_HAPPENED: "success",
        FAILURE_HAPPENED: "failure",
      }
    },
    success: {
      on: {
        WAITING_HAPPENED: "waiting",
      }
    },
    failure: {
      on: {
        RESET_HAPPENED: "input"
      }
    },
    input: {
      on: {
        SUBMIT_HAPPENED: "waiting"
      }
    },
  },
});


const PlatformsView = (props:{
  match: Object,
  organization: Organization,
  setPlatformsRedux: Function,
}) => {
  const {
    match,
    organization,
    setPlatformsRedux,
  } = props;

  const [showAddModal, setShowAddModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [checkedPlatformIds, setCheckedPlatformIds] = useState([]);
  const [ platforms, setPlatforms ] = useState([]);
  const [ state, send ] = useMachine(fetchingMachine);
  const token = useToken();


  useEffect(
    () => {
      send({type: "WAITING_HAPPENED"});
      getPlatforms({
        token,
        organizationId: organization.id,
      }).then(
        platforms => {
          setPlatforms(platforms);
          setPlatformsRedux(platforms);
          send({type: "SUCCESS_HAPPENED"});
        }
      ).catch(
        error => {
          send({type: "FAILURE_HAPPENED"});
        }
      );
    },
    []
  );

  const handleRemoveBeginEvent = (e) => {
    setShowRemoveModal(true);
  }

  const handleRemoveComplete = () => {
    setShowRemoveModal(false);
  }

  const handleAddBeginEvent = (e) => {
    setShowAddModal(true);
  }

  const handleAddComplete = () => {
    setShowAddModal(false);
  }

  const handleCancel = () => {
    setShowAddModal(false);
  }

  const handleCancelRemove = () => {
    setShowRemoveModal(false);
  }

  return state.value === "success"
  ? (
    <>
      <Route
        exact
        path={ match.path }
      >
      { showAddModal &&
      <AddModal
        organizationId={organization.id}
        onComplete={handleAddComplete}
        onCancel={handleCancel}
      />
      }
      { showRemoveModal &&
      <RemoveModal
        organizationId={organization.id}
        platformIds={checkedPlatformIds}
        onComplete={handleRemoveComplete}
        onCancel={handleCancelRemove}
      />
      }
      <ButtonToolbar
        className="float-right">
        <Button
          onClick={handleAddBeginEvent}
          variant="primary"
        ><PlusIcon/> Add</Button>
        </ButtonToolbar>
      <h2>Platforms</h2>
        <Button
          className="mr-2"
          onClick={handleRemoveBeginEvent}
          variant="primary"
        ><TrashIcon /> Delete</Button>
      <CheckablePlatformsTable
        items={platforms}
        checked={checkedPlatformIds}
        callBack={checkedPlatformIds => setCheckedPlatformIds(
          checkedPlatformIds
        )}
      />
      </Route>
      <Route
         exact
         path={`${match.path}/:pid`}
      >
        <PlatformView2 />
      </Route>
      <Route path={`${match.path}/:pid/xero`} >
        <XeroView />
      </Route>
      <Route path={`${props.match.path}/:pid/kashflow`} >
        <KashflowView />
      </Route>
      <Route path={`${props.match.path}/:pid/freeagent`} >
        <FreeagentView />
      </Route>
      <Route path={`${props.match.path}/:pid/sage`} >
        <SageView />
      </Route>
      <Route path={`${props.match.path}/:pid/qbo`} >
        <QboView />
      </Route>
    </>
  )
  : state.value === "waiting"
  ? <Spinner height="50" />
  : state.value === "failure"
  ? <p>Ooops</p>
  : <p>Unknown state</p>;

}

const connector = connect(
  (state, ownProps) => {
    const id = ownProps.match.params.organizationId;
    return {
      organization: R.find(R.propEq("id", id), state.orgs),
    }
  },
  {setPlatformsRedux},
);

export default withRouter(connector(PlatformsView))

export {
  PlatformsView,
}
