// @flow
import * as R from "ramda";

import {
  type ECRCompletedNotice,
} from "../Entity/Types";

const INSERT_ECR_COMPLETED_NOTICE = "INSERT_ECR_COMPLETED_NOTICE";


const insertECRCompletedNotice = (ecr: ECRCompletedNotice) => {
  return Object.freeze({
    type: INSERT_ECR_COMPLETED_NOTICE,
    ecr: ecr,
  });
}


const handleInsert = (state, ecr) => {
  return R.uniqBy(
    (ecr) => ecr.extractionCandidateReportId,
    R.prepend(ecr, state),
  );
}

const reducer = (state: Array<ECRCompletedNotice>=[], action:Object) => {
  switch(action.type){
    case INSERT_ECR_COMPLETED_NOTICE:
      return handleInsert(state, action.ecr);
    default:
      return state;
  }
}


export {
  reducer as ECRCompletedNotices,
  insertECRCompletedNotice,
}
