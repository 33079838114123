// @flow

import { createElement as el, useEffect, useState } from "react";
import log from "loglevel";
import { fork } from "fluture";

import { useParams } from "react-router-dom";

import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "./../../../Util";
import { useToken } from "./../../../State";
import { useCatchExpiredToken } from "./../../../State/Token.js";
import { getResourcePublicKey } from "./../../Api/get_resource_public_key";
import { View } from "./view";


const logger = log.getLogger("Profile");

const Controller = (props: {
}) => {

  const catchExpiredToken = useCatchExpiredToken();
  const token = useToken();
  const { userId } = useParams();
  logger.debug("userId:", userId);

  const [ publicKeyData, setPublicKeyData] = useState();
  const [ display, setDisplay] = useState(INPUT);

  useEffect(
    () => {
      setDisplay(WAITING);
      const action = getResourcePublicKey(token)("users")(userId);

      fork(error => {
        console.log("error:", error);
        catchExpiredToken(error);
        setDisplay(FAILURE);
      })(publicKey_ => {
        setPublicKeyData(publicKey_.data);
        setDisplay(SUCCESS);
      })(action);

    },
    [],
  );

  return el(
    View,
    {
      display,
      publicKeyData,
    }
  );


};

export {
  Controller,
}
