// @flow
import React from "react";
import * as S from "sanctuary";

import ReactTable from "react-table-6";

import { Link } from "react-router-dom";

import {
  BmbixImage,
} from "../../../Widgets/BmbixImage";
import {
  caseInsensitiveAnywhere
} from "./../../../Util/filter.js";

import {
  type AddressUser,
  type OrganizationUser
} from "./Entity.js";
import { AddressProfileWidget } from "./../../../Widgets/address-profile.js";


const View = (props: {
  data: Array<OrganizationUser>,
}) => {
  const {
    data,
  } = props;

  const columns = [
    {
      Header: "Organization",
      columns: [
        {
          accessor: "organization.id",
          Cell: ({value}) => {
            return <BmbixImage src={`https://bmbix-images.s3.eu-west-2.amazonaws.com/organization/${value}`} />
          }
        },
        {
          Header: "Name",
          accessor: "organization.name",
        }
      ]
    },
    {
      Header: "Role",
      columns: [
        {
          Header: "Power",
          accessor: "permission.power",
        },
        {
          Header: "Action",
          Cell: ({row}) => {
            const validatePath = `/v/organizations/${row._original.organization.id}/certificates`;
            const managePath = `/m/organizations/${row._original.organization.id}`;
            return (
              row._original.permission.power ===
              "bmbix://role/organization/certificate-validator" ? <Link
              to={validatePath}>validate</Link>: row._original.permission.power ===
              "bmbix://role/organization/manager" ? <Link
              to={managePath}>manage</Link> : null
            );
          },
        }
      ]
    }
  ]

  return (
    <ReactTable
      columns={columns}
      data={data}
      className="-striped -highlight"
      defaultPageSize={10}
    />
  );

}


const AddressView = (props: {
  data: Array<AddressUser>,
  addressProfiles: Function,
}) => {
  const {
    data,
    addressProfiles,
  } = props;
  console.log("data", data);

  const columns = [
    {
      Header: "Address",
      columns: [
        {
          id: "address.id",
          accessor: (item) => {
            const addressProfile = addressProfiles[item.address.id];
            return !!addressProfile ? `${addressProfile.displayName} ${addressProfile.organizationProfile.name}` : "";
          },
          filterable: true,
          Cell: ({original}) => {
            // line below raises a React state update in a render warning.
            // no idea why?
            // const addressProfile = S.prop (original.address.id) (addressProfiles);
            // Found it! It's just because it raises an error. Like this below:
            // throw new Error("banana cheese");
            // that seems to cause a re-render
              const addressProfile = addressProfiles[original.address.id];
              if (!!addressProfile) {
                return <AddressProfileWidget linkTo="profile" addressProfile={addressProfile} />
              } else {
                return null;
              }
          },
        },
      ]
    },
    {
      Header: "Role",
      columns: [
        {
          Header: "Power",
          accessor: "permission.power",
          filterable: true,
        },
        {
          Header: "Action",
          Cell: ({row}) => {
            const processPath = `/u/organizations/${row._original.address.organizationId}/addresses/${row._original.address.id}`;
            const managePath = `/m/organizations/${row._original.address.organizationId}/addresses/${row._original.address.id}`;
            const processLink = <Link to={processPath}>process</Link>
            const manageLink = <Link to={managePath}>manage</Link>

            const isManager = row._original.permission.power ===
              "bmbix://role/address/manager"

            return (
              isManager ? <>{processLink} &bull; {manageLink}</> : <>{processLink}</>
            );
          },
        }
      ]
    }
  ]

  /*
  return <p>Banjo</p>
  */

  return (
    <ReactTable
      columns={columns}
      data={data}
      className="-striped -highlight"
      pageSize={S.size (data)}
      defaultFilterMethod={caseInsensitiveAnywhere}
    />
  );

}
export {
  AddressView,
  View,
}
