// @flow

import React from "react";
import Button from "react-bootstrap/Button";

import "../Spinner.css";

type SpinnerProps = {
  height?: string,
}
export function Spinner (props:SpinnerProps) {
  const {
    height,
  } = props;

  const height_ = !!height ? height : "";

  return (
        <svg height={height_} className="spinner" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle className="rim" cx="50" cy="50" r="45"/>
          <circle className="rim inner" cx="50" cy="50" r="45"/>
	  <g transform="translate(-0.9375, -0.9375)">
		<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.13 47"><defs><style>{`.cls-1{fill:#218cf2;}`}{`.cls-2{fill:#91d1d5;}`}{`.cls-3{fill:#fff;}`}</style></defs><title>bmbix dot colour</title><circle className="cls-1" cx="24.08" cy="23.98" r="19.77"/><path className="cls-2" d="M26.05,24.07a2.3,2.3,0,0,0-3.91,1.62,2.21,2.21,0,0,0,.68,1.61h0a2.26,2.26,0,0,0,3.23,0,2.19,2.19,0,0,0,.68-1.61A2.23,2.23,0,0,0,26.05,24.07Z"/><path className="cls-3" d="M32.37,21a8.34,8.34,0,0,0-3-3.26,8,8,0,0,0-4.29-1.19,8.17,8.17,0,0,0-3.55.79,8,8,0,0,0-2.41,1.77V11.08a1.82,1.82,0,0,0-.52-1.34,1.78,1.78,0,0,0-1.33-.51,1.82,1.82,0,0,0-1.32.51,1.79,1.79,0,0,0-.53,1.34V25.67a9.22,9.22,0,0,0,1.2,4.64,8.87,8.87,0,0,0,7.82,4.48,9.15,9.15,0,0,0,4.6-1.2,8.73,8.73,0,0,0,3.27-3.28,9.31,9.31,0,0,0,1.18-4.64A9.8,9.8,0,0,0,32.37,21Zm-5.16,9.68h0a5.36,5.36,0,0,1-7.53-2.07,6.3,6.3,0,0,1-.71-3,6.13,6.13,0,0,1,.71-3,5.47,5.47,0,0,1,1.95-2.07,5.56,5.56,0,0,1,5.58,0,5.6,5.6,0,0,1,2,2.07,6,6,0,0,1,.72,3,6.13,6.13,0,0,1-.72,3A5.48,5.48,0,0,1,27.21,30.72Z"/></svg>
	  </g>
        </svg>
  );
}

export function CenteredSpinner (props:SpinnerProps) {
  return (
    <div className="outside">
        <svg className="spinner" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle className="rim" cx="50" cy="50" r="45"/>
          <circle className="rim inner" cx="50" cy="50" r="45"/>
	  <g transform="translate(-0.9375, -0.9375)">
		<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.13 47"><defs><style>{`.cls-1{fill:#218cf2;}`}{`.cls-2{fill:#91d1d5;}`}{`.cls-3{fill:#fff;}`}</style></defs><title>bmbix dot colour</title><circle className="cls-1" cx="24.08" cy="23.98" r="19.77"/><path className="cls-2" d="M26.05,24.07a2.3,2.3,0,0,0-3.91,1.62,2.21,2.21,0,0,0,.68,1.61h0a2.26,2.26,0,0,0,3.23,0,2.19,2.19,0,0,0,.68-1.61A2.23,2.23,0,0,0,26.05,24.07Z"/><path className="cls-3" d="M32.37,21a8.34,8.34,0,0,0-3-3.26,8,8,0,0,0-4.29-1.19,8.17,8.17,0,0,0-3.55.79,8,8,0,0,0-2.41,1.77V11.08a1.82,1.82,0,0,0-.52-1.34,1.78,1.78,0,0,0-1.33-.51,1.82,1.82,0,0,0-1.32.51,1.79,1.79,0,0,0-.53,1.34V25.67a9.22,9.22,0,0,0,1.2,4.64,8.87,8.87,0,0,0,7.82,4.48,9.15,9.15,0,0,0,4.6-1.2,8.73,8.73,0,0,0,3.27-3.28,9.31,9.31,0,0,0,1.18-4.64A9.8,9.8,0,0,0,32.37,21Zm-5.16,9.68h0a5.36,5.36,0,0,1-7.53-2.07,6.3,6.3,0,0,1-.71-3,6.13,6.13,0,0,1,.71-3,5.47,5.47,0,0,1,1.95-2.07,5.56,5.56,0,0,1,5.58,0,5.6,5.6,0,0,1,2,2.07,6,6,0,0,1,.72,3,6.13,6.13,0,0,1-.72,3A5.48,5.48,0,0,1,27.21,30.72Z"/></svg>
	  </g>
        </svg>
    </div>
  );
}


type ResultProps = {
  heading: string,
  message: string,
  callBack: Function,
}
export function Result (props:ResultProps) {
  return (
    <>
      <h2>{props.heading}</h2>
      <p>{props.message}</p>
      <Button
        variant="primary"
        onClick={props.callBack}
      >Close</Button>
    </>
  );
}
