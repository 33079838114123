// @flow
import { createElement as el, useState } from "react";
import { fork } from "fluture";
import { useDispatch } from "react-redux";

import {
  updateAccessListMutation,
  useToken,
} from "../../../../State";
import { addEntry } from "../../Api";
import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "../../../../Util";
import View from "./view.js";
import { useCatchExpiredToken } from "./../../../../State/Token.js";

function Controller (props: {
  list: string,
  owner: string,
  onDone: Function,
}) {

  const {
    list,
    owner,
    onDone,
  } = props;

  const dispatch = useDispatch();
  const mutationUpdate = updateAccessListMutation();
  const token = useToken();
  const [display, setDisplay] = useState(INPUT);
  const [sender, setSender] = useState();
  const catchExpiredToken = useCatchExpiredToken();

  const onSubmit = e => {
    setDisplay(WAITING);
    const action = addEntry(token)(owner)(list)(sender)
    fork(error => {
      console.log(error);
      catchExpiredToken(error);
      setDisplay(FAILURE);
    })(entry => {
      console.log("entry", entry);
      setDisplay(SUCCESS);
    })(action);
  }


  function onCancel (e) {
    console.log("onCancel:", e);
    onDone();
  }

  function onDoneFailure (e) {
    console.log("onDoneFailure:", e);
    onDone();
  }

  function onDoneSuccess(e) {
    console.log("onDoneSuccess:", e);
    dispatch(mutationUpdate);
    onDone();
  }

  function onChange (e) {
    console.log("onChange:", e);
    setSender(e.target.value);
  }


  return el(
    View,
    {
      display,
      onCancel,
      onSubmit,
      onDoneSuccess,
      onDoneFailure,
      onChange,
    }
  );

}

export { Controller as default };
