// @flow
import React, {
  useState,
} from "react";
import log from "loglevel";
import Button from "react-bootstrap/Button";

import {
  instanceService,
} from "../../../Entity/InstanceService.js";
import {
  type Account,
  type Settings,
} from "../../../Entity/Types.js";
import {
  View as SettingsView,
} from "../SetNameModal";
import {
  SetLanguageModal,
} from "../SetLanguageModal";
import {
  SetTimezoneModal,
} from "../SetTimezoneModal";

import {
  Info,
} from "../../../Widgets/Info";


const logger = log.getLogger("banana");

const View = (props: {
  account: Account,
  settings: Settings,
}) => {
  const {
    account,
    settings,
  } = props;

  const [showEditModal, setShowEditModal] = useState();
  const [showSetLanguageModal, setShowSetLanguageModal] = useState(false);
  const [showSetTimezoneModal, setShowSetTimezoneModal] = useState(false);

  const settings_ = !!settings ? settings : instanceService.createSettings({
    userId: account.sub
  });

  logger.debug("creating user pool client");

  const infoText = `Any name
      supplied here will be ignored in favour of the name on your x509 identity
      certificate, if you choose to use one.`;

  const settingsName = !!settings_ ? (
    <>
    <div className="clearfix">
    <h2 className="float-left">Name</h2>
    <Info buttonText="help" infoTitle="Name help" infoContent={infoText}/>
    </div>
    <p>{settings_.name}</p>
    </>
  ): null;

  const setLanguageModal = showSetLanguageModal ? (
    <SetLanguageModal
      onHide={e => setShowSetLanguageModal(false)}
      settings={settings_}
    />
  ) : null;

  const setTimezoneModal = showSetTimezoneModal ? (
    <SetTimezoneModal
      onHide={e => setShowSetTimezoneModal(false)}
      settings={settings_}
    />
  ) : null;

  return (
    <>
      <div className="clearfix">
      <h2 className="float-left">Settings</h2>
      </div>
      <hr/>
      {settingsName}
      <SettingsView
        name={!!settings_ ? settings_.name: ""}
        show={showEditModal}
        onComplete={e => setShowEditModal(false)}
      />
      <Button onClick={e => setShowEditModal(true)}>Edit</Button>
      <hr/>
      <h2>Language</h2>
      <p>{!! settings_ ? settings_.language: null}</p>
      <Button onClick={e => setShowSetLanguageModal(true)}>Edit</Button>
      {setLanguageModal}
      <hr/>
      <h2>Timezone</h2>
      <p>{!! settings_ ? settings_.timezone: null}</p>
      <Button onClick={e => setShowSetTimezoneModal(true)}>Edit</Button>
      {setTimezoneModal}

    </>
  )
}

export {
  View,
}
