// @flow
import { createElement as el } from "react";
import { withRouter } from "react-router-dom";

import { View } from "./view.js";

const Controller = withRouter((props: {
  location: Function,
  match: Function,
}) => {

  const {
    location,
    match,
  } = props;

  return el (
    View,
    {
      location,
      match,
    }
  );
});

export { Controller }
