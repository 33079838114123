// @flow

import React, { useState } from "react"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";
import * as S from "sanctuary";
import { fork } from "fluture";

import { AddForm } from "./AddForm";
import { addOrg } from "../State";
import {
  Spinner,
} from "../Widgets/Toast";
import {
  addOrganization,
} from "../Api/MartletOrganization/Users.js";

import { createAndStore } from "./../OrganizationProxies/Api/create";
import { useToken } from "./../State";
import { useAccount } from "./../State/Account.js";

import {
  createMachine,
} from "xstate";
import {
  useMachine,
} from "@xstate/react";

const machineSpecification = {
  id: "addOrganization",
  initial: "idle",
  context: {},
  states: {
    idle: {
      on: {
        START: "working",
      }
    },
    working: {
      on: {
        SUCCESS: "succeeded",
        FAILURE: "failed",
      }
    },
    succeeded: {
      on: {
        RESET: "idle",
      },
    },
    failed: {
      on: {
        RESET: "idle",
      },
    },
  },
};

const stateMachine = createMachine(machineSpecification);

const connector = connect(
  null,
  {addOrg},
)

const AddModal = connector((props:{
  addOrg: Function,
  onCancel: () => void,
  onComplete: () => void,
}) => {
  const {
    addOrg,
    onCancel,
  } = props;
  const [show, setShow] = useState(true);
  const token = useToken();
  const [ state, send ] = useMachine(stateMachine);
  const account = useAccount();
  const userId = account.id;

  const onClose = (e) => {

    send("RESET");
    setShow(false);
    onCancel();
  }

  const handleSubmit = (data) => {
    send("START")
    const name = data.organizationName;
    addOrganization({
      token,
      userId,
      name,
      isClosed: false,
    }).then(
      organization => {
        addOrg(organization);
        const organization_id = S.prop ("id") (organization);
        const action = createAndStore(token)(organization_id)("Initial Credentials");
        fork(error => {
          console.log("error creating oauth2 client credentials:", error);
          send("FAILURE")
        })(oauth2Client => {
          send("SUCCESS");
        })(action)
      }
    ).catch(
      error => {
        send("FAILURE");
      }
    );
  }

  const body = state.value === "idle"
  ?  <AddForm onSubmit={handleSubmit} />
  : state.value === "working"
  ? <Spinner height="50"/>
  : state.value === "succeeded"
  ? <p>Success, added organization and associated client credentials.</p>
  : state.value === "failed"
  ? <p>Ooops!, looks like something went wrong</p>
  : <p>Unknown state.</p>


  return (
      <Modal show={show} >
        <Modal.Header closeButton onHide={onClose}>
          <Modal.Title>Add Organization</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {body}
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>Close</Button>
        </Modal.Footer>
      </Modal>
  );
});

export {
  AddModal,
}
export default AddModal;
