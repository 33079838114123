// @flow
import React from "react";
import Form from "react-bootstrap/Form";




const View = (props: {
  onChange: Function,
}) => {
  const {
    onChange,
  } = props;

  return <><PersonForm onChange={onChange}/></>
}


const PersonForm = (props: {
  cn: string,
  bmbixId: string,
  onChange: Function,
  locality: string,
  country: string,
  state: string,
}) => {
  const {
    cn,
    bmbixId,
    onChange,
    locality,
    country,
    state,
  } = props;

  return <Form>

    <Form.Group>
    <Form.Label>Bmbix Id</Form.Label>
    <Form.Control
      onChange={onChange}
      name="bmbixId"
      value={bmbixId}
    />
    <Form.Text>The applicant Bmbix Id</Form.Text>
    </Form.Group>

    <Form.Group>
    <Form.Label>Common Name</Form.Label>
    <Form.Control
      onChange={onChange}
      name="cn"
      value={cn}
    />
    <Form.Text>Applicant common name</Form.Text>
    </Form.Group>

    <Form.Group>
    <Form.Label>2-char Country Code</Form.Label>
    <Form.Control
      onChange={onChange}
      name="country"
      value={country}
    />
    <Form.Text>Applicant country eg FR, UK, DE</Form.Text>
    </Form.Group>

    <Form.Group>
    <Form.Label>State</Form.Label>
    <Form.Control
      onChange={onChange}
      name="state"
      value={state}
    />
    <Form.Text>Applicant state</Form.Text>
    </Form.Group>

    <Form.Group>
    <Form.Label>Locality</Form.Label>
    <Form.Control
      onChange={onChange}
      name="locality"
      value={locality}
    />
    <Form.Text>Applicant locality</Form.Text>
    </Form.Group>

  </Form>
}


export {
  View,
}
