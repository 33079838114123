// @flow

import React, {
  useState,
} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import * as R from "ramda";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  Link,
} from "react-router-dom";


import {
  type Acceptance,
  type BmbixAddress,
} from "../Entity/Types";
import ReactTable from "react-table-6"
import "react-table-6/react-table.css"

const CheckableInsertionsTable = (props: {
  address: BmbixAddress,
  items: Array<Acceptance>,
  checked: Array<any>,
  callBack: Function,
  aliasedProcessables: Array<Object>,
}) => {
    const {
      address,
      aliasedProcessables,
      items,
      checked,
      callBack,
    } = props;

    const [chkd, setChkd] = useState(checked);

    const isChecked = (messageId) => {
       return R.contains(messageId, chkd);
    }

    const handleChange = (e) => {
      const isChecked = e.target.checked;
      const id = e.target.value;
      let newChecked;
      if(isChecked){
        newChecked = R.uniq(R.append(id, chkd));
      }
      else {
        newChecked = R.without(id, chkd);
      }
      setChkd(newChecked);
      callBack(newChecked);
    }

    const handleSelectAll = (e) => {
      const allMessageIds = R.map(
        i => i.messageId,
        items,
      );
      setChkd(allMessageIds);
      callBack(allMessageIds);
    }

    const handleSelectNone = (e) => {
      const noneIds = [];
      setChkd(noneIds);
      callBack(noneIds);
    }

    const columns = [
      {
        Cell:  ({row}) => {
          return (
            <Form.Check
              id={row._original.obj.messageId}
              name="cairo"
              key={row._original.obj.messageId}
              value={row._original.obj.messageId}
              onChange={handleChange}
              checked={isChecked(row._original.obj.messageId)}
            />
          )
        }
      },
      {
        Header: "From",
        accessor: "alias",
        Cell: ({row}) => {
          const fromAddressId = row._original.obj.fromAddressId;
          const fromAddressTooltip = <Tooltip>{fromAddressId}</Tooltip>
          const pathname = `/profiles/address/${fromAddressId}`;
          return (
            <OverlayTrigger overlay={fromAddressTooltip} placement="bottom" delay={{show: 250, hide: 400}} >
            <Link to={pathname}>{row.alias}</Link>
            </OverlayTrigger>
          )
        },
      },
      {
        Header: "Type",
        accessor: "obj.messageType",
      },
      {
        Header: "Ref",
        accessor: "obj.senderReference",
        Cell: ({row}) => {
          const messageId = row._original.obj.messageId;
          const messageIdTooltip = <Tooltip>{messageId}</Tooltip>
          const pathname = `/u/organizations/${address.organizationId}/addresses/${address.id}/messages/${messageId}`;
          return (
            <OverlayTrigger overlay={messageIdTooltip} placement="bottom" delay={{show: 250, hide: 400}} >
            <Link to={pathname}>{row["obj.senderReference"]}</Link>
            </OverlayTrigger>
          )
        },
      },
      {
        Header: "Submitted",
        accessor: "obj.submissionTimestamp",
      },
      {
        Header: "Receipted",
        accessor: "obj.receivedTimestamp",
      },
      {
        Header: "Result",
        accessor: "obj.result",
      },
  ]

  return (
    <>
    <Button className="mr-2" onClick={handleSelectAll}>Select All</Button>
    <Button className="mr-2" onClick={handleSelectNone}>Select None</Button>
    <ReactTable
      data={aliasedProcessables}
      columns={columns}
      defaultPageSize={10}
      className="-striped -highlight"
    />
    </>
  )
}



export {
  CheckableInsertionsTable,
}
