// @flow
import React from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {
  useForm,
  Controller,
} from "react-hook-form";

import {
  Spinner,
} from "./../../../../Widgets/Toast";


const MANAGER = "bmbix://role/address/manager";
const OPERATOR = "bmbix://role/address/operator";
const VIEWER = "bmbix://role/address/viewer";

const Button_ = (props: {
  onClick: Function,
}) => <Button onClick={props.onClick}>Add</Button>


const Modal_ = (props: {
  apiState: Function,
  onHide: Function,
  onSubmit: Function,
}) => {

  const {
    apiState,
    onHide,
    onSubmit,
  } = props;

  const state = apiState.value;

  const { register, handleSubmit, formState: { errors } } = useForm();

  const form = <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Group>
    <Form.Label>Actor Id</Form.Label>
    <Form.Control
      placeholder="resource id"
      {...register("resourceId", {
        required: "Must have a resource id"
      })}
    />
    </Form.Group>
    {errors.resourceId && <span>This field is required</span>}

    <Form.Group>
    <Form.Label>Choose Actor type</Form.Label>
          <Form.Check
            value="organization-proxy"
            type="radio"
            label="Organization Proxy"
            {...register("resourceType", {
              required: "Select resource type"
            })}
          />
          <Form.Check
            value="user"
            type="radio"
            label="User"
            {...register("resourceType")}
          />
    </Form.Group>
    {errors.resourceType && <span>This field is required </span> &&
      console.log(errors.resourceType) }

    <Form.Group>
    <Form.Label>Choose Actor role</Form.Label>
          <Form.Check
            value={MANAGER}
            type="radio"
            label="Manager"
            {...register("role", {
              required: "Select role"
            })}
          />
          <Form.Check
            value={OPERATOR}
            type="radio"
            label="Operator"
            {...register("role")}
          />
          <Form.Check
            type="radio"
            value={VIEWER}
            label="Viewer"
            {...register("role")}
           />
    </Form.Group>
    {errors.role && <span>This field is required</span>}


    <Button type="submit" variant="primary" >Save changes</Button>
    </Form>

  const spinner = <Spinner height="24"/>;
  const successMessage = <p>All good, name was updated</p>;
  const failureMessage = <p>Oops, something has gone wrong.</p>;

  const body = state === "idle"
    ? form
    : state === "working"
    ? spinner
    : state === "succeeded"
    ? successMessage
    : state === "failed"
    ? failureMessage
    : <p>Unknown state</p>;

  return (
    <Modal show={true}>
    <Modal.Header closeButton onHide={onHide}>Add Permission</Modal.Header>
    <Modal.Body>
    {body}
    </Modal.Body>
    <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>Close</Button>
    </Modal.Footer>
    </Modal>
  )
}


export {
  Button_,
  Modal_,
}
