// @flow

import * as S from "sanctuary";
import {
  useSelector,
} from "react-redux";
import {
  type UserProfile,
} from "../Entity/Types";

const MERGE_USER_PROFILES= "MERGE_USER_PROFILES"

const mergeUserProfiles= (userProfiles:Array<UserProfile>) => {
  return {
    type: MERGE_USER_PROFILES,
    userProfiles: userProfiles,
  }
}


const makePairs = userProfiles => S.map (u => S.Pair (u.userId) (u)) (userProfiles)

const f = running_result => item => S.insert (S.fst (item)) (S.snd (item)) (running_result);


const merge = (stateProfiles: Array<UserProfile>) => (newProfiles: Array<UserProfile>) => {
  const statePairs= makePairs (stateProfiles);
  const newPairs = makePairs (newProfiles);
  const stateMap = S.fromPairs (statePairs);
  const updatedMap = S.reduce (f) (stateMap) (newPairs);
  return S.values (updatedMap)
}

const reducer = (state:Function=[], action:Function) => {
  switch (action.type) {
    case MERGE_USER_PROFILES:
      return merge(state)(action.userProfiles);
    default:
      return state
  }
}


const exists = (userProfiles: Array<UserProfile>) => (userId: string) => S.elem (userId) (S.map (S.prop ("userId")) (userProfiles))

const contains = anArray => anElement => S.elem (anElement) (anArray);


const userIdIn = (candidateUserIds) => (userProfile) => {
  const userId = S.prop ("userId") (userProfile);
  return contains(candidateUserIds)(userId);
}

const useUserProfiles = (userIds) => {
  const allUserProfiles = useSelector(state => state.userProfiles);
  const userProfiles = S.filter (userIdIn(userIds)) (allUserProfiles)
  return userProfiles
}


export {
  merge,
  reducer,
  reducer as userProfiles,
  mergeUserProfiles,
  exists,
  useUserProfiles,
}
