import React from "react";

import {
  connect,
} from "react-redux";


import {
  generateURL,
} from "./Authz.js";
import {
  getMappings,
  deleteMapping,
  insertMapping,
} from "./Mappings.js";
import {
  getTenants,
  testTenant,
} from "./Tenants.js";
import {
  selectToken,
} from "./Tokens.js";


class XeroV1AuthzApi {

  constructor (args) {
    this.token = args.token;
  }

  generateURL(args) {
    const args_ = {token: this.token, ...args}
    return generateURL(args_);
  }

  getTenants(args) {
    const args_ = {token: this.token, ...args}
    return getTenants(args_);
  }

  testTenant(args) {
    const args_ = {token: this.token, ...args}
    return testTenant(args_);
  }

  getMappings(args) {
    const args_ = {token: this.token, ...args}
    return getMappings(args_);
  }

  deleteMapping(args) {
    const args_ = {token: this.token, ...args}
    return deleteMapping(args_);
  }

  insertMapping(args) {
    const args_ = {token: this.token, ...args}
    return insertMapping(args_);
  }

  selectToken(args) {
    const args_ = {token: this.token, ...args}
    return selectToken(args_);
  }

}


const withXeroV1AuthzApi = (BaseComponent) => {

  const ApiEnabledComponent = (connectedProps) => {
    const xeroV1AuthzApi = new XeroV1AuthzApi({token: connectedProps.token});
    const {token, ...userProps} = connectedProps;
    const processedProps = {...userProps, xeroV1AuthzApi};

    return (
      <BaseComponent {...processedProps} />
    )
  }

  const connector = connect(
    (state) => {
      return {
        token: state.token
      }
    }
  );

  return connector(ApiEnabledComponent);

}


export {
  withXeroV1AuthzApi,
}
