// @flow
import React from "react";

import Form from "react-bootstrap/Form";

import * as S from "sanctuary";

import {
  type BmbixAddress,
} from "../../Entity/Types.js";

const View = (props: {
  addresses?: Array<BmbixAddress>,
  selectedAddressId?: string,
  messageToken?: string,
  onMessageTokenChange: Function,
  onAddressChange?: Function,
}) => {
  const {
    addresses,
    messageToken,
    onMessageTokenChange,
    onAddressChange,
  } = props;

  const options = !!addresses ? S.map (
    addr => <>
      <option value={addr.id}>{addr.alias}</option>
    </>) (addresses): null;

  return (
    <Form>
    <Form.Group>
    <Form.Label>Enter claim code</Form.Label>
    <Form.Control
      type="text"
      onChange={e => onMessageTokenChange(e.target.value)}
      defaultValue={messageToken}
    />
    <Form.Text>Enter the message token</Form.Text>
    </Form.Group>

    { !!onAddressChange ? !!addresses ? (
      <Form.Group>
      <Form.Label>Select destination address</Form.Label>
      <Form.Control as="select" onChange={e => onAddressChange(e.target.value)}>
      {options}
      </Form.Control>
      <Form.Text>Choose the destination address for this message. You can come
      back and change this before you commit.</Form.Text>
      </Form.Group>
    ): null: null }

    </Form>
  );
}

export {
  View,
}
