// @flow
import React from "react";
import {
  Route,
  useParams,
} from "react-router-dom";
import {
  useDispatch,
} from "react-redux";
import { fork } from "fluture";

import { UserView as AddressesUserView } from "../../Addresses/UserView.js";
import OrganizationsUserView from "../../Organization/OrganizationsUserView"
import OrganizationUserPage from "../../Organization/OrganizationUserPage"
import {
  IndexView as AddressIndexView,
  ClaimView as AddressClaimView,
  ExtractView as AddressExtractView,
  ExtractOneView as AddressExtractOneView,
  ExtractionsView as AddressExtractionsView,
  ExtractionView as AddressExtractionView,
  InsertView as AddressInsertView,
  IntegrationView as AddressIntegrationView,
  MessageView as AddressMessageView,
  MessageStatusView as AddressMessageStatusView,
  InboundMessageStatusView as AddressInboundMessageStatusView,
  InsertionsView as AddressInsertionsView,
  AcceptancesView as AddressAcceptancesView,
  ReceivedsView as AddressReceivedsView,
  RejectedsApp as AddressRejectedsView,
  SubmissionsView as AddressSubmissionsView,
  UnaddressedMessageView as AddressUnaddressedMessageView,
  UnaddressedMessagesView as AddressUnaddressedMessagesView,
} from "../../Addresses";
import {
  Full as FullMessageView,
} from "../../Messages/Full";

import {
  useToken,
} from "./../../State";
import {
  refreshPlatformAssignmentAndPlatformThunk,
} from "./../../Thunks/platform-assignments.js";

const Prefetch = (props: {
}) => {
  const dispatch = useDispatch();
  const token = useToken();
  const {
    addressId,
    organizationId,
  } = useParams();
  // const platformAssignment = usePlatformAssignment(addressId);
  // console.log("Prefetch platform assignment: ", platformAssignment);

  const future = dispatch(refreshPlatformAssignmentAndPlatformThunk(token)(organizationId)(addressId));

  fork(console.log)(console.log)(future)

  return null;
}


const UserRootPage = (props: {
}) => {
  return (
      <p>The User Page. Maybe list out accounting systems</p>
  );
}


const View = (props: {
  here: string,
}) => {
  const {
    here,
  } = props;

  return (
    <>
    <Route
      exact
      path={`${here}`}
      component={UserRootPage}
    />
    <Route
      exact
      path={`${here}/messages/:messageId`}
      component={FullMessageView}
    />
    <Route
      exact
      path={`${here}/organizations`}
      component={OrganizationsUserView}
    />
    <Route
      exact
      path={`${here}/organizations/:organizationId`}
      component={OrganizationUserPage}
    />
    <Route
      exact
      path={`${here}/organizations/:organizationId/addresses`}
      component={AddressesUserView}
    />
    <Route
      path={`${here}/organizations/:organizationId/addresses/:addressId`}
    ><Prefetch /></Route>
    <Route
      exact
      path={`${here}/organizations/:organizationId/addresses/:addressId`}
      component={AddressIndexView}
    />
    <Route
      exact
      path={`${here}/organizations/:organizationId/addresses/:addressId/claim`}
      component={AddressClaimView}
    />
    <Route
      exact
      path={`${here}/organizations/:organizationId/addresses/:addressId/integration`}
      component={AddressIntegrationView}
    />
    <Route
      exact
      path={`${here}/organizations/:organizationId/addresses/:addressId/extract`}
      component={AddressExtractView}
    />
    <Route
      exact
      path={`${here}/organizations/:organizationId/addresses/:addressId/extract-one`}
      component={AddressExtractOneView}
    />
    <Route
      exact
      path={`${here}/organizations/:organizationId/addresses/:addressId/extractions`}
      component={AddressExtractionsView}
    />
    <Route
      exact
      path={`${here}/organizations/:organizationId/addresses/:addressId/extractions/:extractionId`}
      component={AddressExtractionView}
    />
    <Route
      exact
      path={`${here}/organizations/:organizationId/addresses/:addressId/submissions`}
      component={AddressSubmissionsView}
    />
    <Route
      exact
      path={`${here}/organizations/:organizationId/addresses/:addressId/acceptances`}
      component={AddressAcceptancesView}
    />
    <Route
      exact
      path={`${here}/organizations/:organizationId/addresses/:addressId/receiveds`}
      component={AddressReceivedsView}
    />
    <Route
      exact
      path={`${here}/organizations/:organizationId/addresses/:addressId/rejecteds`}
      component={AddressRejectedsView}
    />
    <Route
      exact
      path={`${here}/organizations/:organizationId/addresses/:addressId/process`}
      component={AddressInsertView}
    />
    <Route
      exact
      path={`${here}/organizations/:organizationId/addresses/:addressId/insertions`}
      component={AddressInsertionsView}
    />
    <Route
      exact
      path={`${here}/organizations/:organizationId/addresses/:addressId/messages/:messageId`}
      component={AddressMessageView}
    />
    <Route
      exact
      path={`${here}/organizations/:organizationId/addresses/:addressId/messages-status/:messageId`}
      component={AddressMessageStatusView}
    />
    <Route
      exact
      path={`${here}/organizations/:organizationId/addresses/:addressId/inbound-messages-status/:messageId`}
      component={AddressInboundMessageStatusView}
    />
    <Route
      exact
      path={`${here}/organizations/:organizationId/addresses/:addressId/unaddressed-messages`}
      component={AddressUnaddressedMessagesView}
    />
    <Route
      exact
      path={`${here}/organizations/:organizationId/addresses/:addressId/unaddressed-messages/:unaddressedMessageId`}
      component={AddressUnaddressedMessageView}
    />
    </>
  )
}


export {
  View,
}


