// @flow
import React, { useState, useEffect } from "react";
import * as R from "ramda";
import * as S from "sanctuary";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import log from "loglevel";
import {
  Link,
} from "react-router-dom";
import {
  connect,
} from "react-redux";
import { fork } from "fluture";
import { Translation, registerTranslations } from "@psyycker/react-translation";
import {
  DesktopDownloadIcon,
} from "@primer/octicons-react";

import chineseCN from "./Translations/chinese-CN.json";
import frenchFR from "./Translations/french-FR.json"
import russianRU from "./Translations/russian-RU.json"
import {
  type BmbixAddress,
  type Account,
  type MessagesCount,
  type Permission,
  type Organization,
} from "../Entity/Types.js";
import {
  instanceService,
} from "../Entity/InstanceService.js";
import { useToken, useCatchExpiredToken } from "./../State/Token.js";
import { useAccount, } from "./../State/Account.js";
import {
  BmbixImage,
} from "../Widgets/BmbixImage";
import { countMessages } from "../Api/MartletOrganization/Users.js";
import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "../Util";
import { useAddressProfiles } from "./../State/AddressProfiles.js";
import { AddressProfileWidget } from "./../Widgets/address-profile.js";
import { caseInsensitiveAnywhere } from "./../Util/filter.js";

const logger = log.getLogger(":Pages:Home");


registerTranslations({
  "zh-CN": chineseCN,
  "fr-FR": frenchFR,
  "ru-RU": russianRU,
});

const BmbixBadge = (props: {
  type: string,
  value: string,
}) => {
  const {
    type,
    value,
  } = props;

  const variant = value === "0" ?  "success" :
    type === "unread" ? "danger" : "warning";

  return <Badge pill variant={variant}>{value}</Badge>

}


const getUnreadCount =
  (messagesCounts: Array<MessagesCount>) =>
  (addressId: string): string => {
    return S.pipe ([
        S.find (m => m.toId === addressId),
        S.map (x => x.messagesUnread),
        S.fromMaybe ("0"),
    ]) (messagesCounts)
  }


const getUnprocessedCount =
  (messagesCounts: Array<MessagesCount>) =>
  (addressId: string): string => {
    return S.pipe ([
        S.find (m => m.toId === addressId),
        S.map (x => x.messagesUnprocessed),
        S.fromMaybe ("0"),
    ]) (messagesCounts)
  }


const AddressesTable = (props: {
  addresses: Array<BmbixAddress>,
  addressProfiles: Array<Function>,
}) => {
  const catchExpiredToken = useCatchExpiredToken();
  const {
    addresses,
    addressProfiles,
  } = props;

  const [display, setDisplay] = useState(INPUT);
  const [messagesCounts, setMessagesCounts] = useState([]);
  const token = useToken();
  const account = useAccount();
  const userId = account.id;

  useEffect(
    () => {
      setDisplay(WAITING);
      const action = countMessages({token, userId,});
      fork(
        error => {
          setDisplay(FAILURE)
          catchExpiredToken(error);
          console.log(error);
        })(
        messagesCounts => {
          setMessagesCounts(messagesCounts);
          setDisplay(SUCCESS);
        })(action);
    },
    [],
  );

  const columns = [
    {
      Header: <Translation translationKey="AddressesTable.address" defaultValue="Address" />,
          filterable: true,
          id: "addressProfile",
          accessor: (address) => {
            const addressId = address.id;
            const addressProfile = addressProfiles[addressId];
            const text =
              !!addressProfile
              ? `${addressProfile.displayName} ${addressProfile.organizationProfile.name}`
              : "";
            return text;
          },
          width: 500,
          style: {overflow: "auto"},
          Cell:  ({original}) => {
            const addressId = original.id;
            const addressProfile = addressProfiles[addressId];
            return (
              <AddressProfileWidget
                addressProfile={addressProfile}
                linkTo="process"
              />
            )
          },
    },

    {
      Header: "Unread",
      accessor: address => {
        const unreadCount = getUnreadCount(messagesCounts)(address.id);
        return parseInt(unreadCount);
      },
      id: "unread",
      Cell:  ({row}) => {
        const organizationId = row._original.organizationId;
        const addressId = row._original.id;
        const unreadCount = getUnreadCount(messagesCounts)(addressId);
        return (
          <>
            <BmbixBadge type="unread" value={unreadCount} />
          </>
        )
      },
    },
    {
      Header: "Unprocessed",
      accessor: address => {
        const unprocessedCount = getUnprocessedCount(messagesCounts)(address.id);
        return parseInt(unprocessedCount);
      },
      id: "unprocessed",
      Cell:  ({row}) => {
        const organizationId = row._original.organizationId;
        const addressId = row._original.id;
        const unprocessedCount = getUnprocessedCount(messagesCounts)(addressId);
        return (
          <BmbixBadge type="unprocessed" value={unprocessedCount} />
        )
      },
    },

    {
      Header: <Translation translationKey="AddressesTable.inbound" defaultValue="Actions" />,
      sortable: false,
      columns:[
        {
          accessor: "process",
          Cell:  ({row, original}) => {
            const organizationId = row._original.organizationId;
            const addressId = row._original.id;
            return (
              <>
              <Link to={`/u/organizations/${organizationId}/addresses/${original.id}`}> <Translation translationKey="AddressesTable.process" defaultValue="process" /></Link>
              </>
            )
          },
        },
        {
          accessor: "id",
          Cell:  ({row}) => {
            const organizationId = row._original.organizationId;
            const addressId = row._original.id;
            return (
              <>
              <Link to={`/c?addressId=${addressId}`}><DesktopDownloadIcon /> <Translation translationKey="AddressesTable.claim" defaultValue="claim" /></Link>
              </>
            )
          },
        },
      ]
    },


    {
      Header: <Translation translationKey="AddressesTable.inbound_reports" defaultValue="Inbound" />,
      columns: [
        {
          Cell:  ({row}) => {
            const organizationId = row._original.organizationId;
            const addressId = row._original.id;
            const unreadCount = getUnreadCount(messagesCounts)(addressId);
            const unprocessedCount = getUnprocessedCount(messagesCounts)(addressId);
            return (
              <>
              <Link to={`/u/organizations/${organizationId}/addresses/${addressId}/receiveds`}><Translation translationKey="AddressesTable.receiveds" defaultValue="receiveds" /></Link>
              </>
            )
          },
        },
      ],
    },


    {
      Header: <Translation translationKey="AddressesTable.outbound" defaultValue="Outbound" />,
      columns:[
        {
          Cell:  ({row}) => {
            const organizationId = row._original.organizationId;
            const addressId = row._original.id;
            return (
              <>
              <Link to={`/u/organizations/${organizationId}/addresses/${addressId}/unaddressed-messages`}><Translation translationKey="AddressesTable.unaddresseds" defaultValue="unaddresseds" /></Link>
              </>
            )
          },
        },
        {
          Cell:  ({row}) => {
            const organizationId = row._original.organizationId;
            const addressId = row._original.id;
            return (
              <>
              <Link to={`/u/organizations/${organizationId}/addresses/${addressId}/submissions`}><Translation translationKey="AddressesTable.submissions" defaultValue="submissions" /></Link>
              </>
            )
          },
        },
        {
          Cell:  ({row}) => {
            const organizationId = row._original.organizationId;
            const addressId = row._original.id;
            return (
              <>
              <Link to={`/u/organizations/${organizationId}/addresses/${addressId}/acceptances`}><Translation translationKey="AddressesTable.acceptances" defaultValue="acceptances" /></Link>
              </>
            )
          },
        },
        {
          Cell:  ({row}) => {
            const organizationId = row._original.organizationId;
            const addressId = row._original.id;
            return (
              <>
              <Link to={`/u/organizations/${organizationId}/addresses/${addressId}/rejecteds`}><Translation translationKey="AddressesTable.rejecteds" defaultValue="rejecteds" /></Link>
              </>
            )
          },
        },
      ]
    },
  ]

  return (
    <ReactTable
      data={addresses}
      columns={columns}
      showPagination={false}
      pageSize={S.size (addresses)}
      className="-striped -highlight"
      defaultFilterMethod={caseInsensitiveAnywhere}
      //filtered={[{id: "alias" , value: "rebus"},]}
      defaultSorted={[{id: "unread", desc: true}]}
    />
  );
};

const getMyOrganizations = (args: {
  account: Account,
  permissions: Array<Permission>,
  organizations: Array<Organization>,
}) => {
  const {
    account,
    permissions,
    organizations,
  } = args;
  const contains = (organizationIds) => {
    return organization => R.contains(organization.id, organizationIds);
  }
  const me = `bmbix://user/${account.sub}`;
  const myPermissions = R.filter(R.propEq("actor", me))(permissions);
  const myResources = R.map(p => p.resource)(myPermissions);
  const myResourceBRIs = R.map(instanceService.createBRIFromString)(myResources);
  const myOrganizationBRIs = R.filter(R.propEq("resourceType", "organization"))(myResourceBRIs);
  const myOrganizationIds = R.map(a => a.resourceId)(myOrganizationBRIs);
  const myOrganizations = R.filter(contains(myOrganizationIds))(organizations);
  return myOrganizations;
}


const Home_ = (props: {
  history: Object,
  addresses: Array<BmbixAddress>,
    organizations: Array<Organization>,
    permissions: Array<Permission>,
    account: Account,
}) => {
  logger.debug("props:", props);
  const {
    addresses,
    organizations,
    permissions,
    account,
  } = props;
  const addressProfiles = useAddressProfiles();

  const getMyAddresses= ({account, permissions, addresses}) => {
    const contains = (addressIds) => {
      return address => R.contains(address.id, addressIds);
    }
    const me = `bmbix://user/${account.sub}`;
    const myPermissions = R.filter(R.propEq("actor", me))(permissions);
    const myResources = R.map(p => p.resource)(myPermissions);
    const myResourceBRIs = R.map(instanceService.createBRIFromString)(myResources);
    const myAddressBRIs = R.filter(R.propEq("resourceType", "address"))(myResourceBRIs);
    const myAddressIds = R.map(a => a.resourceId)(myAddressBRIs);
    const myAddresses = R.filter(x => !x.isClosed)(R.filter(contains(myAddressIds))(addresses));
    return myAddresses;
  }
  const myAddresses = getMyAddresses({account, permissions, addresses});
  const myOrganizations = getMyOrganizations({account, permissions, organizations});

  const newUser = myAddresses.length === 0 && myOrganizations.length === 0;

  const wizard = newUser ? (
    <>
    <h2>Business Owner</h2>
    <p>If you haven&apos;t used Bmbix before, and you have your own business, use
    this option to get everything setup and ready to use.</p>
    <p>All you need is the name of your organization</p>
    <p>Let&apos;s help you get set up.</p>
    <Link to={"/new-user-wizard-2"}><Button>Start Setup Wizard</Button></Link>
    </>
  ): null;

  const skip = newUser ? (
    <>
    <h2>Pro</h2>
    <p>If you have used Bmbix before and you want total control you can skip the
    wizard and go straight to the management console.</p>
    <Link to={"/m/organizations"}><Button>Skip</Button></Link>
    </>
  ): null;

  const share = newUser ? (
    <>
    <h2>End user</h2>
    <p>If you just want to use Bmbix to work with organizations controlled by
    others, you can get access by sharing your Bmbix user id.</p>
    <Link to={"/account"}><Button>Share</Button></Link>
    </>
  ): null;


  const addressTable = myAddresses.length > 0 ? (
    <AddressesTable
      addresses={myAddresses}
      addressProfiles={addressProfiles}
    />
  ): null;

  return (
    <>
    <Row>
    <Col><h2><Translation translationKey="Home.title" defaultValue="Message Dashboard" /></h2></Col>
    </Row>
    <Row>
    <Col>{wizard}</Col>
    <Col>{share}</Col>
    <Col>{skip}</Col>
    </Row>
    <Row>
    <Col>{addressTable}</Col>
    </Row>
    </>
  )
  }

const connector = connect(
  state => {
    return {
      addresses: state.addresses,
      account: state.account,
      permissions: state.permissions,
      organizations: state.orgs,
    }
  }
)

const Home = connector(Home_);

export default Home
export {
  getMyOrganizations,
  getUnreadCount,
}
