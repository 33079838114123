// @flow
import React from "react";


const View = (props: {
  iconPath: string,
  size: string,
}) => {
  const {
    iconPath,
    size,
  } = props;


  return (
    <svg width={size} height={size}><use xlinkHref={iconPath}></use></svg>
  )
}


export { View }
