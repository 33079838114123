// @flow

import React from "react";


const UserView = (props: {
}) => {

  return (
    <>
    <p>This is the Addresses/UserView page</p>
    </>
  );
}


export {
  UserView,
}
