// @flow
import Future from "fluture";

import {
  ApiClient,
  AddressesApi,
} from "@bmbix/bmb_martlet_organization_client";
import { gwToMessageType } from "./adapt.js";

const removeMessageType =
  (token: string) =>
  (address: string) =>
  (messageTypeId: string): Future =>
{
  console.log("token", token);
  console.log("address", address);
  console.log("messageTypeId", messageTypeId);

  const apiInstance = new AddressesApi();
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;

  return Future((reject, resolve) => {
    apiInstance.deleteMessageType(address, messageTypeId, (error, data, response) => {
      if (error) {
        console.log("error", error);
        reject(error);
      } else {
        const gw = data.message_type;
        const messageType = gwToMessageType(gw);
        resolve(messageType);
      }
    });


    return console.log
  });
}

export { removeMessageType }
