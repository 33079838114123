// @flow


export type MessageContentType = {|
  +id: string,
  +name: string,
  +organizationId: string,
  +b64Schema: string,
  +visibility: Array<string>,
|}


export const createMessageContentType = (args: MessageContentType) => Object.freeze(args)
