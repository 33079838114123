// @flow
import { createElement as el } from "react";

import { type Organization } from "../../../Entity/Types.js";

import { View } from "./view.js";

// import { instanceService } from "../../../Entity/InstanceService.js";

const Controller = (props: {
  organizations: Array<Organization>,
}) => {
  const {
    organizations,
  } = props;

  /*

  const organizations = [
    instanceService.createOrganization({
      name: "Big Organzition",
      id: "abcdabcd-abcd-abcd-abcd-abcdabcdabcd",
      isClosed: false,
    }),
    instanceService.createOrganization({
      name: "Little Co",
      id: "abcdabcd-abcd-abcd-abcd-abcdabcd0001",
      isClosed: false,
    }),
  ];
  */

  return el (
    View,
    {
      organizations,
    }
  )
}

export { Controller }

