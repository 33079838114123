// @flow
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {
  PlusIcon
} from "@primer/octicons-react"

import { type MessageType } from "./../../../../Entity/Types.js";

import { Controller as Table } from "./../../Widgets/Table/controller.js";
import { Controller as MessageTypesModalAdd } from "./../../Widgets/MessageTypesModalAdd/controller.js";

const AddMessageType = (props: {
  address: string,
  direction: string,
}) => {
  const {
    address,
    direction,
  } = props;

  const [showModal, setShowModal] = useState(false);

  return showModal ?
    <MessageTypesModalAdd direction={direction} address={address} onDone={() => setShowModal(false)}/> :
    <Button onClick={e => setShowModal(true)}><PlusIcon /> Add</Button>;
}

const View = (props: {
  address: string,
  direction: string,
  messageTypes: Array<MessageType>,
}) => {
  const {
    address,
    direction,
    messageTypes,
  } = props;

  const heading = direction === "inbound"
  ? "Inbound"
  : "Outbound"

  const greeting = direction === "inbound"
  ?
    <p>These are the inbound message types which are understood by this address.
    The owner of this address asserts that messages on this list will be
    processed in line with expectations for the message type.</p>
  :
    <p>These are the outbound message types which are sent by this address.</p>



  return (
    <>
    <ButtonToolbar className="float-right">
    <AddMessageType address={address} direction={direction}/>
    </ButtonToolbar>
    <h3>{heading} Message Types</h3>
    {greeting}
    <Table address={address} messageTypes={messageTypes} />
    </>
  )
}

export { View }
