// @flow
import React, {
  useState,
} from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

import {
  type Account,
} from "../../../Entity/Types.js";
import {
  UploadUserCertificateModal,
} from "../UploadUserCertificateModal";

import RawViewer from "./../../../Widgets/Certificate/RawViewer";
import Viewer from "./../../../Widgets/Certificate/Viewer";

import {
  Info,
} from "../../../Widgets/Info";

const SuccessView = (props: {
  account: Account,
  subjectName: string,
  certificateId: string,
  data: string,
  onSuccess: Function,
}) => {
  const {
    account,
    subjectName,
    data,
    certificateId,
    onSuccess,
  } = props;
  const [showX509Modal, setShowX509Modal] = useState(false);

  const x509Modal = showX509Modal ? (
    <UploadUserCertificateModal
      onHide={
        e => {
          setShowX509Modal(false)
          onSuccess(e);
        }
      }
      account={account}
    />
  ): null;

  return (
    <>
      <div className="clearfix">
        <h2 className="float-left">X509 Certificate</h2>
        <Info buttonText="help" infoTitle="X509 help" infoContent={`Obtain a
          Bmbix X509 identity certificate from your accountant or bookkeeper.`}/>
      </div>
      <p>Upload your X509 user certificate</p>
      <Button onClick={e => setShowX509Modal(true)}>Upload</Button>
      {x509Modal}
      <Viewer pem={data} />
      <RawViewer
        data={data}
        subjectName={subjectName}
        certificateId={certificateId}
      />
    </>
  );
}

const FailureView = (props: {
  onResetAfterFailure: Function,
}) => {
  const {
    onResetAfterFailure
  } = props;

  return (
    <>
    <p>Sorry, but we cannot find a certificate.</p>
    <p>Perhaps you have not uploaded your certificate yet?</p>
    <p>If you don&apos;t have a certificate, you can obtain one from an authorized
    Bmbix Certificate Issuer, usually your bookkeeper or accountant.</p>
    <p> You can search for a certificate issuer on <Link
    to="/profiles?query='role:certificate-issuer AND type:organization'">user profiles search</Link>.</p>
    <Button onClick={onResetAfterFailure}>Go to certficate upload</Button>
    </>
  )
}

const InputView = (props: {
  account: Account,
  onSuccess: Function,
}) => {
  const {
    account,
    onSuccess,
  } = props;
  const [showX509Modal, setShowX509Modal] = useState(false);

  const x509Modal = showX509Modal ? (
    <UploadUserCertificateModal
      onHide={e => {
        setShowX509Modal(false);
        onSuccess(e);
      }}
      account={account}
    />
  ): null;

  return (
    <>
      <p>Upload your X509 user certificate</p>
      <Button onClick={e => setShowX509Modal(true)}>Upload</Button>
      {x509Modal}
    </>
  )
}

export {
  SuccessView,
  FailureView,
  InputView,
}
