// @flow
import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { copyToClipboard } from "./../../../Util/copy.js";

import DownloadLink from "./../../Certificate/DownloadLink";

const View = (props: {
  data: string,
  filename: string,
}) => {
  const {
    data,
    filename,
  } = props;

  return (
  <>
    <Button className="mr-2" onClick={e => copyToClipboard(data)}>Copy</Button>
    <DownloadLink
      type="application/x-pem-file"
      data={data}
      filename={filename}
    />
    <Form.Control rows={60} as="textarea" value={data} readOnly></Form.Control>
  </>
  )
}

export {
  View,
}

