// @flow
import React from "react";
import Button from "react-bootstrap/Button";
import { Controller as PaymentMethodAddModal } from "./modal-controller.js";

const View = ({
  modalState,
  onStart,
}: {
  modalState: Function,
  onStart: Function,
}) => {
  console.log("modalState: ", modalState);

  return modalState.value === "button"
  ? <Button onClick={onStart}>Add Payment Method</Button>
  : <PaymentMethodAddModal />

}
export {
  View,
}
