// @flow
import { createElement as el } from "react";
import Certificate from 'pkijs/src/Certificate';
import * as asn1js from 'asn1js';
import AttributeTypeAndValue from 'pkijs/src/AttributeTypeAndValue';
import moment from 'moment';

import {
  type HumanCertificate,
} from "./types.js";

import { View } from "./view.js";

/*
 * Based on code example offered at:
 * https://github.com/PeculiarVentures/PKI.js/issues/57
 */


const validateToAndFrom = (from: Date, to: Date): boolean => {
    if (!moment(from).isBefore(new Date())) {
        return false
    } else if (!moment(to).isAfter(new Date())) {
        return false
    }

    return true
}

const formatDistinguishedName = (components: Object) => {

    const nameMap = {
        country: 'C',
        organizationName: 'O',
        organizationalUnit: 'OU',
        commonName: 'CN',
        localityName: 'L',
        stateName: 'S',
        '2.5.4.12': 'T',
        '2.5.4.42': 'GN',
        '2.5.4.43': 'I',
        '2.5.4.4': 'SN',
        email: 'E-mail',
        bmbix_id: "BMBIX_ID",
        uk_companies_house: "UK_COMPANIES_HOUSE",
        france_companies_house: "FRANCE_COMPANIES_HOUSE",
    }

    return Object.keys(components).filter(el => components[el])
        .map(key => `${nameMap[key]}=${components[key]}`)
        .join(',')
}


const keyInformationFormatter = (outputObject: HumanCertificate, parentObj: string, typeValues: AttributeTypeAndValue[]): HumanCertificate => {

    const rdnmap = {
        '2.5.4.6': 'C',
        '2.5.4.10': 'O',
        '2.5.4.11': 'OU',
        '2.5.4.3': 'CN',
        '2.5.4.7': 'L',
        '2.5.4.8': 'S',
        '2.5.4.12': 'T',
        '2.5.4.42': 'GN',
        '2.5.4.43': 'I',
        '2.5.4.4': 'SN',
        '1.2.840.113549.1.9.1': 'E-mail',
        '1.3.6.1.4.1.57106.0.1': 'BMBIX_ID',
        '1.3.6.1.4.1.57106.1.0': 'UK_COMPANIES_HOUSE',
        '1.3.6.1.4.1.57106.1.1': 'FRANCE_COMPANIES_HOUSE',
    }

    // Issuer
    for (const typeAndValue of typeValues) {
        // @ts-ignore
        const typeval = rdnmap[typeAndValue.type]

        if (typeval === 'E-mail') {
            outputObject[parentObj].components.commonName = typeAndValue.value.valueBlock.value
        } else if (typeval === 'CN') {
            outputObject[parentObj].components.commonName = typeAndValue.value.valueBlock.value
        } else if (typeval === 'OU') {
            outputObject[parentObj].components.organizationalUnit = typeAndValue.value.valueBlock.value
        } else if (typeval === 'O') {
            outputObject[parentObj].components.organizationName = typeAndValue.value.valueBlock.value
        } else if (typeval === 'L') {
            outputObject[parentObj].components.localityName = typeAndValue.value.valueBlock.value
        } else if (typeval === 'ST') {
            outputObject[parentObj].components.stateName = typeAndValue.value.valueBlock.value
        } else if (typeval === 'C') {
            outputObject[parentObj].components.country = typeAndValue.value.valueBlock.value
        } else if (typeval === 'BMBIX_ID') {
            outputObject[parentObj].components.bmbix_id = typeAndValue.value.valueBlock.value
        } else if (typeval === 'UK_COMPANIES_HOUSE') {
            outputObject[parentObj].components.uk_companies_house = typeAndValue.value.valueBlock.value
        } else if (typeval === 'FRANCE_COMPANIES_HOUSE') {
            outputObject[parentObj].components.france_companies_house = typeAndValue.value.valueBlock.value
        }
    }

    outputObject[parentObj].distinguishedName = formatDistinguishedName(outputObject[parentObj].components)

    return outputObject

}


const formatCertificateIntoHumanObjects = (certificate: Certificate): HumanCertificate => {

    let output: HumanCertificate = {
        issuer: {
            distinguishedName: '',
            components: {
                email: '',
                commonName: '',
                organizationalUnit: '',
                organizationName: '',
                localityName: '',
                stateName: '',
                country: ''
            }
        },
        subject: {
            distinguishedName: '',
            components: {
                email: '',
                commonName: '',
                organizationalUnit: '',
                organizationName: '',
                localityName: '',
                stateName: '',
                country: ''
            }
        },
        validFrom: certificate.notBefore,
        validTo: certificate.notAfter,
        isValid: validateToAndFrom(certificate.notBefore.value, certificate.notAfter.value),
        version: certificate.version
    }

    output = keyInformationFormatter(output, 'issuer', certificate.issuer.typesAndValues)
    output = keyInformationFormatter(output, 'subject', certificate.subject.typesAndValues)

    return output
}

const mkCert = (pem: string): ?Certificate => {
    const b64 = pem.replace(/(-----(BEGIN|END) CERTIFICATE-----|[\n\r])/g, '')

    // Now that we have decoded the cert it's now in DER-encoding
    const der = Buffer(b64, 'base64')

    // And massage the cert into a BER encoded one
    const ber = new Uint8Array(der).buffer

    const cert = new Certificate({
      schema: asn1js.fromBER(ber).result
    });

    const humcert = formatCertificateIntoHumanObjects(cert);

    return humcert;
}

const Controller = (props: {
  pem: string,
}) => {
  const {
    pem,
  } = props;

  const humanCertificate = mkCert(pem);

  return el (
    View,
    {
      humanCertificate,
    }
  )
}


export {
  mkCert,
  Controller,
}
