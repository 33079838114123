// @flow

import React, {
  useState,
} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import * as R from "ramda";
import ReactTable from "react-table-6"
import "react-table-6/react-table.css"
import {
  Link,
} from 'react-router-dom'

import {
  type Platform,
} from "../Entity/Types";

const CheckablePlatformsTable = (props: {
  items: Array<Platform>,
  checked: Array<any>,
  callBack: Function,
}) => {
    const {
      items,
      checked,
      callBack,
    } = props;

    const [chkd, setChkd] = useState(checked);

    const isChecked = (platformId) => {
       return R.contains(platformId, chkd);
    }

    const handleChange = (e) => {
      const isChecked = e.target.checked;
      const id = e.target.value;
      let newChecked;
      if(isChecked){
        newChecked = R.uniq(R.append(id, chkd));
      }
      else {
        newChecked = R.without(id, chkd);
      }
      setChkd(newChecked);
      callBack(newChecked);
    }

    const handleSelectAll = (e) => {
      const allPlatformIds = R.map(
        i => i.platformId,
        items,
      );
      setChkd(allPlatformIds);
      callBack(allPlatformIds);
    }

    const handleSelectNone = (e) => {
      const noneIds = [];
      setChkd(noneIds);
      callBack(noneIds);
    }

    const columns = [
        {
          maxWidth: 20,
          Cell:  ({row}) => {
            return (
              <Form.Check
                id={row.platformId}
                name="alexandria"
                key={row.platformId}
                value={row.platformId}
                onChange={handleChange}
                checked={isChecked(row.platformId)}
              />
            )
          }
        },
        {
          Header: "Name",
          accessor: "name",
        },
        {
          Header: "Type",
          accessor: "type"
        },
        {
          Header: "",
          accessor: "platformId",
          Cell:  ({row}) => {
            const path = `platforms/${row.platformId}/${row.type}`
            return (
              <Link to={{pathname: path}} >view</Link>)
          }
        }
  ]

  return (
    <>
    <Button className="mr-2" onClick={handleSelectAll}>Select All</Button>
    <Button className="mr-2" onClick={handleSelectNone}>Select None</Button>
    <ReactTable
      className="-striped -highlight"
      data={items}
      columns={columns}
      defaultPageSize={10}
    />
    </>
  )
}



export {
  CheckablePlatformsTable,
}
