import React from "react";
import * as R from "ramda";
import {
  withRouter,
} from "react-router-dom";
import {
  connect,
} from "react-redux";

import {
  type BmbixAddress,
} from "../Entity/Types.js";
/*
import {
  ECRView,
} from "../Pull";
*/
import {
  Controller as ECRView,
} from "../Extractions/ECRApp/Controller.js";


const ExtractView_ = (props: {
  address: BmbixAddress,
}) => {
  const {
    address,
  } = props;


  return (
    <>
      <ECRView
        organizationId={address.organizationId}
        addressId={address.id}
      />
    </>
  )
}

const connector = connect(
  (state, ownProps) => {
    const {
      match,
    } = ownProps;
    const addressId = match.params.addressId
    return {
      address: R.find(R.propEq("id", addressId))(state.addresses),
    }
  }
);

const ExtractView = withRouter(connector(ExtractView_));

export {
  ExtractView,
}
