// @flow
import React, {
  createElement as el,
  useState,
} from "react";
import * as S from "sanctuary";
import {
  useParams,
  useLocation,
  Link,
} from "react-router-dom";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  MentionIcon,
} from "@primer/octicons-react";
import {
  type BmbixAddress,
  type Platform,
} from "../Entity/Types.js";
import { useAddress } from "./../State";
import {
  usePlatformFromAddress,
} from "./../State/PlatformAssignments.js";


const Controller = (props :{
}) => {

  const {
    addressId,
  } = useParams();
  const address = useAddress(addressId);
  const platform = S.maybeToNullable (usePlatformFromAddress(addressId));

  return el(View, {address, platform});

}


const inOrOut = purpose =>
  purpose === "sales" ?  "outbound":
  purpose === "purchases" ?  "inbound" :
  "outbound";

const View = (props: {
  address: BmbixAddress,
  platform: Platform,
}) => {
  const location = useLocation();
  const {
    address,
    platform,
  } = props;
  const [key, setKey] = useState(inOrOut(address.purpose));

  const claimPath = `/c?addressId=${address.id}`;
  const submissionsPath = `${location.pathname}/submissions`;
  const acceptancesPath = `${location.pathname}/acceptances`;
  const receivedsPath = `${location.pathname}/receiveds`;
  const rejectedsPath = `${location.pathname}/rejecteds`;
  const unaddressedMessagesPath = `${location.pathname}/unaddressed-messages`;

  const connectionStatusWidget = !!platform ? (
    <>
      <p>Connected to {platform.name} ({platform.type})</p>
      <Link to={`${location.pathname}/integration`}>integration</Link>
    </>
  ): (
    <>
      <p>Not connected to any accounting platform</p>
    </>
  );

  return (
    <>
    <h2><MentionIcon size="medium" verticalAlign="middle"/> {address.alias}</h2>
    <>{connectionStatusWidget}</>
    <Tabs activeKey={key} onSelect={k => setKey(k)} id="address-inbound-outbound">
    <Tab eventKey="outbound" title="Outbound">
    <h3>Sending</h3>
    <hr/>
    <p>View messages sent without a destination address, to be claimed by code.</p>
    <Link to={unaddressedMessagesPath}><Button>Unaddressed Messages</Button></Link>
    <hr/>
    <p>See the progress of your messages after they have been successfully
    checked and validated by Bmbix and then read and processed by the recipient.</p>
    <Link to={submissionsPath}><Button className="mr-2" >Submissions</Button></Link>
    <Link to={acceptancesPath}><Button className="mr-2" >Acceptances</Button></Link>
    <Link to={rejectedsPath}><Button className="mr-2" >Rejecteds</Button></Link>
    </Tab>

    <Tab eventKey="inbound" title="Inbound">
    <h3>Receiving</h3>
    <hr/>
    <p>View messages received to this address.</p>
    <Link to={receivedsPath}><Button>Inbound Messages</Button></Link>
    <hr/>
    <p>Claim your messages using a code.</p>
    <Link to={claimPath}><Button>Claim message</Button></Link>
    </Tab>
    </Tabs>
    </>
  )
}


export {
  Controller as IndexView,
}
