// @flow

import {
  instanceService,
} from "../../Entity/InstanceService";
import {
  type Acceptance,
  type Message,
  type ReceivedMessage,
  type RejectedMessage,
} from "../../Entity/Types.js";
import * as R from "ramda";

import { callBack } from "./../CallBack";
import {
  Acceptance as AcceptanceGW,
  ApiClient,
  AddressesApi,
  Message as MessageGW,
  ReceivedMessage as ReceivedMessageGW,
  RejectedMessage as RejectedMessageGW,
  SelectionMetadata as SelectionMetadataGW,
} from "@bmbix/bmb_martlet_organization_client";

const createSelectionMetadataFromGW =
  (selectionMetadataGW: SelectionMetadataGW): SelectionMetadata =>
{
  return instanceService.createSelectionMetadata({
    pointer: selectionMetadataGW.pointer,
    count: selectionMetadataGW.count,
  });
}

const createMessageFromGW = (messageGW: MessageGW): Message => {
  const expected = instanceService.createMessage({
    messageId: messageGW.message_id,
    submissionTimestamp: messageGW.submission_timestamp,
    sourceAddressId: messageGW.source_address_id,
    destinationAddressId: messageGW.destination_address_id,
    senderReference: messageGW.sender_reference,
    contentMediaType: messageGW.content_media_type,
    content: messageGW.content,
    checksumAlgorithm: messageGW.checksum_algorithm,
    checksum: messageGW.checksum,
  });
  return expected;
}

const getMessage = (args: {
  token: string,
  organizationId: string,
  addressId: string,
  messageId: string,
  defaultClient_: Object,
}): Promise<Message> => {
  const {
    token,
    organizationId,
    addressId,
    messageId,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AddressesApi(defaultClient);

  const dataHandler = data => {
    const messageGW = data.message;
    const message = createMessageFromGW(messageGW);
    return message;
  }

  return new Promise((resolve, reject) => {
    apiInstance.getMessage(
      organizationId,
      addressId,
      messageId,
      callBack(reject, resolve)(dataHandler),
    );
  });
}

const getMessageNoContext = (args: {
  token: string,
  messageId: string,
  defaultClient_: Object,
}): Promise<Message> => {
  const {
    token,
    messageId,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AddressesApi(defaultClient);

  const dataHandler = data => {
    const messageGW = data.message;
    const message = createMessageFromGW(messageGW);
    return message;
  }

  return new Promise((resolve, reject) => {
    apiInstance.selectNoContext(
      messageId,
      callBack(reject, resolve) (dataHandler),
    );
  });
}


const createReceivedMessageFromGW = (receivedMessageGW: ReceivedMessageGW):
ReceivedMessage => {
  const expected = instanceService.createReceivedMessage({
    systemId: receivedMessageGW.system_id,
    messageId: receivedMessageGW.message_id,
    status: receivedMessageGW.status,
    submitterOrganizationId: receivedMessageGW.submitter_organization_id,
    fromAddressId: receivedMessageGW.from_address_id,
    toAddressId: receivedMessageGW.to_address_id,
    messageType: receivedMessageGW.message_type,
    senderReference: receivedMessageGW.sender_reference,
    submissionTimestamp: receivedMessageGW.submission_timestamp,
  });
  return expected;
}


const createRejectedMessageFromGW = (rejectedMessageGW: RejectedMessageGW):
RejectedMessage => {
  const expected = instanceService.createRejectedMessage({
    systemId: rejectedMessageGW.system_id,
    messageId: rejectedMessageGW.message_id,
    messageType: rejectedMessageGW.message_type,
    reasons: rejectedMessageGW.reasons,
    fromId: rejectedMessageGW.from_id,
    toId: rejectedMessageGW.to_id,
    senderReference: rejectedMessageGW.sender_reference,
    updatedAt: rejectedMessageGW.updated_at,
  });
  return expected;
}

// listSubmissionReceipts
const getReceivedMessages = (args: {
  token: string,
  organizationId: string,
  addressId: string,
  query: string,
  pointer: number,
  limit: number,
  defaultClient_: Object,
}): Promise<Array<ReceivedMessage>> => {
  const {
    token,
    organizationId,
    addressId,
    query,
    pointer,
    limit,
    defaultClient_,
  } = args;

  const opts = { query, pointer, limit};

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }


  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AddressesApi(defaultClient);

  const dataHandler = data => {
    const receivedMessagesGW = data.received_messages;
    const receivedMessages = R.map(
      rmgw => createReceivedMessageFromGW(rmgw),
      receivedMessagesGW,
    );

    const selectionMetadataGW = data.selection_metadata;
    const selectionMetadata =
      createSelectionMetadataFromGW(selectionMetadataGW);

    const receivedMessagesResponse =
      instanceService.createReceivedMessagesResponse({
        selectionMetadata,
        receivedMessages,
      });

    return receivedMessagesResponse;
  }

  return new Promise((resolve, reject) => {
    apiInstance.listSubmissionReceipts(
      addressId,
      opts,
      callBack(reject, resolve)(dataHandler),
    );
  });
}

// getSubmissionReceipt
const getReceivedMessage = (args: {
  token: string,
  organizationId: string,
  addressId: string,
  messageId: string,
  defaultClient_: Object,
}): Promise<ReceivedMessage> => {
  const {
    token,
    organizationId,
    addressId,
    messageId,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AddressesApi(defaultClient);

  const dataHandler = data => {
    const receivedMessageGW = data.received_message;
    const receivedMessage = createReceivedMessageFromGW(receivedMessageGW);
    return receivedMessage;
  }

  return new Promise((resolve, reject) => {
    apiInstance.getSubmissionReceipt(
      addressId,
      messageId,
      callBack(reject, resolve)(dataHandler),
    );
  });
}


// listRejectionNotices
const getRejectedMessages = (args: {
  token: string,
  organizationId: string,
  addressId: string,
  query: string,
  pointer: number,
  limit: number,
  defaultClient_: Object,
}): Promise<Array<RejectedMessage>> => {
  const {
    token,
    organizationId,
    addressId,
    query,
    pointer,
    limit,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AddressesApi(defaultClient);


  const dataHandler = data => {
    const rejectedMessagesGW = data.rejected_messages;
    const rejectedMessages = R.map(
      rmgw => createRejectedMessageFromGW(rmgw),
      rejectedMessagesGW,
    );

    const selectionMetadataGW = data.selection_metadata;
    const selectionMetadata =
      createSelectionMetadataFromGW(selectionMetadataGW);

    const rejectedMessagesResponse =
      instanceService.createRejectedMessagesResponse({
        selectionMetadata,
        rejectedMessages,
      });

    return rejectedMessagesResponse;
  }

  const opts = { query, pointer, limit};

  return new Promise((resolve, reject) => {
    apiInstance.listRejectionNotices(
      addressId,
      opts,
      callBack(reject, resolve) (dataHandler),
    );
  });
}

// getRejectionNoice
const getRejectedMessage = (args: {
  token: string,
  organizationId: string,
  addressId: string,
  messageId: string,
  defaultClient_: Object,
}): Promise<RejectedMessage> => {
  const {
    token,
    organizationId,
    addressId,
    messageId,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AddressesApi(defaultClient);

  const dataHandler = data => {
    const rejectedMessageGW = data.rejected_message;
    const rejectedMessage = createRejectedMessageFromGW(rejectedMessageGW);
    return rejectedMessage;
  }

  return new Promise((resolve, reject) => {
    apiInstance.getRejectionNotice(
      addressId,
      messageId,
      callBack(reject, resolve) (dataHandler),
    );
  });
}

const createAcceptanceFromGW = (acceptanceGW: AcceptanceGW ):Acceptance => {
  return instanceService.createAcceptance({
      systemId: acceptanceGW.system_id ,
      messageId: acceptanceGW.message_id ,
      fromAddressId: acceptanceGW.from_address_id ,
      toAddressId: acceptanceGW.to_address_id ,
      messageType: acceptanceGW.message_type ,
      submissionTimestamp: acceptanceGW.submission_timestamp ,
      senderReference: acceptanceGW.sender_reference ,
      checksum: acceptanceGW.checksum ,
      checksumAlgorithm: acceptanceGW.checksum_algorithm ,
      messageProcessedReceiptId: acceptanceGW.message_processed_receipt_id,
      receivedTimestamp: acceptanceGW.received_timestamp,
      result: acceptanceGW.result,
      reference: acceptanceGW.reference,
      comments: acceptanceGW.comments,
      messageReadReceiptId: acceptanceGW.message_read_receipt_id,
      readReceivedTimestamp: acceptanceGW.read_received_timestamp,
  });
}


const getAcceptedMessage = (args: {
  token: string,
  organizationId: string,
  addressId: string,
  messageId: string,
  defaultClient_: Object,
}): Promise<Acceptance> => {
  const {
    token,
    organizationId,
    addressId,
    messageId,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AddressesApi(defaultClient);

  const dataHandler = data => {
    const acceptanceGW = data.acceptance;
    const acceptance = createAcceptanceFromGW(acceptanceGW);
    return acceptance;
  }

  return new Promise((resolve, reject) => {
    console.log("calling getOutboundDeliveryAdvice");
    apiInstance.getOutboundDeliveryAdvice(
      addressId,
      messageId,
      callBack(reject, resolve) (dataHandler),
    );
  });
}


const getAcceptedMessages = (args: {
  token: string,
  organizationId: string,
  addressId: string,
  defaultClient_: Object,
}): Promise<Array<Acceptance>> => {
  const {
    token,
    organizationId,
    addressId,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AddressesApi(defaultClient);

  const dataHandler = data => {
    const acceptancesGW = data.acceptances;
    const acceptances = R.map(
      acceptance => createAcceptanceFromGW(acceptance),
      acceptancesGW,
    );
    return acceptances;
  }

  return new Promise((resolve, reject) => {
    apiInstance.selectAcceptedMessages(
      organizationId,
      addressId,
      callBack(reject, resolve) (dataHandler),
    );
  });
}


const getAcceptances = (args: {
  token: string,
  addressId: string,
  query: string,
  pointer: number,
  limit: number,
  defaultClient_: Object,
}): Promise<Array<Acceptance>> => {
  const {
    token,
    addressId,
    query,
    pointer,
    limit,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AddressesApi(defaultClient);

  const dataHandler = data => {
    const acceptancesGW = data.acceptances;
    const acceptances = R.map(
      acceptance => createAcceptanceFromGW(acceptance),
      acceptancesGW,
    );
    const selectionMetadataGW = data.selection_metadata;
    const selectionMetadata =
      createSelectionMetadataFromGW(selectionMetadataGW);
    const acceptancesResponse = instanceService.createAcceptancesResponse({
      selectionMetadata,
      acceptances,
    });
    return acceptancesResponse;
  }

  const opts = {query, pointer, limit}

  return new Promise((resolve, reject) => {
    apiInstance.listOutboundDeliveryAdvices(
      addressId,
      opts,
      callBack(reject, resolve) (dataHandler),
    );
  });
}


// listInboundDeliveryAdvices
const getDeliverableMessages = (args: {
  token: string,
  addressId: string,
  query: string,
  pointer: number,
  limit: number,
  defaultClient_: Object,
}): Promise<AcceptanceResponse> => {
  const {
    token,
    addressId,
    query,
    pointer,
    limit,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }


  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AddressesApi(defaultClient);

  const dataHandler = data => {
    const acceptancesGW = data.acceptances;
    const acceptances = R.map(
      acceptance => createAcceptanceFromGW(acceptance),
      acceptancesGW,
    );
    const selectionMetadataGW = data.selection_metadata;
    const selectionMetadata =
      createSelectionMetadataFromGW(selectionMetadataGW);
    const acceptancesResponse = instanceService.createAcceptancesResponse({
      selectionMetadata,
      acceptances,
    });
    return acceptancesResponse;
  }

  const opts = {query, pointer, limit}

  return new Promise((resolve, reject) => {
    apiInstance.listInboundDeliveryAdvices(
      addressId,
      opts,
      callBack(reject, resolve) (dataHandler),
    );
  });
}


const getInboundDeliveryAdvice = (args: {
  token: string,
  addressId: string,
  messageId: string,
}): Promise<Acceptance> => {
  const {
    token,
    addressId,
    messageId,
  } = args;

  const defaultClient = ApiClient.instance;

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AddressesApi(defaultClient);

  const dataHandler = data => {
    const acceptanceGW = data.acceptance;
    const acceptance = createAcceptanceFromGW(acceptanceGW);
    return acceptance;
  }

  return new Promise((resolve, reject) => {
    console.log("calling getInboundDeliveryAdvice");
    apiInstance.getInboundDeliveryAdvice(
      addressId,
      messageId,
      callBack(reject, resolve) (dataHandler),
    );
  });
}


export {
  createAcceptanceFromGW,
  createMessageFromGW,
  createReceivedMessageFromGW,
  createRejectedMessageFromGW,
  getAcceptances,  // listOutboundDeliveryAdvices
  getAcceptedMessage,
  getAcceptedMessages,
  getDeliverableMessages, // listInboundDeliveryAdvices
  getInboundDeliveryAdvice, // getInboundDeliveryAdvice
  getMessage,
  getMessageNoContext,
  getReceivedMessage,  // getSubmissionReceipt
  getReceivedMessages, // listSubmissionReceipts
  getRejectedMessage, // getRejectionNotice
  getRejectedMessages, // listRejectionNotices
}
