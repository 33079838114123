// @flow
import React from "react";

import { Route } from "react-router";


import {
  HomePage,
  ValidateOrganizationPage,
  ValidateUserPage,
  CertificatePage,
  CertificatesPage,
  RescindCertificatePage,
} from "./Widgets";


const View = (props: {
  match: Function,
}) => {
  const {
    match,
  } = props;

  return <>
    <Route path="/v" component={HomePage} exact />
    <Route path="/v/organizations" component={HomePage} exact />
    <Route path="/v/organizations/:organizationId/validate-organization" component={ValidateOrganizationPage} exact />
    <Route path="/v/organizations/:organizationId/validate-user" component={ValidateUserPage} exact />
    <Route path="/v/organizations/:organizationId/certificates" component={CertificatesPage} exact />
    <Route path="/v/organizations/:organizationId/certificates/:certificateId" component={CertificatePage} exact />
    <Route path="/v/organizations/:organizationId/certificates/:certificateId/rescind" component={RescindCertificatePage} exact />
   </>
}

export {
  View,
}
