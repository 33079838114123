// @flow
import { createElement as el, useState } from "react";
import { fork } from "fluture";
import { useDispatch } from 'react-redux';

import {
  updateAccessListMutation,
  useToken,
} from "../../../../State";
import { addList } from "../../Api";
import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "../../../../Util";
import View from "./view.js";
import { useCatchExpiredToken } from "./../../../../State/Token.js";

function Controller (props: {
  owner: string,
  onDone: Function,
}) {

  const {
    owner,
    onDone,
  } = props;

  const dispatch = useDispatch();
  const mutationAction = updateAccessListMutation();
  const token = useToken();
  const [display, setDisplay] = useState(INPUT);
  const [name, setName] = useState();
  const [type, setType] = useState();
  const catchExpiredToken = useCatchExpiredToken();

  const onSubmit = e => {
    setDisplay(WAITING);
    const action = addList(token)(owner)(name)(type);
    fork(error => {
      console.log(error);
      catchExpiredToken(error);
      setDisplay(FAILURE);
    })(list => {
      console.log("list", list);
      setDisplay(SUCCESS);
    })(action);
  }


  function onCancel (e) {
    console.log("onCancel:", e);
    onDone();
  }

  function onDoneFailure (e) {
    console.log("onDoneFailure:", e);
    onDone();
  }

  function onDoneSuccess(e) {
    console.log("onDoneSuccess:", e);
    dispatch(mutationAction);
    onDone();
  }

  function onChange (e) {
    console.log("onChange:", e);
    const fieldName = e.target.name;
    const fieldValue = e.target.value
    switch (fieldName) {
      case "name":
        setName(fieldValue);
        break;
      case "type":
        setType(fieldValue);
        break;
      default:
        break;
    }
  }

  return el(
    View,
    {
      display,
      onCancel,
      onSubmit,
      onDoneSuccess,
      onDoneFailure,
      onChange,
    }
  );

}

export { Controller as default };
