import * as R from "ramda";
import log from "loglevel";
import * as S from "sanctuary";

const logger = log.getLogger("State");

const ADD_MESSAGE_NOTES = "MESSAGE_NOTES_ADD";
const ADD_MESSAGE_NOTE = "MESSAGE_NOTE_ADD";
const DEL_MESSAGE_NOTE = "MESSAGE_NOTE_DELETE";
const UPDATE_MESSAGE_NOTE = "MESSAGE_NOTE_UPDATE";

const addMessageNotes = (messageNotes) => {
  return {
    type: ADD_MESSAGE_NOTES,
    messageNotes: messageNotes,
  }
}
const addMessageNote = (messageNote) => {
  return {
    type: ADD_MESSAGE_NOTE,
    messageNote: messageNote,
  }
}
const deleteMessageNote = (noteId) => {
  return {
    type: DEL_MESSAGE_NOTE,
    noteId: noteId,
  }
}
const updateMessageNote = (messageNote) => {
  return {
    type: UPDATE_MESSAGE_NOTE,
    messageNote: messageNote,
  }
}

const messageNotes = (state=[], action) => {
  logger.debug("state:", state)
  logger.debug("action:", action)
  switch (action.type) {
    case ADD_MESSAGE_NOTES:

      const notIn =
        (notes: Array<MessageNote>) =>
        (note: MessageNote): S.Maybe =>
        S.isNothing (S.find (n => n.noteId === note.noteId) (notes));


      const f = notes => {
        if (notes.length === 0) {
          return [];
        } else {
          const [head, ...tail] = notes;
          if (notIn(tail)(head)) {
            return [head].concat(f(tail));
          } else {
            return f(tail);
          }
        }
      }

      return f(action.messageNotes.concat(state));

    case ADD_MESSAGE_NOTE:
      return R.uniq(R.append(action.messageNote, state));
    case DEL_MESSAGE_NOTE:
      return R.filter(x => x.noteId !== action.noteId, state);
    case UPDATE_MESSAGE_NOTE:
      return R.uniq(R.append(action.messageNote, R.filter(x => x.noteId !== action.messageNote.noteId, state)));
    default:
      return state;
  }
}

export {
  messageNotes,
  addMessageNotes,
  addMessageNote,
  updateMessageNote,
  deleteMessageNote,
}

