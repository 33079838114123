// @flow

import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useForm, Controller } from "react-hook-form";


const AddForm = (props: {
  onSubmit: Function,
}) => {
  const {
    onSubmit,
  } = props;

  const { control, handleSubmit, formState: {errors} } = useForm();


  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Group>
    <Form.Label>Name this proxy</Form.Label>
    <Controller
      name="nameFromUser"
      control={control}
      defaultValue=""
      rules={{required: true, minLength:1 }}
      render={({field}) => <Form.Control {...field} />}
    />
    {errors.nameFromUser && <span>This field is required</span> }

    <Form.Text>eg ERP connector</Form.Text>
    </Form.Group>
    <Button as="input" type="submit" value="Submit" />
    </Form>
  )
}

export { AddForm }
