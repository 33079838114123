// @flow
import React from "react";

import {
  ApiClient,
  CertificateApi,
} from "@bmbix/bmb_polly_client";

import Future from "fluture";
import * as S from "sanctuary";

import {
  connect,
} from "react-redux";


import { createCertificateFromGW } from "./util.js";

const mapStateToProps = (state) => {
  return {
    token: state.token
  }
}

const selectByIssuer = (token: string) => (organizationId: string): Future => {

  const apiInstance = new CertificateApi();
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;

  return Future((reject, resolve) => {
    apiInstance.selectByIssuer(
      organizationId,
      (error, data, response) => {
        if(error){
          console.log("error 323423232342");
          reject(error);
        } else {
          console.log("success 52341789891");
          const certificatesGW = data.certificates;
          const certificates = S.map (createCertificateFromGW) (certificatesGW);
          resolve(certificates);
        }
      }
    );
    const cancelFunc = console.log;
    return cancelFunc;
  });

}

const withSelectByIssuer = (Component: Function) => {
  const newComponent = (propsWithToken) => {
    const {token, ...props} = propsWithToken;
    const apiFunc = selectByIssuer(token);
    const newProps = Object.assign(props, {selectByIssuer: apiFunc});
    return <Component {...newProps} />
  }
  return connect(mapStateToProps)(newComponent)
}

export {
  withSelectByIssuer,
}
