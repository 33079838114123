// @flow
import {
  createElement as el,
  useEffect,
  useState,
} from "react";
import {
  connect,
} from 'react-redux'

import {
  type Account,
} from "../../../Entity/Types.js";

import { SuccessView, FailureView, InputView } from "./view.js";
import { getUserCertificate } from "./../../../Api/MartletOrganization/Certificates";
import { useToken } from "./../../../State";
import { fork } from "fluture";

import { useMachine } from "@xstate/react";
import { createMachine } from "xstate";
import {
  Spinner,
} from "./../../../Widgets/Toast";


const fetchingMachine = createMachine({
  id: "fetchingMachine",
  initial: "waiting",
  states: {
    waiting: {
      on: {
        SUCCESS_HAPPENED: "success",
        FAILURE_HAPPENED: "failure",
      }
    },
    success: {
      on: {
        WAITING_HAPPENED: "waiting",
      }
    },
    failure: {
      on: {
        RESET_HAPPENED: "input"
      }
    },
    input: {
      on: {
        SUBMIT_HAPPENED: "waiting"
      }
    },
  },
});


const timestamp = () => (new Date()).getTime();


const Controller_ = (props: {
  account: Account,
}) => {
  const {
    account,
  } = props;
  const token = useToken();
  const [ certificate, setCertificate] = useState()
  const [ state, send ] = useMachine(fetchingMachine);
  const [ trigger, setTrigger ] = useState(timestamp());

  const onResetAfterFailure = e => send({type: "RESET_HAPPENED"});

  const onUploadSuccess = (e) => {
    setTrigger(timestamp());
  }

  useEffect(
    () => {
      send({type: "WAITING_HAPPENED"});
      const action = getUserCertificate(token)(account.id)
      fork(
        error => {
          console.log(error);
          send({type: "FAILURE_HAPPENED"});
        }
      )(
        certificate => {
          setCertificate(certificate)
          send({type: "SUCCESS_HAPPENED"});
        })(action)
    },
    [token, account, send, trigger],
  );

  return  state.value === "waiting"
  ? el (Spinner, {height: "50"})
  : state.value === "success"
  ? el (
      SuccessView,
      {
        account,
        data: certificate.data,
        subjectName:account.name,
        certificateId: certificate.cretificateId,
        onSuccess: onUploadSuccess,
      }
    )
  : state.value === "failure"
  ? el (
      FailureView,
      {
        onResetAfterFailure,
      },
  )
  : state.value === "input"
  ? el (
      InputView,
      {
        account,
        onSuccess: onUploadSuccess,
      }
    )
  : null;
}

const connector = connect(
  state => {
    return {
      account: state.account,
    }
  },
);

const Controller = connector(Controller_);

export {
  Controller,
}
