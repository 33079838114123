// @flow
import React from "react";
import Form from "react-bootstrap/Form";


import {BModal, BSuccess, BFailure} from "../../../../Widgets/BModal";

function EntryFormAdd (props: {
  onChange: Function,
  data: Function,
}) {
  const {
    onChange,
    data,
  } = props;

  return (
    <Form>
    <Form.Group>
    <Form.Label>Sender</Form.Label>
    <Form.Control
      type="text"
      name="sender"
      onChange={onChange}
      placeholder="Enter sender address bmbix id"
      value={data}
    />
    <Form.Text>The bmbix id of the sender address</Form.Text>
    </Form.Group>
    </Form>
  );
}

function View (props: {
  display: string,
  onSubmit: Function,
  onDoneSuccess: Function,
  onDoneFailure: Function,
  onCancel: Function,
  onChange: Function,
  data: Function,
}) {

  const {
    display,
    onCancel,
    onSubmit,
    onDoneSuccess,
    onDoneFailure,
    onChange,
    data,
  } = props;

  return (
    <BModal title={"Add access list entry"} display={display} onSubmit={onSubmit} onCancel={onCancel}
  onDoneSuccess={onDoneSuccess} onDoneFailure={onDoneFailure}>
      <EntryFormAdd onChange={onChange} data={data} />
      <BSuccess>Successfully added entry.</BSuccess>
      <BFailure>Oops, failed to add entry.</BFailure>
    </BModal>
  );

}

export { View as default };
