// @flow
import { createElement as el } from "react";
import { useMachine } from "@xstate/react";
import { createMachine } from "xstate";
import type { PaymentMethodLink } from "./../../../Entity/Types.js";
import { View } from "./view.js";


const modeMachineSpec = {
  id: "ModeMachine",
  context: {},
  initial: "showButton",
  states: {
    showButton: {
      on: {
        SHOW_MODAL: {
          target: "showModal",
        },
      },
    },
    showModal: {
      on: {
        SHOW_BUTTON: {
          target: "showButton",
        },
      },
    },
  },
}

const modeMachine = createMachine(modeMachineSpec);

const Controller = ({
  onDone,
  paymentMethodLink,
}: {
  onDone: Function,
  paymentMethodLink: PaymentMethodLink,
}) => {
  const [ state, send ] = useMachine(modeMachine);
  console.log("Geneva");

  const handleShowButtonClick = e => {
    send({type: "SHOW_MODAL"})
  }

  const handleCancel = e => {
    send({type: "SHOW_BUTTON"});
  }

  const handleDone = paymentMethodLink => {
    console.log("Submitted data event: ", paymentMethodLink);
    onDone(paymentMethodLink);
    send({type: "SHOW_BUTTON"})
  }

  return el(
    View,
    {
      state,
      onShowButtonClick: handleShowButtonClick,
      onCancel: handleCancel,
      onDone: handleDone,
      paymentMethodLink,
    },
  )
}



export {
  Controller,
}

