// @flow

import {
  type RejectedMessage,
  type AddressProfile,
} from "./../../Entity/Types.js";

export type DecoratedRejectedMessage = {
  +message: RejectedMessage,
  +addressProfile: AddressProfile,
}

export const createDecoratedRejectedMessage = (args: {
  message: RejectedMessage,
  addressProfile: AddressProfile,
}): DecoratedRejectedMessage => {
  return Object.freeze({
    message: args.message,
    addressProfile: args.addressProfile,
  });
}
