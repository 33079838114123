// @flow
import Future from "fluture";
import * as S from "sanctuary";

import {
  ApiClient,
  OrganizationsApi,
  MessageContentType as MessageContentTypeGW,
} from "@bmbix/bmb_martlet_organization_client";

import { callBack } from "./../../Api/CallBack";

import {
  type MessageContentType,
  createMessageContentType,
} from "./entity";


const createMessageContentTypeFromGW = (gw: MessageContentTypeGW) =>
  createMessageContentType({
    id: gw.id,
    name: gw.name,
    organizationId: gw.organization_id,
    b64Schema: gw.b64_schema,
    visibility: gw.visibility,
  })


const fetchMessageContentTypes =
  (token: string) =>
  (organizationId: string): Future<Array<MessageContentType>> =>
{
  console.log("Here's the token in the fluture: ", token);
  const options = {};

  const apiInstance = new OrganizationsApi();
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;

  const dataHandler = data =>
    S.map (createMessageContentTypeFromGW) (data.message_content_types)

  return Future((reject, resolve) => {
    apiInstance.listMessageContentTypes(
      organizationId,
      options,
      callBack(reject, resolve)(dataHandler),
    )
    return console.log;
  });
}


const addMessageContentType =
  (token: string) =>
  (organizationId: string) =>
  (name: string) =>
  (b64Schema: string) =>
  (visibility: Array<string>): Future<MessageContentType> =>
{

  const apiInstance = new OrganizationsApi();
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;

  const dataHandler = data =>
    createMessageContentTypeFromGW(data.message_content_type)

  const body = MessageContentTypeGW.constructFromObject({
    name,
    visibility,
    b64_schema: b64Schema,
  });

  return Future((reject, resolve) => {
    apiInstance.defineMessageContentType(
      body,
      organizationId,
      callBack(reject, resolve)(dataHandler),
    )
    return console.log;
  });
}


const fetchMessageContentType =
  (token: string) =>
  (organizationId: string) =>
  (id: string): Future<MessageContentType> =>
{

  const apiInstance = new OrganizationsApi();
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;

  const dataHandler = data =>
    createMessageContentTypeFromGW(data.message_content_type)

  return Future((reject, resolve) => {
    apiInstance.getMessageContentType(
      organizationId,
      id,
      callBack(reject, resolve)(dataHandler),
    )
    return console.log;
  });
}

export {
  fetchMessageContentTypes,
  addMessageContentType,
  fetchMessageContentType,
}

