// @flow

import {
  createElement as el,
  useState,
} from "react";

import {withMartletOrganizationApi} from "../../Api/MartletOrganization";
import {AddView} from "./AddView.js";

const AddController_ = (props: {
  messageId: string,
  addressId: string,
  martletOrganizationApi: Object,
}) => {

  const {
    addressId,
    messageId,
    martletOrganizationApi,
  } = props;

  const [content, setContent] = useState("");

  const onSubmit = async (e) => {
    if (content !== "") {
      await martletOrganizationApi.insertMessageNote({
        addressId,
        content,
        messageId,
      })
      setContent("");
    }
  }

  const onChange = (e) => {
    setContent(e.target.value);
  }

  const handleKeyPress = async (event) => {
    if(event.key === 'Enter'){
      event.preventDefault();
      await onSubmit(event);
    }
  }

  return el(
    AddView,
    {
      onSubmit,
      onChange,
      onKeyPress: handleKeyPress,
      content,
    },
  );
}


const AddController = withMartletOrganizationApi(AddController_);

export {
  AddController,
}
