// @flow
import {
  createElement as el,
} from "react";


import {
  type ExtractionCandidateReport,
} from "../../Entity/Types.js";
import {
  View,
} from "./View.js";


const Controller = (props: {
  onSubmit: Function,
  onCancel: Function,
  setCheckedRefs: Function,
  report: ExtractionCandidateReport,
}) => {

  const {
    setCheckedRefs,
    onSubmit,
    onCancel,
    report,
  } = props;

  return el(
    View,
    {
      setCheckedRefs,
      onSubmit,
      onCancel,
      report,
    }
  )
}

export {
  Controller,
}

