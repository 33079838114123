// @flow

import React, {
  useState,
} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import * as R from "ramda";
import {
  copyToClipboard,
} from "./../../../Util/copy.js";
import { NewClientCredentialsModal } from "./../new-client-credentials-modal/contoller.js";


import {
  type Oauth2Client,
  type OrganizationProxy,
} from "./../../../Entity/Types";
import { type OrganizationProxyOauth2Client } from "./../../entity.js";
import ReactTable from "react-table-6"
import "react-table-6/react-table.css"

const CheckableOrganizationProxiesTable = (props: {
  onNewClientCredentials: Function,
  items: Array<OrganizationProxyOauth2Client>,
  checked: Array<any>,
  callBack: Function,
}) => {
    const {
      items,
      checked,
      callBack,
      onNewClientCredentials,
    } = props;
    console.log("items: ", items);

    const [chkd, setChkd] = useState(checked);

    const isChecked = (accessKeyId) => {
       return R.contains(accessKeyId, chkd);
    }

    const handleChange = (e) => {
      const isChecked = e.target.checked;
      const accessKeyId = e.target.value;
      let newChecked;
      if(isChecked){
        newChecked = R.uniq(R.append(accessKeyId, chkd));
      }
      else {
        newChecked = R.without(accessKeyId, chkd);
      }
      setChkd(newChecked);
      callBack(newChecked);
    }

    const handleSelectAll = (e) => {
      const allAccessKeyIds = R.map(
        i => i.oauth2ClientId,
        items,
      );
      setChkd(allAccessKeyIds);
      callBack(allAccessKeyIds);
    }

    const handleSelectNone = (e) => {
      const noneAccessKeyIds = [];
      setChkd(noneAccessKeyIds);
      callBack(noneAccessKeyIds);
    }

    const columns = [
      /*
      */
      {
        maxWidth: 20,
        accessor: "oauth2ClientId",
        Cell:  ({original}) => {
          console.log("original: ", original);
          return (
            <Form.Check
              id={original.organizationProxy.id}
              name="cairo"
              key={original.organizationProxy.id}
              value={original.organizationProxy.id}
              onChange={handleChange}
              checked={isChecked(original.organizationProxy.id)}
            />
          )
        }
      },
      {
        Header: "Id",
        accessor: "organizationProxy.id",
      },
      {
        Header: "Name",
        accessor: "organizationProxy.name",
      },
      {
        Header: "Client Id",
        accessor: "oauth2Client.clientId",
      },
      {
        Header: "Client Credentials",
        Cell:  ({original}) => {
          console.log("the original: ", original);
          return (
            <NewClientCredentialsModal
            organizationProxy={original.organizationProxy} />
          )
        }
      },
  ]

  return (
    <>
    <Button className="mr-2" onClick={handleSelectAll}>Select All</Button>
    <Button className="mr-2" onClick={handleSelectNone}>Select None</Button>
    <ReactTable
      className="-striped -highlight"
      data={items}
      columns={columns}
      defaultPageSize={10}
      defaultSort={[{id: "reference", desc: true}]}
    />
    </>
  )
}

export {
  CheckableOrganizationProxiesTable,
}
