// @flow

import React from "react";
import * as S from "sanctuary";
import { useParams } from "react-router";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
} from 'react-router-dom'
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap'
import Button from "react-bootstrap/Button";

import BMBNavbar from './BMBNavbar'
import Home from "./Pages/Home";
import { NewUserWizardView } from "./Pages/NewUserWizardView.js";
import { NewUserWizard2 } from "./Pages/NewUserWizard2";
import { HammockSignupWizard } from "./Pages/HammockSignupWizard";
import { Process } from "./Pages/Process";
import { Notifications } from "./Notifications";
import { View as TokenExpiredView } from "./Pages/Login.js";
import { RequestLogout } from "./Pages/RequestLogout";
import { GotoLocation } from "./Pages/GotoLocation";
import { Manage } from "./Pages/Manage";
import { CenteredSpinner, } from "./Widgets/Toast.js";
import {
  WAITING,
  SUCCESS,
  FAILURE,
} from "./Util";
import Trail from "./Widgets/Trail";
import {
  App as ValidateApp,
  Trail as ValidateTrail,
} from "./ValidateApp";
import {
  App as ClaimApp,
  Trail as ClaimTrail,
} from "./ClaimApp";
import {
  App as AccountApp,
  Trail as AccountTrail,
} from "./AccountApp";
import {
  ProfilesApp,
  Trail as ProfilesTrail,
} from "./ProfilesApp";
import {
  MessagesApp,
  Trail as MessagesTrail,
} from "./MessagesApp";
import { useDispatch } from "react-redux";
import { useToken } from "./State/Token.js";
import { usePermissions } from "./State/Permissions.js";
import {
  refreshAddressProfilesThunk,
} from "./Thunks/address-profiles.js";
import { extractAddressId } from "./Util/bri.js";

const Prefetch = (props: {
}) => {
  const dispatch = useDispatch();
  const token = useToken();
  const permissions = usePermissions();
  const {
    addressId,
    organizationId,
  } = useParams();

  const resourceBRIs= S.map (S.prop ("resource")) (permissions);
  const maybeAddressIds = S.map (extractAddressId) (resourceBRIs);
  const addressIds = S.justs (maybeAddressIds);
  dispatch(refreshAddressProfilesThunk(token)(addressIds));

  return null;
}
const footer = (props) => {
  return (
    <footer className="footer mt-auto py-3 blotter bg-dark">
    <Container fluid={true} className="bg-dark"><Row><Col>
    <Container fluid={true} className="bg-dark"><Row><Col>
      <div>
        <span className="text-muted">Copyright Bmbix 2018-2023.</span>
      </div>
    </Col></Row></Container>
    </Col></Row></Container>
    </footer>
  );
}

const MainPresenter = (props: {
  display: string,
}) => {
  const {
    display,
  } = props;

  const waiting = display === WAITING ? (
    <CenteredSpinner />
  ): null;

  // <Col md={12} lg={12}>
  const success = display === SUCCESS ? (
    <>
    <Container fluid={true} className="bg-primary"><Row><Col>
    <Container fluid={true} className="bg-primary"><Row><Col>
      <BMBNavbar/>
    </Col></Row></Container>
    </Col></Row></Container>

    <Container fluid={true} className="trailbar"><Row><Col>
    <Container fluid={true} className="trailbar"><Row><Col>
      <Switch>
        <Route path="/a"><AccountTrail/></Route>
        <Route path="/c"><ClaimTrail/></Route>
        <Route path="/u"><Trail /></Route>
        <Route path="/m"><Trail /></Route>
        <Route path="/v"><ValidateTrail /></Route>
        <Route path="/profiles"><ProfilesTrail/></Route>
        <Route path="/messages"><MessagesTrail/></Route>
      </Switch>
    </Col></Row></Container>
    </Col></Row></Container>

    <Container fluid={true} className="actionsBar"><Row><Col>
    <Container fluid={true} className="actionsBar"><Row><Col>
    <Switch>
      <Route
        path="/u/organizations/:oid/addresses/:aid"
        render ={
          ({match}) => {

            const path = !!match.params.oid && !!match.params.aid ? `/m/organizations/${match.params.oid}/addresses/${match.params.aid}`: "" ;
            return (
               <Link to={path}><Button>Manage</Button></Link>
            );
          }
        }
    />
      <Route
        path="/m/organizations/:oid/addresses/:aid"
        render ={
          ({match}) => {

            const path = !!match.params.oid && !!match.params.aid ? `/u/organizations/${match.params.oid}/addresses/${match.params.aid}`: "" ;
            return (
               <Link to={path}><Button>Process</Button></Link>
            );
          }
        }
    />
    </Switch>
    </Col></Row></Container>
    </Col></Row></Container>

    <Container fluid={true} className="blotter"><Row><Col>
    <Container fluid={true} className="blotter"><Row><Col>
        <Route
          exact
          path="/"
        >
          <Prefetch />
        </Route>
        <Route
          exact
          path="/"
          component={Home}
        />
        <Route
          exact
          path="/request-logout"
          component={RequestLogout}
        />
        <Route
          exact
          path="/goto-location"
          component={GotoLocation}
        />
        <Route
          exact
          path="/notifications"
          component={Notifications}
        />
        <Route
          path="/profiles"
          component={ProfilesApp}
        />
        <Route
          path="/new-user-wizard"
          component={NewUserWizardView}
        />
        <Route
          path="/new-user-wizard-2"
          component={NewUserWizard2}
        />
        <Route
          path="/hammock-signup-wizard"
          component={HammockSignupWizard}
        />
        <Route
          path="/a"
          component={AccountApp}
        />
        <Route
          path="/c"
          component={ClaimApp}
        />
        <Route
          path={`/m/organizations`}
          component={Manage}
        />
        <Route
          path="/u"
          component={Process}
        />
        <Route
          path="/v"
          component={ValidateApp}
        />
        <Route
          path="/messages"
          component={MessagesApp}
        />
    </Col></Row></Container>
    </Col></Row></Container>
    {footer()}
    </>
  ): null;

  const failure = display === FAILURE ? (
    <>
      <Route
        exact
        path="/token-expired"
      >
        <TokenExpiredView display={FAILURE} />
      </Route>
    </>
  ): null;


  return (
    <Router>
    {waiting}
    {success}
    {failure}
    </Router>
  );
}


export {
  MainPresenter,
}
