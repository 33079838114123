// @flow
import Future from "fluture";
import * as S from "sanctuary";

import {
  ApiClient,
  OrganizationsApi,
  UsageReport as UsageReportGW,
  Price as PriceGW,
  Allowance as AllowanceGW,
  Usage as UsageGW,
  Product as ProductGW,
} from "@bmbix/bmb_martlet_organization_client";

import { callBack } from "./../../Api/CallBack";

import {
  createUsage,
  createProduct,
  createProductList,
  createPrice,
  createPriceList,
  createAllowance,
  createAllowanceList,
  createUsageReport,
  UsageReport,
  Product,
} from "./../entity";

const createProductFromGW = (prod: ProductGW): Product => createProduct({
  id: S.prop ("id") (prod),
  description: S.prop ("description") (prod),
});

const createPriceFromGW = (price: PriceGW): Price => createPrice({
  productId: S.prop ("product_id") (price),
  unitPrice: S.prop ("unit_price") (price),
});

const createAllowanceFromGW = (allowance: AllowanceGW): Allowance => createAllowance({
  productId: S.prop ("product_id") (allowance),
  quantity: S.prop ("quantity") (allowance),
});

const createUsageFromGW = (usage: UsageGW): Usage => createUsage({
  productId: S.prop ("product_id") (usage),
  quantity: S.prop ("quantity") (usage),
});


const createProductListFromGW = (productListGW: ProductListGW): ProductList =>
  createProductList({
      id: S.prop ("id") (productListGW),
      name: S.prop ("name") (productListGW),
      products: S.map (createProductFromGW)
        (S.prop ("products") (productListGW)),
  })
const createPriceListFromGW = (priceListGW: PriceListGW): PriceList =>
  createPriceList({
      id: S.prop ("id") (priceListGW),
      currency: S.prop ("currency") (priceListGW),
      name: S.prop ("name") (priceListGW),
      prices: S.map (createPriceFromGW)
        (S.prop ("prices") (priceListGW)),
  })
const createAllowanceListFromGW = (allowanceListGW: AllowanceListGW): AllowanceList =>
  createAllowanceList({
      id: S.prop ("id") (allowanceListGW),
      name: S.prop ("name") (allowanceListGW),
      allowances: S.map (createAllowanceFromGW)
        (S.prop ("allowances") (allowanceListGW)),
  })
const createUsageListFromGW = (usageListGW: UsageListGW): UsageList => S.map
(createUsageFromGW) (usageListGW)

const createUsageReportFromGW = (ur: UsageReportGW): UsageReport =>
  createUsageReport({
    productList: createProductListFromGW(S.prop ("product_list") (ur)),
    priceList: createPriceListFromGW(S.prop ("price_list") (ur)),
    allowanceList: createAllowanceListFromGW(S.prop ("allowance_list") (ur)),
    usageList: createUsageListFromGW(S.prop ("usage_list") (ur)),
  })

const fetchUsageReport = (args: {
  token: string,
  organizationId: string,
}) => {
  const {
    token,
    organizationId,
  } = args;

  const defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new OrganizationsApi(defaultClient);

  const dataHandler = data => {
    const usageReportGW = data.usage_report;
    const usageReport = createUsageReportFromGW(usageReportGW);
    return usageReport;
  }

  return Future((reject, resolve) => {
    apiInstance.getUsageReport(
      organizationId,
      callBack(reject, resolve)(dataHandler),
    )
    return console.log;
  })

}

export {
  fetchUsageReport,
}
