// @flow
import React from "react";
import Button from "react-bootstrap/Button";

import { type Organization } from "./../../../Entity/Types.js";
import { SicCodeModalChange } from "./../SicCodeModalChange";

const TheButton = (props: {
  handleActivate: Function,
}) => {
  const {
    handleActivate,
  } = props;

  return (
    <Button onClick={handleActivate}>Change</Button>
  )
}


const TheForm = (props: {
  organization: Organization,
  handleDeactivate: Function
}) => {
  const {
    handleDeactivate,
    organization,
  } = props;

  return (
    <SicCodeModalChange
      onDismiss={handleDeactivate}
      organization={organization}
    />
  )
}


export {
  TheButton,
  TheForm,
}
