// @flow
import * as S from "sanctuary";
import {
  type AddressProfile,
} from "./../Entity/Types.js";
import { Link } from "react-router-dom";
import {
  VerifiedIcon,
  AlertIcon,
} from "@primer/octicons-react";
import { BmbixImage } from "./BmbixImage";

const AddressProfileWidget = (props: {
  addressProfile: AddressProfile,
  linkTo: string,
  withIcon: boolean,
  withVerifiedStatus: boolean,
}) => {

  const {
    addressProfile = null,
    linkTo = "",
    withIcon = true,
    withVerifiedStatus = true,
  } = props;
  // default is first value
  // linkTo = "none", "manage", "process", "profile"
  // withIcon = true, false
  if (!addressProfile) {
    return null;
  }

  const withLink = !!linkTo;

  const addressText = S.prop ("displayName") (addressProfile);
  const addressId = S.prop ("addressId") (addressProfile);
  const organizationProfile = S.prop ("organizationProfile") (addressProfile);
  const organizationText = S.prop ("name") (organizationProfile);
  const organizationId = S.prop ("organizationId") (organizationProfile);

  const iconUrl = `https://bmbix-images.s3.eu-west-2.amazonaws.com/organization/${organizationId}`
  const icon = withIcon
  ? <BmbixImage className="h-10" src={iconUrl} />
  : null;

  const text = `${addressText}, ${organizationText}`;
  // const text = `${addressText}@${organizationText}`;
  // const text = `>${addressText}>${organizationText}`;

  const linkUrl = S.equals ("manage") (linkTo)
  ? `/m/organizations/${organizationId}/addresses/${addressId}`
  : S.equals ("process") (linkTo)
  ? `/u/organizations/${organizationId}/addresses/${addressId}`
  : S.equals ("profile") (linkTo)
  ? `/profiles/address/${addressId}`
  : `#`;

  const verif =
    S.prop ("verified") (organizationProfile)
    ? <VerifiedIcon fill="#218cf2"/>
    : <AlertIcon fill="DarkOrange"/>


  const result =
    withIcon
    ? withLink
      ? withVerifiedStatus
        ? <Link to={linkUrl}>{icon} {text} {verif}</Link>
        : <Link to={linkUrl}>{icon} {text}</Link>
      : withVerifiedStatus
        ? <>{icon} {text} {verif}</>
        : <>{icon} {text}</>
    : withLink
      ? withVerifiedStatus
        ? <Link to={linkUrl}>{text} {verif}</Link>
        : <Link to={linkUrl}>{text}</Link>
      : withVerifiedStatus
        ? <>{text} {verif}</>
        : <>{text}</>

  return result;

}


export {
  AddressProfileWidget,
  AddressProfileWidget as default,
}
