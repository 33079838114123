// @flow
import { createElement as el } from "react";

import { View } from "./view.js";


const Controller = (props: {
}) => {

  return el(
    View,
    {
    },
  );

}


export { Controller } ;
