// @flow

import React, {
  useState,
} from "react"
import Form from 'react-bootstrap/Form'
import { BmbixIcon } from "./../Widgets";


export const SelectPlatformTypeFormGroup = (props: {
}) => {
  const {
    onChange,
  } = props;

  return (
      <Form.Group onChange={onChange}>
        <div className="mb-3">
          <Form.Check
            type="radio"
            name="type"
            value="freeagent"
            label={<><BmbixIcon size="32" name="freeagent-icon" /> FreeAgent</>}
          />
        </div>
        <div className="mb-3">
          <Form.Check
            type="radio"
            name="type"
            value="freshbooks"
            label={<><BmbixIcon size="32" name="freshbooks-icon" /> FreshBooks</>}
          />
        </div>
        <div className="mb-3">
          <Form.Check
            type="radio"
            name="type"
            value="kashflow"
            label={<><BmbixIcon size="32" name="kashflow-icon" /> KashFlow</>}
          />
        </div>
        <div className="mb-3">
          <Form.Check
            type="radio"
            name="type"
            value="qbo"
            label={<><BmbixIcon size="32" name="quickbooks-icon" /> QuickBooks Online</>}
          />
        </div>
        <div className="mb-3">
          <Form.Check
            type="radio"
            name="type"
            value="sage"
            label={<><BmbixIcon size="32" name="sage-icon" /> Sage</>}
          />
        </div>
        <div className="mb-3">
          <Form.Check
            type="radio"
            name="type"
            value="xero"
            label={<><BmbixIcon size="32" name="xero-icon" /> Xero</>}
          />
        </div>
      </Form.Group>
  );

}

const AddForm = (props:{
  callBack: Function,
}) => {
  const {callBack} = props;

  const [type, setType] = useState("")
  const [name, setName] = useState("")

  const handleChange = (e) => {
    const NAME = "name";
    const TYPE = "type";
    const input_name = e.target.name;
    const value = e.target.value;
    let updatedName = name;
    let updatedType = type;
    switch(input_name){
      case TYPE:
        setType(value);
        updatedType = value;
        break;
      case NAME:
        setName(value);
        updatedName = value;
        break;
      default:
        throw new Error(`Unknown ${input_name}`);
    }
    callBack({
      name: updatedName,
      type: updatedType,
    });
  }

  return (
    <>
      <Form>
      <SelectPlatformTypeFormGroup onChange={handleChange} />

      <Form.Group>
      <Form.Label>Name</Form.Label>
      <Form.Control
        type="text"
        name="name"
        onChange={handleChange}
        placeholder="Enter platform name"
      />
      </Form.Group>
      </Form>
    </>
  );
}


export {
  AddForm,
}
