//@flow
import { useSelector } from "react-redux";

const ADD_SIGNUP_ORGANIZATION = "SIGNUP_ORGANIZATION_ADD";
const REMOVE_SIGNUP_ORGANIZATION = "SIGNUP_ORGANIZATION_REMOVE";
const ADD_SIGNUP_PLATFORM_TYPE = "SIGNUP_PLATFORM_TYPE_ADD";
const REMOVE_SIGNUP_PLATFORM_TYPE = "SIGNUP_PLATFORM_TYPE_REMOVE";

const addSignupOrganization = (signupOrganization) => {
  return {
    type: ADD_SIGNUP_ORGANIZATION,
    signupOrganization: signupOrganization,
  }
}

const removeSignupOrganization = () => {
  return {
    type: REMOVE_SIGNUP_ORGANIZATION,
  }
}

const signupOrganization = (state="", action) => {
  switch(action.type) {
    case ADD_SIGNUP_ORGANIZATION:
      return action.signupOrganization;
    case REMOVE_SIGNUP_ORGANIZATION:
      return "";
    default:
      return state;
  }
}

const useSignupOrganization = () => {
  return useSelector(store => store.signupOrganization)
}

const addSignupPlatformType = (signupPlatformType) => {
  return {
    type: ADD_SIGNUP_PLATFORM_TYPE,
    signupPlatformType: signupPlatformType,
  }
}

const removeSignupPlatformType = () => {
  return {
    type: REMOVE_SIGNUP_PLATFORM_TYPE,
  }
}

const signupPlatformType = (state="", action) => {
  switch(action.type) {
    case ADD_SIGNUP_PLATFORM_TYPE:
      return action.signupPlatformType;
    case REMOVE_SIGNUP_PLATFORM_TYPE:
      return "";
    default:
      return state;
  }
}

const useSignupPlatformType = () => {
  return useSelector(store => store.signupPlatformType)
}

export {
  addSignupOrganization,
  removeSignupOrganization,
  signupOrganization,
  useSignupOrganization,
  addSignupPlatformType,
  removeSignupPlatformType,
  signupPlatformType,
  useSignupPlatformType,
}

