// @flow

import {
  deserializeBRI,
} from "../Util";
import {
  ApiClient,
  SystemApi,
  UserProfilesRequest as UserProfilesRequestGW,
} from "@bmbix/bmb_martlet_organization_client";
import Future from "fluture";
import * as S from "sanctuary";
import * as $ from "sanctuary-def";
import log from "loglevel";
import {
  type Permission,
  type UserProfile
} from "../Entity/Types.js";


import {
  createUserProfileFromGW,
} from "../Api/MartletOrganization/Profile.js";


const logger = log.getLogger("EnrichedPermission");

//    getAttr :: String -> Maybe String
const getAttr = (attribute: string) => S.get (S.is ($.String)) (attribute)

const permissionsMap = (permissions: Array<Permission>) => S.fromPairs (
  S.justs (
    S.map (keyByUserId) (permissions)
  )
);

// :: UserProfile u => u -> Maybe Pair string u
const createUserProfilePair = (userProfile: UserProfile) => {
  return S.pipe([
    getAttr ("userId"),  // a -> Maybe string
    S.map (userId => S.Pair (userId) (userProfile))
  ]) (userProfile);
}

// userProfilesMap :: UserProfile u => [u] -> a
const userProfilesMap = (userProfiles: Array<UserProfile>) => {
  return S.pipe([
    S.map (createUserProfilePair),
    S.justs,
    S.fromPairs,
  ]) (userProfiles)
}

// dictify:: Permission -> Maybe Pair string Permission
const keyByUserId = (p: Permission) => {
  return S.map (userId => S.Pair (userId) (p)) (extractUserId (p));
}

// extractUserId :: Permission p => p -> Maybe string
const extractUserId = (permission: Permission) => {
  const maybeUserId = S.pipe([
    getAttr ("actor"), // a -> Maybe string
    S.map (deserializeBRI), // Maybe BRI
    S.map (getAttr ("resourceId")), //Maybe Maybe string
    S.join, // Maybe string
  ]) (permission)
  return maybeUserId;
}

const getUserIds = (permissions: Array<Permission>) => {
  const actual = S.pipe([
    S.map (extractUserId),
    S.justs,
  ]) (permissions)
  return actual;
}

/**
 * Returns Just([id1, id2, ...])
 */
// getUserIds :: Array Permission -> Array String

// fetchUserProfiles :: Array String -> Future Error (Array String)
// future = fetchUserProfiles(token)(userIds)
const fetchUserProfiles = token => userIds => {
  console.log("token:", token);
  console.log("userIds again:", userIds);
  const defaultClient = ApiClient.instance;
  const body = UserProfilesRequestGW.constructFromObject({
    user_ids: userIds,
  });
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new SystemApi(defaultClient);

  const future = Future(
    (rej, res) => {
      apiInstance.listUserProfiles(
        body,
        (error, data, response) => {
          if (error) {
            rej(error)
          } else {
            const userProfilesResponseGW = data;
            const userProfilesGW = userProfilesResponseGW.user_profiles;
            const userProfiles = S.map(
              userProfileGW => createUserProfileFromGW(userProfileGW))(userProfilesGW);
            res(userProfiles);
          }
        }
      );
      return () => {
        console.log("Nothing doing getting user Profiles");
      }
    }
  );
  return future;
}


export type EnrichedPermission = {
  +permissionId: string,
  +actor: string,
  +power: string,
  +resource: string,
  +actorName?: ?string,
  +userProfile: ?UserProfile,
}

const createEnrichedPermission = (args: {
  permissionId: string,
  actor: string,
  power: string,
  resource: string,
  actorName?: ?string,
  userProfile: ?UserProfile,
}) => {
  const {
    permissionId,
    actor,
    power,
    resource,
    actorName,
    userProfile,
  } = args;
  return Object.freeze({
    permissionId,
    actor,
    power,
    resource,
    actorName,
    userProfile,
  });
}

const buildEnrichedPermission = (
  permission: Permission,
  actorProfile?: UserProfile,
): EnrichedPermission => {
  return !!actorProfile ?
  createEnrichedPermission({
    permissionId: permission.permissionId,
    actor: permission.actor,
    power: permission.power,
    resource: permission.resource,
    actorName: actorProfile.name,
    userProfile: actorProfile,
  }):
  createEnrichedPermission({
    permissionId: permission.permissionId,
    actor: permission.actor,
    power: permission.power,
    resource: permission.resource,
    actorName: permission.actor,
    userProfile: actorProfile,
  });
}

const merge = (maybePermission, maybeUserProfile) => {
  if (S.isNothing (maybePermission)) {
    return S.Nothing
  } else {
    if (S.isNothing (maybeUserProfile)){
      return S.map (buildEnrichedPermission) (maybePermission)
    } else {
      return S.map (args => buildEnrichedPermission(...args)) (S.sequence (S.Maybe) ([maybePermission, maybeUserProfile]))
    }
  }
}

const unwrap = pair => merge(S.fst(pair), S.snd(pair))

const zipByKey = (longerMap, shorterMap) => {
  return S.map (key => S.Pair (S.value (key) (longerMap)) (S.value (key) (shorterMap))) (S.keys (longerMap))
}

export {
  buildEnrichedPermission,
  createEnrichedPermission,
  createUserProfilePair,
  extractUserId,
  fetchUserProfiles,
  getAttr,
  getUserIds,
  keyByUserId,
  merge,
  permissionsMap,
  userProfilesMap,
  unwrap,
  zipByKey,
}
