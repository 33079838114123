// @flow
import React from "react";
import Button from "react-bootstrap/Button";

const View = (props: {
  onDone: Function,
}) => {
  const {
    onDone,
  } = props;

  return (
    <>
    <p>Your extraction instructions have been successfully queued.</p>
    <Button onClick={onDone}>Done</Button>
    </>
  );
}
export {
  View,
}
