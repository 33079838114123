// @flow
import {
  createElement as el,
} from "react";
import {
  connect,
} from 'react-redux'

import {
  instanceService,
} from "../../../Entity/InstanceService.js";
import {
  type Account,
  type Settings,
} from "../../../Entity/Types.js";

import { View } from "./view.js";


const Controller_ = (props: {
  account: Account,
  settings: Settings,
  freudApi: Object,
  generatePrivateKey: Function,
}) => {
  const {
    account,
    settings,
  } = props;
  // userCard no longer used. Remains here to remind me how it's done.

  const settings_ = !!settings ? settings : instanceService.createSettings({
    userId: account.sub
  });


  return el (
    View,
    {
      account,
      settings: settings_,
    }
  );
}

const connector = connect(
  state => {
    return {
      account: state.account,
      settings: state.settings,
    }
  },
);

const Controller = connector(Controller_);

export {
  Controller,
}
