// @flow
import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { withRouter, Switch, Route, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  VerifiedIcon,
} from "@primer/octicons-react"
import { type Organization } from "../../../Entity/Types.js";
import { getOrganization } from "../../../State/Organizations.js";


const mapStateToProps = state => {
  return {
    organizations: state.orgs,
  }
}

const getOrganizationName = (organizations: Array<Organization>) =>
  (organizationId: string): string => {
    const organization = getOrganization(organizationId, organizations);
    const organization_name = !!organization ? organization.name: organizationId;
    return organization_name;
  }

const View = (props: {
  location: Function,
  match: Function,
}) => {
  return (
    <>
    <Switch>
      <Route exact path="/v/organizations/:organizationId/certificates/:certificateId"><CertificateTrail/></Route>
      <Route path="/v/organizations/:organizationId/certificates/:certificateId"><CertificateActionTrail/></Route>
      <Route exact path="/v/organizations/:organizationId/certificates"><OrganizationTrail/></Route>
      <Route exact path="/v/organizations/:organizationId"><OrganizationTrail/></Route>
      <Route path="/v/organizations/:organizationId"><OrganizationActionTrail/></Route>
      <Route path="/v/organizations"><HomeTrail/></Route>
      <Route path="/v"><HomeTrail/></Route>
    </Switch>
    </>
  );
}


const HomeTrail = (props: {
}) => {

  return (
    <Breadcrumb>
      <Breadcrumb.Item active><VerifiedIcon/> Verify</Breadcrumb.Item>
    </Breadcrumb>
  )
}


const OrganizationTrail = connect(mapStateToProps)(withRouter((props: {
  organizations: Array<Organization>,
  match: Function,
  location: Function,
}) => {
  const {
    organizations,
    match,
  } = props;

  const organizationId = match.params.organizationId;
  const organizationName = getOrganizationName(organizations)(organizationId)

  const linkProps1 = {
    to: `/v/organizations`
  }

  return (
    <Breadcrumb>
      <Breadcrumb.Item linkAs={Link} linkProps={linkProps1} ><VerifiedIcon/> Verify</Breadcrumb.Item>
      <Breadcrumb.Item active > {organizationName}</Breadcrumb.Item>
    </Breadcrumb>
  )
}));


const OrganizationActionTrail = connect(mapStateToProps)(withRouter((props: {
  organizations: Array<Organization>,
  match: Function,
  location: Function,
}) => {
  const {
    organizations,
    match,
  } = props;

  const organizationId = match.params.organizationId;
  const organizationName = getOrganizationName(organizations)(organizationId)

  const linkProps1 = {
    to: `/v/organizations`
  }
  const linkProps2 = {
    to: `/v/organizations/${organizationId}/certificates`
  }

  return (
    <Breadcrumb>
      <Breadcrumb.Item linkAs={Link} linkProps={linkProps1} ><VerifiedIcon/> Verify</Breadcrumb.Item>
      <Breadcrumb.Item linkAs={Link} linkProps={linkProps2}> {organizationName}</Breadcrumb.Item>
    </Breadcrumb>
  )
}));


const CertificateTrail = connect(mapStateToProps)(withRouter((props: {
  organizations: Array<Organization>,
  match: Function,
  location: Function,
}) => {
  const {
    organizations,
    match,
  } = props;

  const organizationId = match.params.organizationId;
  const organizationName = getOrganizationName(organizations)(organizationId);
  const certificateId = match.params.certificateId;

  const linkProps1 = {
    to: `/v/organizations`
  }
  const linkProps2 = {
    to: `/v/organizations/${organizationId}/certificates`
  }

  return (
    <Breadcrumb>
      <Breadcrumb.Item  linkAs={Link} linkProps={linkProps1}> <VerifiedIcon/> Verify</Breadcrumb.Item>
      <Breadcrumb.Item  linkAs={Link} linkProps={linkProps2}> {organizationName}</Breadcrumb.Item>
      <Breadcrumb.Item active > {certificateId }</Breadcrumb.Item>
    </Breadcrumb>
  )
}));




const CertificateActionTrail = connect(mapStateToProps)(withRouter((props: {
  organizations: Array<Organization>,
  match: Function,
  location: Function,
}) => {
  const {
    organizations,
    match,
  } = props;

  const organizationId = match.params.organizationId;
  const organizationName = getOrganizationName(organizations)(organizationId);
  const certificateId = match.params.certificateId;

  const linkProps1 = {
    to: `/v/organizations`
  }
  const linkProps2 = {
    to: `/v/organizations/${organizationId}/certificates`
  }
  const linkProps3 = {
    to: `/v/organizations/${organizationId}/certificates/${certificateId}`
  }

  return (
    <Breadcrumb>
      <Breadcrumb.Item  linkAs={Link} linkProps={linkProps1}> <VerifiedIcon/> Verify</Breadcrumb.Item>
      <Breadcrumb.Item  linkAs={Link} linkProps={linkProps2}> {organizationName}</Breadcrumb.Item>
      <Breadcrumb.Item  linkAs={Link} linkProps={linkProps3}> {certificateId}</Breadcrumb.Item>
    </Breadcrumb>
  )
}));



export { View }
