// @flow
import { createElement as el } from "react";
import { View } from "./view.js";


const Controller = (props: {
  onChange: Function,
}) => {
  const {
    onChange,
  } = props;

  return el(
    View,
    {
      onChange,
    },
  );
}


export {
  Controller,
}
