// @flow

import React, {
  createElement as el,
} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  assign,
  createMachine,
  send
} from "xstate";
import {
  useMachine,
} from "@xstate/react";

import {
  useToken,
} from "./../../../State";
import {
  TenantMapping,
} from "./../../../Entity/Types.js";
import {
  withXeroV1AuthzApi,
} from "./../../../Api/XeroV1Authz";
import {
  Spinner,
} from "./../../../Widgets/Toast";


const stateChart = {
  id: "DeleteTenantMapping",
  context: {},
  initial: "idle",
  states: {
    idle: {
      on: {
        SUBMIT: {
          target: "working",
        }
      }
    },
    working: {
      invoke: {
        id: "Invoke delete tenantMapping",
        src: "deleteTenantMapping",
        onDone: {
          actions: [
            assign((context, event) => ({result: event.data})),
            send("SUCCESS"),
          ],
        },
        onError: {
          actions: [
            send("FAILURE"),
          ],
        }
      },
      on: {
        SUCCESS: {
          target: "success",
        },
        FAILURE: {
          target: "failure",
        },
      },
    },
    success: {
      type: "final",
    },
    failure: {
      type: "final",
    }
  }
};


const Controller = (props: {
  xeroV1AuthzApi: Function,
  tenantMapping: TenantMapping,
  onHide: Function,
  onUpdate: Function,
}) => {
  const {
    xeroV1AuthzApi,
    tenantMapping,
    onHide,
    onUpdate,
  } = props;
  const token = useToken();
  const tenantMappingId = tenantMapping.tenantMappingId;

  const config = {
    services: {
      deleteTenantMapping: (context, event) => xeroV1AuthzApi.deleteMapping({
        token: context.token,
        tenantMappingId: context.tenantMappingId,
      })
    }
  }

  const machine = createMachine(stateChart, config);
  const contextMachine = machine.withContext({
    token,
    tenantMappingId,
  });
  const [ state, sendEvent ] = useMachine(contextMachine);

  const handleSubmit = e => {
    sendEvent({type: "SUBMIT"});
  }

  const handleCancel = e => {
    onHide();
    onUpdate();
  }

  const view = el(
    View,
    {
      onCancel: handleCancel,
      onSubmit: handleSubmit,
      state,
    },
  )

  return view;
}

const View = (props: {
  state: Function,
  onCancel: Function,
  onSubmit: Function,
}) => {

  const {
    state,
    onCancel,
    onSubmit,
  } = props;

  const tenantMappingId = state.context.tenantMappingId;

  const idleBody = <>
      <p>Tenant mapping: {tenantMappingId}</p>
  </>
  const idleButtons = <>
    <Button onClick={onSubmit}>Submit</Button>
    <Button onClick={onCancel}>Cancel</Button>
  </>

  const workingBody = <Spinner height="50" />
  const workingButtons = <>
    <Button onClick={onCancel}>Cancel</Button>
  </>

  const successBody = <p>Success</p>
  const successButtons = <>
    <Button onClick={onCancel}>Dismiss</Button>
  </>

  const failureBody = <p>Ooops something has gone wrong.</p>
  const failureButtons = <>
    <Button onClick={onCancel}>Dismiss</Button>
  </>

  const body = state.value === "idle"
  ? idleBody
  : state.value === "working"
  ? workingBody
  : state.value === "success"
  ? successBody
  : state.value === "failure"
  ? failureBody
  : <p>Unknown state</p>;

  const buttons = state.value === "idle"
  ? idleButtons
  : state.value === "working"
  ? workingButtons
  : state.value === "success"
  ? successButtons
  : state.value === "failure"
  ? failureButtons
  : <p>Unknown state</p>;

  return <Modal show={true} onHide={onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Tenant Mapping</Modal.Title>
        </Modal.Header>
        <Modal.Body> {body} </Modal.Body>
        <Modal.Footer> {buttons} </Modal.Footer>
        </Modal>
}

const TenantMappingDeleteModal = withXeroV1AuthzApi(Controller);

export {
  Controller,
  TenantMappingDeleteModal,
}
