// @flow
import {
  Link,
  Route,
  useRouteMatch,
} from "react-router-dom";

import { Page1 } from "./widgets/page1";
import { Page2 } from "./widgets/page2";

const View = (props: {
}) => {

  const match = useRouteMatch("/hammock-signup-wizard");
  const page1Path = `${match.url}/page1`;
  const page2Path = `${match.url}/page2`;

  return <>
    <p>Hammock</p>
    <Route path={`${match.path}/page1`} component={Page1}/>
    <Route path={`${match.path}/page2`} component={Page2} />
    </>
}


export { View }
