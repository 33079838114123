// @flow

import React, {
  useState,
} from "react";
import * as R from "ramda";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  connect,
} from "react-redux";

import {
  Spinner,
} from "../Widgets/Toast";
import {
  DeleteForm,
} from "./DeleteForm";
import {
  type AddressBookEntry,
} from "../Entity/Types.js";
import {
  withIsimudApi,
} from "../Api/Isimud";
import {
  deleteAddressBookEntry,
} from "../State";


function DeleteModal_ (props: {
  organizationId: string,
  addressId: string,
  addressBookEntries: Array<AddressBookEntry>,
  callbackSubmit: Function,
  callbackCancel: Function,
  isimudApi: Object,
  deleteAddressBookEntry: Function,
}) {

  const INPUT = "input";
  const WAITING = "waiting";
  const SUCCESS = "success";
  const FAILURE = "failure";

  const [display, setDisplay] = useState(INPUT);
  const [confirmation, setConfirmation] = useState(false);

  const handleClose = (e) => {
    e.preventDefault();
    props.callbackCancel();
  }

  const handleSave= (e) => {
    e.preventDefault();
    if(confirmation){

    const promises = R.map(
      abe => props.isimudApi.deleteAddressBookEntry({
        organizationId: props.organizationId,
        addressId: props.addressId,
        addressBookEntryId: abe.addressBookEntryId,
      }),
      props.addressBookEntries,
    );
    Promise.allSettled(promises).then(
      outcomes => {
        const successes = R.filter(
          outcome => outcome.status === "fulfilled",
          outcomes,
        );
        if (successes.length !== outcomes.length) {
          throw new Error("Not all entries were deleted");
        }
        R.map(
          abe => props.deleteAddressBookEntry(abe.addressBookEntryId),
          props.addressBookEntries,
        )
        setDisplay(SUCCESS)
        console.log("outcomes", outcomes);
      }
    ).catch(
      error => {
        setDisplay(FAILURE);
        console.log("error", error);
      }
    );
      props.callbackSubmit();
    }
  }

  const body = () => {
    switch (display) {
      case INPUT:
        return <DeleteForm
          addressBookEntries={props.addressBookEntries}
          callBack={setConfirmation}
          />
      case WAITING:
        return <Spinner />
      case SUCCESS:
        return <p>Deleted address book entries</p>
      case FAILURE:
        return <p>Ooops, Looks like something went wrong</p>
    }
  }

  return (
    <Modal show={true} >
      <Modal.Header>
        <Modal.Title>Delete Address Book Entries</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body()}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
          <Button variant="primary" onClick={handleSave}>Save changes</Button>
        </Modal.Footer>
    </Modal>
  );

}

const mapStateToProps = (state, ownProps) => {
  return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    deleteAddressBookEntry: (addressBookEntryId) => dispatch(
      deleteAddressBookEntry(addressBookEntryId)),
  }
}

const connector = connect(
  mapStateToProps,
  mapDispatchToProps,
)

const DeleteModal = connector(withIsimudApi(DeleteModal_));

export {
  DeleteModal,
}


