// @flow

import React from "react"
import Nav from "react-bootstrap/Nav"
import {
  Link,
  withRouter,
} from "react-router-dom"
import log from "loglevel";
import {
  VerifiedIcon,
  PeopleIcon,
  LockIcon,
  PersonIcon,
  ToolsIcon,
} from "@primer/octicons-react";

const logger = log.getLogger(":AccountApp:Navbar");

const NavbarPresenter = (props: {
  match: Object,
  location: Object,
}) => {
  const {
    location,
  } = props;

  const about_url = `/a`;
  const settings_url = `/a/settings`;
  const teams_url = `/a/teams`;
  const privacy_url = `/a/privacy`;
  const identify_url = `/a/identify`;
  const payment_methods_url = `/a/payment-methods`;

  const selectActiveKey = () => {
    logger.debug("[Navbar] pathname:", location.pathname);
    switch (location.pathname) {
      case about_url:
        return about_url;
      case settings_url:
        return settings_url;
      case privacy_url:
        return privacy_url;
      case identify_url:
        return identify_url;
      case teams_url:
        return teams_url;
      case payment_methods_url:
        return payment_methods_url;
      default:
        return payment_methods_url;
    }
  }

  return (
    <Nav
      variant="tabs"
      activeKey={selectActiveKey()}
    >
      <Nav.Item>
        <Nav.Link
          as={Link}
          href={about_url}
          to={about_url}
        ><PersonIcon/> Account</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          href={settings_url}
          to={settings_url}
        ><ToolsIcon/> Settings</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          href={teams_url}
          to={teams_url}
        ><PeopleIcon/> Teams</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          href={identify_url}
          to={identify_url}
        ><VerifiedIcon/> Verification</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          href={privacy_url}
          to={privacy_url}
        ><LockIcon/> Encryption</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          href={payment_methods_url}
          to={payment_methods_url}
        ><LockIcon/> Payment Methods</Nav.Link>
      </Nav.Item>
    </Nav>
  )
}

export const Navbar = withRouter(NavbarPresenter);
