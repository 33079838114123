// @flow
import {
  createElement as el,
  useState,
} from "react";

import { withMartletOrganizationApi } from "../../../Api/MartletOrganization";
import { type Organization } from "./../../../Entity/Types.js";

import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "./../../../Util";

import  View from "./view.js";




const Controller= withMartletOrganizationApi((props: {
  organization: Organization,
  martletOrganizationApi: Function,
  onDismiss: Function,
}) => {
  const {
    martletOrganizationApi,
    onDismiss,
    organization,
  } = props;

  const [ display, setDisplay ] = useState(INPUT);
  const [ sicCode, setSicCode ] = useState();

  const handleDone = e => {
    console.log("Done", e);
    onDismiss();
    setDisplay(INPUT);
  }

  const handleChange = e => {
    console.log("handleChange", e);
    setSicCode(e.currentTarget.value);
  }

  const handleSubmit = e => {
    console.log("Submit", e);
    console.log("Organization", organization);
    setDisplay(WAITING);
    martletOrganizationApi.updateOrganization({
      organizationId: organization.id,
      sicCode: sicCode,
    }).then(
      organization => {
        setDisplay(SUCCESS);
      }
    ).catch(
      error => {
        setDisplay(FAILURE);
      }
    );
  }

  return el (
    View,
    {
      display,
      onSubmit: handleSubmit,
      onDoneSuccess: handleDone,
      onDoneFailure: handleDone,
      onCancel: handleDone,
      onChange: handleChange,
      data: sicCode,
    }
  );
});


export { Controller } ;

