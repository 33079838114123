// @flow

import React, {
} from "react"
import {
  Link,
} from 'react-router-dom'
import ReactTable from "react-table-6"
import "react-table-6/react-table.css"
import * as S from "sanctuary";

import { BmbixIcon } from "./../Widgets";

import { type DecoratedAddress } from "./../Organization/types.js";


const AddressesUserTable = (props: {
  decoratedAddresses: Array<DecoratedAddress>,
  organizationId: string,
}) => {
  const {
    organizationId,
    decoratedAddresses,
  } = props;
  console.log("decoreatedAddresses", decoratedAddresses);


    const columns = [
        {
          Header: "Name",
          accessor: "displayName"
        },
        {
          Header: "Type",
          accessor: "purpose",
        },
        {
          Header: "Messages",
          accessor: "displayName",
          Cell:  ({row}) => {
            return (
              <Link to={`/u/organizations/${organizationId}/addresses/${row._original.id}`}>{row.displayName}</Link>
            )
          }
        },
        {
          Header: "Integration",
          accessor: "platform.name",
          Cell:  ({row, value}) => {
            const iconMap = {
              freeagent: "freeagent-icon",
              freshbooks: "freshbooks-icon",
              kashflow: "kashflow-icon",
              quickbooks: "quickbooks-icon",
              qbo: "quickbooks-icon",
              sage: "sage-icon",
              xero: "xero-icon",
            };
            const maybeType = S.get (val => true) ("type") (row._original.platform);
            const maybePlatformIcon = S.chain (
              t => S.get (val => true) (t) (iconMap)
              ) (maybeType)
            const iconRef = S.fromMaybe ("unknown-platform-icon") (maybePlatformIcon)

            return (
              <Link to={`/u/organizations/${organizationId}/addresses/${row._original.id}/integration`}><BmbixIcon name={iconRef}></BmbixIcon> {value}</Link>
            )
          }
        }
    ]

    return (
        <div>
        <h2>Addresses</h2>
        <ReactTable
          className="-striped -highlight"
          data={decoratedAddresses}
          columns={columns}
          defaultPageSize={10}
        />
        </div>
    )
}

export default AddressesUserTable
