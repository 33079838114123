// @flow
import React from "react";

const View = (props: {
  children?: Array<Function>,
}) => {

  const {
    children
  } = props;

  return (
    <>
    {children}
    </>
  )
}

export {
  View,
}
