// @flow
import { createElement as el } from "react";

import { type MessageType } from "./../../../../Entity/Types.js";

import { View } from "./view.js";

const Controller = (props: {
  address: string,
  messageTypes: Array<MessageType>,
}) => {
  const {
    address,
    messageTypes,
  } = props;

  return el(
    View,
    {
      address,
      messageTypes,
    },
  )
}
export { Controller }
