// @flow

import type {
  KashflowConnection,
} from './Types'


const createKashflowConnection = (args: {
  id: string,
  platformId: string,
  username: string,
  password: string,
}): KashflowConnection => {
  const {
    id,
    platformId,
    username,
    password,
  } = args;
  return Object.freeze({
    id,
    platformId,
    username,
    password,
  });
}

export const InstanceServiceFactory = () => ({
  createKashflowConnection,
})

export const instanceService = InstanceServiceFactory();
