// @flow
import React from "react";
import * as QRCode from 'easyqrcodejs';
import logo from "../../bmbix_dot_colour.png"

// ====== Title
/*
title: 'QR Title', // content
titleFont: "normal normal bold 18px Arial", //font. default is "bold 16px Arial"
titleColor: "#004284", // color. default is "#000"
titleBackgroundColor: "#fff", // background color. default is "#fff"
titleHeight: 70, // height, including subTitle. default is 0
titleTop: 25, // draws y coordinates. default is 30
*/



class BmbixQR extends React.Component<{uri: string}> {

    constructor(props) {
        super(props);
        this.qrcode = React.createRef();
    }

    componentDidMount() {
        var title = !!this.props.title ? this.props.title : null;
        // Options
        var options = {
            title,
            titleTop: 30,
            titleHeight: 50,
            titleFont: "bold 24px Arial",
            text: this.props.uri,
            logo: logo,
            PI: '#218cf2',
        }
        // Create new QRCode Object
        new QRCode( this.qrcode.current, options);
    }
    render() {
        return (
        <div className = "App">
            <div ref={this.qrcode}></div>
        </div>
        );
    }
}

export {
  BmbixQR,
}
