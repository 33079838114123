// @flow

import * as S from "sanctuary";
import {
  BillingAddress as BillingAddressGW,
} from "@bmbix/bmb_martlet_organization_client";
import {
  instanceService,
} from "./../../Entity/InstanceService";

const createBillingAddressFromGW = (b: BillingAddressGW): BillingAddress => {
  return instanceService.createBillingAddress({
    id: S.prop ("id") (b),
    organizationId: S.prop ("organization_id") (b),
    createdAt: S.prop ("created_at") (b),
    addressId: S.prop ("address_id") (b),
  });
}
export {
  createBillingAddressFromGW,
}
