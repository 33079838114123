// @flow
import {
  createElement as el,
} from "react";
import {
  connect,
} from "react-redux";
import log from "loglevel";

import {
  type Notification,
} from "../Entity/Types.js";
import {
  View,
} from "./View.js";

const logger = log.getLogger("Notifications");

const Controller_ = (props: {
  notifications: Array<Notification>,
}) => {
  const {
    notifications,
  } = props;

  logger.error("notifications:", notifications);

  return el(
    View,
    {
      notifications,
    },
  )
}


const connector = connect(
  state => {
    return {
      notifications: state.notifs,
    }
  }
)

const Controller = connector(Controller_)


export {
  Controller,
}
