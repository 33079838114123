// @flow

import Future, { chain } from "fluture";

import {
  ApiClient,
  OrganizationsApi,
  OrganizationProxy as OrganizationProxyGW,
} from "@bmbix/bmb_martlet_organization_client";

// import { store as reduxStore, } from "../../State/Store.js";
import { createOrganizationProxyFromGW } from "./adapt.js";
import type {
  OrganizationProxy,
} from "./../../Entity/Types.js";
// import { addOauth2Client } from "./../store.js";

const create = (token: string) => (organizationId: string) => (name:
  string): Future<OrganizationProxy> => {

  const apiInstance = new OrganizationsApi();
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;

  const body = OrganizationProxyGW.constructFromObject({ name });

  return Future((reject, resolve) => {

    apiInstance.createProxy(body, organizationId, (error, data, response) => {
      if (error) {
        console.log("error", error);
        reject(error);
      } else {
        const gw = data.organization_proxy;
        const organizationProxy = createOrganizationProxyFromGW(gw);
        resolve(organizationProxy);
      }
    });

    return console.log;
  });
}


/*
const storeOauth2Client = (o: Oauth2Client) => {
  return Future((reject, resolve) => {
    try {
      reduxStore.dispatch(addOauth2Client(o))
      resolve(o)
    } catch (error) {
      reject(error)
    }
    return console.log;
  });
}


const createAndStore =
  (token:string) => (orgId: string) => (resource: string) =>
    chain (storeOauth2Client) (create (token) (orgId) (resource))
    */


export {
  create,
  // createAndStore,
}
