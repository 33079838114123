// @flow
import { createElement as el } from "react";

import { View } from "./view";

const Controller = (props: {
  data: string,
  subjectName: string,
  certificateId: string,
}) => {
  const {
    data,
    subjectName,
    certificateId,
  } = props;

  const filename = `${subjectName}-${certificateId}.pem`;
  const type = "application/x-pem-file";

  return el(
    View,
    {
      filename,
      data,
      type,
    }
  )
}

export {
  Controller,
}
