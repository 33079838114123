// @flow
import * as R from "ramda";


import { instanceService } from "../../Entity/InstanceService";
import {
  type ExtractionCandidateReportHeader,
  type ExtractionCandidateReportEntry,
  type ExtractionCandidateReport,
} from "../../Entity/Types.js";
import {
  ApiClient,
  ExtractionCandidatesApi,
  ExtractionCandidateReportHeader as ExtractionCandidateReportHeaderGW,
  ExtractionCandidateReportEntry as ExtractionCandidateReportEntryGW,
  ExtractionCandidateReport as ExtractionCandidateReportGW,
  ExtractionCandidateReportRequest as ExtractionCandidateReportRequestGW,
} from "@bmbix/bmb_isimud_client";


const createExtractionCandidateReportHeaderFromGW = (
  headerGW: ExtractionCandidateReportHeaderGW,
):ExtractionCandidateReportHeader => {
  const expected = instanceService.createExtractionCandidateReportHeader({
        extractionCandidateReportId: headerGW.extraction_candidate_report_id,
        requestIssuedAt: headerGW.request_issued_at,
        reportCreatedAt: headerGW.report_created_at,
        contentMediaType: headerGW.content_media_type,
        addressId: headerGW.address_id,
  });
  return expected;
}

const createExtractionCandidateReportEntryFromGW = (
  entryGW: ExtractionCandidateReportEntryGW,
):ExtractionCandidateReportEntry => {
   const expected = instanceService.createExtractionCandidateReportEntry({
        extractionCandidateEntryId: entryGW.extraction_candidate_entry_id,
        extractionCandidateReportId: entryGW.extraction_candidate_report_id,
        reference: entryGW.reference,
        summary: entryGW.summary,
   });
  return expected;
}

const createExtractionCandidateReportFromGW = (
  reportGW: ExtractionCandidateReportGW,
): ExtractionCandidateReport => {
    const expected = instanceService.createExtractionCandidateReport({
      header: createExtractionCandidateReportHeaderFromGW(reportGW.header),
      entries: R.map(createExtractionCandidateReportEntryFromGW, reportGW.entries),
    });
    return expected;
}

const getExtractionCandidateReport = (args:{
  token: string,
  organizationId: string,
  addressId: string,
  extractionCandidateReportId: string,
}): Promise<ExtractionCandidateReport> => {
  // throw new Error("what is going on");
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = args.token;
  let apiInstance = new ExtractionCandidatesApi();
  return new Promise((resolve, reject) => {
    apiInstance.select(
      args.organizationId,
      args.addressId,
      args.extractionCandidateReportId,
      (error, data, response,
    ) => {
      if (error) {
        resolve(error);
      } else {
        const reportGW= data.report;
        const report = createExtractionCandidateReportFromGW(reportGW);
        resolve(report);
      }
    });
  });
}


/** returns the id of the report */
const requestExtractionCandidateReport = (args:{
  token: string,
  organizationId: string,
  addressId: string,
  fromDate: string,
  toDate?: string,
  contentMediaType: string,
}): Promise<string> => {
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = args.token;
  let apiInstance = new ExtractionCandidatesApi();
  return new Promise((resolve, reject) => {
    const body = new ExtractionCandidateReportRequestGW();
    body.from_date = args.fromDate;
    body.content_media_type = args.contentMediaType;
    if (args.toDate){
      body.to_date = args.toDate;
    }
    apiInstance.generate(
      body,
      args.organizationId,
      args.addressId,
      (error, data, response) => {
      if (error) {
        resolve(error);
      } else {
        const request = data.extraction_candidate_report_request;
        const extractionCandidateReportId = request.extraction_candidate_report_id;
        resolve(extractionCandidateReportId);
      }
    });
  });
}


export {
  createExtractionCandidateReportHeaderFromGW,
  createExtractionCandidateReportEntryFromGW,
  createExtractionCandidateReportFromGW,
  getExtractionCandidateReport,
  requestExtractionCandidateReport,
}
