// @flow

import React, {
  useState,
  useEffect,
} from "react";
import * as R from "ramda";
import {
  withRouter,
} from "react-router-dom";
import {
  connect,
} from "react-redux";
import Button from "react-bootstrap/Button";
import {
  SyncIcon,
} from "@primer/octicons-react";

import {
  type BmbixAddress,
} from "../Entity/Types.js";
import {
  withIsimudApi,
} from "../Api/Isimud";
import {
  InsertionReceiptsTable,
} from "./InsertionReceiptsTable.js";


const InsertionsView_ = (props: {
  isimudApi: Object,
  address: BmbixAddress,
  match: Object,
}) => {

  const {
    address,
    isimudApi,
  } = props;

  const [insertionReceipts, setInsertionReceipts] = useState([]);

  const refresh = () => {
    isimudApi.queryInsertionReceipts({
      organizationId: address.organizationId,
      addressId: address.id,
      fromReceivedTimestamp: "2019-01-01",
    }).then(
      insertionReceipts => {
        setInsertionReceipts(insertionReceipts)
      }
    );
  }

  const insertionReceiptsWidget = () => {
    if(insertionReceipts) {
      return (
        <>
        <h2>Insertion Receipts</h2>
        <Button onClick={refresh}><SyncIcon /> Refresh</Button>
        <InsertionReceiptsTable
          items={insertionReceipts}
        />
        </>
      )
    } else {
      return null;
    }
  }

  useEffect(
    refresh,
    [],
  );

  return (
    <>
    {insertionReceiptsWidget()}
    </>
  )
}

const connector = connect(
  (state, ownProps) => {
    const {
      match,
    } = ownProps;
    const addressId = match.params.addressId
    return {
      address: R.find(R.propEq("id", addressId))(state.addresses),
    }
  }
);

const InsertionsView = withRouter(connector(withIsimudApi(InsertionsView_)));

export {
  InsertionsView,
}



