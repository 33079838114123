import React from "react";
import {Link} from "react-router-dom";
import log from "loglevel";
import {
  ShieldCheckIcon,
  AlertIcon,
} from "@primer/octicons-react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const logger = log.getLogger("MessageNotes");

const View = (props: {
  verified: Boolean,
  name: String,
  iconUrl: String,
  organizationId: String,
}) => {
  logger.debug("props", props);
  const {
    verified,
    name,
    organizationId,
    iconUrl,
  } = props;

  logger.debug("verified:", verified);

  const icon = verified ? <ShieldCheckIcon fill="#32cd32" /> : <AlertIcon
  fill="DarkOrange" />;
  const verifiedTip = verified ? <Tooltip>Identity verified</Tooltip> :
    <Tooltip>Caution: Identity not verified</Tooltip>
  const organizationTip = <Tooltip>{organizationId}</Tooltip>

  return (
    <>
    <OverlayTrigger
      overlay={verifiedTip}
      placement="bottom"
      delay={{show: 250, hide: 400}}
    ><span>{icon}</span></OverlayTrigger> <OverlayTrigger
      overlay={organizationTip}
      placement="bottom"
      delay={{show: 250, hide: 400}}
    ><Link to={`/profiles/organization/${organizationId}`}>{name}</Link></OverlayTrigger>
    </>
  );
}

export {
  View,
}
