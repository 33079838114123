// @flow
import {
  createElement as el,
} from "react";
import {
  withRouter,
} from "react-router-dom";

import {
  View,
} from "./View.js";


const Controller_ = (props: {
  match: Object,
}) => {
  const {
    match,
  } = props;

  const here = match.path;

  return el(
    View,
    {here},
  );
}

const Controller = withRouter(Controller_);

export {
  Controller,
}
