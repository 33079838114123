// @flow
import React from "react";
import Button from "react-bootstrap/Button";

import {
  SyncIcon,
} from "@primer/octicons-react"

import {
  type DecoratedRejectedMessage as RejectedMessage,
} from "./../../entity.js";
import { Controller as RejectedMessageTable } from "./../../Widgets/RejectedsTable/controller.js";
import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "./../../../../Util";
import { CenteredSpinner } from "./../../../../Widgets/Toast.js";
import {
  Spinner,
} from "./../../../../Widgets/Toast";

const View = (props: {
  organizationId: string,
  addressId: string,
  display: string,
  rejectedMessages: Array<RejectedMessage>,
  handleNext: Function,
  handleReset: Function,
  displayFetching: string,
  lastRecordSet: boolean,
  fetchedRecordCount: number,
  totalRecordCount: number,
}) => {
  const {
    organizationId,
    addressId,
    display,
    rejectedMessages,
    onNext,
    onReset,
    displayFetching,
    lastRecordSet,
    fetchedRecordCount,
    totalRecordCount,
  } = props;

  const input = display === INPUT ? (
    <></>
  ): null;

  const success = display === SUCCESS && !!rejectedMessages ? (
    <RejectedMessageTable
      organizationId={organizationId}
      addressId={addressId}
      rejectedMessages={rejectedMessages}
    />
  ): null;

  const waiting = display === WAITING ? (
    <CenteredSpinner />
  ): null;

  const failure = display === FAILURE ? (
    <p>Oops something went wrong</p>
  ): null;

  return (
    <>
    <h2>Rejected for delivery</h2>
    <Button className="mr-2" onClick={onReset}><SyncIcon /> Refresh</Button>
    <>
    { displayFetching === "FETCHING" && <Spinner height="25"/> }
    { displayFetching !== "FETCHING" &&
    <Button disabled={lastRecordSet} onClick={onNext}>Load more</Button>
    }
    </>

    <div>Showing {fetchedRecordCount} of {totalRecordCount} records found.</div>


    {input}
    {waiting}
    {success}
    {failure}
    <div><br/></div>
    </>
  );
}

export { View }
