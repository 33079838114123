// @flow
import { useSelector } from "react-redux";
import {
  type Account,
} from "../Entity/Types.js";


const ADD_ACCOUNT = "ADD_ACCOUNT";

const addAccount = (account: Account) => {
  return {
    type: ADD_ACCOUNT,
    account: account,
  }
}

const reducer = (state:?Account=null, action:Object) => {
   switch ( action.type ) {
     case ADD_ACCOUNT:
       return state=action.account;
     default:
       return state;
   }
}

const useAccount = () => {
  return useSelector(store => store.account)
}

export {
  addAccount,
  reducer as account,
  useAccount,
}


