// @flow
import React, {useState} from "react";
import {
  connect
} from "react-redux";
import * as R from "ramda";
import Button from "react-bootstrap/Button";
import {
  ArrowSwitchIcon,
} from "@primer/octicons-react";

import {
  withMartletOrganizationApi,
} from "../Api/MartletOrganization";
import {
  updateAddress as stateUpdateAddress,
} from "../State";
import { OpenModal } from "./OpenModal";


const Widget = (props:{
  label: string,
  organizationId: string,
  addressIds: Array<string>,
  stateUpdateAddress: Function,
  martletOrganizationApi: Object,
}) => {

  const [modal, setModal] = useState();

  const hitTheAPI = () => {
    setModal(
      <OpenModal display="waiting"/>
    );

    Promise.allSettled(
      R.map(addId => {
        const args = {
          addressId: addId,
          isClosed: false,
        };
        return props.martletOrganizationApi.updateAddress(args);
      },
      props.addressIds,
      )
    ).then(
      outcomes => {
        const successes = R.filter(
          outcome => outcome.status === "fulfilled",
          outcomes,
        );
        R.map(
          success => {
            props.stateUpdateAddress(success.value)
          },
          successes,
        );
        setModal(
          <OpenModal display="success" cancel={cancel}/>
        )
      }
    );
  }

  const cancel = (e) => {
    setModal(null);
  }

  const handleClick = (e) => {
    setModal(
      <OpenModal
        display={"input"}
        callBack={hitTheAPI}
        cancel={cancel}
      />
    );
  }

  return (
    <>
    <Button variant="primary" onClick={handleClick}><ArrowSwitchIcon /> {props.label}</Button>
    {modal}
    </>
  );
}


const mapStateToProps = (state) => {
  return {
    token: state.token,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    stateUpdateAddress: (address) => dispatch(stateUpdateAddress(address)),
  }
}

const OpenButton = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withMartletOrganizationApi(Widget));

export {
  OpenButton,
  Widget,
}

