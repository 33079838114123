// @flow
import * as R from "ramda";
import * as S from "sanctuary";
import * as $ from "sanctuary-def";
import { type PlatformAssignment } from "../Entity/Types";
import { useSelector } from "react-redux";
import { usePlatform } from "./Platforms.js";

const SET_PLATFORMASSIGNMENTS = "SET_PLATFORMASSIGNMENTS";
const ADD_PLATFORMASSIGNMENT = "ADD_PLATFORMASSIGNMENT";
const DEL_PLATFORMASSIGNMENT = "DEL_PLATFORMASSIGNMENT";


const getPlatformAssignment = (
  platformAssignmentId:string,
  platformAssignments:Array<PlatformAssignment>,
) => {
  return R.find(R.propEq("platformAssignmentId", platformAssignmentId))(platformAssignments);
}


const setPlatformAssignments = (platformAssignments: Array<PlatformAssignment>) => {
  return {
    type: SET_PLATFORMASSIGNMENTS,
    platformAssignments: platformAssignments
  }
}

const addPlatformAssignment = (platformAssignment: PlatformAssignment) => {
  return {
    type: ADD_PLATFORMASSIGNMENT,
    platformAssignment: platformAssignment
  }
}

const deletePlatformAssignment = (platformAssignmentId: string) => {
  return {
    type: DEL_PLATFORMASSIGNMENT,
    platformAssignmentId: platformAssignmentId
  }
}

const reducer = (state: Array<PlatformAssignment> = [], action: Object) => {
  switch (action.type) {
    case ADD_PLATFORMASSIGNMENT:
      return R.uniq(R.append(action.platformAssignment, state))
    case DEL_PLATFORMASSIGNMENT:
      return R.filter((p) => (p.platformAssignmentId !== action.platformAssignmentId) , state)
    case SET_PLATFORMASSIGNMENTS:
      return action.platformAssignments;
    default:
      return state
  }
}

const usePlatformAssignment = (addressId: string) => {
  return useSelector(store => {
    const addressIdSelector = S.compose (S.equals (S.Just (addressId))) (S.get (S.is ($.String) ) ("addressId"));
    const maybePlatformAssignment = S.find (addressIdSelector) (store.platformAssignments);
    return S.maybeToNullable(maybePlatformAssignment);
  });
}

const usePlatformAssignmentExists = (addressId: string) => {
  return useSelector(store => {
    // return True if Key exists in strMap of Maybes
    // ie no key means no attempt to fetch item.
    // key exists  and value is Nothing -> tried to fetch assignment but none
    // found
    // key exists and value is Just -> fetched key and exists.
    const keys = S.keys (store.platformAssignments);
    const maybeKey = S.find (S.equals (addressId)) (keys);
    const hasKey = S.isJust (maybeKey);
    return hasKey;
  });
}

const usePlatformFromAddress = (addressId: string) => {
  const getPlatform =
    platforms =>
    maybePlatformAssignment =>
  {
    return S.chain (platformAssignment => {
      const platformId = S.prop ("platformId") (platformAssignment);
      const selector = platform => S.equals (platformId) (S.prop ("platformId") (platform))
      return S.find (selector) (platforms)
    }) (maybePlatformAssignment);
  }

  return useSelector(store => {
    const addressIdSelector = S.compose (S.equals (S.Just (addressId))) (S.get (S.is ($.String) ) ("addressId"));
    const maybePlatformAssignment = S.find (addressIdSelector) (store.platformAssignments);

    const maybePlatform = getPlatform (store.platforms) (maybePlatformAssignment);
    return maybePlatform;
  });
}


export {
  reducer as platformAssignments,
  setPlatformAssignments,
  addPlatformAssignment,
  deletePlatformAssignment,
  getPlatformAssignment,
  usePlatformAssignment,
  usePlatformAssignmentExists,
  usePlatformFromAddress,
}
