// @flow

import React from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {
  Spinner,
} from "../../../Widgets/Toast";
import {
  useForm,
} from "react-hook-form";

const View = (props: {
  state: string,
  language: ?string,
  onSubmit: Function,
  onClose: Function,
}) => {

  console.log("props: ", props);

  const {
    state,
    language,
    onClose,
    onSubmit,
  } = props;

  const { register, handleSubmit, formState: { errors } } =
    useForm({defaultValues: {language}});
  const form = <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Group>
    <Form.Label>Language</Form.Label>
          <Form.Control as="select" {...register("language", {required: true})}>
            <option value="fr-FR">fr-FR</option>
            <option value="en-GB">en-GB</option>
            <option value="ru-RU">ru-RU</option>
            <option value="zh-CN">zh-CN</option>
          </Form.Control>
    <Form.Text>Choose your language</Form.Text>
      {errors.language && <span>This field is required</span>}
    </Form.Group>
    <Button type="submit" variant="primary" >Save changes</Button>
      </Form>;
  const spinner = <Spinner height="24"/>;
  const successMessage = <p>All good, language was updated</p>;
  const failureMessage = <p>Oops, something has gone wrong.</p>;


  const body = state === "idle"
    ? form
    : state === "working"
    ? spinner
    : state === "succeeded"
    ? successMessage
    : state === "failed"
    ? failureMessage
    : <p>Unknown state</p>;



  return (
    <Modal show={true}>
    <Modal.Header closeButton onHide={onClose}>Set Language</Modal.Header>
    <Modal.Body>
    {body}
    </Modal.Body>
    <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>Close</Button>
    </Modal.Footer>
    </Modal>
  );

}

export {
  View,
};
