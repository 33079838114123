// @flow
import { createElement as el, useEffect, useState } from "react";
import { fork } from "fluture";
import { useMachine } from "@xstate/react";
import { createMachine, assign } from "xstate";

import {
  useToken,
  useAccessListMutation,
} from "../../../../State";
import { getLists } from "./../../Api";
import View from "./view.js";

import { useCatchExpiredToken } from "./../../../../State/Token.js";

const fetchingMachine = createMachine({
  id: "fetchingMachine",
  initial: "waiting",
  context: {
    error: undefined,
  },
  states: {
    waiting: {
      on: {
        SUCCESS_HAPPENED: "success",
        FAILURE_HAPPENED: "failure",
        FAILURE403_HAPPENED: {
          target: "failure403",
          actions: assign({
            error: (c, e) => e.error
          }),
        },
      }
    },
    success: {
      on: {
        WAITING_HAPPENED: "waiting",
      }
    },
    failure: {
      on: {
        RESET_HAPPENED: "input"
      }
    },
    failure403: {
      on: {
        RESET_HAPPENED: "input",
      }
    },
    input: {
      on: {
        SUBMIT_HAPPENED: "waiting"
      }
    },
  },
});

function Controller (props: {
  owner: string,
}) {
  const {
    owner,
  } = props;

  const [ fetchingState, fetchingSend ] = useMachine(fetchingMachine);
  const token = useToken();
  const [ accessLists, setAccessLists ] = useState([]);
  const accessListMutation = useAccessListMutation();
  const catchExpiredToken = useCatchExpiredToken();

  useEffect(
    function () {
      fetchingSend({type: "WAITING_HAPPENED"});
      const action = getLists(token)(owner);
      fork(
        error => {
          catchExpiredToken(error);
          const errorObject = JSON.parse(error.response.text);
          console.log("errorObject:", errorObject);
          if (error.status === 403) {
            fetchingSend({type: "FAILURE403_HAPPENED", error: errorObject});
          } else {
            fetchingSend({type: "FAILURE_HAPPENED"});
          }
        }
      )(
        accessLists => {
          setAccessLists(accessLists);
          fetchingSend({type: "SUCCESS_HAPPENED"});
        }
      )(action);
    },
    [accessListMutation],
  );

  return el(
    View,
    {
      fetchingState,
      owner,
      accessLists,
    },
  );
}


export { Controller as default };
