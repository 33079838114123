// @flow
import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const AddView = (props: {
  onSubmit: Function,
  onChange: Function,
  onKeyPress: Function,
  content: string,
}) => {
  const {
    content,
    onChange,
    onKeyPress,
    onSubmit,
  } = props;

  return (
    <>
      <Form>
      <Form.Group>
      <Form.Label></Form.Label>
      <Form.Control
        type="text"
        name="content"
        onKeyPress={onKeyPress}
        onChange={onChange}
        placeholder="Enter a note"
        value={content}
      />
      </Form.Group>
      </Form>
      <Button onClick={onSubmit}>Submit</Button>
    </>
  );
}
export {
  AddView,
}
