// @flow
import React from "react";
import Form from "react-bootstrap/Form";




const View = (props: {
  onChange: Function,
}) => {

  const {
    onChange,
  } = props;

  return <><PersonForm onChange={onChange}/></>
}


const PersonForm = (props: {
  bmbixId: string,
  organizationName: string,
  onChange: Function,
  registrar: string,
  registerEntry: string,
}) => {
  const {
    bmbixId,
    organizationName,
    onChange,
    registrar,
    registerEntry,
  } = props;

  /*
    passphrase,
    bmbixid,
    org,
    registrar,
    registerentry,
    out,
    */
  return <Form>

    <Form.Group>
    <Form.Label>Bmbix Id</Form.Label>
    <Form.Control
      onChange={onChange}
      name="bmbixId"
      value={bmbixId}
    />
    <Form.Text>Your Bmbix Id</Form.Text>
    </Form.Group>

    <Form.Group>
    <Form.Label>Registrar</Form.Label>
    <Form.Control
      as="select"
      onChange={onChange}
      name="registrar"
      value={registrar}
    >
      <option value=""></option>
      <option value="france_companies_house">Infogreffe (FR)</option>
      <option value="uk_companies_house">Companies House (UK)</option>
    </Form.Control>
    <Form.Text>Supply a registrar</Form.Text>
    </Form.Group>

    <Form.Group>
    <Form.Label>Register Entry</Form.Label>
    <Form.Control
      onChange={onChange}
      name="registerEntry"
      value={registerEntry}
    />
    <Form.Text>Supply a register entry</Form.Text>
    </Form.Group>

    <Form.Group>
    <Form.Label>Organization Name</Form.Label>
    <Form.Control
      onChange={onChange}
      name="organizationName"
      value={organizationName}
    />
    <Form.Text>Give the organization name as it appears on the register</Form.Text>
    </Form.Group>

  </Form>
}


export {
  View,
}
