// @flow
import React, {
  useState,
} from "react";
import Form from "react-bootstrap/Form";


const OpenForm = (props:{}) => {


  return (
    <Form>
    <Form.Group>
    <Form.Label>Open Organizations</Form.Label>
    <Form.Text>Are you sure?</Form.Text>
    </Form.Group>
    </Form>
  );
}


export default OpenForm;
export {
  OpenForm,
}
