// @flow

import * as R from "ramda";
import * as S from "sanctuary";

import {
  type BmbixRole,
  type BRI,
  type Permission,
} from "../Entity/Types.js";
import {
  instanceService,
} from "../Entity/InstanceService.js";

  const createBmbixRoleFromBRI = (bri: string): BmbixRole => {
    const pattern = /bmbix:\/\/(.+)\/(.+)\/(.+)/
    const matches = bri.match(pattern);
    if (!!matches ) {

      const [_, domain, resourceType, roleName, ...rest ] = matches;
      if ( domain === "role" ) {
        return instanceService.createBmbixRole({resourceType, roleName});
      }
    }
    throw new Error("bri string was not convertible to role");
  }

// Permission -> Maybe string
const extractResource = permission => {
  return S.get (x => true)("resource")(permission);
}

// Maybe string -> Maybe BRI
const safeCreateBRI = resource => {
  return resource.map(instanceService.createBRIFromString);
}

// string -> Maybe BRI -> Boolean
const filterResourceType = R.curry((resourceType, bri) => {
  return bri.map(x => x.resourceType).equals(S.Maybe.Just(resourceType));
})

// Maybe BRI -> Maybe string
const extractId = (bri) => {
  return bri.map(x => x.resourceId)
}

const extractResourceIds_ = R.curry((resourceType, permissions) => {
  return R.pipe(
    R.map(R.pipe(extractResource, safeCreateBRI)),
    R.filter(filterResourceType(resourceType)),
    R.map(R.pipe(extractId, x => x.option())),
  )(permissions);
});



const extractResourceIds = (type: string) => (permissions: Array<Permission>) => {
  const filterEntity = type => p => deserializeBRI(p.resource).resourceType === type;
  const extract = p => deserializeBRI(p.resource).resourceId;
  return S.pipe ([
    S.filter (filterEntity (type)),
    S.map (extract)
  ]) (permissions)
}


const serializeBRI = (bri: BRI): string => {
  return !!bri.resourceId ?
    `${bri.protocol}://${bri.resourceType}/${bri.resourceId}`:
    `${bri.protocol}://${bri.resourceType}`;
}

const generateURIs= (b: string): Object => {
  const bri = deserializeBRI(b);
  const organizationId = "";
  let validateURI, manageURI, claimURI, processURI, profileURI = "";
  if (!!bri) {
  switch (bri.resourceType) {
    case "address":
       claimURI = `/c?addressId=${bri.resourceId}`;
       processURI = `/u/organizations/${organizationId}/addresses/${bri.resourceId}`;
       manageURI = `/m/organizations/${organizationId}/addresses/${bri.resourceId}`;
       profileURI = `/profiles/address/${bri.resourceId}`;
      return Object.freeze({
        claimURI,
        processURI,
        manageURI,
        profileURI,
      });
    case "organization":
       manageURI = `/m/organizations/${bri.resourceId}`;
       profileURI = `/profiles/organization/${bri.resourceId}`;
       validateURI = `/v/organizations/${bri.resourceId}/certificates`;
      return Object.freeze({
        manageURI,
        profileURI,
        validateURI,
      });
    case "user":
       profileURI = `/profiles/user/${bri.resourceId}`;
      return Object.freeze({
        profileURI,
      });
    default:
      return {}
  }
  }
}

const extractAddressId =
  (resourceBRI) =>
{
  const addressBRIRegex = /bmbix:\/\/address\/(.+)/
  const maybeResult = S.match (addressBRIRegex) (resourceBRI);
  const maybeGroupsArray = S.chain (S.get (x => true) ("groups")) (maybeResult);
  const maybeMaybeFirst = S.chain (S.head) (maybeGroupsArray);
  const maybeValue = S.join(maybeMaybeFirst);
  const value = S.fromMaybe ("") (maybeValue);
  return maybeValue;
}

const deserializeBRI = (bri: string): ?BRI => {
  return instanceService.createBRIFromString(bri);
}

export {
  extractResourceIds,
  createBmbixRoleFromBRI,
  serializeBRI,
  deserializeBRI,
  generateURIs,
  extractAddressId,
}
