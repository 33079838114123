// @flow
import {
  createElement  as el,
} from "react";
import {
  View,
} from "./view";



const Controller = (props: {}) => {

  const hammockGLAddress = window.localStorage.getItem("HAMMOCK_GL_ADDRESS");

  return el(View,
    {
      hammockGLAddress,
    });

}


export { Controller }
