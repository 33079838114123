// @flow
import { type
  Oauth2Client,
  OrganizationProxy,
} from "./../../Entity/Types.js";
import { instanceService } from "./../../Entity/InstanceService.js";
import {
  Oauth2Client as Oauth2ClientGW,
  OrganizationProxy as OrganizationProxyGW,
} from "@bmbix/bmb_martlet_organization_client";

const createOauth2ClientFromGW = (gw: Oauth2ClientGW): Oauth2Client => {
  const updatedAt = gw.updated_at;
  const oauth2ClientId = gw.oauth2_client_id;
  const resource = gw.resource;
  const nameFromUser = gw.name_from_user;
  const clientId = gw.client_id;
  const clientName = gw.client_name;
  const clientSecret = gw.client_secret;
  return instanceService.createOauth2Client({
    updatedAt,
    oauth2ClientId,
    resource,
    nameFromUser,
    clientId,
    clientName,
    clientSecret,
  })
}

const createOrganizationProxyFromGW = (gw: OrganizationProxyGW): OrganizationProxy => {
  const systemId = gw.system_id;
  const updatedAt = gw.updated_at;
  const id = gw.id;
  const organizationId = gw.organization_id;
  const name = gw.name;
  return instanceService.createOrganizationProxy({
    systemId,
    updatedAt,
    id,
    organizationId,
    name,
  })
}

export {
  createOauth2ClientFromGW,
  createOrganizationProxyFromGW,
}
