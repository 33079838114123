// @flow
import { createElement as el } from "react";
import * as S from "sanctuary";

import {
  assign,
  createMachine,
} from "xstate";
import {
  useMachine,
} from "@xstate/react";
import { useToken } from "./../../../../State";
import {
  type Permission,
} from "./../../../../Entity/Types";

import {
  Button_,
  Modal_,
} from "./view.js";
import { createPermissionForAddress } from "./../../../../Api/MartletOrganization/Address.js";

const viewSpecification = {
  id: "buttonOrModal",
  initial: "button",
  states: {
    button: {
      on: {
        SHOW_MODAL: "modal",
      }
    },
    modal: {
      on: {
        HIDE_MODAL: "button",
      }
    },
  }
}

const viewStateMachine = createMachine(viewSpecification);

const api =
  (token: string) =>
  (organizationId: string) =>
  (addressId: string) =>
  (actor: string) =>
  (power: string): Promise<Permission> =>
  createPermissionForAddress({
    token,
    addressId,
    actor,
    power,
  });

const apiSpecification = {
  id: "MartletOrganizationApi",
  initial: "idle",
  context: {},
  states: {
    idle: {
      on: {
        START: "working",
      }
    },
    working: {
      invoke: {
        id: "createPermissionForAddress",
        src: (c, e) =>
        api(e.token)(e.organizationId)(e.addressId)(e.actor)(e.power),
        onDone: {
          target: "succeeded",
        },
        onError: {
          target: "failed",
        },
      },
    },
    succeeded: {
      on: {
        RESET: "idle",
      },
    },
    failed: {
      on: {
        RESET: "idle",
      },
    },
  },
};

const apiStateMachine = createMachine(apiSpecification);

const makeActor =
  (data: Function) => {
    console.log("data: ", data);
    return `bmbix://${data.resourceType}/${data.resourceId}`;
  }

const makePower =
  (data: Function) => data.role



const AddModal =
  (props: {
    organizationId: string,
    addressId: string,
  }) =>
{
  const { organizationId, addressId } = props;
  const token = useToken();
  const [ apiState, apiSend ] = useMachine(apiStateMachine);
  const [ viewState, viewSend ] = useMachine(viewStateMachine);

  return viewState.value === "button"
  ? el (
    Button_,
    {
      onClick: () => {viewSend({type: "SHOW_MODAL" })},
    },
  )
  : viewState.value === "modal"
  ? el (
    Modal_,
    {
      onHide: () => viewSend({type: "HIDE_MODAL"}),
      onSubmit: (data) => apiSend({
        type: "START",
        token,
        organizationId,
        addressId,
        actor: makeActor (data),
        power: makePower (data),
      }),
      apiState,
    },
  )
  : el (
    "p",
    {},
    ["Unknown state"]
  )
  ;
}


export {
  AddModal,
}
