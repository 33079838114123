// @flow
import React from "react";
import Button from "react-bootstrap/Button";
import { Controller as Modal } from "./modal-controller.js";

const View = ({
  modeFSMState,
  onStart,
  onCancel,
  onDone,
}: {
  modeFSMState: Function,
  onStart: Function,
  onCancel: Function,
  onDone: Function,
}) => {
  return modeFSMState.value === "button"
  ? <Button onClick={onStart}>Delete</Button>
  : <Modal
      onCancel={onCancel}
      onDone={onDone}
      paymentMethod={modeFSMState.context.paymentMethod}
    />
}

export {
  View,
}
