// @flow
import { createElement as el, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMachine } from "@xstate/react";
import { createMachine, assign } from "xstate";
import { getPaymentMethod } from "./../../../Api/MartletOrganization/Users.js";
import { useAccount } from "./../../../State/Account.js";
import { useToken } from "./../../../State/Token.js";
import { View } from "./view.js";

const fetchingMachine = createMachine({
  id: "fetchingMachine",
  context: {
    token: undefined,
    userId: undefined,
    paymentMethodId: undefined,
    errors: undefined,
    paymentMethod: undefined,
  },
  initial: "unconfigured",
  states: {
    unconfigured: {
      on: {
        CONFIGURE: {
          target: "ready",
          actions: assign({
            userId: (c, e, m) => e.userId,
            token: (c, e, m) => e.token,
            paymentMethodId: (c, e, m) => e.paymentMethodId,
          }),
        }
      },
    },
    ready: {
      on: {
        SUBMIT: "working",
      }
    },
    working: {
      invoke: {
        src: (context, event) => getPaymentMethod({
          token: context.token,
          userId: context.userId,
          paymentMethodId: context.paymentMethodId,
        }),
        onDone: {
          target: "success",
          actions: assign({
            paymentMethod: (c, e) => e.data,
          })
        },
        onError: {
          target: "failure",
          actions: assign({
            errors: (c, e) => e.data,
          })
        }
      },
    },
    success: {
      on: {
        RESET: "ready",
        SUBMIT: "working",
      }
    },
    failure: {
      on: {
        RESET: "ready"
      }
    },
  },
});

const Controller = () => {
  const { paymentMethodId } = useParams();
  const [ state, send ] = useMachine(fetchingMachine);
  const account = useAccount();
  const userId = account.id;
  const token = useToken();

  const onCreated = pml => {
    console.log("Fujiyama: ", pml);
    console.log("state.value: ", state.value);
    send({type: "SUBMIT"});
  }


  useEffect(
    () => {
      send({type: "CONFIGURE", token, paymentMethodId, userId})
      send({type: "SUBMIT"});
    },
    [send, token, paymentMethodId, userId],
  );

  return el(
    View,
    {
      state,
      onCreated,
    },
  );
}

export {
  Controller,
}
