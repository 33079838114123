// @flow
import sanctuary from "sanctuary";
import { env as flutureEnv } from "fluture-sanctuary-types";
import {
  fork,
} from "fluture";

import {
  getAddressProfilesWithFuture,
} from "./../Api/MartletOrganization/Profile.js";
import { mergeAddressProfiles } from "./../State/AddressProfiles.js";

const S = sanctuary.create ({
  checkTypes: true, env:
  sanctuary.env.concat(flutureEnv)
});



function difference(setA, setB) {
  const _difference = new Set(setA);
  for (const elem of setB) {
    _difference.delete(elem);
  }
  return _difference;
}



const findCacheMisses =
  (cachedProfiles: {[string]: AddressProfile}) =>
  (addressIds: Array<string>) =>
{
  const needed = new Set(addressIds);
  const available = new Set(S.keys (cachedProfiles));
  const addressIdsNotCached = difference(needed, available);
  return Array.from(addressIdsNotCached.keys());
}


const refreshAddressProfilesThunk =
  (token: string) =>
  (addressIds: Array<string>) =>
{

  const thunk =
    (dispatch, getState) =>
  {
    const state = getState();

    const missingAddressIds =
      findCacheMisses(state.addressProfiles)(addressIds);

    const eitherAddressIds = S.tagBy (S.compose (S.gt (0)) (S.size)) (missingAddressIds);

    const action = getAddressProfilesWithFuture(token)(missingAddressIds)

    const fail = error => console.log("error", error);

    const success =
      (addressProfiles: Array<AddressProfile>) =>
    {
      const addressIds = S.map(S.prop ("addressId")) (addressProfiles);
      const addressPairs = S.zip (addressIds) (addressProfiles);
      const addressMap = S.fromPairs (addressPairs);
      dispatch(mergeAddressProfiles(addressMap));
    }

    const fetch = () => fork (fail)(success)(action)

    S.either (S.I) (fetch) (eitherAddressIds);

  }
  return thunk;

}

export {
  refreshAddressProfilesThunk,
}
