// @flow
import { createElement as el, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fork } from "fluture";
import {
  useAccessListMutation,
  useToken ,
} from "../../../../State";
import {
  WAITING,
  SUCCESS,
  FAILURE,
} from "../../../../Util";

import { getList } from "./../../Api";
import { WaitingView, FailureView, SuccessView } from "./view.js";
import { useCatchExpiredToken } from "./../../../../State/Token.js";

const Controller = (props: {

}) => {

  const [ accessList, setAccessList ] = useState();
  const [ display, setDisplay ] = useState(WAITING);
  const token = useToken();
  const { addressId, list } = useParams();
  const accessListMutation = useAccessListMutation(list);
  const catchExpiredToken = useCatchExpiredToken();

  useEffect(
    function () {
      const action = getList(token)(addressId)(list);
      fork(
        error => {
          setDisplay(FAILURE);
          catchExpiredToken(error);
          console.log("error", error);
        }
      )(
        accessList => {
          setDisplay(SUCCESS);
          console.log("success", accessList);
          setAccessList(accessList);
        }
      )(action);
    },
    [accessListMutation],
  );

  return display === WAITING ?  el(WaitingView, {}):
    display === FAILURE ? el(FailureView, {}) :
    display === SUCCESS && !!accessList ? el(
      SuccessView,
      {
        display,
        owner: addressId,
        name: accessList.name,
        isActive: accessList.isActive,
        list: accessList.list,
        type: accessList.type,
      },
    ): null;
}
export default Controller
