// @flow
import { createElement as el } from "react";
import { useMachine } from "@xstate/react";
import { createMachine, assign } from "xstate";
import { deletePaymentMethodLink } from "./../../../Api/MartletOrganization/Users.js";
import { useAccount } from "./../../../State/Account.js";
import { useToken } from "./../../../State/Token.js";
import { View } from "./modal-view.js";
import type { PaymentMethodLink } from "./../../../Entity/Types.js";




const Controller = ({
  onDone,
  onCancel,
  paymentMethodLink,
}: {
  onDone: Function,
  onCancel: Function,
  paymentMethodLink: PaymentMethodLink,
}) => {
  const [ apiState, apiSend ] = useMachine(() => createMachine({


  id: "apiMachine",
  context: {
    token: undefined,
    userId: undefined,
    paymentMethodLinkId: undefined,
    errors: undefined,
  },
  initial: "unconfigured",
  states: {
    unconfigured: {
      on: {
        CONFIGURE: {
          target: "ready",
          actions: assign({
            userId: (c, e, m) => e.userId,
            token: (c, e, m) => e.token,
            paymentMethodLinkId: (c, e, m) => e.paymentMethodLinkId,
          }),
        }
      },
    },
    ready: {
      on: {
        SUBMIT: "working",
      }
    },
    working: {
      invoke: {
        src: (context, event) => deletePaymentMethodLink({
          token: context.token,
          userId: context.userId,
          paymentMethodLinkId: context.paymentMethodLinkId,
        }),
        onDone: {
          target: "success",
        },
        onError: {
          target: "failure",
          actions: assign({
            errors: (c, e) => e.data,
          })
        }
      },
    },
    success: {
      entry: (c, e) => {
        onDone();
      },
      on: {
        RESET: "ready",
      }
    },
    failure: {
      on: {
        RESET: "ready"
      }
    },
  },





  }));
  const account = useAccount();
  const userId = account.id;
  const token = useToken();

  const handleModalCancel = onCancel

  const handleModalSubmit = e => {
    console.log("Submitted data event: ", e);
    const paymentMethodLinkId = paymentMethodLink.id;
    apiSend({type: "CONFIGURE", userId, token, paymentMethodLinkId});
    apiSend({type: "SUBMIT"});
  }

  return el(
    View,
    {
      apiState,
      onModalCancel: handleModalCancel,
      onModalSubmit: handleModalSubmit,
    },
  )
}



export {
  Controller,
}


