// @flow

import { createElement as el } from "react";
import { connect } from "react-redux";

import {
  type BmbixAddress,
  type Account,
  type Organization,
  type Permission,
} from "../../../Entity/Types.js";

import { View } from "./view.js";
// import { useAddressProfiles } from "./../../../State/AddressProfiles.js";

const mapStateToProps = (state) => {
  return {
    account: state.account,
    addresses: state.addresses,
    organizations: state.orgs,
    permissions: state.permissions,
    addressProfiles: state.addressProfiles,
  }
}
const Controller = connect(mapStateToProps)((props: {
  account: Account,
  addresses: Array<BmbixAddress>,
  organizations: Array<Organization>,
  permissions: Array<Permission>,
  addressProfiles: Array<Function>,
}) => {
  const {
    account,
    addresses,
    organizations,
    permissions,
    addressProfiles,
  } = props;

  return el (
    View,
    {
      account,
      addresses,
      organizations,
      permissions,
      addressProfiles,
    }
  );
});

export { Controller }
