// @flow
import React from "react";
import Form from "react-bootstrap/Form";




const View = (props: {
  onChange: Function,
}) => {

  const {
    onChange,
  } = props;

  return <Form>
    <Form.Group>
    <Form.Check
      type="checkbox"
      label="Confirm recission"
      onChange={onChange}
      name="confirmed"
      value="confirmed"
    />
    <Form.Text>Confirm recission</Form.Text>
    </Form.Group>
  </Form>
}

export {
  View,
}
