// @flow
import React, {
  createElement as el,
  useState,
} from "react";
import {
  withRouter,
} from "react-router-dom";
import {
  connect,
} from "react-redux";
import * as R from "ramda";
import { useMachine } from "@xstate/react";
import { createMachine } from "xstate";

import {
  type BmbixAddress,
} from "../../../Entity/Types.js";
import {
  withKlondikeApi,
} from "../../../Api/Klondike";
import {
  withMartletOrganizationApi,
} from "../../../Api/MartletOrganization";

import {
  generateAlias,
} from "./../../../Util/alias";

import { ConfirmClaimView, ErrorView, ResultClaimView, SelectionClaimView,  } from "./view.js";

const addressMachine = createMachine({
  id: "addressAbsent",
  initial: "addressPresent",
  states: {
    addressPresent: {
      on: { ADDRESS_REMOVED: "addressAbsent" }
    },
    addressAbsent: {
      on: { ADDRESS_AVAILABLE: "addressPresent" }
    }
  },
});

const WAITING = "waiting";
const FAILURE = "failure";

const connector = connect(
  state => {
    return {
      addresses: state.addresses,
    }
  },
)

const Controller = withKlondikeApi(withMartletOrganizationApi(withRouter(connector((props: {
  addresses: Array<BmbixAddress>,
  location: Object,
  match: Object,
  klondikeApi: Object,
  martletOrganizationApi: Object,
}) => {
  const SELECTION = "selection";
  const CONFIRMATION = "confirmation";
  const RESULT = "result";
  const {
    addresses,
    location,
    klondikeApi,
    martletOrganizationApi,
  } = props;
  const params = new URLSearchParams(location.search);
  const messageToken_ = params.get("message_token");
  const [messageToken, setMessageToken] = useState(messageToken_);
  const [address, setAddress] = useState(addresses[0]);
  const [display, setDisplay] = useState(SELECTION);
  const [unaddressedMessage, setUnaddressedMessage] = useState();
  const [messageId, setMessageId] = useState();
  const [ state, send ] = useMachine(addressMachine);

  const lookupAddress = (addressId, addresses) => {
    return R.find(R.propEq("id", addressId))(addresses);
  }

  const fetchUnaddressedMessage = async (messageToken) => {
    const unaddressedMessage = await klondikeApi.getUnaddressedMessageByMessageToken({
      messageToken,
    });
    if (!!unaddressedMessage.claimedAt){
      // message already claimed
      return unaddressedMessage;
    }
    const fromAddressId = unaddressedMessage.fromAddressId;

    /*
    const aliases = await martletOrganizationApi.getAliases({
      addressIds: [fromAddressId, ]
    });
    const alias = aliases[0].alias;
    */

    const addressProfiles: Array<AddressProfile> = await martletOrganizationApi.getAddressProfiles({addressIds: [fromAddressId, ]})
    const addressProfile = addressProfiles[0]
    const alias = generateAlias(addressProfile);


    return Object.assign({alias}, unaddressedMessage);
  }

  const selection = display === SELECTION ? (
    <SelectionClaimView
      selectedAddressId = {params.get("addressId")}
      messageToken={!!messageToken ? messageToken: ""}
      addresses={addresses}
      onClaim={(messageToken, addressId)=>{
        setAddress(lookupAddress(addressId, addresses));
        setMessageToken(messageToken);
        setDisplay(WAITING);
        fetchUnaddressedMessage(messageToken).then(
          unaddressedMessage => {
            setUnaddressedMessage(unaddressedMessage);
            setDisplay(CONFIRMATION);
          }
        ).catch(
          error => setDisplay(FAILURE)
        );
      }}
    />
  ): null;

  const confirmation = display === CONFIRMATION ? (
    <>
    <ConfirmClaimView
      unaddressedMessage={unaddressedMessage}
      onCancel={e => setDisplay(SELECTION)}
      onConfirm={e => {
        setDisplay(WAITING);
        klondikeApi.updateWithClaim({
          addressId: address.id,
          messageToken: messageToken,
        }).then(
          claim => {
            setMessageId(claim.messageId);
            setDisplay(RESULT)
          }
        ).catch(
          error => setDisplay(FAILURE)
        );
      }}
    />
    </>
  ): null;

  const result = display === RESULT && !!messageId ? (
    <ResultClaimView
      messageId={messageId}
      address={address}
      messageToken={messageToken}
      callBack={e => setDisplay(SELECTION)}
    />
  ): null;

  const waiting = display === WAITING ? (
    <p>Waiting ...</p>
  ): null;

  const failure = display === FAILURE ? (
    <p>Ooops! Something has gone wrong ...</p>
  ): null;


  return addresses.length <= 1 ? <ErrorView error="no-addresses"/> : (
    <>
    {selection}
    {confirmation}
    {result}
    {waiting}
    {failure}
    </>
  );

}))));

export {
  Controller,
}
