// @flow
import React from "react";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {
  Link,
} from "react-router-dom";
import Button from "react-bootstrap/Button";
import log from "loglevel";

import {
  type AddressBookEntry,
} from "../Entity/Types.js";
import {
  decorateInPlace,
} from "../Util";
import {
  MailIcon,
  DeviceMobileIcon,
} from "@primer/octicons-react";


const logger = log.getLogger("AddressBookEntry");

const List = (props: {
  addressBookEntries: Array<AddressBookEntry>,
  selection: Array<string>,
  callback: Function,
  onEdit: Function
}) => {

  const {
    onEdit,
  } = props;


  const decoratedAddressBookEntries = decorateInPlace({
    items: props.addressBookEntries,
    selection: props.selection,
    key: "addressBookEntryId",
  });

  const handleChange = (e) => {
    const selected = e.target.checked;
    const addressBookEntryId = e.target.value;
    props.callback({
      addressBookEntryId,
      selected,
    });
  }

  const columns = [
    {
      maxWidth: 20,
      id: "record",
      accessor: d => {return {item: d.item, selected: d.selected}},
      Cell:  ({row}) => {
        return (
          <Form.Check
            id={row.record.item.addressBookEntryId}
            name={row.record.item.addressBookEntryId}
            key={row.record.item.addressBookEntryId}
            value={row.record.item.addressBookEntryId}
            onChange={handleChange}
            checked={row.record.selected}
          />
        )
      }
    },
    {
      Header: "Platform Account",
      id: "localAccount",
      accessor: d => d.item.localAccount,
      filterable: true,
    },
    {
      Header: "Platform Name",
      id: "localName",
      accessor: d => d.item.localName,
      filterable: true,
    },
    {
      Header: "Platform Email",
      id: "localEmail",
      filterable: true,
      accessor: d => !!d.item.localEmail ? d.item.localEmail : "",
      Cell: ({row}) => {
        const email = row.item.localEmail;
        return !!email ? (
          <>{email}</>
        ) : <>no email found</>;
      },
    },
    {
      Header: "Bmbix Address",
      id: "item",
      accessor: d => d.item,
      filterable: true,
      filterMethod: (filter, rows) => !!rows[filter.id].foreignAddressId ? rows[filter.id]["foreignAddressId"].includes(filter.value): false,
      Cell: ({row}) => {
        const foreignAddressId = row.item.foreignAddressId;
        if (!!foreignAddressId) {
          if (!!row.item.alias) {
            const addressTooltip = <Tooltip>{foreignAddressId}</Tooltip>
            const profileUrl = `/profiles/address/${foreignAddressId}`;
            return (
              <OverlayTrigger overlay={addressTooltip} placement="bottom"
              delay={{show: 250, hide: 400}} ><Link to={profileUrl}>{row.item.alias}</Link></OverlayTrigger>
            )
          } else {
            return <>{foreignAddressId}</>
          }
        } else {
          const email = row.item.localEmail;
          const foreignAddressId = row.item.foreignAddressId;
          const subject = encodeURIComponent(`Bmbix Invitation`);
          const body = encodeURIComponent(`Hi, it would be great if you could join us on Bmbix. We can exchange pure digital accounting documents securely and safely. Here's the link: https://client.bmbix.com/referrer=A93243. Kind regards.`);
          const mailto = `mailto:${email}?subject=${subject}&body=${body}`;
          const whatsapp = `https://wa.me/447941025011/?text=${body}`;
          const emailTooltip = <Tooltip>Send invitation by email</Tooltip>
          const whatsAppTooltip = <Tooltip>Send invitation by WhatsApp</Tooltip>

          return !!email && !foreignAddressId ? (
            <><a href={mailto}><OverlayTrigger overlay={emailTooltip} placement="bottom" delay={{show: 250, hide: 400}}><Button className="mr-2"><MailIcon /></Button></OverlayTrigger></a>
            <a href={whatsapp}><OverlayTrigger overlay={whatsAppTooltip} placement="bottom" delay={{show: 250, hide: 400}} ><Button><DeviceMobileIcon /></Button></OverlayTrigger></a></>
            ): <></>;


        }
      },
    },
    {
      id: "editButton",
      Cell: ({row}) => {
        return (
          <Button
            value={row.record.item.addressBookEntryId}
            onClick={onEdit}
          >Edit</Button>
        )
      }
    },
  ]

  return (
    <div>
    <ReactTable
      className="-striped -highlight"
      columns={ columns }
      data={ decoratedAddressBookEntries }
      defaultPageSize={ 10 }
      defaultSorted={[
        {id: "localName", desc: false},
      ]}
    />
    </div>
  );
}


export {
  List,
}
