// @flow
import React, {useEffect, useState,} from "react";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import { useMachine } from "@xstate/react";
import {
  assign,
  createMachine,
} from "xstate";
import {
  Spinner,
} from "./../../../Widgets/Toast.js";
import { useDispatch } from "react-redux";

import { withMartletOrganizationApi } from "./../../../Api/MartletOrganization";
import {
  TrashIcon,
  PlusIcon,
  SyncIcon,
} from "@primer/octicons-react"
import { useSelector } from "react-redux";


import { useToken } from "./../../../State";

import {
  AddModal,
} from "./../../Widgets/AddModal/view.js";
import {
  DeleteModal,
} from "./../../Widgets/DeleteModal/view.js";
import {
  RevealModal
} from "./../../Widgets/RevealModal/view.js";
import {
  CheckableOrganizationProxiesTable,
} from "./../../Widgets/Table/view.js";
import { getOrganizationProxyOauth2Clients } from "./../../store.js";


const viewMachine = createMachine({
  id: "viewMachine",
  initial: "idle",
  context: {
    errors: undefined,
    organizationProxyOauth2Clients: undefined,
  },
  states: {
    idle: {
      on: {
        START: "working"
      }
    },
    working: {
      on: {
        SUCCESS: {
          target: "success",
          actions: assign({
            organizationProxyOauth2Clients: (c, e, m) => e.opocs,
          }),
        },
        FAILURE: {
          target: "failure",
          actions: assign({
            errors: (c, e, m) => e.errors,
          }),
        }
      }
    },
    success: {
      on: {
        RESET: "idle",
      }
    },
    failure: {
      on: {
        RESET: "idle"
      }
    },
  },
});

const View_ = (props: {
  match: Function,
  martletOrganizationApi: Object,
}) => {
  const {
    match,
    martletOrganizationApi,
  } = props;
  const dispatch = useDispatch();
  const organizationId = match.params.organizationId;
  const [oauth2ClientId, setOauth2ClientId] = useState();
  const [secret, setSecret] = useState();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDelModal, setShowDelModal] = useState(false);
  const token = useToken();
  const oauth2Clients = useSelector( state => state.oauth2Clients );
  const [ viewState, viewSend] = useMachine(viewMachine);

  useEffect (() => {
    viewSend({type: "START"});
    dispatch(getOrganizationProxyOauth2Clients(token)(organizationId)).then(
      opocs => {
        console.log("organizationProxiesOCs: ", opocs);
        viewSend({type: "SUCCESS", opocs});
      }
    ).catch(
      error => {
        console.log("error: ", error);
        viewSend({type: "FAILURE", error});
      }
    )
  }, [organizationId, token, viewSend, dispatch])

  const refresh = (event) => console.log("Refresh event: ", event);

  const handleNewClientCredentials = (e) => {
    const secret_ = e.target.value;
    setSecret(secret_);
  }

  const reveal = !!secret ? (
    <RevealModal secret={secret} onDismiss={ e => setSecret(null) }/>
  ): null;

  const add = showAddModal ? (
    <AddModal
      martletOrganizationApi={martletOrganizationApi}
      organizationId={organizationId}
      onComplete={ _ => setShowAddModal(false) }
      onCancel={ _ => setShowAddModal(false) }
    />
  ): null;

  const del = showDelModal ? (
    <DeleteModal
      oauth2ClientId={oauth2ClientId}
      martletOrganizationApi={martletOrganizationApi}
      organizationId={organizationId}
      onComplete={ _ => setShowDelModal(false) }
      onCancel={ _ => setShowDelModal(false) }
    />
  ): null;


  const itemsTable = viewState.value === "idle"
    ? null
    : viewState.value === "working"
    ? <Spinner height="50" />
    : viewState.value === "failure"
    ? <p>Unable to fetch extraction items. {viewState.context.errors.toString()}</p>
    : viewState.value === "success"
    ? <CheckableOrganizationProxiesTable
        onNewClientCredentials={handleNewClientCredentials}
        items={viewState.context.organizationProxyOauth2Clients}
        checked={[]}
        callBack={checkedIds => {
          if ( checkedIds.length > 0 ) {
            setOauth2ClientId(checkedIds[0])
          } else {
            setOauth2ClientId(null)
          }
        }}
      />
    : <p>Unknown state</p>;

  return (
    <>
    <ButtonToolbar className="float-right" >
      <Button onClick={e => setShowAddModal(true) }><PlusIcon/> Add</Button>
    </ButtonToolbar>
    <h2>Organization Proxies</h2>
    <p>An organization proxy is like a service account. An organization proxy is
    an actor in the system, representing the organization rather than an
    individual user. An organization can create many proxies, the benefit being
    that each proxy can be given its own access permissions.</p>
    <Button className="mr-2" onClick={ e => setShowDelModal(true) }><TrashIcon /> Delete</Button>
    <Button className="mr-2" onClick={refresh}><SyncIcon /> Refresh</Button>

    {itemsTable}
    {reveal}
    {add}
    {del}
    </>
  );
}

const View = withMartletOrganizationApi(View_);

export {
  View,
}
