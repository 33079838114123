// @flow

export type Product = {
  +id: string,
  +description: string,
}

const createProduct = (args: {
  id: string,
  description: string,
}) => {
  return Object.freeze(args);
}

export type ProductList = {
  +id: string,
  +name: string,
  +products: Array<Product>,
}

const createProductList = (args: {
  id: string,
  name: string,
  products: Array<Product>,
}) => {
  return Object.freeze(args);
}

export type Price = {
  +productId: string,
  +unitPrice: string,
}

const createPrice = (args: {
  productId: string,
  unitPrice: string,
}) => {
  return Object.freeze(args);
}

export type PriceList = {
  +id: string,
  +currency: string,
  +name: string,
  +prices: Array<Price>,
}

const createPriceList = (args: {
  id: string,
  currency: string,
  name: string,
  prices: Array<Price>,
}) => {
  return Object.freeze(args);
}

export type Allowance = {
  +productId: string,
  +quantity: string,
}

const createAllowance = (args: {
  productId: string,
  quantity: string,
}) => {
  return Object.freeze(args);
}

export type AllowanceList = {
  +id: string,
  +name: string,
  +allowances: Array<Allowance>,
}

const createAllowanceList = (args: {
  id: string,
  name: string,
  allowances: Array<Allowance>,
}) => {
  return Object.freeze(args);
}

export type Usage = {
  +productId: string,
  +quantity: string,
}

const createUsage = (args: {
  productId: string,
  quantity: string,
}) => {
  return Object.freeze(args);
}

export type UsageReport = {
  +productList: ProductList,
  +priceList: PriceList,
  +allowanceList: AllowanceList,
  +usageList: Array<Usage>,
}

const createUsageReport = (args : {
  productList: ProductList,
  priceList: PriceList,
  allowanceList: AllowanceList,
  usageList: Array<Usage>,
}): UsageReport => {
  return Object.freeze(args)
}

export type CardPaymentMethod = {
  +description: string,
}

const createCardPaymentMethod = (args: {
  description: string,
}) => {
  return Object.freeze(args);
}

export type DirectDebitPaymentMethod = {
  +description: string,
}

const createDirectDebitPaymentMethod = (args: {
  description: string,
}) => {
  return Object.freeze(args);
}

export type PaymentMethod =
  | CardPaymentMethod
  | DirectDebitPaymentMethod


export type CurrentChargeLine = {
  +quantity: string,
  +productId: string,
  +description: string,
  +unitPrice: string,
  +total: string,
}

const createCurrentChargeLine = (args: {
  quantity: string,
  productId: string,
  description: sting,
  unitPrice:string,
  subTotal: string,
  total: string,
}) => {
  return Object.freeze(args);
}


export {
  createCurrentChargeLine,
  createProduct,
  createProductList,
  createPrice,
  createPriceList,
  createAllowance,
  createAllowanceList,
  createUsage,
  createCardPaymentMethod,
  createDirectDebitPaymentMethod,
  createUsageReport,
}


