// @flow
import Future from "fluture";

import {
  ApiClient,
  AddressesApi,
  AccessList as AccessListGW,
} from "@bmbix/bmb_martlet_organization_client";
import { gwToAccessList } from "./adapt.js";

const addList = (token: string) => (owner: string) => (name: string)=> (type: string): Future => {

  const apiInstance = new AddressesApi();
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;

  const body = AccessListGW.constructFromObject({
    name,
    type
  });

  return Future((reject, resolve) => {
    console.log("owner", owner);
    console.log("name", name);
    console.log("type", type);
    apiInstance.createAccessList(body, owner, (error, data, response) => {
      if (error) {
        console.log("error", error);
        reject(error);
      } else {
        const gw = data.access_list;
        const accessList = gwToAccessList(gw);
        resolve(accessList);
      }
    });
    return console.log
  });
}

export { addList }
