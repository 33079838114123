// @flow

import React, {
  createElement as el,
  useState,
} from "react";
import Button from "react-bootstrap/Button";

import {
  TenantMappingDeleteModal,
} from "./TenantMappingDeleteModal.js";
import {
  TenantMapping,
} from "./../../../Entity/Types.js";

const Controller = (props: {
  tenantMapping: TenantMapping,
  onUpdate: Function,
}) => {

  const {
    onUpdate,
    tenantMapping,
  } = props;

  const [ showModal, setShowModal ] = useState(false);

  const handleShow= e => {
    setShowModal(true);
  }

  const handleHide= e => {
    setShowModal(false);
  }

  const button = el(
    ButtonView,
    {
      onShow: handleShow,
    }
  );

  const modal = el (
    TenantMappingDeleteModal,
    {
      onHide: handleHide,
      onUpdate,
      tenantMapping,
    },
  );

  return showModal
  ? modal
  : button;

}


const ButtonView = (props: {
  onShow: Function,
}) => {
  return <>
    <Button onClick={props.onShow}>Unassign</Button>
  </>
}

export {
  Controller,
  Controller as TenantMappingDelete,
  ButtonView,
}
