// @flow

import React from "react";
import Button from "react-bootstrap/Button";
import {
  Row,
  Col,
} from 'react-bootstrap'


const View = (props: {
  logoutUrl: string,
  onSubmit: Function,
}) => {
  const {
    logoutUrl,
    onSubmit,
  } = props;

  return (
    <div className="d-flex align-items-center justify-content-center" style={{height: "40vh"}}>
    <a href={logoutUrl}><Button className="btn-lg" onClick={onSubmit}>Log out</Button></a>
    </div>
  );
}


export {
  View,
}
