// @flow
import Future from "fluture";
import * as S from "sanctuary";

import {
  ApiClient,
  OrganizationsApi,
  BillingAddress as BillingAddressGW,
  BillingAddressHistoryResponse as BillingAddressHistoryResponseGW,
} from "@bmbix/bmb_martlet_organization_client";

import {
  instanceService,
} from "./../../Entity/InstanceService";

import { callBack } from "./../../Api/CallBack";

import { createBillingAddressFromGW } from "./adapt";

const getBillingAddressHistory = (args: {
  token: string,
  organizationId: string,
}) => {
  const {
    token,
    organizationId,
  } = args;

  const defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new OrganizationsApi(defaultClient);

  const dataHandler = data => {
    const billingAddressesGW = data.billing_addresses;
    const billingAddresses = S.map (createBillingAddressFromGW) (billingAddressesGW);
    return billingAddresses;
  }

  return Future((reject, resolve) => {
    apiInstance.getBillingAddressHistory(
      organizationId,
      callBack(reject, resolve)(dataHandler),
    )
    return console.log;
  })

}

export {
  getBillingAddressHistory,
}
