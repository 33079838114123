// @flow
import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Switch, Route } from "react-router-dom";
import {
  DesktopDownloadIcon,
} from "@primer/octicons-react"

const View = (props: {
  location: Function,
  match: Function,
}) => {

  return (
    <>
    <Switch>
      <Route path="/c"><ClaimTrail/></Route>
    </Switch>
    </>
  );
}

const ClaimTrail = (props: {
}) => {

  return (
    <Breadcrumb>
      <Breadcrumb.Item active><DesktopDownloadIcon/> Claim</Breadcrumb.Item>
    </Breadcrumb>
  )
}

export { View }
