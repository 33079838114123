// @flow

import * as S from "sanctuary";
import { useSelector, useDispatch, } from "react-redux";

const ADD_TOKEN = "TOKEN_ADD";
const REMOVE_TOKEN = "TOKEN_REMOVE";
const ADD_BAD_TOKEN_FLAG = "BAD_TOKEN_SIGNAL_ADD";
const REMOVE_BAD_TOKEN_FLAG = "BAD_TOKEN_SIGNAL_REMOVE";

const addBadTokenFlag = () => {
  return {
    type: ADD_BAD_TOKEN_FLAG,
  }
}

const removeBadTokenFlag = () => {
  return {
    type: REMOVE_BAD_TOKEN_FLAG,
  }
}

const badTokenFlag = (state=false, action) => {
  switch(action.type) {
    case ADD_BAD_TOKEN_FLAG:
      return true;
    case REMOVE_BAD_TOKEN_FLAG:
      return false;
    default:
      return state;
  }
}

const useBadTokenFlag = () => {
  return useSelector(store => store.bad_token_flag)
}

const addToken = (token) => {
  return {
    type: ADD_TOKEN,
    token: token,
  }
}

const removeToken = (token) => {
  return {
    type: REMOVE_TOKEN,
  }
}

const token = (state=null, action) => {
  switch(action.type) {
    case ADD_TOKEN:
      return action.token;
    case REMOVE_TOKEN:
      return null;
    default:
      return state;
  }
}

const useToken = () => {
  return useSelector(store => store.token )
}

const useCatchExpiredToken =
  () =>
{
  const dispatch = useDispatch();
  const catchExpiredToken =
    error =>
  {
    const expiredTokenEither = S.tagBy (e => S.equals (401) (e.status)) (error);
    const doNothing = S.I;
    const flagToken = error => dispatch(addBadTokenFlag())
    S.either (doNothing) (flagToken) (expiredTokenEither);
  }
  return catchExpiredToken;
}

export {
  addToken,
  removeToken,
  token,
  useToken,
  addBadTokenFlag,
  removeBadTokenFlag,
  badTokenFlag,
  useBadTokenFlag,
  useCatchExpiredToken,
}
