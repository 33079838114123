import * as R from "ramda";


const ACCESSKEY_ADD = "ACCESSKEY_ADD";
const ACCESSKEY_DELETE = "ACCESSKEY_DELETE";


const addAccessKey = (accessKey) => {
  return {
    type: ACCESSKEY_ADD,
    accessKey: accessKey,
  }
}


const deleteAccessKey = (accessKeyId) => {
  return {
    type: ACCESSKEY_DELETE,
    accessKeyId: accessKeyId,
  }
}



const accessKeys = (state=[], action) => {
  switch(action.type){
    case ACCESSKEY_ADD:
      return R.append(action.accessKey, state);
    case ACCESSKEY_DELETE:
      return R.filter(k => k.accessKeyId !== action.accessKeyId, state);
    default:
      return state;

  }
}


export {
  accessKeys,
  addAccessKey,
  deleteAccessKey,
}
