// @flow
import React from "react";
import Button from "react-bootstrap/Button";
const View = ({
  apiState,
  onCancel,
  onSubmit,
}: {
  apiState: Function,
  onCancel: Function,
  onSubmit: Function,
}) => {

  return apiState.value === "ready"
  ? <Button onClick={onSubmit}>Submit</Button>
  : apiState.value === "working"
  ? <p>Spinning ...</p>
  : apiState.value === "success"
  ? <p>Success</p>
  : apiState.value === "failure"
  ? <><p>It is brokeded</p><Button onClick={onCancel}>Cancel</Button></>
  : <p>Unknown state</p>


}


export {
  View,
}
