// @flow
import {
  createElement as el,
} from "react";

import {
  type Acceptance,
} from "./../../Entity/Types.js";

import { View } from "./view.js";


const Controller = (props: {
  deliveryAdvice: Acceptance,
}) => {
  const {
    deliveryAdvice,
  } = props;

  return el (
    View,
    {
      deliveryAdvice,
    });

};

export {
  Controller,
}

