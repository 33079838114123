// @flow
import sanctuary from "sanctuary";
import * as $ from "sanctuary-def";
import { env as flutureEnv } from "fluture-sanctuary-types";
import {
  chain,
  encaseP,
  map,
  resolve,
} from "fluture";

import {
  getUserProfiles,
} from "./../Api/MartletOrganization/Profile.js";
import {
  getUserIds,
} from "./../Organization/EnrichPermissionsLogic.js";
import {
  refreshAddressPermissionsThunk,
} from "./permissions.js";
import { mergeUserProfiles } from "./../State/UserProfiles.js";

const S = sanctuary.create ({
  checkTypes: true, env:
  sanctuary.env.concat(flutureEnv)
});

const contains = anArray => anElement => S.elem (anElement) (anArray);
const diffArrays = pool => candidates => S.reject (contains(pool)) (candidates)
const notEmpty = S.pipe ([S.size, S.gt (0)]);

const getFetchablesEither =
  state =>
  userIds =>
{
    const userIdPoolMaybes = S.map (S.get (S.is ($.String)) ("userId")) (state.userProfiles)
    const userIdPool = S.justs (userIdPoolMaybes);
    const missingUserIds = diffArrays(userIdPool)(userIds);
    const eitherUserIds = S.tagBy (notEmpty) (missingUserIds);
  return eitherUserIds;
}


const fetchUserProfiles =
  token =>
  eitherUserIds =>
{
  const doNothing = userIds => resolve(userIds);
  const fetch = userIds => encaseP (getUserProfiles) ({token, userIds})
  return S.either (doNothing) (fetch) (eitherUserIds);
}


const cacheUserProfiles =
  dispatch =>
  userProfiles =>
{
  dispatch(mergeUserProfiles(userProfiles));
  return userProfiles; // not used
}

const refreshUserProfilesThunk =
  (token: string) =>
  (addressId: string) =>
{

  const thunk =
    (dispatch, getState) =>
  {
    const state = getState();


    const permissionsFuture =
      dispatch(refreshAddressPermissionsThunk(token)(addressId));

    console.log("stratus: ", S.type(permissionsFuture));

    const requiredUserIdsFuture = map(getUserIds) (permissionsFuture);

    const userIdsToBeFetchedFuture =
      map (getFetchablesEither(state)) (requiredUserIdsFuture);

    const userProfilesFuture = chain (fetchUserProfiles(token)) (userIdsToBeFetchedFuture);
    const futureAfterCaching = map (cacheUserProfiles(dispatch)) (userProfilesFuture);
    return futureAfterCaching;

  }
  return thunk;

}

export {
  refreshUserProfilesThunk,
}
