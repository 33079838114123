// @flow
import React, {
  useState,
} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import {
  withIsimudApi,
} from "../Api/Isimud";

const INPUT = "input";
const WAITING = "waiting";
const SUCCESS = "success";
const FAILURE = "failure";


const UnassignModal_ = (props: {
  onComplete: Function,
  onCancel: Function,
  organizationId: string,
  platformAssignmentId: string,
  isimudApi: Object,
}) => {

  const {
    onComplete,
    onCancel,
    organizationId,
    platformAssignmentId,
    isimudApi,
  } = props;

  const [display, setDisplay] = useState(INPUT);

  const handleClickSubmit = (e) => {
    setDisplay(WAITING);
    isimudApi.deletePlatformAssignment({
      organizationId,
      platformAssignmentId,
    }).then(
      result => {
        setDisplay(SUCCESS);
      }
    ).catch(
      error => setDisplay(FAILURE)
    );
  }

  const cancelButton = display === INPUT ? (
    <Button onClick={onCancel} variant="secondary">Cancel</Button>
  ): null;

  const submitButton = display === INPUT ? (
    <Button onClick={handleClickSubmit} variant="primary">Unassign</Button>
  ): null;

  const doneButton = display === SUCCESS || display === FAILURE ? (
    <Button onClick={onComplete} variant="primary">Back</Button>
  ): null ;


  const input = display === INPUT ? (
    <>
      <p>Do you want to delete this assignment &quest;</p>
      <p>If this address is not assigned to a platform then you will not be able to insert or extract messages via this application.</p>
    </>
  ): null;

  const waiting = display === WAITING ? (
    <p>Waiting ...</p>
  ): null ;

  const success = display === SUCCESS ? (
    <p>Success!</p>
  ): null;

  const failure = display === FAILURE ? (
    <p>Ooops!, Something went wrong.</p>
  ): null;

  return (
    <Modal show={true} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Assignment</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {input}
        {waiting}
        {success}
        {failure}
      </Modal.Body>

      <Modal.Footer>
        {cancelButton}
        {submitButton}
        {doneButton}
      </Modal.Footer>
    </Modal>
  )

}


const UnassignModal = withIsimudApi(UnassignModal_);


export {
  UnassignModal,
}
