// @flow
import React from "react";

import { Route } from "react-router";


import {
  IndexPage,
} from "./Widgets";


const View = (props: {
  match: Function,
}) => {

  return <>
    <Route exact path="/c" component={IndexPage} />
   </>
}

export {
  View,
}

