// @flow
import log from "loglevel";
import { useSelector } from "react-redux";

import {
  type Settings,
} from "../Entity/Types.js";

/* Works
const logger = log.getLogger("xtate.Settings");
*/
/* Broken
const logger = log.getLogger("State.Settings");
*/
/* Works
const logger = log.getLogger("State.xettings");
*/
/* Works
const logger = log.getLogger("State.Xettings");
*/
/* Works
const logger = log.getLogger("State.Addresses");
*/
const logger = log.getLogger("Settings");

const SET_SETTINGS = "SET_SETTINGS";

const setSettings = (settings: Settings) => {
  return {
    type: SET_SETTINGS,
    settings: settings,
  }
}

const reducer = (state:?Settings=null, action:Object)  => {
  if (!action){
    logger.debug("no action");
    return state;
  }
  switch(action.type) {
    case SET_SETTINGS:
      logger.debug("setting settings:", action.settings);
      return action.settings;
    default:
      logger.debug(`no recognized action: ${action}`);
      return state;
  }
}

const useSettings = () => {
  return useSelector(store => store.settings)
}


export {
  setSettings,
  reducer as settings,
  useSettings,
}
