// @flow
import * as React from "react";

import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "../Util";

const Input = (props: {
  display: string,
  children: ?React.Node,
}) => {
  return props.display === INPUT ? (
    <>
    {props.children}
    </>
  ): null;
}


const Waiting = (props: {
  display: string,
  children: ?React.Node,
}) => {
  return props.display === WAITING ? (
    <>
    {props.children}
    </>
  ): null;
}

const Success = (props: {
  display: string,
  children: ?React.Node,
}) => {
  return props.display === SUCCESS ? (
    <>
    {props.children}
    </>
  ): null;
}

const Failure = (props: {
  display: string,
  children: ?React.Node,
}) => {
  return props.display === FAILURE ? (
    <>
    {props.children}
    </>
  ): null;
}


export {
  Input,
  Waiting,
  Success,
  Failure,
}
