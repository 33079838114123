// @flow

import React from "react";
import {
  type InsertionReceipt,
} from "../Entity/Types";
import ReactTable from "react-table-6"
import "react-table-6/react-table.css"

const InsertionReceiptsTable = (props: {
  items: Array<InsertionReceipt>,
}) => {
    const {
      items,
    } = props;

    const columns = [
      {
        Header: "Type",
        accessor: "messageType",
      },
      {
        Header: "Ref",
        accessor: "reference",
      },
      {
        Header: "Received",
        accessor: "receivedTimestamp",
      },
      {
        Header: "Result",
        accessor: "result",
      },
      {
        Header: "Comments",
        accessor: "comments",
      },
  ]

  return (
    <ReactTable
      data={items}
      columns={columns}
      defaultPageSize={10}
      className="-striped -highlight"
      defaultSorted={[{id:"receivedTimestamp", desc: true}]}
    />
  )
}



export {
  InsertionReceiptsTable,
}

