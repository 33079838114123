// @flow
import {
  createElement as el,
  useEffect,
  useState,
} from "react";
import {
  withRouter,
} from "react-router-dom";
import {
  connect,
} from "react-redux";
import * as S from "sanctuary";
import { useMachine } from "@xstate/react";
import { createMachine } from "xstate";
import {
  Spinner,
} from "./../../Widgets/Toast";

import log from "loglevel";

import {
  type Organization,
} from "../../Entity/Types.js";
import { SuccessView, FailureView, InputView } from "./view.js";

import { getOrganizationCertificate } from "./../../Api/MartletOrganization/Certificates";
import { useToken } from "./../../State";
import { fork } from "fluture";

const logger = log.getLogger("CertificateView");

const fetchingMachine = createMachine({
  id: "fetchingMachine",
  initial: "waiting",
  states: {
    waiting: {
      on: {
        SUCCESS_HAPPENED: "success",
        FAILURE_HAPPENED: "failure",
      }
    },
    success: {
      on: {
        WAITING_HAPPENED: "waiting",
      }
    },
    failure: {
      on: {
        RESET_HAPPENED: "input"
      }
    },
    input: {
      on: {
        SUBMIT_HAPPENED: "waiting"
      }
    },
  },
});

const Controller_ = (props: {
  organization: ?Organization,
}) => {

  const {
    organization,
  } = props;
  const token = useToken();
  const [ certificate, setCertificate] = useState()
  const [ state, send ] = useMachine(fetchingMachine);

  const onResetAfterFailure = e => send({type: "RESET_HAPPENED"});

  useEffect(
    () => {
      send({type: "WAITING_HAPPENED"});
      const action = getOrganizationCertificate(token)(organization.id)
      fork(error => {
        console.log(error);
        send({type: "FAILURE_HAPPENED"});
      })(certificate=> {
        setCertificate(certificate)
        send({type: "SUCCESS_HAPPENED"});
      })(action)
    },
    [token, organization, send],
  );

  return  state.value === "waiting"
  ? el (Spinner, {height: "50"})
  : state.value === "success"
  ? el (
      SuccessView,
      {
        organization,
        data: certificate.data,
        subjectName:organization.name,
        certificateId: certificate.cretificateId,
      }
    )
  : state.value === "failure"
  ? el (
      FailureView,
      {
        onResetAfterFailure,
      },
  )
  : state.value === "input"
  ? el (
      InputView,
      {
        organization,
      }
    )
  : null;
}



const connector = connect(
  (state, ownProps) => {
    const organizationId = ownProps.match.params.organizationId;
    logger.error("organizationId:", organizationId);
    const organization = S.maybeToNullable (
      S.find (o => o.id === organizationId) (state.orgs)
    );
    logger.error("organization:", organization);
    return {
      organization,
    }
  }
)


const Controller = withRouter(connector(Controller_));


export {
  Controller,
}
