// @flow
import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "../../../Util";
import {
  Spinner,
} from "../../../Widgets/Toast";
import {
  useForm,
  Controller,
} from "react-hook-form";

const View = (props: {
  state: string,
  name: string,
  onCancel: Function,
  onSubmit: Function,
}) => {

  const {
    state,
    name,
    onSubmit,
    onClose,
  } = props;

  const { control, handleSubmit, formState: { errors } }
    = useForm({defaultValues: {accountName: name}});
  const form = <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Group>
    <Form.Label>Name</Form.Label>
    <Controller
      name="accountName"
      rules={{required: true}}
      control={control}
      render={({field}) =>
        <Form.Control {...field} />
      }
    />
    <Form.Text>Enter your name</Form.Text>
      {errors.accountName && <span>This field is required</span>}
    </Form.Group>
    <Button type="submit" variant="primary" >Save changes</Button>
    </Form>
  const spinner = <Spinner height="24"/>;
  const successMessage = <p>All good, name was updated</p>;
  const failureMessage = <p>Oops, something has gone wrong.</p>;

  const body = state === "idle"
    ? form
    : state === "working"
    ? spinner
    : state === "succeeded"
    ? successMessage
    : state === "failed"
    ? failureMessage
    : <p>Unknown state</p>;

  return (
    <Modal show={true}>
    <Modal.Header closeButton onHide={onClose}>Set Name</Modal.Header>
    <Modal.Body>
    {body}
    </Modal.Body>
    <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>Close</Button>
    </Modal.Footer>
    </Modal>
  )
}

export {
  View,
}
