// @flow
import {
  createElement as el
} from "react";


import {
  View,
} from "./View.js";

const Controller = (props: {
  onRetry: Function,
}) => {
  const {
    onRetry,
  } = props;

  return el(
    View,
    {
      onRetry,
    }
  );
}

export {
  Controller,
}

