// @flow
import {
  createElement as el,
  useState,
} from "react";
import log from "loglevel";

import {
  withSageV1AuthnApi,
} from "../Api";
import {
  INPUT,
  FAILURE,
  SUCCESS,
  WAITING,
} from "../../../Util";

import {
  View,
} from "./View.js";

const logger = log.getLogger("Sage");

const Controller_ = (props: {
  platformId: string,
  organizationId: string,
  onComplete: Function,
  sageV1AuthnApi: Object,
}) => {

  const {
    platformId,
    organizationId,
    onComplete,
    sageV1AuthnApi,
  } = props;

  const [display, setDisplay] = useState(INPUT);
  const [status, setStatus] = useState();

  const onSubmit = e => {
    setDisplay(WAITING);
    sageV1AuthnApi.test({
      platformId,
      organizationId,
    }).then(
      status => {
        setStatus(status);
        setDisplay(SUCCESS);
      }
    ).catch(
      error => {
        setDisplay(FAILURE)
      }
    );
  }

  const onSuccess= e => {
    logger.debug("disconnect success");
    onComplete();
  }

  const onFailure = e => {
    logger.debug("disconnect failure");
    onComplete();
  }

  const onCancel = e => {
    logger.debug("disconnect cancelled");
    onComplete();
  }

  return el(
    View,
    {
      display,
      status,
      onSuccess,
      onSubmit,
      onCancel,
      onFailure,
    },
  );
}

const Controller = withSageV1AuthnApi(Controller_);

export {
  Controller,
  Controller_,
}

