// @flow
import * as R from "ramda";

import Future from "fluture";

import {
  ApiClient,
  AddressesApi,
  Address as AddressGW,
  AddressQuery as AddressQueryGW,
  MessageReadReceipt as MessageReadReceiptGW,
  Permission as PermissionGW,
} from "@bmbix/bmb_martlet_organization_client";
import { type BmbixAddress } from "../../Entity/Types";

import { callBack } from "./../CallBack/";
import {
  createAddressFromGW,
  createPermissionFromGW,
  createMessageReadReceiptFromGW,
} from "./adapters.js";


const getAddress = (args: {
  token: string,
  addressId: string,
  defaultClient_: Object,
}): Promise<BmbixAddress> => {
  const {
    token,
    addressId,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AddressesApi(defaultClient);

  const dataHandler = data => {
    const addressGW = data.address;
    const address = createAddressFromGW(addressGW);
    return address;
  }

  return new Promise((resolve, reject) => {
    apiInstance.getAddress(
      addressId,
      callBack(reject, resolve) (dataHandler),
    );
  });
}


const updateAddress = (args:{
  token: string,
  addressId: string,
  isClosed?: boolean,
  displayName?: string,
  alias?: string,
  purpose?: string,
  apiInstance?: Function,
}): Promise<BmbixAddress> => {
  let apiInstance = new AddressesApi();
  if(args.apiInstance){
    apiInstance = args.apiInstance;
  }
  let body = new AddressGW();
  if (args.isClosed !== undefined){
    body.is_closed = args.isClosed;
  }
  if (args.alias !== undefined) {
    body.alias = args.alias
  }
  if (args.purpose !== undefined) {
    body.purpose = args.purpose
  }
  if (args.displayName !== undefined){
    body.display_name = args.displayName
  }
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = args.token;

  const dataHandler = data => {
    const addressGW = data.address;
    const address = createAddressFromGW(addressGW);
    return address;
  }

  return new Promise((resolve, reject) => {
    apiInstance.updateAddress(
      body,
      args.addressId,
      callBack(reject, resolve) (dataHandler),
    );
  });
}

const updateAddressFuture = (args:{
  token: string,
  addressId: string,
  isClosed?: boolean,
  displayName?: string,
  alias?: string,
  purpose?: string,
  webHook?: string,
  webHookToken?: string,
  apiInstance?: Function,
}): Future => {
  let apiInstance = new AddressesApi();
  if(args.apiInstance){
    apiInstance = args.apiInstance;
  }
  let body = new AddressGW();
  if (args.isClosed !== undefined){
    body.is_closed = args.isClosed;
  }
  if (args.alias !== undefined) {
    body.alias = args.alias
  }
  if (args.purpose !== undefined) {
    body.purpose = args.purpose
  }
  if (args.displayName !== undefined){
    body.display_name = args.displayName
  }
  if (args.webHook !== undefined){
    body.web_hook = args.webHook
  }
  if (args.webHookToken !== undefined){
    body.web_hook_token = args.webHookToken
  }
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = args.token;

  const dataHandler = data => {
    console.log("success 32342");
    const addressGW = data.address;
    const address = createAddressFromGW(addressGW);
    return address;
  }

  return Future((reje, reso) => {
    apiInstance.updateAddress(
      body,
      args.addressId,
      callBack(reje, reso)(dataHandler),
    );
    return console.log  // cancel function
  });
}

const getAliases = (args:{
  token: string,
  addressIds: Array<string>,
  defaultClient_: Object,
}): Promise<Array<BmbixAddress>> => {
  const {
    token,
    addressIds,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  const body = AddressQueryGW.constructFromObject({
    address_ids: addressIds,
  });
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  const apiInstance = new AddressesApi(defaultClient);

  const dataHandler = data => {
    const addressesGW = data.addresses;
    const addresses = R.map(createAddressFromGW, addressesGW);
    return addresses;
  }

  return new Promise((resolve, reject) => {
    apiInstance.query(
      body,
      callBack(reject, resolve)(dataHandler),
    );
  });
}

const getPermissionsForAddress = (args: {
  token: string,
  addressId: string,
  defaultClient_: Object,
}): Promise<Array<Permission>> => {
  const {
    token,
    addressId,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AddressesApi(defaultClient);

  const dataHandler = data => {
    const permissionsGW = data.permissions;
    const permissions = R.map(
      permission => createPermissionFromGW(permission),
      permissionsGW,
    );
    return permissions;
  }

  return new Promise((resolve, reject) => {
    apiInstance.listPermissions(
      addressId,
      callBack(reject, resolve) (dataHandler),
    );
  });
}

const createPermissionForAddress = (args: {
  token: string,
  addressId: string,
  actor: string,
  power: string,
  defaultClient_: Object,
}): Promise<Permission> => {
  const {
    token,
    addressId,
    actor,
    power,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  const body = PermissionGW.constructFromObject({
    actor,
    power,
  });

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AddressesApi(defaultClient);

  const dataHandler = data => {
    const permissionGW = data.permission;
    const permission = createPermissionFromGW(permissionGW);
    return permission;
  }

  return new Promise((resolve, reject) => {
    apiInstance.createPermission(
      body,
      addressId,
      callBack(reject, resolve) (dataHandler),
    );
  });
}

const deletePermissionForAddress = (args: {
  token: string,
  addressId: string,
  permissionId: string,
  defaultClient_: Object,
}): Promise<?Permission> => {
  const {
    token,
    addressId,
    permissionId,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AddressesApi(defaultClient);

  const dataHandler = data => {
    return null;
  }

  return new Promise((resolve, reject) => {
    apiInstance.deletePermission(
      addressId,
      permissionId,
      callBack(reject, resolve) (dataHandler),
    );
  });
}

const markRead = (token: string) =>
  (addressId: string) =>
  (messageId: string): Future =>
{
  const defaultClient = ApiClient.instance;

  const body = MessageReadReceiptGW.constructFromObject({
    message_id: messageId,
  });

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AddressesApi(defaultClient);

  const dataHandler = data => {
    const messageReadReceiptGW = data.message_read_receipt;
    const messageReadReceipt = createMessageReadReceiptFromGW(messageReadReceiptGW);
    return messageReadReceipt;
  }

  return Future((reject, resolve) => {
    apiInstance.markRead(
      body,
      addressId,
      callBack(reject, resolve) (dataHandler),
    );
    return console.log;
  });
}

export {
  createAddressFromGW,
  getAddress,
  updateAddress,
  updateAddressFuture,
  getAliases,
  getPermissionsForAddress,
  deletePermissionForAddress,
  createPermissionForAddress,
  markRead,
}
