// @flow
import React from "react";
import Button from "react-bootstrap/Button";
import {
  Row,
  Col,
} from 'react-bootstrap'
import { Link } from "react-router-dom";
import * as S from "sanctuary";
import {
  MailIcon,
  LinkExternalIcon,
} from "@primer/octicons-react";

import {
  type OrganizationProfile,
} from "./../../../Entity/Types.js";
import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "./../../../Util";
import {
  copyToClipboard,
} from "./../../../Util/copy.js";
import {
  OrganizationProfileWidget,
} from "./../../../Widgets/OrganizationProfile";
import {
  BmbixQR,
} from "./../../../Widgets/BmbixQR";
import { CopyClip } from "./../../../Widgets/copy-clip.js";

const View = (props: {
  profile?: OrganizationProfile,
  display: String,
}) => {
  const {
    profile,
    display,
  } = props;

  const certificatePath = !!profile ? `./${profile.organizationId}/certificate`:"";
  const publicKeyPath = !!profile ? `./${profile.organizationId}/public_key`:"";
  const getPpcSignatory = profile => profile.ppcSignatory.toString();
  const ppcSignatory = S.fromEither ("n/a") (S.encase (getPpcSignatory) (profile))


  const check_practices_url = !!profile ? `https://check-payment-practices.service.gov.uk/search?q=${(profile.name+"          ").slice(0,10)}` : "";
  const mail_body = !! profile ? `We are experiencing supply chain bullying from an organization with Bmbix Id: ${profile.organizationId}` : "";
  const mailto_url = !!profile ? `mailto:enquiries@smallbusinesscommissioner.gov.uk?sbject=Supply chain bullying&body=${mail_body}` : "";

  const addressLinks = (!!profile && profile.addresses.length > 0) ?
    <ul>
    {S.map (adr => <li key={adr.addressId}><Link to={`../address/${adr.addressId}`}>{adr.displayName}</Link></li>) (profile.addresses)}
    </ul>
    : <p>No related addresses yet.</p>;

  const input = display === INPUT ? (
    <p>Ready to fetch profile</p>
  ): null;

  const waiting = display === WAITING ? (
    <p>Working ...</p>
  ): null;

  const success = ((display === SUCCESS) && (!!profile)) ? (
    <Row>
    <Col>
    <h2>QR Code</h2>
    <BmbixQR
      uri={`https://client.bmbix.com/profiles/address/${profile.addressId}`}
      title="organization"
    />
    </Col>
    <Col>
    <h2>Name</h2>
    <OrganizationProfileWidget organizationProfile={profile} />
    <hr/>
    <h2>Bmbix Organization Id</h2>
    <p>{profile.organizationId}<CopyClip clip={profile.organization}/></p>
    <hr/>
    <h2>Related Addresses</h2>
    {addressLinks}
    <hr/>
    <h2>Prompt Payment Code</h2>
    <p>Signed PPC: {ppcSignatory}</p>
    <p><a href={check_practices_url} target="_blank">Check Payment Practices</a> <LinkExternalIcon/></p>
    <p><a href={mailto_url} target="_blank">Contact Small Business
    Commissioner</a> <MailIcon/></p>
    <hr/>
    </Col>
    <Col>
    <h2>Public Key</h2>
    <Link to={publicKeyPath}>public key</Link>
    <hr/>
    <h2>Identity Validation Certificate</h2>
    <Link to={certificatePath}>certificate</Link>
    <hr/>
    </Col>
    </Row>
  ): null;

  const failure = display === FAILURE ? (
    <p>Ooops!, something went wrong</p>
  ): null;

  return (
    <>
    <h1>Organization Profile</h1>
    <hr/>
    {input}
    {waiting}
    {success}
    {failure}
    </>
  );
}

export {
  View,
}
