// @flow

import {
  createElement as el,
} from "react";
import {promise} from "fluture";
import * as S from "sanctuary";

import {
  assign,
  createMachine,
} from "xstate";
import {
  useMachine,
} from "@xstate/react";
import { useToken } from "./../../../../State";

import { Button_, Modal_} from "./view.js";
import { addMessageContentType } from "./../../api.js";

const viewSpecification = {
  id: "buttonOrModal",
  initial: "button",
  states: {
    button: {
      on: {
        SHOW_MODAL: "modal",
      }
    },
    modal: {
      on: {
        HIDE_MODAL: "button",
      }
    },
  }
}


const api =
  (token: string) =>
  (organizationId: string) =>
  (name: string) =>
  (b64Schema: string) =>
  (visibility: Array<string>): Promise<MesssageContentType> =>
  promise(addMessageContentType(token)(organizationId)(name)(b64Schema)(visibility))


const apiSpecification = {
  id: "MartletOrganizationApi",
  initial: "unconfigured",
  context: {
    organizationId: undefined,
  },
  states: {
    unconfigured: {
      on: {
        CONFIGURE: {
          target: "idle",
          actions: assign({
            organizationId: (c, e, m) => e.organizationId
          }),
        }
      },
    },
    idle: {
      on: {
        START: "working",
      }
    },
    working: {
      invoke: {
        id: "addMessageContentType",
        src: (c, e) => api(e.token)(c.organizationId)(e.name)(e.b64Schema)(e.visibility),
        onDone: {
          target: "succeeded",
        },
        onError: {
          target: "failed",
        },
      },
    },
    succeeded: {
      on: {
        RESET: "idle",
      },
    },
    failed: {
      on: {
        RESET: "idle",
      },
    },
  },
};

const apiStateMachine = createMachine(apiSpecification);
const viewStateMachine = createMachine(viewSpecification);

const Controller = (props: {
  organizationId: string,
}) => {
  const {
    organizationId,
  } = props;

  const token = useToken()
  const [ apiState, apiSend ] = useMachine(apiStateMachine);
  const [ viewState, viewSend ] = useMachine(viewStateMachine);

  apiSend({type: "CONFIGURE", organizationId})

  return viewState.value === "button"
  ? el(
    Button_,
    {
      onClick: () => {viewSend({type: "SHOW_MODAL" })},
    },
  )
  : el(
    Modal_,
    {
      onHide: () => viewSend({type: "HIDE_MODAL"}),
      onSubmit: (data) => apiSend({
        type: "START",
        token,
        name: data.name,
        b64Schema: btoa(data.schema),
        visibility: S.map (S.trim) (S.splitOn (",") (data.visibility)),
      }),
      apiState,
    },
  );
}


export {
  Controller,
}

