import React from "react";

import {
  connect,
} from "react-redux";

import {
  callDelete,
  insert,
  query,
  select,
  test,
} from "./Authn.js";


class KashflowV1AuthnApi {
  constructor (args) {
    this.token = args.token;
  }

  insert (args) {
    const args_ = {token: this.token, ...args}
    return insert(args_);
  }

  callDelete(args) {
    const args_ = {token: this.token, ...args}
    return callDelete(args_);
  }

  query(args) {
    const args_ = {token: this.token, ...args}
    return query(args_);
  }

  select(args) {
    const args_ = {token: this.token, ...args}
    return select(args_);
  }

  test(args) {
    const args_ = {token: this.token, ...args}
    return test(args_);
  }
}


const withKashflowV1AuthnApi = (ComponentFunc) => {

  const BmbixFunc = (connectedProps) => {
    const kashflowV1AuthnApi = new KashflowV1AuthnApi({token: connectedProps.token});
    const {token, ...userProps} = connectedProps;
    const processedProps = {...userProps, kashflowV1AuthnApi};

    return (
      <ComponentFunc {...processedProps} />
    )
  }
  const mapStateToProps = (state) => {
    return {
      token: state.token
    }
  }
  return connect(mapStateToProps)(BmbixFunc);

}


export {
  withKashflowV1AuthnApi,
}

