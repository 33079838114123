// @flow
import { createElement as el, useState } from "react";
import { fork } from "fluture";

import { useToken } from "./../../../State";

import { searchOrganizations, searchUsers } from "./../../Api";
import { useCatchExpiredToken } from "./../../../State/Token.js";


import { View } from "./view.js";

const Controller = (props: {

}) => {
  const token = useToken();
  const catchExpiredToken = useCatchExpiredToken();
  const [ searchState, setSearchState ] = useState("INACTIVE");
  const [ terms, setTerms ] = useState();
  const [ type, setType ] = useState();
  const [
    organizationProfiles,
    setOrganizationProfiles,
  ] = useState([]);
  const [
    userProfiles,
    setUserProfiles,
  ] = useState([]);

  const onChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "type":
        setType(value);
        break;
      case "terms":
        setTerms(value);
        break;
      default:
        console.log("boom")

    }
  }

  const onSubmit = e => {
    setSearchState("RUNNNING");
    console.log("e", e, "terms", terms, "type", type);
    if (type === "organization") {
      const action = searchOrganizations({ token, terms });
      fork(error =>
        {
          console.log("error", error);
          catchExpiredToken(error);
          setSearchState("COMPLETE");
        }

      )(organizationProfiles  =>
      {
        console.log("organizationProfiles", organizationProfiles);
        setOrganizationProfiles(organizationProfiles);
        setUserProfiles([]);
        setSearchState("COMPLETE");
      })(action)
    } else if (type === "user") {
      const action = searchUsers({ token, terms })
      fork(error =>
        {
          console.log("error", error)
          catchExpiredToken(error);
          setSearchState("COMPLETE");
        }
      )(userProfiles  => {
        console.log("userProfiles", userProfiles);
        setUserProfiles(userProfiles);
        setOrganizationProfiles([]);
        setSearchState("COMPLETE");
      })(action)
    } else {
      console.log("the type was not handled");
    }
  }

  const onKeyPress = event => {
    if(event.key === 'Enter'){
      event.preventDefault();
      onSubmit(event);
    }
  }

  return el(
    View,
    {
      data: terms,
      onChange,
      onSubmit,
      onKeyPress,
      userProfiles,
      organizationProfiles,
      searchState,
    },
  )
}

export { Controller }
