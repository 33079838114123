import {createElement as el } from "react";
import log from "loglevel";

import {
  type OrganizationProfile,
} from "../../Entity/Types.js";

import {
  View,
} from "./View.js";

const logger = log.getLogger("Profiles");

const Controller = (props: {
  organizationProfile: OrganizationProfile,
}) => {
  const {
    organizationProfile,
  } = props;
  logger.debug("organizationProfile_", organizationProfile);

  return el(
    View,
    {
      name: organizationProfile.name,
      organizationId: organizationProfile.organizationId,
      iconUrl: organizationProfile.iconUrl,
      verified: organizationProfile.verified,
    },
  )
}
export {
  Controller,
}
