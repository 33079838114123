// @flow
import { createElement as el, useEffect } from "react";
import { View } from "./modal-view.js";
import { useMachine } from "@xstate/react";
import { createMachine, assign } from "xstate";
import { useAccount } from "./../../../State/Account.js";
import { useToken } from "./../../../State/Token.js";
import {
  getStripeIntent,
} from "./../../../Api/MartletOrganization/Users";


const apiFSM = createMachine({
  id: "apiFSM",
  context: {
    token: undefined,
    userId: undefined,
    stripeIntent: undefined,
    errors: undefined,
  },
  initial: "unconfigured",
  states: {
    unconfigured: {
      on: {
        CONFIGURE: {
          target: "ready",
          actions: assign({
            userId: (c, e, m) => e.userId,
            token: (c, e, m) => e.token
          }),
        }
      },
    },
    ready: {
      on: {
        SUBMIT: "working",
      }
    },
    working: {
      invoke: {
        src: (context, event) => getStripeIntent({token: context.token, userId: context.userId}),
        onDone: {
          target: "success",
          actions: assign({
            stripeIntent: (c, e) => e.data,
          })
        },
        onError: {
          target: "failure",
          actions: assign({
            errors: (c, e) => e.data,
          })
        }
      },
    },
    success: {
      on: {
        RESET: "ready",
        REFRESH: "working",
      }
    },
    failure: {
      on: {
        RESET: "ready"
      }
    },
  },
});
const Controller = () => {
  const account = useAccount();
  const userId = account.sub;
  const token = useToken();
  const [ apiState, apiSend ] = useMachine(apiFSM);

  useEffect(
    () => {
      apiSend({type: "CONFIGURE", userId, token});
      apiSend({type: "SUBMIT"})
    },
    [ apiSend, userId, token ]
  );

  return el (
    View,
    {
      apiState,
    },
  );
}


export {
  Controller,
}
