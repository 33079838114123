// @flow
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { fork } from "fluture";

import { create } from "./../../Api/create-proxy.js";
import { useToken } from "./../../../State";

import { AddForm } from "./../AddForm/view.js";

const AddModal = (props: {
  martletOrganizationApi: Object,
  organizationId: string,
  onCancel: Function,
  onComplete: Function,
  addAccessKey: Function,
}) => {
  const {
    organizationId,
    onCancel,
    onComplete,
  } = props;

  const INPUT = "input";
  const WAITING = "waiting";
  const SUCCESS = "success";
  const FAILURE = "failure";

  const [display, setDisplay] = useState(INPUT);
  const [errorMessage, setErrorMessage] = useState();
  const token = useToken();

  const handleSubmit = (data) => {
    setDisplay(WAITING);
    fork( error => {
      setDisplay(FAILURE);
      setErrorMessage(error.toString());
    })( organizationProxy => {
      console.log("Created this: ", organizationProxy);
      setDisplay(SUCCESS);
    })(
      create (token) (organizationId) (data.nameFromUser)
    )
  }

  const input = display === INPUT ? (
    <AddForm
      onSubmit={handleSubmit}
    />
  ): null;

  const waiting = display === WAITING ? (
    <p>Waiting ...</p>
  ): null;

  const success = display === SUCCESS ? (
    <p>Success!</p>
  ): null;

  const failure = display === FAILURE ? (
    <>
    <p>Ooops! something went wrong.</p>
    <p>{errorMessage}</p>
    </>
  ): null;

  const completeButton = (display === SUCCESS || display === FAILURE ) ? (
    <Button onClick={ e => onComplete() } >Dismiss</Button>
  ): null;

  const cancelButton = (display === INPUT || display === WAITING) ? (
    <Button onClick={ e => onCancel() } >Cancel</Button>
  ): null;

  return (
    <Modal show={true}>
    <Modal.Header closeButton onHide={onCancel}>
      <Modal.Title>Add</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {input}
      {waiting}
      {success}
      {failure}
    </Modal.Body>
    <Modal.Footer>
      {completeButton}
      {cancelButton}
    </Modal.Footer>
    </Modal>
  );
}

export { AddModal }
