// @flow
import React, {
  useState,
} from "react";
import * as R from "ramda";


import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button";
import {
  Spinner,
} from "../Widgets/Toast";
import {
  type BmbixAddress,
} from "../Entity/Types.js";
import {
  withIsimudApi,
} from "../Api/Isimud";

/*
 * submit insertion instructions
 * draw a modal.
 * let the user confirm or cancel.
 * make the api calls.
 * show a spinner.
 * show result.
 * let the user dismiss the modal.
 * call the callBack with any wanted info.
 */

const Modal_ = (props: {
  address: BmbixAddress,
  onCancel?: Function,
  messageIds: Array<string>,
  isimudApi: Object,
}) => {
  const {
    address,
    onCancel,
    messageIds,
    isimudApi,
  } = props;
  const [display, setDisplay] = useState("input");

  const handleInsert = (e) => {
    e.preventDefault();
    setDisplay("waiting");
    const promises = R.map(
      mId => isimudApi.submitInsertionInstruction({
        addressId: address.id,
        organizationId: address.organizationId,
        messageId: mId,
      }),
      messageIds,
    )
    Promise.allSettled(promises).then(
      outcomes => {
        const successes = R.filter(R.propEq("status", "fulfilled"))(outcomes)
        const failures = R.filter(R.propEq("status", "rejected"))(outcomes)
        if(failures.length > 0){
          console.log("boom an insertion submission failed");
          setDisplay("failure");
        } else {
          const instructions = R.map(
            s => s.value,
            successes,
          );
          setDisplay("success");
          console.log("successfully inserted these instructions:", instructions);
        }
      }
    ).catch(
      error => {
        console.log("boom:", error);
        setDisplay("failure");
      }
    );
  }

  const handleClose = (e) => {
    e.preventDefault();
    if(onCancel){
      onCancel();
    }
  }

  return (
      <Modal show={true} >
        <Modal.Header>
          <Modal.Title>Process Messages</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {
              (display === "input" || display === undefined)
              &&
              <p>Process selected messages?</p>
            }
            {
              display === "waiting"
              &&
              <Spinner />
            }
            {
              display === "success"
              &&
              <p>Success!</p>
            }
            {
              display === "failure"
              &&
              <p>Ooops! Looks like something went wrong</p>
            }
          </>

        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Close</Button>
            <Button variant="primary" onClick={handleInsert}>Process</Button>
        </Modal.Footer>
      </Modal>
  );


}

const InsertionInstructionsModal = withIsimudApi(Modal_);

export {
  InsertionInstructionsModal,
}
