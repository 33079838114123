import {
  createElement as el,
  useState,
} from "react";
import {
  connect,
} from "react-redux";

import {
  type BmbixAddress,
} from "../Entity/Types.js";
import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "../Util";
import {
  updateAddress,
} from "../State";
import {
  withMartletOrganizationApi,
} from "../Api/MartletOrganization";
import {
  PurposeModal,
  PurposeButton,
} from "./PurposeEditorView.js";

const Controller = (props: {
  address: BmbixAddress,
  martletOrganizationApi: Object,
  updateAddress: Function,
}) => {
  const {
    address,
    martletOrganizationApi,
    updateAddress,
  } = props;
  const [active, setActive] = useState(false);
  const [display, setDisplay] = useState(INPUT);
  const [purpose, setPurpose] = useState(address.purpose);

  const onClick = e => setActive(true);
  const onSuccessComplete = e => {
    setActive(false);
    setDisplay(INPUT);
  }
  const onFailureComplete = e => {
    setActive(false);
    setDisplay(INPUT);
  }
  const onCancel = e => {
    setActive(false);
    setDisplay(INPUT);
  }
  const onChange = purpose => setPurpose(purpose);
  const onSubmit = e => {
    setDisplay(WAITING);
    martletOrganizationApi.updateAddress({
      addressId: address.id,
      purpose,
    }).then(
      address => {
        setDisplay(SUCCESS);
        updateAddress(address);
      }
    ).catch(
      error => {
        setDisplay(FAILURE);
      }
    );
  }

  return active ? el(
    PurposeModal,
    {display, purpose, onChange, onSubmit, onSuccessComplete, onFailureComplete, onCancel}
  ): el(
    PurposeButton,
    {onClick}
  );
}

const connector = connect(
  null,
  {updateAddress},
)

const PurposeEditorController = connector(withMartletOrganizationApi(Controller));

export {
  Controller,
  PurposeEditorController,
}
