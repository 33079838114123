// @flow

import Future from "fluture";
import * as S from "sanctuary";

import {
  ApiClient,
  OrganizationsApi,
} from "@bmbix/bmb_martlet_organization_client";

import { createOauth2ClientFromGW } from "./adapt.js";
import { type Oauth2Client } from "./../../Entity/Types.js";

const fetch =
  (token: string) =>
  (organizationId: string): Future<Oauth2Client> =>
{
  const apiInstance = new OrganizationsApi();
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;

  return Future((reject, resolve) => {

    apiInstance.listOauth2Clients(organizationId, (error, data, response) => {
      if (error) {
        console.log("error", error);
        reject(error);
      } else {
        const gws = data.oauth2_clients;
        const oauth2Clients = S.map (createOauth2ClientFromGW) (gws);
        resolve(oauth2Clients);
      }
    });

    return console.log;
  });
}


export {
  fetch,
}

