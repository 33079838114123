// @flow
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";


import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "../../Util";

const View = (props: {
  display: string,
  foreignAddressId?: string,
  onChange?: Function,
  onCancel?: Function,
  onSubmit?: Function,
  onDone?: Function,
}) => {
  const {
    display,
    foreignAddressId,
    onCancel,
    onChange,
    onSubmit,
    onDone,
  } = props;

  const body = display === INPUT ? (<>
      <Form role="form">
      <Form.Group controlId="foreignAddressInput">
      <Form.Label>Foreign Address</Form.Label>
      <Form.Control
        type="text"
        name="foreignAddressId"
        onChange={onChange}
        placeholder="Enter new foreign address id"
        value={foreignAddressId}
      />
      </Form.Group>
      </Form>
      <p>This is the insert view</p>
    </>) : display === WAITING ? (<>
      <p>Waiting ...</p>
    </>) : display === SUCCESS ? (<>
      <p>Success view </p>
    </>) : display === FAILURE ? (<>
      <p>Failure view </p>
    </>) : null;

  const footer = display === INPUT ? (<>
      <Button onClick={onCancel} aria-label="Cancel">Cancel</Button>
      <Button onClick={onSubmit} aria-label="Submit">Submit</Button>
    </>) : display === WAITING ? (<>
      <Button onClick={onCancel} aria-label="Cancel">Cancel</Button>
    </>) : display === SUCCESS ? (<>
      <Button onClick={onDone} aria-label="Done">Done</Button>
    </>) : display === FAILURE ? (<>
      <Button onClick={onDone} aria-label="Done">Done</Button>
    </>) : null;

  return (
    <>
    <Modal show={true}>
    <Modal.Header>Edit Foreign Address</Modal.Header>
    <Modal.Body>
      {body}
    </Modal.Body>
    <Modal.Footer>
      {footer}
    </Modal.Footer>
    </Modal>
    </>
  );

}


export {
  View,
}
