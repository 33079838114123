// @flow
import React, {
  useState,
} from "react"
import {
  Row,
  Col,
} from 'react-bootstrap'
import {withRouter} from "react-router-dom";
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Figure from "react-bootstrap/Figure"
import log from "loglevel";

import {
  withMartletOrganizationApi,
} from "../Api/MartletOrganization";

const logger = log.getLogger("Organization:IconView");

const View = (props: {
  martletOrganizationApi: Object,
  match: Object,
}) => {
  const {
    match,
    martletOrganizationApi,
  } = props;
  const organizationId = match.params.organizationId;
  const [src, setSrc] = useState(`https://bmbix-images.s3.eu-west-2.amazonaws.com/organization/${organizationId}`);
  const [file, setFile] = useState();
  const [revision, setRevision] = useState(0)

  const selectFile = (event: any): void => {
    const file = event.target.files[0];
    logger.debug("file:", file);
    setFile(file);
  }

  const sendFile = (event: any): void => {
    if (!!file) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const data_ = fileReader.result;
        logger.debug("data_: ", data_);
        martletOrganizationApi.setIcon({
          organizationId: organizationId,
          data: Buffer.from(data_).toString("base64"),
          mediaType: file.type,
        }).then(
          null_ => {
            logger.debug("Set logo: ", null_);
            setRevision(prev => prev + 1);
          }
        )
      }
      fileReader.readAsArrayBuffer(file);
    }
    logger.debug("No file to send");
  }

  return (
    <>
    <h2>Organization Icon</h2>
    <hr/>
    <Row>
    <Col>
    <Figure>
      <Figure.Image
        height={120}
        src={`${src}?${revision}`}
      />
      <Figure.Caption>Your current logo</Figure.Caption>
    </Figure>
    </Col>
    <Col>
    <h3>Update</h3>
    <p>Dimensions: 300 x 300 px</p>
    <p>Format: png gif svg</p>
    <Form>
    <Form.Group>
      <Form.Label>Select file</Form.Label>
        <Form.Control
          id="fileUpload"
          type="file"
          onChange={selectFile}
        />
    </Form.Group>
    </Form>
    <Button onClick={sendFile}>Upload</Button>
    <p>Revision {revision}</p>
    <hr/>
    </Col>
    </Row>
    </>
  );
}

const IconView = withMartletOrganizationApi(withRouter(View));

export {
  IconView,
}
