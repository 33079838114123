// @flow

import {
  createElement as el,
  useState,
} from "react";
import {
  connect,
} from "react-redux";
import log from "loglevel";

import {
  type Settings,
} from "../../../Entity/Types.js";
import {
  instanceService,
} from "../../../Entity/InstanceService";
import {
  createMachine,
} from "xstate";
import {
  useMachine,
} from "@xstate/react";

import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "../../../Util";
import {
  setSettings,
} from "../../../State";
import { View } from "./View.js";
import { useAccount } from "./../../../State/Account.js";
import { useToken } from "./../../../State/Token.js";
import { setName } from "./../../../Api/MartletOrganization/Users.js";


const machineSpecification = {
  id: "setName",
  initial: "idle",
  context: {},
  states: {
    idle: {
      on: {
        START: "working",
      }
    },
    working: {
      on: {
        SUCCESS: "succeeded",
        FAILURE: "failed",
      }
    },
    succeeded: {
      on: {
        RESET: "idle",
      },
    },
    failed: {
      on: {
        RESET: "idle",
      },
    },
  },
};

const stateMachine = createMachine(machineSpecification);

const logger = log.getLogger("Account.Controller");

const updateSettingsName = settings => name => !!settings ? instanceService.createSettings({
    userId: settings.userId,
    name: name,
    verified: settings.verified,
    verifiedName: settings.verifiedName,
    alias: settings.alias,
    email: settings.email,
    language: settings.language,
    timezone: settings.timezone,
    iconUrl: settings.iconUrl,
}): instanceService.createSettings({
    name: name,
})


const Controller = (props: {
  name: string,
  show: boolean,
  onComplete: Function,
  settings: Settings,
  setSettings: Function,
}) => {
  const {
    show,
    onComplete,
    name,
    settings,
    setSettings,
  } = props;

  const [ state, send ] = useMachine(stateMachine);
  const token = useToken();
  const account = useAccount();
  const userId = account.id;

  const handleCancel = () => {
    onComplete(true);
    send("RESET");
  }

  const handleSubmit = ({accountName}) => {
    send("START");
    setName({token, userId, name: accountName}).then(
      settings_ => {
        send(SUCCESS);
        const updatedSettings = updateSettingsName(settings)(accountName);
        setSettings(updatedSettings);
      }
    ).catch(
      error => {
        send(FAILURE);
        logger.error("Error setting name", error);
      }
    );
  }

  return show ? el(
    View,
    {
      onClose: handleCancel,
      onSubmit: handleSubmit,
      name,
      state: state.value,
    },
  ): null;
}

const connector = connect(
  state => {
    return {
      settings: state.settings,
    }
  },
  {setSettings},
)

const Controller_ = connector(Controller);

export {
  Controller_ as View,
}

