// @flow

import React, {
} from "react";
import * as R from "ramda";
import Form from "react-bootstrap/Form";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import {
  Link,
  withRouter,
} from "react-router-dom";
import {
  BookIcon,
  CircleSlashIcon,
  WorkflowIcon,
  MentionIcon,
  PeopleIcon,
  TagIcon,
} from "@primer/octicons-react";


import type {
  AccountingSystem,
} from "../Entity/Types";
import { addCheckedProperty } from "../Util";


type Props = {
  match: Object,
  accountingSystems: Array<AccountingSystem>,
  toggleSelectHandler: Function,
  checkedItems: Array<string>,
}
const AccountingSystemsList = (props:Props) => {

  const decoratedItems = addCheckedProperty(props.accountingSystems, props.checkedItems);

  const columns = [
    {
      maxWidth: 20,
      Cell:  ({row}) => {
        return (
          <Form.Check
            id={row.id}
            name={row.id}
            key={row.id}
            value={row.id}
            onChange={props.toggleSelectHandler}
            checked={row._original.checked}
          />
        )
      }
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({row}) => {
        const path = `${props.match.url}/${row.id}`;
        return (
          <Link
            to={{pathname: path}}
          ><MentionIcon /> {row._original.displayName}</Link>
        )
      },
    },
    {
      Header: "",
      accessor: "id",
      Cell:  ({row}) => {
        const path = `${props.match.url}/${row.id}/team`
        return (
          <Link to={{pathname: path}} ><PeopleIcon /> Team</Link>)
      }
    },
    {
      Header: "",
      accessor: "id",
      Cell:  ({row}) => {
        const path = `${props.match.url}/${row.id}/access-lists`
        return (
          <Link to={{pathname: path}} ><CircleSlashIcon/> Access Lists</Link>)
      }
    },
    {
      Header: "",
      accessor: "id",
      Cell:  ({row}) => {
        const path = `${props.match.url}/${row.id}/message-types`
        return (
          <Link to={{pathname: path}} ><TagIcon/> Message Types</Link>)
      }
    },
    {
      Header: "",
      accessor: "id",
      Cell:  ({row}) => {
        const path = `${props.match.url}/${row.id}/platform`
        if(row.ledgerType==="purchase"){
          return null;
        }
        else {
          return (
            <Link to={{pathname: path}} ><WorkflowIcon /> Platform Assignment</Link>)
        }
      }
    },
    {
      Header: "",
      accessor: "id",
      Cell:  ({row}) => {
        const path = `${props.match.url}/${row.id}/address-book`
        return (
          <Link to={{pathname: path}} ><BookIcon/> Address Book</Link>)
      }
    },
]
return (
    <div>
    <ReactTable
      data={ decoratedItems }
      columns={ columns }
      defaultPageSize={ 10 }
      className="-striped -highlight"
    />
    </div>
  )
}


const mapStateToPropsOpen = (state, ownProps) => {
  return {
    accountingSystems: R.filter(ad => {
      return (
        ad.organizationId===ownProps.organizationId
        &&
        ad.isClosed === false
      );
    }, state.addresses),
  }
}

const withAddresses = (wrappedComponent) => {

  const replacementComponent = (props: any) => {
    return wrappedComponent(props);
  }
  return replacementComponent;
}

const AccountingSystemsListOpen = withAddresses(withRouter(AccountingSystemsList));
const AccountingSystemsListClosed = withAddresses(withRouter(AccountingSystemsList));

export {
  AccountingSystemsList,
  AccountingSystemsListOpen,
  AccountingSystemsListClosed,
}
