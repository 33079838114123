// @flow
import React from "react";
import ReactTable from "react-table-6"
import { Link, useLocation } from "react-router-dom";

import { MessageContentType } from "./../entity.js";


const MessageContentTypesTable = ( props: {
  data: Array<MessageContentType>,
}) => {
  const { data } = props;
  const { pathname} = useLocation();

  const columns = [
    {
      Header: "Id",
      accessor: "id",
      Cell: ({original}) => {
        const toLink = `${pathname}/${original.id}`;
        return <Link to={toLink}>{original.id}</Link>
      }
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Visibility",
      accessor: "visibility",
    },
  ];

  return <ReactTable
          className="-striped -highlight"
          data={data}
          columns={columns}
          defaultPageSize={10}
        />
}

export {
  MessageContentTypesTable,
}
