import * as S from "sanctuary";

const appendIfNotPresent = list => value => {
  return S.unless (S.elem (value)) (S.append (value)) (list)
}

const uniq = list => S.reduce(appendIfNotPresent) ([]) (list)

const getUniqueAddressIds = extractor => items => {
  return uniq (S.map (extractor) (items))
}

const getAlias = aliases => id => {
  const maybeAliasObj = S.find (a => a.id === id) (aliases);
  const maybeAlias = S.chain (S.value ("alias")) (maybeAliasObj);
  const unwrappedAlias = S.maybeToNullable (maybeAlias);
  return unwrappedAlias;
}

const mergeItemWithAlias = extractor => aliases => item => {
  return {obj: item, alias: getAlias (aliases) (extractor(item))}
}

const merge = extractor => aliases => items => {
  return S.map(item => mergeItemWithAlias (extractor) (aliases) (item)) (items)
}

const decorateWithAlias = api => extractor => async items => {
  const addressIds = getUniqueAddressIds (extractor) (items);
  console.log("XXXaddressIds", addressIds);
  const aliases = await api.getAliases({addressIds});
  console.log("XXXaliases", aliases);
  return merge (extractor) (aliases) (items);
}



const unique = keyFunc => sequence => S.values (S.fromPairs (S.map (x => S.Pair (keyFunc(x)) (x)) (sequence)))

const generateAlias = (ap: AddressProfile) => `${S.prop ("name") (S.prop ("organizationProfile") (ap))}-${S.prop ("displayName") (ap)}`;

const generateAliasMap = (aps: Array<AddressProfile>) => {
  const generateKey = ap => S.prop ("addressId") (ap);
  const makePair =
    keyFunc =>
    valueFunc =>
    baseObj =>
    S.Pair (keyFunc(baseObj)) (valueFunc(baseObj));
  const pairs = S.map (makePair(generateKey)(generateAlias)) (aps);
  const aliasMap = S.fromPairs (pairs)
  return aliasMap;
}


export {
  unique,
  generateAliasMap,
  generateAlias,
  appendIfNotPresent,
  uniq,
  getUniqueAddressIds,
  getAlias,
  mergeItemWithAlias,
  merge,
  decorateWithAlias,
}
