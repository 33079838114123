// @flow
import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "../../../Util";

const View = (props: {
  display: string,
    onSuccess: Function,
    onSubmit: Function,
    onCancel: Function,
    onFailure: Function,
}) => {
  const {
    display,
    onSuccess,
    onSubmit,
    onCancel,
    onFailure,
  } = props;

  let content, buttons;
  switch (display) {
    case INPUT:
      content = <p>Do you want to disconnect&#63;</p>
      buttons = <>
        <Button onClick={e => onSubmit()} className="mr-2">Submit</Button>
        <Button onClick={onCancel}>Cancel</Button>
        </>
      break;
    case WAITING:
      content = <p>Waiting ...</p>
      buttons = <>
        <Button onClick={onCancel}>Cancel</Button>
        </>
      break;
    case SUCCESS:
      content = <p>All done!</p>
      buttons = <>
        <Button onClick={onSuccess}>Done (Success)</Button>
        </>
      break;
    case FAILURE:
      content = <p>Ooops, something went wrong</p>
      buttons = <>
        <Button onClick={onFailure}>Done (Failure)</Button>
        </>
      break;
    default:
      throw new Error("Unhandled value for display");
  }

  return (
    <Modal show={true}>
      <Modal.Header>Disconnect</Modal.Header>
      <Modal.Body>
       {content}
      </Modal.Body>
      <Modal.Footer>
       {buttons}
      </Modal.Footer>
    </Modal>
  )
}

export {
  View,
}
