// @flow
import React, {
  useState,
} from "react";
import {
  Row,
  Col,
} from 'react-bootstrap'
import * as R from "ramda";
import {
  connect,
} from "react-redux";
import {
  withRouter,
} from "react-router";
import {
  Link,
} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import log from "loglevel";
import * as S from "sanctuary";
import * as $ from "sanctuary-def";

import {
  type Organization,
} from "../Entity/Types";
import {
  withMartletOrganizationApi,
} from "../Api/MartletOrganization";
import {
  updateOrg,
} from "../State";
import {
  SicCodeWidget,
  HeadcountWidget,
  PpcSignatoryWidget,
  PaymentContactEmailWidget,
} from "./Widgets";
import { BmbixIcon } from "./../Widgets/";
import { CopyClip } from "./../Widgets/copy-clip.js";


const logger = log.getLogger("OrganizationPanel");

const INPUT = "INPUT";
const WAITING = "WAITING";
const SUCCESS = "SUCCESS";
const FAILURE = "FAILURE";

const InputForm = (props: {
  onChange: Function,
  organization: Organization,
}) => {
  const {
    organization,
  } = props;
  logger.debug("[OrganizationPanel] InputForm organization:", organization);
  return (
    <Form>
    <Form.Group>
    <Form.Label>Change status to {(!organization.isClosed).toString()}</Form.Label>
    </Form.Group>
    </Form>
  );
}

const connector2 = connect(
  null,
  {updateOrg},
);

const ToggleClosed = connector2(withMartletOrganizationApi((props: {
  organization: Organization,
  martletOrganizationApi: Object,
  updateOrg: Function,
}) => {
  const {
    martletOrganizationApi,
    organization,
    updateOrg,
  } = props;
  const [display, setDisplay] = useState(INPUT);
  const [show, setShow] = useState(false);

  const handleSubmit = (e) => {
    setDisplay(WAITING);
    martletOrganizationApi.updateOrganization({
      organizationId: organization.id,
      isClosed: !organization.isClosed,
    }).then(
      organization => {
        updateOrg(organization);
        setDisplay(SUCCESS);
      }
    ).catch(
      error => {
        setDisplay(FAILURE);
      }
    );
  }

  const handleChange = () => {}
  const handleCancel = (e) => {
    setDisplay(INPUT);
    setShow(false);
  }
  const handleComplete= (e) => {
    setDisplay(INPUT);
    setShow(false);
  }

  const input = display === INPUT ? (
    <InputForm
      organization={organization}
      onChange={handleChange}
    />
  ): null;

  const waiting = display === WAITING ? (
    <p>Waiting ...</p>
  ): null;

  const success = display === SUCCESS ? (
    <p>Success!</p>
  ): null;

  const failure = display === FAILURE ? (
    <p>Ooops, something has gone wrong</p>
  ): null;

  const submitButton = display === INPUT ? (
    <Button onClick={handleSubmit}>Submit</Button>
  ): null;

  const cancelButton = display === INPUT || display === WAITING ? (
    <Button onClick={handleCancel}>Cancel</Button>
  ): null;

  const doneButton = display === FAILURE || display === SUCCESS ? (
    <Button onClick={handleComplete}>Done</Button>
  ): null;

  return (
    <>
    <Modal show={show}>
    <Modal.Header></Modal.Header>
    <Modal.Body>
    {input}
    {waiting}
    {success}
    {failure}
    </Modal.Body>
    <Modal.Footer>
    {submitButton}
    {cancelButton}
    {doneButton}
    </Modal.Footer>
    </Modal>
    <Button onClick={(e) => {setShow(true)}}>Toggle</Button>
    </>
  );
}));




const OrganizationPanel = (props:{
  organization: Organization,
}) => {
  const {
    organization
  } = props;

  const sicCode = S.fromMaybe ("n/a") (
    S.get (sc => S.not((S.is ($.Undefined) (sc)))) ("sicCode") (organization)
  )

  const headcount = S.maybe ("n/a") (x => x.toString()) (
    S.get (h => S.is ($.Object) (h)) ("headcount") (organization)
  )
  const getPpcSignatory = organization => organization.ppcSignatory.toString();
  const ppcSignatory = S.fromEither ("n/a") (S.encase(getPpcSignatory) (organization));
  const paymentContactEmail = S.fromMaybe ("n/a") (
    S.get (pce => S.is ($.String) (pce)) ("paymentContactEmail") (organization)
  );

  return (
    <>
    <div className="clearfix">
    <h2 className="float-left">{organization.name}</h2>
    <Link className="align-middle d-inline-flex ml-2 mt-2" to={`/profiles/organization/${organization.id}`} >public profile</Link>
    </div>
    <hr/>
    <Row>
    <Col>
    <h3>Bmbix Id</h3>
    <p>{organization.id} <CopyClip clip={organization.id} /></p>
    <hr/>
    <h3>Status</h3>
    <p>Is closed: {organization.isClosed.toString()}</p>
    <ToggleClosed organization={organization}/>
    <hr/>
    </Col>
    <Col>
    <h3>SIC</h3>
    <p>SIC Code: {sicCode}</p>
    <SicCodeWidget  organization={organization}/>
    <hr/>
    <h3>Headcount</h3>
    <p>Headcount: {headcount}</p>
    <HeadcountWidget organization={organization}/>
    <hr/>
    <h3>Prompt Payment Code</h3>
    <BmbixIcon name="prompt-payment-code-icon" size="32"/>
    <p>Signed PPC: {ppcSignatory}</p>
    <PpcSignatoryWidget organization={organization} />
    <hr/>
    <h3>Payment Contact</h3>
    <p>Email: <a
    href="mailto:{paymentContactEmail}">{paymentContactEmail}</a></p>
    <PaymentContactEmailWidget organization={organization}/>
    <hr/>
    </Col>
    </Row>
    </>
  );
}

const connector = connect(
  (state, ownProps) => {
    const id = ownProps.match.params.organizationId;
    return {
      organization: R.find(R.propEq("id", id))(state.orgs),
    }
  }
);

export default withRouter(connector(OrganizationPanel));
