// @flow


import * as R from "ramda";

import {
  type AddressBookEntry,
} from "../Entity/Types.js";
import {
  instanceService,
} from "../Entity/InstanceService.js";

const ADD_ADDRESS_BOOK_ENTRIES = "ADDRESS_BOOK_ENTRIES_ADD";
const ADD_ADDRESS_BOOK_ENTRY = "ADDRESS_BOOK_ENTRY_ADD";
const DEL_ADDRESS_BOOK_ENTRY = "ADDRESS_BOOK_ENTRY_DELETE";
const UPDATE_ADDRESS_BOOK_ENTRY = "ADDRESS_BOOK_ENTRY_UPDATE";
const UPDATE_FOREIGN_ADDRESS_ID= "UPDATE_FOREIGN_ADDRESS_ID";


const addAddressBookEntries = (addressBookEntries: Array<AddressBookEntry>) => {
  return {
    type: ADD_ADDRESS_BOOK_ENTRIES,
    addressBookEntries: addressBookEntries,
  }
}
const addAddressBookEntry = (addressBookEntry: AddressBookEntry) => {
  return {
    type: ADD_ADDRESS_BOOK_ENTRY,
    addressBookEntry: addressBookEntry,
  }
}
const deleteAddressBookEntry = (addressBookEntryId: string) => {
  return {
    type: DEL_ADDRESS_BOOK_ENTRY,
    addressBookEntryId: addressBookEntryId,
  }
}
const updateAddressBookEntry = (addressBookEntry: AddressBookEntry) => {
  return {
    type: UPDATE_ADDRESS_BOOK_ENTRY,
    addressBookEntry: addressBookEntry,
  }
}
const updateForeignAddressId = (
  addressBookEntryId: string,
  foreignAddressId: string,
) => {
  return {
    type: UPDATE_FOREIGN_ADDRESS_ID,
    addressBookEntryId: addressBookEntryId,
    foreignAddressId: foreignAddressId,
  }
}


const addressBookEntries = (state: Array<AddressBookEntry>=[], action: Object) => {
  switch (action.type) {
    case ADD_ADDRESS_BOOK_ENTRIES:
      return R.uniq(R.concat(action.addressBookEntries, state));
    case ADD_ADDRESS_BOOK_ENTRY:
      return R.uniq(R.append(action.addressBookEntry, state));
    case DEL_ADDRESS_BOOK_ENTRY:
      return R.filter(x => x.addressBookEntryId !== action.addressBookEntryId, state);
    case UPDATE_ADDRESS_BOOK_ENTRY:
      return R.uniq(R.append(action.addressBookEntry, R.filter(x => x.addressBookEntryId !== action.addressBookEntry.addressBookEntryId, state)));
    case UPDATE_FOREIGN_ADDRESS_ID:
      const entry = R.find(R.propEq("addressBookEntryId", action.addressBookEntryId), state);
      const updatedEntry = instanceService.createAddressBookEntry({
        ...entry,
        foreignAddressId: action.foreignAddressId,
      });
      const updatedState = R.uniq(R.append(updatedEntry, R.filter(x => x.addressBookEntryId !== action.addressBookEntryId, state)));
      return updatedState;
    default:
      return state;
  }
}

export {
  addressBookEntries,
  addAddressBookEntries,
  addAddressBookEntry,
  updateAddressBookEntry,
  deleteAddressBookEntry,
  updateForeignAddressId,
}

