// @flow
import React from "react";

import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

import { type Certificate } from "./../../entity.js";

import RawViewer from "./../../../Widgets/Certificate/RawViewer";
import Viewer from "./../../../Widgets/Certificate/Viewer";

const HeaderTable = (props: {
  certificate: Certificate,
}) => {
  const {
    certificate,
  } = props;

  return (
    <Table striped bordered hover>
      <tbody>
        <tr>
          <th>Cert Id</th>
          <td>{certificate.certId}</td>
        </tr>
        <tr>
          <th>Subject URI</th>
          <td>{certificate.subjectURI}</td>
        </tr>
        <tr>
          <th>Date Issued</th>
          <td>{certificate.dateIssued}</td>
        </tr>
        <tr>
          <th>Issuer URI</th>
          <td>{certificate.issuerURI}</td>
        </tr>
        <tr>
          <th>Validator URI</th>
          <td>{certificate.validatorURI}</td>
        </tr>
        <tr>
          <th>Date Rescinded</th>
          <td>{certificate.dateRescinded}</td>
        </tr>
        <tr>
          <th>Rescinder URI</th>
          <td>{certificate.rescinderURI}</td>
        </tr>
      </tbody>
    </Table>
  )
}

const View = (props: {
  certificate?: Certificate,
  organizationId: string,
}) => {

  const {
    certificate,
    organizationId,
  } = props;

  const path = !! certificate ? `/v/organizations/${organizationId}/certificates/${certificate.certId}/rescind`: "" ;

  return !!certificate ? <>
    <h2>Certificate</h2>
    <Link to={path}><Button>Rescind</Button></Link>
    <HeaderTable certificate={certificate}/>
    <Viewer pem={certificate.data} />
    <RawViewer
      data={certificate.data}
      subjectName={certificate.subjectURI}
      certificateId={certificate.certId}
    />
    </> : null;
}

export { View };
