// @flow
import { createElement as el } from "react";

import { useParams } from "react-router-dom";

import { View } from "./view";

const Controller = (props: {

}) => {
  const { addressId } = useParams();


  return el(
    View,
    {
      address: addressId,
    }
  )
}
export { Controller }
