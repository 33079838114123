// @flow
import { createElement as el } from "react";

import { type Certificate } from "../../entity.js";

import { View } from "./view.js";


// import { createCertificate } from "../../entity.js";

const Controller = (props: {
  certificates: Array<Certificate>,
  organizationId: string,
}) => {

  const {
    certificates,
    organizationId,
  } = props;

  /*
  const organizationId = "abcdabcd-abcd-abcd-abcd-abcdabcd9999";

  const certificates = [
    createCertificate({
      certId: "abcdabcd-abcd-abcd-abcd-abcdabcdabcd",
      subjectURI: "bmbix://user/abcdabcd-abcd-abcd-abcd-000100020003",
      dateIssued: "2021-07-23T12:00:00",
      issuerURI: `bmbix://organization/${organizationId}`,
      validatorURI: "bmbix://organization/abcdabcd-abcd-abcd-abcd-000100020005",
    }),
    createCertificate({
      certId: "abcdabcd-abcd-abcd-abcd-abcdabcd0001",
      subjectURI: "bmbix://user/abcdabcd-abcd-abcd-abcd-000200020003",
      dateIssued: "2021-07-21T12:00:00",
      issuerURI: `bmbix://organization/${organizationId}`,
      validatorURI: "bmbix://organization/abcdabcd-abcd-abcd-abcd-000100020005",
    }),
  ];
  */

  return el (
    View,
    {
      certificates,
      organizationId,
    }
  )
}

export { Controller }

