// @flow
import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import log from "loglevel";
import logo from "../../src/bmbix-logos/Colour/bmbix round colour.svg"

import {
  INPUT,
  WAITING,
  FAILURE,
} from "../Util";

const logger = log.getLogger("Login");

const baseLoginUrl = "https://auth2.bmbix.com/login?client_id=1lpdc7hab1qtjcbb4hlac9ci4u&response_type=token&scope=aws.cognito.signin.user.admin+email+https://52mu5p1iw3.execute-api.eu-west-2.amazonaws.com/message.submit+openid+phone+profile&redirect_uri=";

const makeLoginUrl = redirectUri => `${baseLoginUrl}${redirectUri}`


const View = (props: {
  display: string,
  redirectUri?: string,
}) => {
  const {
    redirectUri,
    display,
  } = props;


  if (!!redirectUri) {
    window.localStorage.setItem("gotoUrl", redirectUri);
    logger.error("Login redirectUri prop WAS set", redirectUri);
  } else {
    logger.error("Login redirectUri prop was not set");
  }

  const origin = window.location.origin;
  const loginUrl = !!redirectUri ?
    makeLoginUrl(`${origin}/goto-location`) :
    makeLoginUrl(`${origin}`);

  const waiting = display === WAITING ? (
    <p>Logging you in</p>
  ): null;

  const failure = display === FAILURE ? (
    <>
    <h1>Session Expired</h1>
    <a href={loginUrl}>Log in again</a>
    </>
  ): null;

  const input = display === INPUT ? (
    <Container>
    <Row>
    <Col className="justify-content-center d-flex mb-5">
    <img style={{width: "18%"}} src={logo}  alt="Bmbix logo"/>
    </Col>
    </Row>
    <Row>
    <Col className="justify-content-center d-flex">
    <a href={loginUrl}><Button className="btn-lg">Log in</Button></a>
    </Col>
    </Row>
    </Container>
  ): null;

  return (
    <Container fluid={true}>
    <Row>
    <Col>
    <div className="d-flex align-items-center justify-content-center" style={{height: "40vh"}}>
    {input}
    {waiting}
    {failure}
    </div>
    </Col>
    </Row>
    </Container>
  )
}

export {
  View,
}
