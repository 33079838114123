// @flow
//
import React, {
  createElement as el,
} from "react";

import {
  TenantMapping,
} from "./../../../Entity/Types.js";

const Controller = (props: {
  tenantMapping: TenantMapping,
}) => {

  return el(
    View,
    {
      tenantMapping: props.tenantMapping,
    }
  );
}


const View = (props: {
  tenantMapping: TenantMapping,
}) => {
  return <p>XeroTenantId: {props.tenantMapping.xeroTenantId}</p>
}


export {
  Controller,
  View,
  Controller as TenantMappingShow,
}
