// @flow

import * as R from "ramda";

import {
  ApiClient,
  InsertionsApi,
  InsertionInstruction as InsertionInstructionGW,
  InsertionReceipt as InsertionReceiptGW,
} from "@bmbix/bmb_isimud_client";
import {
  type InsertionInstruction,
  type InsertionReceipt,
} from "../../Entity/Types.js";
import {
  instanceService,
} from "../../Entity/InstanceService";
import { callBack } from "./../CallBack/";

const createInsertionInstructionFromGW = (insertionInstructionGW:
  InsertionInstructionGW): InsertionInstruction => {

    const expected = instanceService.createInsertionInstruction({
      insertionInstructionId: insertionInstructionGW.insertion_instruction_id,
      receivedTimestamp: insertionInstructionGW.received_timestamp,
      messageId: insertionInstructionGW.message_id,
      processingInstructions: insertionInstructionGW.processing_instructions,
      sentToApInsertionQueueTimestamp: insertionInstructionGW.sent_to_ap_insertion_queue_timestamp,
      sentToNotifyMartletQueueTimestamp: insertionInstructionGW.sent_to_notify_martlet_queue_timestamp,
      sentMessageProcessedReceiptTimestamp: insertionInstructionGW.sent_message_processed_receipt_timestamp,
    });
    return expected;
  }


const submitInsertionInstruction = (args: {
  token: string,
  organizationId: string,
  addressId: string,
  messageId: string,
}): Promise<InsertionInstruction> => {
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = args.token;
  let apiInstance = new InsertionsApi();

  const dataHandler = data => {
    const insertionInstructionGW = data.insertion_instruction;
    const insertionInstruction = createInsertionInstructionFromGW(insertionInstructionGW);
    return insertionInstruction;
  }

  return new Promise((resolve, reject) => {
    const body = InsertionInstructionGW.constructFromObject({
      message_id: args.messageId,
    });
    apiInstance.insert(
      body,
      args.organizationId,
      args.addressId,
      callBack(reject, resolve)(dataHandler),
    );
  });
}


const createInsertionReceiptFromGW = (
  insertionReceiptGW: InsertionReceiptGW): InsertionReceipt => {
    return instanceService.createInsertionReceipt({
      insertionReceiptId: insertionReceiptGW.insertion_receipt_id,
        insertionInstructionId: insertionReceiptGW.insertion_instruction_id,
        receivedTimestamp: insertionReceiptGW.received_timestamp,
        addressId: insertionReceiptGW.address_id,
        platformId: insertionReceiptGW.platform_id,
        messageId: insertionReceiptGW.message_id,
        result: insertionReceiptGW.result,
        reference: insertionReceiptGW.reference,
        comments: insertionReceiptGW.comments,
        messageType: insertionReceiptGW.message_type,
    });
}


const queryInsertionReceipts = (args:{
  token: string,
  organizationId: string,
  addressId: string,
  messageId: string,
  insertionInstructionId: string,
  platformId: string,
  messageId: string,
  reference: string,
  result: string,
  messageType: string,
  fromReceivedTimestamp: string,
  toReceivedTimestamp: string,
}): Promise<Array<InsertionReceipt>> => {

  const opts = {}
  if(args.messageId !== undefined) {
    opts["messageId"] = args.messageId;
  }
  if(args.insertionInstructionId !== undefined) {
    opts["insertionInstructionId"] = args.insertionInstructionId;
  }
  if(args.platformId !== undefined) {
    opts["platformId"] = args.platformId;
  }
  if(args.messageId !== undefined) {
    opts["messageId"] = args.messageId;
  }
  if(args.reference !== undefined) {
    opts["reference"] = args.reference;
  }
  if(args.result !== undefined) {
    opts["result"] = args.result;
  }
  if(args.messageType !== undefined) {
    opts["messageType"] = args.messageType;
  }
  if(args.fromReceivedTimestamp !== undefined) {
    opts["fromReceivedTimestamp"] = args.fromReceivedTimestamp;
  }
  if(args.toReceivedTimestamp !== undefined) {
    opts["toReceivedTimestamp"] = args.toReceivedTimestamp;
  }

  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = args.token;
  let apiInstance = new InsertionsApi();
  return new Promise((resolve, reject) => {
    apiInstance.queryInsertionReceipts(args.organizationId, args.addressId, opts, (error, data, response) => {
      if (error) {
        reject(error);
      } else {
        const insertionReceiptsGW = data.insertion_receipts;
        const insertionReceipts = R.map(
          r => createInsertionReceiptFromGW(r),
          insertionReceiptsGW,
        )
        resolve(insertionReceipts);
      }
    });
  });
}


export {
  createInsertionInstructionFromGW,
  createInsertionReceiptFromGW,
  submitInsertionInstruction,
  queryInsertionReceipts,
}
