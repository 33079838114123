// @flow
import {
  Organization as OrganizationGW,
  MessageReadReceipt as MessageReadReceiptGW,
  PaymentMethodLink as PaymentMethodLinkGW,
} from "@bmbix/bmb_martlet_organization_client";
import {
  instanceService,
} from "../../Entity/InstanceService.js";
import type {
  BmbixAddress,
  Organization,
  Permission,
  MessageReadReceipt,
  PaymentMethodLink,
} from "../../Entity/Types";

const createMessageReadReceiptFromGW = (m: MessageReadReceiptGW): MessageReadReceipt => {
  return instanceService.createMessageReadReceipt({
    messageReadReceiptId: m.message_read_receipt_id,
    receivedTimestamp: m.received_timestamp,
    messageId: m.message_id,
  });
}

const createOrganizationFromGW = (organization_gw: OrganizationGW): Organization => {
  const organization = instanceService.createOrganization({
    id: organization_gw.organization_id,
    name: organization_gw.name,
    isClosed: organization_gw.is_closed,
    sicCode: organization_gw.sic_code,
    headcount: organization_gw.headcount,
    ppcSignatory: organization_gw.ppc_signatory,
    paymentContactEmail: organization_gw.payment_contact_email,
  });
  return organization
}

const createAddressFromGW = (addressGW:AddressGW):BmbixAddress => {
  const address = instanceService.createBmbixAddress({
    id: addressGW.id,
    organizationId: addressGW.organization_id,
    alias: addressGW.alias,
    displayName: addressGW.display_name,
    isClosed: addressGW.is_closed,
    purpose: addressGW.purpose,
    webHook: addressGW.web_hook,
    webHookToken: addressGW.web_hook_token,
  });
  return address;
}

const createPermissionFromGW = (gw: PermissionGW): Permission => {
  return Object.freeze({
    permissionId: gw.permission_id,
    actor: gw.actor,
    power: gw.power,
    resource: gw.resource,
  });
}

const createPaymentMethodLinkFromGW = (gw: PaymentMethodLinkGW): PaymentMethodLink => {
  return instanceService.createPaymentMethodLink({
    id: gw.id,
    organizationId: gw.organization_id,
    paymentMethodId: gw.payment_method_id,
    paymentMethodUserId: gw.payment_method_user_id,
    paymentMethodDescription: gw.payment_method_description,
  });
}



export {
  createAddressFromGW,
  createOrganizationFromGW,
  createPermissionFromGW,
  createMessageReadReceiptFromGW,
  createPaymentMethodLinkFromGW,
}

