// @flow

import {
  createElement  as el,
  useState,
} from "react";
import * as S from "sanctuary";
import { useDispatch } from "react-redux";
import { useMachine } from "@xstate/react";
import { createMachine } from "xstate";
import {
  promise,
} from "fluture";
import {
  useToken ,
  useAccount,
} from "./../../../../State";
import {
  InputView,
  WaitingView,
  SuccessView,
  FailureView,
  OAuthButton,
} from "./view";
import {
  addSignupOrganization,
  useSignupOrganization,
  addSignupPlatformType,
  useSignupPlatformType
} from "./../../state";

import {
  addOrganization,
} from "./../../../../Api/MartletOrganization/Users.js";
import {
  addAddress,
} from "./../../../../Api/MartletOrganization/Organization.js";
import {
  createPermissionForAddress,
  updateAddressFuture,
} from "./../../../../Api/MartletOrganization/Address.js";
import {
  insertPlatform,
} from "./../../../../Api/Isimud/Platforms.js";
import {
  insertPlatformAssignment,
} from "./../../../../Api/Isimud/PlatformAssignments.js";
import {
  addMessageType,
} from "./../../../../Addresses/MessageTypesApp/Api";
import {
  addList,
  activateList,
  addEntry,
} from "./../../../../Addresses/AccessListsApp/Api";


import {
  connect as qboConnect,
} from "./../../../../Platforms/QboV1/Api/Authn.js";

import {
  connect as sageConnect,
} from "./../../../../Platforms/SageV1/Api/Authn.js";

import {
  connect as freeagentConnect,
} from "./../../../../Platforms/FreeagentV1/Api/Authn.js";

import {
  generateURL as xeroConnect,
} from "./../../../../Api/XeroV1Authz/Authz.js";
import log from "loglevel";
const logger = log.getLogger("Hammock");

const fetchingMachine = createMachine({
  id: "fetchingMachine",
  initial: "input",
  states: {
    waiting: {
      on: {
        SUCCESS_HAPPENED: "success",
        FAILURE_HAPPENED: "failure",
      }
    },
    success: {
      on: {
        SUBMIT_HAPPENED: "waiting",
      }
    },
    failure: {
      on: {
        RESET_HAPPENED: "input"
      }
    },
    input: {
      on: {
        SUBMIT_HAPPENED: "waiting"
      }
    },
  },
});

const Controller = (props: {}) => {

  const [ state, send ] = useMachine(fetchingMachine);
  const [ organizationName, setOrganizationName ] = useState(useSignupOrganization());
  const [ platformType, setPlatformType ] = useState(useSignupPlatformType());
  const [ platformId, setPlatformId ] = useState("");
  const dispatch = useDispatch();
  const [ organizationId, setOrganizationId ] = useState("");
  const token = useToken();
  const account = useAccount();
  const [ messages, setMessages ] = useState([]);
  const userId = account.id;

  const handleOrganizationChange = newOrganizationName => {
    setOrganizationName(newOrganizationName);
    dispatch(addSignupOrganization(newOrganizationName));
  }

  const handlePlatformTypeChange = e => {
    const platformType = e.target.value;
    dispatch(addSignupPlatformType(platformType));
    setPlatformType(platformType);
  }

  const update = (message) => {
    setMessages( S.append(message));
  }

  const donthandleSubmit = (e) => {
    send({type: "SUBMIT_HAPPENED"});
    /*
    doSubmit(e).then(
      _ => {
        send({type: "SUCCESS_HAPPENED"});
      }
    ).catch(
      error => {
        send({type: "FAILURE_HAPPENED"});
      }
    );
    */
  }

  const handleSubmit = async (e) => {
    send({type: "SUBMIT_HAPPENED"});
    try {
      update("Starting wizard");
      const actor = `bmbix://user/${account.sub}`;
      update(`Generating resources for ${actor}`);
      update("Creating organization");
      const organization = await addOrganization({
        token,
        userId,
        name: organizationName,
        isClosed: false,
      })
      setOrganizationId(organization.id);
      update("Organization created");

      update("Creating sales address");
      const address1 = await addAddress({
        token,
        organizationId: organization.id,
        displayName: "Sales Ledger",
        purpose: "sales",
      });
      update("Sales address created");
      update("Giving manager role for sales address");
      await createPermissionForAddress({
        token,
        addressId: address1.id,
        actor,
        power: "bmbix://role/address/manager",
      });
      update("Gave manager role for sales address");

      update("Creating purchases address");
      const address2 = await addAddress({
        token,
        organizationId: organization.id,
        displayName: "Purchase Ledger",
        purpose: "purchases",
      });
      update("Purchases address created");
      update("Giving manager role for purchases address");
      await createPermissionForAddress({
        token,
        addressId: address2.id,
        actor,
        power: "bmbix://role/address/manager",
      });
      update("Gave manager role for purchases address");

      update("Creating GL address");
      const address3 = await addAddress({
        token,
        organizationId: organization.id,
        displayName: "General Ledger",
        purpose: "general-ledger",
      });
      window.localStorage.setItem("HAMMOCK_GL_ADDRESS", address3.id);
      update("General Ledger address created");
      update("Giving manager role for general-ledger address");
      await createPermissionForAddress({
        token,
        organizationId: organization.id,
        addressId: address3.id,
        actor,
        power: "bmbix://role/address/manager",
      });
      update("Gave manager role for general-ledger address");

      update("Creating web hook for General Ledger");
      const hookURL = `https://21a2zcozm2.execute-api.eu-west-2.amazonaws.com/Prod/hooks/${address3.id}`;
      const hookPassword = "a932437qqbpyiyo";
      const webHookFuture = updateAddressFuture({
        token,
        addressId: address3.id,
        webHook: hookURL,
        webHookToken: hookPassword,
      });
      await promise(webHookFuture)

      update("Creating message-type for General Ledger");
      const messageTypeFuture = addMessageType(token)(address3.id)("bmbix-gl-journal");
      await promise(messageTypeFuture)
      update("Created message type for General Ledger")

      update("Create new access list");
      const addListFuture = addList(token)(address3.id)("Allow List")("allow");
      const accessList = await promise(addListFuture);
      update("Created access list for General Ledger")

      update("Add Hammock to access list");
      // actually Bmbix Ops Sales Ledger
      const hammockAddress = "146ac213-c287-4069-8883-85ac6a32c0c8";
      const addEntryFuture = addEntry(token)(address3.id)(accessList.list)(hammockAddress);
      await promise(addEntryFuture);
      update("Created access list entry for Hammock");

      update("Activate list");
      const activateListFuture = activateList(token)(address3.id)(accessList.list);
      await promise(activateListFuture);
      update("Activated access list.");

      if (platformType === "none") {
        update("Skipping platform creation as none selected");
      } else {
        update("Creating platform");
        const platform = await insertPlatform({
          token,
          organizationId: organization.id,
          name: `${platformType}-1`,
          type: platformType,
        });
        setPlatformId(platform.platformId);
        update("Platform created");

        update("Assigning sales address to platform");
        const platformAssignment1 = await insertPlatformAssignment({
          token,
          organizationId: organization.id,
          platformId: platform.platformId,
          addressId: address1.id,
        });
        update("Sales address assigned to platform");

        update("Assigning purchases address to platform");
        const platformAssignment2 = await insertPlatformAssignment({
          token,
          organizationId: organization.id,
          platformId: platform.platformId,
          addressId: address2.id,
        });
        update("Purchases address assigned to platform");

        update("Assigning general-ledger address to platform");
        const platformAssignment3 = await insertPlatformAssignment({
          token,
          organizationId: organization.id,
          platformId: platform.platformId,
          addressId: address3.id,
        });
        update("General ledger address assigned to platform");
      }
      update("Wizard successfully completed");
      send({type: "SUCCESS_HAPPENED"});
    }
    catch (e) {
      logger.error("NewUserWizardView:", e);
      update("NewUserWizardView failed");
      send({type: "FAILURE_HAPPENED"});
    }
  }

  const handleSageOAuth2Flow = (e) => {
    sageConnect({
      token,
      organizationId,
      platformId,
    }).then(
      auth_code_url => {
        const return_to_url = window.location.pathname;
        console.log("return_to_url", return_to_url);
        console.log("auth_code_url", auth_code_url);
        window.localStorage.setItem("gotoUrl", return_to_url);
        window.location = auth_code_url;
      }
    ).catch(
      error => {
        console.log("submission error", error);
      }
    );
  }

  const handleQboOAuth2Flow = () => {
    qboConnect({
      token,
      organizationId,
      platformId,
    }).then(
      auth_code_url => {
        // const return_to_url = window.location.pathname;
        const return_to_url = "/hammock-signup-wizard/page2";
        console.log("return_to_url", return_to_url);
        console.log("auth_code_url", auth_code_url);
        window.localStorage.setItem("gotoUrl", return_to_url);
        window.location = auth_code_url;

      }
    ).catch(
      error => {
        console.log("submission error", error);
      }
    );
  }

  const renderSage = <OAuthButton onSubmit={handleSageOAuth2Flow}/>
  const renderFreeagent = null;
  const renderQbo = <OAuthButton onSubmit={handleQboOAuth2Flow}/>;
  const renderFreshbooks = null;
  const renderKashflow = null;
  const renderXero = null;
  const renderPlatformNotRecognized = null;

  const inputElement = el(
    InputView,
    {
      onChange: handleOrganizationChange,
      onPlatformTypeChange: handlePlatformTypeChange,
      proposedName: organizationName,
      onSubmit: handleSubmit,
    },
  );
  const successElement = el( SuccessView, {
      organization: organizationName,
      platformType,
      platformId,
      organizationId,
    },
    platformType === "sage"
    ? renderSage
    : platformType === "freeagent"
    ? renderFreeagent
    : platformType === "qbo"
    ? renderQbo
    : platformType === "freshbooks"
    ? renderFreshbooks
    : platformType === "kashflow"
    ? renderKashflow
    : platformType === "xero"
    ? renderXero
    : renderPlatformNotRecognized
  );
  const failureElement = el( FailureView, {},);
  const waitingElement = el( WaitingView, {messages},);

  return state.value === "input"
  ? inputElement
  : state.value === "waiting"
  ? waitingElement
  : state.value === "failure"
  ? failureElement
  : state.value === "success"
  ? successElement
  : <p>Unknown state</p>;


}


export { Controller }
