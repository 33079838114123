// @flow
import { createElement as el, useEffect, useState, } from "react";
import { useParams } from "react-router-dom";
import { fork } from "fluture";
import * as S from "sanctuary";
import { createMachine, assign, } from "xstate";
import { useMachine } from "@xstate/react";

import {
  View,
} from "./view";
import { useToken } from "./../State";
import {
  fetchUsageReport,
  getBillingAddressHistory,
} from "./Api";
import { getPaymentMethodLinkForOrganization } from "./../Api/MartletOrganization/Organization.js";

import {
  ProductList,
  PriceList,
  AllowanceList,
  Usage,
  createCurrentChargeLine,
} from "./entity";


const formatNumber = decimalPlaces => n => (n).toLocaleString(
  undefined,
  {minimumFractionDigits: decimalPlaces},
)

const listOfObjsToStrMap = keyFieldName => listOfObjs =>
  S.fromPairs (
    S.map
      (item => S.Pair (S.prop (keyFieldName) (item)) (item))
      (listOfObjs))

const x = productList => priceList => allowanceList => usage => {
  const productId = S.prop ("productId") (usage);

  const listOfPrices = S.prop ("prices") (priceList)
  const pricesStrMap = listOfObjsToStrMap ("productId") (listOfPrices)
  const maybePrice = S.value (productId) (pricesStrMap)
  const maybeUnitPriceString = S.map (S.prop ("unitPrice")) (maybePrice)
  console.log("maybeUnitPriceString", maybeUnitPriceString)
  const maybeUnitPrice = S.chain (S.parseFloat) (maybeUnitPriceString)
  console.log("maybeUnitPrice", maybeUnitPrice)

  const listOfProducts = S.prop ("products") (productList)
  const productsStrMap = listOfObjsToStrMap ("id") (listOfProducts)
  const maybeProduct = S.value (productId) (productsStrMap)
  const maybeDescription = S.map (S.prop ("description")) (maybeProduct)

  const maybeQuantityString = S.Just (S.prop ("quantity") (usage))
  const maybeQuantity = S.chain (S.parseFloat) (maybeQuantityString)
  const maybeSubTotal = S.lift2 (S.mult) (maybeUnitPrice) (maybeQuantity)
  const actual = createCurrentChargeLine({
    quantity: S.fromMaybe ("") (S.map (formatNumber (2)) (maybeQuantity)),
    productId: S.prop ("productId") (usage),
    description: S.fromMaybe ("") (maybeDescription),
    unitPrice: S.fromMaybe ("") (S.map (formatNumber (2)) (maybeUnitPrice)),
    subTotal: S.fromMaybe ("") (S.map (formatNumber (2)) (maybeSubTotal)),
  })

  const listOfAllowances = S.prop ("allowances") (allowanceList)
  const allowancesStrMap = listOfObjsToStrMap ("productId") (listOfAllowances)
  const maybeAllowance = S.value (productId) (allowancesStrMap)
  const maybeMaxAllowanceQuantity = S.map (S.prop ("quantity")) (maybeAllowance)
  const maybeAllowanceQuantity = S.lift2
    (x => y => Math.min(x, y))
    (maybeMaxAllowanceQuantity) (maybeQuantity)
  const maybeAllowanceUnitPrice = S.lift2 (S.mult) (S.Just (-1)) (maybeUnitPrice)
  const maybeAllowanceSubTotal =
    S.lift2(S.mult) (maybeAllowanceUnitPrice) (maybeAllowanceQuantity)
  const maybeAllowanceDescription =
    S.concat (S.Just ("Allowance - ")) (maybeDescription)
  const allowance = createCurrentChargeLine({
    quantity:
      S.fromMaybe ("") (S.map (formatNumber (2)) (maybeAllowanceQuantity)),
    productId: S.prop ("productId") (usage),
    description: S.fromMaybe ("") (maybeAllowanceDescription),
    unitPrice:
      S.fromMaybe ("") (S.map (formatNumber (2)) (maybeAllowanceUnitPrice)),
    subTotal:
      S.fromMaybe ("") (S.map (formatNumber (2)) (maybeAllowanceSubTotal)),
  })
  const charged = createCurrentChargeLine({
    quantity: "",
    productId: "",
    description: "",
    unitPrice: "",
    subTotal: "",
    total:
      S.fromMaybe
      ("")
      (S.map (formatNumber (2))
        (S.lift2 (S.add) (maybeSubTotal) (maybeAllowanceSubTotal)))
  })
  return [actual, allowance, charged];
}

const buildCurrentChargeLines = (args: {
  productList: ProductList,
  priceList: PriceList,
  allowanceList: AllowanceList,
  usageList: Array<Usage>,
}) => {
  const {
    productList,
    priceList,
    allowanceList,
    usageList,
  } = args
  const triples = S.map (x(productList)(priceList)(allowanceList)) (usageList)
  const flattenedTriples = S.join (triples)
  return flattenedTriples
}

const getTimestamp = () => (new Date()).getTime()

const Controller = (props: {}) => {
  const token = useToken();
  const { organizationId } = useParams();
  const [ chargeUIVersion, setChargeUIVersion ] = useState(getTimestamp())
  const [ currentChargeLines, setCurrentChargeLines ] = useState([])

  const [ apiState, apiSend ] = useMachine(() => createMachine({
    id: "apiFSM",
    initial: "ready",
    context: {
      token,
      organizationId,
      errors: undefined,
      paymentMethodLink: undefined,
    },
    states: {
      ready: {
        on: {
          SUBMIT: "working",
        },
      },
      working: {
        invoke: {
          src: (c, e) => getPaymentMethodLinkForOrganization({
            token: c.token,
            organizationId: c.organizationId,
          }),
          onDone: {
            target: "success",
            actions: assign({
              paymentMethodLink: (c, e) => e.data,
            }),
          },
          onError: {
            target: "failure",
            actions: assign({
              errors: (c, e) => e.data,
            }),
          },
        }
      },
      success: {},
      failure: {},
    },
  }));

  const onRefreshCurrentCharges = e => {
    const newTimestamp = getTimestamp();
    console.log("new timestamp:", newTimestamp);
    setChargeUIVersion(newTimestamp)
  }

  useEffect(
    () => {
      console.log("running effect");
      const action = fetchUsageReport({token, organizationId,})
      fork(
        error => {
          console.log(error)
      })(
        usageReport => {
          const currentChargeLines = buildCurrentChargeLines({
            productList: S.prop ("productList") (usageReport),
            priceList: S.prop ("priceList") (usageReport),
            allowanceList: S.prop ("allowanceList") (usageReport),
            usageList: S.prop ("usageList") (usageReport),
          });
          setCurrentChargeLines(currentChargeLines);
      })(action)
      apiSend({type: "SUBMIT"});
    },
    [apiSend, chargeUIVersion, organizationId, token ]
  )

  return el (
    View,
    {
      apiState,
      currentChargeLines,
      onRefreshCurrentCharges,
      organizationId,
    },
    ["Here is the billing app"]
  );
}


export {
  Controller,
}
