// @flow
import React from "react";
import * as S from "sanctuary";
import log from "loglevel";

import {
  type Notification,
} from "../Entity/Types.js";

const logger = log.getLogger("Notifications");

const View = (props: {
  notifications: Array<Notification>,
}) => {
  const {
    notifications,
  } = props;

  logger.error("Notifications view: ", notifications);

  const notification_items = S.map (n => <li key={n.id}>{n.content.reference}</li>) (notifications)

  return (
    <>
    <h2>Notifications</h2>
    <p>Awaiting implementation</p>
    <ul>
     {notification_items}
    </ul>
    </>
  )
}


export {
  View,
}
