// @flow
import * as R from "ramda";
import {
  type ProcessedReceipt,
} from "../Entity/Types.js";


const INSERT_MANY = "INSERT_MANY";

const insertProcessedReceipts = (processedReceipts: Array<ProcessedReceipt>) => {
  return Object.freeze({
    type: INSERT_MANY,
    processedReceipts: processedReceipts,
  });
}


const handleInsertMany = (state, processedReceipts) => {
  return R.uniqBy(
    pr => pr.message_processed_receipt_id,
    R.concat(state, processedReceipts),
  );
}



const reducer = (state: Array<ProcessedReceipt>=[], action: Object) => {
  switch(action.type){
    case INSERT_MANY:
      return handleInsertMany(state, action.processedReceipts);
    default:
      return state;
  }
}



export {
  reducer as processedReceipts,
  insertProcessedReceipts,
}
