// @flow

import React, {
  useState,
} from "react";
import Button from "react-bootstrap/Button";
import {
  Link,
} from "react-router-dom";


import {
  withIsimudApi,
} from "../Api/Isimud";
import {
  SingleExtractionForm,
} from "./SingleExtractionForm";


const X = (props: {
  organizationId: string,
  addressId: string,
    isimudApi: Object,
}) => {

  const [reference, setReference] = useState();
  const [messageType, setMessageType] = useState();
  const [display, setDisplay] = useState("input");
  const [extraction, setExtraction] = useState();

  const changeListener = (e) => {
    const value = e.currentTarget.value;
    const name = e.currentTarget.name;
    switch(name){
      case "messageType":
        setMessageType(value);
        return;
      case "reference":
        setReference(value);
        return;
      default:
        throw new Error(`Unexpected field: ${name}`);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisplay("waiting");
    props.isimudApi.submitExtractionRequest({
      organizationId: props.organizationId,
      addressId: props.addressId,
      messageType: messageType,
      references: [reference],
    }).then(
      extraction => {
        setExtraction(extraction);
        setDisplay("success");
      }
    ).catch(
      error => {
        console.log("error: ", error);
        setDisplay("error");
      }
    );
  }

  const view = () => {
    switch(display){
      case "input":
        return (
          <>
          <SingleExtractionForm
            changeListener={changeListener}
            reference={reference}
            messageType={messageType}
          />
          <Button type="submit" onClick={handleSubmit}>Submit</Button>
          </>
        );
      case "waiting":
        return (
          <p>Waiting</p>
        );
      case "success":
        if(extraction){
          const path = `/u/organizations/${props.organizationId}/addresses/${props.addressId}/extractions`;
          return (
            <>
            <h2>Request received</h2>
            <p>{extraction.header.extractionId}</p>
            <Link to={path} ><Button>View Progress</Button></Link>
            </>
          );
        }
        return;
      case "error":
        return (
          <p>Boom. Something went wrong</p>
        );
      default:
        throw new Error("display state not recognized");
    }
  }


  return (
    <>
    <h2>Extract by reference</h2>
    <p>Use this form to extract one transaction by reference.</p>
    {view()}
    </>
  )
}


const SingleExtractionWidget = withIsimudApi(X);


export {
  SingleExtractionWidget,
}
