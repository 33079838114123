// @flow
import { createElement as el } from "react";
import type { PaymentMethod } from "./../../../Entity/Types.js";
import { View } from "./view.js";

import { createMachine } from "xstate";
import { useMachine } from "@xstate/react";




const Controller = (
  props: {
    onItemsChanged: Function,
    paymentMethods: Array<PaymentMethod>
  }) => {
  const {
    onItemsChanged,
    paymentMethods,
  } = props;
  const [ cleanFSMState, cleanFSMSend ] = useMachine(() => createMachine({
    id: "cleanFSM",
    initial: "clean",
    states: {
      clean: {
        on: {
          ITEMS_CHANGED: "dirty",
        },
      },
      dirty: {
        entry: (c, e) => {
          console.log("entry: itemsChanged: ", c, e);
          onItemsChanged();
        },
      },
    },
  }));

  const handleItemsChanged = (e) => {
    console.log("PaymentMethodsTable, itemsChanged: ", e);
    cleanFSMSend({type: "ITEMS_CHANGED"});
  }

  return el(
    View,
    {
      onItemsChanged: handleItemsChanged,
      paymentMethods,
    },
  );
}

export {
  Controller,
}
