// @flow

import {
  createElement as el,
  useState,
} from "react";
import {
  connect,
} from "react-redux";
import log from "loglevel";
import { getTimeZones } from "@vvo/tzdb";
import {
  createMachine,
} from "xstate";
import {
  useMachine,
} from "@xstate/react";

import {
  type Settings,
} from "../../../Entity/Types.js";
import {
  View,
} from "./View.js";
import {
  setSettings,
} from "../../../State";
import { useToken } from "./../../../State/Token.js";
import { useAccount } from "./../../../State/Account.js";
import { setTimezone as updateTimezone } from "./../../../Api/MartletOrganization/Users.js";

const logger = log.getLogger("SetTimezoneModal");

const machineSpecification = {
  id: "setTimeZone",
  initial: "idle",
  context: {},
  states: {
    idle: {
      on: {
        START: "working",
      }
    },
    working: {
      on: {
        SUCCESS: "succeeded",
        FAILURE: "failed",
      }
    },
    succeeded: {
      on: {
        RESET: "idle",
      },
    },
    failed: {
      on: {
        RESET: "idle",
      },
    },
  },
};

const stateMachine = createMachine(machineSpecification);

const Controller_ = (props: {
  onHide: Function,
  settings: Settings,
  setSettings: Function,
}) => {
  const {
    onHide,
    settings,
    setSettings,
  } = props;
  const [ state, send ] = useMachine(stateMachine);
  const [active, setActive] = useState(true);
  const [timezone, setTimezone] = useState(settings.timezone);
  const token = useToken();
  const account = useAccount();
  const userId = account.id;

  const onSubmit = data => {
    send("START");
    const timezone = data.timezone;
    updateTimezone({
      token,
      userId,
      timezone,
    }).then(
      updatedSettings => {
        send("SUCCESS");
        setSettings(Object.assign({}, settings, {timezone}));
        setTimezone(timezone);
      }
    ).catch(
      error => {
        send("FAILURE");
        logger.error("SetTimezoneModal:", error);
      }
    );
  }

  const onClose = e => {
    send("RESET");
    setActive(false);
    onHide();
  }

  const timezones = getTimeZones()

  return active ? el(
      View,
      {
        state: state.value,
        timezone,
        timezones,
        onSubmit,
        onClose,
      }
  ): null;
}

const connector = connect(
  null,
  {
    setSettings,
  },
)

const Controller = connector(Controller_);

export {
  Controller,
  Controller_,
}

