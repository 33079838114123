// @flow
import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import {
  Spinner,
} from "../../../../Widgets/Toast";
import {
  useForm,
  Controller,
} from "react-hook-form";


const Button_ = (props: {
  onClick: Function,
}) => <Button onClick={props.onClick}>Add MCT</Button>


const Modal_ = (props: {
  apiState: Function,
  onHide: Function,
  onSubmit: Function,
}) => {

  const {
    apiState,
    onHide,
    onSubmit,
  } = props;

  const state = apiState.value;

  const { control, handleSubmit, formState: { errors } }
    = useForm({defaultValues: {messageContentTypeName: ""}});
  const form = <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Group>
    <Form.Label>Name</Form.Label>
    <Controller
      name="name"
      rules={{required: true}}
      defaultValue=""
      control={control}
      render={
        ({ field }) =>
        <Form.Control {...field} />
      }
    />
    <Form.Text>Give a name for this content type</Form.Text>
      {errors.messageContentTypeName && <span>This field is required</span>}
    </Form.Group>
    <Form.Group>
    <Form.Label>Schema</Form.Label>
    <Controller
      name="schema"
      defaultValue=""
      rules={{required: true}}
      control={control}
      render={
        ({ field }) =>
        <Form.Control {...field} />
      }
    />
    <Form.Text>Plain-text schema</Form.Text>
      {errors.messageContentTypeName && <span>This field is required</span>}
    </Form.Group>
    <Form.Group>
    <Form.Label>Visibility</Form.Label>
    <Controller
      name="visibility"
      defaultValue=""
      rules={{required: true}}
      control={control}
      render={
        ({ field }) =>
        <Form.Control {...field} />
      }
    />
    <Form.Text>Comma separated list of bmbix entities</Form.Text>
      {errors.messageContentTypeName && <span>This field is required</span>}
    </Form.Group>
    <Button type="submit" variant="primary" >Save changes</Button>
    </Form>
  const spinner = <Spinner height="24"/>;
  const successMessage = <p>All good, name was updated</p>;
  const failureMessage = <p>Oops, something has gone wrong.</p>;

  const body = state === "idle"
    ? form
    : state === "working"
    ? spinner
    : state === "succeeded"
    ? successMessage
    : state === "failed"
    ? failureMessage
    : <p>Unknown state</p>;

  return (
    <Modal show={true}>
    <Modal.Header closeButton onHide={onHide}>Add Message Content Types</Modal.Header>
    <Modal.Body>
    {body}
    </Modal.Body>
    <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>Close</Button>
    </Modal.Footer>
    </Modal>
  )
}

export {
  Button_,
  Modal_,
}
