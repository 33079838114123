// @flow

import { createElement as el, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import log from "loglevel";

import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "./../../../Util";
import {
  withMartletOrganizationApi,
} from "./../../../Api/MartletOrganization";
import {
  instanceService,
} from "./../../../Entity/InstanceService.js";
import { View } from "./view.js";
import { useCatchExpiredToken } from "./../../../State/Token.js";

const logger = log.getLogger("Profile");

const Controller = withMartletOrganizationApi(withRouter((props: {
  match: Object,
  martletOrganizationApi: Object,
}) => {
  const {
    martletOrganizationApi,
    match,
  } = props;
  const catchExpiredToken = useCatchExpiredToken();

  const userId = match.params.userId;
  logger.debug("userId:", userId);
  const [profile, setProfile] = useState();
  const [display, setDisplay] = useState(INPUT);

  useEffect(
    () => {
      setDisplay(WAITING);
      martletOrganizationApi.getUserProfiles({
        userIds:[userId],
      }).then(
        profiles => {
          let profile;
          if (profiles.length == 1) {
            profile = profiles[0];
          }
          else {
            profile = instanceService.createUserProfile({
              userId
            });
          }
          setProfile(profile);
          setDisplay(SUCCESS);
        }
      ).catch(
        error => {
          logger.error("Error fetching profile", error);
          catchExpiredToken(error);
          setDisplay(FAILURE);
        }
      );
    },
    [],
  );

  return !!profile ? el (
    View,
    { profile, display, }
  ): el (
    View,
    {display},
  );
}

));

export {
  Controller,
}
