// @flow
import {
  useState,
  createElement as el,
} from "react";
import log from "loglevel";

import {
  withSageV1AuthnApi,
} from "../Api/";
import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "../../../Util";

import {
  View,
} from "./View.js";

const logger = log.getLogger("Sage");

const Controller_ = (props: {
  organizationId: string,
  platformId: string,
  sageV1AuthnApi: Object,
  onComplete: Function,
}) => {
  const {
    organizationId,
    platformId,
    sageV1AuthnApi,
    onComplete,
  } = props;

  const [display, setDisplay] = useState(INPUT);

  const onSubmit = () => {
    setDisplay(WAITING);
    logger.error("submitting");
    sageV1AuthnApi.connect({
      organizationId,
      platformId,
    }).then(
      auth_code_url => {
        const return_to_url = window.location.pathname;
        logger.debug("return_to_url", return_to_url);
        logger.debug("auth_code_url", auth_code_url);
        window.localStorage.setItem("gotoUrl", return_to_url);
        window.location = auth_code_url;
        setDisplay(SUCCESS);

      }
    ).catch(
      error => {
        setDisplay(FAILURE);
      }
    );
  }

  const onSuccess = (e) => {
    logger.error("it all went swimmingly");
    onComplete();
  }

  const onFailure = (e) => {
    logger.error("it all went rather less well than hoped");
    onComplete();
  }

  const onCancel = (e) => {
    logger.error("it all went rather less well than hoped");
    onComplete();
  }

  return el(
    View,
    {
      display,
      onSuccess,
      onCancel,
      onSubmit,
      onFailure,
    },
  )
}

const Controller = withSageV1AuthnApi(Controller_);

export {
  Controller,
  Controller_,
}
