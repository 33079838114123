// @flow
import React from "react";
import Button from "react-bootstrap/Button";
import { Controller as ModalView } from "./modal-controller.js";



const View = (
  {
    state,
    onShowButtonClick,
    onDone,
    onCancel,
  }: {
    state: Function,
    onShowButtonClick: Function,
    onDone: Function,
    onCancel: Function,
  }) => {


  return state.value === "showButton"
  ? <Button onClick={onShowButtonClick}>Add Link</Button>
  : <ModalView onDone={onDone} onCancel={onCancel} />

}


export {
  View,
}
