// @flow

import React from "react";

import {
  Route,
  Switch, useRouteMatch, useParams } from "react-router-dom";
import {promise} from "fluture";

import {
  useToken,
} from "./../../State";

import { useMachine } from "@xstate/react";
import { createMachine, assign } from "xstate";
import {
  Spinner,
} from "./../../Widgets/Toast";
import { fetchMessageContentTypes } from "./api.js";
import { HomePage } from "./widgets/home-page.js";
import { MessageContentTypePage } from "./widgets/message-content-type-page.js";


const fetchContentTypes = (context, event) =>
  promise(fetchMessageContentTypes(event.token)(event.organizationId))


const fetchingMachine = createMachine({
  id: "fetchingMachine",
  initial: "ready",
  context: {
    errors: undefined,
    contentTypes: undefined,
  },
  states: {
    working: {
      invoke: {
        id: "fetchContentTypes",
        src: (context, event) => fetchContentTypes(context, event),
        onDone: {
          target: "success",
          actions: assign({contentTypes: (context, event) => {
            console.log("Here be promise resolution: ", event);
            return event.data;
          }}),
        },
        onError: {
          target: "failure",
          actions: assign({error: (context, event) => {
            console.log("error event: ", event);
            return event.data
          }}),
        }
      },
    },
    success: {
      on: {
        RESET_HAPPENED: "ready",
      }
    },
    failure: {
      on: {
        RESET_HAPPENED: "ready"
      }
    },
    ready: {
      on: {
        SUBMIT_HAPPENED: "working"
      }
    },
  },
});

const App = (props: {
}) => {

  const {organizationId} = useParams("organizationId");
  const token = useToken();
  const [ state, send ] = useMachine(fetchingMachine);
  const match = useRouteMatch();

  send({type: "SUBMIT_HAPPENED", token: token, organizationId: organizationId});
  console.log("match.path: ", match.path);

  const body = state.value === "ready"
  ? <>Ready to go</>
  : state.value === "working"
  ? <Spinner height="50" />
  : state.value === "success"
  ? <HomePage state={state} organizationId={organizationId} />
  : state.value === "failure"
  ? <p>Oops</p>
  : <p>Unknown state</p>;

  return (
    <>
    <Switch>
      <Route path={`${match.path}/:id`}>
        <MessageContentTypePage />
      </Route>
      <Route path={`${match.path}`}>
        {body}
      </Route>
    </Switch>
    </>
  )

}

export default App;
export {
  App,
}
