// @flow
import React from "react";
import ReactTable from "react-table-6";
import {
  Link,
  useLocation,
} from "react-router-dom"
import { Controller as PaymentMethodDeleteWidget } from "./../PaymentMethodDeleteWidget/controller.js";

import {
  type PaymentMethod
} from "./../../../Entity/Types.js";


const View = (props: {
  onItemsChanged: Function,
  paymentMethods: Array < PaymentMethod > ,
}) => {
  const {
    onItemsChanged,
    paymentMethods,
  } = props;
  const location = useLocation();


  const columns = [
    {
      Header: "Id",
      accessor: "id",
      Cell: (args: {args: Function}) => {
        const {
          original,
        } = args;
        return <Link to={`${location.pathname}/${original.id}`}>{original.id}< /Link>
      },
    },
    {
      Header: "UserId",
      accessor: "userId",
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "Actions",
      Cell: ({original}: {original: PaymentMethod}) => {
        return <PaymentMethodDeleteWidget onDone={onItemsChanged} paymentMethod={original} />
      },
    },
  ]

  return ( <
    ReactTable className = "-striped -highlight"
    data = {
      paymentMethods
    }
    columns = {
      columns
    }
    defaultPageSize = {
      10
    }
    />
  )
}
export {
  View
}

