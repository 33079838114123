import React, {
} from "react";
import Form from "react-bootstrap/Form";


const SingleExtractionForm = (props:{
  changeListener: string,
    messageType: string,
    reference: string,
}) => {

  return (
    <Form>
    <Form.Group>
    <Form.Label>Message type</Form.Label>
    <Form.Control as="select" name="messageType" onChange={props.changeListener} >
	  <option value=""></option>
	  <option value="bmbix-simplified-invoice-5">bmbix-simplified-invoice-5</option>
    </Form.Control> 


	  <Form.Text>eg bmbix-simplified-invoice-5</Form.Text>
    </Form.Group>
    <Form.Group>
    <Form.Label>Reference</Form.Label>
    <Form.Control type="text" name="reference" onChange={props.changeListener} />
    <Form.Text>eg INV-0001</Form.Text>
    </Form.Group>
    </Form>
  );
}

export {
  SingleExtractionForm,
}
