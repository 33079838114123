// @flow
import * as R from "ramda";

import {
  type ProcessedReceipt,
} from "../../Entity/Types.js";

import {
  instanceService,
} from "../../Entity/InstanceService";

import {
  MessageProcessedReceipt as ProcessedReceiptGW,
  ApiClient,
  AddressesApi,
} from "@bmbix/bmb_martlet_organization_client";
import { callBack } from "./../CallBack/";

const createProcessedReceiptFromGW = (args: {
  addressId: string,
  processedReceiptGW: ProcessedReceiptGW,
}): ProcessedReceipt => {
    const {addressId, processedReceiptGW} = args;
    return instanceService.createProcessedReceipt({
      addressId: addressId,
      messageProcessedReceiptId: processedReceiptGW.message_processed_receipt_id,
      receivedTimestamp: processedReceiptGW.received_timestamp,
      messageId: processedReceiptGW.message_id,
      result: processedReceiptGW.result,
      reference: processedReceiptGW.reference,
      comments: processedReceiptGW.comments,
    });
}


const getProcessedReceipts = (args: {
  token: string,
  addressId: string,
}): Promise<Array<ProcessedReceipt>> => {
  const {
    token,
    addressId,
  } = args;
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AddressesApi();

  const dataHandler = data => {
    const processedReceiptsGW = data.message_processed_receipts;
    const processedReceipts= R.map(
      pr => createProcessedReceiptFromGW({
        addressId: addressId,
        processedReceiptGW: pr,
      }),
      processedReceiptsGW,
    );
    return processedReceipts;
  }

  return new Promise((resolve, reject) => {
    apiInstance.listMessageProcessedReceipts(
      addressId,
      callBack(reject, resolve) (dataHandler),
    );
  })
}


export {
  createProcessedReceiptFromGW,
  getProcessedReceipts,
}
