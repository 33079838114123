// @flow
import Future from "fluture";
import log from "loglevel";

import {
  ApiClient,
  UsersApi,
  OrganizationsApi,
  Certificate as CertificateGW,
} from "@bmbix/bmb_martlet_organization_client";

import {
  type Certificate,
} from "../../Entity/Types.js";
import { callBack } from "./../CallBack/";
import {
  instanceService,
} from "../../Entity/InstanceService";

const logger = log.getLogger("MartletOrganizationApi");

const createCertificateFromGW = (gw: CertificateGW): Certificate => {
  return instanceService.createCertificate({
    certificateId: gw.certificate_id,
    name: gw.name,
    data: gw.data,
    mediaType: gw.media_type,
  });
}

const getUserCertificate =
  (token: string) =>
  (userId: string): Future =>
{
  const defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new UsersApi(defaultClient);

  const dataHandler = data => {
    const publicKeyGW = data.certificate;
    const certificate = createCertificateFromGW(publicKeyGW);
    logger.debug("certificate:", certificate);
    return certificate;
  }

  return Future((reject, resolve) => {
    apiInstance.getUserCertificate(
      userId,
      callBack(reject, resolve)(dataHandler),
    );
    return console.log;
  });
}

const getOrganizationCertificate =
  (token: string) =>
  (organizationId: string): Future =>
{
  const defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new OrganizationsApi(defaultClient);

  const dataHandler = data => {
    const publicKeyGW = data.certificate;
    const certificate = createCertificateFromGW(publicKeyGW);
    logger.debug("certificate:", certificate);
    return certificate;
  }

  return Future((reject, resolve) => {
    apiInstance.getOrganizationCertificate(
      organizationId,
      callBack(reject, resolve)(dataHandler),
    );
    return console.log;
  });
}

const setUserCertificate = (args: {
  userId: str,
  token: string,
  mediaType: string,
  data: string,
  defaultClient_: Object,
}): Promise<string> => {
  const {
    userId,
    token,
    mediaType,
    data,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  const body = CertificateGW.constructFromObject({
    data: data,
    media_type: mediaType,
  });

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new UsersApi(defaultClient);
  return new Promise((resolve, reject) => {
    apiInstance.setUserCertificate(
      body, userId,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          const certificateId= data.certificate_id;
          logger.debug("certificateId:", certificateId);
          resolve(certificateId);
        }
      }
    );
  });
}

const setOrganizationCertificate = (args: {
  organizationId: str,
  token: string,
  mediaType: string,
  data: string,
  defaultClient_: Object,
}): Promise<string> => {
  const {
    organizationId,
    token,
    mediaType,
    data,
    defaultClient_,
  } = args;

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  const body = CertificateGW.constructFromObject({
    data: data,
    media_type: mediaType,
  });

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new OrganizationsApi(defaultClient);
  return new Promise((resolve, reject) => {
    apiInstance.setOrganizationCertificate(
      body, organizationId,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          const certificateId= data.certificate_id;
          logger.debug("certificateId:", certificateId);
          resolve(certificateId);
        }
      }
    );
  });
}

export {
  getUserCertificate,
  setUserCertificate,
  getOrganizationCertificate,
  setOrganizationCertificate,
}
