// @flow
import Future from "fluture";
import * as S from "sanctuary";

import {
  ApiClient,
  AddressesApi,
  OrganizationsApi,
  UsersApi,
} from "@bmbix/bmb_martlet_organization_client";

import { callBack } from "./../../Api/CallBack";

import {
  type PrivateKey ,
} from "./../entity";

import { createPrivateKeyFromGW } from "./adapt";

const update =
  (token: string) =>
  (resourceType: string) =>
  (resourceId: string) =>
  (privateKeyId: string): Future<Array<PrivateKey>> =>
{
  const allowedResourceTypes = [
    "organizations",
    "addresses",
    "users",
  ];
  if (!S.elem (resourceType) (allowedResourceTypes)) {
    throw new Error("resourceType was not one of allowed values")
  }

  const generic_patch_item = {
    op: "replace",
    path: "/rescinded",
    value: true,
  }

  const body = {
    patches: [generic_patch_item],
  };

  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;

  const dataHandler = data => {
    const gw = data.private_key;
    const privateKey = createPrivateKeyFromGW(gw);
    return privateKey;
  }

  switch (resourceType) {
    case "organizations": {
      return Future((reject, resolve) => {
        const apiInstance = new OrganizationsApi();
        apiInstance.updatePrivateKey(
          body,
          resourceId,
          privateKeyId,
          callBack(reject, resolve)(dataHandler),
        )
        return console.log;
      });
    }
    case "users": {
      return Future((reject, resolve) => {
        const apiInstance = new UsersApi();
        apiInstance.updatePrivateKey(
          body,
          resourceId,
          privateKeyId,
          callBack(reject, resolve)(dataHandler),
        )
        return console.log;
      });
    }
    default: {
      return Future((reject, resolve) => {
        const apiInstance = new AddressesApi();
        apiInstance.updatePrivateKey(
          body,
          resourceId,
          privateKeyId,
          callBack(reject, resolve)(dataHandler),
        )
        return console.log;
      });
    }
  }

}

export {
  update,
}
