// @flow
import Future from "fluture";
import log from "loglevel";

import {
  ApiClient,
  AddressesApi,
  UsersApi,
  OrganizationsApi,
  PublicKey as ResourcePublicKeyGW,
} from "@bmbix/bmb_martlet_organization_client";
import { callBack } from "./../../Api/CallBack";

import {
  createResourcePublicKey,
  ResourcePublicKey,
} from "./../entity";

const logger = log.getLogger("ProfilesApp");

const createResourcePublicKeyFromGW = (gw: ResourcePublicKeyGW): ResourcePublicKey => {
  return createResourcePublicKey({
    data: gw.data,
    uri: gw.uri,
    fingerprint: gw.fingerprint,
    expiresAt: gw.expires_at,
  });
}


const getResourcePublicKey =
  (token: string) =>
  (resourceType: string): Future =>
  (resourceId: string): Future =>
{
  const defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;

  const dataHandler = data => {
    const publicKeyGW = data.public_key;
    console.log("publicKeyGW", publicKeyGW);
    const publicKey = createResourcePublicKeyFromGW(publicKeyGW);
    logger.debug("publicKey:", publicKey);
    return publicKey;
  }

  switch (resourceType) {
    case "organizations": {
      return Future((reject, resolve) => {
        let apiInstance = new OrganizationsApi(defaultClient);
        apiInstance.getPublicKey(
          resourceId,
          callBack(reject, resolve)(dataHandler)
        );
        return console.log;
      });
    }
    case "users": {
      return Future((reject, resolve) => {
        let apiInstance = new UsersApi(defaultClient);
        apiInstance.getPublicKey(
          resourceId,
          callBack(reject, resolve)(dataHandler)
        );
        return console.log;
      });
    }
    default: {
      return Future((reject, resolve) => {
        let apiInstance = new AddressesApi(defaultClient);
        apiInstance.getPublicKey(
          resourceId,
          callBack(reject, resolve)(dataHandler)
        );
        return console.log;
      });
    }
  }

}


export {
  getResourcePublicKey,
}
