import React from "react";

import {
  connect,
} from "react-redux";

import {
  getMessageNotes,
  insertMessageNote,
} from "./MessageNotes.js";


import {
  getAliases,
  getAddress,
  updateAddress,
  updateAddressFuture,
} from "./Address.js";
import {
  getAcceptedMessage,
  getAcceptedMessages,
  getAcceptances,
  getDeliverableMessages,
  getMessage,
  getMessageNoContext,
  getReceivedMessages,
  getRejectedMessages,
  getReceivedMessage,
  getRejectedMessage,
} from "./Message.js";
import {
  getAllOrganizations,
  getOrganization,
  updateOrganization,
  setIcon,
  setCertificate,
} from "./Organization.js";
import {
  createPermission,
  deletePermission,
  getPermissions,
  getAllPermissions,
} from "./Permission.js";

import {
  getProcessedReceipts,
} from "./ProcessedReceipt.js";

import {
  getUserProfiles,
  getAddressProfiles,
  getOrganizationProfiles,
} from "./Profile.js";

class AcceptancesController {

  constructor (args) {
    this.token = args.token;
  }

  getToken() {
    return this.token;
  }
}

const withBmbix = (ComponentFunc) => {

  const BmbixFunc = (connectedProps) => {
    const bmbix = new AcceptancesController({token: connectedProps.token});
    const {token, ...userProps} = connectedProps;
    const processedProps = {...userProps, bmbix};

    return (
      <ComponentFunc {...processedProps} />
    )
  }
  const mapStateToProps = (state) => {
    return {
      token: state.token
    }
  }
  return connect(mapStateToProps)(BmbixFunc);

}


class MartletOrganizationApi {

  constructor (args) {
    this.token = args.token;
  }

  insertMessageNote(args) {
    const args_ = {token: this.token, ...args}
    return insertMessageNote(args_);
  }

  getMessageNotes(args) {
    const args_ = {token: this.token, ...args}
    return getMessageNotes(args_);
  }

  getAcceptances (args) {
    const args_ = {token: this.token, ...args}
    return getAcceptances(args_);
  }

  getAliases(args){
    const args_ = {token: this.token, ...args}
    return getAliases(args_);
  }

  getAddress (args){
    const args_ = {token: this.token, ...args}
    return getAddress(args_)
  }

  updateAddress (args) {
    const args_ = {token: this.token, ...args}
    return updateAddress(args_)
  }

  updateAddressFuture (args) {
    const args_ = {token: this.token, ...args}
    return updateAddressFuture(args_)
  }

  getAllOrganizations (args) {
    const args_ = {token: this.token, ...args}
    return getAllOrganizations(args_)
  }

  getOrganization (args) {
    const args_ = {token: this.token, ...args}
    return getOrganization(args_)
  }

  setIcon (args) {
    const args_ = {token: this.token, ...args}
    return setIcon(args_)
 }

  setCertificate(args) {
    const args_ = {token: this.token, ...args}
    return setCertificate(args_)
 }

  updateOrganization (args) {
    const args_ = {token: this.token, ...args}
    return updateOrganization(args_)
 }

  createPermission (args) {
    const args_ = {token: this.token, ...args}
    return createPermission(args_)
 }

  deletePermission (args) {
    const args_ = {token: this.token, ...args}
    return deletePermission(args_)
 }

  getPermissions (args) {
    const args_ = {token: this.token, ...args}
    return getPermissions(args_)
 }

  getAllPermissions (args) {
    const args_ = {token: this.token, ...args}
    return getAllPermissions(args_)
 }

  getProcessedReceipts(args) {
    const args_ = {token: this.token, ...args}
    return getProcessedReceipts(args_)
 }

  getMessage(args) {
    const args_ = {token: this.token, ...args}
    return getMessage(args_)
 }

  getMessageNoContext(args) {
    const args_ = {token: this.token, ...args}
    return getMessageNoContext(args_)
 }

  getReceivedMessages(args) {
    const args_ = {token: this.token, ...args}
    return getReceivedMessages(args_)
 }

  getRejectedMessages(args) {
    const args_ = {token: this.token, ...args}
    return getRejectedMessages(args_)
 }

  getReceivedMessage(args) {
    const args_ = {token: this.token, ...args}
    return getReceivedMessage(args_)
 }

  getRejectedMessage(args) {
    const args_ = {token: this.token, ...args}
    return getRejectedMessage(args_)
 }

  getAcceptedMessage(args) {
    const args_ = {token: this.token, ...args}
    return getAcceptedMessage(args_)
 }

  getAcceptedMessages(args) {
    const args_ = {token: this.token, ...args}
    return getAcceptedMessages(args_)
 }

  getDeliverableMessages(args) {
    const args_ = {token: this.token, ...args}
    return getDeliverableMessages(args_)
 }

  getUserProfiles(args) {
    const args_ = {token: this.token, ...args}
    return getUserProfiles(args_)
 }

  getAddressProfiles(args) {
    const args_ = {token: this.token, ...args}
    return getAddressProfiles(args_)
 }

  getOrganizationProfiles(args) {
    const args_ = {token: this.token, ...args}
    return getOrganizationProfiles(args_)
 }

}


const withMartletOrganizationApi = (ComponentFunc) => {

  const BmbixFunc = (connectedProps) => {
    const martletOrganizationApi = new MartletOrganizationApi({token: connectedProps.token});
    const {token, ...userProps} = connectedProps;
    const processedProps = {...userProps, martletOrganizationApi};

    return (
      <ComponentFunc {...processedProps} />
    )
  }
  const mapStateToProps = (state) => {
    return {
      token: state.token
    }
  }
  return connect(mapStateToProps)(BmbixFunc);

}


export {
  withBmbix,
  withMartletOrganizationApi,
}
