// @flow

import React from 'react';
import ReactDOM from 'react-dom';
// import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux'
// import 'bootswatch/dist/cosmo/bootstrap.css'
import './bmbix-cosmo/bootstrap.css'
//import 'bootstrap/dist/css/bootstrap.css';
import "react-datetime/css/react-datetime.css";
import './index.css';
import './App.css';
import {Session} from './App';
import {
  store,
} from "./State/Store.js";


ReactDOM.render(
  <Provider store={store}>
  <Session />
  </Provider>, document.getElementById('root'));
// registerServiceWorker();
