// @flow
import {createElement as el} from "react";


import {
  View,
} from "./View.js";


const Controller = (props: {
}) => {
  return el(
    View,
    props,
  )
}

export {
  Controller,
}
