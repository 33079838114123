// @flow
import React from "react";
import Button from "react-bootstrap/Button";

import {
  type UserProfile,
  type OrganizationProfile,
} from "./../../../Entity/Types.js";

import { Controller as SearchForm } from "./../../Widgets/SearchForm/controller.js";
import { Controller as ProfilesSearchResultTable } from "./../../Widgets/ProfilesSearchResultTable/controller.js";

const View = (props: {
  onChange: Function,
  onKeyPress: Function,
  onSubmit: Function,
  data: Function,
  userProfiles: Array<UserProfile>,
  organizationProfiles: Array<OrganizationProfile>,
  searchState: "INACTIVE" | "RUNNING" | "COMPLETE",
}) => {
  const {
    onChange,
    onKeyPress,
    onSubmit,
    data,
    userProfiles,
    organizationProfiles,
    searchState
  } = props;
  console.log("userProfiles", userProfiles);
  console.log("organizationProfiles", organizationProfiles);

  return (
    <>
    <h2>Search</h2>
    <p>You can search for profiles for users and organizations.</p>
    <p>When searching for organizations, you can search for those who support the
    Prompt Payment Code.</p>
    <SearchForm data={data} onChange={onChange} onKeyPress={onKeyPress} />
    <Button onClick={onSubmit}> Search</Button>
    {
      searchState === "COMPLETE"
      ?  (<ProfilesSearchResultTable
         userProfiles={userProfiles}
         organizationProfiles={organizationProfiles}
         />)
      : searchState === "RUNNING"
      ?  <p>Running search</p>
      : <p>Search results will appear here</p>
    }
    </>
  )
}

export { View }
