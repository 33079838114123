// @flow
import React from "react";
import * as R from "ramda";
import {
  connect,
} from "react-redux";
import {
  withRouter,
} from "react-router";
import {
  Link,
} from "react-router-dom";

import {
  type Platform,
} from "../Entity/Types";


const PlatformView2 = (props: {
  platform: Platform,
  location: Object;
}) => {
  const {
    platform,
    location,
  } = props;

  const xero = platform.type === "xero" ? (
    <Link to={`${location.pathname}/xero`} >Authenticate</Link>
  ) : null;

  return (
    <>
    <h2>Platform</h2>
    <h3>Name</h3>
    <p>{platform.name}</p>
    <h3>Type</h3>
    <p>{platform.type}</p>
    {xero}
    </>
  )
}


export {
  PlatformView2,
}


const redux_connector = connect(
  (state, ownProps) => {
    const platformId = ownProps.match.params.pid;
    return {
      platform: R.find(R.propEq("platformId", platformId))(state.platforms)
    }
  }
);


// the HOCs work from left to right.
// I call the component with some props
// then withRouter adds its match, history etc
// then redux ads in the state (working off match)
// and finally the actual component receives all the props.
export default withRouter(redux_connector(PlatformView2));
