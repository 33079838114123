// @flow
import React, {
  useState,
} from "react";
import {
  MentionIcon,
} from "@primer/octicons-react";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  Link,
} from "react-router-dom";
import * as S from "sanctuary";
import * as $ from "sanctuary-def";

import {
  type Platform,
} from "./../../../Entity/Types.js";
import {
  makeDecoratedAddress,
} from "./../../../Util/platform.js";

const inOrOut = purpose =>
  purpose === "sales" ?  "outbound":
  purpose === "purchases" ?  "inbound" :
  "outbound";

const PlatformWidget = (props: {
  platform: Platform,
  size?: string,
}) => {
  const {
    platform,
    size,
  } = props;
  console.log("platform:", platform);

  const size_ = !!size ? size : "32";

  const iconMap = {
    sage: "sage-icon",
    quickbooks: "quickbooks-icon",
    qbo: "quickbooks-icon",
    xero: "xero-icon",
  };
  const iconRef = type => S.fromMaybe ("unknown-platform-icon") (S.value (type) (iconMap))
  const iconPath = `/platform_logos.svg#${iconRef(platform.type)}`;

  return (
    <svg width={size_} height={size_} ><use xlinkHref={iconPath}></use></svg>
  )
}
const View = (props: {
  address: BmbixAddress,
  platform: Platform,
}) => {
  const {
    address,
    platform,
  } = props;
  console.log("Electric platform: ", platform);
  const [key, setKey] = useState(inOrOut(address.purpose));
  if (S.is ($.Null) (platform)) {
    return <p>Unable to render this view as platform is null</p>;
  }
  const decoratedAddress = makeDecoratedAddress(address)(platform);

  const url_root = `/u/organizations/${address.organizationId}/addresses/${address.id}`;
  const extractPath = `${url_root}/extract`;
  const extractOnePath = `/u/organizations/${address.organizationId}/addresses/${address.id}/extract-one`;
  const extractionsPath = `${url_root}/extractions`;
  const processPath = `${url_root}/process`;
  const insertionsPath = `${url_root}/insertions`;

  return (
    <>
    <h2><MentionIcon size="medium" verticalAlign="middle"/> {address.alias}</h2>
    <PlatformWidget platform={decoratedAddress.platform} size="64"/>
    <Tabs activeKey={key} onSelect={k => setKey(k)} id="address-inbound-outbound">
    <Tab eventKey="outbound" title="Outbound">
    <h3>Extracting</h3>
    <hr/>
    <p>Extract transactions from your accounting platform, convert them to
    messages and send them to Bmbix for checking and onward delivery.
    </p>
    <Link to={extractPath}><Button className={"mr-2"} >Extract By Date Range</Button></Link>
    <Link to={extractOnePath}><Button className={"mr-2"}>Extract By Reference</Button></Link>
    <hr/>
    <p>View extraction history and follow your messages as they move from your
    accounting platform to Bmbix.</p>
    <Link to={extractionsPath}><Button>Extractions History</Button></Link>
    <hr/>
    </Tab>
    <Tab eventKey="inbound" title="Inbound">
    <h3>Inserting</h3>
    <hr/>
    <p>Insert unprocessed messages to the accounting platform associated with this address.</p>
    <Link to={processPath}><Button>Insert Many</Button></Link>
    <hr/>
    <p>View the processing history of message insertions to the accounting
    platform associated with this address.</p>
    <Link to={insertionsPath}><Button>Insertions History</Button></Link>
    </Tab>
    </Tabs>
    </>
  )

}


export {
  View,
}
