// @flow
import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link, Switch, Route } from "react-router-dom";
import {
  PersonIcon,
} from "@primer/octicons-react"

const View = (props: {
  location: Function,
  match: Function,
}) => {

  return (
    <>
    <Switch>
      <Route path="/a/roles"><RolesTrail/></Route>
      <Route path="/a"><AccountTrail/></Route>
    </Switch>
    </>
  );
}

const AccountTrail = (props: {
}) => {

  return (
    <Breadcrumb>
      <Breadcrumb.Item active><PersonIcon/> Account</Breadcrumb.Item>
    </Breadcrumb>
  )
}

const RolesTrail = (props: {
}) => {

  const linkProps1 = {
    to: "/a",
  }

  return (
    <Breadcrumb>
      <Breadcrumb.Item linkAs={Link} linkProps={linkProps1}><PersonIcon/> Account</Breadcrumb.Item>
      <Breadcrumb.Item active> Roles</Breadcrumb.Item>
    </Breadcrumb>
  )
}

export { View }
