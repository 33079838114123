// @flow
import React, {
  createElement as el,
  useState,
} from "react";
import Button from "react-bootstrap/Button";


import {
  fork,
} from 'fluture'
import { connect } from "react-redux";
import { withMartletOrganizationApi } from "../../Api/MartletOrganization";
import {
  updateAddress,
} from "../../State";

import {
  SUCCESS,
  WAITING,
  INPUT,
  FAILURE,
} from "../../Util";

import {
  View,
} from "./View.js";

import {
  createWebHook
} from "./Entity.js";
import { type  BmbixAddress } from "../../Entity/Types.js";

const Controller_ = (props: {
  martletOrganizationApi: Function,
  address: BmbixAddress,
  updateAddress: Function
}) => {
  console.log("props", props);
  const {
    martletOrganizationApi,
    address,
    updateAddress,
  } = props;

  const webHook_ = createWebHook({URL: address.webHook, token: address.webHookToken});


  const [active, setActive] = useState(false);
  const [display, setDisplay] = useState(INPUT);
  const [webHook, setWebHook] = useState(webHook_);

  const handleSubmit = (e) => {
    setDisplay(WAITING);
    const futureInstance = martletOrganizationApi.updateAddressFuture({
      addressId: address.id,
      webHook: webHook.URL,
      webHookToken: webHook.token,
    })
    fork(
      error => {
        setDisplay(FAILURE)
        console.log("error", error)
      }
    )(
      address => {
        setDisplay(SUCCESS)
        updateAddress(address)
        console.log("address", address)
      }
    )(futureInstance)
  }

  const handleCancel = (e) => {
    setActive(false);
    setDisplay(INPUT);
  }

  const handleDismissOnSuccess = (e) => {
    setActive(false)
    setDisplay(INPUT);
  }

  const handleDismissOnFailure = (e) => {
    setActive(false)
    setDisplay(INPUT);
  }

  const handleChange = (e) => {
    console.log("e.target", e.target);
    console.log("e.currentTarget", e.currentTarget);
    const token = e.currentTarget.name === "token" ?
      e.currentTarget.value: webHook.token
    const URL = e.currentTarget.name === "URL" ?
      e.currentTarget.value: webHook.URL
    setWebHook(createWebHook({token, URL}));
  }


  const activeView = el (
    View, {
      webHook,
      display,
      onChange: handleChange,
      onSubmit: handleSubmit,
      onCancel: handleCancel,
      onSuccessComplete: handleDismissOnSuccess,
      onFailureComplete: handleDismissOnFailure,
    })

  const inactiveView = <Button onClick={e => setActive(true)}>Update</Button>

  return active ? activeView: inactiveView

}

const connector = connect(
  null,
  {updateAddress},
);


const Controller = connector(withMartletOrganizationApi(Controller_))


export {
  Controller,
}
