// @flow
import {
  createElement as el,
  useEffect,
  useState,
}
from "react";
import log from "loglevel";
import {
  withRouter,
} from "react-router-dom";
import {
  connect,
} from "react-redux";
import * as S from "sanctuary";

import {
  type Platform,
} from "../../../Entity/Types.js";
import {
  withKashflowV1AuthnApi,
} from "../Api/";
import {
  ConnectedView,
  NotConnectedView,
} from "./View.js";
import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "../../../Util";

const logger = log.getLogger("Kashflow");

const Controller_ = (props: {
  platform: Platform,
  kashflowV1AuthnApi: Object,
}) => {
  const {
    kashflowV1AuthnApi,
    platform,
  } = props;

  const platformName = platform.name;
  const platformId= platform.platformId;
  const organizationId = platform.organizationId;

  const [connection, setConnection] = useState();
  const [display, setDisplay] = useState(INPUT);

  const refresh = async () => {
    setDisplay(WAITING);
    const connections = await kashflowV1AuthnApi.query({
      organizationId,
      platformId,
    });
    switch (connections.length) {
      case 0:
        setConnection(null);
        setDisplay(SUCCESS);
        break;
      case 1:
        const connection = connections[0];
        setConnection(connection);
        setDisplay(SUCCESS);
        break;
      default:
        setDisplay(FAILURE);
        throw new Error("More than one connection found for this platform");
    }
  }

  useEffect(
    () => {refresh()},
    [],
  );

  const onConnect = () => {
    logger.debug("connection occurred");
    refresh()
  }
  const onTest = () => {
    logger.debug("connection test occurred");
  }
  const onDisconnect = () => {
    logger.debug("connection disconnect occurred");
    refresh()
  }

  return (
    !!connection ? el(
      ConnectedView,
      {
        display,
        platform,
        connection,
        onDisconnect,
        onTest,
      },
    ) : el(
      NotConnectedView,
      {
        display,
        platform,
        onConnect,
      },
    )
  )
}

const find = sequence => key => value => {
  const platform = S.find ( item => S.equals (S.prop (key) (item)) (value)) ( sequence);
  logger.debug("platform", platform);
  return S.maybeToNullable (platform);
}

const connector = connect(
  (state, ownProps) => {
    const platformId = ownProps.match.params.pid;
    return {
      platform: find (state.platforms) ("platformId") (platformId),
    }
  }
)

const Controller = withRouter(connector(withKashflowV1AuthnApi(Controller_)));

export {
  find,
  Controller,
}
