// @flow
import React, {
  useState,
} from "react";
import * as R from "ramda";
import Form from "react-bootstrap/Form";

import {
  type AddressBookEntry,
} from "../Entity/Types.js";


const LocalAccounts = (props: {
  localAccounts: Array<string>,
}) => {

  const localAccounts = R.map(
    la => <li key={la} >{la}</li>,
    props.localAccounts,
  );

  return (
    <ul>
    {localAccounts}
    </ul>
  );
}


const DeleteForm = (props: {
  addressBookEntries: Array<AddressBookEntry>,
  callBack: Function,
}) => {

  const [checked, setChecked] = useState(false);

  const localAccounts = R.map(
    abe => abe.localAccount,
    props.addressBookEntries,
  );

  const handleClick = (e) => {
    const isChecked = e.target.checked;
    setChecked(isChecked);
    props.callBack(isChecked);
  }

  return (
    <>
    <h2>Confirm deletions</h2>
    <p>You are about to delete:</p>
    <LocalAccounts
      localAccounts={localAccounts}
    />
    <Form>
    <Form.Group>
    <Form.Label>Confirm</Form.Label>
    <Form.Control type="checkbox" checked={checked} onChange={handleClick}/>
    <Form.Text>Tick the box to confirm</Form.Text>
    </Form.Group>
    </Form>
    </>

  );
}


export {
  DeleteForm,
}
