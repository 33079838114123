// @flow
import React from "react";
import Button from "react-bootstrap/Button";



const View = (props: {
  onNext?: Function,
  nextValue?: string,
  onBack?: Function,
  backValue?: string,
  onSkip?: Function,
  skipValue?: string,
  children?: Function,
}) => {
  const {
    onNext,
    nextValue,
    onBack,
    backValue,
    onSkip,
    skipValue,
    children,
  } = props;

  const backButton =  !!onBack ?
    <Button role="back" onClick={onBack} className="mr-2">{backValue}</Button>
    : null;

  const nextButton =  !!onNext ?
    <Button role="next" onClick={onNext} className="mr-2">{nextValue}</Button>
    : null;

  const skipButton = !!onSkip ?
    <Button role="skip" variant="link" onClick={onSkip} className="mr-2">{skipValue}</Button>
    : null;

  return (
    <>
    {children}
    {backButton}
    {nextButton}
    {skipButton}
    </>
  );
}

export {
  View,
}
