// @flow

import React from "react";
import logo from "./hammock_logo.svg";
import Button from "react-bootstrap/Button";

const View = (props: {
  hammockGLAddress: string,
}) => {
  const {
    hammockGLAddress,
  } = props;

  return (
    <>
     <h2>Setup Complete</h2>
     <p>Below is the address that Hammock needs to be able to update your
    accounts.</p>
     <h3>{hammockGLAddress}</h3>
     <p>Click the button below to return to Hammock</p>
     <div><Button variant="light" size="lg" href="https://secure.usehammock.com/home"><img src={logo} alt="Hammock logo"/></Button></div>
    </>
  )

}


export {
  View,
}
