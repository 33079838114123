// @flow
import React, {
  createElement as el,
  useEffect,
  useState,
} from "react";

import {
  withMartletOrganizationApi,
} from "./../../../Api/MartletOrganization";
import {
  type BmbixAddress,
} from "./../../../Entity/Types.js";
import { useMachine } from "@xstate/react";
import { createMachine } from "xstate";
import {
  Spinner,
} from "./../../../Widgets/Toast";
import {
  generateAliasMap,
} from "./../../../Util/alias";

import { View } from "./view.js";

const fetchingMachine = createMachine({
  id: "fetchingMachine",
  initial: "waiting",
  states: {
    waiting: {
      on: {
        SUCCESS_HAPPENED: "success",
        FAILURE_HAPPENED: "failure",
      }
    },
    success: {
      on: {
        WAITING_HAPPENED: "waiting",
      }
    },
    failure: {
      on: {
        RESET_HAPPENED: "input"
      }
    },
    input: {
      on: {
        SUBMIT_HAPPENED: "waiting"
      }
    },
  },
});

const Controller = withMartletOrganizationApi((props: {
  martletOrganizationApi: Object,
  address: BmbixAddress,
  messageId: string,
}) => {
  const {
    martletOrganizationApi,
    address,
    messageId,
  } = props;
  const organizationId = address.organizationId;
  const addressId = address.id;
  const [receivedMessage, setReceivedMessage] = useState();
  const [rejectedMessage, setRejectedMessage] = useState();
  const [acceptedMessage, setAcceptedMessage] = useState();
  const [ aliasMap, setAliasMap ] = useState([]);
  const [ refresh_, setRefresh_ ] = useState(false);
  const [ state, send ] = useMachine(fetchingMachine);

  useEffect(
    () => {
      (
      async () => {
        try {
          send({type: "WAITING_HAPPENED"})

          const receivedMessage_ = await martletOrganizationApi.getReceivedMessage({
            organizationId,
            addressId,
            messageId,
          });
          setReceivedMessage(receivedMessage_);
          const addressIds = [
            receivedMessage_.fromAddressId,
            receivedMessage_.toAddressId,
          ];

          const addressProfiles: Array<AddressProfile> = await martletOrganizationApi.getAddressProfiles({addressIds})
          console.log("addressProfiles:", addressProfiles);
          const aliasMap = generateAliasMap(addressProfiles);
          console.log("aliasMap:", aliasMap);
          setAliasMap(aliasMap);


          if (!!receivedMessage_){
            switch ( receivedMessage_.status ) {
              case "pending":
                break;
              case "accepted":
                const acceptedMessage = await martletOrganizationApi.getAcceptedMessage({
                  organizationId,
                  addressId,
                  messageId,
                });
                setAcceptedMessage(acceptedMessage);
                break;
              case "rejected":
                const rejectedMessage_ = await martletOrganizationApi.getRejectedMessage({
                  organizationId,
                  addressId,
                  messageId,
                });
                setRejectedMessage(rejectedMessage_);
                break;
              default:
                console.log("message has unknown status");
            }
          } else {
            console.log("we have no receivedMessage_");
          }
          setRefresh_(false);


          send({type: "SUCCESS_HAPPENED"})
        } catch (error) {
          send({type: "FAILURE_HAPPENED"})
        }
      }
      )();
    },
    [addressId, organizationId, messageId, martletOrganizationApi, refresh_],
  );

  const onRefresh = e => setRefresh_(true)

  return state.value === "success"
  ? el (View, {
    receivedMessage,
    rejectedMessage,
    acceptedMessage,
    aliasMap,
    onRefresh,
  })
  : state.value === "waiting"
  ? <Spinner height="50" />
  : state.value === "failure"
  ? <p>Oops</p>
  : <p>Unknown state</p>;
});

export {
  Controller,
}

