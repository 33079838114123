// @flow
import React, { createElement as el } from "react";

const Controller = (props: {

}) => {

  return (
    <>Messages App</>
  )
}
export { Controller }
