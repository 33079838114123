// @flow
import {
  createElement as el,
  useEffect,
  useState,
}
from "react";
import log from "loglevel";
import {
  withRouter,
} from "react-router-dom";
import {
  connect,
} from "react-redux";
import * as S from "sanctuary";

import {
  type Platform,
} from "../../../Entity/Types.js";
import {
  withSageV1AuthnApi,
} from "../Api/";
import {
  ConnectedView,
  NotConnectedView,
} from "./View.js";
import {
  INPUT,
  WAITING,
  SUCCESS,
} from "../../../Util";

const logger = log.getLogger("Sage");

const Controller_ = (props: {
  platform: Platform,
  sageV1AuthnApi: Object,
}) => {
  const {
    sageV1AuthnApi,
    platform,
  } = props;
  logger.debug("platform:", platform);

  const platformName = platform.name;
  const platformId= platform.platformId;
  const organizationId = platform.organizationId;

  const [connection, setConnection] = useState();
  const [display, setDisplay] = useState(INPUT);

  const refresh = async () => {
    setDisplay(WAITING);
    const connection = await sageV1AuthnApi.select({
      organizationId,
      platformId,
    });
    if (!!connection) {
        setConnection(connection);
        setDisplay(SUCCESS);
    }
    else {
        setConnection(null);
        setDisplay(SUCCESS);
    }
  }

  useEffect(
    () => {refresh()},
    [],
  );

  const onConnect = () => {
    logger.debug("connection occurred");
    // refresh()
  }
  const onTest = () => {
    logger.debug("connection test occurred");
  }
  const onDisconnect = () => {
    logger.debug("connection disconnect occurred");
    refresh()
  }

  return (
    !!connection ? el(
      ConnectedView,
      {
        display,
        platform,
        connection,
        onDisconnect,
        onTest,
      },
    ) : el(
      NotConnectedView,
      {
        display,
        platform,
        onConnect,
      },
    )
  )
}

const find = sequence => key => value => {
  const platform = S.find ( item => S.equals (S.prop (key) (item)) (value)) ( sequence);
  const platform_ = S.maybeToNullable (platform);
  logger.debug("platform_", platform_);
  return platform_;
}

const connector = connect(
  (state, ownProps) => {
    const platformId = ownProps.match.params.pid;
    logger.debug("platformId", platformId)
    return {
      platform: find (state.platforms) ("platformId") (platformId),
    }
  }
)

const Controller = withRouter(connector(withSageV1AuthnApi(Controller_)));

export {
  find,
  Controller,
}
