import React, {
  useState,
  useEffect,
} from "react";
import * as R from "ramda";
import {
  withRouter,
} from "react-router-dom";
import {
  connect,
} from "react-redux";
import Button from "react-bootstrap/Button";
import {
  SyncIcon,
} from "@primer/octicons-react";
import { useMachine } from "@xstate/react";
import { createMachine } from "xstate";


import {
  type BmbixAddress,
} from "../Entity/Types.js";
import {
  withIsimudApi,
} from "../Api/Isimud";
import {
  ExtractionItemWithHeaderTable,
} from "./ExtractionItemWithHeaderTable.js";
import {
  Spinner,
} from "./../Widgets/Toast";


const fetchingMachine = createMachine({
  id: "fetchingMachine",
  initial: "input",
  states: {
    waiting: {
      on: {
        SUCCESS_HAPPENED: "success",
        FAILURE_HAPPENED: "failure",
      }
    },
    success: {
      on: {
        SUBMIT_HAPPENED: "waiting",
      }
    },
    failure: {
      on: {
        RESET_HAPPENED: "input"
      }
    },
    input: {
      on: {
        SUBMIT_HAPPENED: "waiting"
      }
    },
  },
});


const ExtractionsView_ = (props: {
  isimudApi: Object,
  address: BmbixAddress,
  match: Object,
}) => {

  const {
    address,
    isimudApi,
  } = props;

  const [extractionItemWithHeaders, setExtractionItemWithHeaders] = useState([]);
  const [ state, send ] = useMachine(fetchingMachine);

  const refresh = () => {
    send({type: "SUBMIT_HAPPENED"});
    isimudApi.getExtractionItemWithHeaders({
      organizationId: address.organizationId,
      addressId: address.id,
      fromDate: "2022-01-01",
    }).then(
      extractionItemWithHeaders => {
        setExtractionItemWithHeaders(extractionItemWithHeaders)
        send({type: "SUCCESS_HAPPENED"});
      }
    ).catch(
      error => {
        send({type: "FAILURE_HAPPENED"});
        console.log(error);
      }
    );
  }

  const extractionItemWithHeadersWidget = () => {
    return state.value === "input"
    ? null
    : state.value === "waiting"
    ? <Spinner height="50" />
    : state.value === "failure"
    ? <p>Unable to fetch extraction items.</p>
    : state.value === "success"
    ? <ExtractionItemWithHeaderTable
        items={extractionItemWithHeaders}
      />
    : <p>Unknown state</p>;

  }

  useEffect(
    refresh,
    [],
  );

  return (
    <>
      <h2>Extractions</h2>
      <p>Shows the progress of your messages as they move from your accounting
      platform to receipt by Bmbix.</p>
      <Button onClick={refresh}><SyncIcon /> Refresh</Button>
      {extractionItemWithHeadersWidget()}
    </>
  )
}

const connector = connect(
  (state, ownProps) => {
    const {
      match,
    } = ownProps;
    const addressId = match.params.addressId
    return {
      address: R.find(R.propEq("id", addressId))(state.addresses),
    }
  }
);

const ExtractionsView = withRouter(connector(withIsimudApi(ExtractionsView_)));

export {
  ExtractionsView,
}


