// @flow
import { useSelector } from "react-redux";
const ADD_ID_TOKEN = "ID_TOKEN_ADD";


const addIdToken = (idToken) => {
  return {
    type: ADD_ID_TOKEN,
    idToken: idToken,
  }
}


const idToken = (state=null, action) => {
  switch(action.type) {
    case ADD_ID_TOKEN:
      return action.idToken;
    default:
      return state;
  }
}

const useIdToken = () => {
  return useSelector(store => store.idToken )
}

export {
  addIdToken,
  idToken,
  useIdToken,
}
