// @flow
import {
  createElement as el,
} from "react";
import * as S from "sanctuary";
import {
  useParams,
} from "react-router-dom";

import {
  useAddress,
} from "./../../../State/Addresses.js";
import {
  View,
} from "./view.js";

import {
  usePlatformFromAddress,
} from "./../../../State/PlatformAssignments.js";

const Controller = (props :{}) => {

  const {
    addressId,
  } = useParams();
  const address = useAddress(addressId);
  const platform = S.maybeToNullable (usePlatformFromAddress(addressId));
  console.log("Hello platfrom", platform);

  return platform === null
  ? null
  : el ( View, { address, platform});

}


export {
  Controller,
}
