// @flow

import React, {
  useState,
  useEffect,
} from "react";
import * as R from "ramda";
import {
  withRouter,
} from "react-router-dom";
import {
  connect,
} from "react-redux";
import Button from "react-bootstrap/Button";
import {
  SyncIcon,
} from "@primer/octicons-react";
import * as S from "sanctuary";

import {
  type BmbixAddress,
} from "../Entity/Types.js";
import {
  CheckableInsertionsTable,
} from "../Widgets/CheckableInsertionsTable.js";
import {
  InsertionInstructionsModal,
} from "../Insertions/InsertionInstructionsModal.js";
import {
  withMartletOrganizationApi,
} from "../Api/MartletOrganization";
import {
  unique,
  generateAliasMap,
} from "../Util/alias";


const InsertView_ = (props: {
  address: BmbixAddress,
  martletOrganizationApi: Object,
}) => {
  const {
    address,
    martletOrganizationApi,
  } = props;
  const [checkedProcessables, setCheckedProcessables] = useState([]);
  const [showInsertModal, setShowInsertModal] = useState(false);
  const [processables, setProcessables] = useState([]);
  const [aliasedProcessables, setAliasedProcessables] = useState([]);

  const insertionModal = () => {
    if(showInsertModal){
      return (
          <InsertionInstructionsModal
            address={address}
            messageIds={checkedProcessables}
            onCancel={ () => setShowInsertModal(false)}
          />
      )
    } else {
      return null;
    }
  }

  const handleProcessMessages = (e) => {
      setShowInsertModal(true);
  }

  const refresh = async () => {
    try {
      console.log("Refreshing InsertView");
      const acceptances = await martletOrganizationApi.getDeliverableMessages({
        organizationId: address.organizationId,
        addressId: address.id,
      });
      const processables_ = R.filter(
        ra => (ra.toAddressId === address.id && ra.messageProcessedReceiptId === ""),
        acceptances,
      );
      setProcessables(processables_);

      // const aliasedProcessables = await decorateWithAlias (martletOrganizationApi) (extractor) (processables_);


      const addressIds = S.reject (x => typeof x === "undefined" ) (S.map (S.prop ("fromAddressId")) (processables_) );
      const uniqAddressIds = unique (x => x) (addressIds);
      const addressProfiles: Array<AddressProfile> = await martletOrganizationApi.getAddressProfiles({addressIds: uniqAddressIds})
      const aliasMap = generateAliasMap(addressProfiles);

      const aliasedProcessables = S.map (pr => {
        const alias = S.fromMaybe ("No alias found") (S.value (S.prop ("fromAddressId") (pr)) (aliasMap)) ;
        const obj = pr;
        return Object.freeze({obj, alias});
      })(processables_)


      setAliasedProcessables(aliasedProcessables)
    } catch (error) {
      console.log("boombangalang", error);
    }
  }

  useEffect(
    () => { refresh(); },
    [],
  )  // only run on page load

  return (
    <>
    <h2>Insert Messages</h2>
    <p>Insert one or more received messages into the accounting platform linked
    to this address.</p>
    <Button className="mr-2" onClick={refresh}><SyncIcon /> Refresh</Button>
    <Button className="mr-2" onClick={handleProcessMessages}>Process</Button>
    <CheckableInsertionsTable
      address={address}
      aliasedProcessables={aliasedProcessables}
      items={processables}
      checked={[]}
      callBack={(checked) => setCheckedProcessables(checked)}
    />
    {insertionModal()}
    </>
  )
}

const connector = connect(
  (state, ownProps) => {
    const {
      match,
    } = ownProps;
    const addressId = match.params.addressId
    return {
      address: R.find(R.propEq("id", addressId))(state.addresses),
    }
  }
);

const InsertView = withRouter(connector(withMartletOrganizationApi(InsertView_)));

export {
  InsertView,
}



