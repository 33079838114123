import React, {
  useRef,
  useState,
} from "react";

import Overlay from "react-bootstrap/Overlay";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import { CopyIcon } from "@primer/octicons-react";

import {
  copyToClipboard,
} from "../Util/copy.js";


const CopyClip =
  (props: {
    clip: string,
  }) =>
{
  const { clip } = props;
  const target = useRef(null);
  const [ show, setShow ] = useState(false);

  const onCopy =
    (clip: string) =>
    (event: Function) => {
      copyToClipboard(clip);
      setShow(true);
      setTimeout(() => setShow(false), 700);
    }

  return (
    <>
    <Button
      ref={target}
      style={{
        marginBottom: "14px",
        padding: "5px",
        backgroundColor: "transparent",
        borderColor: "transparent",
      }}
      onClick={onCopy(clip)}
    >
      <CopyIcon fill="slategrey" size="12" />
    </Button>
    <Overlay target={target.current} show={show} placement="top">
    {({
      placement: _placement,
      arrowProps: _arrowProps,
      show: _show,
      popper: _popper,
      hasDoneInitialMeasure: _hasDoneInitialMeasure,
      ...props
    }) => (
      <Badge
        {...props}
        style={{
          borderRadius: "5px",
          margin: "5px", backgroundColor: "#218cf2",
          color: "white",
          ...props.style
        }}
        bg="secondary"
      >Copied to clipboard</Badge>
    )}
    </Overlay>
    </>
  )
}


export default CopyClip;
export {
  CopyClip,
}
