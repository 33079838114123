// @flow
import React from "react";

import ReactTable from "react-table-6"
import "react-table-6/react-table.css"
import { Link } from "react-router-dom";

import { type Organization } from "../../../Entity/Types.js";

const View = (props: {
  organizations: Array<Organization>,
}) => {
  const {
    organizations,
  } = props;

  const columns = [
    {
      Header: "Certificate Issuer",
      accessor: "name",
    },
    {
      accessor: "id",
      Cell: ({row}) => {
        const path = `/v/organizations/${row.id}/certificates`;
        return (
          <Link to={path}>Certificates</Link>
        )
      }
    },
    {
      accessor: "id",
      Cell: ({row}) => {
        const path = `/v/organizations/${row.id}/validate-organization`;
        return (
          <Link to={path}>Verify an organization</Link>
        )
      }
    },
    {
      accessor: "id",
      Cell: ({row}) => {
        const path = `/v/organizations/${row.id}/validate-user`;
        return (
          <Link to={path}>Verify a user</Link>
        )
      }
    }

  ]

  return (
    <ReactTable
      className="-striped -highlight"
      data={organizations}
      columns={columns}
      defaultPageSize={10}
    />
  )
}


export {
  View
}
