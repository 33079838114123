// @flow
import React, { useState } from "react";
import AccessListsTable from "../AccessListsTable";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import { PlusIcon } from "@primer/octicons-react";
import ListModalAdd from "../ListModalAdd";


import { type AccessList } from "./../../entity.js";
import {
  WAITING,
  SUCCESS,
  FAILURE,
} from "./../../../../Util";


function AddList (props: {
  owner: string,
}) {
  const {
    owner,
  } = props;
  const [showModal, setShowModal] = useState(false);

  return showModal ?
    <ListModalAdd owner={owner} onDone={() => setShowModal(false)} /> :
    <Button onClick={e => setShowModal(true)}><PlusIcon /> Add</Button>;
}

const View = (props: {
  fetchingState: Function,
  owner: string,
  accessLists: Array<AccessList>,
}) => {
  const {
    fetchingState,
    owner,
    accessLists,
  } = props;

  return fetchingState.value === "waiting"
  ? <p>Waiting</p>
  : fetchingState.value === "success"
  ? <>
    <ButtonToolbar className="float-right">
    <AddList owner={owner}/>
    </ButtonToolbar>
    <h2>Access Lists</h2>
    <p>Control who can send messages to you. There are allow-lists and
    deny-lists. You can have many lists of each type. Only one list is active at
    any time. If you have no active list, then you can receive messages from any
    sender. This is discouraged.</p>
    <AccessListsTable accessLists={accessLists}/>
    </>
  : fetchingState.value === "failure"
  ? <p>Ooops, something went wrong.</p>
  : fetchingState.value === "failure403"
  ? <>
    <p>It looks like you need more permissions to do this.</p>
    <p>Log trace: {fetchingState.context.error.code}</p>
    <p>Information: {fetchingState.context.error.description}</p>
    </>
  : null
}

export { View as default };
