// @flow
import React from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

import {
  Controller as CertificatesTable
} from "./../CertificatesTable/controller.js";
import { type Certificate } from "../../entity.js";

const View = (props: {
  certificates: Array<Certificate>,
  organizationId: string,
}) => {

  const {
    certificates,
    organizationId,
  } = props;

  const validateUserPath = `/v/organizations/${organizationId}/validate-user`;
  const validateOrganizationPath = `/v/organizations/${organizationId}/validate-organization`;

  return <>
    <h2>Certificates</h2>
    <Link to={validateUserPath}><Button className="mr-2">Verify User</Button></Link>
    <Link to={validateOrganizationPath}><Button>Verify Organization</Button></Link>
    <CertificatesTable certificates={certificates} organizationId={organizationId}/>
    </>
}

const FailureView = (props: {
  onResetAfterFailure: Function,
}) => {
  const {
    onResetAfterFailure
  } = props;

  return (
    <>
    <p>Ooops, looks like something went wrong</p>
    <Button onClick={onResetAfterFailure}>Retry</Button>
    </>
  )
}

export {
  FailureView,
  View,
};
