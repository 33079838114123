// @flow
import React, {
  useEffect,
  useState,
} from "react";
import {
  withRouter,
  Link,
} from "react-router-dom";
import ReactTable from "react-table-6";
import { useDispatch } from "react-redux";
import "react-table-6/react-table.css";
import * as S from "sanctuary";
import * as $ from "sanctuary-def";
import {
  CheckIcon,
  XIcon,
  AlertIcon,
  HourglassIcon,
  NoteIcon,
  SyncIcon,
} from "@primer/octicons-react"
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";

import {
  includesText,
} from "../Util/filter.js";
import {
  withMartletOrganizationApi,
} from "../Api/MartletOrganization";
import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "../Util";
import {
  unique,
  generateAlias,
} from "../Util/alias";
import { BmbixDateTime } from "./../Widgets/";
import {
  Spinner,
  CenteredSpinner,
} from "./../Widgets/Toast.js";
import { type AddressProfile } from "./../Entity/Types";
import { BmbixImage } from "./../Widgets/BmbixImage";
import {
  useAddressProfiles,
} from "./../State/AddressProfiles.js";
import {
  refreshAddressProfilesThunk,
} from "./../Thunks/address-profiles.js";
import { useToken } from "./../State";


const ReceivedMessageTable = (props: {
  decoratedMessages: Array<Object>,
  organizationId: string,
  addressId: string,
}) => {

  const {
    decoratedMessages,
    organizationId,
    addressId,
  } = props;

  const columns = [
    {
      Header: "To",
      accessor: "alias",
      filterable: true,
      Cell: ({original}) => {
        const toAddressId = original.toAddressId;
        const src = original.src;
        const pathname = `/profiles/address/${toAddressId}`;
        const toAddressTooltip = <Tooltip>{toAddressId}</Tooltip>
        return (
          <>
          <OverlayTrigger overlay={toAddressTooltip} placement="bottom" delay={{show: 250, hide: 400}} >
          <Link to={pathname}><BmbixImage src={src} />{original.alias}</Link>
          </OverlayTrigger>
          </>
        )
      },
    },
    {
      Header: "System Id",
      accessor: "systemId",
      show: false
    },
    {
      Header: "Message Type",
      accessor: "messageType",
      filterable: true,
    },
    {
      Header: "Sender Ref",
      accessor: "senderReference",
      filterable: true,
    },
    {
      Header: "Submitted At",
      accessor: "submissionTimestamp",
      Cell: ({value}) => !!value ? <BmbixDateTime timeStamp={value} />: null,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({row}) => {
        const status = row["status"];
        return status === "accepted" ? <CheckIcon /> :
          status === "rejected" ? <XIcon /> :
          status === "pending" ? <HourglassIcon />:
          <AlertIcon />
      }
    },
    {
      accessor: "messageId",
      Cell: ({original}) => {
        const messageIdTooltip = <Tooltip>{original["messageId"]}</Tooltip>
        const pathname = `/u/organizations/${organizationId}/addresses/${addressId}/messages-status/${original["messageId"]}`;
        return (
          <>
          <OverlayTrigger overlay={messageIdTooltip} placement="bottom" delay={{show: 250, hide: 400}} >
          <Link to={pathname}><Button><NoteIcon /> Status</Button></Link>
          </OverlayTrigger>
          </>
        )
      },
    },
  ]

  return (
    <ReactTable
      className="-striped -highlight"
      data={decoratedMessages}
      showPagination={false}
      pageSize={S.size (decoratedMessages)}
      columns={columns}
      defaultFilterMethod={includesText}
      // defaultSorted={[{id:"XXXXXXXXXXXXXXXXXXheader.date", desc: true}]}
      defaultSorted={[{id: "systemId", desc: true}]}
    />
  );
}


const SubmissionsView_ = (props: {
  martletOrganizationApi: Object,
  match: Object,
}) => {
  const {
    match,
    martletOrganizationApi,
  } = props;
  const organizationId = match.params.organizationId;
  const addressId = match.params.addressId;
  const addressProfiles = useAddressProfiles();
  const dispatch = useDispatch();
  const token = useToken();
  const [ receivedMessages, setReceivedMessages ] = useState([]);
  const [ pointer, setPointer ] = useState();
  const [ totalRecordCount, setTotalRecordCount ] = useState(-1);
  const [ fetchedRecordCount, setFetchedRecordCount ] = useState(0);
  const [ display, setDisplay ] = useState(INPUT);
  const [ displayFetching, setFetchingDisplay ] = useState(INPUT);
  const limit = 25;

  // const aliasMap = generateAliasMap(addressProfiles);

  const updateReceivedMessage =
    addressProfiles =>
    receivedMessage =>
  {
    const addressId = S.prop ("toAddressId") (receivedMessage);
    const maybeAddressProfile = S.value (addressId) (addressProfiles);
    const maybeAlias = S.map (generateAlias) (maybeAddressProfile);
    const alias = S.fromMaybe ("No alias found") (maybeAlias);
    const maybeOrgId = S.map (S.prop ("organizationId")) (maybeAddressProfile)
    const orgId = S.fromMaybe ("") (maybeOrgId);
    const src =
    `https://bmbix-images.s3.eu-west-2.amazonaws.com/organization/${orgId}`;
    // console.log("src is now: ", src);
    // const updatedReceivedMessage = Object.assign({}, receivedMessage, {src, alias});
    const updatedReceivedMessage = {
      toAddressId: receivedMessage.toAddressId,
      src,
      alias,
      systemId: receivedMessage.systemId,
      messageType: receivedMessage.messageType,
      senderReference: receivedMessage.senderReference,
      submissionTimestamp: receivedMessage.submissionTimestamp,
      status: receivedMessage.status,
      messageId: receivedMessage.messageId,

    }
    return updatedReceivedMessage;
  }

  const decoratedMessages = S.map (updateReceivedMessage(addressProfiles)) (receivedMessages);

  const fetchRecords = async (mode) => {
    // mode = initial, next, reset
    setFetchingDisplay("FETCHING");

    const receivedMessagesResponse = mode !== "next"
    ? await martletOrganizationApi.getReceivedMessages({
      organizationId,
      addressId,
      limit,
    })
    : await martletOrganizationApi.getReceivedMessages({
      organizationId,
      addressId,
      limit,
      pointer,
    });

    const receivedMessages_ = receivedMessagesResponse.receivedMessages;

    setPointer(
      S.maybeToNullable (
        S.map (S.prop ("systemId")) (S.last (receivedMessages_))));
    setTotalRecordCount(receivedMessagesResponse.selectionMetadata.count);

    switch (mode) {
      case "initial":
      case "next":
        setReceivedMessages(prev => prev.concat(receivedMessages_));
        setFetchedRecordCount(prev => S.add (prev) (S.size(receivedMessages_)));
        break;
      case "reset":
        setReceivedMessages(receivedMessages_);
        setFetchedRecordCount(S.size(receivedMessages_));
        break;
      default:
        throw new Error("found a case we didn't like");
    }

    const thunk = refreshAddressProfilesThunk(token)(S.justs (S.map (S.get (S.is ($.String)) ("toAddressId"))(receivedMessages_)));
    dispatch(thunk);

    setDisplay(SUCCESS);
    setFetchingDisplay("DONE");
  }

  useEffect(
    () => {fetchRecords("initial")},
    [],
  );

  const isLastRecordSet = () => S.equals (fetchedRecordCount) (totalRecordCount);

  const input = display === INPUT ? (
    <></>
  ): null;

  const success = display === SUCCESS ? (
    <>
    <Button className="mr-2" onClick={e => fetchRecords("reset")}><SyncIcon /> Refresh</Button>
    <>
     { displayFetching === "FETCHING" && <Spinner height="25"/> }
     { displayFetching !== "FETCHING" &&
     <Button disabled={isLastRecordSet()} onClick={e => fetchRecords("next")}>Load more</Button>
     }

    </>
    <div>Showing {fetchedRecordCount} of {totalRecordCount} records found.</div>
    <ReceivedMessageTable
      organizationId={organizationId}
      addressId={addressId}
      decoratedMessages={decoratedMessages}
    />
    </>
  ): null;

  const failure = display === FAILURE ? (
    <p>Oops something went wrong</p>
  ): null;

  return (
    <>
    <h2>Submitted for delivery</h2>
    {input}
    {success}
    {failure}
    <div><br/></div>
    </>
  );
}

const SubmissionsView = withMartletOrganizationApi(
  withRouter(SubmissionsView_));

export {
  SubmissionsView,
}
