// @flow
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import EntriesTable from "../EntriesTable";
import EntriesModalAdd from "../EntriesModalAdd";
import {
  PlusIcon
} from "@primer/octicons-react"
import { type AccessListEntry } from "./../../entity.js";

const AddEntry = (props: {
  owner: string,
  list: string,
}) => {
  const {
    owner,
    list,
  } = props;

  const [showModal, setShowModal] = useState(false);

  return showModal ?
    <EntriesModalAdd owner={owner} list={list} onDone={() => {setShowModal(false)}}/> :
    <Button onClick={e => setShowModal(true)}><PlusIcon /> Add</Button>;
}


const View = (props: {
  owner: string,
  list: string,
  entries: Array<AccessListEntry>,
}) => {
  const {
    entries,
    owner,
    list,
  } = props;
  console.log("entries::::", entries);

  return (
    <>
    <ButtonToolbar className="float-right">
    <AddEntry owner={owner} list={list}/>
    </ButtonToolbar>
    <h3>Entries</h3>
    <EntriesTable owner={owner} list={list} entries={entries}/>
    </>
  )
}

export { View as default }
