// @flow

import React, {
  createElement as el,
} from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {
  assign,
  createMachine,
  send
} from "xstate";
import {
  useMachine,
} from "@xstate/react";
import * as S from "sanctuary";

import {
  useToken,
} from "./../../../State";
import {
  Platform,
} from "./../../../Entity/Types.js";
import {
  withXeroV1AuthzApi,
} from "./../../../Api/XeroV1Authz";
import {
  Spinner,
} from "./../../../Widgets/Toast";


const stateChart = {
  id: "CreateTenantMapping",
  context: {},
  initial: "idle",
  states: {
    idle: {
      on: {
        CONFIGURE: assign(
          (context, event) => ({xeroTenantId: event.xeroTenantId}),
        ),
        SUBMIT: {
          target: "working",
        }
      }
    },
    working: {
      invoke: {
        id: "Invoke create tenantMapping",
        src: "createTenantMapping",
        onDone: {
          actions: [
            assign((context, event) => ({result: event.data})),
            send("SUCCESS"),
          ],
        },
        onError: {
          actions: [
            send("FAILURE"),
          ],
        }
      },
      on: {
        SUCCESS: {
          target: "success",
        },
        FAILURE: {
          target: "failure",
        },
      },
    },
    success: {
      type: "final",
    },
    failure: {
      type: "final",
    }
  }
};


const Controller = (props: {
  xeroV1AuthzApi: Function,
  platfrom: Platform,
  tenants: Array<AuthenticatedTenant>,
  onCancel: Function,
  onUpdate: Function,
}) => {
  const {
    xeroV1AuthzApi,
    platform,
    tenants,
    onCancel,
    onUpdate,
  } = props;
  const token = useToken();

  const defaultValue = tenants[0].xeroTenantId;

  const config = {
    services: {
      createTenantMapping: (context, event) => xeroV1AuthzApi.insertMapping({
        token,
        platformId: platform.platformId,
        xeroTenantId: context.xeroTenantId,
      })
    }
  }

  const machine = createMachine(stateChart, config);
  const machineWithContext = machine.withContext({
    xeroTenantId: defaultValue,
    tenants,
  });
  const [ state, sendEvent ] = useMachine(machineWithContext);

  const handleSelectTenant = e => {
    const xeroTenantId = e.target.value;
    sendEvent({
      type: "CONFIGURE",
      xeroTenantId,
    });
  }

  const handleSubmit = e => {
    sendEvent({type: "SUBMIT"});
  }

  const handleCancel = e => {
    onCancel();
    onUpdate();
  }

  const view = el(
    View,
    {
      onCancel: handleCancel,
      onChange: handleSelectTenant,
      onSubmit: handleSubmit,
      state,
    },
  )

  return view
}

const View = (props: {
  onCancel: Function,
  onChange: Function,
  onSubmit: Function,
  state: Object,
}) => {

  const {
    onCancel,
    onChange,
    onSubmit,
    state,
  } = props;

  const tenants = state.context.tenants;
  const defaultValue = state.context.xeroTenantId;

  const options = S.map (tenant => <option value={tenant.xeroTenantId}>{tenant.tenantName}</option>) (tenants);

  const idleBody = <>
    <Form.Control
      as="select"
      onChange={onChange}
      defaultValue={defaultValue}
    >
      {options}
    </Form.Control>
  </>
  const idleButtons = <>
    <Button onClick={onSubmit}>Submit</Button>
    <Button onClick={onCancel}>Cancel</Button>
  </>

  const workingBody = <Spinner height="50" />
  const workingButtons = <>
    <Button onClick={onCancel}>Cancel</Button>
  </>

  const successBody = <p>Success</p>
  const successButtons = <>
    <Button onClick={onCancel}>Dismiss</Button>
  </>

  const failureBody = <p>Ooops something has gone wrong.</p>
  const failureButtons = <>
    <Button onClick={onCancel}>Dismiss</Button>
  </>

  const body = state.value === "idle"
  ? idleBody
  : state.value === "working"
  ? workingBody
  : state.value === "success"
  ? successBody
  : state.value === "failure"
  ? failureBody
  : <p>Unknown state</p>;

  const buttons = state.value === "idle"
  ? idleButtons
  : state.value === "working"
  ? workingButtons
  : state.value === "success"
  ? successButtons
  : state.value === "failure"
  ? failureButtons
  : <p>Unknown state</p>;

  return <Modal show={true} onHide={onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Assign tenant</Modal.Title>
        </Modal.Header>
        <Modal.Body> {body} </Modal.Body>
        <Modal.Footer> {buttons} </Modal.Footer>
        </Modal>
}

const TenantMappingCreateModal = withXeroV1AuthzApi(Controller);

export {
  Controller,
  TenantMappingCreateModal,
}
