// @flow
import React from "react";
import Button from "react-bootstrap/Button";
import {
  CheckableExtractionCandidatesTable,
} from "../../Addresses/CheckableExtractionCandidatesTable.js";

import {
  type ExtractionCandidateReport,
} from "../../Entity/Types.js";
import {
  Heading as ECRHeading,
} from "../ECRHeading.js";


const View = (props: {
  report: ExtractionCandidateReport,
  onSubmit: Function,
  onCancel: Function,
  setCheckedRefs: Function,
}) => {

  const {
    report,
    onSubmit,
    onCancel,
    setCheckedRefs,
  } = props;




  return (
    <>
    <ECRHeading header={report.header}/>
    <Button aria-label="Extract" onClick={onSubmit} className="mr-2">Extract</Button>
    <Button aria-label="Cancel" onClick={onCancel} className="mr-2">Cancel</Button>
    <CheckableExtractionCandidatesTable
      items={report.entries}
      checked={[]}
      callBack={setCheckedRefs}
    />
    </>
  );


}

export {
  View,
}
