// @flow
import React from "react";

import { useParams } from "react-router-dom";

import { Controller as EncryptionApp } from "./../EncryptionApp/controller";

const PkiView = (props: {

}) => {

  const { organizationId } = useParams();

  return (
    <>
    <EncryptionApp resourceType="organizations" resourceId={organizationId} />
    </>
  )
}


export {
  PkiView
}
