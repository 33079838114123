// @flow
import {
  createElement as el,
} from "react";

import {
  View,
} from "./View.js";
import {
  removeTokens,
} from "../../Tokens.js";

const Controller = (props: {
}) => {

  const client_id = "1lpdc7hab1qtjcbb4hlac9ci4u";
  const logout_uri = window.location.origin;

  const logoutUrl= `https://auth2.bmbix.com/logout?client_id=${client_id}&logout_uri=${logout_uri}`;
  const onSubmit = () => removeTokens();


  /*
   * Hitting the Cognito logout url removes the Cognito cookie.
   * But it's up to us to delete any tokens we may have stashed.
   */
  return el(
    View,
    {
      onSubmit,
      logoutUrl,
    },
  );
}


export {
  Controller,
}
