// @flow
import React, { useEffect } from "react";

import { useParams } from "react-router";

import {
  getInboundDeliveryAdvice,
} from "../Api/MartletOrganization/Message.js";
import { useToken } from "./../State/Token.js";

import { useMachine } from "@xstate/react";
import {
  createMachine,
  assign,
} from "xstate";
import DeliveryAdviceWidget from "./../Widgets/DeliveryAdviceWidget";

const fetchingMachine = {
  id: "getInboundDeliveryAdvice",
  initial: "idle",
  context: {
    token: null,
    addressId: null,
    messageId: null,
    error: null,
    deliveryAdvice: null,
  },
  states: {
    idle: {
      on: {
        START: "working",
        INITIALIZE: {
          actions: assign({
            token: (context, event) => event.token,
            messageId: (context, event) => event.messageId,
            addressId: (context, event) => event.addressId,
          }),
        }
      }
    },
    working: {
      invoke: {
        id: "getdata",
        src: (context, event) => getInboundDeliveryAdvice({
          token: context.token,
          addressId: context.addressId,
          messageId: context.messageId,
        }),
        onDone: {
          target: "succeeded",
          actions: assign({
            deliveryAdvice: (context, event) => event.data
          }),
        },
        onError: {
          target: "failed",
          actions: assign({
            error: (context, event) => event.data
          }),
        },
      },
    },
    succeeded: {
      on: {
        RESET: "idle",
      },
    },
    failed: {
      on: {
        RESET: "idle",
      },
    },
  },
}

const machine = createMachine(fetchingMachine);

const InboundMessageStatusView = (props: {
}) => {
  const token = useToken();
  const { addressId, messageId } = useParams();
  const [ state, send ] = useMachine(machine);
  send({type: "INITIALIZE", token, addressId, messageId});

  useEffect(
    () => {
      send({type: "START"});
    },
    [send]
  );

  return state.value === "succeeded"
    ? <DeliveryAdviceWidget
        deliveryAdvice={state.context.deliveryAdvice}
      />
    : <p>Nothing to see here</p>
}


export {
  InboundMessageStatusView,
}
