// @flow
import React from "react";


import ReactTable from "react-table-6"
import "react-table-6/react-table.css"
import { Link } from "react-router-dom";

import { type Certificate } from "../../entity.js";
import { generateURIs } from "../../../Util/bri.js";

const View = (props: {
  organizationId: string,
  certificates: Array<Certificate>,
}) => {
  const {
    organizationId,
    certificates,
  } = props;
  console.log(">>>>>>>>>>>>>>>>>>>>, ", certificates)

  const columns = [
    {
      Header: "Id",
      accessor: "certId",
    },
    {
      Header: "Subject",
      accessor: "subjectURI",
      Cell: ({value}) => {
        const uris = generateURIs(value);
        return <Link to={uris.profileURI}>{value}</Link>
      }
    },
    {
      Header: "Issued on",
      accessor: "dateIssued",
    },
    {
      Header: "Verified by",
      accessor: "validatorURI",
      Cell: ({value}) => {
        const uris = generateURIs(value);
        return <Link to={uris.profileURI}>{value}</Link>
      }
    },
    /*
    {
      accessor: "certId",
      Cell: ({row}) => {
        const path = `/v/organizations/${organizationId}/certificates/${row.certId}/rescind`;
        return (
          <Link to={path}><Button>Rescind</Button></Link>
        )
      }
    },
    */
    {
      accessor: "certId",
      Cell: ({row}) => {
        const path = `/v/organizations/${organizationId}/certificates/${row.certId}`;
        return (
          <Link to={path}>view</Link>
        )
      }
    }

  ]

  return (
    <ReactTable
      className="-striped -highlight"
      data={certificates}
      columns={columns}
      defaultPageSize={10}
    />
  )
}


export {
  View
}
