// @flow
import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {
  useForm,
} from "react-hook-form";


const AddForm = (props: {
  onSubmit: Function,
}):any => {

  const {
    onSubmit,
  } = props;

  const {handleSubmit, register, formState: { errors }} = useForm();

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
    <Form.Group>
    <Form.Label>Name</Form.Label>
    <Form.Control {...register("organizationName", {required: true})} type="text"
    />
    <Form.Text>Enter the name of the new organization</Form.Text>
    {errors.organizationName && <span>This field is required</span>}
    </Form.Group>
    <Button variant="primary" type="submit" >Add organization</Button>
    </Form>
  );
}


export {
  AddForm,
}
