import {createElement as el } from "react";
import {View} from "./View.js";


const Controller = (props: {
  permissions: Function,
  checked: Array<string>,
  onChange: Function,
}) => {
  const {
    permissions,
    checked,
    onChange,
  } = props;

  return el(
    View,
    {
      permissions,
      checked,
      onChange,
    },
  )
}

export {
  Controller,
}
