// @flow

import React from "react";
import * as R from "ramda";
import {
  withRouter,
} from "react-router-dom";
import {
  connect,
} from "react-redux";

import {
  type BmbixAddress,
} from "../Entity/Types.js";
import {
  SingleExtractionWidget,
} from "./../Extractions/SingleExtractionWidget.js";


const ExtractOneView_ = (props: {
  address: BmbixAddress,
}) => {
  const {
    address,
  } = props;
  const organizationId = address.organizationId;
  const addressId = address.id;

  return (
    <>
      <SingleExtractionWidget
        organizationId={organizationId}
        addressId={addressId}
      />
    </>
  )
}

const connector = connect(
  (state, ownProps) => {
    const {
      match,
    } = ownProps;
    const addressId = match.params.addressId
    return {
      address: R.find(R.propEq("id", addressId))(state.addresses),
    }
  }
);

const ExtractOneView = withRouter(connector(ExtractOneView_));

export {
  ExtractOneView,
}
