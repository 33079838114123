// @flow
import Future from "fluture";
import * as S from "sanctuary";

import {
  ApiClient,
  SystemApi,
} from "@bmbix/bmb_martlet_organization_client";
import { callBack } from "./../../Api/CallBack";

import { createUserProfileFromGW } from "./../../Api/MartletOrganization/Profile.js";
import { createOrganizationProfileFromGW } from "./../../Api/MartletOrganization/Profile.js";


const searchUsers = (props: {
  terms: string,
  token: string,
}) => {
  const {
    terms,
    token,
  } = props;
  console.log("terms", terms, "token", token);

  const client = new ApiClient()
  let MartletOauth2 = client.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  const apiInstance = new SystemApi(client);

  const dataHandler = data => {
    const gws = data.user_profiles;
    const userProfiles = S.map (createUserProfileFromGW) (gws);
    return userProfiles;
  }

  return Future((reject, resolve) => {

    apiInstance.queryUserProfiles(
      terms,
      callBack(reject, resolve)(dataHandler),
    )

    return console.log;
  })
}

const searchOrganizations = (props: {
  terms: string,
  token: string,
}) => {
  const {
    terms,
    token,
  } = props;
  console.log("terms", terms, "token", token);

  const apiInstance = new SystemApi();
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;

  const dataHandler = data => {
    const gws = data.organization_profiles;
    const organizationProfiles = S.map (createOrganizationProfileFromGW) (gws);
    return organizationProfiles;
  }

  return Future((reject, resolve) => {

    apiInstance.queryOrganizationProfiles(
      terms,
      callBack(reject, resolve)(dataHandler),
    );

    return console.log;
  })
}
export { searchUsers, searchOrganizations }
