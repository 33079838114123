// @flow
import { useSelector } from "react-redux";
import * as R from "ramda";
import * as S from "sanctuary";
import { type Permission } from "../Entity/Types";

// importing anything from MartletOrganization causes a runtime failure.
//import { decoy } from "./../Api/MartletOrganization";
// Import from MO something not to do with permissions should also fail.
// yes - failed: import { getOrganization } from "./../Api/MartletOrganization";
// Now let's try to import from Klondike
// result: app did load! import { withKlondikeApi } from "./../Api/Klondike";
// Hypothesis: Something in MartletOrganization api is not working well with
// Permissions.

const PERMISSION_ADD = "PERMISSION_ADD";
const PERMISSION_DELETE = "PERMISSION_DELETE";


const addPermission = (permission: Permission):Object => {
  return {
    type: PERMISSION_ADD,
    permission: permission,
  }
}

const deletePermission = (permissionId: string):Object => {
  return {
    type: PERMISSION_DELETE,
    permissionId: permissionId,
  }
}


const permissions = (
  state:Array<Permission>=[],
  action:Object,
):Array<Permission> => {
  switch(action.type){
    case PERMISSION_ADD:
      return R.uniq(R.append(action.permission, state));
    case PERMISSION_DELETE:
      return R.uniq(R.filter(p => p.permissionId !== action.permissionId, state));
    default:
      return state;
  }
}

const usePermissions = (): Array<Permission> => {
  return useSelector(state => state.permissions)
}


const filterPermissions = (xpermissions: Array<Permission>) => (addressId: string) => {
    const resource = `bmbix://address/${addressId}`;
    const belongsToAddress = S.compose (S.equals (resource)) (S.prop ("resource"));
    return S.filter (belongsToAddress) (xpermissions);
  }


const usePermissionsByAddress = (addressId: string): Array<Permission> => {
  const allPermissions = useSelector(state => state.permissions);
  return filterPermissions (allPermissions) (addressId);
}

export {
  permissions,
  addPermission,
  deletePermission,
  usePermissions,
  usePermissionsByAddress,
}
