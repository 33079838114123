// @flow
import React from "react";
import { useParams } from "react-router-dom";

import { Controller as EncryptionApp } from "./../EncryptionApp/controller";



const PkiView = (props: {

}) => {

  const { addressId } = useParams();

  return (
    <EncryptionApp resourceType="addresses" resourceId={addressId} />
  )
}

export {
  PkiView,
}
