// @flow
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { createElement as el } from "react";

import { fork } from "fluture";
import { useMachine } from "@xstate/react";
import { createMachine } from "xstate";

import { withSelectByIssuer } from "../../Api";
import { Spinner } from "./../../../Widgets/Toast";
import { View, FailureView } from "./view.js";

const fetchingMachine = createMachine({
  id: "fetchingMachine",
  initial: "waiting",
  states: {
    waiting: {
      on: {
        SUCCESS_HAPPENED: "success",
        FAILURE_HAPPENED: "failure",
      }
    },
    success: {
      on: {
        WAITING_HAPPENED: "waiting",
      }
    },
    failure: {
      on: {
        RESET_HAPPENED: "waiting"
      }
    },
  },
});

const Controller = withSelectByIssuer(withRouter((props: {
  selectByIssuer: Function,
  match: Function,
}) => {
  const {
    selectByIssuer,
    match,
  } = props;

  const [ state, send ] = useMachine(fetchingMachine);
  const [certificates, setCertificates] = useState([]);

  const organizationId = match.params.organizationId;

  useEffect(
    () => {
      send({type: "WAITING_HAPPENED"})
      fork(
        error => {
          console.log(error)
          send({type: "FAILURE_HAPPENED"})
        }
      )(
        certificates_ => {
          setCertificates(certificates_)
          send({type: "SUCCESS_HAPPENED"})
        }
      )(selectByIssuer(organizationId));
    },
    [send, selectByIssuer, organizationId]
  );

  const onResetAfterFailure = e => send({type: "RESET_HAPPENED"});

  return  state.value === "waiting"
  ? el (Spinner, {height: "50"})
  : state.value === "success"
  ? el (
      View,
      {
        certificates,
        organizationId,
      }
    )
  : state.value === "failure"
  ? el (
      FailureView,
      {
        onResetAfterFailure,
      },
  )
  : null;

}))


export { Controller };
