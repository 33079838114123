// @flow

import Future, { chain } from "fluture";

import {
  ApiClient,
  OrganizationsApi,
  Oauth2Client as Oauth2ClientGW,
  Oauth2ClientRequest as Oauth2ClientRequestGW,

} from "@bmbix/bmb_martlet_organization_client";

import { store as reduxStore, } from "../../State/Store.js";
import { createOauth2ClientFromGW } from "./adapt.js";
import { type Oauth2Client } from "./../../Entity/Types.js";
import { addOauth2Client } from "./../store.js";

const create = (token: string) => (organizationId: string) => (resource:
  string): Future<Oauth2Client> => {

  const apiInstance = new OrganizationsApi();
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;

  const oauth2Client = Oauth2ClientGW.constructFromObject({ resource });
  const body = Oauth2ClientRequestGW.constructFromObject({
    oauth2_client: oauth2Client,
  })

  return Future((reject, resolve) => {

    apiInstance.createOauth2Client(body, organizationId, (error, data, response) => {
      if (error) {
        console.log("error", error);
        reject(error);
      } else {
        const gw = data.oauth2_client;
        const oauth2Client = createOauth2ClientFromGW(gw);
        resolve(oauth2Client);
      }
    });

    return console.log;
  });
}


const storeOauth2Client = (o: Oauth2Client) => {
  return Future((reject, resolve) => {
    try {
      reduxStore.dispatch(addOauth2Client(o))
      resolve(o)
    } catch (error) {
      reject(error)
    }
    return console.log;
  });
}


const createAndStore =
  (token:string) => (orgId: string) => (resource: string) =>
    chain (storeOauth2Client) (create (token) (orgId) (resource))


export {
  create,
  createAndStore,
}
