// @flow
import React from "react";
import Button from "react-bootstrap/Button";


import { Controller as RescindCertificateForm } from "./../RescindCertificateForm/controller.js";

const View = (props: {
  certificateId: string,
  display: string,
  onChange: Function,
  onSubmit: Function,
}) => {
  const {
    certificateId,
    display,
    onChange,
    onSubmit,
  } = props;

  return <>
    <h2>Rescind Certificate</h2>
    <h3>{certificateId}</h3>
    <RescindCertificateForm onChange={onChange} />
    <Button onClick={onSubmit}>Submit</Button>
    </>
}

export { View }

