// @flow
import React, {createElement as el,  useEffect, useState} from 'react';
import { connect } from 'react-redux'
import log from "loglevel";
import * as S from "sanctuary";
import "@psyycker/react-translation";
import {
  changeLocale,
  registerTranslations,
} from "@psyycker/react-translation";
import frenchFR from "./Translations/french-fr.json";
import chineseCN from "./Translations/chinese-CN.json";
import russianRU from "./Translations/russian-RU.json";


import { useMachine } from "@xstate/react";
import { createMachine } from "xstate";

import { useSettings } from "./State/Settings.js";
import {
  addToken,
  addIdToken,
  addAccount,
  removeBadTokenFlag,
  removeToken,
} from "./State";
import {
  addNotif,
} from "./bmbixredux.js";
import {
  INPUT,
} from "./Util";
import {
  cacheAccessToken,
  getMaybeCachedAccessToken,
  getMaybeURLAccessToken,
  cacheIdentityToken,
  getMaybeCachedIdentityToken,
  getMaybeURLIdentityToken,
} from "./Tokens";
import {
  instanceService,
} from "./Entity/InstanceService.js";
//  import './App.css';
import { View as Login } from "./Pages/Login.js";
import jwtDecode from "jwt-decode";
import { Site } from "./Site";

registerTranslations({
  "fr-FR": frenchFR,
  "zh-CN": chineseCN,
  "ru-RU": russianRU,
});



log.setDefaultLevel(log.levels.DEBUG);
log.getLogger("Util").setLevel(log.levels.ERROR, false);
log.getLogger("Util.notifications").setLevel(log.levels.ERROR, false);
log.getLogger("AddressBookEntry").setLevel(log.levels.ERROR, false);


const createAccount = idToken => {
  const claims = jwtDecode(idToken);
  console.log("claims::::::", idToken, claims)
  const account = instanceService.createAccount({
    id: claims.sub,
    name: claims["email"],
    email: claims["email"],
    cognitoUsername: claims["cognito:username"],
    sub: claims["sub"],
  });
  return account
}


const loginMachine = createMachine({
  id: "login",
  initial: "loggedOut",
  states: {
    loggedIn: {
      on: { LOGOUT_HAPPENED: "loggedOut" }
    },
    loggedOut: {
      on: { LOGIN_HAPPENED: "loggedIn" }
    }
  },
});


const Session_ = (props: {
  // token: string,
  addToken: Function,
  addIdToken: Function,
  addAccount: Function,
  addNotif: Function,
  badTokenFlag: boolean,
  removeBadTokenFlag: Function,
  token: string,
}) => {
  const {
    // token,
    addToken,
    addIdToken,
    addAccount,
    badTokenFlag,
    removeToken,
    removeBadTokenFlag,
  } = props;

  const [display, setDisplay] = useState(INPUT);
  const [redirectUri, setRedirectUri] = useState();
  const [ state, send ] = useMachine(loginMachine);
  const settings = useSettings();

  try {
    const locale = new Intl.Locale(settings.language);
    changeLocale(locale);
  } catch (e) {
    console.log("Unable to set language");
  }

  /*
   * Tokens are either in the url or localStorage.
   * If local Storage they may have expired.
   * Check the tokens for validity.
   * Create an account object and redux it.
   * Put the access token in redux.
   * Create the websocket.
   * At the end of a successful login, we have:
   *  - an account object in redux
   *  - an access tokenin redux
   *  - a websocket.
   *
   *  inputs: Redux, Localstorage, path(sort of).
   *  output: React component.
   *
   * complication: there may also be a gotoURL either in localStorage
   * or in the URL. This is the app state to move to after logging in.
   * If the app thinks it is about to be bounced off to a third party site
   * either for OAuth2 (self or third party) then it can set "gotoUrl" in
   * localStorage before the jump. Then when we come back we can pick up the
   * cached gotoUrl and navigate accordingly. This works in AppView.js where
   * there is a route to GotoLocation. We don't need to do anything here.
   * if there path has /goto-location and there is a gotoUrl in localStorage
   * then we are all golden.
   */
  useEffect(
    () => {
      const maybeCachedAccessToken = getMaybeCachedAccessToken();
      const maybeURLAccessToken = getMaybeURLAccessToken();
      console.log("token exists on disk?:", S.show (maybeCachedAccessToken));
      console.log("token exists in url?:", S.show (maybeURLAccessToken));

      const handleNoCachedAccessToken = (nothing) => {
        console.log("Unable to find a token to use to login.");
        send({type: "LOGOUT_HAPPENED"});
      }

      const handleCachedAccessToken = (justAccessToken) => {
        S.map (addToken) (justAccessToken);
        S.map (addIdToken) (getMaybeCachedIdentityToken());
        S.map
          (S.compose (addAccount) (createAccount))
          (getMaybeCachedIdentityToken())
        send({type: "LOGIN_HAPPENED"});
      }

      const handleNoURLAccessToken = (nothing) => {
        S.ifElse
        (S.isNothing)
        (handleNoCachedAccessToken)
        (handleCachedAccessToken)
        (maybeCachedAccessToken)
      }

      const handleURLAccessToken = (justAccessToken) => {
        S.map (cacheAccessToken)(justAccessToken);
        S.map (addToken)(justAccessToken);
        S.map (cacheIdentityToken)(getMaybeURLIdentityToken());
        S.map (addIdToken)(getMaybeURLIdentityToken());
        S.map
          (S.compose (addAccount) (createAccount))
          (getMaybeCachedIdentityToken())
        send({type: "LOGIN_HAPPENED"});
      }

      S.ifElse
        (S.isNothing)
        (handleNoURLAccessToken)
        (handleURLAccessToken)
        (maybeURLAccessToken)
    },
    [send, addIdToken, addToken, addAccount, ]
  )
  useEffect(
    () => {
      if ( badTokenFlag ) {
        //do stuff to make logout real
        console.log("logging out as badTokenFlag: ", badTokenFlag)
        removeToken(null);
        removeBadTokenFlag();
        setRedirectUri(window.location.pathname);
        send({type: "LOGOUT_HAPPENED"});
      }
    },
    [ badTokenFlag, send, removeToken, removeBadTokenFlag ]
  );

  return state.value === "loggedOut"
  ? el (
      Login,
      {
        display,
        redirectUri,
      },
    )
  : state.value === "loggedIn"
  ? el (
      Site,
      {},
    )
  : <p>Neither logged in nor logged out</p>;
}

const Session = connect(
  state => {
    return {
      token: state.token,
      badTokenFlag: state.badTokenFlag,
    }
  },
  {
    addToken,
    addIdToken,
    addAccount,
    addNotif,
    removeToken,
    removeBadTokenFlag,
  }
)(Session_);

export {
  Session,
};
