// @flow
import { createElement as el } from "react";
import { promise } from "fluture";
import { useMachine } from "@xstate/react";
import {
  assign,
  createMachine,
} from "xstate";
import { useToken } from "./../../../State";
import { View } from "./view.js";
import { create as createOauth2Client } from "./../../Api/create.js";
import { type OrganizationProxy } from "./../../../Entity/Types.js";


const modeMachine = createMachine({
  id: "modeMachine",
  initial: "button",
  states: {
    button: {
      on: {
        SHOW_MODAL: "modal",
      },
    },
    modal: {
      on: {
        HIDE_MODAL: "button",
      },
    },
  },
});


const fetchMachine = createMachine({
  id: "fetchMachine",
  initial: "unconfigured",
  context: {
    errors: undefined,
    oauth2Client: undefined,
    organizationProxy: undefined,
  },
  states: {
    unconfigured: {
      on: {
        CONFIGURE: {
          target: "idle",
          actions: assign({
            organizationProxy: (c, e) => e.organizationProxy
          }),
        },
      },
    },
    idle: {
      on: {
        SUBMIT: "working",
      }
    },
    working: {
      invoke: {
        id: "createNewOauth2Client",
        src: (c, e) => promise(
          createOauth2Client(e.token)(e.organizationId)(e.resource)
        ),
        onDone: {
          target: "success",
          actions: assign({
            oauth2Client: (c, e) => e.data,
          })
        },
        onError: {
          target: "failure",
          actions: assign({
            errors: (c, e) => e.data,
          })
        },
      },
    },
    success: {},
    failure: {},
  },
});


const NewClientCredentialsModal =
  (props: {
    organizationProxy: OrganizationProxy,
  }) =>
{

  const { organizationProxy } = props;
  const resource = `bmbix://organization-proxy/${organizationProxy.id}`;
  const organizationId = organizationProxy.organizationId;
  const token = useToken();
  const [ modeState, modeSend ] = useMachine(modeMachine);
  const [ fetchState, fetchSend ] = useMachine(fetchMachine);
  fetchSend({type: "CONFIGURE", organizationProxy});
  const onSubmit = data => fetchSend({
    type: "SUBMIT", token, organizationId, resource})
  const onCancel = e => modeSend({type: "HIDE_MODAL"});
  const onShowModalView = e => modeSend({type: "SHOW_MODAL"});

  return el(
    View,
    {
      fetchState,
      modeState,
      onShowModalView,
      onSubmit,
      onCancel,
    },
  );
}

export {
  NewClientCredentialsModal,
}
