// @flow
import React from "react";
import Button from "react-bootstrap/Button";
import * as S from "sanctuary";
import {
  Spinner,
} from "./../Widgets/Toast";
import { BmbixDateTime } from "./../Widgets/";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";

import { type PrivateKey } from "./entity";
import EnabledIcon from "./../Widgets/enabledIcon.js";

const mstring = value => {
  return S.ifElse (x => typeof x === "boolean") (e => e.toString()) (e =>
  "unknown") (value)
}

const enabled = (pk: PrivateKey ) =>
  S.fromEither (false) (S.encase (S.compose (S.not) (S.prop ("rescinded"))) (pk))


const SuccessView = (props: {
  onGeneratePrivateKey: Function,
  rescindPrivateKey: Function,
  privateKeys: Array<PrivateKey>,
}) => {
  const {
    onGeneratePrivateKey,
    privateKeys,
    rescindPrivateKey,
  } = props;
  console.log("privateKeys:", privateKeys);

  const columns = [
    {
      Header: "Fingerprint",
      accessor:  "fingerprint",
    },
    {
      Header: "Created At",
      accessor: "createdAt",
      Cell: ({value}) => !!value ? <BmbixDateTime timeStamp={value.toString()} />: null,
    },
    {
      Header: "Expires At",
      accessor: "expiresAt",
      Cell: ({value}) => !!value ? <BmbixDateTime timeStamp={value.toString()} />: null,
    },
    {
      Header: "Not rescinded",
      Cell: ({row}) => {
        return <EnabledIcon enabled={enabled(row._original)}/>
      }
    },
    {
      Cell: ({row}) => {
        return (
          <Button value={row._original.privateKeyId} onClick={rescindPrivateKey}>Rescind</Button>
        );
      }
    }
  ]

  return (
    <>
    <h2>Private Keys</h2>
    <Button onClick={onGeneratePrivateKey}>Generate Private Key</Button>
      <ReactTable
        className="-striped -highlight"
        defaultPageSize={10}
        data={privateKeys}
        columns={columns}
        defaultSorted={[
          {id: "createdAt", desc: true},
        ]}
      />
    </>
  )
}

const FailureView = (props: {

}) => {

  return (
    <>
    <p>Ooops, looks like something went wrong</p>
    </>
  )
}


const InputView = (props: {

}) => {

  return (
    <p> This is the input view</p>
  )
}

const WaitingView = (props: {

}) => {

  return (
    <Spinner height={"50"} />
  )
}
export {
  SuccessView,
  FailureView,
  WaitingView,
  InputView,
}
