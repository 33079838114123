// @flow
import * as S from "sanctuary";
import log from "loglevel";


const logger = log.getLogger("TableFilter");

const caseInsensitiveAnywhere =
  (filter, row, column) =>
{
        const re = S.regex ("g") (S.toLower (filter.value));
        console.log("filter", filter);
        console.log("row", row);
        console.log("column", column);
        console.log("re", re);
        return S.test (re) (S.toLower (row[filter.id]));
}

/*
 * default for react-table.
 */
const includesText = (filter: Object, row: Object, column: Object): boolean => {
  logger.debug("filter:", filter, "row:", row);
  const id = filter.pivotId || filter.id
  return row[id] !== undefined ? String(row[id]).includes(filter.value) : true
}

export {
  caseInsensitiveAnywhere,
  includesText,
}
