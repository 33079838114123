// @flow
import { createElement as el } from "react";
import { View } from "./view.js";

import { withRouter } from "react-router";


const Controller_ = (props: {
  match: Function,
}) => {

  const {
    match,
  } = props;

  return el(
    View,
    {
      match,
    },
  );
}

const Controller = withRouter(Controller_);


export {
  Controller,
}
