// @flow
import Future from "fluture";
import {
  ApiClient,
  AddressesApi,
} from "@bmbix/bmb_martlet_organization_client";

import { gwToAccessListActivation } from "./adapt.js";



const activateList = (token: string) => (owner: string) => (list: string): Future => {

  const apiInstance = new AddressesApi();
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;

  return Future((reject, resolve) => {
    console.log("list", list);
    apiInstance.activateAccessList(owner, list, (error, data, response) => {
      if ( error ) {
        console.log("error", error);
        reject(error);
      } else {
        const gw = data.access_list_activation;
        const accessListActivation = gwToAccessListActivation(gw);
        resolve(accessListActivation);
      }
    }
    );
    return console.log
  });
}

export { activateList }
