// @flow
import React, {
  useState,
  useEffect,
} from "react";
import {
  withRouter,
} from "react-router-dom";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import { fork } from "fluture";
import { useToken } from "./../../../State";
import { submitInsertionInstruction } from "./../../../Api/Isimud";


import {
  MessageNotesWidget,
} from "./../../../Widgets/MessageNotes";
import {
  markRead,
} from "./../../../Api/MartletOrganization/Address.js";
import {
  type BmbixAddress,
  type Message,
} from "./../../../Entity/Types.js";
import { selectStylesheetLocation } from "./util.js";
import Accordion from 'react-bootstrap/Accordion'
import Card from "react-bootstrap/Card";

import { Controller as MessageStatusWidget } from "./../MessageStatusWidget/controller.js";


type Props = {
}
class Pooter extends React.Component<Props> {

  constructor (props:Props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount () {
    console.log("Pooter mounting", this.ref.current);
    if(!!this.props.bmbixDoc){
      this.ref.current.appendChild(this.props.bmbixDoc);
    }
  }

  render () {
    return (
      <div ref={this.ref}/>
    )
  }
}



const INPUT = "input";
const WAITING = "waiting";
const SUCCESS = "success";
const FAILURE = "failure";


const MessageView_ = (props: {
  address: BmbixAddress,
  message: ?Message,
  match: Object,
}) => {
  const {
    address,
    message,
    match,
  } = props;
  const messageId = match.params.messageId;
  const addressId = address.id;
  const organizationId = address.organizationId;
  const [display, setDisplay] = useState(WAITING);
  const [theTransform, setTheTransform] = useState();
  const token = useToken();

  const handleInsert = e => {
    const promise = submitInsertionInstruction({
      token,
      organizationId,
      addressId,
      messageId,
    });
    promise.then(
      insertionInstruction => alert("sent insertion instruction")
    ).catch(
      error => console.error("error:", error)
    );
 }

  const handleMarkRead = e => {
    const action = markRead(token)(addressId)(messageId);
    fork(error => console.log(error))(messageReadReceipt =>
      console.log(messageReadReceipt))(action);
  }

  const refresh = () => {
    setDisplay(WAITING);

    const firstPromise = new Promise((resolve, reject) => {
      if (!!message) {
        console.log("%%%%%%%%%%%%%%%%%%%%%%%%%", message);
        resolve(message)
      } else {
        const error = new Error("No useful message yet");
        reject(error);
      }
    });
    firstPromise.then(
      message => {
        const stylesheetLocation =
          selectStylesheetLocation(message.contentMediaType);
        // const source = atob(message.content);
        const source = message.content;
        const promise = window.SaxonJS.transform({
          stylesheetLocation: stylesheetLocation,
          sourceText: source,
          destination: "raw",
        }, "async");

        promise.then(
          transform => {
            console.log("transform", transform);
            setTheTransform(transform.principalResult);
            setDisplay(SUCCESS);
          }
        ).catch(
          error => {
            console.log("error", error);
          }
        );
      }
    ).catch(
      error => {
        console.log("error", error);
        setDisplay(FAILURE);
      }
    );
  }

  useEffect(
    refresh,
    [],
  )

  const input = display === INPUT ? (
    null
  ): null;

  const waiting = display === WAITING ? (
    <p>Waiting ... </p>
  ): null;

  const success = ( display === SUCCESS && !! message ) ? (
    <>
        <Pooter bmbixDoc={theTransform}/>
    </>
      ): null;

  const failure = display === FAILURE ? (
    <p>Ooops! Something went wrong.</p>
  ): null;

  return (
    <>
    <Row>
    <Col>
    <Alert variant="info">
    This is a human-readable render of a purely digital accounting document. This document
    can be automatically inserted into your accounting systems without re-keying
    or scanning. <a
    href="https://bmbix.com">Find out how</a>.
    </Alert>
    </Col>
    </Row>
    <Row>
    <Col>
    {!!message && <h2>Document type: {message.contentMediaType}</h2>}
    </Col>
    </Row>

    <Row>
    <Col>
    <Accordion>
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey="0">
        Toggle Status Report
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        <Card.Body>
          <MessageStatusWidget address={address} messageId={messageId} />
        </Card.Body>
      </Accordion.Collapse>
    </Card>
    </Accordion>
    </Col>
    </Row>
    <Row className="mt-3">
    <Col lg={9} sm={9} md={9} xs={9} xl={9}>
    {input}
    {waiting}
    {success}
    {failure}
    </Col>
    <Col>
    <Button className="mr-2" onClick={handleMarkRead}>Mark Read</Button>
    <Button onClick={ handleInsert }>Insert</Button>
    <MessageNotesWidget messageId={messageId} addressId={addressId} />
    </Col>
    </Row>
    </>
  )

}


const MessageView = withRouter(MessageView_);

export {
  MessageView,
}
