// @flow
import React from "react";
import * as S from "sanctuary";
import { useDispatch } from "react-redux";
import { Route } from "react-router";

import { usePermissions } from "./../State/Permissions.js";
import { useToken } from "./../State/Token.js";
import {
  refreshAddressProfilesThunk,
} from "./../Thunks/address-profiles.js";
import { extractAddressId } from "./../Util/bri.js";
import {
  IdentifyPage,
  IndexPage,
  RolesPage,
  PrivacyPage,
  SettingsPage,
  PaymentMethodPage,
  PaymentMethodsPage,
  StripeSetupPage,
  Navbar,
} from "./Widgets";

const Prefetch = (props: {
}) => {
  const dispatch = useDispatch();
  const token = useToken();
  const permissions = usePermissions();

  const resourceBRIs= S.map (S.prop ("resource")) (permissions);
  const maybeAddressIds = S.map (extractAddressId) (resourceBRIs);
  const addressIds = S.justs (maybeAddressIds);
  dispatch(refreshAddressProfilesThunk(token)(addressIds));

  return null;
}

const View = (props: {
  match: Function,
}) => {

  return <>
    <Navbar />
    <Route path="/a"><Prefetch /></Route>
    <Route exact path="/a" component={IndexPage} />
    <Route exact path="/a/teams" component={RolesPage} />
    <Route exact path="/a/privacy" component={PrivacyPage} />
    <Route exact path="/a/identify" component={IdentifyPage} />
    <Route exact path="/a/settings" component={SettingsPage} />
    <Route exact path="/a/payment-methods" component={PaymentMethodsPage} />
    <Route exact path="/a/payment-methods/:paymentMethodId" component={PaymentMethodPage} />
    <Route exact path="/a/payment-gateways/stripe/setup-status" component={StripeSetupPage} />
   </>
}

export {
  View,
}


