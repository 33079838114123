// @flow
import uuid4 from "uuid/v4";
import { useSelector } from "react-redux";

const ACCESSLIST_MUTATION_UPDATE = "ACCESSLIST_MUTATION_UPDATE";


type MutationAction = {
  type: string,
  mutation: string,
  list: string,
}


const updateAccessListMutation = (list: string): MutationAction => {
  return {
    type: ACCESSLIST_MUTATION_UPDATE,
    mutation: uuid4(),
    list,
  }
}


const accessListsMutations = (state: Object={}, action: MutationAction): Object => {
  switch (action.type) {
    case ACCESSLIST_MUTATION_UPDATE:
      state[action.list] = action.mutation;
      console.log("state", state);
      return state;
    default:
      return state;
  }
}

const useAccessListMutation= (list: string): string => {
  return useSelector(store => store.accessListsMutations[list])
}

export {
  accessListsMutations,
  updateAccessListMutation,
  useAccessListMutation,
}
