// @flow
import { createElement as el } from "react";

import { View } from "./view.js";
import { type AccessList } from "./../../entity.js";


const Controller = (props: {
  accessLists: Array<AccessList>,
}) => {
  const {
    accessLists,
  } = props;

  return el(
    View,
    {
      accessLists,
    },
  );
}


export { Controller }
