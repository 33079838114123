// @flow
//
//
import React from "react";
import {
  createElement as el,
} from "react";
import {promise} from "fluture";
import * as S from "sanctuary";
import { useParams } from "react-router-dom";

import { useMachine } from "@xstate/react";
import { createMachine, assign } from "xstate";
import {
  Spinner,
} from "./../../../Widgets/Toast";
import { useToken } from "./../../../State";

import { fetchMessageContentType } from "./../api.js";
import { MessageContentTypeView } from "./message-content-type-view.js"

const api =
  (token: string) =>
  (organizationId: string) =>
  (id: string): Promise<MesssageContentType> =>
  promise(fetchMessageContentType(token)(organizationId)(id))

const apiSpecification = {
  id: "MartletOrganizationApi",
  initial: "unconfigured",
  context: {
    organizationId: undefined,
    id: undefined,
    token: undefined,
    messageContentType: undefined,
    errors: undefined,
  },
  states: {
    unconfigured: {
      on: {
        CONFIGURE: {
          target: "idle",
          actions: assign({
            organizationId: (c, e, m) => e.organizationId,
            id: (c, e, m) => e.id,
            token: (c, e, m) => e.token,
          }),
        }
      },
    },
    idle: {
      on: {
        START: "working",
      }
    },
    working: {
      invoke: {
        id: "fetchMessageContentType",
        src: (c, e) => api(c.token)(c.organizationId)(c.id),
        onDone: {
          target: "succeeded",
          actions: assign({
            messageContentType: (c, e) => e.data
          }),
        },
        onError: {
          target: "failed",
          actions: assign({
            errors: (c, e) => e.data
          }),
        },
      },
    },
    succeeded: {
      on: {
        RESET: "idle",
      },
    },
    failed: {
      on: {
        RESET: "idle",
      },
    },
  },
};

const apiStateMachine = createMachine(apiSpecification);

const MessageContentTypePage = (props: {
}) => {

  const { organizationId, id } = useParams();
  const token = useToken();
  const [ apiState, apiSend ] = useMachine(apiStateMachine)

  apiSend({type: "CONFIGURE", token, organizationId, id});
  apiSend({type: "START"});

  return apiState.value === "idle"
  ? <>Ready to go</>
  : apiState.value === "working"
  ? <Spinner height="50" />
  : apiState.value === "succeeded"
  ? <MessageContentTypeView
    messageContentType={apiState.context.messageContentType} />
  : apiState.value === "failed"
  ? <p>Oops</p>
  : <p>Unknown apiState</p>;
}


export {
  MessageContentTypePage,
}
