// @flow
import React from "react";
import Form from "react-bootstrap/Form";
import Datetime from 'react-datetime';
import Button from "react-bootstrap/Button";
import log from "loglevel";


const logger = log.getLogger("Pull");

const View = (props: {
    onSubmit: Function,
    onFromDateChange: Function,
    onToDateChange: Function,
    onContentMediaTypeChange: Function,
}) => {

  const {
    onSubmit,
    onFromDateChange,
    onToDateChange,
    onContentMediaTypeChange,
  } = props;
  return (
    <>
    <h2>Extract By Date Range</h2>
    <p>Use this form to extract all the available transactions between two
    dates.</p>
      <Form>
       <Form.Group>
         <Form.Label>Content media type</Form.Label>
         <Form.Control
           onChange={e => onContentMediaTypeChange(e.target.value)}
           as="select"
           name="contentMediaType"
           aria-label="Content media type"
         >
            <option value=""></option>
            <option value="bmbix-simplified-invoice-5">bmbix-simplified-invoice-5</option>
         </Form.Control>
         <Form.Text>Content Media Type</Form.Text>
       </Form.Group>
       <Form.Group>
         <Form.Label>From Date</Form.Label>
         <Datetime
           inputProps={{ "aria-label": "From date", }}
           onChange = {v => {
             logger.debug("date value:", v);
             const text = v.format("YYYY-MM-DD");
             onFromDateChange(text);
           }}
           dateFormat="YYYY-MM-DD"
           timeFormat={false}
         />
         <Form.Text>From date</Form.Text>
       </Form.Group>
       <Form.Group>
         <Form.Label>To date</Form.Label>
         <Datetime
           inputProps={{ "aria-label": "To date", }}
           onChange={v => {
             logger.debug("date value:", v);
             const text = v.format("YYYY-MM-DD");
             onToDateChange(text);
           }}
           dateFormat="YYYY-MM-DD"
           timeFormat={false}
         />
         <Form.Text>To date</Form.Text>
       </Form.Group>
      </Form>
      <Button aria-label="Generate" onClick={onSubmit} >Generate</Button>
    </>
  )
}

export {
  View,
}
