// @flow
import React from "react";
import ReactTable from "react-table-6"
import "react-table-6/react-table.css"
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Accordion from 'react-bootstrap/Accordion'
import Card from "react-bootstrap/Card";
import * as S from "sanctuary";
import type {
  CurrentChargeLine,
  PaymentMethod,
} from "./entity";
import type {
  PaymentMethodLink,
} from "./../Entity/Types.js";
import { Controller as PaymentMethodLinkDeleteWidget } from "./Widgets/PaymentMethodLinkDeleteWidget/controller.js";

import { Controller as BillingAddressHistory } from
"./Widgets/BillingAddressHistory/controller";
import { Controller as CurrentBillingAddress } from
"./Widgets/CurrentBillingAddress/controller";
import { Controller as SetBillingAddress } from
"./Widgets/SetBillingAddress/controller";

const InvoicePicker = (props: {
  invoices: Array<Invoice>,
  onChange: Function,
  reference: string,
}) => {
  const {
    invoices,
    onChange,
    reference,
  } = props;

  const buildOption = (invoice) =>
    <option
      key={invoice.header.reference}
      value={invoice.header.invoice}>{`Invoice ${invoice.header.reference}
      ${invoice.header.date}`}
    </option>

  return (
    <Form>
    <Form.Group>
    <Form.Label>Select Invoice</Form.Label>
    <Form.Control
      as="select"
      type="text"
      name="reference"
      onChange={onChange}
      placeholder="Select invoice"
      value={reference}
    >
    {
      S.map (buildOption) (invoices)
    }
    </Form.Control>
    <Form.Text>Select your invoice</Form.Text>
    </Form.Group>
    </Form>

  );
}

const PaymentMethodLinkWidget = ({
  paymentMethodLink,
}: {
  paymentMethodLink: ?PaymentMethodLink,
}) => {
  return !!paymentMethodLink
  ? <><p>UserId: {paymentMethodLink.paymentMethodUserId}, {paymentMethodLink.paymentMethodDescription}</p>
  <PaymentMethodLinkDeleteWidget onDone={e => console.log("Completed deletion: ", e)}/></>
  : <p>No payment method link</p>
}


const View = (props: {
  apiState: Function,
  currentChargeLines: Array<CurrentChargeLine>,
  onRefreshCurrentCharges: Function,
  organizationId: string,
}) => {
  const {
    apiState,
    organizationId,
    currentChargeLines,
    onRefreshCurrentCharges,
  } = props;

  return (
    <>
    <PaymentMethodLinkWidget paymentMethodLink={apiState.context.paymentMethodLink} />
    <Accordion>
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey="currentCharges">
        Current Charges
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="currentCharges">
        <Card.Body>
    <ButtonToolbar className="float-right" >
      <Button className="mr-2" onClick={e => console.log("Your statement will be sent to your designated purchases address")}>Statement</Button>
      <Button onClick={onRefreshCurrentCharges}>Refresh</Button>
    </ButtonToolbar>
     <h2>Current Charges</h2>
     <CurrentChargesTable currentChargeLines={currentChargeLines} />
        </Card.Body>
      </Accordion.Collapse>
    </Card>
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey="billing">
        Billing
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="billing">
        <Card.Body>
        <h2>Billing Address</h2>
        <p>Enter details of your billing address</p>
        <SetBillingAddress organizationId={organizationId}/>
        <CurrentBillingAddress organizationId={organizationId}/>
        <BillingAddressHistory organizationId={organizationId}/>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey="paymentMethods">
        Payment Methods
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="paymentMethods">
        <Card.Body>
    <ButtonToolbar className="float-right" >
      <Button onClick={e => console.log("Here we add a new payment method")}>Add</Button>
    </ButtonToolbar>
     <h2>Payment Methods</h2>
     <PaymentMethodsTable />
        </Card.Body>
      </Accordion.Collapse>
    </Card>
    </Accordion>
    </>
  );
}


const PaymentMethodsTable = (props: {
  paymentMethods: Array<PaymentMethod>,
}) => {
  const {
    paymentMethods,
  } = props;

  const columns = [
      {
        Header: "Quantity",
        accessor: "quantity"
      },
      {
        Header: "ProductId",
        accessor: "productId"
      },
      {
        Header: "Description",
        accessor: "description"
      },
      {
        Header: "UnitPrice",
        accessor: "unitPrice"
      },
      {
        Header: "LineTotal",
        accessor: "lineTotal"
      },
  ];

  return (
    <ReactTable
      data={paymentMethods}
      className="-striped -highlight"
      columns={columns}
      defaultPageSize={10}
    />
  )
}
const CurrentChargesTable = (props: {
  currentChargeLines: Array<CurrentChargeLine>,
}) => {
  const {
    currentChargeLines,
  } = props;
  console.log("currentChargeLines", currentChargeLines)

  const columns = [
      {
        Header: "Quantity",
        accessor: "quantity",
        className: "text-right",
      },
      {
        Header: "ProductId",
        accessor: "productId"
      },
      {
        Header: "Description",
        accessor: "description"
      },
      {
        Header: "UnitPrice",
        accessor: "unitPrice",
        className: "text-right",
      },
      {
        Header: "SubTotal",
        accessor: "subTotal",
        className: "text-right",
      },
      {
        Header: "LineTotal",
        accessor: "total",
        className: "text-right font-weight-bold",
      },
  ];

  return (
    <ReactTable
      data={currentChargeLines}
      className="-striped -highlight"
      columns={columns}
      defaultPageSize={10}
    />
  )
}



export {
  CurrentChargesTable,
  View,
}
