// @flow
import * as S from "sanctuary";
import * as R from "ramda";
import log from "loglevel";
import {
  chunkPromise,
  PromiseFlavor
} from "chunk-promise";

import {
  ApiClient,
  PlatformAssignmentsApi,
  PlatformAssignment as PlatformAssignmentGW
} from "@bmbix/bmb_isimud_client";
import { instanceService } from "../../Entity/InstanceService";
import { type PlatformAssignment } from "../../Entity/Types";

const logger = log.getLogger("Isimud:PlatformAssignments");


const  createPlatformAssignmentFromGW = (
  platformAssignmentGW:PlatformAssignmentGW,
): PlatformAssignment => {
  return instanceService.createPlatformAssignment({
    platformAssignmentId: platformAssignmentGW.platform_assignment_id,
    addressId: platformAssignmentGW.address_id,
    platformId: platformAssignmentGW.platform_id,
  });
}


const getPlatformAssignments = (args: {
  token: string,
  organizationId: string,
  addressId: string,
}):Promise<Array<PlatformAssignment>> => {
  let defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = args.token;
  let apiInstance = new PlatformAssignmentsApi();
  return new Promise((resolve, reject) => {
    const opts = null;
    apiInstance.index(args.organizationId, args.addressId, opts, (error, data, response) => {
      if (error) {
        console.log("error in getg:", error);
        reject(error);
      } else {
        const platformAssignmentsResponseGW = data;
        const platformAssignmentsGW = platformAssignmentsResponseGW.platform_assignments;
        const platformAssignments = R.map(createPlatformAssignmentFromGW, platformAssignmentsGW);
        resolve(platformAssignments);
      }
    });
  });
}


const getPlatformAssignment = async (args: {
  token: string,
  organizationId: string,
  addressId: string,
}) => {
  const platformAssignments = await getPlatformAssignments(args);
  return platformAssignments[0];
}


const getAllPlatformAssignments = async (args: {
  token: string,
  organizationIds: Array<string>,
}): Promise<Array<PlatformAssignment>> => {

  const promiseArr = [];

  for (const oId of args.organizationIds) {
    const p = () => getPlatformAssignments({
      token: args.token,
      organizationId: oId,
    });
    promiseArr.push(p);
  }

  const promise = chunkPromise(
    promiseArr, {
      concurrent: 1,
      promiseFlavour: PromiseFlavor.PromiseAll,
      sleepMs: 2000,
    },
  );
  return promise;

}

const original_getAllPlatformAssignments = async (args: {
  token: string,
  organizationIds: Array<string>,
}): Promise<Array<PlatformAssignment>> => {

  const allPlatformAssignments = [];

  for (const oId of args.organizationIds) {
    console.log(`fetching platform assignments for ${oId}`);
    const platformAssignments = await getPlatformAssignments({
      token: args.token,
      organizationId: oId,
    });
    console.log(`got platform assignments for ${oId}`);
    allPlatformAssignments.push(platformAssignments);
  }
  return S.join (allPlatformAssignments);
}


const _getAllPlatformAssignments = (args: {
  token: string,
  organizationIds: Array<string>,
}): Promise<Array<PlatformAssignment>> => {
  const promise = Promise.allSettled(
    R.map(
      oId => {
        return getPlatformAssignments({
          token: args.token,
          organizationId: oId,
        });
      },
      args.organizationIds,
    )
  ).then(
    promises => {
      return R.filter(
        p => p.status === "fulfilled",
        promises,
      )
    }
  ).then(
    promises => R.map(
      p => {
        return p.value
      },
      promises,
    )
  ).then(
    platformAssignments => {
      return R.flatten(platformAssignments)
    }
  ).then(
    platformAssignments => {
      logger.debug("platformAssignments", platformAssignments);
      return platformAssignments;
    }
  );

  return promise;
}


const insertPlatformAssignment = (args: {
  token: string,
  organizationId: string,
  platformId: string,
  addressId: string,
  defaultClient_: Object,
}): Promise<PlatformAssignment> => {
  const {
    token,
    organizationId,
    addressId,
    platformId,
    defaultClient_,
  } = args;
  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new PlatformAssignmentsApi(defaultClient);

  const body = PlatformAssignmentGW.constructFromObject({
    platform_id: platformId,
    address_id: addressId,
  })
  return new Promise((resolve, reject) => {
    apiInstance.insert(
      body,
      organizationId,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          const platformAssignmentGW = data.platform_assignment;
          const platformAssignment = createPlatformAssignmentFromGW(platformAssignmentGW);
          resolve(platformAssignment);
        }
      }
    );
  });

}


const deletePlatformAssignment = (args: {
  token: string,
  organizationId: string,
  platformAssignmentId: string,
  defaultClient_: Object,
}): Promise<null> => {
  const {
    token,
    organizationId,
    platformAssignmentId,
    defaultClient_,
  } = args;
  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new PlatformAssignmentsApi(defaultClient);

  return new Promise((resolve, reject) => {
    apiInstance.callDelete(
      organizationId,
      platformAssignmentId,
      (error, data, response) => {
        if (error) {
          reject(error);
        } else {
          const platformAssignmentGW = data.platform_assignment;
          if ( platformAssignmentGW === undefined ) {
            resolve(null);
          } else {
            throw new Error(`Expected undefined, got: ${platformAssignmentGW}`);
          }
        }
      }
    );
  });

}


export {
  createPlatformAssignmentFromGW,
  getPlatformAssignments,
  getPlatformAssignment,
  getAllPlatformAssignments,
  insertPlatformAssignment,
  deletePlatformAssignment,
}
