// @flow
import {
  createElement as el,
} from "react";

import {
  View,
} from "./View.js";

const Controller = (props: {
  onCancel: Function,
}) => {
  const {
    onCancel,
  } = props;

  return el(
    View,
    {
      onCancel,
    },
  );
}


export {
  Controller,
}
