// @flow
import log from "loglevel";
import * as S from "sanctuary";
import * as $ from "sanctuary-def";
import aws4 from "aws4";
import {
  fromCognitoIdentityPool,
} from "@aws-sdk/credential-providers";

import {
  instanceService,
} from "../Entity/InstanceService.js"
import {
  store,
} from "../State/Store.js";
import {
  updateMessageNote,
} from "../State/MessageNotes.js";

const logger = log.getLogger("Util.notifications");


const env = $.env;

const def = $.create({
  checkTypes: false,
  env,
});

/*
 * The main types
 * WsMessage - MessageEvent interface
 * NotificationMessage - resource, message
 * NoticeMessage - dataType, data
 * Notice - various
 */

const WsMessage = $.Object;

const WireMessage = $.RecordType ({
  resource: $.String,
  noticeType: $.String,
  noticeData: $.String,
});

const MessageProcessedNotice = $.RecordType ({
  messageId: $.String,
});

const ExtractionCandidateReportRequestCompletedNotice = $.RecordType ({
  extractionCandidateReportId: $.String,
});

const AddressBookSyncedNotice = $.RecordType ({
  syncId: $.String,
});

const MessageNoteReceivedNotice = $.RecordType ({
  noteId: $.String,
  messageId: $.String,
  userId: $.String,
  referencesNoteId: $.String,
  content: $.String,
  submittedAtUtc: $.String,
});


const createWireMessageFromWS = def ("createWireMessageFromWS") ({}) ([
  WsMessage, WireMessage,
]) (
  wsMessage => {
    const wireMessageWS = JSON.parse(wsMessage.data);
    return Object.freeze({
      resource: wireMessageWS.resource,
      noticeType: wireMessageWS.notice_type,
      noticeData: wireMessageWS.notice_data,
    });
  }
)


////////////////////////////////

const createMessageProcessedNotice = def ("createMessageProcessedNotice") ({}) ([
  $.String, MessageProcessedNotice,
]) (
  data => {
    const obj = JSON.parse(data);
    return Object.freeze({
      messageId: obj.message_id,
    })
  }
)

const storeMessageProcessedNotice = def ("storeMessageProcessedNotice") ({}) ([
  MessageProcessedNotice, $.Undefined,
]) (
  messageProcessedNotice => logger.debug("storing messageProcessedNotice:", messageProcessedNotice)
)

const handleMessageProcessed = def ("handleMessageProcessed") ({}) ([
  $.String, $.Undefined,
]) (
  obj => S.pipe ([
      createMessageProcessedNotice,
      storeMessageProcessedNotice,
    ])(obj)

)

const createExtractionCandidateReportRequestCompletedNotice = def ("createMessageProcessedNotice") ({}) ([
  $.String, ExtractionCandidateReportRequestCompletedNotice,
]) (
  data => {
    const obj = JSON.parse(data);
    return Object.freeze({
      extractionCandidateReportId: obj.extraction_candidate_report_id
    })
  }
)

const createAddressBookSyncedNotice = def ("createAddressBookSyncedNotice") ({}) ([
  $.String, AddressBookSyncedNotice,
]) (
  data => {
    const obj = JSON.parse(data);
    return Object.freeze({
      syncId: obj.sync_id,
    })
  }
)

const logAddressBookSyncedNotice = def ("logMessageProcessedNotice") ({}) ([
  AddressBookSyncedNotice, $.Undefined,
]) (
  addressBookSyncedNotice => logger.debug("logging addressBookSyncedNotice:",
    addressBookSyncedNotice)
)


const handleAddressBookSynced = def ("handleAddressBookSynced") ({}) ([
  $.String, $.Undefined,
]) (
  obj => S.pipe ([
      createAddressBookSyncedNotice,
      logAddressBookSyncedNotice,
    ])(obj)

)


const createMessageNoteReceivedNotice = def ("createMessageNoteReceivedNotice") ({}) ([
  $.String, MessageNoteReceivedNotice,
]) (

  noticeDataString => {
    const messageNoteReceivedNoticeGW = JSON.parse(noticeDataString);
    return Object.freeze({
      noteId: messageNoteReceivedNoticeGW.note_id,
      messageId: messageNoteReceivedNoticeGW.message_id,
      userId: messageNoteReceivedNoticeGW.user_id,
      referencesNoteId: messageNoteReceivedNoticeGW.references_note_id,
      content: messageNoteReceivedNoticeGW.content,
      submittedAtUtc: messageNoteReceivedNoticeGW.submitted_at_utc,
    });
  }

)


const storeMessageNoteReceivedNotice = def ("storeMessageNoteReceivedNotice") ({}) ([
  MessageNoteReceivedNotice, $.undefined,
]) (
  notice => {
    logger.debug("messageNoteReceivedNotice", notice);
    const messageNote = instanceService.createMessageNote({
      messageId: notice.messageId,
      noteId: notice.noteId,
      userId: notice.userId,
      content: notice.content,
      referencesNoteId: notice.referencesNoteId,
      submittedAtUtc: notice.submittedAtUtc,
    });
    logger.debug(
      "messageNote",
      messageNote,
    );
    store.dispatch(updateMessageNote(messageNote));
  }
)


const handleMessageNoteReceived = def ("handleMessageNoteReceived") ({}) ([
  $.String, $.undefined,
]) (
  noticeDataString => S.pipe ([
      createMessageNoteReceivedNotice,
      storeMessageNoteReceivedNotice,
    ])(noticeDataString)

)


///////////////////////////////////////////


const handleMessageEvent = def ("handleMessageEvent") ({}) ([
  WsMessage, $.Undefined,
]) (
  wsMessage => {
    logger.debug("wsMessage:", wsMessage);
    const handlers = {
      "MessageProcessedNotice": handleMessageProcessed,
      "AddressBookSyncedNotice": handleAddressBookSynced,
      "MessageNoteReceivedNotice": handleMessageNoteReceived,
    }
    try {
      const wireMessage = createWireMessageFromWS(wsMessage);
      logger.debug("wireMessage:", wireMessage);
      const handler = handlers[wireMessage.noticeType];
      logger.debug("handler:", handler);
      return handler(wireMessage.noticeData);
    } catch (error) {
      logger.error("Boom: unable to handle message event:", wsMessage);
      logger.error("Boom: error:", error);
    }
  }
)

const sendKeepAliveMessage = (event: Function): null => {
  logger.debug("onopen event", event);
  const awsTTLInMinutes = 10;
  const margin = 100;
  const keepaliveMillis = (awsTTLInMinutes * 60 * 1000) - margin;
  const wsSocket = event.target;
  setInterval(() => {
    const date = new Date();
    const msg = `ws-keepalive: ${date.toString()}`;
    logger.error("msg:", msg);
    wsSocket.send(msg);
  }, keepaliveMillis);
}

const handleCloseEvent = (event: Function): null => {
  console.log("Closing Web socket")
  return null;
}

const buildWebSocket = (credentials: Function): WebSocket => {
  logger.debug("credentials:", credentials)
  const AWS_REGION = "eu-west-2";
  const SOCKET_HOST = "9yd0hjl2hc";
  const ENV = "v1";
  const WEBSOCKET_URL = `${SOCKET_HOST}.execute-api.${AWS_REGION}.amazonaws.com`;
  const {
    sessionToken,
  } = credentials;
  const requestOptions = {
    host: WEBSOCKET_URL,
    path: `${ENV}?X-Amz-Security-Token=${encodeURIComponent(sessionToken)}`,
    service: "execute-api",
    region: AWS_REGION,
    signQuery: true,
  };
  const { path } = aws4.sign(requestOptions, credentials);
  logger.error("path:", path);
  const wsURL = `wss://${WEBSOCKET_URL}/${path}`;
  logger.debug("wsURL:", wsURL);
  const webSocket = new WebSocket(wsURL);
  return webSocket;
}

const getCredentialsPromise =
  (region: string) =>
  (identityPoolId: string) =>
  (idToken: string): Function => {
    const credentialsPromise = fromCognitoIdentityPool({
      identityPoolId: identityPoolId,
      logins: {
        "cognito-idp.eu-west-2.amazonaws.com/eu-west-2_ZLSSKYb4u": idToken,
      },
      clientConfig: {region},
    });
    return credentialsPromise
  }

/*
 * This is how it all gets put together.
        const credentialsPromise = getCredentialsPromise(REGION)(IDENTITY_POOL_ID)(idToken)();
        credentialsPromise.then(
          credentials => {
            console.log("Here are credentials:", credentials);
            if (!!webSocket){ // we don't want to keep opening sockets.
              webSocket.close();
            }
            webSocket = buildWebSocket(credentials);
            webSocket.onopen = sendKeepAliveMessage;
            webSocket.onmessage = handleMessageEvent;
          }
        ).catch(
          e => {
            logger.error("e:", e)
          }
        )
*/


export {
  buildWebSocket,
  getCredentialsPromise,
  handleCloseEvent,
  handleMessageEvent,
  sendKeepAliveMessage,
  createExtractionCandidateReportRequestCompletedNotice,
  createWireMessageFromWS,
}
