// @flow
import { createElement as el, useState, } from "react";
import { fork } from "fluture";

import { withRouter } from "react-router-dom";

import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "../../../Util";

import { withRescind } from "../../Api";
import { View } from "./view.js";

const Controller = withRouter(withRescind((props: {
  match: Function,
  rescind: Function,
}) => {
  const {
    match,
    rescind,
  } = props;
  const organizationId = match.params.organizationId;
  const certificateId = match.params.certificateId;

  const [confirmed, setConfirmed] = useState(false);
  const [display, setDisplay] = useState(INPUT);

  const handleChange = e => {
    console.log(e.currentTarget.name, e.currentTarget.value);
    const n = e.currentTarget.name;
    const v = e.currentTarget.value;
    switch (n) {
      case "confirmed":
        // no value from form is checkbox not ticked.
        // const actualValue = v === "confirmed"
        setConfirmed(v === "confirmed");
        break;
      default:
        break;
    }
  }

  const handleSubmit = e => {
    if (confirmed) {
      console.log(e);
      setDisplay(WAITING);

      const action = rescind(organizationId)(certificateId);

      fork(error => {
        console.log(error);
        setDisplay(FAILURE);
      })(certificate => {
        console.log(certificate);
        setDisplay(SUCCESS);
      })(action)
    } else {
      alert("Cannot submit unless you confirm");
    }
  }

  return el(
    View,
    {
      certificateId,
      display,
      onChange: handleChange,
      onSubmit: handleSubmit,
    }
  )
}));

export { Controller }

