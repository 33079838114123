// flow


const makeStateChart = chart => startObj => {
  chart.states.off.on.START = startObj;
  return chart;
}


export {
  makeStateChart,
}
