// @flow
import React from 'react';
import { Controller as PaymentMethodsTable } from "./../PaymenMethodsTable/controller.js"
import { Controller as PaymentMethodAddWidget } from "./../PaymentMethodAddWidget/controller.js";



const PaymentMethodsList = (props: {
  onItemsChanged: Function,
  paymentMethodsState: Function,
}) => {
  const {
    onItemsChanged,
    paymentMethodsState,
  } = props;


  return paymentMethodsState.value === "success"
  ? <>
    <h2>PaymentMethods</h2>
    <PaymentMethodsTable onItemsChanged={onItemsChanged} paymentMethods={paymentMethodsState.context.paymentMethods} />
    </>
  : <p>Waiting for payment methods</p>

}



const View = (props: {
  paymentMethodsState: Function,
  onItemsChanged: Function,
}) => {
  const {
    onItemsChanged,
    paymentMethodsState,
  } = props;

  return (<>
    <PaymentMethodAddWidget />
    <PaymentMethodsList
      onItemsChanged={onItemsChanged}
      paymentMethodsState={paymentMethodsState}
    />
  </>);
}



export {
  View,
}
