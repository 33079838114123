// @flow
import React, {
  useState,
} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "../../../Util";

const View = (props: {
  display: string,
  onSubmit: Function,
  onSuccess: Function,
  onFailure: Function,
  onCancel: Function,
}) => {
  const {
    onSubmit,
    onSuccess,
    onFailure,
    onCancel,
    display,
  } = props;

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  let content, buttons;
  switch (display) {
    case INPUT:
      content = <Form>
        <Form.Group>
        <Form.Label>Username</Form.Label>
        <Form.Control type="text" onChange={e => setUsername(e.target.value)} />
        <Form.Text>Your Kashflow username</Form.Text>
        </Form.Group>
        <Form.Group>
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" onChange={e => setPassword(e.target.value)} />
        <Form.Text>Your Kashflow username</Form.Text>
        </Form.Group>
        </Form>
      buttons = <>
        <Button onClick={e => onSubmit({username, password})} className="mr-2">Submit</Button>
        <Button onClick={onCancel}>Cancel</Button>
        </>
      break;
    case WAITING:
      content = <p>Waiting ...</p>
      buttons = <>
        <Button onClick={onCancel}>Cancel</Button>
        </>
      break;
    case SUCCESS:
      content = <p>All done!</p>
      buttons = <>
        <Button onClick={onSuccess}>Done (Success)</Button>
        </>
      break;
    case FAILURE:
      content = <p>Ooops, something went wrong</p>
      buttons = <>
        <Button onClick={onFailure}>Done (Failure)</Button>
        </>
      break;
    default:
      throw new Error("Unhandled value for display");
  }

  return (
    <Modal show={true}>
      <Modal.Header>Connect</Modal.Header>
      <Modal.Body>
       {content}
      </Modal.Body>
      <Modal.Footer>
       {buttons}
      </Modal.Footer>
    </Modal>
  )
}

export {
  View,
}
