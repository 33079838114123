// @flow
import * as R from "ramda";

import {
  ApiClient,
  AddressesApi,
  MessageNote as MessageNoteGW,
} from "@bmbix/bmb_martlet_organization_client";
import { instanceService } from "../../Entity/InstanceService";
import { type MessageNote } from "../../Entity/Types";
import log from "loglevel";

import {
  store as reduxStore,
} from "../../State/Store.js";
import {
  addMessageNotes,
} from "../../State";
import { callBack } from "./../CallBack/";

const logger = log.getLogger("Api.Isimud")

const createMessageNoteFromGW = (
  messageNoteGW:MessageNoteGW,
): MessageNote => {
  return instanceService.createMessageNote({
    noteId: messageNoteGW.note_id,
    messageId: messageNoteGW.message_id,
    userId: messageNoteGW.user_id,
    referencesNoteId: messageNoteGW.references_note_id,
    content: messageNoteGW.content,
    submittedAtUtc: messageNoteGW.submitted_at_utc,
  });
}


const getMessageNotes = (args: {
  token: string,
  addressId: string,
  messageId: string,
  apiClient?: Object,
  store_?: Object
}):Promise<Array<MessageNote>> => {
  const {
    token,
    addressId,
    messageId,
    apiClient,
    store_,
  } = args;
  const client = !!apiClient ? apiClient : ApiClient.instance;
  const store = !!store_ ? store_:  reduxStore;
  let MartletOauth2 = client.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AddressesApi(client);

  const dataHandler = data => {
    const messageNotesGW = data.message_notes;
    const messageNotes = R.map(createMessageNoteFromGW, messageNotesGW);
    store.dispatch(addMessageNotes(messageNotes));
    return messageNotes;
  }

  return new Promise((resolve, reject) => {
    apiInstance.listNotes(
      addressId,
      {messageId},
      callBack(reject, resolve) (dataHandler),
    );
  });
}

const insertMessageNote = (args: {
  token: string,
  addressId: string,
  messageId: string,
  content: string,
  referencesNoteId?: string,
  apiClient?: Object,
}):Promise<MessageNote> => {
  const {
    token,
    addressId,
    messageId,
    content,
    referencesNoteId,
    apiClient,
  } = args;
  logger.debug("args:", args);
  const body = MessageNoteGW.constructFromObject({
    message_id: messageId,
    content: content,
    references_note_id: referencesNoteId,
  });

  const client = !!apiClient ? apiClient : ApiClient.instance;
  let MartletOauth2 = client.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new AddressesApi(client);

  const dataHandler = data => {
    const messageNoteGW = data;
    const messageNote = createMessageNoteFromGW(messageNoteGW);
    return messageNote;
  }

  return new Promise((resolve, reject) => {
    apiInstance.createNote(
      body,
      addressId,
      callBack(reject, resolve) (dataHandler),
    );
  });
}

export {
  createMessageNoteFromGW,
  getMessageNotes,
  insertMessageNote,
}
