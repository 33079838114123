// @flow
import { createElement as el, useEffect, useState } from "react";
import Future, { fork, chain } from "fluture";
import * as S from "sanctuary";
import {
  useToken,
  useAccessListMutation,
} from "./../../../../State";
import { getEntries } from "./../../Api";
import { getAddressProfilesWithFuture } from "../../../../Api/MartletOrganization";
import View from "./view.js";
import { useCatchExpiredToken } from "./../../../../State/Token.js";

import {
  WAITING,
  SUCCESS,
  FAILURE,
} from "../../../../Util";

const Controller = (props: {
  owner: string,
  list: string,
}) => {
  const {
    owner,
    list,
  } = props;

  const accessListMutation = useAccessListMutation();
  const [ display, setDisplay ] = useState(WAITING);
  const [ entries, setEntries ] = useState([]);
  const [ addressProfiles, setAddressProfiles ] = useState([]);
  const token = useToken();
  const catchExpiredToken = useCatchExpiredToken();

  useEffect(
    function () {

      const action = chain(getAddressProfilesWithFuture(token)) (chain(es => {
        setEntries(es);
        return Future((reject, resolve) => {
          const senders = S.map (e => e.sender)(es);
          resolve(senders);
          return console.log;
        })
      })(getEntries(token)(owner)(list)));

      fork(
        error => {
          setDisplay(FAILURE);
          catchExpiredToken(error);
          console.log("error", error);
        }
      )(
        addressProfiles => {
          setDisplay(SUCCESS);
          setAddressProfiles(addressProfiles);
          console.log("addressProfiles", addressProfiles);
        }
      )(action);
    },
    [accessListMutation]
  );

  const profilePairs = S.fromPairs (S.map (p => S.Pair (p.addressId) (p)) (addressProfiles));
  console.log("profilePairs", profilePairs);
  const enrichedEntries = (entries, profiles, acc) => {
    if (entries.length === 0) {return acc}
    const [head, ...tail ] = entries;
    const profile = S.fromMaybe ({}) (S.value (head.sender) (profiles));
    const newAcc = S.append (Object.assign({addressProfile: profile}, head)) (acc);
    return enrichedEntries(tail, profilePairs, newAcc);
  }

  return el(
    View,
    {
      owner,
      entries: enrichedEntries(entries, profilePairs, []),
      display,
      list,
    }
  )
}

export { Controller as default };
