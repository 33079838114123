import * as R from "ramda";
import * as S from "sanctuary";
import { useSelector } from "react-redux";

const ADD_ADDRESS = "ADDRESS_ADD";
const DEL_ADDRESS = "ADDRESS_DELETE";
const UPDATE_ADDRESS = "ADDRESS_UPDATE";

const addAddress = (address) => {
  return {
    type: ADD_ADDRESS,
    address: address,
  }
}
const deleteAddress = (id) => {
  return {
    type: DEL_ADDRESS,
    id: id,
  }
}
const updateAddress = (address) => {
  return {
    type: UPDATE_ADDRESS,
    address: address,
  }
}

const uniq = addresses => R.uniqBy(a => a.id, addresses);

const addresses = (state=[], action) => {
  switch (action.type) {
    case ADD_ADDRESS:
      return uniq(R.append(action.address, state));
    case DEL_ADDRESS:
      return uniq(R.filter(x => x.id !== action.id, state));
    case UPDATE_ADDRESS:
      return uniq(R.append(action.address, R.filter(x => x.id !== action.address.id, state)));
    default:
      return uniq(state);
  }
}

const useAddresses = (addressId: string): Array<BmbixAddress> => {
  return useSelector(state => state.addresses)
}

const useAddress = (addressId: string): BmbixAddress => {
  return useSelector(state => {
    const idSelector = S.compose (S.equals (addressId)) (S.prop ("id"));
    const maybeAddress = S.find (idSelector) (state.addresses);
    return S.maybeToNullable(maybeAddress);
  });
}

export {
  addresses,
  addAddress,
  updateAddress,
  deleteAddress,
  useAddresses,
  useAddress,
}
