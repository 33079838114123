// @flow
import Future from "fluture";

import {
  ApiClient,
  OrganizationsApi,
  BillingAddress as BillingAddressGW,
  BillingAddressRequest as BillingAddressRequestGW,
} from "@bmbix/bmb_martlet_organization_client";


import { callBack } from "./../../Api/CallBack";
import { createBillingAddressFromGW } from "./adapt";


const setBillingAddress = (args: {
  token: string,
  organizationId: string,
  addressId: string,
}) => {
  const {
    token,
    organizationId,
    addressId,
  } = args;

  const defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new OrganizationsApi(defaultClient);

  const dataHandler = data => {
    const billingAddressGW = data.billing_address;
    const billingAddress = createBillingAddressFromGW(billingAddressGW);
    return billingAddress;
  }

  const billingAddressGW = BillingAddressGW.constructFromObject({
    address_id: addressId,
  });
  const body = BillingAddressRequestGW.constructFromObject({
    billing_address: billingAddressGW,
  });

  return Future((reject, resolve) => {
    apiInstance.setBillingAddress(
      body,
      organizationId,
      callBack(reject, resolve)(dataHandler),
    )
    return console.log;
  })

}

export {
  setBillingAddress,
}
