// @flow
import React, { createElement as el } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

import { type Certificate } from "../../entity.js";

import { Controller as OrganizationForm } from "./../OrganizationForm/controller.js";

const Wrapper = (props: Function) => {
  return <>
    <h2>Verify Organization</h2>
    {props.children}
    </>;
}

const InputView = (props: {
  onChange: Function,
  onSubmit: Function,
}) => {
  const {
    onChange,
    onSubmit,
  } = props;

  return (<Wrapper>
    <OrganizationForm onChange={onChange} />
    <Button onClick={onSubmit}>Submit</Button>
    </Wrapper>
  );
}


const WaitingView = (props: Function) => {

  return <Wrapper>
      <h3>Waiting</h3>
    </Wrapper>

}


const SuccessView = (props: {
  certificate: Certificate,
  organizationId: string,
}) => {
  const {
    certificate,
    organizationId,
  } = props;

  const path = `/v/organizations/${organizationId}/certificates/${certificate.certId}`;

  return <Wrapper>
      <h3>Success</h3>
      <Link to={path}>View your new certificate here</Link>
    </Wrapper>
}

const FailureView = (props: {

}) => {

  return <Wrapper>
      <h3>Failure</h3>
      <p>Something went wrong</p>
    </Wrapper>
}

export { InputView, WaitingView, SuccessView, FailureView }
