// @flow
import React from "react";
import Table from "react-bootstrap/Table";
import Time from 'pkijs/src/Time';
import {
  AlertIcon,
} from "@primer/octicons-react";

import {
  type HumanCertificate,
  type KeyInformation,
} from "./types.js";


const KeyInfo = (props: {
  info: KeyInformation,
  type: string,
}) => {
  const {
    info,
    type,
  } = props;

  const registrar = !!info.components.uk_companies_house ? "UK Companies House" :
    !!info.components.france_companies_house ? "France Companies House" :
    "Unrecognized";

  const registerEntry = !!info.components.uk_companies_house ?
    info.components.uk_companies_house :
    !!info.components.france_companies_house ?
    info.components.france_companies_house :
    "Unknown";

  return <>
    <Table striped bordered hover>
     <thead>
    <tr>
    <th>{type}</th>
    <th></th>
    </tr>
     </thead>
     <tbody>
      <tr>
      <td>Bmbix Id</td>
      <td>{info.components.bmbix_id}</td>
      </tr>
      <tr>
      <td>Email</td>
      <td>{info.components.email}</td>
      </tr>
      <tr>
      <td>Common Name</td>
      <td>{info.components.commonName}</td>
      </tr>
      <tr>
      <td>Organizational Unit</td>
      <td>{info.components.organizationalUnit}</td>
      </tr>
      <tr>
      <td>Organization Name</td>
      <td>{info.components.organizationName}</td>
      </tr>
      <tr>
      <td>Locality Name</td>
      <td>{info.components.localityName}</td>
      </tr>
      <tr>
      <td>State Name</td>
      <td>{info.components.stateName}</td>
      </tr>
      <tr>
      <td>Country</td>
      <td>{info.components.country}</td>
      </tr>
      <tr>
      <td>Registrar</td>
      <td>{registrar}</td>
      </tr>
      <tr>
      <td>Register Entry</td>
      <td>{registerEntry}</td>
      </tr>
     </tbody>
    </Table>
    </>
}

const DateInfo = (props: {
  to: Time,
  from: Time,
}) => {
  const {
    to,
    from,
  } = props;

  return (
    <>
    <Table striped bordered hover>
    <thead>
    <tr>
    <th>Validity</th>
    <th></th>
    </tr>
    </thead>
    <tbody>
    <tr>
    <td>Valid From</td>
    <td>{from.value.toString()}</td>
    </tr>
    <tr>
    <td>Valid To</td>
    <td>{to.value.toString()}</td>
    </tr>
    </tbody>
    </Table>
    </>
  )
}


const View = (props: {
  humanCertificate: HumanCertificate,
}) => {
  const {
    humanCertificate,
  } = props;

  const validityMarker = humanCertificate.isValid ?
    null: <>
    <AlertIcon fill="Red" /> Certificate Expired
    </>;

  return (
    <>
      <h2>View</h2>
      { validityMarker }
      <DateInfo
        from={humanCertificate.validFrom}
        to={humanCertificate.validTo}
      />
      <KeyInfo type="Subject" info={humanCertificate.subject} />
      <KeyInfo type="Issuer" info={humanCertificate.issuer} />
    </>
  )
}

export { View }
