// @flow
import React from "react";
import Button from "react-bootstrap/Button";


import { type Message } from "./../../../Entity/Types.js";


const ChooseView = (props: {
  setViewType: Function,
}) => {
  const {
    setViewType,
  } = props;

  return (
    <>
    <p>For this message, you have permissions for both the sender and recipient
    addresses.</p>
    <Button onClick={e => setViewType("senderOnly") } >View as Sender</Button>
    <Button onClick={e => setViewType("recipientOnly") } >View as Recipient</Button>
    </>
  )
}


export {
  ChooseView,
}
