// @flow
import {
  MessageType as MessageTypeGW,
} from "@bmbix/bmb_martlet_organization_client";

import { createMessageType } from "./../entity";

const gwToMessageType = (gw: MessageTypeGW) => createMessageType({
  id: gw.id,
  updatedAt: "",
  address: gw.address,
  messageType: gw.message_type,
  direction: gw.direction,
  retentionPolicy: gw.retention_policy,
})

export { gwToMessageType };
