// @flow
import React from "react";

import ListsManager from "../ListsManager";


const View = (props: {
  owner: string,
}) => {
  const {
    owner,
  } = props;

  return (
    <ListsManager owner={owner}/>
  )
}
export { View }
