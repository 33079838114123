// @flow
import React from "react";
import ReactTable from "react-table-6";

import {
  type PaymentMethodLink
} from "./../../../Entity/Types.js";
import { Controller as PaymentMethodLinkDeleteWidget } from
"./../PaymentMethodLinkDeleteWidget/controller.js";


const View = (props: {
  paymentMethodLinks: Array < PaymentMethodLink > ,
}) => {
  const {
    paymentMethodLinks,
  } = props;


  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Organization Id",
      accessor: "organizationId",
    },
    {
      Header: "User Id",
      accessor: "paymentMethodUserid",
    },
    {
      Header: "Description",
      accessor: "paymentMethodDescription",
    },
    {
      Header: "Actions",
      // accessor: "paymentMethodDescription",
      Cell: ({original}) => {
        return <PaymentMethodLinkDeleteWidget onDone={pml => console.log("deleted", pml)} paymentMethodLink={original} />
      },
    },
  ]

  return ( <
    ReactTable className = "-striped -highlight"
    data = {
      paymentMethodLinks
    }
    columns = {
      columns
    }
    defaultPageSize = {
      10
    }
    />
  )
}
export {
  View
}

