// @flow
import React from "react";
import Button from "react-bootstrap/Button";
import {
  Row,
  Col,
} from 'react-bootstrap'
import * as S from "sanctuary";
import { Link } from "react-router-dom";

import {
  type AddressProfile,
} from "./../../../Entity/Types.js";
import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "./../../../Util";
import {
  copyToClipboard,
} from "./../../../Util/copy.js";
import {
  BmbixQR,
} from "./../../../Widgets/BmbixQR";
import {
  OrganizationProfileWidget,
} from "./../../../Widgets/OrganizationProfile";

const View = (props: {
  profile?: AddressProfile,
  display: String,
}) => {
  const {
    profile,
    display,
  } = props;
  const publicKeyPath = !!profile ? `./${profile.addressId}/public_key`:"";

  const input = display === INPUT ? (
    <p>Ready to fetch profile</p>
  ): null;

  const waiting = display === WAITING ? (
    <p>Working ...</p>
  ): null;


  const organizationProfile = !!profile ? profile.organizationProfile: null;

  const success = display === SUCCESS && !!profile ? (
    <Row>
    <Col>
    <h2>QR Code</h2>
    <BmbixQR
      uri={`https://client.bmbix.com/profiles/address/${profile.addressId}`}
      title={profile.purpose}
    />
    </Col>
    <Col>
    <h2>Name</h2>
    <p>{profile.displayName}</p>
    <hr/>
    <h2>Bmbix Address Id</h2>
    <p>{profile.addressId}</p>
    <Button onClick={e => copyToClipboard(profile.addressId)}>Copy</Button>
    <hr/>
    <h2>Related Organization</h2>
    <OrganizationProfileWidget organizationProfile={organizationProfile} />
    <hr/>
    <h2>Business Process</h2>
    { !!profile.purpose ?
      <p>{ profile.purpose }</p> :
      <p>No business process yet specified.</p>
    }
    <hr/>
    <h2>Accepted Message Types</h2>
    { !!profile.contentTypesAccepted && profile.contentTypesAccepted.length > 0 ?
      S.map (cta => <p key={cta}>{cta}</p>) (profile.contentTypesAccepted):
      <p>No content types yet specified.</p>
    }
    <hr/>
    </Col>
    <Col>
    <h2>Public Key</h2>
    <Link to={publicKeyPath}>public key</Link>
    <hr/>
    </Col>
    </Row>
  ): null;

  const failure = display === FAILURE ? (
    <p>Ooops!, something went wrong</p>
  ): null;

  return (
    <>
    <h1>Address Profile</h1>
    <hr/>
    {input}
    {waiting}
    {success}
    {failure}
    </>
  );
}

export {
  View,
}
