// @flow
import React, {useEffect, useState} from "react";
import * as S from "sanctuary";
import * as $ from "sanctuary-def";
import { useMachine } from "@xstate/react";
import { createMachine } from "xstate";
import { fork } from "fluture";
import {
  getCurrentBillingAddress,
} from "./../../Api";
import { useToken } from "./../../../State";
import {
  Spinner,
} from "./../../../Widgets/Toast";


import { type BillingAddress } from "./../../../Entity/Types";

const fetchingMachine = createMachine({
  id: "fetchingMachine",
  initial: "waiting",
  states: {
    waiting: {
      on: {
        SUCCESS_HAPPENED: "success",
        FAILURE_HAPPENED: "failure",
      }
    },
    success: {
      on: {
        SUBMIT_HAPPENED: "waiting",
      }
    },
    failure: {
      on: {
        RESET_HAPPENED: "input"
      }
    },
    input: {
      on: {
        SUBMIT_HAPPENED: "waiting"
      }
    },
  },
});

const getTimestamp = () => (new Date()).getTime()

const Controller = (props: {
  organizationId: string,
}) => {
  const {
    organizationId,
  } = props;
  const [ UIVersion, setUIVersion ] = useState(getTimestamp())
  const [ state, send ] = useMachine(fetchingMachine);
  const [ billingAddress, setBillingAddress ] = useState();
  const token = useToken();

  useEffect(
    () => {
      send({type: "SUBMIT_HAPPENED"});
      const action = getCurrentBillingAddress({token, organizationId})
      fork(
        error => {
          send({type: "FAILURE_HAPPENED"});
          console.log("failure happened");
        }
      )(
        billingAddress => {
          send({type: "SUCCESS_HAPPENED"});
          setBillingAddress(billingAddress);
        }
      )(action)
    },
    [UIVersion]
  );


  return state.value === "success"
  ? <>
    <h2>Current Billing Address</h2>
    <ul>
    {S.fromMaybe ("") (S.get (S.complement (S.is ($.Undefined))) ("addressId") (billingAddress))}
    </ul>
    </>
  : state.value === "waiting"
  ? <Spinner height="50" />
  : state.value === "failure"
  ? <p>Oops</p>
  : <p>Unknown state</p>;
}


export {
  Controller,
}
