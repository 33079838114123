// @flow
import React from "react";
import { useRouteMatch, Link } from "react-router-dom";
import ReactTable from "react-table-6";
import { StarFillIcon } from "@primer/octicons-react";

import { type AccessList } from "../../entity.js";


const View = (props: {
  accessLists: Array<AccessList>,
}) => {
  const {
    accessLists,
  } = props;

  const match = useRouteMatch();

  const columns = [
    {
      Header: "Active",
      accessor: "isActive",
      Cell: ({value}) => value ? <StarFillIcon />: null,
    },
    {
      Header: "List",
      accessor: "list",
      Cell: ({row, value}) => <Link to={`${match.url}/${value}`}>{row._original.name}</Link>,
    },
    {
      Header: "Type",
      accessor: "type",
    },

  ]

  return (
    <ReactTable
      className="-striped -highlight"
      columns={columns}
      data={accessLists}
      defaultPageSize={10}
    />
  )
}


export { View }
