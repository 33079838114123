// @flow
import sanctuary from "sanctuary";
import { env as flutureEnv } from "fluture-sanctuary-types";
import {
  encaseP,
  map,
  resolve,
} from "fluture";

import {
  addPermission,
} from "./../State/Permissions.js";
import { type Permission } from "../Entity/Types";
import { getPermissionsForAddress } from "./../Api/MartletOrganization/Address.js";

const S = sanctuary.create ({
  checkTypes: true, env:
  sanctuary.env.concat(flutureEnv)
});

const filterPermissions =
  (xpermissions: Array<Permission>) =>
  (addressId: string) =>
{
  const resource = `bmbix://address/${addressId}`;
  const belongsToAddress = S.compose (S.equals (resource)) (S.prop ("resource"));
  return S.filter (belongsToAddress) (xpermissions);
}

const refreshAddressPermissionsThunk=
  (token: string) =>
  (addressId: string) =>
{

  const thunk =
    (dispatch, getState) =>
  {
    const state = getState();
    const permissions = filterPermissions (state.permissions) (addressId);
    console.log("permissions from state for this address: ", permissions);
    const moreThanOne = S.pipe ([
      S.size,
      S.gt (1),
    ]);
    const eitherPermissions = S.tagBy (moreThanOne) (permissions);

    const noPermissionsFuture = (someBoolean) => {

      const fetchPermissionsFuture = encaseP (getPermissionsForAddress) ({
          token, addressId});

      console.log("Firmament:", S.type(fetchPermissionsFuture));

      const storeToRedux = S.map (permission => {
        dispatch(addPermission(permission));
        return permission;
      });

      const futureAfterAddingToRedux = map (storeToRedux) (fetchPermissionsFuture);
      return futureAfterAddingToRedux;
    }

    const hasPermissionsFuture = permissions => resolve(permissions);

    const theFuture = S.either (noPermissionsFuture) (hasPermissionsFuture) (eitherPermissions);
    return theFuture
  }
  return thunk;


}

export {
  refreshAddressPermissionsThunk,
}
