// @flow

import {
  createElement as el,
  useState,
} from "react";
import {
  connect,
} from "react-redux";
import log from "loglevel";

import {
  withMartletOrganizationApi,
} from "../../Api/MartletOrganization";
import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "../../Util";
import {
  View,
} from "./View.js";
import {
  addPermission,
} from "../../State";

const logger = log.getLogger("PermissionsAddModal");

const Controller_ = (props: {
  onHide: Function,
  organizationId: string,
  martletOrganizationApi: Object,
}) => {
  const {
    onHide,
    organizationId,
    martletOrganizationApi,
  } = props;
  const [display, setDisplay] = useState(INPUT);
  const [active, setActive] = useState(true);
  const [userId, setUserId] = useState();

  const onUserIdChange = userId => setUserId(userId);

  const onSubmit = e => {
    if (!!userId) {
      setDisplay(WAITING);
      martletOrganizationApi.createPermission({
        organizationId,
        actor: `bmbix://user/${userId}`,
        power: "bmbix://role/organization/manager",
      }).then(
        permission => {
          setDisplay(SUCCESS);
          addPermission(permission);
        }
      ).catch(
        error => {
          setDisplay(FAILURE);
        }
      );
    }
    else {
      logger.warning("userId must be set");
    }
  }

  const onCancel = e => {
    setDisplay(INPUT);
    setActive(false);
    onHide();
  }
  const onSuccessComplete = onCancel;
  const onFailureComplete = onCancel;

  return active ? el(
      View,
      {
        display,
        userId,
        onUserIdChange,
        onSubmit,
        onCancel,
        onSuccessComplete,
        onFailureComplete,
      }
  ): null;
}

const connector = connect(
  null,
  {
    addPermission,
  },
)

const Controller = connector(withMartletOrganizationApi(Controller_));

export {
  Controller,
  Controller_,
}

