// @flow
import { createElement as el } from "react"
import { View } from "./View.js";


const Controller = (props: {
  timeStamp: string,
}) => {
  const {
    timeStamp,
  } = props;

  const date = new Date(timeStamp);

  const genDisplayText = () => {
    try{
      return new Intl.DateTimeFormat().format(date);
    } catch (e) {
      return null;
    }
  }
  const displayText = genDisplayText();

  return el(
    View,
    {
      displayText,
      rolloverText: timeStamp,
    }
  )
}

export {
  Controller,
}
