// @flow

import sanctuary from "sanctuary";
import * as $ from "sanctuary-def";
import { env as flutureEnv } from "fluture-sanctuary-types";
import {
  encaseP,
  map,
  reject,
  resolve,
  chain,
} from "fluture";
import {
  addPlatformAssignment,
} from "./../State/PlatformAssignments.js";
import {
  getPlatformAssignments
} from "./../Api/Isimud/PlatformAssignments.js";

import {
  refreshPlatformThunk,
} from "./platforms.js";

const S = sanctuary.create ({
  checkTypes: true, env:
  sanctuary.env.concat(flutureEnv)
});

const cacheFetchResult =
  addressId =>
  dispatch =>
  platformAssignments =>
{
  const selector = S.compose (S.equals (addressId)) (S.prop ("addressId"));
  const maybePlatformAssignmentFromServer = S.find (selector) (platformAssignments);

  const storePlatformAssignment = pa => dispatch(addPlatformAssignment(pa))

  S.map (storePlatformAssignment) (maybePlatformAssignmentFromServer)

  return maybePlatformAssignmentFromServer;
}



const refreshPlatformAssignmentThunk =
  token =>
  organizationId =>
  addressId =>
{
  const thunk =
    (dispatch, getState) =>
  {

    // Do we have the platformAssignment in the cache?
    const state = getState();
    const platformAssignmentsFromState = state.platformAssignments;
    console.log("platformAssignmensFromState", platformAssignmentsFromState);
    const selector = S.compose (S.equals (addressId)) (S.prop ("addressId"));
    const maybePlatformAssignment = S.find (selector) (platformAssignmentsFromState);
    const eitherPlatformAssignment = S.maybeToEither ("platform assignment not in store") (maybePlatformAssignment);

    // if yes, return a future with the cached assignment in it.
    const cacheHit = pa => resolve(S.Just(pa));


    // if not in cache fetch the assignment
    // cache it, and return it in a future
    const cacheMiss =
      _ =>
    {
      const fetchFuture = encaseP (getPlatformAssignments) ({
        token, organizationId, addressId});
      const futureAfterCaching = map (cacheFetchResult(addressId)(dispatch)) (fetchFuture);
      return futureAfterCaching;
    }

    return S.either (cacheMiss) (cacheHit) (eitherPlatformAssignment);

  }
  return thunk;
}


const getPlatformFuture =
  dispatch =>
  token =>
  organizationId =>
  maybePlatformAssignment =>
{

  const eitherPlatformAssignment = S.maybeToEither ("no platform assignment to be had") (maybePlatformAssignment);
  // platformAssignment could be null in which case there is nothing to do.
  return S.either
    (text => reject(text))
    (pa => dispatch(refreshPlatformThunk(token)(organizationId)(S.prop ("platformId") (pa))))
    (eitherPlatformAssignment);
}

const refreshPlatformAssignmentAndPlatformThunk =
  token =>
  organizationId =>
  addressId =>
{
  const thunk =
    (dispatch, getState) =>
  {
    const platformAssignmentFuture = dispatch(refreshPlatformAssignmentThunk(token)(organizationId)(addressId));
    const platformFuture = chain (getPlatformFuture(dispatch)(token)(organizationId)) (platformAssignmentFuture);
    return platformFuture;
  }
  return thunk;

}
export {
  refreshPlatformAssignmentThunk,
  refreshPlatformAssignmentAndPlatformThunk,
}
