// @flow
import React, {
  useEffect,
  useState,
} from "react";

import {fork} from "fluture";

import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {
  withRouter,
} from "react-router-dom";
import log from "loglevel";
import {
  connect,
} from "react-redux";
import * as S from "sanctuary";
import {
  PlusIcon,
  SyncIcon,
  TrashIcon,
} from "@primer/octicons-react";

import {
  withMartletOrganizationApi,
} from "../Api/MartletOrganization";
import {
  OrganizationPermissionsAddModal as AddModal,
} from "../Widgets/OrganizationPermissionsAddModal";
import {
  PermissionsDeleteModal as DeleteModal,
} from "./PermissionsDeleteModal.js";
import {
  WAITING,
  FAILURE,
} from "../Util";
import {
  fetchUserProfiles,
  getUserIds,
  permissionsMap,
  unwrap,
  userProfilesMap,
  zipByKey,
} from "./EnrichPermissionsLogic.js";
import { CenteredSpinner } from "./../Widgets/Toast.js";

import {
  UserRoleTable,
} from "../Widgets/UserRoleTable";

const logger = log.getLogger("Organizations:PermissionsView");

const READY = "ready";

const View_ = (props: {
  token: string,
  martletOrganizationApi: Object,
  match: Object,
}) => {
  const {
    match,
    martletOrganizationApi,
    token,
  } = props;
  const organizationId = match.params.organizationId;
  const [display, setDisplay] = useState(WAITING);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);
  const [enrichedPermissions, setEnrichedPermissions] = useState();

  const refresh = async () => {
    setDisplay(WAITING);
    try {
      const permissions = await martletOrganizationApi.getPermissions({
        organizationId,
      });
      console.log("martletPermissions:", permissions);

      const userIds = getUserIds(permissions);
      console.log("userIds:", userIds);
      const future = fetchUserProfiles(token)(userIds);
      fork(error => {
        console.log("Boom:", error);
        setDisplay(FAILURE);
      })(userProfiles => {
        console.log("Huzzah:", userProfiles)
        const upm = userProfilesMap(userProfiles)
        const pm = permissionsMap(permissions)
        const enrichedPermissions = S.justs (S.map (unwrap) (zipByKey(pm, upm)));
        console.log("enriched permissions", enrichedPermissions);
        setEnrichedPermissions(enrichedPermissions);
        setDisplay(READY);
      })(future)
    }
    catch (e) {
      logger.error("Unable to fetch permissions", e);
      setDisplay(FAILURE);
    }
  }

  useEffect(
    () => {refresh()},
    [],
  )

  const deleteModal = showDeleteModal ? (
    <DeleteModal
      organizationId={organizationId}
      permissionIds={selectedPermissionIds}
      onSuccess={() => {
        setShowDeleteModal(false);
        refresh();
      }}
      onCancel={() => {setShowDeleteModal(false)}}
      onFailure={() => {setShowDeleteModal(false)}}
    />
  ): null;

  const addModal = showAddModal ? (
    <AddModal
      organizationId={organizationId}
      onHide={ e  => {
        setShowAddModal(false);
        refresh();
      }}
    />
  ): null;

  const ready = display === READY ? (
    <>
    <ButtonToolbar className="float-right" >
      <Button onClick={() => setShowAddModal(true) }><PlusIcon />Add</Button>
    </ButtonToolbar>
    <h2>Organization Team</h2>
    <Button className="mr-2" onClick={() => setShowDeleteModal(true) }><TrashIcon /> Delete</Button>
    <Button className="mr-2" onClick={refresh}><SyncIcon /> Refresh</Button>
    <UserRoleTable
      permissions={enrichedPermissions}
      onChange={permissionIds => setSelectedPermissionIds(permissionIds)}
      checked={selectedPermissionIds}
    />
    </>
  ): null;


/*
    <PermissionsTable
      permissions={enrichedPermissions}
      onChange={permissionIds => setSelectedPermissionIds(permissionIds)}
      checked={selectedPermissionIds}
    />

*/

  const waiting = display === WAITING ? (
    <CenteredSpinner />
  ): null;

  const failure = display === FAILURE ? (
    <p>Error fetching permissions.</p>
  ): null;

  return (
    <>
    {ready}
    {waiting}
    {addModal}
    {deleteModal}
    {failure}
    </>
  );
}

const connector = connect(
  state => {
    return {
      token: state.token,
    }
  }
)

const PermissionsView = withRouter(withMartletOrganizationApi(connector(View_)));

export {
  PermissionsView,
}
