// @flow
import React from "react";

import Form from "react-bootstrap/Form";
import {BModal, BSuccess, BFailure} from "./../../../Widgets/BModal";
import * as S from "sanctuary";

export type PpcSignatory = {
  +code: string,
  +description: string,
}


function PpcSignatoryFormChange (props: {
  onChange: Function,
  data: string,
}) {
  const {
    onChange,
    data,
  } = props;
  console.log("data is", data);

  const checkboxes = S.map (
    val => <Form.Check
          type="radio"
          name="ppcSignatory"
          onChange={ onChange }
          value={ val }
          checked={ data === val}
          label={ val.toString() }
        />
    ) ([true, false]);

  return (
    <Form>
    <Form.Group>
    <Form.Label>Change PPS Signatory status</Form.Label>
    { checkboxes }
    </Form.Group>
    </Form>
  );
}

function View (props: {
  display: string,
  onSubmit: Function,
  onDoneSuccess: Function,
  onDoneFailure: Function,
  onCancel: Function,
  onChange: Function,
  data: Function,
}) {

  const {
    display,
    onCancel,
    onSubmit,
    onDoneSuccess,
    onDoneFailure,
    onChange,
    data,
  } = props;

  return (
    <BModal title={"Update PPC Status"} display={display} onSubmit={onSubmit} onCancel={onCancel}
  onDoneSuccess={onDoneSuccess} onDoneFailure={onDoneFailure}>
      <PpcSignatoryFormChange onChange={onChange} data={data} />
      <BSuccess>Successfully updated Prompt Payment Code status.</BSuccess>
      <BFailure>Oops, failed to change Prompt Payment Code status.</BFailure>
    </BModal>
  );

}

export { View as default };

