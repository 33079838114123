// @flow
import React, {
} from "react";
import Form from "react-bootstrap/Form";


const CloseForm = (props:any) => {

  return (
    <Form>
    <Form.Group>
    <Form.Label>Close Organizations</Form.Label>
    <Form.Text>Are you sure? Note that closing the organization will also close
    all related addresses. Reload the application to see these changes.</Form.Text>
    </Form.Group>
    </Form>
  )
}

export {
  CloseForm,
}
