// @flow

import { createElement as el } from "react";
import { fork } from "fluture";

import View from "./view.js";
import { useToken } from "../../../../State";
import { removeEntry } from "../../Api";
import { type AccessListEntry } from "../../entity.js";

const Controller = (props: {
  entries: Array<AccessListEntry>,
  owner: string,
  list: string,
}) => {
  const {
    owner,
    list,
    entries,
  } = props;

  const token = useToken();

  const handleDelete = e => {
    const entry = e.target.value;
    const action = removeEntry(token)(entry)
    fork(error => console.log(error))(entry_ => {
      console.log("entry_", entry_);
    })(action);
  }

  return el(
    View,
    {
      owner,
      list,
      entries,
      onDeleteEntry: handleDelete,
    }
  )
}


export { Controller as default };
