// @flow
import React from "react";
import * as R from "ramda"

import {
  connect,
} from "react-redux";
import {
  withRouter,
} from "react-router-dom";

import {
  withMartletOrganizationApi,
} from "../Api/MartletOrganization";
import {
  type BmbixAddress,
} from "../Entity/Types.js";

import { MessageStatusWidget } from "./../MessagesApp/Widgets"

const MessageStatusView_ = (props: {
  martletOrganizationApi: Object,
  address: BmbixAddress,
  match: Object,
}) => {
  const {
    martletOrganizationApi,
    address,
    match,
  } = props;
  const messageId = match.params.messageId;

  return <MessageStatusWidget
    martletOrganizationApi={martletOrganizationApi}
    address={address}
    messageId={messageId}
  />
}

const connector = connect(
  (state, ownProps) => {
    const {
      match,
    } = ownProps;
    const addressId = match.params.addressId
    return {
      address: R.find(R.propEq("id", addressId))(state.addresses),
    }
  }
)

const MessageStatusView = withRouter(connector(withMartletOrganizationApi(MessageStatusView_)));


export {
  MessageStatusView,
}
