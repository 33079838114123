// @flow
import React, { useState } from "react";
import ReactTable from "react-table-6";
import Button from "react-bootstrap/Button";
import { TrashIcon } from "@primer/octicons-react";

import { type MessageType } from "./../../../../Entity/Types.js";
import { Controller as MessageTypesModalDelete } from
"./../../Widgets/MessageTypesModalDelete/controller.js";

const RemoveMessageType = (props: {
  messageTypeId: string,
  address: string,
}) => {
  const {
    address,
    messageTypeId,
  } = props;
  const [showModal, setShowModal] = useState(false);

  return showModal ?
    <MessageTypesModalDelete
      address={address}
      messageTypeId={messageTypeId}
      onDone={() => {setShowModal(false)}}
    /> :
    <Button
      value={messageTypeId}
      onClick= {e => setShowModal(true)} >
      <TrashIcon /> Delete
    </Button>;
}

const View = (props: {
  address: string,
  messageTypes: Array<MessageType>,
}) => {
  const {
    address,
    messageTypes,
  } = props;

  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Retention Policy",
      accessor: "retentionPolicy",
    },
    {
      Header: "Message Type",
      accessor: "messageType",
    },
    {
      Header: "Actions",
      accessor: "id",
      Cell: ({value}) => {
        console.log("value of entry: ", value);
        return (<RemoveMessageType address={address} messageTypeId={value} />);
      },
    },
  ]

  return (
    <ReactTable
      className="-striped -highlight"
      data={messageTypes}
      columns={columns}
      defaultPageSize={10}
    />
  )
}
export { View }

