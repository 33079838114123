// @flow

import React, {
  useState,
} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  Spinner,
} from "../Widgets/Toast";
import {OpenForm} from "./OpenForm";


function OpenModal (props: {
  callBack?: Function,
  cancel?: Function,
  display?: string,
}) {

  const handleClose = (e) => {
    e.preventDefault();
    if (props.cancel){
      props.cancel();
    }
  }

  const handleSave= (e) => {
    e.preventDefault();
    if(props.callBack){
      props.callBack();
    }
  }

  const submitButton = props.display === "input" ? (
          <Button variant="primary" onClick={handleSave}>Open organization</Button>
  ): null;

  const cancelButton = props.display === "input" || props.display === "waiting" ? (
          <Button variant="secondary" onClick={handleClose}>Cancel</Button>
  ): null;

  const doneButton = props.display === "success" || props.display === "failure" ? (
          <Button variant="secondary" onClick={handleClose}>Done</Button>
  ): null;


  return (
    <Modal show={true} >
      <Modal.Header>
        <Modal.Title>Close Organizations</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          {
            ( props.display === "input" || props.display === undefined )
            &&
            <OpenForm />
          }
          {
            props.display === "waiting"
            &&
            <Spinner />
          }
          {
            props.display === "success"
            &&
            <p>Success Opened organizations</p>
          }
          {
            props.display === "failure"
            &&
            <p>Ooops, Looks like something went wrong</p>
          }
        </>

      </Modal.Body>
        <Modal.Footer>
          {cancelButton}
          {submitButton}
          {doneButton}
        </Modal.Footer>
    </Modal>
  );

}


export {
  OpenModal,
}
