// @flow

import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  copyToClipboard,
} from "./../../../Util/copy.js";

const RevealModal = (props: {
  secret: String,
  onDismiss?: Function,
}) => {
  const {
    secret,
    onDismiss,
  } = props;

  return (
    <Modal show={true}>
      <Modal.Header closeButton onHide={onDismiss}>
        <Modal.Title>Access Key Secret</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {secret} <Button onClick={e => copyToClipboard(secret)}>Copy</Button>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onDismiss}>Dismiss</Button>
      </Modal.Footer>
    </Modal>
  )
}


export { RevealModal }
