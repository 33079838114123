// @flow

import React, {
  useState,
}  from "react";
import Button from "react-bootstrap/Button";

import { fork } from "fluture";
import {withRouter} from "react-router-dom";

import {
  withKlondikeApi,
} from "../Api/Klondike";

import {
  ClaimUnaddressedMessageForm
} from "../Widgets/ClaimUnaddressedMessageForm";
import { useCatchExpiredToken } from "./../State/Token.js";

const ClaimView_ = (props: {
  klondikeApi: Function,
  match: Function,
}) => {

  const {
    klondikeApi,
    match,
  } = props;
  const addressId = match.params.addressId;
  const catchExpiredToken = useCatchExpiredToken();

  const [messageToken, setMessageToken] = useState();

  const future = klondikeApi.updateWithClaimFuture()(addressId)(messageToken);
  const reject = (error) => {
    console.log("error:", error);
    catchExpiredToken(error);
  }
  const resolve = (claim) => console.log("claim:", claim);

  const handleSubmit = e => {
    const cancelFunc = fork(reject)(resolve)(future);
  }


  return (
    <>
    <p>Claim redemption</p>
    <ClaimUnaddressedMessageForm
      onMessageTokenChange={setMessageToken}
    />
    <Button onClick={handleSubmit}>Claim</Button>
    </>
  );
}

const ClaimView = withRouter(withKlondikeApi(ClaimView_));

export {
  ClaimView,
}
