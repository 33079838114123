import React from "react"
import
  Nav
from "react-bootstrap/Nav"
import {
  Link,
  withRouter,
} from "react-router-dom"
import {
  BookIcon,
  CircleSlashIcon,
  LockIcon,
  MentionIcon,
  PeopleIcon,
  TagIcon,
  WorkflowIcon,
} from "@primer/octicons-react";


const NavbarPresenter = (props: {
  location: Object,
  match: Object,
}) => {
  const {
    location,
    match,
  } = props;
  const organizationId = match.params.organizationId;
  const addressId = match.params.addressId;
  const root = `/m/organizations/${organizationId}/addresses/${addressId}`;
  const about_url = `${root}`;
  const team_url = `${root}/team`;
  const platform_url = `${root}/platform`;
  const address_book_url = `${root}/address-book`;
  const access_lists_url = `${root}/access-lists`;
  const message_types_url = `${root}/message-types`;
  const pki_url = `${root}/pki`;

  return (
    <Nav
      variant="tabs"
      activeKey={location.pathname}
    >
      <Nav.Item>
        <Nav.Link
          as={Link}
          href={about_url}
          to={about_url}
        ><MentionIcon /> Address</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          href={team_url}
          to={team_url}
        ><PeopleIcon /> Team</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          href={access_lists_url}
          to={access_lists_url}
        ><CircleSlashIcon /> Access Lists</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          href={message_types_url}
          to={message_types_url}
        ><TagIcon /> Message Types</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          href={pki_url}
          to={pki_url}
        ><LockIcon /> Encryption</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          href={platform_url}
          to={platform_url}
        ><WorkflowIcon /> Platform Assignment</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          href={address_book_url}
          to={address_book_url}
        ><BookIcon /> Address Book</Nav.Link>
      </Nav.Item>
    </Nav>
  );
}

export const Navbar = withRouter(NavbarPresenter);
