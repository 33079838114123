// @flow
import { createElement as el } from "react";
import { useMachine } from "@xstate/react";
import { createMachine, assign } from "xstate";
import { View } from "./view.js";

const modalFSMConfig = {
  id: "modalFSM",
  initial: "button",
  context: {},
  states: {
    button: {
      on: {
        SHOW_MODAL: {
          target: "modal",
        },
      },
    },
    modal: {
      on: {
        SHOW_BUTTON: {
          target: "button",
        }
      },
    },
  }
}

const Controller = () => {
  const [ modalState, modalSend ] = useMachine(() => createMachine(modalFSMConfig));

  const handleStart = (e) => {
    modalSend({type: "SHOW_MODAL"});
  }


  return el(
    View,
    {
      onStart: handleStart,
      modalState,
    },
  );
}


export {
  Controller,
}
