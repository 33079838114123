// @flow
import {
  createElement as el,
  useEffect,
  useState,
} from "react";

import { withRouter } from "react-router-dom";

import { fork } from "fluture";

// import { createCertificate } from "../../entity.js";
import { withSelect } from "../../Api";

import { View } from "./view.js";

const Controller = withRouter(withSelect((props: {
  match: Function,
  select: Function,
}) => {

  const {
    match,
    select,
  } = props;

  const [certificate, setCertificate] = useState();

  const certificateId = match.params.certificateId;
  const organizationId = match.params.organizationId;

  /*
  const organizationId = "abcdabcd-abcd-abcd-abcd-abcdabcd9999";
  const certificate = createCertificate({
    certId: "abcdabcd-abcd-abcd-abcd-abcdabcdabcd",
    subjectURI: "bmbix://user/abcdabcd-abcd-abcd-abcd-000100020003",
    dateIssued: "2021-07-23T12:00:00",
    issuerURI: `bmbix://organization/${organizationId}`,
    validatorURI: "bmbix://organization/abcdabcd-abcd-abcd-abcd-000100020005",
    data: "abceasdfasdf asdfasldfafdljasdf asdfljasdflasfasfjlasfd",
  });
  */


  useEffect(
    () => {
      console.log("9999999999999999");
      fork(error => console.log(error))(
        certificate => setCertificate(certificate))(
          select(organizationId)(certificateId));
    },
    [],
  );

  return el (
    View,
    {
      certificate,
      organizationId,
    }
  );

}));


export { Controller };
