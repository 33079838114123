// @flow
import React from "react";
import Button from "react-bootstrap/Button";
import { Controller as ModalView } from "./modal-controller.js";
import type { PaymentMethodLink } from "./../../../Entity/Types.js";



const View = (
  {
    state,
    onShowButtonClick,
    onDone,
    onCancel,
    paymentMethodLink,
  }: {
    state: Function,
    onShowButtonClick: Function,
    onDone: Function,
    onCancel: Function,
    paymentMethodLink: PaymentMethodLink,
  }) => {


  return state.value === "showButton"
  ? <Button onClick={onShowButtonClick}>Unlink</Button>
  : <ModalView
      onDone={onDone}
      onCancel={onCancel}
      paymentMethodLink={paymentMethodLink}
    />

}


export {
  View,
}

