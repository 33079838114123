// @flow

import {
  type UserProfile,
  type OrganizationProfile,
} from "./../Entity/Types.js";

export type ProfilesSearchResult = {
  +organizationProfiles?: Array<OrganizationProfile>,
  +userProfiles?: Array<UserProfile>
}

export type ResourcePublicKey = {|
  +data: string,
  +uri: string,
  +fingerprint: string,
  +expiresAt: Date,
|}

const createResourcePublicKey =
  (args: {
    data: string,
    uri: string,
    fingerprint: string,
    expiresAt: string,
  }): ResourcePublicKey =>
{
  let expiresAt_ = new Date(args.expiresAt)
  return Object.freeze({
    data: args.data,
    uri: args.uri,
    fingerprint: args.fingerprint,
    expiresAt: expiresAt_
  });
}

const createProfilesSearchResult = (args: {
  organizationProfiles?: Array<OrganizationProfile>,
  userProfiles?: Array<UserProfile>
}) => {
  return Object.freeze(args)
}

export {
  createProfilesSearchResult,
  createResourcePublicKey,
}
