// @flow
import * as R from "ramda";
import log from "loglevel";
import Future from "fluture";
import * as S from "sanctuary";

import {
  ApiClient,
  SystemApi,
  UserProfile as UserProfileGW,
  UserProfilesRequest as UserProfilesRequestGW,
  OrganizationProfile as OrganizationProfileGW,
  OrganizationProfilesRequest as OrganizationProfilesRequestGW,
  AddressProfile as AddressProfileGW,
  AddressProfilesRequest as AddressProfilesRequestGW,
} from "@bmbix/bmb_martlet_organization_client";
import {
  instanceService,
} from "../../Entity/InstanceService";
import {
  type UserProfile,
  type AddressProfile,
  type OrganizationProfile,
} from "../../Entity/Types.js";
import { callBack } from "./../CallBack/";

const logger = log.getLogger("MartletOrganizationApi");

const createUserProfileFromGW = (userProfileGW: UserProfileGW): UserProfile => {
  const expected = instanceService.createUserProfile({
    name: userProfileGW.name,
    verified: userProfileGW.verified,
    iconUrl: userProfileGW.icon_url,
    userId: userProfileGW.user_id,
  });
  return expected;
}

const createAddressProfileFromGW_ = (addressProfileGW: AddressProfileGW): AddressProfile => {
  const expected = instanceService.createAddressProfile({
    addressId: addressProfileGW.address_id,
    displayName: addressProfileGW.display_name,
    isClosed: addressProfileGW.is_closed,
  });
  return expected;
}

const createOrganizationProfileFromGW = (organizationProfileGW: OrganizationProfileGW): OrganizationProfile => {
  const expected = instanceService.createOrganizationProfile({
    organizationId: organizationProfileGW.organization_id,
    name: organizationProfileGW.name,
    isClosed: organizationProfileGW.is_closed,
    verified: organizationProfileGW.verified,
    iconUrl: organizationProfileGW.icon_url,
    addresses: S.map (createAddressProfileFromGW_) (organizationProfileGW.addresses || []),
    sicCode: organizationProfileGW.sic_code,
    headcount: organizationProfileGW.headcount,
    ppcSignatory: organizationProfileGW.ppc_signatory,
    paymentContactEmail: organizationProfileGW.payment_contact_email,
  });
  return expected;
}

const createAddressProfileFromGW = (addressProfileGW: AddressProfileGW): AddressProfile => {
  const organizationProfile = createOrganizationProfileFromGW(
    addressProfileGW.organization_profile,
  )

  const expected = instanceService.createAddressProfile({
    addressId: addressProfileGW.address_id,
    organizationId: addressProfileGW.organization_id,
    displayName: addressProfileGW.display_name,
    purpose: addressProfileGW.purpose,
    isClosed: addressProfileGW.is_closed,
    contentTypesEmitted: addressProfileGW.content_types_emitted,
    contentTypesAccepted: addressProfileGW.content_types_accepted,
    organizationProfile,
  });
  return expected;
}

const getUserProfiles = (args: {
  token: string,
  userIds: Array<string>,
  defaultClient_: Object,
}): Promise<Array<UserProfile>> => {
  const {
    token,
    userIds,
    defaultClient_,
  } = args;
  logger.debug("MartletOrganizationApi", "userIds:", userIds);

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  const body = UserProfilesRequestGW.constructFromObject({
    user_ids:userIds,
  });
  logger.debug("MartletOrganizationApi", "body", body);

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new SystemApi(defaultClient);

  const dataHandler = data => {
    const userProfilesResponseGW = data;
    const userProfilesGW = userProfilesResponseGW.user_profiles;
    const userProfiles = R.map(
      userProfileGW => createUserProfileFromGW(userProfileGW),
      userProfilesGW,
    );
    return userProfiles;
  }

  return new Promise((resolve, reject) => {
    apiInstance.listUserProfiles(
      body,
      callBack(reject, resolve) (dataHandler),
    );
  });
}

const getAddressProfiles = (args: {
  token: string,
  addressIds: Array<string>,
  defaultClient_: Object,
}): Promise<Array<AddressProfile>> => {
  const {
    token,
    addressIds,
    defaultClient_,
  } = args;
  logger.debug("MartletOrganizationApi", "addressIds:", addressIds);

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  const body = AddressProfilesRequestGW.constructFromObject({
    address_ids:addressIds,
  });
  logger.debug("MartletOrganizationApi", "body", body);

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new SystemApi(defaultClient);

  const dataHandler = data => {
    const addressProfilesResponseGW = data;
    const addressProfilesGW = addressProfilesResponseGW.address_profiles;
    const addressProfiles = R.map(
      addressProfileGW => createAddressProfileFromGW(addressProfileGW),
      addressProfilesGW,
    );
    return addressProfiles;
  }

  return new Promise((resolve, reject) => {
    apiInstance.listAddressProfiles(
      body,
      callBack(reject, resolve)(dataHandler),
    );
  });
}

const getAddressProfilesWithFuture = (token: string) => (addressIds: Array<string>): Future  => {
  const defaultClient = ApiClient.instance;
  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new SystemApi(defaultClient);
  const body = AddressProfilesRequestGW.constructFromObject({
    address_ids:addressIds,
  });

  const dataHandler = data => {
    const addressProfilesResponseGW = data;
    const addressProfilesGW = addressProfilesResponseGW.address_profiles;
    const addressProfiles = R.map(
      addressProfileGW => createAddressProfileFromGW(addressProfileGW),
      addressProfilesGW,
    );
    return addressProfiles;
  }

  return Future((reject, resolve) => {
    apiInstance.listAddressProfiles(
      body,
      callBack(reject, resolve)(dataHandler),
    );
    return console.log;
  });
}

const getOrganizationProfiles = (args: {
  token: string,
  organizationIds: Array<string>,
  defaultClient_: Object,
}): Promise<Array<OrganizationProfile>> => {
  const {
    token,
    organizationIds,
    defaultClient_,
  } = args;
  logger.debug("MartletOrganizationApi", "organizationIds:", organizationIds);

  let defaultClient;
  if(defaultClient_ === undefined){
    defaultClient = ApiClient.instance;
  } else {
    defaultClient = defaultClient_;
  }

  const body = OrganizationProfilesRequestGW.constructFromObject({
    organization_ids:organizationIds,
  });
  logger.debug("MartletOrganizationApi", "body", body);

  let MartletOauth2 = defaultClient.authentications['MartletOauth2'];
  MartletOauth2.accessToken = token;
  let apiInstance = new SystemApi(defaultClient);

  const dataHandler = data => {
    const organizationProfilesResponseGW = data;
    const organizationProfilesGW = organizationProfilesResponseGW.organization_profiles;
    const organizationProfiles = R.map(
      organizationProfileGW => createOrganizationProfileFromGW(organizationProfileGW),
      organizationProfilesGW,
    );
    return organizationProfiles;
  }

  return new Promise((resolve, reject) => {
    apiInstance.listOrganizationProfiles(
      body,
      callBack(reject, resolve)(dataHandler),
    );
  });
}


export {
  createUserProfileFromGW,
  getUserProfiles,
  createAddressProfileFromGW,
  getAddressProfiles,
  getAddressProfilesWithFuture,
  createOrganizationProfileFromGW,
  getOrganizationProfiles,
}

