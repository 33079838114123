// @flow

import React, {
  useState,
} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import * as R from "ramda";


import {
  type ExtractionCandidateReportEntry,
} from "../Entity/Types";
import ReactTable from "react-table-6"
import "react-table-6/react-table.css"

const CheckableExtractionCandidatesTable = (props: {
  items: Array<ExtractionCandidateReportEntry>,
  checked: Array<any>,
  callBack: Function,
}) => {
    const {
      items,
      checked,
      callBack,
    } = props;

    const [chkd, setChkd] = useState(checked);

    const isChecked = (reference) => {
       return R.contains(reference, chkd);
    }

    const handleChange = (e) => {
      const isChecked = e.target.checked;
      const ref = e.target.value;
      let newChecked;
      if(isChecked){
        newChecked = R.uniq(R.append(ref, chkd));
      }
      else {
        newChecked = R.without(ref, chkd);
      }
      setChkd(newChecked);
      callBack(newChecked);
    }

    const handleSelectAll = (e) => {
      const allRefs = R.map(
        i => i.reference,
        items,
      );
      setChkd(allRefs);
      callBack(allRefs);
    }

    const handleSelectNone = (e) => {
      const noneRefs = [];
      setChkd(noneRefs);
      callBack(noneRefs);
    }

    const columns = [
      {
        Header: "Select",
        Cell:  ({row}) => {
          return (
            <Form.Check
              id={row.reference}
              name="cairo"
              key={row.reference}
              value={row.reference}
              onChange={handleChange}
              checked={isChecked(row.reference)}
            />
          )
        }
      },
      {
        Header: "Reference",
        accessor: "reference",
      },
      {
        Header: "Summary",
        accessor: "summary",
      },
  ]

  return (
    <>
    <Button onClick={handleSelectAll} className="mr-2">Select All</Button>
    <Button onClick={handleSelectNone} className="mr-2">Select None</Button>
    <ReactTable
      className="-striped -highlight"
      data={items}
      columns={columns}
      defaultPageSize={10}
      defaultSort={[{id: "reference", desc: true}]}
    />
    </>
  )
}



export {
  CheckableExtractionCandidatesTable,
}
