// @flow
import {
  createElement as el,
} from "react";
import {
  Redirect,
} from "react-router-dom";


const Controller = (props: {
}) => {

  const to = window.localStorage.getItem("gotoUrl");
  window.localStorage.removeItem("gotoUrl");

  return el(
    Redirect,
    {
      to,
    },
  );
}

export {
  Controller,
}
