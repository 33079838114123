// @flow
import {
  Certificate as CertificateGW,
} from "@bmbix/bmb_polly_client";

import {
  type Certificate,
  createCertificate,
} from "../entity.js";

const createCertificateFromGW = (cgw: CertificateGW): Certificate => {
  return createCertificate({
  certId: cgw.cert_id,
  subjectURI: cgw.subject_uri,
  data: cgw.data,
  dateIssued: cgw.date_issued,
  issuerURI: cgw.issuer_uri,
  validatorURI: cgw.validator_uri,
  dateRescinded: cgw.date_rescinded,
  rescinderURI: cgw.rescinder_uri,
  })
}

export { createCertificateFromGW }

