// @flow
import { createElement as el, useState, } from "react";
import { fork } from "fluture";

import { withRouter } from "react-router-dom";

import {
  INPUT,
  WAITING,
  SUCCESS,
  FAILURE,
} from "../../../Util";

import { withValidateOrganization } from "../../Api";
import { InputView, WaitingView, SuccessView, FailureView } from "./view.js";
import { type ValidateOrganizationRequest } from "../../entity.js";

const Controller = withRouter(withValidateOrganization((props: {
  match: Function,
  validateOrganization: Function,
}) => {
  const {
    match,
    validateOrganization,
  } = props;
  const organizationId = match.params.organizationId;

  const [organizationName, setOrganizationName] = useState();
  const [registrar, setRegistrar] = useState();
  const [registerEntry, setRegisterEntry] = useState();
  const [bmbixId, setBmbixId] = useState();
  const [display, setDisplay] = useState(INPUT);
  const [certificate, setCertificate] = useState(null);

  const handleChange = e => {
    console.log(e.currentTarget.name, e.currentTarget.value);
    const n = e.currentTarget.name;
    const v = e.currentTarget.value;
    switch (n) {
      case "bmbixId":
        setBmbixId(v);
        break;
      case "registrar":
        setRegistrar(v);
        break;
      case "registerEntry":
        setRegisterEntry(v);
        break;
      case "organizationName":
        setOrganizationName(v);
        break;
      default:
        break;
    }
  }

  const handleSubmit = e => {
    console.log(e);
    setDisplay(WAITING);
    const body: ValidateOrganizationRequest = Object.freeze({
      bmbixId,
      registrar,
      registerEntry,
      organizationName,
    });

    const action = validateOrganization(organizationId)(body);

    fork(error => {
      console.log(error);
      setDisplay(FAILURE);
    })(certificate => {
      console.log(certificate);
      setCertificate(certificate);
      setDisplay(SUCCESS);
    })(action)
  }

  return display === INPUT ? el(
    InputView,
    {
      display,
      onChange: handleChange,
      onSubmit: handleSubmit,
    }
  ): display === WAITING ? el (
    WaitingView,
    {
    },
  ): (display === SUCCESS) && (!!certificate)? el (
    SuccessView,
    {
      certificate,
      organizationId,
    },
  ): display === FAILURE ? el (
    FailureView,
    {
    }
  ): null;
}));

export { Controller }
