// @flow
export type Certificate = {
  +certId: string,
  +subjectURI: string,
  +data?: string,
  +dateIssued: string,
  +issuerURI: string,
  +validatorURI: string,
  +dateRescinded?: string,
  +rescinderURI?: string,
}

export type ValidateOrganizationRequest = {
  +bmbixId: string,
  +registrar: string,
  +registerEntry: string,
  +organizationName: string,
}

export type ValidateUserRequest = {
  +bmbixId: string,
  +cn: string,
  +country: string,
  +state: string,
  +locality: string,
}

export const createCertificate = (args: {
  +certId: string,
  +subjectURI: string,
  +data?: string,
  +dateIssued: string,
  +issuerURI: string,
  +validatorURI: string,
  +dateRescinded?: string,
  +rescinderURI?: string,
}): Certificate => {
  const {
    certId,
    subjectURI,
    data,
    dateIssued,
    issuerURI,
    validatorURI,
    dateRescinded,
    rescinderURI,
  } = args;
  return Object.freeze({
    certId,
    subjectURI,
    data,
    dateIssued,
    issuerURI,
    validatorURI,
    dateRescinded,
    rescinderURI,
  });

}
